import { createSelector, ParametricSelector, Selector } from 'reselect';
import { RootState } from '..';
import entities from '../reducers/entities';
import { Actions, EntitiesState, Entity } from '../features/entities';

export const EMPTY_PROPS = {};

type EntitiesList = keyof ReturnType<typeof entities>;

export type GenericSelector<Result> = Selector<RootState, Result>;
export type GenericSelectorNullable<Result> = Selector<RootState, Nullable<Result>>;

export type GenericParametricSelector<Props, Result> = ParametricSelector<RootState, Props, Result>;
export type GenericParametricSelectorNullable<Props, Result> = GenericParametricSelector<
  Props,
  Nullable<Result>
>;

export const createEntitiesPendingSelector =
  (name: EntitiesList) =>
  (state: RootState): boolean =>
    state.entities[name]!.pending ?? false;
export const createEntitiesErrorSelector =
  (name: EntitiesList) =>
  (state: RootState): boolean =>
    state.entities[name]!.error ?? false;

// *******************************************************************************

type MapKey = string | number | symbol;
type MapOrArray<T> = Array<T> | Record<MapKey, T>;

function selectMapOrArray<T>(items: Array<T>): Array<T>;
function selectMapOrArray<T>(items: Record<any, T>): Record<any, T>;
function selectMapOrArray<T>(items: MapOrArray<T>): MapOrArray<T> {
  return items;
}

function selectMap<T>(items: Record<MapKey, T>) {
  return items;
}
export const createSelectMapToArray = <T>(): Selector<Record<MapKey, T>, Array<T>> =>
  createSelector([selectMap], (entities) => Object.values(entities));

export const createSelectArrayToArray = <T, R>(callback: (item: T) => R) =>
  createSelector<MapOrArray<T>, MapOrArray<T>, Array<R>>([selectMapOrArray], (items) => {
    items = Array.isArray(items) ? items : Object.values(items);

    return items.map(callback);
  });

// *******************************************************************************

export interface PossibleIdProps {
  id?: string;
}

export const selectPossibleId = (state: RootState, props?: PossibleIdProps) => props?.id;

// *******************************************************************************

export type ExtendedEntity<T> = T & {
  __actions?: Actions;
};

export interface SelectorResult<T> {
  items: Array<ExtendedEntity<T>>;
  error: any | undefined | null;
  isFetching: boolean;
  metadata: {
    pageCount: number;
  };
}

interface Options {
  withActions?: boolean;
}

export type TableReduxSelector<T, P> = ParametricSelector<
  RootState,
  P,
  SelectorResult<ExtendedEntity<T>>
>;

export const wrapEntitiesTableSelectorFactory = <T extends Entity, P>(
  selector: ParametricSelector<RootState, P, EntitiesState<T>>,
  options?: Options
): TableReduxSelector<T, P> =>
  createSelector([selector], (entities) => {
    if (!entities.metadata && !entities.pending) {
      return {
        items: [],
        error: entities.error,
        isFetching: entities.pending,
        metadata: {
          pageCount: 0,
        },
      };
    }

    let items: Array<ExtendedEntity<T>> = Object.values(entities.items);
    if (options?.withActions) {
      items = items.map((v: T): ExtendedEntity<T> => {
        return {
          ...v,
          __actions: entities.actions[v.id],
        };
      });
    }

    return {
      items: items,
      error: entities.error,
      isFetching: entities.pending,
      metadata: {
        pageCount: entities.metadata ? entities.metadata.pageCount : 0,
      },
    };
  });
