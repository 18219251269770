import {
  INTERCOM_PROPERTY_SUCCESS,
  INTERCOM_PROPERTY_REQUEST,
  INTERCOM_PROPERTY_FAIL,
} from '../../../../constants/actions';

const defaultState = {
  value: null,
  isFetching: false,
  error: null,
};

export default function property(state = {}, action) {
  switch (action.type) {
    case INTERCOM_PROPERTY_REQUEST: {
      return {
        ...state,
        [action.payload.id]: {
          [action.payload.key]: {
            ...defaultState,
            isFetching: true,
          },
        },
      };
    }
    case INTERCOM_PROPERTY_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          [action.payload.key]: {
            isFetching: false,
            value: action.payload.value,
          },
        },
      };
    }
    case INTERCOM_PROPERTY_FAIL: {
      return {
        ...state,
        [action.payload.id]: {
          [action.payload.key]: {
            isFetching: false,
            error: action.payload.error,
          },
        },
      };
    }
    default:
      break;
  }

  return state;
}
