import React, { ComponentType } from 'react';
import { Field, FieldProps } from 'formik';
import { BindingType, Building } from 'core/constants/contracts';
import { AddressHandler, RenderProps } from './handler';

export interface AddressRendererProps extends RenderProps {}

export interface AddressDropdownProps {
  name: string;
  fields: Set<BindingType>;
  component: ComponentType<AddressRendererProps>;
  buildings?: Array<Building>;
  ordering?: Array<BindingType>;
  optimizeFetch?: boolean;
  isActive?: boolean;
  isTest?: boolean;
  onChangeBuilding?: (buildingId: string) => void;
  onChangeType?: (type: BindingType, bindingId: string) => void;
}

export class AddressDropdown extends React.Component<AddressDropdownProps> {
  renderAddress = (renderProps: AddressRendererProps) => {
    const {
      component: View,

      name,
      fields,
      buildings,
      ordering,
      optimizeFetch,
      isActive,
      isTest,

      ...rest
    } = this.props;

    return <View {...rest} {...renderProps} />;
  };

  renderHandler = (renderProps: FieldProps) => {
    const {
      name,
      fields,
      buildings,
      ordering,
      optimizeFetch,
      isActive,
      isTest,
      onChangeBuilding,
      onChangeType,
    } = this.props;

    return (
      <AddressHandler
        name={name}
        fields={fields}
        buildings={buildings}
        ordering={ordering}
        optimizeFetch={optimizeFetch}
        isActive={isActive}
        isTest={isTest}
        onChangeBuilding={onChangeBuilding}
        onChangeType={onChangeType}
        {...renderProps}
      >
        {this.renderAddress}
      </AddressHandler>
    );
  };

  render() {
    return <Field name={this.props.name}>{this.renderHandler}</Field>;
  }
}
