import * as yup from 'yup';

const innRegexp = new RegExp(/^([0-9]{10}|[0-9]{12})$/);
const ogrnRegexp = new RegExp(/^([0-9]{13}|[0-9]{15})$/);

const schemaLegalEntityFactory = (commercialStatusValues) => {
  return yup.object().shape({
    name: yup.string().required('Укажите Наименование'),
    legal_address: yup.string().required('Укажите Юр. адрес'),
    phone: yup.string().required('Укажите Телефон'),
    web_site: yup.string().url('Укажите валидный Сайт'),
    inn: yup
      .number()
      .typeError('Укажите числовые значения')
      .integer('Укажите валидный ИНН')
      .positive('Укажите валидный ИНН')
      .required('Укажите ИНН')
      .test('is-inn', 'Длина ИНН 10 или 12', function (value) {
        return innRegexp.test(value);
      }),
    ogrn: yup
      .number()
      .typeError('Укажите числовые значения')
      .integer('Укажите валидный ОГРН')
      .positive('Укажите валидный ОГРН')
      .test('is-ogrn', 'Длина ОГРН 13 или 15', function (value) {
        if (!value) {
          return true;
        }

        return ogrnRegexp.test(value);
      }),
    personal_account: yup.string().when('isEditForm', (isEditForm, schema) => {
      return isEditForm ? schema : schema.required('Укажите Лицевой счет');
    }),
    commercial_status: yup
      .string()
      .required('Укажите статус')
      .oneOf(commercialStatusValues, 'Укажите статус из списка предлогаемых вариантов'),
  });
};

export default schemaLegalEntityFactory;
