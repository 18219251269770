import * as yup from 'yup';
import { set, get, isEmpty } from 'lodash';
import { ValidationError } from 'yup';

export const validateBySchema = (schema, values, options = {}) => {
  let errors = {};

  try {
    schema.validateSync(values, { abortEarly: false, ...options });
  } catch (e) {
    if (e instanceof yup.ValidationError) {
      errors = e.inner.reduce((acc, error) => {
        if (false === isEmpty(get(acc, error.path))) {
          return acc;
        }

        return set(acc, error.path, error.message);
      }, {});
    } else {
      throw e;
    }
  }

  return errors;
};

export const validateBySchemaAsync = (schema, values, options) => {
  return schema.validate(values, { abortEarly: false, recursive: true, ...options }).catch((e) => {
    let errors = {};

    if (e instanceof ValidationError) {
      if (e.path) {
        set(errors, e.path, e.message);
      }

      errors = e.inner.reduce((acc, error) => {
        if (false === isEmpty(get(acc, error.path))) {
          return acc;
        }

        return set(acc, error.path, error.message);
      }, errors);
    } else {
      errors['_'] = 'invalid schema validation';
    }

    return Promise.reject(errors);
  });
};

// ---------------------------------------------------------------------------------------------------------------------

yup.addMethod(
  yup.object,
  'uniqueProperty',
  function (propertyName, message = 'A property of array is not unique') {
    return this.test('unique', message, function (value) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const currentIndex = options.indexOf(value);

      const subOptions = options.slice(0, currentIndex);

      if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }

      return true;
    });
  }
);
