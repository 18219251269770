import { RootState } from 'core/store';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownItemProps, Modal } from 'semantic-ui-react';
import CloudArchiveForm from './form';
import { FormValues } from './contracts';
import { request } from 'core/utils/request';
import api from 'core/constants/api';
import { useToasts } from 'react-toast-notifications';
import { Loader } from 'components/share';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceId: string;
  variant: 'create' | 'edit';
  companyData?: {
    id: string;
    max_channels: number;
    serviceRegistryId: string;
  };
}

type IStoreProps = {
  companies: DropdownItemProps[];
};

export const CloudArchiveModal: FC<ModalProps> = ({
  isOpen,
  onClose,
  serviceId,
  companyData,
  variant,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const { addToast } = useToasts();

  const { companies } = useSelector<RootState, IStoreProps>((state) => {
    const companiesMap = state.entities.companies.items;
    return {
      companies: Object.keys(companiesMap).map((key) => ({
        key,
        text: companiesMap[key].name,
        value: key,
      })),
    };
  });

  const onSubmit = ({ owner_id, max_channels }: FormValues) => {
    const requestData = {
      service_id: serviceId,
      max_channels,
    };

    setIsFetching(true);

    if (variant === 'edit') {
      request
        .put(
          api.billingCompanyServicesRegistry(owner_id) +
            `/${companyData?.serviceRegistryId}/payload`,
          requestData
        )
        .then(() => {
          addToast(`Данные сохранены`);
          onClose();
        })
        .catch((err) => {
          console.error(err);
          addToast(`Ошибка при сохранении данных`, {
            appearance: 'error',
            autoDismiss: true,
          });
        })
        .finally(() => {
          setIsFetching(false);
          // TODO Костыль чтобы обновить данные на странице
          setTimeout(() => window.location.reload(), 1500);
        });
    } else {
      request
        .post(api.billingCompanyServicesRegistry(owner_id), requestData)
        .then(() => {
          addToast(`Данные сохранены`);
          onClose();
        })
        .catch((err) => {
          console.error(err);
          addToast(`Ошибка при сохранении данных`, {
            appearance: 'error',
            autoDismiss: true,
          });
        })
        .finally(() => {
          setIsFetching(false);
          // TODO Костыль чтобы обновить данные на странице
          setTimeout(() => window.location.reload(), 1500);
        });
    }
  };

  return (
    <Modal open={isOpen} closeIcon onClose={onClose} size={'small'}>
      <Modal.Header content="Добавить каналы облачного архива" className="font-weight-medium" />
      <Modal.Content>
        <Loader active={isFetching} />

        <CloudArchiveForm
          variant={variant}
          companies={companies}
          companyData={companyData}
          onCancel={onClose}
          onSubmit={onSubmit}
        />
      </Modal.Content>
    </Modal>
  );
};
