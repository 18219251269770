import { BUILDINGS_REQUEST, BUILDINGS_SUCCESS, BUILDINGS_FAIL } from '../../../constants/actions';

const defaultState = {
  metadata: {
    page: 1,
    perPage: 20,
  },
  isFetching: false,
  items: [],
  error: null,
};

export default function buildings(state = defaultState, action) {
  switch (action.type) {
    case BUILDINGS_REQUEST: {
      return { ...state, isFetching: true, error: null };
    }
    case BUILDINGS_SUCCESS: {
      return {
        ...state,
        metadata: action.payload.metadata,
        items: action.payload.items,
        isFetching: false,
      };
    }
    case BUILDINGS_FAIL: {
      return { ...state, error: action.payload.error, isFetching: false, items: [], metadata: {} };
    }
    default:
      break;
  }

  return state;
}
