import React, { useCallback, useMemo, FC } from 'react';
import { useLocation } from 'react-router-dom';

import { PromiseThunk } from 'core/store';
import { WebAPIActionFetchParams } from 'core/utils/webapi.contracts';
import { wrapEntitiesTableSelectorFactory } from 'core/store/selectors';
import { TableRedux, TableReduxFetchOptions } from 'components/share/components/table/redux';

import { createColumns } from './columns';
import { createSelectVideoRecordAggAsState } from 'core/store/selectors/video-recorders';
import { TableFilterFormData } from 'components/share/widgets/table-filter';

interface VideoRecordersProps {
    fetchData: (props: WebAPIActionFetchParams) => PromiseThunk;
    isFetching?: boolean;
    showUpperPagination?: boolean;
    isArmDomofon?: boolean;
    filters: TableFilterFormData;
}

export const VideoRecordersTable: FC<VideoRecordersProps> = ({
    fetchData,
    isFetching,
    showUpperPagination = true,
    isArmDomofon,
    filters,
  }) => {
    const query = new URLSearchParams(useLocation().search);
    const columns = useMemo(
        () => createColumns({ query, isArmDomofon }),
        [isArmDomofon, query]
    );
    const videoRecorderSelector = useMemo(() => createSelectVideoRecordAggAsState(), []);
    const selector = useCallback(
        wrapEntitiesTableSelectorFactory(videoRecorderSelector, {
        withActions: false,
        }),
        []
    );
    const fetcher = useCallback(
        ({ pageIndex, pageSize, ...other }: TableReduxFetchOptions) => {
        return fetchData({
            page: pageIndex + 1,
            limit: pageSize,
            sort: '-created_at',
            filter: filters,
            ...other,
        });
        },
        [fetchData, filters]
    );

    return (
        <TableRedux
            columns={columns}
            fetcher={fetcher}
            selector={selector}
            refreshAfterResize={5}
            isFetching={isFetching}
            showUpperPagination={showUpperPagination}
        />
    );
  }