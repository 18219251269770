import React, { FC, useMemo } from 'react';
import { LabelStatus } from 'components/share/components/status/label';
import { hintOperationalStatus } from '../../../utils';

interface Props {
  message: string;
  isOnline: boolean;
}

export const OperationStatus: FC<Props> = ({ message, isOnline }) => {
  const popup = useMemo(() => {
    return {
      content: hintOperationalStatus(isOnline),
      wide: !isOnline ? ('very' as const) : undefined,
    };
  }, [isOnline]);

  const color = isOnline ? 'green' : 'red';
  return (
    <LabelStatus color={color} popup={popup} light>
      {message}
    </LabelStatus>
  );
};
