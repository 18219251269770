import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ParametricSelector } from 'reselect';
import { Table } from 'mts-ui';
import { TableFetchOptions, Column, TableProps } from '../contracts';
import { DEFAULT_PAGE_SIZE, PAGE_SIZES, DEFAULT_PAGE_INDEX } from 'core/constants/app';

export interface TableReduxFetchOptions extends TableFetchOptions {
  filter?: object;
}

export interface Fetcher {
  (params: TableReduxFetchOptions): any;
}

export interface SelectorResult {
  items: Array<object>;
  error: any | undefined | null;
  isFetching: boolean;
  metadata: {
    pageCount: number;
  };
}

export type TableReduxSelector = ParametricSelector<any, any, SelectorResult>;
export type Scroller = (ref: React.RefObject<HTMLElement>) => void;

export interface OwnProps {
  columns: Array<Column<any>>;
  fetcher?: Fetcher;
  selector: TableReduxSelector;
  filter?: object;
  pageSizes?: Array<number>;
  defaultPageSize?: number;
  tableProps?: TableProps;
  scroll?: false | Scroller;
  // Количество элементов в таблице, которое будет удалено/добавлено для последующего автообновления таблицы
  refreshAfterResize?: number;
  isFetching?: boolean;
  isScrollable?: boolean;
  showUpperPagination?: boolean;
  queryPaginationOff?: boolean;
}

interface Props extends OwnProps, DispatchProp, ReturnType<typeof mapStateToProps> {}

const scrollToRef = (ref: React.RefObject<HTMLElement>) => {
  try {
    // @ts-ignore
    if (window.scrollTo && ref.current && ref.current.getBoundingClientRect) {
      window.scrollTo(0, ref.current.getBoundingClientRect().top + window.scrollY - 80);
    }
  } catch (e) {}
};

function TableReduxComponent(props: Props) {
  const {
    fetcher,
    dispatch,
    filter,
    data,
    columns,
    pageSizes,
    defaultPageSize,
    tableProps,
    scroll = true,
    refreshAfterResize,
    isFetching,
    isScrollable = false,
    showUpperPagination,
    queryPaginationOff = false,
  } = props;

  const [changes, setChanges] = useState({
    count: 0,
    refresh: 0,
    size: data.items.length,
  });

  let actualRefresh = changes.refresh; // ToDo: Костыль

  useEffect(() => {
    if (refreshAfterResize) {
      if (data.items.length !== changes.size) {
        const delta = changes.size === 0 ? 0 : 1;
        const count = changes.count + delta;
        const isRefreshTime = count === refreshAfterResize;
        const refresh = isRefreshTime ? changes.refresh + 1 : changes.refresh;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        actualRefresh = refresh;

        setChanges({
          count: count,
          refresh: refresh,
          size: data.items.length,
        });
      }
    }
  }, [refreshAfterResize, data.items.length]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }: TableReduxFetchOptions) => {
      if (refreshAfterResize && changes.count > 0) {
        setChanges({
          // если не равны, значит произошло обновление из-за количетсва удаление/добавлений записей в хранилище
          // следовательно следующий рендер значения size и data.items.length будуте разные и добавится 1, что мы здесь омпенсируем
          // TODO мб быга при количестве новых данных равное пришедшему, т.е. на последней странице
          count: actualRefresh === changes.refresh ? -1 : 0,
          refresh: changes.refresh,
          size: data.items.length,
        });
      }

      if (!filter || (filter && Object.keys(filter).length === 0)) {
        fetcher && dispatch(fetcher({ pageIndex, pageSize }));
      } else {
        fetcher &&
          dispatch(
            fetcher({
              pageIndex: DEFAULT_PAGE_INDEX,
              pageSize,
              filter,
            })
          );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, fetcher, changes.refresh]
  );

  return (
    <Table.Async
      columns={columns}
      data={data.items}
      fetchData={fetchData}
      pageSizes={pageSizes || PAGE_SIZES}
      defaultPageSize={defaultPageSize || DEFAULT_PAGE_SIZE}
      pageCount={data.metadata.pageCount}
      isFetching={isFetching ?? data.isFetching}
      tableProps={tableProps}
      scroll={scroll === false ? false : scrollToRef}
      isScrollable={isScrollable}
      showUpperPagination={showUpperPagination}
      queryPaginationOff={queryPaginationOff}
      selectable
    />
  );
}

const mapStateToProps = (state: any, props: OwnProps) => {
  return {
    data: props.selector(state, props),
  };
};

export const TableRedux: ComponentType<OwnProps> = connect(mapStateToProps)(TableReduxComponent);
