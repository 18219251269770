import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import TextInput from 'components/share/redux-form/text-input';
import Dropdown from 'components/share/redux-form/dropdown';
import InputMask from 'components/share/redux-form/input-mask';
import { Field } from 'redux-form';

const Index = ({
  commercialStatuses,
  showForis,
  contractorOptions,
  handleChangeType,
  isEditForm,
}) => {
  return (
    <React.Fragment>
      <Form.Group widths="3">
        {!isEditForm && (
          <Field
            component={Dropdown}
            name="person_type"
            label="Тип контрагента"
            options={contractorOptions}
            onChange={handleChangeType}
          />
        )}
        <Field
          component={TextInput}
          name="personal_account"
          label="Лицевой счет"
          required={!isEditForm}
        />
      </Form.Group>
      <Form.Group widths="3">
        <Field name={'name'} component={TextInput} label={'Наименование'} required />
        <Field name={'legal_address'} component={TextInput} label={'Юр. адрес'} required />
        <Field name={'fact_address'} component={TextInput} label={'Факт. адрес'} />
      </Form.Group>
      <Form.Group widths="3">
        <Field
          name={'phone'}
          component={InputMask}
          label={'Номер телефона'}
          required
          mask="+7 (999) 999-99-99"
          placeholder="+7 (999) 999-99-99"
          formatChars={{
            9: '[0-9]',
          }}
        />
        <Field name={'web_site'} component={TextInput} label={'Сайт'} />
      </Form.Group>
      <Form.Group widths="3">
        <Field name={'inn'} component={TextInput} label={'ИНН'} required />
        <Field name={'ogrn'} component={TextInput} label={'ОГРН'} />
      </Form.Group>
      <Form.Group widths="3">
        <Field
          name={'commercial_status'}
          component={Dropdown}
          label={'Коммерческий статус'}
          required
          options={commercialStatuses}
        />
        {showForis && <Field name={'foris'} component={TextInput} label={'Foris ID'} />}
      </Form.Group>
    </React.Fragment>
  );
};

Index.propTypes = {
  field: PropTypes.func,
  showForis: PropTypes.bool,
  administrativeStatuses: PropTypes.array,
  commercialStatuses: PropTypes.array,
};

export default Index;
