import * as yup from 'yup';

const innRegexp = new RegExp(/^([0-9]{10}|[0-9]{12})$/);

export const schemaIndividualFactory = (commercialStatusValues) => {
  return yup.object().shape({
    last_name: yup.string().required('Укажите Фамилия'),
    first_name: yup.string().required('Укажите Имя'),
    mid_name: yup.string(),
    address: yup.string().required('Укажите Адрес'),
    inn: yup
      .number()
      .typeError('Укажите числовые значения')
      .integer('Укажите валидный ИНН')
      .positive('Укажите валидный ИНН')
      .test('is-inn', 'Длина ИНН 10 или 12 цифр', function (value) {
        if (!value) {
          return true;
        }

        return innRegexp.test(value);
      }),
    personal_account: yup.string().when('isEditForm', (isEditForm, schema) => {
      return isEditForm ? schema : schema.required('Укажите Лицевой счет');
    }),
    commercial_status: yup
      .string()
      .required('Укажите статус')
      .oneOf(commercialStatusValues, 'Укажите статус из списка предлогаемых вариантов'),
  });
};

export default schemaIndividualFactory;
