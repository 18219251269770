import { createSlice } from '@reduxjs/toolkit';
import { Camera } from '../../constants/contracts';
import api from '../../constants/api';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createSaveEntity,
  createUpdateEntity,
  createFetchEntityById,
} from './entities';

const slice = createSlice({
  name: 'cameras',
  initialState: createEntitiesInitialState<Camera>(),
  reducers: createDefaultEntitiesReducers<Camera>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchCamera = createFetchEntityById<Camera>(api.cameras, slice.actions);
export const fetchCameras = createFetchEntities<Camera>(api.cameras(), slice.actions);
export const saveCamera = createSaveEntity(api.cameras, slice.actions);
export const updateCamera = createUpdateEntity(api.cameras, slice.actions);
