import React from 'react';
import { Grid } from 'semantic-ui-react';
import { UserAuthCredentialAsterisk } from 'core/constants/contracts';

interface Props {
  credential: UserAuthCredentialAsterisk;
}

export function SipAsteriskInformation(props: Props) {
  return (
    <Grid>
      <Grid.Row columns={'equal'}>
        <Grid.Column>SIP: {props.credential.sip}</Grid.Column>
        <Grid.Column>Domain: {props.credential.domain}</Grid.Column>
        <Grid.Column>Secret: {props.credential.secret}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
