import * as yup from 'yup';
import { IP_ADDRESS_REGEXP, RANGE_REGEXP } from 'core/constants/validation';
import { schemaList } from '../../../config';

const schema = yup.object().shape(
  {
    ip: yup
      .string()
      .matches(IP_ADDRESS_REGEXP, 'IP-адрес не валидный')
      .required('Укажите IP-адрес устройства'),
    port: yup
      .number()
      .typeError('Укажите число')
      .required('Укажите порт устройства')
      .integer('Должно быть целое число')
      .min(0, 'Укажите число от 0 до 65535')
      .max(65535, 'Укажите число от 0 до 65535'),
    schema: yup.string().oneOf(schemaList),
    login: yup.string().max(30).required('Укажите логин устройства'),
    password: yup.string().max(30).required('Укажите пароль устройства'),
    reference: yup.mixed().required('Укажите поставщика устройства'),
    name: yup
      .string()
      .required('Укажите название')
      .min(2, 'Укажите значение от 2 до 50')
      .max(50, 'Укажите значение от 2 до 50'),
    сapacity: yup
      .number()
      .typeError('Укажите число')
      .integer('Должно быть целое число')
      .min(0, 'Укажите число больше 0'),
    shift1: yup
      .number()
      .typeError('Укажите число')
      .integer('Должно быть целое число')
      .min(0, 'Укажите число больше 0')
      .when('range1', (range1: string, schema: any) => {
        if (range1) {
          return schema.required('Укажите сдвиг нумерации портов  1');
        }
        return schema;
      }),
    range1: yup
      .string()
      .matches(RANGE_REGEXP, 'Укажите диапазон в формате 0 - 999')
      .when('shift1', (shift1: string, schema: any) => {
        if (shift1) {
          return schema.required('Укажите диапазон портов 1');
        }
        return schema;
      }),
    shift2: yup
      .number()
      .typeError('Укажите число')
      .integer('Должно быть целое число')
      .min(0, 'Укажите число больше 0')
      .when('range2', (range2: string, schema: any) => {
        if (range2) {
          return schema.required('Укажите сдвиг нумерации портов  2');
        }
        return schema;
      }),
    range2: yup
      .string()
      .matches(RANGE_REGEXP, 'Укажите диапазон в формате 0 - 999')
      .when('shift2', (shift2: string, schema: any) => {
        if (shift2) {
          return schema.required('Укажите диапазон портов 2');
        }
        return schema;
      }),
  },
  [
    ['range1', 'shift1'],
    ['range2', 'shift2'],
  ]
);

export default schema;
