import { createSelector } from 'reselect';
import {
  ExtendedEntity,
  GenericParametricSelector,
  GenericParametricSelectorNullable,
  GenericSelector,
  selectPossibleId,
  PossibleIdProps,
} from '.';
import { RootState } from '..';
import {
  DeviceCommand,
  DeviceReference,
  Intercom,
  OwnerStatusType,
} from '../../constants/contracts';
import { getDeviceReferencesItems } from './devices-references';
import { EntitiesState, EntityId } from '../features/entities';
import { getCompaniesItems } from './companies';
import { getBuildingsItems } from './buildings';
import isEmpty from 'lodash/isEmpty';
import { ownerStatusMap } from './shared/helper';

interface Props {
  intercomId: string;
}

// @ts-ignore
export const getIntercoms = (state: RootState) => state.entities.intercoms;
export const getIntercomsItems = (state: RootState) => state.entities.intercoms.items;
export const getIntercomsPending = (state: RootState) => state.entities.intercoms.pending;
export const getIntercomsActions = (state: RootState) => state.entities.intercoms.actions;
export const getId = (state: RootState, props: Props) => props.intercomId;

export const createSelectIntercomsAsArray = (): GenericSelector<Array<Intercom>> =>
  createSelector([getIntercomsItems], (intercoms) => Object.values(intercoms));

export const createSelectIntercom = (): GenericParametricSelectorNullable<Props, Intercom> =>
  createSelector([getIntercomsItems, getId], (intercoms, intercomId) => intercoms[intercomId]);

// агрегат
export interface IntercomAgg extends ExtendedEntity<Intercom> {
  __device_ref?: DeviceReference;
  __commands?: Array<DeviceCommand>;
  __owner_status?: OwnerStatusType;
  __building_is_test?: boolean;
}

export const createSelectIntercomsAggAsArray = (): GenericParametricSelector<
  PossibleIdProps,
  Array<IntercomAgg>
> =>
  createSelector(
    [
      getIntercomsItems,
      getDeviceReferencesItems,
      getIntercomsActions,
      getCompaniesItems,
      getBuildingsItems,
      selectPossibleId,
    ],
    (intercoms, references, actions, companies, buildings, intercomId) => {
      const items =
        intercomId && intercoms[intercomId] ? { [intercomId]: intercoms[intercomId] } : intercoms;

      return Object.values(items).map((v: Intercom): IntercomAgg => {
        const refId = v.device?.reference;
        const deviceOwnerId = v.device.owner?.id;
        const deviceBuildingId = v.device.building?.id;
        const deviceReference = references[refId];
        if (refId && deviceReference) {
          const commands = new Map<string, DeviceCommand>();
          deviceReference.protocols.forEach((p) =>
            p.commands.filter((c) => c.can_customize).forEach((c) => commands.set(c.id, c))
          );

          return {
            ...v,
            __device_ref: deviceReference,
            __commands: Array.from(commands.values()),
            __owner_status:
              deviceOwnerId && !isEmpty(companies)
                ? {
                    name: ownerStatusMap[companies[deviceOwnerId].commercial_status].name,
                    color: ownerStatusMap[companies[deviceOwnerId].commercial_status].color,
                  }
                : undefined,
            __building_is_test:
              deviceBuildingId && !isEmpty(buildings) && !isEmpty(buildings[deviceBuildingId])
                ? buildings[deviceBuildingId].is_test
                : false,
            __actions: actions[v.id],
          };
        }

        return v;
      });
    }
  );

export const createSelectIntercomsAggAsMap = (): GenericParametricSelector<
  PossibleIdProps,
  Record<EntityId, IntercomAgg>
> => {
  const selectIntercomsAggAsArray = createSelectIntercomsAggAsArray();

  return createSelector([selectIntercomsAggAsArray], (intercoms) => {
    return intercoms.reduce((acc, v) => {
      acc[v.id] = v;

      return acc;
    }, {} as Record<EntityId, IntercomAgg>);
  });
};

export const createSelectIntercomsAggAsState = (): GenericParametricSelector<
  PossibleIdProps,
  EntitiesState<IntercomAgg>
> => {
  const selectIntercomsAggAsMap = createSelectIntercomsAggAsMap();

  return createSelector([getIntercoms, selectIntercomsAggAsMap], (intercomsState, items) => {
    return {
      ...intercomsState,
      items,
    };
  });
};
