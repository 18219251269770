import { createSlice } from '@reduxjs/toolkit';
import { ServiceReason } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
} from './entities';
import api from '../../constants/api';

const slice = createSlice({
  name: 'services-reasons',
  initialState: createEntitiesInitialState<ServiceReason>(),
  reducers: createDefaultEntitiesReducers<ServiceReason>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchServicesReasons = createFetchEntities(api.servicesReasons, slice.actions);
