import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { TextArea } from 'components/share/redux-form';
import FormModalDialog from 'components/share/modals/form';

const validate = (values) => {
  const error = {};

  if (!values.cause) {
    error.cause = 'Нужно указать причину';
  }

  if (values.cause && values.cause.length < 10) {
    error.cause = 'Минимальная длина сообщения 10 символов';
  }

  return error;
};

class ConfirmBlockCompany extends React.PureComponent {
  render() {
    const header = this.props.isBlocked
      ? 'Оказание услуг будет востановлено. Подтвердите разблокировку'
      : 'Оказание услуг будет приостановлено до разблокировки. Подтвердите блокировку';

    return (
      <FormModalDialog
        {...this.props}
        content={
          <React.Fragment>
            <div>{header}</div>
            <Field key={1} name={'cause'} component={TextArea} label={'Причина'} />
          </React.Fragment>
        }
      />
    );
  }
}

ConfirmBlockCompany.propTypes = {
  isBlocked: PropTypes.bool,
  onSubmit: PropTypes.func,
  trigger: PropTypes.node,
};

const shouldError = () => {
  return true;
};

let component = ConfirmBlockCompany;
component = reduxForm({ form: 'update-user-service', validate, shouldError })(component);
component = connect()(component);

export default component;
