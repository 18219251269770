import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Modal } from 'semantic-ui-react';
import { formPropTypes, reduxForm } from 'redux-form';
import { get, isFunction } from 'lodash';
import { connect } from 'react-redux';

/**
 * @deprecated
 */
class FormModalDialog extends React.Component {
  state = { open: false };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      trigger: this.prepareTrigger(this.props.trigger),
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.trigger.props !== prevProps.trigger.props) {
      this.setState({
        trigger: this.prepareTrigger(this.props.trigger),
      });
    }
  }

  prepareTrigger = (trigger) => {
    const openHandler = this.handleOpen.bind(this);

    let handleOnClick = openHandler;
    let originHandlerOnClick = get(trigger, 'props.onClick');
    if (isFunction(originHandlerOnClick)) {
      originHandlerOnClick = originHandlerOnClick.bind(trigger);

      handleOnClick = function () {
        openHandler();
        originHandlerOnClick(...arguments);
      };
    }

    const triggerPartialProps = { onClick: handleOnClick };

    return React.cloneElement(this.props.trigger, triggerPartialProps);
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false }, () => this.props.reset());
  };

  handleSubmit = (values, dispatch, props) => {
    let result;
    if (isFunction(this.props.onSubmit)) {
      result = this.props.onSubmit(values, dispatch, props);
    }

    return Promise.resolve(result).then(() => this.handleClose());
  };

  render() {
    const {
      header,
      cancelButton,
      confirmButton,

      invalid,
      content,
      handleSubmit,
    } = this.props;

    return (
      <Modal
        open={this.state.open}
        trigger={this.state.trigger}
        size={'small'}
        onClose={this.handleClose}
      >
        <Modal.Header icon="archive" content={header} />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form
                  id={'modal-form-dialog'}
                  error={invalid}
                  onSubmit={handleSubmit(this.handleSubmit)}
                >
                  {content}
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} {...cancelButton} />
          <Button primary type={'submit'} {...confirmButton} form={'modal-form-dialog'} />
        </Modal.Actions>
      </Modal>
    );
  }
}

FormModalDialog.defaultProps = {
  header: 'Подтверждение операции',
  cancelButton: {
    content: 'Отмена',
  },
  confirmButton: {
    content: 'Подтвердить',
  },
};

FormModalDialog.propTypes = {
  ...formPropTypes,
  trigger: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  header: PropTypes.string,
  cancelButton: PropTypes.object,
  confirmButton: PropTypes.object,
};

export function createConnected(props, formProps = {}) {
  function Wrapper() {
    return <FormModalDialog {...props} />;
  }

  let component;
  component = reduxForm({ form: 'confirm-form-dialog', ...formProps })(Wrapper);
  component = connect()(component);

  return component;
}

export default FormModalDialog;
