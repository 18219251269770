import React from 'react';
import { Company } from 'core/constants/contracts';
import { isEmpty } from 'lodash';
import { Image, Menu } from 'semantic-ui-react';
import locker from './locker.svg';

interface Props {
  company?: Company;
}

const BlockedCompanyMenuItem: React.FunctionComponent<Props> = ({ company }) => {
  if (undefined === company || isEmpty(company)) {
    return null;
  }

  if (company.administrative_status !== 'blocked') {
    return null;
  }

  return (
    <Menu.Item position="left" className={'company-blocked'}>
      <Image src={locker} size={'mini'} className={'locker'} />
      УК заблокирована
    </Menu.Item>
  );
};

export default BlockedCompanyMenuItem;
