export const STATUS_NEW = 'new';
export const STATUS_CREATED = 'created';
export const STATUS_ONSALE = 'onsale';
export const STATUS_ACTIVE = 'active';
export const STATUS_INACTIVE = 'inactive';
export const STATUS_DEFECTIVE = 'defective';
export const STATUS_PENDING = 'pending';

export const STATUS_MAP = {
  [STATUS_NEW]: 'Новый',
  [STATUS_CREATED]: 'Создан',
  [STATUS_ONSALE]: 'Продажа',
  [STATUS_ACTIVE]: 'Активен',
  [STATUS_INACTIVE]: 'Не активен',
  [STATUS_DEFECTIVE]: 'Дефект',
  [STATUS_PENDING]: 'Панель не подключена',
};

export const STATUS_MAP_COLOR = {
  [STATUS_NEW]: 'white',
  [STATUS_CREATED]: 'green',
  [STATUS_ONSALE]: 'orange',
  [STATUS_ACTIVE]: 'green',
  [STATUS_INACTIVE]: 'red',
  [STATUS_DEFECTIVE]: 'red',
  [STATUS_PENDING]: 'red',
};

export const DEVICE_TYPE_INTERCOM = 'intercom';
export const DEVICE_TYPE_CAMERA = 'camera';

export const DEVICE_TYPE_MAP = {
  [DEVICE_TYPE_INTERCOM]: 'Домофон',
  [DEVICE_TYPE_CAMERA]: 'Видео камера',
};
