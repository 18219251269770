import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import NewPasswordForm from '../components/new-password-form';
import '../styles.less';

export default class NewPasswordPage extends Component {
  render() {
    return (
      <div className={'content-margin-top width700 security'}>
        <Segment>
          <Header as={'h2'}>Восстановление пароля</Header>
          <NewPasswordForm />
        </Segment>
      </div>
    );
  }
}
