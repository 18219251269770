import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { restoreUserPassword } from 'core/store/actions/user';
import { connect } from 'react-redux';
import { TextInput } from 'components/share/redux-form';
import * as yup from 'yup';
import { validateBySchema } from 'core/utils/validate';

const schema = yup.object().shape({
  username: yup.string().email('Укажите валидный "E-mail"').required('Укажите "E-mail"'),
});

const validate = (values) => {
  return validateBySchema(schema, values);
};

class NewPasswordForm extends React.Component {
  handleSubmit = (values) => {
    const data = {
      username: values.username,
      source: process.env.REACT_APP_SOURCE,
    };

    return this.props
      .restoreUserPassword(data)
      .then((res) => {
        this.props.reset();
        return res;
      })
      .catch((err) => {
        console.error(err);
        throw new SubmissionError({
          username: err.message,
        });
      });
  };

  render() {
    const { handleSubmit, submitting, invalid, submitSucceeded, submitFailed, message } =
      this.props;

    return (
      <Form error={submitFailed} success={submitSucceeded} className="bg-gage">
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field
                name="username"
                component={TextInput}
                placeholder="E-mail"
                label="E-mail"
                fluid
                type="email"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign="center">
            <Grid.Column>
              <Button
                primary
                disabled={invalid || submitting}
                onClick={handleSubmit(this.handleSubmit)}
                content="Восстановить пароль"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Message success content="На указанный E-mail направлено письмо с интрукцией" />
        {message}
      </Form>
    );
  }
}

NewPasswordForm.defaultProps = {
  message: (
    <Message>
      Укажите E-mail, на который придет дальнейшая инструкция по восстановлению пароля
    </Message>
  ),
};

NewPasswordForm.propTypes = {
  restoreUserPassword: PropTypes.func,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),

  reset: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  errors: PropTypes.object,
};

const FORM_ID = 'restore-password';

const mapDispatchToProps = (dispatch) => ({
  restoreUserPassword: bindActionCreators(restoreUserPassword, dispatch),
});

let component;
component = reduxForm({ form: FORM_ID, validate })(NewPasswordForm);
component = connect(null, mapDispatchToProps)(component);

export default component;
