import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { createSelectIntercomsAggAsState } from 'core/store/selectors/intercoms';
import { wrapEntitiesTableSelectorFactory } from 'core/store/selectors';
import { clear, fetchIntercoms } from 'core/store/features/intercoms';
import { TableRedux, TableReduxFetchOptions } from 'components/share/components/table/redux';
import { Column } from 'components/share/components/table';

interface Props {
  columns: Array<Column<any>>;
  category: string;
  filter?: object;
  isScrollable?: boolean;
}

export function IntercomsTable({ columns, category, filter, isScrollable }: Props) {
  const intercomSelector = useMemo(() => createSelectIntercomsAggAsState(), []);
  const selector = useCallback(
    wrapEntitiesTableSelectorFactory(intercomSelector, {
      withActions: true,
    }),
    []
  );
  const fetcher = useCallback(
    (params: TableReduxFetchOptions) => {
      return fetchIntercoms({
        page: params.pageIndex + 1,
        limit: params.pageSize,
        filter: {
          ...filter,
          category,
        },
      });
    },
    [category, filter]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, [dispatch]);

  return (
    <TableRedux
      columns={columns}
      fetcher={fetcher}
      selector={selector}
      refreshAfterResize={5}
      isScrollable={isScrollable}
      showUpperPagination
    />
  );
}
