import React, { FC, useEffect } from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import { InputMask } from 'components/share/redux-form';
import { Icon } from 'components/share';
import { Help, Close, Plus } from 'components/share/assets';
import { Popup } from 'semantic-ui-react';
import { ButtonAdd, FieldBox, FieldContainer, LabelStyled } from './styles';
import { ButtonSimple } from 'views/pages/devices/abstract/list/filter/styles';
import styled from 'styled-components';

type PoListFormProps = WrappedFieldArrayProps & {
  readOnly?: boolean;
};

const Box = styled.div`
  height: 16px;
`;

export const PoListForm: FC<PoListFormProps> = ({ readOnly, fields }) => {
  useEffect(() => {
    if (fields.length === 0) {
      fields.push('');
    }
  }, [fields]);

  const handlePoIdAdd = () => {
    fields.push('');
  };

  const handlePoIdRemove = (index: number) => () => {
    fields.remove(index);
  };

  return (
    <FieldContainer>
      <div>
        {fields.map((item, index) => (
          <FieldBox>
            <Field
              key={index}
              name={item}
              component={InputMask}
              label={
                <LabelStyled>
                  <span>ПО</span>
                  <Popup
                    trigger={
                      <Box>
                        <Icon icon={<Help />} />
                      </Box>
                    }
                    content="Номер приложения обслуживания, состоящий из 11 цифр"
                    offset={-16}
                    inverted
                  />
                </LabelStyled>
              }
              placeholder="ПО"
              readOnly={readOnly}
              mask="99999999999"
              formatChars={{ 9: '[0-9]' }}
              width={4}
            />
            {index !== 0 && (
              <ButtonSimple onClick={handlePoIdRemove(index)}>
                <Icon icon={<Close />} />
              </ButtonSimple>
            )}
          </FieldBox>
        ))}
      </div>
      <ButtonAdd secondary onClick={handlePoIdAdd}>
        <Icon icon={<Plus />} />
        Добавить ПО
      </ButtonAdd>
    </FieldContainer>
  );
};
