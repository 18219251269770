import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { SidebarMenu as Menu } from 'mts-ui';
import { isMenuGroup, isMenuItem, MenuType } from 'core/menu/contracts';

interface Props {
  menu: Array<MenuType>;
}

function SidebarMenu(props: Props) {
  const { menu = [] as Array<MenuType> } = props;

  const preparedMenu = useMemo(() => {
    return menu.map((group) => {
      const result: Record<string, any> = {
        title: group.title,
        name: group.name,
        style: group.style,
        disabled: group.disabled,
      };

      if (isMenuGroup(group)) {
        result['icon'] = group.icon;

        if (group.items) {
          result['items'] = group.items
            .filter((v) => !!v)
            .map((item) => ({
              title: item.title,
              name: item.name,
              disabled: item.disabled,
              to: item.url,
              as: Link,
            }));
        }
      }

      if (isMenuItem(group)) {
        result['to'] = group.url;
        result['as'] = Link;
      }

      return result;
    });
  }, [menu]);

  return <Menu menu={preparedMenu} />;
}

SidebarMenu.defaultProps = {};

export default SidebarMenu;
