import { createSelector } from 'reselect';
import { Company, User } from 'core/constants/contracts';
import { getUsers } from 'core/store/selectors/users';
import { getCompaniesItems } from 'core/store/selectors/companies';

export interface UserAndCompany extends User {
  company?: Company;
}

export const selectUsersWithCompany = createSelector(
  [getUsers, getCompaniesItems],
  (users, companies) => {
    const companiesMapByClientRole = Object.values(companies).reduce((acc, v) => {
      acc.set(v.client_role, v);

      return acc;
    }, new Map() as Map<string, Company>);

    const items: Record<string, UserAndCompany> = {};
    users.ids.forEach((id) => {
      const user = users.items[id];

      let company;
      for (let i = 0; i < user.roles.length; i++) {
        const role = user.roles[i];
        if (companiesMapByClientRole.has(role)) {
          company = companiesMapByClientRole.get(role);
          break;
        }
      }

      items[id] = {
        ...user,
        company,
      };
    });

    return {
      ...users,
      items,
    };
  }
);
