import {
  DEVICE_REFERENCES_FAIL,
  DEVICE_REFERENCES_REQUEST,
  DEVICE_REFERENCES_SUCCESS,
} from '../../../constants/actions';

const defaultState = {
  items: [],
  isFetching: false,
  error: null,
  metadata: {},
};

export default function deviceReferences(state = defaultState, action) {
  switch (action.type) {
    case DEVICE_REFERENCES_REQUEST: {
      return { ...state, isFetching: true };
    }
    case DEVICE_REFERENCES_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        error: null,
        isFetching: false,
        metadata: action.payload.metadata,
      };
    }
    case DEVICE_REFERENCES_FAIL: {
      return { ...state, isFetching: false, error: action.payload.error };
    }
    default:
      break;
  }

  return state;
}
