import React, { FC } from 'react';
import { Form, Segment, SemanticFLOATS } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledSegment = styled(Segment)`
  &&&& {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const StyledFormGroup = styled(({ noIndent, ...props }) => <Form.Group {...props} />)`
  &&&& {
    margin: 0em -1.5em ${({ noIndent }: Pick<Props, 'noIndent'>) => (noIndent ? '0em' : '1em')};
  }
`;

interface Props {
  floated?: SemanticFLOATS;
  noIndent?: boolean;
}

export const FormButtonGroup: FC<Props> = ({ children, floated = 'right', noIndent }) => {
  return (
    <StyledFormGroup widths="equal" noIndent={noIndent}>
      <Form.Field>
        <StyledSegment clearing floated={floated}>
          {children}
        </StyledSegment>
      </Form.Field>
    </StyledFormGroup>
  );
};
