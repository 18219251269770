import { createSlice } from '@reduxjs/toolkit';
import { Device } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createFetchEntity,
  createSaveEntity,
} from './entities';
import api from '../../constants/api';

const slice = createSlice({
  name: 'devices',
  initialState: createEntitiesInitialState<Device>(),
  reducers: createDefaultEntitiesReducers<Device>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchDevices = createFetchEntities(api.device(), slice.actions);
export const fetchDevice = createFetchEntity(api.device, slice.actions);
export const saveDevice = createSaveEntity<Device>(api.device, slice.actions);
