import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Field, getFormSyncErrors, reduxForm, SubmissionError } from 'redux-form';
import { Button, Dimmer, Form, Grid, Loader, Message } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { get, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { TextInput } from 'components/share/redux-form';
import { getToken, resetPassword } from 'core/store/actions/token';
import RestorePasswordForm from '../new-password-form';

const validate = (values) => {
  let errors = {};

  if (!values.password) {
    errors.password = 'Необходимо указать новый пароль';
  }

  if (!values.password_repeat) {
    errors.password_repeat = 'Необходимо повторить пароль';
  }

  if (values.password && values.password_repeat && values.password !== values.password_repeat) {
    errors.password = 'Пароли не совпадают';
  }

  return errors;
};

export class ResetPasswordForm extends Component {
  componentDidMount() {
    this.props.getToken(get(this.props, 'match.params.id'));
  }

  handleSubmit = async (values) => {
    if (isEmpty(values)) {
      const errors = validate(values);
      if (false === isEmpty(errors)) {
        throw new SubmissionError(errors);
      }
    }

    await this.resetPassword(values);
  };

  resetPassword = async (values) => {
    try {
      await this.props.resetPassword(get(this.props, 'match.params.id'), values.password);
    } catch (e) {
      throw new SubmissionError(e);
    }
  };

  render() {
    const { handleSubmit, submitting, invalid, token, isFetching, errorResponse } = this.props;

    if (isFetching) {
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
    }

    let message;

    if (errorResponse) {
      message = (
        <Message negative>
          <Message.Header>Ошибка при установке пароля</Message.Header>
          <p>
            Код установки не найден или возникла ошибка сервера. Попробуйте повторить попытку позже
          </p>
        </Message>
      );
    }

    if (token) {
      if (token.is_expired) {
        message = (
          <Message negative>
            <Message.Header>
              Срок действия ссылки истек или она больше недействительна
            </Message.Header>
            {/* ссылкиукажите - так было УТВЕРЖДЕННО в figma */}
            <p>
              Для повторного получения ссылкиукажите e-mail, на который придет дальнейшая инструкция
            </p>
          </Message>
        );

        return <RestorePasswordForm message={message} />;
      }

      if (token.is_verified) {
        message = (
          <Message negative>
            <Message.Header>Данной ссылкой уже воспользователись</Message.Header>
            <p>Для восстановления пароля или создания нового, перейдите в Личный кабинет УК</p>
          </Message>
        );
      }

      if (token.is_verified) {
        message = (
          <Message positive>
            <Message.Header>Пароль успешно изменен</Message.Header>
            <p>
              <Link to={'/sign-in'}>Авторизоваться</Link> в системе.
            </p>
          </Message>
        );
      }
    }

    return (
      <Form onSubmit={handleSubmit(this.handleSubmit)} error={invalid} className={'bg-gage'}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={'password'}
                component={TextInput}
                required
                label={'Новый пароль'}
                type={'password'}
                labelWidth={3}
                controlProps={{ required: false }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Field
                name={'password_repeat'}
                component={TextInput}
                required
                label={'Повторите пароль'}
                type={'password'}
                labelWidth={3}
                controlProps={{ required: false }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row textAlign={'center'}>
            <Grid.Column>
              <Button
                primary
                type={'submit'}
                disabled={invalid || submitting}
                content={'Изменить'}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {message}
      </Form>
    );
  }
}

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  errors: PropTypes.object,
  errorResponse: PropTypes.string,
  token: PropTypes.object,
  invalid: PropTypes.bool,
  resetPassword: PropTypes.func,
  getToken: PropTypes.func,
  header: PropTypes.string,
  isFetching: PropTypes.bool,
};

const shouldError = () => {
  return true;
};

const mapStateToProps = (state) => {
  return {
    errors: getFormSyncErrors('user-reset-password')(state),
    token: state.app.token.token,
    isFetching: state.app.token.isFetching || false,
    errorResponse: state.app.token.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: bindActionCreators(resetPassword, dispatch),
  getToken: bindActionCreators(getToken, dispatch),
});

let component = ResetPasswordForm;
component = withRouter(component);
component = reduxForm({ form: 'reset-password', validate, shouldError })(component);

export default connect(mapStateToProps, mapDispatchToProps)(component);
