import React, { FC, useEffect } from "react";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";

import { RootState } from "core/store";
import { fetchCompanies, clear as clearCompanies } from "core/store/features/companies";
import { connect, ConnectedProps } from "react-redux";
import { Formik } from "formik";
import { TableFilterFormData, FormFields } from "./contracts";
import { removeEmptyValues } from "core/utils/forms";
import FormInner from "./form";

type ReduxStoreProps = ConnectedProps<typeof withConnect>;
interface TableFilterProps extends ReduxStoreProps {
  defaultValue: TableFilterFormData;
  filters: TableFilterFormData;
  setFilters: React.Dispatch<React.SetStateAction<TableFilterFormData>>;
  fields: FormFields[];
}

const TableFilter: FC<TableFilterProps> = ({
  fetchCompanies, clearCompanies, defaultValue, filters, setFilters, fields, companies, loadingCompanies
}) => {
  useEffect(() => {
    fetchCompanies({ limit: 3000 });
    
    return () => {
      clearCompanies();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: TableFilterFormData) => {
    const sanitation = removeEmptyValues(data);
    if(Object.keys(sanitation).length > 0) {
      setFilters({
        ...filters,
        ...sanitation,
      })
    } else {
      setFilters({ ...defaultValue });
    }
  }

  return (
    <Formik<TableFilterFormData> initialValues={defaultValue} onSubmit={onSubmit}>
      {(formik) => <FormInner companiesStore={{ companies, loadingCompanies }} fields={fields} {...formik}/>}
    </Formik>
  )

}

const mapStateToProps = (state: RootState) => {
const companiesMap = state.entities.companies.items;
const companiesOptions = Object.keys(companiesMap).map((key) => ({
  key,
  value: companiesMap[key].id,
  text: companiesMap[key].name,
}));

return {
    companies: companiesOptions,
    loadingCompanies: state.entities.companies.pending,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
return {
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    clearCompanies: bindActionCreators(clearCompanies, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(TableFilter);