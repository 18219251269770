import React, { FC, useEffect, useState } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { Dimmer, Loader, Grid, Icon, Button, Form } from 'semantic-ui-react';
import { TextInput, Dropdown, RadioGroup } from 'components/share/redux-form';
import { FormButtonGroup } from 'components/share/form';
import { request } from 'core/utils/request';
import api from 'core/constants/api';
import notification from 'core/utils/notification';
import { parseUrl, urlBuilder, UrlType } from 'core/utils/url';
import { StreamInfo, StreamInfoError, CameraStreamMode } from 'core/constants/contracts';
import { isAxiosError } from 'core/utils/webapi.handlers';
import { cameraProtocolOptions, timezoneOptions, errorsDictionary, ErrorType } from './config';
import {
  SegmentStyled,
  FieldStyled15,
  FieldStyled50,
  ButtonStyled,
  ColumnStyled,
  FromGroupStyled,
} from './styles';

export interface URL {
  protocol: string;
  hostname: string;
  port?: string | number;
  path?: string;
  username?: string;
  password?: string;
}

export interface CameraFormData extends URL {
  name?: string;
  url: string;
  timezone?: string;
  address?: string;
  owner?: string;
  stream_mode: CameraStreamMode;
  rtsp: string;
}

interface CameraFormProps {
  formProps: Pick<InjectedFormProps<CameraFormData>, 'invalid' | 'change' | 'anyTouched'>;
  formData: {
    url: UrlType;
    urlString: string;
    address?: string;
    owner?: string;
    streamMode?: string;
    rtsp?: string;
  };
  isFetching: boolean;
  isAdmin: boolean;
  setIsFetching: (isFetching: boolean) => void;
  handleCancel: (isChanged: boolean) => void;
  handleSubmit: () => void;
  validateFields: () => void;
  handleGetStream?: () => void;
}

function isStreamInfoError(arg: StreamInfo): arg is StreamInfoError {
  return (arg as StreamInfoError).error !== undefined;
}

export const CameraForm: FC<CameraFormProps> = ({
  formProps: { invalid, change, anyTouched },
  formData: { url, urlString, address, owner, streamMode, rtsp },
  isFetching,
  isAdmin,
  setIsFetching,
  handleCancel,
  handleSubmit,
  validateFields,
  handleGetStream,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isStreamVerified, setIsStreamVerified] = useState(true);
  const [useRestreamer, setUseRestreamer] = useState(false);

  useEffect(() => {
    if (streamMode) setUseRestreamer(streamMode === CameraStreamMode.restreamer);
  }, [streamMode]);

  const checkStream = async () => {
    validateFields();
    let url: string | undefined;

    if (useRestreamer) {
      if (!rtsp || invalid) return;
      url = rtsp;
    } else {
      if (!urlString || invalid) return;
      url = urlString;
    }

    setIsFetching(true);

    try {
      const { data: checkResult } = await request.get<StreamInfo>(
        api.checkCameraStream(encodeURIComponent(url))
      );

      if (isStreamInfoError(checkResult)) {
        const error = checkResult.error;
        notifyAxiosError(error);
        setIsStreamVerified(false);
      } else {
        const hasVideoStream = checkResult.streams.some((item) => item.codec_type === 'video');

        if (hasVideoStream) {
          notification.success('Проверка видеопотока пройдена успешно');
          setIsStreamVerified(true);
        } else {
          notification.error('На устройстве отсутствует видеопоток');
          setIsStreamVerified(false);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        if (isAxiosError<StreamInfoError>(e) && e.response) {
          const error = e.response.data.error;

          notifyAxiosError(error);
        } else {
          notification.error('Произошла непредвиденная ошибка');
        }
      }
      setIsStreamVerified(false);
    } finally {
      setIsFetching(false);
    }
  };

  const notifyAxiosError = (error: StreamInfoError['error']) => {
    if (error.code in errorsDictionary) {
      notification.error(errorsDictionary[error.code as unknown as ErrorType]);
    } else {
      notification.error(`${error.code}: ${error.title}`);
    }
  };

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const urlStringOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsStreamVerified(false);
    const parseResult = parseUrl(event as unknown as string);
    function changeURLObj(url?: UrlType) {
      change('protocol', url?.protocol || '');
      change('username', url?.username || '');
      change('password', url?.password || '');
      change('hostname', url?.hostname || '');
      change('port', url?.port || '');
      change('path', url?.path || '');
    }

    if (parseResult) {
      changeURLObj(parseResult);
    } else {
      changeURLObj();
    }

    return event;
  };

  const urlPartsOnChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsStreamVerified(false);
    const newURL = { ...url, [name]: event };
    change('url', urlBuilder(newURL));
    return event;
  };

  const handleCancelInner = () => {
    handleCancel(anyTouched);
  };

  const handleVideoStreamType = (event: React.ChangeEvent<HTMLInputElement>) => {
    (event as unknown as string) === CameraStreamMode.restreamer
      ? setUseRestreamer(true)
      : setUseRestreamer(false);
    setIsStreamVerified(false);
  };

  return (
    <SegmentStyled padded>
      <Form error={invalid}>
        <Dimmer active={isFetching} inverted>
          <Loader />
        </Dimmer>

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form.Group widths="2">
                <Field
                  name="stream_mode"
                  component={RadioGroup}
                  disabled={!isAdmin}
                  label="Источник видеопотока"
                  items={[
                    { name: 'Камера', value: CameraStreamMode.camera },
                    { name: 'Рестример', value: CameraStreamMode.restreamer },
                  ]}
                  onChange={handleVideoStreamType}
                />
              </Form.Group>

              {useRestreamer && (
                <Grid.Row>
                  <Grid.Column>
                    <FromGroupStyled widths="2">
                      <Field
                        name="rtsp"
                        component={TextInput}
                        label="Ссылка на поток рестримера"
                        required
                        disabled
                      />
                      {!rtsp && (
                        <ButtonStyled onClick={handleGetStream}>
                          Получить поток рестримера
                        </ButtonStyled>
                      )}
                    </FromGroupStyled>
                  </Grid.Column>
                </Grid.Row>
              )}

              <Form.Group widths="3">
                <Field
                  name="protocol"
                  required
                  component={Dropdown}
                  label="Протокол"
                  placeholder="Протокол"
                  options={cameraProtocolOptions}
                  clearable
                  onChange={urlPartsOnChange('protocol')}
                />
                <Field
                  name="username"
                  component={TextInput}
                  label="Логин"
                  onChange={urlPartsOnChange('username')}
                />
                <Field
                  name="password"
                  component={TextInput}
                  label="Пароль"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  icon={
                    <Icon
                      name={showPassword ? 'eye slash' : 'eye'}
                      link
                      onClick={showPasswordHandler}
                    />
                  }
                  onChange={urlPartsOnChange('password')}
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Group widths="3">
                <Field
                  name="hostname"
                  component={TextInput}
                  label="Hostname"
                  required
                  onChange={urlPartsOnChange('hostname')}
                />
                <FieldStyled15
                  name="port"
                  component={TextInput}
                  props={{ label: 'Порт' }}
                  onChange={urlPartsOnChange('port')}
                />
                <FieldStyled50
                  name="path"
                  component={TextInput}
                  props={{ label: 'Относительный путь' }}
                  onChange={urlPartsOnChange('path')}
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Form.Group widths="3">
                <FieldStyled50
                  name="url"
                  component={TextInput}
                  props={{ label: 'URL', required: true }}
                  onChange={urlStringOnChange}
                />
                <FieldStyled15
                  name="timezone"
                  component={Dropdown}
                  props={{
                    label: 'Таймзона',
                    placeholder: 'Таймзона',
                    options: timezoneOptions,
                    clearable: true,
                  }}
                />
                <Field name="name" required component={TextInput} label="Имя камеры" />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>

          {(address || owner) && (
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="2">
                  {address && <Field name="address" component={TextInput} label="Адрес" disabled />}
                  {owner && <Field name="owner" component={TextInput} label="Владелец" disabled />}
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            <ColumnStyled>
              <ButtonStyled onClick={checkStream}>Проверить поток</ButtonStyled>
              <FormButtonGroup>
                <Button secondary onClick={handleCancelInner}>
                  Отменить
                </Button>
                <Button
                  primary
                  icon
                  labelPosition="right"
                  floated="right"
                  disabled={invalid || !isStreamVerified}
                  onClick={handleSubmit}
                >
                  <Icon name="save" />
                  Сохранить
                </Button>
              </FormButtonGroup>
            </ColumnStyled>
          </Grid.Row>
        </Grid>
      </Form>
    </SegmentStyled>
  );
};
