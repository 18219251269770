import * as React from 'react';
import { request } from 'core/utils/request';
import { WebAPIMetadata } from 'core/utils/webapi.contracts';
import get from 'lodash/get';

export interface QueryParams {
  [field: string]: any;
}

export interface ResponseResult {
  isFetching: boolean;
  error: string;
  items: Array<any>;
  metadata: WebAPIMetadata;
}

export interface FetchProps {
  fetchData: (queryParams?: QueryParams) => void;
}

export function withSubscription(
  WrappedComponent: React.ComponentType<any>,
  apiEndpoint: string
): React.ComponentType {
  return class extends React.Component<any, ResponseResult> {
    state = {
      isFetching: false,
      error: '',
      items: [],
      metadata: {
        total: 0,
        perPage: 0,
        pageCount: 0,
        page: 0,
      },
    };

    fetchData = async (queryParams?: QueryParams) => {
      try {
        this.setState({ isFetching: true });
        const response = await request.get(apiEndpoint, { params: queryParams });
        this.setState({
          items: get(response, 'data.list', []),
          metadata: get(response, 'data.metadata', {}),
          isFetching: false,
          error: '',
        });
      } catch (e) {
        this.setState({ isFetching: false, error: e.message });
      }
    };

    render() {
      const data = { ...this.state };

      return <WrappedComponent {...this.props} fetchData={this.fetchData} data={data} />;
    }
  };
}
