import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { onDelete, onFailed, onSuccess } from './handlers';
import { Action, Entity, Meta } from './contracts';
import { ApiThunk, PromiseThunk, RootState } from '../..';
import {
  DEFAULT_META_MULTIPLE,
  DEFAULT_META_SINGLE,
  EntityActionPayload,
  EntityClearActionPayload,
} from '.';
import {
  DefaultActionFetchParams,
  WebAPIActionFetchParams,
  WebAPIResponse,
} from '../../../utils/webapi.contracts';
import { dataFromAxios } from '../../../utils/webapi.handlers';
import { WSOperationResponseData } from '../../../utils/ws.contracts';
import { request, sortToString, uniqid } from '../../../utils/request';
import OperationManager from '../../../utils/operation';

/***********************************************************
 *
 *            Default actions implementations
 *
 **********************************************************/

export interface SuccessActions {
  pending: any;
  success: any;
  failed: any;
}

export interface DeleteActions {
  pending: any;
  deleted: any;
  failed: any;
}

export interface ActionActions {
  action: (payload: EntityActionPayload) => PayloadAction<EntityActionPayload>;
  clearAction: (payload: EntityClearActionPayload) => PayloadAction<EntityClearActionPayload>;
  success: any;
  failed: any;
}

export const createDeleteEntity =
  (url: (id: string) => string, actions: DeleteActions) =>
  (id: string, meta?: Meta): PromiseThunk<string> => {
    return (dispatch: Dispatch) => {
      dispatch(actions.pending(meta));

      return request.delete(url(id)).then(
        () => onDelete(dispatch, actions.deleted, id),
        (err) => onFailed(dispatch, actions.failed, err)
      );
    };
  };

export const createCreateEntity =
  <T, D = Partial<T>>(url: string, actions: SuccessActions) =>
  (data: D, meta?: Meta): ApiThunk<T> => {
    return (dispatch: Dispatch) => {
      dispatch(actions.pending(meta));

      return request.post(url, data).then(
        (res) => onSuccess(dispatch, actions.success, res, meta),
        (err) => onFailed(dispatch, actions.failed, err)
      );
    };
  };

export const createUpdateEntity =
  <T, D = Partial<T>>(url: (id: string) => string, actions: SuccessActions) =>
  (id: string, data: D, meta?: Meta): ApiThunk<T> => {
    return (dispatch: Dispatch) => {
      dispatch(actions.pending(meta));

      return request.put(url(id), data).then(
        (res) => onSuccess(dispatch, actions.success, res, meta),
        (err) => onFailed(dispatch, actions.failed, err)
      );
    };
  };

export const createUpdateEntityWithCustomUrl =
  <T, D = Partial<T>>(url: (args: any) => string, actions: SuccessActions) =>
  (args: any, data: D, meta?: Meta): ApiThunk<T> => {
    return (dispatch: Dispatch) => {
      dispatch(actions.pending(meta));

      return request.post(url(args), data).then(
        (res) => onSuccess(dispatch, actions.success, res, meta),
        (err) => onFailed(dispatch, actions.failed, err)
      );
    };
  };

export const createDeleteEntityWithCustomUrl =
  <T, D = Partial<T>>(url: (args: any) => string, actions: SuccessActions) =>
  (args: any, meta?: Meta): ApiThunk<T> => {
    return (dispatch: Dispatch) => {
      dispatch(actions.pending(meta));

      return request.delete(url(args)).then(
        (res) => onSuccess(dispatch, actions.success, res, meta),
        (err) => onFailed(dispatch, actions.failed, err)
      );
    };
  };

export const createUpdateWithPostEntity =
  <T, D = Partial<T>>(url: (id: string) => string, actions: SuccessActions) =>
  (id: string, data: D, meta?: Meta): ApiThunk<T> => {
    return (dispatch: Dispatch) => {
      dispatch(actions.pending(meta));

      return request.post(url(id), data).then(
        (res) => onSuccess(dispatch, actions.success, res, meta),
        (err) => onFailed(dispatch, actions.failed, err)
      );
    };
  };

export const createSaveEntity =
  <T, D extends Partial<Entity> = Partial<T>>(
    url: (id?: string) => string,
    actions: SuccessActions
  ) =>
  (data: D, meta?: Meta): ApiThunk<T> => {
    return (dispatch: Dispatch) => {
      dispatch(actions.pending(meta));

      const id = data.id;
      const method = id ? request.put : request.post;

      return method(url(id), data).then(
        (res) => onSuccess(dispatch, actions.success, res, meta),
        (err) => onFailed(dispatch, actions.failed, err)
      );
    };
  };

export interface EntitySelector<T> {
  (state: RootState, props: { id: string }): Nullable<T>;
}

// TODO избавится от any
export const createFetchEntity =
  <T>(url: (id: string) => string, actions: SuccessActions, selector?: EntitySelector<T>) =>
  (id: string, meta?: Meta): ApiThunk<T> => {
    return (dispatch: Dispatch, getState) => {
      if (selector) {
        const entity = selector!(getState(), { id });
        if (entity) {
          const res: WebAPIResponse<T> = {
            error: null,
            list: [entity],
            metadata: {
              page: 1,
              pageCount: 1,
              perPage: 1,
              total: 1,
            },
          };

          return Promise.resolve(res);
        }
      }

      dispatch(actions.pending(meta));

      return request.get(url(id)).then(
        (res) => onSuccess(dispatch, actions.success, res, meta),
        (err) => onFailed(dispatch, actions.failed, err)
      );
    };
  };

// TODO избавится от any
export const createFetchEntityById =
  <T>(url: (id: string) => string, actions: SuccessActions) =>
  (id: string, meta?: Meta): ApiThunk<T> => {
    return (dispatch: Dispatch) => {
      meta = { ...DEFAULT_META_MULTIPLE, ...meta };

      dispatch(actions.pending(meta));

      return request.get(url(id)).then(
        (res) => onSuccess(dispatch, actions.success, res, meta),
        (err) => onFailed(dispatch, actions.failed, err)
      );
    };
  };

// TODO избавится от any
export const createFetchEntities =
  <T>(url: string, actions: SuccessActions) =>
  (options: WebAPIActionFetchParams = DefaultActionFetchParams, meta?: Meta): ApiThunk<T> => {
    return (dispatch: Dispatch) => {
      meta = { ...DEFAULT_META_MULTIPLE, ...meta };

      dispatch(actions.pending(meta));

      const { limit, page, sort, filter, ...rest } = options;

      const query: Record<string, any> = {
        limit,
        page,
        sort: sort && sortToString(sort),
        ...filter,
        ...rest,
      };

      return request.get(url, { params: query }).then(
        (res) => onSuccess(dispatch, actions.success, res, meta),
        (err) => onFailed(dispatch, actions.failed, err)
      );
    };
  };

interface CreateOperationOptions {
  url: (id: string) => string;
  actionName: string;
  actions: ActionActions;
  autoClear?: boolean;
  timeout?: number;
  handlerCreator?: (
    operationId: string,
    entityId: string,
    options: CreateOperationOptions
  ) => (data: any) => void;
  withSuccess?: boolean;
}

export const createActionOperationEntity =
  <T>(options: CreateOperationOptions) =>
  (entityId: string, data?: Partial<T>, meta?: Meta): ApiThunk<T> => {
    const {
      url: urlBuilder,
      actionName,
      actions,
      autoClear = false,
      timeout,
      withSuccess = false,
    } = options;

    return (dispatch: Dispatch) => {
      meta = { ...DEFAULT_META_SINGLE, ...meta };

      dispatch(
        actions.action({
          id: entityId,
          name: actionName,
          action: {
            status: 'unknown',
            pending: true,
          },
        })
      );

      const operationId = uniqid();
      const url = urlBuilder(entityId);
      const config = {
        headers: {
          'X-Operation-ID': operationId,
        },
      };

      return request.post(url, data, config).then(
        (res) => {
          const handler = options.handlerCreator
            ? options.handlerCreator(operationId, entityId, options)
            : (data?: WSOperationResponseData<any>) => {
                let error;
                if (data?.data) {
                  error =
                    data.data.code === 'failed' || data.data.code === 'internal_error'
                      ? data.data.message ?? 'Unknown error'
                      : undefined;
                } else {
                  error = 'Invalid response';
                }

                const actionData: Action = {
                  pending: false,
                  status: error ? 'failed' : 'success',
                  error: error,
                };

                dispatch(
                  actions.action({
                    id: entityId,
                    name: actionName,
                    action: actionData,
                  })
                );

                if (autoClear) {
                  dispatch(
                    actions.clearAction({
                      id: entityId,
                      name: actionName,
                      unset: true,
                    })
                  );
                }
              };
          OperationManager.register(operationId, handler, timeout);

          return withSuccess
            ? onSuccess<T>(dispatch, actions.success, res, meta)
            : dataFromAxios<T>(res);
        },
        (err) => onFailed(dispatch, actions.failed, err)
      );
    };
  };
