import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Confirm } from 'semantic-ui-react';

class ConfirmDialog extends Component {
  state = {
    open: this.props.open || false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({
        open: nextProps.open,
      });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.onClose();

    if (this.props.closeCallback) {
      this.props.closeCallback();
    }
  };

  handleConfirm = (e, item) => {
    if (this.props.confirmCallback) {
      this.props.confirmCallback();
    }

    this.setState({ open: false });
    this.props.onClose();
  };

  render() {
    const { open } = this.state;

    const cancelButton = {
      secondary: true,
      content: this.props.cancelButton ? this.props.cancelButton : 'Отменить',
    };

    const message = this.props.content
      ? this.props.content
      : 'Вы уверены что хотите совершить данное действие?';

    const content =
      typeof message === 'string' ? message : <Modal.Content>{message}</Modal.Content>;

    return (
      <Confirm
        open={open}
        header={this.props.header ? this.props.header : 'Подтверждение'}
        content={content}
        onCancel={this.handleClose}
        onConfirm={this.handleConfirm}
        cancelButton={cancelButton}
        confirmButton={this.props.confirmButton ? this.props.confirmButton : 'Подтвердить'}
      />
    );
  }
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  closeCallback: PropTypes.func,
  confirmCallback: PropTypes.func,
  header: PropTypes.any,
  content: PropTypes.any,
  onClose: PropTypes.func,
  cancelButton: PropTypes.string,
  confirmButton: PropTypes.string,
};

export default ConfirmDialog;
