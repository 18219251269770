import React, { ComponentType, ReactElement, useCallback, useState } from 'react';
import { Button, ButtonProps, Grid, Header, Icon, Segment, SemanticICONS } from 'semantic-ui-react';
import styled from 'styled-components';
import cx from 'classnames';
import { withStaticProperties } from 'core/utils/react';
import { CardItem } from './item';
import { CardDelimiter } from './delimiter';

const HeaderComponent = (props: WithClassNameProps<{ title: string }>) => (
  <Header as={'h4'} floated={'left'} className={cx('font-weight-medium', props.className)}>
    {props.title}
  </Header>
);

const HeaderStyled = styled(HeaderComponent)`
  &&& {
    float: none;
    display: inline;
    margin-bottom: 0;
    line-height: 44px; // как высота кнопки
  }
`;

const GridColumnStyled = styled(Grid.Column)`
  &&&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export interface Props {
  className?: string;
  title?: string;
  onEdit?: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void;
  buttonProps?: ButtonProps;
  actions?: ReactElement;
}

const CardComponent: ComponentType<Props> = (props) => {
  const { title, onEdit, buttonProps, actions, className } = props;

  const [isEdit, setEdit] = useState(false);
  const handleEditClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
      setEdit(!isEdit);
      onEdit && onEdit(e, { ...data, isEdit: !isEdit });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [isEdit, onEdit]
  );

  return (
    <Segment padded={'very'} className={cx('block', className)}>
      <Grid>
        {title && (
          <Grid.Row columns={'equal'} verticalAlign={'middle'}>
            <GridColumnStyled>
              <HeaderStyled title={title} />
              {onEdit && (
                <Button icon onClick={handleEditClick} className={'edit-button'} {...buttonProps}>
                  <Icon name={'mts-icon-edit' as SemanticICONS} />
                </Button>
              )}
            </GridColumnStyled>

            {actions && <Grid.Column textAlign="right">{actions}</Grid.Column>}
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>{props.children}</Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export const Card = withStaticProperties(CardComponent, {
  Item: CardItem,
  Delimiter: CardDelimiter,
});
