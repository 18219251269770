import { PromiseThunk } from 'core/store';
import { fetchIntercom } from 'core/store/features/intercoms';
import { fetchCamera } from 'core/store/features/cameras';
import { startSectionLoading, stopSectionLoading } from 'core/store/features/app';

interface Params {
  intercomId: string;
  cameraId: string;
}

export const SECTION_LOADING_NAME = 'view_camera';

export const fetchCameraData = ({ intercomId, cameraId }: Params): PromiseThunk => {
  return (dispatch) => {
    dispatch(startSectionLoading(SECTION_LOADING_NAME));

    const promises = [
      dispatch(fetchIntercom(intercomId)),
      dispatch(fetchCamera(cameraId)),
    ] as const;

    return Promise.all(promises).finally(() => dispatch(stopSectionLoading(SECTION_LOADING_NAME)));
  };
};
