import React, { FC, useEffect, useState } from 'react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { Grid, Header } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { Intercom, CameraStreamMode } from 'core/constants/contracts';
import { Loader } from 'components/share';
import { RouteComponentProps } from 'react-router-dom';
import { reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';
import { validateBySchema } from 'core/utils/validate';
import { UrlType } from 'core/utils/url';
import { CameraForm, CameraFormData, schema } from 'components/share/containers/camera';
import { confirm } from 'components/share/dialogs';
import { saveCamera } from 'core/store/features/cameras';
import notification from 'core/utils/notification';
import { clear as clearIntercoms, fetchIntercom } from 'core/store/features/intercoms';
import _ from 'lodash';
import { DataForCamera } from 'components/share/containers/camera/form/config';

type ConnectProps = ConnectedProps<typeof withConnect>;

type RouteParams = {
  intercomId: string;
};

interface CameraCreateProps
  extends ConnectProps,
    RouteComponentProps<RouteParams>,
    InjectedFormProps<CameraFormData, CameraCreateProps> {}

const validate = (values: CameraFormData) => {
  return validateBySchema(schema, values);
};

const CameraCreate: FC<CameraCreateProps> = ({
  intercom,
  isIntercomLoading,
  formData,
  invalid,
  anyTouched,
  history,
  match,
  isAdmin,
  change,
  handleSubmit,
  fetchIntercom,
  createCamera,
  clearIntercoms,
}) => {
  const { intercomId } = match.params;
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (intercomId) {
      fetchIntercom(intercomId);
    }

    return () => {
      clearIntercoms();
    };
  }, [intercomId, clearIntercoms, fetchIntercom]);

  const changeIsFetching = (isFetching: boolean) => setIsFetching(isFetching);

  const routeToIntercom = () => history.push(`/intercoms/${intercomId}`);

  const handleCancel = () => {
    confirm({
      content: 'Вы уверены, что хотите отменить создание камеры?',
      onConfirm: routeToIntercom,
    });
  };

  const handleSubmitForm = async (values: CameraFormData) => {
    const { name, timezone, protocol, username, password, hostname, port, path, stream_mode } =
      values;

    confirm({
      content: 'Вы уверены, что хотите сохранить данные камеры?',
      onConfirm: async () => {
        setIsFetching(true);
        let message;

        const data: DataForCamera = {
          parent: intercom?.device.id,
          stream: {
            protocol,
            username,
            password,
            hostname,
            port: port ? +port : undefined,
            path,
          },
          stream_mode,
          name,
          device: {
            timezone: timezone === '' ? null : timezone,
          },
        };

        try {
          const cameraResult = await createCamera(data);
          message = _.get(cameraResult, 'error');
        } catch (e) {
          console.error(e);
          message = e.message;
        } finally {
          setIsFetching(false);
        }

        if (message) {
          notification.error(message);
        } else {
          notification.success();
          setTimeout(routeToIntercom, 1000);
        }
      },
    });
  };

  const handleValidate = (values: CameraFormData) => {
    validate(values);
  };

  if (isIntercomLoading) {
    return <Loader active={true} placeholder={true} />;
  }

  return (
    <Grid>
      <SetBreadcrumb url={'/intercoms'}>Домофоны</SetBreadcrumb>
      <SetBreadcrumb url={`/intercoms/${intercomId}`}>{intercom?.phone || 'Домофон'}</SetBreadcrumb>
      <SetBreadcrumb>Добавить камеру</SetBreadcrumb>

      <Grid.Row>
        <Grid.Column>
          <Header as="h1">Добавить камеру</Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <CameraForm
            formProps={{ invalid, change, anyTouched }}
            formData={formData}
            isFetching={isFetching}
            setIsFetching={changeIsFetching}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit(handleSubmitForm)}
            validateFields={handleSubmit(handleValidate)}
            isAdmin={isAdmin}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const FORM_ID = 'create_camera';
const selector = formValueSelector(FORM_ID);

const CameraCreateReduxForm = reduxForm<CameraFormData, CameraCreateProps>({
  form: FORM_ID,
  validate,
})(CameraCreate);

const mapStateToProps = (state: RootState, { match }: RouteComponentProps<RouteParams>) => {
  const { intercomId } = match.params;

  const url: UrlType = selector(
    state,
    'protocol',
    'hostname',
    'port',
    'path',
    'username',
    'password'
  );
  const urlString: string = selector(state, 'url');

  return {
    formData: {
      url,
      urlString,
    },
    intercom: state.entities.intercoms.items[intercomId] as Intercom | undefined,
    isIntercomLoading: state.entities.intercoms.pending,
    initialValues: {
      protocol: 'rtsp',
      timezone: '+03:00',
      stream_mode: CameraStreamMode.camera,
    } as Partial<CameraFormData>,
    isAdmin: false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchIntercom: bindActionCreators(fetchIntercom, dispatch),
    createCamera: bindActionCreators(saveCamera, dispatch),
    clearIntercoms: bindActionCreators(clearIntercoms, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(CameraCreateReduxForm);
