import * as yup from 'yup';

export const schema = yup.object().shape({
  protocol: yup.string().required('Укажите Протокол'),
  hostname: yup.string().required('Укажите hostname'),
  username: yup.string().required('Укажите Логин'),
  password: yup.string().required('Укажите Пароль'),
  port: yup.number().min(1).max(65535).typeError('Значение должно быть числом').required('Укажите RTSP Порт'),
  reference: yup.string().required('Укажите Поставщика оборудования'),
  owner: yup.string().required('Укажите Компанию владельца'),
});
