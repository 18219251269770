import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import TextInput from 'components/share/redux-form/text-input';
import Dropdown from 'components/share/redux-form/dropdown';
import { Field } from 'redux-form';

const Index = ({
  commercialStatuses,
  showForis,
  contractorOptions,
  handleChangeType,
  isEditForm,
}) => {
  return (
    <React.Fragment>
      <Form.Group widths="3">
        {!isEditForm && (
          <Field
            component={Dropdown}
            name="person_type"
            label="Тип контрагента"
            options={contractorOptions}
            onChange={handleChangeType}
          />
        )}
        <Field
          component={TextInput}
          name="personal_account"
          label="Лицевой счет"
          required={!isEditForm}
        />
      </Form.Group>
      <Form.Group widths="3">
        <Field
          name="last_name"
          component={TextInput}
          label="Фамилия"
          required
          controlProps={{ required: false }}
        />
        <Field
          name="first_name"
          component={TextInput}
          label="Имя"
          required
          controlProps={{ required: false }}
        />
        <Field name="mid_name" component={TextInput} label="Отчество" />
      </Form.Group>
      <Form.Group widths="3">
        <Field
          name="address"
          component={TextInput}
          label="Адрес"
          required
          controlProps={{ required: false }}
        />
        <Field name="inn" component={TextInput} label="ИНН" />
      </Form.Group>
      <Form.Group widths="3">
        <Field
          name="commercial_status"
          component={Dropdown}
          label="Коммерческий статус"
          required
          options={commercialStatuses}
        />
        {showForis && <Field name="foris" component={TextInput} label="Foris ID" />}
      </Form.Group>
    </React.Fragment>
  );
};

Index.propTypes = {
  field: PropTypes.func,
  showForis: PropTypes.bool,
  administrativeStatuses: PropTypes.array,
  commercialStatuses: PropTypes.array,
};

export default Index;
