export type ColorsType = { [key in ColorsEnum]: string };

export enum ColorsEnum {
  aliceBlue = 'aliceBlue',
  black = 'black',
  blueberryDark = 'blueberryDark',
  cheatas = 'cheatas',
  darkApple = 'darkApple',
  darkBlueberry = 'darkBlueberry',
  darkestCranberry = 'darkestCranberry',
  darkestLime = 'darkestLime',
  darkestMint = 'darkestMint',
  darkestYellow = 'darkestYellow',
  darkLime = 'darkLime',
  darkMint = 'darkMint',
  darkOrange = 'darkOrange',
  darkPlum = 'darkPlum',
  darkYellow = 'darkYellow',
  deepBlue = 'deepBlue',
  greyChateau = 'greyChateau',
  greyHeather = 'greyHeather',
  greyRaven = 'greyRaven',
  inactiveTabbar = 'inactiveTabbar',
  lavender = 'lavender',
  lightApple = 'lightApple',
  lightBlack = 'lightBlack',
  lightBlackberry = 'lightBlackberry',
  lightBlueberry = 'lightBlueberry',
  lightCranberry = 'lightCranberry',
  lightestYellow = 'lightestYellow',
  lightGray = 'lightGray',
  lightPlum = 'lightPlum',
  lightStroke = 'lightStroke',
  linkLightSecondary = 'linkLightSecondary',
  linkWater = 'linkWater',
  midnightBlue = 'midnightBlue',
  normalApple = 'normalApple',
  normalBlackberry = 'normalBlackberry',
  normalBlueberry = 'normalBlueberry',
  normalCranberry = 'normalCranberry',
  normalLinkWater = 'normalLinkWater',
  normalMint = 'normalMint',
  normalPlum = 'normalPlum',
  normalRaspberry = 'normalRaspberry',
  normalYellow = 'normalYellow',
  orangeLightest = 'orangeLightest',
  orangeNormal = 'orangeNormal',
  redMTS = 'redMTS',
  silverMyst = 'silverMyst',
  silverSand = 'silverSand',
  sinApple = 'sinApple',
  textSecondary = 'textSecondary',
  white = 'white',
  whiteSmoke = 'whiteSmoke',
}

type ThemeVarsType = {
  colors: ColorsType;
};

export const ThemeVars: ThemeVarsType = {
  colors: {
    [ColorsEnum.aliceBlue]: '#f8f9fb',
    [ColorsEnum.black]: '#000',
    [ColorsEnum.blueberryDark]: '#0077db',
    [ColorsEnum.cheatas]: '#f9b021',
    [ColorsEnum.darkApple]: '#04aa42',
    [ColorsEnum.darkBlueberry]: '#007cff',
    [ColorsEnum.darkestCranberry]: '#7f3363',
    [ColorsEnum.darkestLime]: '#808201',
    [ColorsEnum.darkestMint]: '#00724d',
    [ColorsEnum.darkestYellow]: '#f37f19',
    [ColorsEnum.darkLime]: '#a6c100',
    [ColorsEnum.darkMint]: '#03a17b',
    [ColorsEnum.darkOrange]: '#e04a17',
    [ColorsEnum.darkPlum]: '#6d2d79',
    [ColorsEnum.darkYellow]: '#fac031',
    [ColorsEnum.deepBlue]: '#001424',
    [ColorsEnum.greyChateau]: '#9198a0',
    [ColorsEnum.greyHeather]: '#bbc1c7',
    [ColorsEnum.greyRaven]: '#6e7782',
    [ColorsEnum.inactiveTabbar]: '#aeb5bd',
    [ColorsEnum.lavender]: '#e9ebf7',
    [ColorsEnum.lightApple]: '#74df8b',
    [ColorsEnum.lightBlack]: '#1d2023',
    [ColorsEnum.lightBlackberry]: '#6384e0',
    [ColorsEnum.lightBlueberry]: '#45b6fc',
    [ColorsEnum.lightCranberry]: '#e677ad',
    [ColorsEnum.lightestYellow]: '#fffde8',
    [ColorsEnum.lightGray]: '#d3d3d3',
    [ColorsEnum.lightPlum]: '#a86ea7',
    [ColorsEnum.lightStroke]: '#bcc3d0',
    [ColorsEnum.linkLightSecondary]: '#969fa8',
    [ColorsEnum.linkWater]: '#e2e5eb',
    [ColorsEnum.midnightBlue]: '#202d3d',
    [ColorsEnum.normalApple]: '#26cd58',
    [ColorsEnum.normalBlackberry]: '#014fce',
    [ColorsEnum.normalBlueberry]: '#0097fd',
    [ColorsEnum.normalCranberry]: '#e54887',
    [ColorsEnum.normalLinkWater]: '#e7eafa',
    [ColorsEnum.normalMint]: '#00c19b',
    [ColorsEnum.normalPlum]: '#883888',
    [ColorsEnum.normalRaspberry]: '#ea1f49',
    [ColorsEnum.normalYellow]: '#fbe739',
    [ColorsEnum.orangeLightest]: '#fbe9e7',
    [ColorsEnum.orangeNormal]: '#f95721',
    [ColorsEnum.redMTS]: '#FF0032',
    [ColorsEnum.silverMyst]: '#d3dae1',
    [ColorsEnum.silverSand]: '#c4c4c4',
    [ColorsEnum.sinApple]: '#03ad00',
    [ColorsEnum.textSecondary]: '#626c77',
    [ColorsEnum.white]: '#FFFFFF',
    [ColorsEnum.whiteSmoke]: '#f2f3f7',
  },
};
