import { Reducer, Dispatch } from 'redux';
import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { set } from 'lodash';

export const REDUCER_MANAGER_PREFIX = 'modules';

export interface ManagedRootState {
  [REDUCER_MANAGER_PREFIX]?: Record<string, any>;
}

// TODO добавить мидлварь для удаления state
export class ReducerManager {
  private readonly prefix: string;
  private readonly staticReducers: Record<string, Reducer> = {};
  private dynamicReducers: Record<string, Reducer> = {};
  private combinedReducer: Reducer;
  private keysToRemove: Array<string> = [];
  private dispatch?: Dispatch;

  constructor(initialReducers: Record<string, Reducer>) {
    this.staticReducers = { ...initialReducers };
    this.combinedReducer = combineReducers(this.staticReducers);
    this.prefix = REDUCER_MANAGER_PREFIX + '.';
  }

  reduce(state: any, action: AnyAction) {
    if (this.keysToRemove.length > 0) {
      for (let key of this.keysToRemove) {
        deleteState(state, key);
      }
      this.keysToRemove = [];
    }

    return this.combinedReducer(state, action);
  }

  add(key: string, reducer: Reducer) {
    key = this.prefix + key;
    if (this.dynamicReducers[key]) {
      return;
    }

    this.dynamicReducers[key] = reducer;
    this.combinedReducer = combineReducers({
      ...this.staticReducers,
      ...combine(this.dynamicReducers),
    });

    this.dispatch!({ type: '@@redux-manager/ADD' });
  }

  addStatic(key: string, reducer: Reducer) {
    this.staticReducers[key] = reducer;
    this.combinedReducer = combineReducers({
      ...this.staticReducers,
      ...combine(this.dynamicReducers),
    });

    this.dispatch!({ type: '@@redux-manager/ADD_STATIC' });
  }

  remove(key: string) {
    key = this.prefix + key;
    if (!key || !this.dynamicReducers[key]) {
      return;
    }

    delete this.dynamicReducers[key];

    this.keysToRemove.push(key);
    this.combinedReducer = combineReducers({
      ...this.staticReducers,
      ...combine(this.dynamicReducers),
    });

    this.dispatch!({ type: '@@redux-manager/REMOVE' });
  }

  setDispatch(dispatch: Dispatch) {
    this.dispatch = dispatch;
  }

  private prepareReducer(key: string, reducer: Reducer): [string, Reducer] {
    const path: Array<string> = (this.prefix + key).split('.');
    if (path.length === 1) {
      return [key, reducer];
    }

    const firstKey = path.pop();
    if (!firstKey) {
      return [key, reducer];
    }

    const lastKey = path.shift();
    if (!lastKey) {
      return [key, reducer];
    }

    const acc = path.reverse().reduce(
      function (acc, k) {
        return {
          [k]: combineReducers(acc),
        };
      },
      { [firstKey]: reducer }
    );

    return [lastKey, combineReducers(acc)];
  }
}

//-------------------------------------------------------------------------

interface ReducersMap {
  [key: string]: ReducersMap | Reducer;
}

function combine(map: Record<string, Reducer>) {
  const reducers = {};
  Object.keys(map).forEach((path) => {
    set(reducers, path, map[path]);
  });

  return iterateAndCombineReducers(reducers);
}

function iterateAndCombineReducers(map: ReducersMap): Record<string, Reducer> {
  const reducers: Record<string, Reducer> = {};
  for (let key in map) {
    const maybeReducer = map[key];
    if (typeof maybeReducer === 'function') {
      reducers[key] = maybeReducer;
    } else {
      reducers[key] = combineReducers(iterateAndCombineReducers(maybeReducer));
    }
  }

  return reducers;
}

type State = Record<string, any>;

function deleteState(state: State, key: string) {
  const path: Array<string> = key.split('.');
  if (path.length === 0) {
    return state;
  }

  if (path.length === 1) {
    state = { ...state };
    delete state[key];
  }

  if (path.length > 1) {
    const firstKey = path.shift();
    if (!firstKey) {
      return state;
    }

    state = { ...state };
    state[firstKey] = deleteState(state[firstKey], path.join('.'));

    if (state[firstKey] === undefined) {
      delete state[firstKey];
    }
  }

  return state;
}
