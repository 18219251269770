import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import FormInner from './form';
import { RootState } from 'core/store';
import { Dispatch, bindActionCreators } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { fetchCompanies, clear as clearCompanies } from 'core/store/features/companies';

export interface FormData {
  address: string;
  owner_id: string;
}

type ReduxStoreProps = ConnectedProps<typeof withConnect>;

export interface Props extends ReduxStoreProps {
  filter: Partial<FormData>;
  defaultFilter: Partial<FormData>;
  setFilter: Function;
}

export const initialValues: FormData = {
  address: '',
  owner_id: '',
};

const BuildingFilter: FC<Props> = ({
  filter,
  defaultFilter,
  setFilter,
  companies,
  loadingCompanies,
  fetchCompanies,
  clearCompanies,
}) => {
  useEffect(() => {
    fetchCompanies({ limit: 3000 });

    return () => {
      clearCompanies();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = ({ address, owner_id }: FormData) => {
    if (isEmpty(address) && isEmpty(owner_id)) {
      setFilter({ ...defaultFilter });
    } else {
      setFilter({
        ...filter,
        address,
        owner_id,
      });
    }
  };

  return (
    <Formik<FormData> initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => <FormInner companiesStore={{ companies, loadingCompanies }} {...formik} />}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const companiesMap = state.entities.companies.items;
  const companiesOptions = Object.keys(companiesMap).map((key) => ({
    key,
    value: companiesMap[key].id,
    text: companiesMap[key].name,
  }));

  return {
    companies: companiesOptions,
    loadingCompanies: state.entities.companies.pending,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    clearCompanies: bindActionCreators(clearCompanies, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(BuildingFilter);
