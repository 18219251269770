import { createSlice } from '@reduxjs/toolkit';
import { DeviceCategory, DeviceCategoryRaw } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createFetchEntity,
  Options,
} from './entities';
import api from '../../constants/api';

const options: Options<DeviceCategory, DeviceCategoryRaw> = {
  prepare: function (entity) {
    return {
      ...entity,
      parent: entity.parent?.id,
    };
  },
};

const slice = createSlice({
  name: 'devices-categories',
  initialState: createEntitiesInitialState<DeviceCategory>(),
  reducers: createDefaultEntitiesReducers<DeviceCategory, DeviceCategoryRaw>(options),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchDeviceCategories = createFetchEntities(api.deviceCategory(), slice.actions);
export const fetchDeviceCategory = createFetchEntity(api.deviceCategory, slice.actions);
