import React, { FC, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { Header, Loader } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import { Card, ErrorMessage } from 'components/share';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { RootState } from 'core/store';
import { isSectionLoading } from 'core/store/selectors/app';
import { clear as clearUser } from 'core/store/features/users';
import { clear as clearCompany } from 'core/store/features/companies';
import { fetchData, SECTION_LOADING_NAME } from './data/fetch';
import { UserEditForm } from './form';

type MatchParams = {
  userId: string;
  companyId: string;
};

type Props = RouteComponentProps<MatchParams> & ConnectedProps<typeof withConnect>;

const CompanyUserEdit: FC<Props> = ({
  user,
  company,
  error,
  isFetching,
  fetchData,
  clearStore,
  history,
  match,
}) => {
  const { companyId, userId } = match.params;
  const { addToast } = useToasts();

  const handleCancel = () => {
    history.push(`/companies/${companyId}`);
  };

  const handleSubmit = () => {
    history.push(`/companies/${companyId}`);
    addToast('Данные успешно сохранены', {
      appearance: 'success',
    });
  };

  useEffect(() => {
    fetchData({ companyId, userId });
    return () => {
      clearStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, userId]);

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (isFetching) {
    return <Loader active={true} placeholder={true} />;
  }

  if (user) {
    return (
      <React.Fragment>
        <SetBreadcrumb url={'/companies'}>Компании</SetBreadcrumb>
        <SetBreadcrumb url={`/companies/${companyId}`}>{company?.name}</SetBreadcrumb>
        <SetBreadcrumb>Редактировать</SetBreadcrumb>

        <Header as={'h2'}>Редактировать</Header>
        <Card title={'Данные контактного лица'}>
          <UserEditForm user={user} onCancel={handleCancel} onSubmit={handleSubmit} />
        </Card>
      </React.Fragment>
    );
  }

  return null;
};

const mapStateToProps = (state: RootState, props: RouteComponentProps<MatchParams>) => {
  return {
    user: state.entities.users.items[props.match.params.userId],
    company: state.entities.companies.items[props.match.params.companyId],
    error: state.entities.companies.error || state.entities.users.error,
    isFetching: isSectionLoading(state, SECTION_LOADING_NAME, true),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const clearStore = () => {
    bindActionCreators(clearUser, dispatch)();
    bindActionCreators(clearCompany, dispatch)();
  };

  return {
    fetchData: bindActionCreators(fetchData, dispatch),
    clearStore,
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(withRouter(CompanyUserEdit));
