import React from 'react';
import { CellProps } from 'react-table';
import { Button } from 'semantic-ui-react';
import { Column } from 'components/share/components/table';
import { confirm } from 'components/share/dialogs';
import { LabelStatus } from 'components/share/components/status/label';
import { BindingType, Building as DataBuilding } from 'core/constants/contracts';
import { WarningLabel } from './components/warning';
import ModalBlockBuilding from './components/form-block';

const CELL_PROPS = {
  className: 'conceal',
};

export interface Building {
  id: string;
  address: string;
  is_active: boolean;
  bindings_statistic: Array<Stat>;
}

interface Stat {
  type: string;
  count: number;
}

interface Props {
  block: (data: Partial<DataBuilding>) => void;
  unblock: (data: Partial<DataBuilding>) => void;
}

export function createColumns(props: Props): Array<Column<Building>> {
  const { block, unblock } = props;

  return [
    {
      Header: 'Адрес',
      accessor: 'address',
      Cell: ({ cell: { value } }: CellProps<Building>) => {
        if (!value) {
          return '';
        }

        // #F95721 - block
        return <LabelStatus color={'#26CD58'}>{value}</LabelStatus>;
      },
    },
    {
      id: 'entrance',
      Header: 'Кол-во дверей',
      Cell: ({ row: { original } }: CellProps<Building>) => {
        const agg = original.bindings_statistic.find((v) => v.type === BindingType.entrance);

        return agg?.count ?? 0;
      },
    },
    {
      id: 'apartment',
      Header: 'Кол-во квартир',
      Cell: ({ row: { original } }: CellProps<Building>) => {
        const agg = original.bindings_statistic.find((v) => v.type === BindingType.apartment);

        return (
          <React.Fragment>
            {agg?.count ?? 0}
            {!original.is_active && (
              <WarningLabel cause={'Причина: Просрочена оплата'}>Дом заблокирован</WarningLabel>
            )}
          </React.Fragment>
        );
      },
    },
    {
      id: 'actions',
      accessor: 'id',
      Header: 'Действия',
      Cell: ({ cell: { value }, row: { original } }) => {
        const isActive = original.is_active;
        const icon = isActive ? 'mts-icon-lock-fill' : 'mts-icon-lock-open-fill';
        const data = {
          id: original.id,
          is_active: !isActive,
        };

        let handleClick = undefined;
        if (!isActive) {
          handleClick = () => {
            confirm({
              content: 'Вы уверены, что хотите разблокировать строение?',
              onConfirm: () => unblock(data),
            });
          };
        }

        const trigger = <Button icon={icon} onClick={handleClick} />;

        return isActive ? (
          <ModalBlockBuilding
            trigger={trigger}
            onSubmit={(values) => block({ ...values, ...data })}
          />
        ) : (
          trigger
        );
      },
      cellProps: () => CELL_PROPS,
    },
  ];
}
