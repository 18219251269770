import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import InputMask from 'react-input-mask';
import { Form } from 'mts-ui';

export default class ReactInputMask extends Component {
  render() {
    const { label, input, meta, help, style, required, ...rest } = this.props;

    const { touched = false, error = '' } = meta;
    const hasError = !!(touched && error);

    return (
      <Form.FieldAdvanced
        required={required}
        style={style}
        label={label}
        error={hasError ? error : undefined}
        hint={help}
      >
        <div className={'ui input'}>
          <InputMask {...rest} {...input} />
        </div>
      </Form.FieldAdvanced>
    );
  }
}

ReactInputMask.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  required: PropTypes.bool,
  help: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  alwaysShowMask: PropTypes.bool,
  formatChars: PropTypes.object,
  permanents: PropTypes.array,
};
