export const intercomList = '/intercoms';
export const intercomCreate = '/intercoms/create';
export const intercomView = (id: string) => `${intercomList}/${id}`;
export const intercomEdit = (id: string) => `${intercomList}/${id}/edit`;
export const intercomHistory = (id: string) => `${intercomList}/${id}/history`;

export const intercomCameraCreate = (intercomId: string) =>
  `${intercomList}/${intercomId}/cameras/create`;
export const intercomCameraEdit = (intercomId: string, cameraId: string) =>
  `${intercomList}/${intercomId}/cameras/${cameraId}/edit`;

export const barrierList = '/barriers';
export const barrierView = (id: string) => `${barrierList}/${id}`;
export const barrierEdit = (id: string) => `${barrierList}/${id}/edit`;
export const barrierHistory = (id: string) => `${barrierList}/${id}/history`;
