import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Dimmer, Grid, Loader, Header } from 'semantic-ui-react';
import { Card } from 'components/share';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { fetchCompany } from 'core/store/actions/companies';
import { getUser } from 'core/store/actions/users';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import CompanyView from './components/company';
import ContactUserView from './components/contantUser';
import { withToastManager } from 'react-toast-notifications';
import { pathWithTableParams, DEFAULT_TABLE_PARAMS } from 'core/utils/routing';

class CompanyViewContainer extends React.Component {
  UNSAFE_componentWillMount() {
    const { match } = this.props;
    this.props.fetchCompany(match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.company !== prevProps.company && this.props.company.item.contact_user) {
      this.props.getUser(this.props.company.item.contact_user);
    }
  }

  handleBack = () => {
    const { tableParams } = this.props.location.state || DEFAULT_TABLE_PARAMS;
    this.props.history.push(pathWithTableParams('/companies', tableParams.page, tableParams.size));
  };

  handleThroughCompanyEdit = (companyId) => {
    this.props.history.push(`/companies/${companyId}/edit`);
  };

  handleThroughUserEdit = (companyId, userId) => {
    this.props.history.push(`/companies/${companyId}/user/${userId}/edit`);
  };

  render() {
    const {
      company: { item = {} },
      user,
    } = this.props;
    const { tableParams } = this.props.location.state || DEFAULT_TABLE_PARAMS;

    if (isEmpty(item)) {
      return (
        <Dimmer active={true} inverted>
          <Loader />
        </Dimmer>
      );
    }

    let contactUserCard = null;

    if (item.contact_user) {
      contactUserCard = (
        <Grid.Row>
          <Grid.Column>
            <Card
              title={'Данные контактного лица'}
              onEdit={() => this.handleThroughUserEdit(item.id, item.contact_user)}
            >
              {user[item.contact_user] ? (
                <ContactUserView
                  user={user[item.contact_user].item}
                  id={item.id}
                  toastManager={this.props.toastManager}
                />
              ) : null}
            </Card>
          </Grid.Column>
        </Grid.Row>
      );
    }

    return (
      <Grid>
        <SetBreadcrumb url={pathWithTableParams('/companies', tableParams.page, tableParams.size)}>
          Компании
        </SetBreadcrumb>
        <Grid.Row>
          <Grid.Column>
            <Header as={'h1'}>Карточка компании</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Card title={'Данные компании'} onEdit={() => this.handleThroughCompanyEdit(item.id)}>
              <CompanyView company={item} />
            </Card>
          </Grid.Column>
        </Grid.Row>
        {contactUserCard}
      </Grid>
    );
  }
}

CompanyViewContainer.propTypes = {
  fetchCompany: PropTypes.func,
  company: PropTypes.shape({
    isFetching: PropTypes.bool,
    item: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),

  match: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

let component;

component = withRouter(CompanyViewContainer);
component = withToastManager(component);
component = reduxForm({
  form: 'company_edit',
  enableReinitialize: true,
})(component);

const mapStateToProps = (state, props) => {
  const company = get(state, `app.company.${props.match.params.id}`, {});
  const { user } = state.app;

  return {
    company,
    user,
    initialValues: {
      name: get(company, 'item.name'),
      is_active: get(company, 'item.is_active'),
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCompany: bindActionCreators(fetchCompany, dispatch),
  getUser: bindActionCreators(getUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(component);
