import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { Column } from 'components/share/components/table';
import { IntercomsTable } from './table';
import IntercomsFilter from './filter';
import {
  fetchDeviceReferences,
  clear as clearDeviceReferences,
} from 'core/store/features/devices-references';
import { fetchCompanies, clear as clearCompanies } from 'core/store/features/companies';
import { fetchBuildings, clear as clearBuildings } from 'core/store/features/buildings';
import { removeEmptyValues } from 'core/utils/forms';

import { TableFilter } from 'components/share/widgets/table-filter';

interface Props {
  columns: Array<Column<any>>;
  category: string;
  isScrollable?: boolean;
}

export interface FilterValues {
  sip?: string;
  name?: string;
  owner_id?: string;
  address?: string;
  actual_owner?: string;
  internet_provider?: string;
  regional_billing_account?: string;
  serial_number?: string;
}

const defaultFilter: Readonly<FilterValues> = {};

export function IntercomsListContainer(props: Props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDeviceReferences());
    if (props.category === 'intercom') {
      dispatch(fetchCompanies({ limit: 1000 }));
      dispatch(fetchBuildings({ limit: 1000 }));
    }

    return () => {
      dispatch(clearDeviceReferences());
      dispatch(clearCompanies());
      dispatch(clearBuildings());
    };
  }, [dispatch, props.category]);

  const [filter, setFilter] = useState<FilterValues>(defaultFilter);

  return (
    <Grid>
      {props.category === 'intercom' && <IntercomsFilter filter={filter} setFilter={setFilter} />}

      <Grid.Row>
        <Grid.Column>
          <TableFilter
            fields={['address', 'sip', 'owner_id']}
            defaultValue={defaultFilter}
            filters={filter}
            setFilters={setFilter}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <IntercomsTable filter={removeEmptyValues(filter)} {...props} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
