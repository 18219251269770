import React, { FC } from 'react';
import { IntercomCommand } from 'core/constants/contracts';
import styled from 'styled-components';
import { Grid, Popup } from 'semantic-ui-react';
import { deviceCommandsList } from 'core/constants/device-commands';
import { ThemeVars } from 'core/styles/vars';
import moment from 'moment';
import { DATE_TIME_SEC_FORMAT } from 'core/constants/app';
import { Icon } from 'components/share';
import { Copy } from 'components/share/assets';
import notification from 'core/utils/notification';
import { getValueOrDash } from 'core/utils/field';

type IDetailsProps = {
  details: IntercomCommand;
};

const GridStyled = styled(Grid)`
  &&&& {
    margin-bottom: 3rem;
  }
`;

const ContainerCommands = styled.div`
  white-space: pre;
  padding: 1.5rem;
  overflow-y: scroll;
  max-height: 325px;
  border: 1px solid rgba(188, 195, 208, 0.5);
  box-sizing: border-box;
  border-radius: 16px;
`;

const ContainerAttempts = styled.div`
  padding: 1.5rem;
  border: 1px solid rgba(188, 195, 208, 0.5);
  box-sizing: border-box;
  border-radius: 16px;
`;

const Header = styled.span`
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 1rem;
`;

const Item = styled.div`
  font-size: 14px;
  line-height: 20px;
  padding: 0.375rem 0.75rem;
  background: ${ThemeVars.colors.whiteSmoke};
  border-radius: 4px;
  display: inline-block;
  margin-right: 0.75rem;
  margin-bottom: 0.25rem;

  &:last-child {
    margin-right: 0;
  }
`;

const TableHeader = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: ${ThemeVars.colors.greyChateau};
`;

const LongText = styled.span`
  display: inline-block;
  width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const LongTextContainer = styled.div`
  cursor: pointer;

  & > i {
    margin-left: 0.5rem;
    color: ${ThemeVars.colors.darkBlueberry};
  }
`;

const CopyText: FC = ({ children }) => {
  const copyText = () => {
    children && navigator.clipboard.writeText(children.toString());
    notification.success('Текст скопирован');
  };

  return (
    <Popup
      trigger={
        <LongTextContainer onClick={copyText}>
          <LongText>{children}</LongText>
          <Icon icon={<Copy />} />
        </LongTextContainer>
      }
      content={children}
      inverted
    />
  );
};

export const Details: FC<IDetailsProps> = ({ details }) => {
  return (
    <GridStyled>
      <Grid.Row>
        <Grid.Column>
          <Item>{deviceCommandsList[details.command]}</Item>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ContainerCommands>
            <Header>Параметры команды</Header>
            {JSON.stringify(details, null, '\t')}
          </ContainerCommands>
        </Grid.Column>
      </Grid.Row>
      {details.attempts && (
        <Grid.Row>
          <Grid.Column>
            <ContainerAttempts>
              <Header>Попытки выполнения</Header>
              <Grid celled>
                <Grid.Row columns={6}>
                  <Grid.Column>
                    <TableHeader>Сообщение пользователю</TableHeader>
                  </Grid.Column>
                  <Grid.Column>
                    <TableHeader>Код результата</TableHeader>
                  </Grid.Column>
                  <Grid.Column>
                    <TableHeader>result_raw_log</TableHeader>
                  </Grid.Column>
                  <Grid.Column>
                    <TableHeader>error_trace</TableHeader>
                  </Grid.Column>
                  <Grid.Column>
                    <TableHeader>created_at</TableHeader>
                  </Grid.Column>
                  <Grid.Column>
                    <TableHeader>finished_at</TableHeader>
                  </Grid.Column>
                </Grid.Row>

                {details.attempts.map((attempt, index) => (
                  <Grid.Row columns={6} key={index}>
                    <Grid.Column>{getValueOrDash(attempt.message)}</Grid.Column>
                    <Grid.Column>{getValueOrDash(attempt.result_code)}</Grid.Column>
                    <Grid.Column>
                      {getValueOrDash(attempt.result_raw_log, (checkedValue) => (
                        <CopyText>{checkedValue}</CopyText>
                      ))}
                    </Grid.Column>
                    <Grid.Column>
                      {getValueOrDash(attempt.error_trace, (checkedValue) => (
                        <CopyText>{checkedValue}</CopyText>
                      ))}
                    </Grid.Column>
                    <Grid.Column>
                      {getValueOrDash(attempt.created_at, (checkedValue) =>
                        moment(checkedValue).format(DATE_TIME_SEC_FORMAT)
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      {getValueOrDash(attempt.finished_at, (checkedValue) =>
                        moment(checkedValue).format(DATE_TIME_SEC_FORMAT)
                      )}
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </ContainerAttempts>
          </Grid.Column>
        </Grid.Row>
      )}
    </GridStyled>
  );
};
