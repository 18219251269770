import { PromiseThunk } from 'core/store';
import { fetchIntercom } from 'core/store/features/intercoms';
import { getIntercomsItems } from 'core/store/selectors/intercoms';
import { fetchDeviceReference } from 'core/store/features/devices-references';
import { Intercom } from 'core/constants/contracts';
import { fetchApartments } from 'core/store/features/apartments';
import { fetchCameras } from 'core/store/features/cameras';
import { startSectionLoading, stopSectionLoading } from 'core/store/features/app';
import { fetchCompany } from 'core/store/features/companies';
import { fetchBuildings } from 'core/store/features/buildings';

export const SECTION_LOADING_NAME = 'view_device';

export const fetchData = (intercomId: string): PromiseThunk => {
  return (dispatch, getState) => {
    dispatch(startSectionLoading(SECTION_LOADING_NAME));

    return dispatch(fetchIntercom(intercomId))
      .then(() => {
        const intercom: Intercom = getIntercomsItems(getState())[intercomId];
        const deviceId = intercom?.device.id;
        const referenceId = intercom?.device.reference;
        const buildingIdList = intercom.device.bindings.flatMap((item) =>
          item.type === 'building' ? item.building : []
        );

        if (!deviceId || !referenceId) {
          return;
        }

        const promises = [
          dispatch(
            fetchApartments({
              filter: { device: deviceId },
              limit: 1,
            })
          ),
          dispatch(fetchDeviceReference(referenceId)),
          dispatch(fetchCameras({ filter: { parent: deviceId } })),
          dispatch(fetchCompany(intercom.device.owner?.id || '')),
        ];

        if (buildingIdList.length || intercom.device.building?.id) {
          promises.push(
            dispatch(
              fetchBuildings({
                filter: {
                  _id: buildingIdList.length ? buildingIdList : intercom.device.building?.id,
                },
              })
            )
          );
        }

        return Promise.all(promises);
      })
      .finally(() => dispatch(stopSectionLoading(SECTION_LOADING_NAME)));
  };
};
