import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import ClientsContainer from './container';

export class ClientsList extends React.Component {
  render() {
    return (
      <Grid>
        <SetBreadcrumb>Клиенты</SetBreadcrumb>

        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <Header as={'h1'}>Клиенты</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ClientsContainer />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
