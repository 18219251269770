import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Header, Segment } from 'semantic-ui-react';
import { validateBySchemaAsync } from 'core/utils/validate';
import { get } from 'lodash';
import BuildingForm from 'views/pages/buildings/components/forms';
import { requestErrorHandlerForReduxForm, submissionErrorFlatten } from 'core/utils/errors';
import { RouterMatchProps, WebHistoryProps } from 'core/constants/props';
import { fetchBuilding, saveBuilding } from 'core/store/features/buildings';
import { getBuildings, getBuildingsItems } from 'core/store/selectors/buildings';
import { fetchBindings } from 'core/store/features/bindings';
import { getBindings } from 'core/store/selectors/bindings';
import notification from 'core/utils/notification';
import { batchProcessingBindings } from 'core/store/features/bindings';
import { calcEntrancesSelectorFactory } from '../selectors';
import { prepareBindings, hasIntersections } from '../utils';
import schema from '../schema/home';
import { fetchEntrances } from 'core/store/features/entrances';
import { Loader } from 'components/share';

const FORM_ID = 'edit-building';

const mapStateToPropsForm = (state, props) => {
  return {
    initialValues: props.initialValues,
  };
};

const s = schema();
const validate = (values) => {
  return validateBySchemaAsync(s, values);
};

const FormComponent = connect(mapStateToPropsForm)(
  reduxForm({
    form: FORM_ID,
    asyncValidate: validate,
    shouldAsyncValidate: () => true,
    // enableReinitialize: true,
  })(BuildingForm)
);

const readonly = { owner: true, address: true };

class BuildingEditForm extends React.Component {
  state = {
    isFetching: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { match: { params: { id } } = {} } = this.props;
    this.setState({ isFetching: true });
    try {
      await this.props.fetchBuilding(id);
      await this.props.fetchBindings({
        filter: { building: id, is_active: true },
        limit: 5000,
      });
      await this.props.fetchEntrances({
        filter: {
          building: id,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isFetching: false });
    }
  };

  onSubmit = (values) => {
    const { match: { params: { id: buildingId } } = {}, batchProcessingBindings } = this.props;

    if (hasIntersections(values.bindings)) {
      notification.error('Дублирование квартир. Проверьте внесённые диапазоны');
      return;
    }

    const data = prepareBindings(values.bindings);
    const buildingParams = {
      id: buildingId,
      commercial_status: values.commercial_status,
      year: values.year,
      floors: values.floors,
      billing_account: values.billing_account,
    };
    const bindingParams = {
      building_id: buildingId,
      data: data,
    };

    return this.props
      .saveBuilding(buildingParams)
      .then(() => batchProcessingBindings(bindingParams))
      .then(() => this.fetchData())
      .then(() => notification.success())
      .catch(requestErrorHandlerForReduxForm)
      .catch((e) => Promise.reject(submissionErrorFlatten(e, ['address'])));
  };

  onCancel = () => {
    this.props.history.push('/buildings');
  };

  render() {
    return (
      <>
        <Header as={'h2'}>{this.props.header || 'Редактирование дома'}</Header>
        <Segment padded={'very'} className={'block'}>
          {this.state.isFetching ? (
            <Loader active placeholder />
          ) : (
            <FormComponent
              onSubmit={this.onSubmit}
              onCancel={this.onCancel}
              readonly={readonly}
              initialValues={this.props.initialValues}
            />
          )}
        </Segment>
      </>
    );
  }
}

BuildingEditForm.propTypes = {
  header: PropTypes.string,

  history: WebHistoryProps.isRequired,
  match: RouterMatchProps.isRequired,
  initialValues: PropTypes.object,

  fetchBuilding: PropTypes.func,
  fetchBindings: PropTypes.func,
  batchProcessingBindings: PropTypes.func,
  saveBuilding: PropTypes.func,
};

const makeMapStateToProps = () => {
  const calcEntrancesSelector = calcEntrancesSelectorFactory();

  return (state, props) => {
    const { match: { params: { id } } = {} } = props;
    const building = getBuildingsItems(state)[id];
    const isFetching = getBuildings(state).pending || getBindings(state).pending;

    let initialValues;
    if (building && false === isFetching) {
      initialValues = {
        isAddressValidate: false,
        owner: get(building.owner, 'name'),
        address: {
          value: get(building.address, 'id'),
          title: get(building.address, 'text'),
          name: get(building.address, 'text'),
        },
        commercial_status: building.commercial_status,
        type: building.type,
        year: get(building.payload, 'year'),
        floors: get(building.payload, 'floors'),
        bindings: calcEntrancesSelector(state, building),
        billing_account: building.billing_account,
      };
    }

    return {
      initialValues,
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBuilding: bindActionCreators(fetchBuilding, dispatch),
    fetchBindings: bindActionCreators(fetchBindings, dispatch),
    saveBuilding: bindActionCreators(saveBuilding, dispatch),
    batchProcessingBindings: bindActionCreators(batchProcessingBindings, dispatch),
    fetchEntrances: bindActionCreators(fetchEntrances, dispatch),
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(BuildingEditForm);
