import React, { useCallback, useMemo, useState } from 'react';
import { ColumnInstance } from 'react-table';
import { useMutation, useQuery } from 'urql';
import { DEFAULT_PAGE_SIZE } from 'core/constants/app';
import { TableAsync, TableFetchOptions } from 'components/share/components/table';
import ErrorOnPage from 'views/components/error-on-page';
import { Building, createColumns, Sort } from './columns';
import { ManageBuildingModal, IModalVariant } from '../components/modals/manageBuildingModal';
import { showToast } from 'core/utils/toast';
import { withToastManager, ToastConsumerContext } from 'react-toast-notifications';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { request } from 'core/utils/request';
import api from 'core/constants/api';

const BuildingsQuery = `
query ($page: Int, $perPage: Int, $orderBy: BuildingsOrderInput, $filter: BuildingsFilterInput) {
    buildings(
        page: $page
        perPage: $perPage
        orderBy: $orderBy
        filter: $filter
    ) {
        totalCount
        page
        perPage
        list {
            id
            address
            created_at
            is_active
            is_test
            billing_account
            owner_name
            owner_id
        }
    }
}
`;

const BuildingMutation = `
mutation ($input: BuildingUpdateInput!) {
    updateBuilding (input: $input) {
        id
        is_active
    }
}
`;

interface Response {
  buildings: {
    page: number;
    perPage: number;
    totalCount: number;
    list: Array<Building>;
  };
}

type FilterProps = {
  address?: string;
  owner_id?: string;
};

interface Props {
  filter: FilterProps;
  toastManager: ToastConsumerContext;
}

interface State {
  page: number;
  perPage: number;
  orderBy?: {
    field: string;
    direction: 'ASC' | 'DESC';
  };
  filter?: FilterProps;
}

interface StateSort extends Sort {}

export interface IModalState {
  open: boolean;
  id: string;
  variant: IModalVariant;
}

const initialModalState: IModalState = {
  open: false,
  id: '',
  variant: 'block',
};

const tableProps = (props: object) => {
  return {
    ...props,
    className: 'table24',
    sortable: true,
  };
};

function BuildingGrid(props: Props) {
  const { filter, toastManager } = props;
  const query = new URLSearchParams(useLocation().search);

  const [, update] = useMutation(BuildingMutation);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateBuilding = useCallback((data) => update({ input: data }), []);

  const [sort, setSort] = useState<StateSort>({
    field: 'address',
    direction: 'ascending',
  });
  const [variables, setVariables] = useState<State>({
    page: 1,
    perPage: DEFAULT_PAGE_SIZE,
  });

  const [modalState, setModalState] = useState<IModalState>(initialModalState);
  const setModalClose = () => setModalState(initialModalState);
  const setModalOpen = (newState: IModalState) => setModalState(newState);

  const [result, reexecuteQuery] = useQuery<Response>({
    query: BuildingsQuery,
    variables: variables,
    requestPolicy: 'network-only',
  });
  const { data, fetching, error } = result;
  const pageCount = data ? Math.ceil(data.buildings.totalCount / data.buildings.perPage) : 0;

  const updateBlockStatus = () => {
    updateBuilding({
      id: modalState.id,
      is_active: modalState.variant === 'unblock' ? true : false,
      cause: 'Не указана',
    }).then(({ error }) => {
      if (error) {
        showToast({
          toastManager: toastManager,
          message: 'Произошла сетевая ошибка',
          type: 'error',
        });
      } else {
        // ToDo: Костыль для ожидания ответа от сервера при обновления таблицы при блокировке дома.
        setTimeout(() => {
          reexecuteQuery({ requestPolicy: 'network-only' });
          showToast({
            toastManager: toastManager,
            message: `Дом успешно ${
              modalState.variant === 'unblock' ? 'разблокирован' : 'заблокирован'
            }`,
            type: 'success',
          });
        }, 1000);
      }
    });
  };

  const deleteBuilding = () => {
    request
      .delete(api.buildings(modalState.id))
      .then(() => {
        // ToDo: Костыль для обновления таблицы
        setTimeout(() => {
          reexecuteQuery({ requestPolicy: 'network-only' });
          showToast({
            toastManager: toastManager,
            message: 'Дом успешно удален',
            type: 'success',
          });
        }, 1000);
      })
      .catch((err) => showToast({
        toastManager: toastManager,
        message: 'Произошла сетевая ошибка',
        type: 'error',
      }));
  }

  const onSubmit = modalState.variant === 'delete' ? deleteBuilding : updateBlockStatus

  const columns = useMemo(() => {
    const onSort = (column: ColumnInstance) => {
      setSort({
        field: column.id,
        direction: sort.direction === 'ascending' ? 'descending' : 'ascending',
      });
    };

    return createColumns({ sort, query, onSort, modalOpen: setModalOpen });
  }, [sort, query]);

  const fetcher = useCallback(
    (params: TableFetchOptions) => {
      const newState: State = {
        page: params.pageIndex + 1,
        perPage: params.pageSize,
      };

      if (!isEmpty(filter)) {
        newState.filter = {
          address: filter.address !== '' ? filter.address : undefined,
          owner_id: filter.owner_id !== '' ? filter.owner_id : undefined,
        };
      }

      if (sort.field && sort.direction) {
        newState.orderBy = {
          field: sort.field,
          direction: sort.direction === 'ascending' ? 'ASC' : 'DESC',
        };
      }

      setVariables(newState);
    },
    [filter, sort]
  );

  if (error) {
    return <ErrorOnPage>{error?.message}</ErrorOnPage>;
  }

  return (
    <>
      <TableAsync
        columns={columns}
        data={data?.buildings.list || []}
        fetcher={fetcher}
        pageCount={pageCount}
        isFetching={fetching}
        tableProps={tableProps}
        showUpperPagination
      />
      <ManageBuildingModal
        open={modalState.open}
        onClose={setModalClose}
        onSubmit={onSubmit}
        variant={modalState.variant}
      />
    </>
  );
}

export default withToastManager(BuildingGrid);
