import React, { useCallback, useState } from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import JSONInput from 'react-json-editor-ajrm/index';
import locale from 'react-json-editor-ajrm/locale/ru';
import { useToasts } from 'react-toast-notifications';

import { confirm } from '../../share/dialogs';

import './style.less';

export interface ColumnOptions {
  [prop: string]: any;
}

export type OnConfirmCallback = (json: string) => Promise<boolean>;

export interface ComponentProps {
  value: any;
  columnOptions: ColumnOptions;
  onConfirm: OnConfirmCallback;
}

export const JSONEditable = (props: ComponentProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [uniqKey, setUniqKey] = useState(0);
  const [json, setJson] = useState(
    typeof props.value === 'string' ? JSON.parse(props.value) : props.value
  );
  const { addToast } = useToasts();

  const handleChange = useCallback(
    (value) => {
      setJson(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const handleSaveJson = useCallback(() => {
    if (typeof json === 'object' && typeof json.error === 'object') {
      addToast(`Необходимо исправить ошибки в JSON: ${json.error.reason}`, {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    } else {
      confirm({
        content: 'Отправить данные на сервер?',
        onConfirm: () => {
          const jsonPayload: any = typeof json.jsObject !== 'undefined' ? json.jsObject : json;
          props
            .onConfirm(jsonPayload)
            .then((result) => {
              if (result) {
                addToast(`Данные сохранены`);
                setIsEdit(false);
              }
            })
            .catch((error) => {
              addToast(`Ошибка при сохранении JSON: ${error.message}`, {
                appearance: 'warning',
                autoDismiss: true,
              });
            });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [json]);

  const handleReset = useCallback(() => {
    setUniqKey(uniqKey + 1);
    setJson(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [json]);

  return (
    <Table.Cell {...(props.columnOptions || {})}>
      <div className={'editable-container'}>
        <JSONInput
          key={uniqKey}
          placeholder={json.jsObject || json}
          theme="light_mitsuketa_tribute"
          locale={locale}
          onChange={handleChange}
          onKeyPressUpdate={false}
          height={330}
          viewOnly={!isEdit}
        />

        <div className={'control-buttons'}>
          <div>
            <Button
              size={'mini'}
              onClick={() => {
                setIsEdit(!isEdit);
              }}
            >
              {isEdit && <Icon name={'close'} />}
              {!isEdit && <Icon name={'edit'} />}
            </Button>
            {isEdit && (
              <>
                <Button size={'mini'} onClick={handleReset} color={'blue'}>
                  <Icon name={'redo'} />
                </Button>
                <Button size={'mini'} onClick={handleSaveJson} color={'green'}>
                  <Icon name={'check'} />
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </Table.Cell>
  );
};
