import styled from 'styled-components';
import { Button, ButtonProps, Loader } from 'semantic-ui-react';

export const CommandsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonStyled = styled(Button)<ButtonProps>`
  &&&& {
    display: flex;
    align-items: center;
    height: fit-content;

    & > i {
      margin-right: 0.5rem;
    }
  }
`;

export const Spinner = styled(Loader)`
  &&&& {
    width: 1.25rem;
    height: 1.25rem;

    &::before,
    &::after {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;
