import React from 'react';
import { Redirect, RouteProps } from 'react-router';
import { connect } from 'react-redux';
import { RootState } from '../store';
import tokenStorage from 'core/utils/token';

function mapStateToProps(state: RootState) {
  return {
    user: state.appx.user,
  };
}

interface OwnProps extends RouteProps {
  privacy?: boolean;
}

interface Props extends OwnProps, ReturnType<typeof mapStateToProps> {}

const guard = <P extends {}>(
  WrappedComponent: React.ComponentType<P>
): React.FunctionComponent<P & OwnProps> => {
  function Guard(props: P & Props) {
    const { privacy, user, ...rest } = props;

    if (privacy === true && (false === !!user || false === tokenStorage.hasToken())) {
      return <Redirect to={{ pathname: '/sign-in' }} />;
    }

    if (privacy === false && true === !!user) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    return <WrappedComponent {...(rest as P)} />;
  }

  return connect(mapStateToProps)(Guard as any);
};

export interface GuardProps extends OwnProps {}

export default guard;
