import React from 'react';
import { Button, Dimmer, Form, Grid, Icon, Loader, Segment } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import get from 'lodash/get';
import TextInput from 'components/share/redux-form/text-input';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateIntercom } from 'core/store/actions/intercoms';
import notification from 'core/utils/notification';
import { confirm } from 'components/share/dialogs';
import { requestErrorHandlerForReduxForm } from 'core/utils/errors';
import { FORM_ID as FORM_ID_STEP1 } from '../step1';
import { validate, generatePassword } from '../utils';
import schema from './schema';

class Step3 extends React.Component {
  handlePrev = () => this.props.prev();

  handleNext = async ({ login, password, port }) => {
    return new Promise((resolve, reject) => {
      confirm({
        content: 'Вы уверены, что хотите обновить административные настройки?',
        onClose: () => resolve(),
        onConfirm: async () => {
          try {
            const apiData = {
              id: this.props.step1.id,
              api: {
                username: login,
                password: password,
              },
              device: {
                port: +port,
              },
            };
            await this.props.updateIntercom(apiData);

            notification.success();
            resolve();
            this.props.next();
          } catch (e) {
            reject(requestErrorHandlerForReduxForm(e));
          }
        },
      });
    });
  };

  regeneratePassword = () => {
    this.props.dispatch(change(FORM_ID, 'password', generatePassword()));
  };

  render() {
    const { handleSubmit, invalid, submitting, isFetching } = this.props;

    return (
      <Segment padded className="segment-form">
        <Form error={invalid}>
          <Dimmer active={isFetching} inverted>
            <Loader />
          </Dimmer>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="equal">
                  <Field
                    name="login"
                    component={TextInput}
                    label="Логин"
                    controlProps={{
                      required: false,
                    }}
                  />
                  <Field
                    name="password"
                    component={TextInput}
                    controlProps={{
                      action: <Button icon="undo alternate" onClick={this.regeneratePassword} />,
                    }}
                    label="Пароль"
                  />
                  <Field
                    name="port"
                    component={TextInput}
                    label="Порт"
                    controlProps={{
                      required: false,
                    }}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  primary
                  icon
                  labelPosition="right"
                  floated="right"
                  disabled={submitting}
                  onClick={handleSubmit(this.handleNext)}
                >
                  <Icon name="right arrow" />
                  Далее
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}

Step3.propTypes = {
  step1: PropTypes.shape({
    id: PropTypes.string.isRequired,
    login: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    port: PropTypes.number,
  }),
  login: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  updateIntercom: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  prev: PropTypes.func,
  next: PropTypes.func.isRequired,
};

const FORM_ID = 'create_intercom.step3';
const Step3ReduxForm = reduxForm({
  form: FORM_ID,
  validate: validate(schema),
})(Step3);

const mapStateToProps = (state) => {
  const step1 = getFormValues(FORM_ID_STEP1)(state);

  return {
    step1,
    isFetching: get(state, `app.intercom.${step1.id}.isFetching`),
    initialValues: {
      login: get(step1, 'login'),
      password: get(step1, 'password'),
      port: get(step1, 'port'),
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateIntercom: bindActionCreators(updateIntercom, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3ReduxForm);
