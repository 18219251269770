export const NOTIFY_RECEIVE = 'NOTIFY_RECEIVE';
export const REFRESH_COUNTER = 'REFRESH_COUNTER';

export const RESET_COUNTER_DELTA = 'RESET_COUNTER_DELTA';
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
export const SET_ACTIVE_COUNTER = 'SET_ACTIVE_COUNTER';

export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';
export const CURRENT_USER_FAIL = 'CURRENT_USER_FAIL';
export const CURRENT_USER_SET = 'CURRENT_USER_SET';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAIL = 'USER_FAIL';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_ACTIVATE_REQUEST = 'USER_ACTIVATE_REQUEST';
export const USER_ACTIVATE_SUCCESS = 'USER_ACTIVATE_SUCCESS';
export const USER_ACTIVATE_FAIL = 'USER_ACTIVATE_FAIL';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';

export const USER_DEVICES_REQUEST = 'USER_DEVICES_REQUEST';
export const USER_DEVICES_SUCCESS = 'USER_DEVICES_SUCCESS';
export const USER_DEVICES_FAIL = 'USER_DEVICES_FAIL';

export const USER_CHANGE_DEVICE_REQUEST = 'USER_CHANGE_DEVICE_REQUEST';
export const USER_CHANGE_DEVICE_SUCCESS = 'USER_CHANGE_DEVICE_SUCCESS';
export const USER_CHANGE_DEVICE_FAIL = 'USER_CHANGE_DEVICE_FAIL';

export const USER_SERVICES_REQUEST = 'USER_SERVICES_REQUEST';
export const USER_SERVICES_SUCCESS = 'USER_SERVICES_SUCCESS';
export const USER_SERVICES_FAIL = 'USER_SERVICES_FAIL';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAIL = 'USERS_FAIL';

export const USER_ADD_SERVICE_REQUEST = 'USER_ADD_SERVICE_REQUEST';
export const USER_ADD_SERVICE_SUCCESS = 'USER_ADD_SERVICE_SUCCESS';
export const USER_ADD_SERVICE_FAIL = 'USER_ADD_SERVICE_FAIL';

export const USER_DELETE_SERVICE_REQUEST = 'USER_DELETE_SERVICE_REQUEST';
export const USER_DELETE_SERVICE_SUCCESS = 'USER_DELETE_SERVICE_SUCCESS';
export const USER_DELETE_SERVICE_FAIL = 'USER_DELETE_SERVICE_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const SERVICES_REQUEST = 'SERVICES_REQUEST';
export const SERVICES_SUCCESS = 'SERVICES_SUCCESS';
export const SERVICES_FAIL = 'SERVICES_FAIL';

export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAIL = 'TOKEN_FAIL';

export const INTERCOMS_REQUEST = 'INTERCOMS_REQUEST';
export const INTERCOMS_SUCCESS = 'INTERCOMS_SUCCESS';
export const INTERCOMS_FAIL = 'INTERCOMS_FAIL';

export const INTERCOM_REQUEST = 'INTERCOM_REQUEST';
export const INTERCOM_SUCCESS = 'INTERCOM_SUCCESS';
export const INTERCOM_FAIL = 'INTERCOM_FAIL';

export const INTERCOM_CREATE_REQUEST = 'INTERCOM_CREATE_REQUEST';
export const INTERCOM_CREATE_SUCCESS = 'INTERCOM_CREATE_SUCCESS';
export const INTERCOM_CREATE_FAIL = 'INTERCOM_CREATE_FAIL';

export const INTERCOM_USERS_REQUEST = 'INTERCOM_USERS_REQUEST';
export const INTERCOM_USERS_SUCCESS = 'INTERCOM_USERS_SUCCESS';
export const INTERCOM_USERS_FAIL = 'INTERCOM_USERS_FAIL';

export const INTERCOM_UPDATE_REQUEST = 'INTERCOM_UPDATE_REQUEST';
export const INTERCOM_UPDATE_SUCCESS = 'INTERCOM_UPDATE_SUCCESS';
export const INTERCOM_UPDATE_FAIL = 'INTERCOM_UPDATE_FAIL';

export const INTERCOMS_ITEM_REQUEST = 'INTERCOMS_ITEM_REQUEST';
export const INTERCOMS_ITEM_SUCCESS = 'INTERCOMS_ITEM_SUCCESS';
export const INTERCOMS_ITEM_FAIL = 'INTERCOMS_ITEM_FAIL';

export const INTERCOM_PROPERTY_REQUEST = 'INTERCOM_PROPERTY_REQUEST';
export const INTERCOM_PROPERTY_SUCCESS = 'INTERCOM_PROPERTY_SUCCESS';
export const INTERCOM_PROPERTY_FAIL = 'INTERCOM_PROPERTY_FAIL';

export const DEVICE_REFERENCES_REQUEST = 'DEVICE_REFERENCES_REQUEST';
export const DEVICE_REFERENCES_SUCCESS = 'DEVICE_REFERENCES_SUCCESS';
export const DEVICE_REFERENCES_FAIL = 'DEVICE_REFERENCES_FAIL';

export const MENU_REQUEST = 'MENU_REQUEST';
export const MENU_SUCCESS = 'MENU_SUCCESS';
export const MENU_FAIL = 'MENU_FAIL';

export const METRIC_REQUEST = 'METRIC_REQUEST';
export const METRIC_SUCCESS = 'METRIC_SUCCESS';
export const METRIC_FAIL = 'METRIC_FAIL';

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export const USER_EVENTS_REQUEST = 'USER_EVENTS_REQUEST';
export const USER_EVENTS_SUCCESS = 'USER_EVENTS_SUCCESS';
export const USER_EVENTS_FAIL = 'USER_EVENTS_FAIL';

export const COMPANIES_REQUEST = 'COMPANIES_REQUEST';
export const COMPANIES_SUCCESS = 'COMPANIES_SUCCESS';
export const COMPANIES_FAIL = 'COMPANIES_FAIL';

export const COMPANY_SAVE_REQUEST = 'COMPANY_CREATE_REQUEST';
export const COMPANY_SAVE_SUCCESS = 'COMPANY_CREATE_SUCCESS';
export const COMPANY_SAVE_FAIL = 'COMPANY_CREATE_FAIL';

export const COMPANY_REQUEST = 'COMPANY_REQUEST';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const COMPANY_FAIL = 'COMPANY_FAIL';

export const CURRENT_COMPANY_REQUEST = 'CURRENT_COMPANY_REQUEST';
export const CURRENT_COMPANY_SUCCESS = 'CURRENT_COMPANY_SUCCESS';
export const CURRENT_COMPANY_FAIL = 'CURRENT_COMPANY_FAIL';

export const USER_RESTORE_PASSWORD_REQUEST = 'USER_RESTORE_PASSWORD_REQUEST';
export const USER_RESTORE_PASSWORD_SUCCESS = 'USER_RESTORE_PASSWORD_SUCCESS';
export const USER_RESTORE_PASSWORD_FAIL = 'USER_RESTORE_PASSWORD_FAIL';

export const USER_SWAP_PARENT_REQUEST = 'USER_SWAP_PARENT_REQUEST';
export const USER_SWAP_PARENT_SUCCESS = 'USER_SWAP_PARENT_SUCCESS';
export const USER_SWAP_PARENT_FAIL = 'USER_SWAP_PARENT_FAIL';

export const APARTMENT_REQUEST = 'APARTMENT_REQUEST';
export const APARTMENT_SUCCESS = 'APARTMENT_SUCCESS';
export const APARTMENT_FAIL = 'APARTMENT_FAIL';

export const APARTMENTS_REQUEST = 'APARTMENTS_REQUEST';
export const APARTMENTS_SUCCESS = 'APARTMENTS_SUCCESS';
export const APARTMENTS_FAIL = 'APARTMENTS_FAIL';

export const SAVE_APARTMENT_REQUEST = 'SAVE_APARTMENT_REQUEST';
export const SAVE_APARTMENT_SUCCESS = 'SAVE_APARTMENT_SUCCESS';
export const SAVE_APARTMENT_FAIL = 'SAVE_APARTMENT_FAIL';

export const UNLINK_APARTMENT_REQUEST = 'UNLINK_APARTMENT_REQUEST';
export const UNLINK_APARTMENT_SUCCESS = 'UNLINK_APARTMENT_SUCCESS';
export const UNLINK_APARTMENT_FAIL = 'UNLINK_APARTMENT_FAIL';

export const BUILDINGS_REQUEST = 'BUILDINGS_REQUEST';
export const BUILDINGS_SUCCESS = 'BUILDINGS_SUCCESS';
export const BUILDINGS_FAIL = 'BUILDINGS_FAIL';

export const BUILDING_REQUEST = 'BUILDING_REQUEST';
export const BUILDING_SUCCESS = 'BUILDING_SUCCESS';
export const BUILDING_FAIL = 'BUILDING_FAIL';

export const SAVE_BUILDING_REQUEST = 'SAVE_BUILDING_REQUEST';
export const SAVE_BUILDING_SUCCESS = 'SAVE_BUILDING_SUCCESS';
export const SAVE_BUILDING_FAIL = 'SAVE_BUILDING_FAIL';

export const FEEDBACK_REFERENCES_REQUEST = 'FEEDBACK_REFERENCES_REQUEST';
export const FEEDBACK_REFERENCES_SUCCESS = 'FEEDBACK_REFERENCES_SUCCESS';
export const FEEDBACK_REFERENCES_FAIL = 'FEEDBACK_REFERENCES_FAIL';

export const UPDATE_USER_SERVICE_REQUEST = 'UPDATE_USER_SERVICE_REQUEST';
export const UPDATE_USER_SERVICE_SUCCESS = 'UPDATE_USER_SERVICE_SUCCESS';
export const UPDATE_USER_SERVICE_FAIL = 'UPDATE_USER_SERVICE_FAIL';

export const DEVICE_REFERENCE_REQUEST = 'DEVICE_REFERENCE_REQUEST';
export const DEVICE_REFERENCE_SUCCESS = 'DEVICE_REFERENCE_SUCCESS';
export const DEVICE_REFERENCE_FAIL = 'DEVICE_REFERENCE_FAIL';

export const DEVICES_REFERENCE_REQUEST = 'DEVICES_REFERENCE_REQUEST';
export const DEVICES_REFERENCE_SUCCESS = 'DEVICES_REFERENCE_SUCCESS';
export const DEVICES_REFERENCE_FAIL = 'DEVICES_REFERENCE_FAIL';

export const SAVE_DEVICE_REQUEST = 'SAVE_DEVICE_REQUEST';
export const SAVE_DEVICE_SUCCESS = 'SAVE_DEVICE_REQUEST';
export const SAVE_DEVICE_FAIL = 'SAVE_DEVICE_REQUEST';

export const CREATE_REPORT_REQUEST = 'CREATE_REPORT_REQUEST';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_FAIL = 'CREATE_REPORT_FAIL';

export const REPORT_REQUEST = 'REPORT_REQUEST';
export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const REPORT_FAIL = 'REPORT_FAIL';
export const REPORT_COMPLETED = 'REPORT_COMPLETED';

export const BINDINGS_REQUEST = 'BINDINGS_REQUEST';
export const BINDINGS_SUCCESS = 'BINDINGS_SUCCESS';
export const BINDINGS_FAIL = 'BINDINGS_FAIL';

export const PARSER_SESSIONS_REQUEST = 'PARSER_SESSIONS_REQUEST';
export const PARSER_SESSIONS_SUCCESS = 'PARSER_SESSIONS_SUCCESS';
export const PARSER_SESSIONS_FAIL = 'PARSER_SESSIONS_FAIL';

export const UPLOAD_PARSER_SESSION_REQUEST = 'UPLOAD_PARSER_SESSION_REQUEST';
export const UPLOAD_PARSER_SESSION_SUCCESS = 'UPLOAD_PARSER_SESSION_SUCCESS';
export const UPLOAD_PARSER_SESSION_FAIL = 'UPLOAD_PARSER_SESSION_FAIL';

export const PARSER_ROWS_REQUEST = 'PARSER_ROWS_REQUEST';
export const PARSER_ROWS_SUCCESS = 'PARSER_ROWS_SUCCESS';
export const PARSER_ROWS_FAIL = 'PARSER_ROWS_FAIL';

export const PARSER_ROW_REQUEST = 'PARSER_ROW_REQUEST';
export const PARSER_ROW_SUCCESS = 'PARSER_ROW_SUCCESS';
export const PARSER_ROW_FAIL = 'PARSER_ROW_FAIL';
