import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import tokenStorage from '../../utils/token';
import { WebAPIResponse } from '../../utils/webapi.contracts';
import { Session } from '../../constants/contracts';
import { ThunkResult } from '..';
import axios from 'axios';
import api from '../../constants/api';
import { dataFromAxios } from '../../utils/webapi.handlers';
import { onFailed } from './entities';

export interface State {
  token: Nullable<string>;
  isFetching: boolean;
  error: Nullable<string>;
}

export const initialState: State = {
  token: tokenStorage.getToken(),
  isFetching: false,
  error: null,
};

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    authFetch: (state) => {
      state.isFetching = true;
      return state;
    },
    authFailed: (state, action: PayloadAction<string>) => {
      state.isFetching = false;
      if (action.payload === 'Unauthorized') {
        state.error = 'Введен неверный E-mail или Пароль';
      } else {
        state.error = action.payload;
      }

      return state;
    },
    authSuccess: {
      reducer: (
        state,
        action: PayloadAction<WebAPIResponse<Session>, string, { remember_me: boolean }>
      ) => {
        const session = action.payload.list[0];
        if (!session) {
          return state;
        }

        tokenStorage.setToken(session.sid, action.meta.remember_me);
        state.token = session.sid;
        state.isFetching = false;
        state.error = null;

        return state;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    logout: (state) => {
      state.token = null;
      return state;
    },
  },
});

export default slice;

export const { authFetch, authSuccess, authFailed, logout: logoutAction } = slice.actions;

/**
 * Чистый клиент, без всяки интерцепторов
 */
const client = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export interface AuthActionData {
  username: string;
  password: string;
  remember_me: boolean;
  role?: string;
}

export const authenticate = (data: AuthActionData): ThunkResult<void> => {
  return async (dispatch: Dispatch) => {
    dispatch(authFetch());

    const requestData = {
      username: data.username,
      password: data.password,
      role: data.role,
    };

    return client.post(api.auth(), requestData).then(
      (res) => dispatch(authSuccess(dataFromAxios(res), { remember_me: data.remember_me })),
      (err) => onFailed(dispatch, authFailed, err)
    );
  };
};

export const logout = (): ThunkResult<void> => {
  return async (dispatch: Dispatch) => {
    const token = tokenStorage.getToken();
    dispatch(logoutAction());

    return client.delete(api.auth(token)).catch(console.error);
  };
};
