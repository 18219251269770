import { createSlice } from '@reduxjs/toolkit';
import { Building } from '../../constants/contracts';
import { getById } from '../selectors/buildings';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntity,
  createFetchEntities,
  createSaveEntity,
} from './entities';
import api from '../../constants/api';

const slice = createSlice({
  name: 'buildings',
  initialState: createEntitiesInitialState<Building>(),
  reducers: createDefaultEntitiesReducers<Building>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchBuildings = createFetchEntities<Building>(api.buildings(), slice.actions);

export const fetchBuilding = createFetchEntity<Building>(api.buildings, slice.actions, getById);

export const saveBuilding = createSaveEntity(api.buildings, slice.actions);
