/* eslint-disable */
import React, { Component } from 'react';
import { fieldPropTypes } from 'redux-form';
import PropTypes from 'prop-types';
import { isEmpty, debounce } from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import { Form } from 'mts-ui';
import { request } from 'core/utils/request';
import './style.less';

export default class Suggest extends Component {
  static defaultProps = {
    minLength: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      titleMap: null,
      selectedValue: undefined,
      isFetching: false,
      onlyLoadData: false,
    };
  }

  static _mapOptions(options = []) {
    return options.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    }));
  }

  sendRequest = async (searchQuery) => {
    if (this.state.onlyLoadData) {
      return;
    }

    this.setState({
      isFetching: true,
    });

    try {
      const res = await request.get(this.props.ajaxSource || '', {
        params: { [this.props.searchParamName || 'name']: searchQuery },
      });

      this.setState({
        titleMap: Suggest._mapOptions(res.data.list),
        isFetching: false,
        onlyLoadData: this.props.onlyLoadData,
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isFetching: false,
      });
    }
  };

  _debounceSendRequest = debounce(this.sendRequest, 400);

  handleChange = async (e, { value }) => {
    this.setState({ selectedValue: value });

    await this.props.input.onChange(value);
    if (this.props.handleChange) {
      this.props.handleChange(value);
    }

    document.activeElement.blur();
  };

  handleBlur = (e, { value }) => {
    this.props.input.onBlur(value);
  };

  handleSearchChange = async (value, { searchQuery }) => {
    if (searchQuery.length >= this.props.minLength) {
      this._debounceSendRequest(searchQuery);
    }
  };

  componentDidMount() {
    if (isEmpty(this.props.titleMap)) {
      this._debounceSendRequest();
    }
  }

  render() {
    const { isFetching, selectedValue, titleMap: stateTitleMap } = this.state;

    const {
      label,
      titleMap = stateTitleMap,
      help,
      fluid = true,
      meta: { touched, error } = {},
      input,
      placeholder = 'Выберите значение...',
      multi = false,
      readonly,
      clearable,
      required = false,
      noResultsMessage = 'По вашему запросу ничего не найдено',
    } = this.props;

    const hasError = touched && error;

    return (
      <Form.FieldAdvanced
        required={required}
        label={label}
        error={hasError ? error : undefined}
        hint={help}
      >
        <Dropdown
          placeholder={placeholder}
          noResultsMessage={noResultsMessage}
          fluid={fluid}
          multiple={multi}
          clearable={clearable}
          search
          selection
          disabled={readonly || isFetching}
          loading={isFetching}
          {...input}
          value={selectedValue}
          onChange={this.handleChange}
          onSearchChange={this.handleSearchChange}
          onBlur={this.handleBlur}
          options={titleMap}
        />
      </Form.FieldAdvanced>
    );
  }
}

Suggest.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  titleMap: PropTypes.array,
  readonly: PropTypes.bool,
  help: PropTypes.string,
  ajaxSource: PropTypes.string,
  prompt: PropTypes.string,
  multi: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  isEdited: PropTypes.bool,
  fluid: PropTypes.bool,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  minLength: PropTypes.number,
  handleChange: PropTypes.func,
  onlyLoadData: PropTypes.bool,
  searchParamName: PropTypes.string,
};
