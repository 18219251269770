import React, { useEffect, FC, useState } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';
import { CamerasTable, fetchData, SECTION_LOADING_NAME } from 'components/share/containers/camera';
import { withCurrentUser } from 'core/contexts/user';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { connect, ConnectedProps } from 'react-redux';
import { clear as clearIntercoms } from 'core/store/features/intercoms';
import { clear as clearCameras } from 'core/store/features/cameras';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { isSectionLoading } from 'core/store/selectors/app';
import { clearSectionLoading } from 'core/store/features/app';

import { TableFilter, TableFilterFormData } from 'components/share/widgets/table-filter';

type ListProps = RouteComponentProps & ConnectedProps<typeof withConnect>;

const defaultFilter: TableFilterFormData = {};

const List: FC<ListProps> = ({ isFetching, clearSection }) => {
  const [filters, setFilters] = useState(defaultFilter);

  useEffect(() => {
    return () => {
      clearSection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      <SetBreadcrumb>Камеры</SetBreadcrumb>

      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h1">Камеры</Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <TableFilter 
            fields={['address', 'name', 'owner_id']}
            defaultValue={defaultFilter}
            filters={filters}
            setFilters={setFilters} 
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <CamerasTable 
            filters={filters}
            fetchData={fetchData}
            isFetching={isFetching}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

function mapStateToProps(state: RootState) {
  return {
    isFetching: isSectionLoading(state, SECTION_LOADING_NAME, true),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const clearSection = () => {
    bindActionCreators(clearIntercoms, dispatch)();
    bindActionCreators(clearCameras, dispatch)();
    dispatch(clearSectionLoading(SECTION_LOADING_NAME));
  };

  return {
    clearSection,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(withCurrentUser(List));
