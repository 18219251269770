import React, { FC } from 'react';
import { Label, TextBox } from './styles';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props {
  title: string | number | undefined | null;
  size?: SemanticWIDTHS;
  noGrid?: boolean;
}

const Content = styled.div`
  overflow-wrap: break-word;
`;

export const TextFieldX: FC<Props> = ({ title, size, noGrid = false, children }) => {
  const renderField = (
    <TextBox>
      <Label>{title}</Label>
      <Content>{children || children === 0 ? children : '-'}</Content>
    </TextBox>
  );

  return noGrid ? renderField : <Grid.Column width={size}>{renderField}</Grid.Column>;
};
