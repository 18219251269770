import React, { Component } from 'react';
import { fieldPropTypes } from 'redux-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import filesize from 'filesize';
import moment from 'moment';
import { Button, Form, Icon, List } from 'semantic-ui-react';
import InfoIcon from '../common/info-icon';
import FileItem from './item';
import * as fileHelper from '../../utils/file';

import './style.less';

export default class FileInputAjax extends Component {
  static defaultProps = {
    emptyMessage: '(не выбрано)',
  };

  constructor(props) {
    super(props);

    this.fileRef = null;
    this.state = {
      files: [],
      edited: this.getFileEditedInfo(),
    };
  }

  getFileEditedInfo = () => {
    const { input } = this.props;
    let changes = {};

    if (input.value && Array.isArray(input.value) && input.value.length > 0) {
      input.value.forEach((file) => {
        changes[file.id] = {
          description: file.description,
          isUpdate: false,
        };
      });
    }

    return changes;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.uploaded && this.state.files.length > 0) {
      //удаление из состояния загруженных файлов
      const newState = this.state.files.filter((item) => {
        const itemId = fileHelper.getFileId(item);

        return !nextProps.uploaded[itemId] || nextProps.uploaded[itemId]['id'] === undefined;
      });

      this.setState({
        files: newState,
      });
    }
  }

  handleUploadSingleFile = (files) => {
    if (this.props.handleUploadFiles) {
      this.props
        .handleUploadFiles({
          files,
          type: this.props.fileType,
          typeAlias: this.props.fileTypeAlias,
        })
        .then((loadedFiles) => {
          this.props.input.onChange(loadedFiles);
        });
    }
    this.setState({
      files: files,
    });
  };

  handleUploadFiles = (files) => {
    if (this.props.handleUploadFiles) {
      this.props
        .handleUploadFiles({
          files,
          type: this.props.fileType,
          typeAlias: this.props.fileTypeAlias,
        })
        .then((loadedFiles) => {
          let allFiles = this.props.input.value || [];
          this.props.input.onChange(allFiles.concat(loadedFiles));
        })
        .catch(() => {});
    }
  };

  handleChange = (e) => {
    let files = Array.prototype.slice.call(e.target.files);
    const { multiple, input } = this.props;

    files.forEach((file) => {
      file.intercomId = fileHelper.generateUniqId(file);
    });

    if (!multiple) {
      if (input.value && input.value.length > 0) {
        Promise.all(
          input.value.map((item) => {
            return this.props.handleDeleteFile(item.id);
          })
        ).then(() => {
          this.handleUploadSingleFile(files);
        });
      } else {
        this.setState(
          () => {
            return { files: files };
          },
          () => {
            this.handleUploadFiles(files);
          }
        );
      }
    } else {
      this.setState(
        (prevState) => {
          return { files: prevState.files.concat(files) };
        },
        () => {
          this.handleUploadFiles(files);
        }
      );
    }
    this.fileRef.value = '';
  };

  handleDeleteFile = (id) => {
    if (
      this.props.handleDeleteFile &&
      !this.state.files.some((file) => fileHelper.getFileId(file) === id)
    ) {
      this.props.handleDeleteFile(id).then((id) => {
        let value = this.props.input.value;
        if (!Array.isArray(value)) {
          value = [value];
        }

        this.props.input.onChange(
          value.filter((file) => {
            return fileHelper.getFileId(file) !== id;
          })
        );
      });
    }

    this.setState({
      files: this.state.files.filter((file) => {
        return fileHelper.getFileId(file) !== id;
      }),
    });
  };

  getLoadedFiles = () => {
    const { input } = this.props;

    if (!input.value) {
      return [];
    }

    let value = input.value;
    if (!Array.isArray(value)) {
      value = [value];
    }

    return value.map((file) => {
      return {
        id: file.id,
        name: file.filename,
        isUploaded: true,
        isFetching: _.get(file, 'isFetching', false),
        size: file.size,
        mime: file.mime,
        errorMessage: file.errorMessage,
        url: file.url,
        download: file.download,
        description: file.description,
        filename: file.filename,
        user: file.user,
        createdAt: file.created_at || moment().format('YYYY-MM-DD'),
        fileType: file.workflow_upload_type,
      };
    });
  };

  handleUpdateFile = (id, { description }) => {
    return this.props.handleUpdateFile(id, { description }).then((updatedFile) => {
      let files = (this.props.input.value || []).map((file) => {
        if (file.id === updatedFile.id) {
          return updatedFile;
        }

        return file;
      });
      this.props.input.onChange(files);
    });
  };

  render() {
    const {
      input,
      label,
      meta: { touched, error, warning },
      buttonLabel,
      buttonOptions = {},
      uploaded,
      width,
      ...custom
    } = this.props;
    const { files } = this.state;

    let loadedFiles = this.getLoadedFiles(),
      formFieldParams = {};

    if (width) {
      formFieldParams['width'] = width;
    }

    if (files && files.length > 0) {
      files.forEach((item) => {
        const fileId = fileHelper.getFileId(item);
        const uploadedFile = uploaded[fileId];

        let file = {
          id: fileId,
          name: item.name,
          isFetching: true,
          isUploaded: false,
          originalFile: item,
          description: item.description,
          user: {},
          createdAt: moment().format('YYYY-MM-DD'),
        };

        if (uploadedFile) {
          file = {
            ...file,
            isFetching: uploadedFile.isFetching,
            progress: uploadedFile.progress,
            hasError: uploadedFile.errorMessage !== '',
            errorMessage: uploadedFile.errorMessage,
            url: uploadedFile.url,
            download: uploadedFile.download,
            description: uploadedFile.description,
            user: uploadedFile.user,
            createdAt: uploadedFile.createdAt || moment().format('YYYY-MM-DD'),
          };
        }

        loadedFiles.push(file);
      });
    }

    return (
      <Form.Field style={{ wordWrap: 'break-word' }} error={touched && error} {...formFieldParams}>
        {touched && error && <InfoIcon item={error} />}
        <label style={{ display: 'inline-block' }}>{label}</label>
        {this.props.canEdit && (
          <div>
            <input
              ref={(ref) => (this.fileRef = ref)}
              type="file"
              style={{ display: 'none' }}
              onChange={this.handleChange}
              {...custom}
            />
            <div>
              <Button
                {...buttonOptions}
                onClick={(e) => {
                  e.preventDefault();
                  this.fileRef.click();
                }}
              >
                {buttonLabel || 'Загрузить'}
              </Button>
            </div>
          </div>
        )}

        {loadedFiles && loadedFiles.length > 0 && (
          <List celled verticalAlign="middle" className="file-list">
            {loadedFiles.map((item) => this.renderItem(item))}
          </List>
        )}
        {!this.props.canEdit && (!loadedFiles || loadedFiles.length === 0) && (
          <i>{this.props.emptyMessage}</i>
        )}
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </Form.Field>
    );
  }

  handleEditItem = (id, data) => {
    this.setState({
      edited: {
        ...this.state.edited,
        [id]: data,
      },
    });
  };

  renderItem(item) {
    const { multiple, canEdit, titleMap } = this.props;

    if (!multiple && item.isFetching && item.isUploaded) {
      return null;
    }

    let title = '';

    if (item.url) {
      title = (
        <a href={item.download || item.url} target="_blank" rel="noopener noreferrer" download>
          {item.name}
        </a>
      ); //eslint-disable-line
    } else {
      title = <span>{item.name}</span>;
    }

    let fsize = item.size ? filesize(item.size) : null;
    let fileField = {};

    if (item.id && this.state.edited[item.id]) {
      fileField = this.state.edited[item.id];
    }

    let errorIcon = <Icon name="remove circle" size="big" className="text-danger" />,
      uploadDescription = (
        <span className="component-uploader-description" style={{ padding: '10px' }}>
          {title}
        </span>
      );

    let ItemComponent = this.props.renderItemComponent || FileItem;

    return (
      <ItemComponent
        key={item.id}
        item={item}
        canEdit={canEdit}
        errorIcon={errorIcon}
        fsize={fsize}
        fileField={fileField}
        title={title}
        canEditItem={this.props.canEditItem}
        uploadDescription={uploadDescription}
        handleChange={this.handleEditItem}
        handleDeleteFile={this.handleDeleteFile}
        handleUpdateFile={this.handleUpdateFile}
        handleUploadFiles={this.handleUploadFiles}
        titleMap={titleMap}
      />
    );
  }
}

FileInputAjax.propTypes = {
  ...fieldPropTypes,
  uploaded: PropTypes.array,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  bsStyle: PropTypes.object,
  handleUploadFiles: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  handleUpdateFile: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  fileType: PropTypes.number,
  fileTypeAlias: PropTypes.string,
  width: PropTypes.number,
  renderItemComponent: PropTypes.func,
  buttonOptions: PropTypes.func,
  canEditItem: PropTypes.func,
  emptyMessage: PropTypes.string,
  titleMap: PropTypes.array,
};
