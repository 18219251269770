import styled from 'styled-components';
import { Button, ButtonProps } from 'semantic-ui-react';
import { ThemeVars } from 'core/styles/vars';

export const ButtonStyled = styled(Button)<ButtonProps>`
  &&&& {
    display: flex;
    align-items: center;
    background-color: ${ThemeVars.colors.white};
    border: 2px solid ${ThemeVars.colors.linkWater};
    color: ${ThemeVars.colors.deepBlue};

    & > svg {
      margin-right: 0.5rem;
      color: ${ThemeVars.colors.deepBlue};
    }
  }

  &&&&:hover {
    background-color: ${ThemeVars.colors.deepBlue};
    border: 2px solid ${ThemeVars.colors.deepBlue};
    color: ${ThemeVars.colors.white};

    & > svg {
      color: ${ThemeVars.colors.white};
    }
  }
`;

export const ButtonGroup = styled.div`
  float: right;
`;

export const ButtonSimple = styled.span`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${ThemeVars.colors.lightBlack};
  white-space: nowrap;
  margin-top: 0.25rem;
`;

export const FiltersBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 1.25rem;
  gap: 1.25rem;
`;
