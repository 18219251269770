import { createSlice } from '@reduxjs/toolkit';
import { Entrance } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createUpdateEntity,
} from './entities';
import api from '../../constants/api';

const slice = createSlice({
  name: 'entrances',
  initialState: createEntitiesInitialState<Entrance>(),
  reducers: createDefaultEntitiesReducers<Entrance>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchEntrances = createFetchEntities<Entrance>(api.entrances(), slice.actions);

export const updateEntrance = createUpdateEntity<Entrance>(api.entrances, slice.actions);
