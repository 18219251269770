import View from './view';
import List from './list';
import Create from './create';
import Edit from './edit';
import History from './history';

export default {
  View,
  List,
  Create,
  Edit,
  History,
};
