import React, { useCallback, useMemo, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { ThemeVars } from 'core/styles/vars';
import { Input, InputProps } from './input';

const IconStyled = styled(Icon)`
  pointer-events: auto !important;
  cursor: pointer !important;
  color: ${ThemeVars.colors.linkLightSecondary} !important;
`;

interface InputPasswordProps extends Omit<InputProps, 'type' | 'icon' | 'iconPosition'> {
  showPassword?: boolean;
}

type InputPasswordType = 'password' | 'text';

function InputPassword(props: InputPasswordProps) {
  const { showPassword = true } = props;

  const [type, setType] = useState<InputPasswordType>('password');
  const handleChangeType = useCallback(() => {
    setType((prev) => (prev === 'password' ? 'text' : 'password'));
  }, [setType]);
  const icon = useMemo(() => {
    if (!showPassword) {
      return undefined;
    }

    return (
      <IconStyled name={type === 'password' ? 'eye' : 'eye slash'} onClick={handleChangeType} />
    );
  }, [showPassword, type, handleChangeType]);

  return <Input {...props} type={type} icon={showPassword ? icon : undefined} />;
}

export { InputPassword };
export type { InputPasswordProps };
