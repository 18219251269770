import { createSlice } from '@reduxjs/toolkit';
import { WorkflowReason } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
} from './entities';
import api from '../../constants/api';

const slice = createSlice({
  name: 'workflow_reasons',
  initialState: createEntitiesInitialState<WorkflowReason>(),
  reducers: createDefaultEntitiesReducers<WorkflowReason>(),
});

export default slice;

export const { pending, success, failed, clear, deleted } = slice.actions;

export const fetchWorkflowReasons = createFetchEntities(api.workflowReasons(), slice.actions);
