import { Action, AnyAction, Reducer, StoreEnhancer } from '@reduxjs/toolkit';
import { PreloadedState } from 'redux';

const round = (number: number) => Math.round(number * 10000) / 10000;

const stopwatchEnhancer: StoreEnhancer = (next) => {
  return function <S, A extends Action = AnyAction>(
    reducer: Reducer<S, A>,
    initialState?: PreloadedState<S>
  ) {
    const stopwatch: Reducer<S, A> = (state, action) => {
      const start = performance.now();
      const nextState = reducer(state, action);
      const end = performance.now();
      const diff = round(end - start);

      console.log(`[REDUX][STOPWATCH] ${action.type}: ${diff}ms`);

      return nextState;
    };

    return next(stopwatch, initialState);
  };
};

export default stopwatchEnhancer;
