import React, { FC, useEffect } from 'react';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { RouteComponentProps } from 'react-router-dom';
import { DropdownItemProps } from 'semantic-ui-react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { ErrorMessage, Loader } from 'components/share';

import { RootState } from 'core/store';
import notification from 'core/utils/notification';
import { createSelectArrayToArray } from 'core/store/selectors';

import { Company, DeviceReference } from 'core/constants/contracts';
import { confirm } from 'components/share/dialogs';

import { createFetchData, LOADING_SECTION_NAME } from './fetch';
import { isSectionLoading } from 'core/store/selectors/app';
import { getCompaniesItems } from 'core/store/selectors/companies';
import { getDeviceReferencesItems } from 'core/store/selectors/devices-references';

import { clear as clearVideoRecorers } from 'core/store/features/video-recorders';
import { saveVideoRecorder } from 'core/store/features/video-recorders';
import { VideoRecorderForm, VideoRecorderFormData } from './create-form';
import { DataForVideoRecorder } from 'components/share/containers/video-recorder/form/config';

interface VideoRecorderContainerProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps>,
    RouteComponentProps {}

const VideoRecorderCreate: FC<VideoRecorderContainerProps> = ({
  companies,
  references,
  isLoading,
  error,
  history,
  fetchData,
  createVideoRecorder,
  clearVideoRecorers,
}) => {
  useEffect(() => {
    fetchData();

    return () => {
      clearVideoRecorers();
    };
  }, [clearVideoRecorers, fetchData]);

  const routeToVideoRecorders = () => history.push('/video-recorders');

  const handleCancel = () => {
    confirm({
      content: 'Вы уверены, что хотите отменить создание видеорегистратора?',
      onConfirm: routeToVideoRecorders,
    });
  };

  const handleSubmitForm = async (values: VideoRecorderFormData) => {
    const { name, protocol, username, password, hostname, port, rtsp_port, reference, owner } =
      values;

    confirm({
      content: 'Вы уверены, что хотите сохранить данные видеорегистратора?',
      onConfirm: async () => {
        let message;

        const data: DataForVideoRecorder = {
          name,
          protocol,
          username,
          password,
          hostname,
          port,
          rtsp_port,
          device: { reference, owner },
        };

        try {
          const videoRecorderResult = await createVideoRecorder(data);
          message = _.get(videoRecorderResult, 'error');
        } catch (e) {
          message = 'Create video-recorder error';
          if (e instanceof Error) {
            message = e.message;
          }
          console.error(e);
        }

        if (message) {
          notification.error(message);
        } else {
          notification.success();
          setTimeout(routeToVideoRecorders, 1000);
        }
      },
    });
  };

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (isLoading) {
    return <Loader active={true} placeholder={true} />;
  }

  return (
    <>
      <VideoRecorderForm
        references={references}
        companies={companies}
        handleCancel={handleCancel}
        handleSubmit={handleSubmitForm}
      />
    </>
  );
};

const selectCompaniesAsOptions = createSelectArrayToArray<Company, DropdownItemProps>((v) => ({
  value: v.id,
  text: v.name,
}));

const selectDeviceReferencesAsOptions = createSelectArrayToArray<
  DeviceReference,
  DropdownItemProps
>((v) => ({
  value: v.id,
  text: v.name,
}));

const fetchData = createFetchData(['video_recorder']);

function mapStateToProps(state: RootState) {
  return {
    companies: selectCompaniesAsOptions(getCompaniesItems(state)) as Array<DropdownItemProps>,
    references: selectDeviceReferencesAsOptions(
      getDeviceReferencesItems(state)
    ) as Array<DropdownItemProps>,
    error: null,
    isLoading: isSectionLoading(state, LOADING_SECTION_NAME, true),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchData: bindActionCreators(fetchData, dispatch),
    createVideoRecorder: bindActionCreators(saveVideoRecorder, dispatch),
    clearVideoRecorers: bindActionCreators(clearVideoRecorers, dispatch),
  };
}

export const CreateVideoRecorderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoRecorderCreate);
