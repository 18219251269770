import React from 'react';
import PropsTypes from 'prop-types';
import { ExtTable, Provider } from 'semantic-data-grid/lib';

export class DataGrid extends React.Component {
  constructor(props) {
    super(props);
    this.apiDataProvider = new Provider.ApiDataProvider({ defaultLimit: 50 });
    this.apiDataProvider.setApiCallback(props.fetchData);

    if (props.defaultParams) {
      this.apiDataProvider.setDefaultParams(props.defaultParams);
    }
  }

  componentDidMount() {
    this.apiDataProvider.fetch();
  }

  render() {
    const {
      header,
      metadata,
      items = [],
      isFetching = false,
      emptyMessage,
      columns = [],
      rowOptions,
    } = this.props;

    this.apiDataProvider.setMetadata(metadata).setItems(items);

    return (
      <ExtTable
        header={header}
        isFetching={isFetching}
        emptyMessage={emptyMessage}
        columns={columns}
        rowOptions={rowOptions}
        provider={this.apiDataProvider}
      />
    );
  }
}

DataGrid.propTypes = {
  fetchData: PropsTypes.func,
  metadata: PropsTypes.object,
  items: PropsTypes.array,
  isFetching: PropsTypes.bool,
  header: PropsTypes.string,
  emptyMessage: PropsTypes.string,
  columns: PropsTypes.array,
  defaultParams: PropsTypes.any,
  rowOptions: PropsTypes.func,
};
