import styled from 'styled-components';
import React, { FC, ReactNode, SyntheticEvent } from 'react';
import { ThemeVars } from 'core/styles/vars';

interface ButtonStyledProps {
  disable: boolean;
}

interface FlatButtonProps extends Partial<ButtonStyledProps> {
  onClick: (e: SyntheticEvent<HTMLAnchorElement>) => void;
  children: ReactNode;
}

const ButtonStyled = styled.a<ButtonStyledProps>`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  cursor: ${(props) => (props.disable ? 'unset' : 'pointer')};
  color: ${(props) => (props.disable ? ThemeVars.colors.greyHeather : ThemeVars.colors.greyRaven)};

  &:hover {
    color: ${(props) => (props.disable ? ThemeVars.colors.greyHeather : ThemeVars.colors.deepBlue)};
  }
`;

export const FlatButton: FC<FlatButtonProps> = ({ disable = false, onClick, children }) => {
  return (
    <ButtonStyled disable={disable} onClick={disable ? undefined : onClick}>
      {children}
    </ButtonStyled>
  );
};
