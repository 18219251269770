import { AppearanceTypes, ToastConsumerContext } from 'react-toast-notifications';

interface Props {
  toastManager: ToastConsumerContext;
  message: string;
  type: AppearanceTypes;
}

export const showToast = ({ toastManager, message, type }: Props) => {
  toastManager.add(message, {
    appearance: type,
    autoDismiss: true,
  });
};
