import { combineReducers } from '@reduxjs/toolkit';
import apartments from '../../features/apartments';
import apartmentTypes from '../../features/apartment-types';
import users from '../../features/users';
import userCategories from '../../features/user-categories';
import buildings from '../../features/buildings';
import bindings from '../../features/bindings';
import intercoms from '../../features/intercoms';
import services from '../../features/services';
import servicesReasons from '../../features/services-reasons';
import userServices from '../../features/user-services';
import events from '../../features/events';
import workflow from '../../features/workflow';
import workflowReasons from '../../features/workflow-reasons';
import companies from '../../features/companies';
import tokens from '../../features/tokens';
import devices from '../../features/devices';
import cameras from '../../features/cameras';
import companyService from '../../features/company-services';
import videoRecorders from '../../features/video-recorders';
import devicesCategories from '../../features/devices-categories';
import devicesReferences from '../../features/devices-references';
import entrances from '../../features/entrances';
import intercomCommands from '../../features/intercom-commands';
import restreamer from '../../features/restreamers';

export default combineReducers({
  apartment_types: apartmentTypes.reducer,
  apartments: apartments.reducer,
  bindings: bindings.reducer,
  buildings: buildings.reducer,
  cameras: cameras.reducer,
  companyService: companyService.reducer,
  video_recorders: videoRecorders.reducer,
  companies: companies.reducer,
  devices_categories: devicesCategories.reducer,
  devices_references: devicesReferences.reducer,
  devices: devices.reducer,
  entrances: entrances.reducer,
  events: events.reducer,
  intercomCommands: intercomCommands.reducer,
  intercoms: intercoms.reducer,
  services_reasons: servicesReasons.reducer,
  services: services.reducer,
  tokens: tokens.reducer,
  user_categories: userCategories.reducer,
  user_services: userServices.reducer,
  users: users.reducer,
  workflow_reasons: workflowReasons.reducer,
  workflow: workflow.reducer,
  restreamer: restreamer.reducer,
});
