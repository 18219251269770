import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { Form } from 'mts-ui';

export default class InputMaskPhone extends Component {
  render() {
    const { label, input, meta, help, style, required, ...rest } = this.props;

    const { touched = false, error = '' } = meta;
    const hasError = !!(touched && error);

    return (
      <Form.FieldAdvanced
        required={required}
        style={style}
        label={label}
        error={hasError ? error : undefined}
        hint={help}
      >
        <div className={'ui input'}>
          <InputMask
            mask="+7 (999) 999-99-99"
            formatChars={{ 9: '[0-9]' }}
            placeholder={'+7 (___) ___-__-__'}
            {...rest}
            {...input}
          />
        </div>
      </Form.FieldAdvanced>
    );
  }
}

InputMaskPhone.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  help: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  alwaysShowMask: PropTypes.bool,
  formatChars: PropTypes.object,
  permanents: PropTypes.array,
};
