import React, { FC, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';

import { RootState } from 'core/store';
import { Loader } from 'components/share';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { getDeviceReferencesItems } from 'core/store/selectors/devices-references';

import { isSectionLoading } from 'core/store/selectors/app';
import { clear as clearVideoRecorders } from 'core/store/features/video-recorders';
import { fetchVideoRecorder } from 'core/store/features/video-recorders';
import { EDIT_SECTION_LOADING_NAME, fetchDeviceReferencesData } from 'components/share/containers/video-recorder';
import { VideoRecorder } from 'core/constants/contracts';
import EditVideoRecorderContainer from 'components/share/containers/video-recorder/form/edit';

import { LocationStateNavigation } from 'core/utils/routing';

type RouteParams = {
  videoRecorderId: string;
};

type VideoRecorderEditProps = ConnectedProps<typeof withConnect> &
  RouteComponentProps<RouteParams, any, LocationStateNavigation>;

const VideoRecorderEdit: FC<VideoRecorderEditProps> = ({
  match,
  history,
  videoRecorder,
  references,
  location,
  fetchVideoRecorder,
  fetchDeviceReference,
  clearVideoRecorders,
  isLoading,
}) => {
  const { videoRecorderId } = match.params;
  useEffect(() => {
    fetchVideoRecorder(videoRecorderId);
    fetchDeviceReference();

      return () => {
        clearVideoRecorders();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRecorderId]);
  if (isLoading) {
    return <Loader active={true} placeholder={true} />;
  }
  return (
    <Grid>
      <SetBreadcrumb url="/video-recorders">Видеорегистраторы</SetBreadcrumb>
      <SetBreadcrumb>{videoRecorder?.name || "Видеорегистратор"}</SetBreadcrumb>

      <Grid.Row>
        <Grid.Column>
          <Header as="h1">Карточка видеорегистратора</Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <EditVideoRecorderContainer 
            videoRecorder={videoRecorder}
            references={references}
            history={history}
            location={location}
            match={match}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};


const mapStateToProps = (state: RootState, { match }: RouteComponentProps<RouteParams>) => {
  const { videoRecorderId } = match.params;

  return {
    references: getDeviceReferencesItems(state) ,
    videoRecorder: state.entities.video_recorders.items[videoRecorderId] as VideoRecorder,
    isLoading: videoRecorderId
      ? isSectionLoading(state, EDIT_SECTION_LOADING_NAME, true)
      : state.entities.video_recorders.pending,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchVideoRecorder: bindActionCreators(fetchVideoRecorder, dispatch),
    fetchDeviceReference: bindActionCreators(fetchDeviceReferencesData, dispatch),
    clearVideoRecorders: bindActionCreators(clearVideoRecorders, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(VideoRecorderEdit);
