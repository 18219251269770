import React, { useCallback, useMemo, FC } from 'react';
import { TableRedux, TableReduxFetchOptions } from 'components/share/components/table/redux';
import { wrapEntitiesTableSelectorFactory } from 'core/store/selectors';
import { createColumns } from './columns';
import { createSelectCamerasAggAsState } from 'core/store/selectors/cameras';
import { WebAPIActionFetchParams } from 'core/utils/webapi.contracts';
import { PromiseThunk } from 'core/store';
import { useLocation } from 'react-router-dom';
import { TableFilterFormData } from 'components/share/widgets/table-filter';

interface CamerasTableProps {
  fetchData: (props: WebAPIActionFetchParams) => PromiseThunk;
  isFetching?: boolean;
  isBindingAllowed?: boolean;
  showUpperPagination?: boolean;
  isArmDomofon?: boolean;
  filters?: TableFilterFormData;
}

export const CamerasTable: FC<CamerasTableProps> = ({
  fetchData,
  isFetching,
  isBindingAllowed,
  showUpperPagination = true,
  isArmDomofon,
  filters,
}) => {
  const query = new URLSearchParams(useLocation().search);
  const columns = useMemo(
    () => createColumns({ isBindingAllowed, query, isArmDomofon }),
    [isArmDomofon, isBindingAllowed, query]
  );
  const cameraSelector = useMemo(() => createSelectCamerasAggAsState(), []);
  const selector = useCallback(
    wrapEntitiesTableSelectorFactory(cameraSelector, {
      withActions: false,
    }),
    []
  );
  const fetcher = useCallback(
    ({ pageIndex, pageSize, ...other }: TableReduxFetchOptions) => {
      return fetchData({
        page: pageIndex + 1,
        limit: pageSize,
        sort: '_id',
        filter: filters,
        ...other,
      });
    },
    [filters, fetchData]
  );

  return (
    <TableRedux
      columns={columns}
      fetcher={fetcher}
      selector={selector}
      refreshAfterResize={5}
      isFetching={isFetching}
      showUpperPagination={showUpperPagination}
    />
  );
};
