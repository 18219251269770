export const TOKEN_KEY = 'bearer_token';
export const REMEMBER_ME_KEY = 'remember_me';

export default {
  getToken() {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return token;
    }

    return sessionStorage.getItem(TOKEN_KEY);
  },

  setToken(token, remember) {
    if (remember) {
      localStorage.setItem(TOKEN_KEY, token);
      sessionStorage.removeItem(TOKEN_KEY);
    } else {
      sessionStorage.setItem(TOKEN_KEY, token);
      localStorage.removeItem(TOKEN_KEY);
    }
  },

  hasToken() {
    return !!this.getToken();
  },

  clean() {
    localStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(TOKEN_KEY);
  },

  getRememberMe() {
    try {
      const item = localStorage.getItem(REMEMBER_ME_KEY);

      return JSON.parse(item);
    } catch (e) {
      return false;
    }
  },

  setRememberMe(value) {
    return localStorage.setItem(REMEMBER_ME_KEY, JSON.stringify(value));
  },
};
