import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Button, Dimmer, Form, Grid, Icon, Loader, Segment } from 'semantic-ui-react';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import { confirm } from 'components/share/dialogs';
import TextInput from 'components/share/redux-form/text-input';
import { bindActionCreators } from '@reduxjs/toolkit';
import { fetchIntercom, updateIntercom } from 'core/store/actions/intercoms';
import { requestErrorHandlerForReduxForm } from 'core/utils/errors';
import { FORM_ID as FORM_ID_STEP1 } from '../step1';
import { validate } from '../utils';
import schema from './schema';

class Step2 extends React.Component {
  componentDidMount() {
    // TODO пока так, в будущем переделать на сокеты
    this.props.fetchIntercom(this.props.step1.id);

    let timer = setInterval(() => {
      const {
        intercom: { config },
      } = this.props;
      if (config && (config.sip || config.domain || config.secret)) {
        return clearInterval(timer);
      }
      this.props.fetchIntercom(this.props.step1.id);
    }, 1000);
  }

  handlePrev = () => {
    this.props.prev();
  };

  handleNext = async ({ sip, secret, domain }) => {
    return new Promise((resolve, reject) => {
      confirm({
        content: 'Вы уверены, что хотите обновить настройки SIP?',
        onClose: () => resolve(),
        onConfirm: async () => {
          try {
            const sipData = {
              id: this.props.step1.id,
              sip: {
                hostname: domain,
                username: sip,
                password: secret,
              },
            };
            await this.props.updateIntercom(sipData);

            resolve();
            this.props.next();
          } catch (e) {
            reject(requestErrorHandlerForReduxForm(e));
          }
        },
      });
    });
  };

  render() {
    const { handleSubmit, invalid, submitting, intercom } = this.props;

    const isLoading = isUndefined(get(intercom, 'config.sip'));
    let isFetching = (this.props.isFetching && !isLoading) || false;

    return (
      <Segment padded className="segment-form">
        <Form error={invalid}>
          <Dimmer active={isFetching} inverted>
            <Loader />
          </Dimmer>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="equal">
                  <Field
                    name="sip"
                    component={TextInput}
                    label="SIP"
                    controlProps={{
                      loading: isLoading,
                    }}
                  />
                  <Field
                    name="secret"
                    component={TextInput}
                    label="Секрет"
                    controlProps={{
                      loading: isLoading,
                    }}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Field
                    name="domain"
                    component={TextInput}
                    label="Домен"
                    controlProps={{
                      loading: isLoading,
                    }}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  primary
                  icon
                  labelPosition="right"
                  floated="right"
                  disabled={submitting}
                  onClick={handleSubmit(this.handleNext)}
                >
                  <Icon name="right arrow" />
                  Далее
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}

Step2.propTypes = {
  step1: PropTypes.shape({
    id: PropTypes.string,
  }),
  intercom: PropTypes.shape({
    config: PropTypes.shape({
      sip: PropTypes.string,
      domain: PropTypes.string,
      secret: PropTypes.string,
    }),
  }),
  isFetching: PropTypes.bool,
  fetchIntercom: PropTypes.func,
  updateIntercom: PropTypes.func,

  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  prev: PropTypes.func,
  next: PropTypes.func.isRequired,
};

export const FORM_ID = 'create_intercom.step2';

const Step2ReduxForm = reduxForm({
  form: FORM_ID,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: validate(schema),
})(Step2);

const mapStateToProps = (state) => {
  const step1 = getFormValues(FORM_ID_STEP1)(state) || {};

  return {
    step1,
    isFetching: get(state, `app.intercom.items.${step1.id}.isFetching`),
    intercom: get(state, `app.intercom.items.${step1.id}.item`),
    initialValues: {
      sip: get(state, `app.intercom.items.${step1.id}.item.config.sip`),
      secret: get(state, `app.intercom.items.${step1.id}.item.config.secret`),
      domain: get(state, `app.intercom.items.${step1.id}.item.config.domain`),
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIntercom: bindActionCreators(fetchIntercom, dispatch),
    updateIntercom: bindActionCreators(updateIntercom, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2ReduxForm);
