import React from 'react';
import { throttle } from 'lodash';
import './styles.less';

interface Props {
  timezone: number; // sec
  timeline: number; // sec
  currentStart: Date;
  currentTime?: number; // sec
}

export class CurrentTime extends React.Component<Props> {
  private throttleSelfUpdate = throttle(this.forceUpdate.bind(this), 75);

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    if (nextProps.timezone !== this.props.timezone || nextProps.timeline !== this.props.timeline) {
      this.throttleSelfUpdate.cancel();
      return true;
    }

    if (nextProps.currentTime !== this.props.currentTime) {
      this.throttleSelfUpdate();
      return false;
    }

    return true;
  }

  getLiveDate() {
    const timezone = this.props.timezone * 1000;

    const date = new Date(Date.now() + timezone);

    return date;
  }

  getCurrentDate() {
    const timezone = this.props.timezone * 1000;
    const timeline = this.props.timeline * 1000;
    const currentTime = (this.props.currentTime ?? 0) * 1000;

    const date = new Date(this.props.currentStart.getTime() + timezone + timeline + currentTime);

    return date;
  }

  render() {
    return (
      <span className={'video-archive-current-time control'}>
        {this.props.currentTime !== undefined && (
          <React.Fragment>
            <span className={'current-date'}>{formatDate(this.getCurrentDate())}</span>
            <span className={'delimiter'}> / </span>
          </React.Fragment>
        )}
        <span className={'live-date'}>{formatDate(this.getLiveDate())}</span>
      </span>
    );
  }
}

function formatDate(date: Date) {
  const day = leadZero(date.getUTCDate());
  const month = leadZero(date.getUTCMonth() + 1);
  const year = date.getUTCFullYear();
  const hour = leadZero(date.getUTCHours());
  const minute = leadZero(date.getUTCMinutes());

  return `${day}.${month}.${year} ${hour}:${minute}`;
}

function leadZero(value: number): string {
  if (value > 9) {
    return '' + value;
  }

  return '0' + value;
}
