import React from 'react';
import { Card, Button, Image } from 'semantic-ui-react';
import building from './building.svg';
import { Link } from 'react-router-dom';

export default function ViewReportsWidget() {
  return (
    <Card>
      <Card.Content>
        <Image floated="right" src={building} />
        <Card.Header>
          Посмотрите <strong>Отчеты</strong>
        </Card.Header>
        <Card.Meta>
          Здесь можно создать дом вместе с квартирами и подъездами!
          <div className={'controls'}>
            <Button secondary as={Link} content={'Добавить'} to={'/reports/apartments_usage'} />
          </div>
        </Card.Meta>
      </Card.Content>
    </Card>
  );
}
