import React, { useCallback } from 'react';
import { Input as SemanticInput, InputOnChangeData, StrictInputProps } from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import { setFieldValue } from '../helpers';
import { BaseFormikFieldProps } from '../props';
import { FormFieldWrapper, FormFieldWrapperProps, useGetComponentProps } from '../field';

interface OwnProps extends FormFieldWrapperProps, Omit<StrictInputProps, 'label' | 'onChange'> {
  onChange?: (
    event: React.FormEvent<HTMLInputElement>,
    data: InputOnChangeData,
    props: Props
  ) => void;

  autoComplete?: 'on' | 'off' | 'new-password';
  placeholder?: string;
}

interface Props extends OwnProps, FieldProps {}

function InputControl(props: Props) {
  const {
    onChange: onChangeOrigin,

    field,
    form,
    meta,
    children,
    placeholder,

    ...rest
  } = useGetComponentProps(props);

  const onChange = useCallback(
    (e, data) => {
      setFieldValue(form, field.name, data.value, true);
      onChangeOrigin?.(e, data, props);
    },
    [form, field, props, onChangeOrigin]
  );

  const placeholderInner = placeholder || props.label;

  return (
    <FormFieldWrapper {...props}>
      <SemanticInput
        {...rest}
        onChange={onChange}
        value={field.value}
        children={children}
        disabled={props.disabled}
        placeholder={placeholderInner}
      />
    </FormFieldWrapper>
  );
}

interface InputProps extends BaseFormikFieldProps<string>, OwnProps {}

function Input(props: InputProps) {
  return <Field component={InputControl} {...props} />;
}

export { Input };
export type { InputProps };
