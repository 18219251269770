import React from 'react';
import { Table } from 'mts-ui';
import { Token } from 'core/constants/contracts';
import { columns } from './columns';
import './styles.less';

interface Props {
  tokens: Array<Token>;
}

export function TokensTable({ tokens }: Props) {
  return (
    <div className={'tokens-table'}>
      <Table.Simple columns={columns} data={tokens} />
    </div>
  );
}
