import React from 'react';
import { Divider, Grid } from 'semantic-ui-react';
import { Field, FieldArray, fieldArrayPropTypes } from 'redux-form';
import { EntranceFloors } from './entrance-floors';
import { PoListForm } from './po-list';
import { confirm } from 'components/share/dialogs';
import { EnteranceContainer, ButtonStyled } from './styles';
import { Icon } from 'components/share';
import { RecycleBin } from 'components/share/assets';

class Entrances extends React.Component {
  componentDidMount() {
    const { fields } = this.props;
    if (fields.length === 0) {
      fields.push(void 0);
    }
  }

  onRemoveClicked = (index) => {
    const { fields } = this.props;
    const entranceNumber = fields.get(index)?.entrance;

    confirm({
      content: `Вы уверены, что хотите удалить подьезд ${
        entranceNumber ? `№${entranceNumber} ` : ''
      }?`,
      onConfirm: () => {
        const { fields } = this.props;
        if (fields.length > 1) {
          fields.splice(index, 1);
        }
      },
    });
  };

  render() {
    const { fields, readOnly } = this.props;

    return (
      <React.Fragment>
        {fields.map((member, index) => {
          return (
            <Grid key={index}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <EnteranceContainer>
                    <Field name={member} component={EntranceFloors} />
                    <FieldArray
                      name={`${member}.poList.numbers`}
                      component={PoListForm}
                      label="Список ПО"
                      readOnly={readOnly}
                    />
                  </EnteranceContainer>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  {fields.length !== 1 && (
                    <ButtonStyled onClick={() => this.onRemoveClicked(index)}>
                      <Icon icon={<RecycleBin />} />
                      Удалить подьезд
                    </ButtonStyled>
                  )}
                </Grid.Column>
              </Grid.Row>
              {fields.length !== 1 && index !== fields.length - 1 && <Divider />}
            </Grid>
          );
        })}
      </React.Fragment>
    );
  }
}

Entrances.propTypes = {
  ...fieldArrayPropTypes,
};

export default Entrances;
