import React from 'react';
import { Card, Button, Image } from 'semantic-ui-react';
import building from './building.svg';
import { Link } from 'react-router-dom';

export default function CreateCompanyWidget(props) {
  return (
    <Card>
      <Card.Content>
        <Image
          floated="right"
          // size='small'
          src={building}
        />
        <Card.Header>
          Добавьте <strong>Клиентов</strong>
        </Card.Header>
        <Card.Meta>
          Вносите необходимую информацию о новых клиентах
          <div className={'controls'}>
            <Button secondary as={Link} content={'Добавить'} to={'/companies/create'} />
          </div>
        </Card.Meta>
      </Card.Content>
    </Card>
  );
}
