import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { GenericParametricSelector } from 'core/store/selectors';
import { getIntercomsItems } from 'core/store/selectors/intercoms';
import { getApartmentsItems } from 'core/store/selectors/apartments';
import { getBuildingsItems } from 'core/store/selectors/buildings';
import { getBindingsItems } from 'core/store/selectors/bindings';
import { Apartment, Binding, Building, Intercom } from 'core/constants/contracts';

export type ApartmentAgg = Omit<Apartment, 'building'> & {
  building: Building;
  bindings: Array<Binding>;
};

export interface UnionApartmentAndIntercoms {
  apartment: ApartmentAgg;
  intercoms: Array<Intercom>;
}

interface UserIdProps {
  userId: string;
}

const getUserId = (state: RootState, props: UserIdProps) => props.userId;

export const createSelectApartmentAggregations = (): GenericParametricSelector<
  UserIdProps,
  Array<UnionApartmentAndIntercoms>
> => {
  return createSelector(
    [getIntercomsItems, getApartmentsItems, getBuildingsItems, getBindingsItems, getUserId],
    (intercoms, apartments, buildings, bindings, userId) => {
      const userApartments = Object.values(apartments)
        .filter((v) => v.is_active)
        .filter((v) => v.users.includes(userId));
      const intercomsArray = Object.values(intercoms);

      const result: Array<UnionApartmentAndIntercoms> = [];

      for (let i = 0; i < userApartments.length; i++) {
        const apartment = userApartments[i];
        const intercoms = intercomsArray.filter((v) => apartment.devices.includes(v.device.id));

        result.push({
          apartment: {
            ...apartment,
            building: buildings[apartment.building],
            bindings: [bindings[apartment.binding]],
          },
          intercoms,
        });
      }

      return result;
    }
  );
};
