import { MODAL_CLOSE, MODAL_OPEN } from './constants';

export default function modals(state = {}, action) {
  switch (action.type) {
    case MODAL_OPEN: {
      return { ...state, name: action.name, payload: action.payload, action: 'open' };
    }
    case MODAL_CLOSE: {
      return { ...state, name: action.name, payload: action.payload, action: 'close' };
    }
    default:
      return state;
  }
}
