import React, { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, submit } from 'redux-form';
import { Form, Icon, SemanticICONS } from 'semantic-ui-react';
import { debounce, isEmpty } from 'lodash';
import { Dropdown, TextInput } from 'components/share/redux-form';
import { Company } from 'core/constants/contracts';

interface FormData {
  name: string;
  roles: string;
  phone: string;
}

export interface Props {
  companies: Array<Company>;
  filter?: object;
  defaultFilter?: object;
  setFilter: Function;
}

class ClientFilter extends React.Component<Props & InjectedFormProps<FormData, Props>> {
  renderIcon = () => {
    return <Icon name={'mts-icon-search' as SemanticICONS} />;
  };

  render(): ReactNode {
    const { invalid, companies } = this.props;

    return (
      <Form error={invalid} className={'filter-form bg-gage'}>
        <Form.Group widths="equal">
          <Field name={'name'} component={TextInput} placeholder={'ФИО'} icon={this.renderIcon()} />
          <Field
            name={'roles'}
            component={Dropdown}
            placeholder={'Компания'}
            options={companies.map((v) => {
              return {
                value: v.client_role,
                text: v.name,
              };
            })}
            clearable
          />
          <Field
            name={'phone'}
            component={TextInput}
            placeholder={'Номер телефона'}
            icon={this.renderIcon()}
          />
        </Form.Group>
      </Form>
    );
  }
}

export const FORM_ID = 'clients-grid-filter';

function submitForm(dispatch: Dispatch<any>) {
  dispatch(submit(FORM_ID));
}
const submitFormDebounce = debounce(submitForm, 400);

const form = reduxForm<FormData, Props>({
  form: FORM_ID,
  onChange: (values, dispatch, props, prevValues) => {
    submitFormDebounce(dispatch);
    if (values.roles !== prevValues.roles) {
      submitFormDebounce.flush();
    }
  },
  onSubmit: (values, dispatch, props) => {
    if (isEmpty(values)) {
      props.setFilter({ ...props.defaultFilter });
    } else {
      props.setFilter({ ...values });
    }
  },
})(ClientFilter);
const connected = connect()(form);

export default connected;
