import React, { useEffect, FC, useState } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';

import { RootState } from 'core/store';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { withCurrentUser } from 'core/contexts/user';
import { isSectionLoading } from 'core/store/selectors/app';
import { clearSectionLoading } from 'core/store/features/app';

import { VideoRecordersTable, fetchData, SECTION_LOADING_NAME } from 'components/share/containers/video-recorder';
import { clear as clearVideoRecorders } from 'core/store/features/video-recorders';
import { TableFilter, TableFilterFormData } from 'components/share/widgets/table-filter';

type ListProps = RouteComponentProps & ConnectedProps<typeof withConnect>;

const defaultFilter: Readonly<TableFilterFormData> = {};

const List: FC<ListProps> = ({ isFetching, clearSection }) => {
  const [filters, setFilters] = useState(defaultFilter);

  useEffect(() => {
    return () => {
      clearSection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      <SetBreadcrumb>Видеорегистраторы</SetBreadcrumb>

      <Grid.Row columns="equal">
        <Grid.Column>
          <Header as="h1">Видеорегистраторы</Header>
        </Grid.Column>
        <Grid.Column textAlign={'right'}>
          <Button as={Link} to="/video-recorders/create">
            Добавить Видеорегистратор
          </Button>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <TableFilter
            fields={['name', 'owner_id']}
            defaultValue={defaultFilter}
            filters={filters}
            setFilters={setFilters}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <VideoRecordersTable
            filters={filters}
            fetchData={fetchData}
            isFetching={isFetching}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

function mapStateToProps(state: RootState) {
  return {
    isFetching: isSectionLoading(state, SECTION_LOADING_NAME, true),
    isAdmin: state.appx.user?.isAdmin || false,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const clearSection = () => {
    bindActionCreators(clearVideoRecorders, dispatch)();
    dispatch(clearSectionLoading(SECTION_LOADING_NAME));
  };

  return {
    clearSection,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(withCurrentUser(List));
