import { compose } from '@reduxjs/toolkit';
import {
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  TOKEN_FAIL,
  TOKEN_REQUEST,
  TOKEN_SUCCESS,
} from '../../constants/actions';

import {
  checkResponse,
  formatErrorMessage,
  prepareError,
  prepareResponse,
  request,
  singleResult,
  returnValue,
} from '../../utils/request';
import api from '../../constants/api';
import { TOKEN_TRANSPORTS, TOKEN_TYPES } from '../../constants/domain/tokens';
import { get } from 'lodash';

/**
 * Подтверждение отправленного кода
 * @param {String} id
 * @param {String} password
 * @returns {Function}
 */
export function resetPassword(id, password) {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });

    try {
      let response = await request.put(api.tokens(id), { password });

      checkResponse(response);

      if (!response.data) {
        throw new Error('Введен невалидный код');
      }

      let result = response.data.list[0];

      if (!result) {
        throw new Error('Произошла ошибка при подтверждении токена, попробуйте еще раз');
      }

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: {
          token: result,
        },
      });
    } catch (e) {
      console.error(e);

      let message;

      const errors = get(e, 'response.data.error.data.errors');
      if (errors && Array.isArray(errors)) {
        message = errors[0].message;
      } else {
        message = formatErrorMessage(e);
      }

      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: {
          error: message,
        },
      });
    }
  };
}

export function getToken(id) {
  return async (dispatch) => {
    dispatch({
      type: TOKEN_REQUEST,
      payload: { id },
    });

    await request
      .get(api.tokens(id))
      .catch((err) => {
        console.error(err.response);

        dispatch({
          type: TOKEN_FAIL,
          payload: {
            error: formatErrorMessage(err),
          },
        });
      })
      .then((response) => {
        try {
          if (!response) {
            return;
          }

          let result = checkResponse(response);

          dispatch({
            type: TOKEN_SUCCESS,
            payload: {
              token: result.list[0],
            },
          });
        } catch (err) {
          console.error(err);

          dispatch({
            type: TOKEN_FAIL,
            payload: {
              error: formatErrorMessage(err),
            },
          });
        }
      });
  };
}

function createTokenRequest() {
  return {
    type: TOKEN_REQUEST,
  };
}

function createTokenSuccess(res) {
  return {
    type: TOKEN_SUCCESS,
    payload: {
      token: res,
    },
  };
}

function createTokenFail(e) {
  return {
    type: TOKEN_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export function createToken({ type, transport, target, user_id = undefined, payload = {} }) {
  return (dispatch) => {
    if (!TOKEN_TYPES.includes(type)) {
      return Promise.reject(new Error('Invalid token type'));
    }
    if (!TOKEN_TRANSPORTS.includes(transport)) {
      return Promise.reject(new Error('Invalid token transport'));
    }

    dispatch(createTokenRequest());

    const result = request
      .post(api.tokens(), { user_id, type, transport, target, payload })
      .then(prepareResponse)
      .then(singleResult)
      .catch(prepareError);

    result
      .then((r) => compose(returnValue, dispatch, createTokenSuccess)(r))
      .catch((e) => dispatch(createTokenFail(e)));

    return result;
  };
}

function verifyTokenRequest() {
  return {
    type: TOKEN_REQUEST,
  };
}

function verifyTokenSuccess(res) {
  return {
    type: TOKEN_SUCCESS,
    payload: {
      token: res,
    },
  };
}

function verifyTokenFail(e) {
  return {
    type: TOKEN_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export function verifyToken(id, code) {
  return (dispatch) => {
    dispatch(verifyTokenRequest());

    const result = request
      .put(api.tokens(id), { code })
      .then(prepareResponse)
      .then(singleResult)
      .catch(prepareError);

    result
      .then((r) => compose(returnValue, dispatch, verifyTokenSuccess)(r))
      .catch((e) => dispatch(verifyTokenFail(e)));

    return result;
  };
}
