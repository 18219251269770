import { createSlice } from '@reduxjs/toolkit';
import { ApartmentType } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
} from './entities';
import api from '../../constants/api';

const slice = createSlice({
  name: 'apartment-types',
  initialState: createEntitiesInitialState<ApartmentType>(),
  reducers: createDefaultEntitiesReducers<ApartmentType>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchApartmentTypes = createFetchEntities(api.apartmentTypes(), slice.actions);
