import React from 'react';
import { Grid } from 'semantic-ui-react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import WizardPage from './wizard';
import * as RouteUrl from 'core/constants/routes';

export default class DeviceCreate extends React.Component {
  render() {
    return (
      <Grid>
        <SetBreadcrumb url={RouteUrl.intercomList}>Домофоны</SetBreadcrumb>
        <SetBreadcrumb>Добавить</SetBreadcrumb>

        <Grid.Row>
          <Grid.Column>
            <WizardPage />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
