import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { Token } from '../../constants/contracts';
import {
  createCreateEntity,
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createUpdateEntity,
} from './entities';
import api from '../../constants/api';
import { ApiThunk } from '../index';

const slice = createSlice({
  name: 'tokens',
  initialState: createEntitiesInitialState<Token>(),
  reducers: createDefaultEntitiesReducers<Token>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchTokens = createFetchEntities(api.tokens(), slice.actions);

export const fetchTokenByTypeAndTarget = (type: string, target: string): ApiThunk<Token> => {
  return (dispatch: Dispatch) => {
    return dispatch(fetchTokens({ filter: { type, target, is_verified: true } }) as any);
  };
};

export const createToken = createCreateEntity(api.tokens(), slice.actions);

export const verifyToken = createUpdateEntity(api.tokens, slice.actions);
