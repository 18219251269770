import React from 'react';
import { fieldPropTypes } from 'redux-form';
import { TextInput, NumberRange } from 'components/share/redux-form';
import { EntranceFieldStyled } from './styles';

export const EntranceFloors = ({ input: { name }, readOnly }) => {
  return (
    <>
      <EntranceFieldStyled
        name={`${name}.entrance`}
        component={TextInput}
        type="number"
        min={0}
        label="Номер подъезда"
        required
        readOnly={readOnly}
      />

      <EntranceFieldStyled
        name={`${name}.apartments`}
        component={NumberRange}
        label="Диапазон квартир"
        required
        inline
        readOnly={readOnly}
        minFrom={0}
        minTo={0}
      />
    </>
  );
};

EntranceFloors.propTypes = fieldPropTypes;
