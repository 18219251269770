import React, { ReactNode } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { FieldInputProps, FieldMetaProps, FormikProps } from 'formik';
import api from 'core/constants/api';
import { request } from 'core/utils/request';
import { WebAPIResponse } from 'core/utils/webapi.contracts';
import { Binding, BindingType, Building } from 'core/constants/contracts';
import { DEFAULT_BINDING_TYPE_ORDER, RelationshipTable } from './table';
import { bindingsToOptions, buildingsToOptions } from './utils';

export interface RenderProps {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  meta: FieldMetaProps<any>;

  name: string;
  fields: Set<BindingType>;
  onChangeBuilding: (buildingId: string) => void;
  onChangeType: (type: BindingType, bindingId: string) => void;

  buildings: Array<Building>;
  bindings: Array<Binding>;
  buildingsOptions: Array<DropdownItemProps>;
  bindingsTypesOptions: Record<string, Array<DropdownItemProps>>;
  selected: Record<string, string>;
  fetching: Record<string, boolean>;
}

interface OwnProps {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  meta: FieldMetaProps<any>;

  name: string;
  fields: Set<BindingType>;
  // для того что бы повторно не получать строения, если эо этого уже загрузили гже то еще
  buildings?: Array<Building>;

  ordering: Array<BindingType>;
  optimizeFetch?: boolean;
  children: (props: RenderProps) => ReactNode;
  isTest?: boolean;
  isActive?: boolean;
  onChangeBuilding?: (buildingId: string) => void;
  onChangeType?: (type: BindingType, bindingId: string) => void;
}

interface Props extends OwnProps {}

interface State {
  selected: Record<string, string>;
  fetching: Record<string, boolean>;

  buildings: Array<Building>;
  bindings: Array<Binding>;

  buildingsOptions: Array<DropdownItemProps>;
  bindingsTypesOptions: Record<string, Array<DropdownItemProps>>;
}

export class AddressHandler extends React.Component<Props, State> {
  static defaultProps = {
    fields: new Set([BindingType.entrance]),
    ordering: DEFAULT_BINDING_TYPE_ORDER,
    optimizeFetch: true,
  };

  private table: RelationshipTable;

  constructor(props: Props) {
    super(props);

    this.state = {
      selected: props.meta.initialValue ?? {},
      fetching: {},
      buildings: props.buildings ?? [],
      bindings: [],
      buildingsOptions: [],
      bindingsTypesOptions: {},
    };

    this.table = new RelationshipTable(props.fields!, props.ordering);
  }

  componentDidMount() {
    this.fetchBuildingsAndUpdateState();

    const initialValue = this.props.meta.initialValue;
    const initialBuildingId = initialValue?.['building_id'];

    if (initialBuildingId) {
      this.fetchBindingsByBuilding(initialBuildingId).then(() => {
        const fields = Array.from(this.props.fields);

        let parentId: string | undefined = undefined;
        let stopIfEnd = false;
        for (let i = 0; i < fields.length; i++) {
          const type = fields[i];
          const value = initialValue[type];

          if (value) {
            this.setBindingTypeValue(type, value);
            stopIfEnd = true;
          }

          this.setBindingTypeOptions(type, parentId);

          parentId = value;

          if (!parentId && stopIfEnd) {
            return;
          }
        }
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.fields !== prevProps.fields || this.props.ordering !== prevProps.ordering) {
      this.table = new RelationshipTable(this.props.fields!, this.props.ordering);
      this.fetchBuildingsAndUpdateState();
    }
  }

  private fetchBuildingsAndUpdateState() {
    Promise.resolve().then(() => {
      this.fetchBuildings().then((buildings) => {
        this.setState({
          buildings: buildings,
          buildingsOptions: buildingsToOptions(buildings),
        });
      });
    });
  }

  private fetchBuildings(): Promise<Array<Building>> {
    if (Array.isArray(this.props.buildings) && this.props.buildings.length > 0) {
      return Promise.resolve(this.props.buildings);
    }

    this.setState({
      fetching: {
        ...this.state.fetching,
        building_id: true,
      },
      bindings: [],
    });

    const params = {
      limit: 1000,
      is_active: this.props.isActive,
    };

    return request
      .get<WebAPIResponse<Building>>(api.buildings(), { params })
      .then((response) => response.data.list)
      .finally(() => {
        this.setState({
          fetching: {
            ...this.state.fetching,
            building_id: false,
          },
        });
      });
  }

  private fetchBindingsByBuilding(buildingId: string): Promise<Array<Binding>> {
    const type = [];
    if (this.props.optimizeFetch) {
      type.push(...this.table.getDependTypesRange());

      // no one visible
      if (type.length === 0) {
        return Promise.resolve([]);
      }
    }

    const building = this.state.buildings.find((v) => v.id === buildingId);

    const params = {
      limit: 5000,
      building: buildingId,
      is_active: this.props.isActive,
      is_test: this.props.isTest ?? building?.is_test,
      type: type,
    };

    const availableTypes = Array.from(this.props.fields!.values());
    const fetchingStart = availableTypes.reduce((acc, v) => ({ ...acc, [v]: true }), {});
    const fetchingStop = availableTypes.reduce((acc, v) => ({ ...acc, [v]: false }), {});
    const options = availableTypes.reduce((acc, v) => ({ ...acc, [v]: [] }), {});

    this.setState({
      fetching: {
        ...this.state.fetching,
        ...fetchingStart,
      },
      bindings: [],
      bindingsTypesOptions: {
        ...this.state.bindingsTypesOptions,
        ...options,
      },
    });

    return request
      .get<WebAPIResponse<Binding>>(api.bindings(), { params })
      .then((response) => response.data.list)
      .then((bindings) => {
        this.table.make(bindings);

        const type = this.table.findFirstType();

        if (type) {
          // const selected = this.table.getBindings(type);
          // this.setOptionsByType(type, selected);
          this.setBindingTypeOptions(type);
        }

        this.setState({
          bindings: bindings,
        });

        return bindings;
      })
      .finally(() => {
        this.setState({
          fetching: {
            ...this.state.fetching,
            ...fetchingStop,
          },
        });
      });
  }

  handleChangeBuilding = (buildingId: string) => {
    this.setBuildingValue(buildingId);
  };

  private setBuildingValue(buildingId: string) {
    this.props.onChangeBuilding?.(buildingId);

    this.setState({
      selected: {
        // ...this.state.selected,
        building_id: buildingId,
      },
    });

    // зануляем биндинги связанные с билдинг
    const { name, fields: bindingTypes } = this.props;
    const fields = Array.from(bindingTypes);
    for (let i = 0; i < fields.length; i++) {
      const bindingType = fields[i];
      this.props.form.setFieldValue(`${name}.${bindingType}`, '', false);
    }

    if (buildingId) {
      this.fetchBindingsByBuilding(buildingId).catch(console.error);
    }
  }

  handleChangeBinding = (type: BindingType, bindingId: string) => {
    const nextType = this.table.findNextType(type);

    const selected: Record<string, string> = {};
    if (nextType) {
      if (bindingId) {
        this.setBindingTypeOptions(nextType, bindingId);
      }

      // зануляем все биндинги, которые ниже по иерархии
      const { name, fields: bindingTypes } = this.props;
      const fields = Array.from(bindingTypes);

      let canUnsetNextFields = false;
      for (let i = 0; i < fields.length; i++) {
        const bindingType = fields[i];

        if (bindingType === nextType) {
          canUnsetNextFields = true;
        }

        if (!canUnsetNextFields) {
          continue;
        }

        this.props.form.setFieldValue(`${name}.${bindingType}`, '', false);
        selected[bindingType] = '';
      }
    }

    selected[type] = bindingId;
    this.setBindingTypeValueBatch(selected);
  };

  private setBindingTypeValue(type: BindingType, bindingId: string) {
    this.props.onChangeType?.(type, bindingId);

    this.setState((prevState) => {
      return {
        selected: {
          ...prevState.selected,
          [type]: bindingId,
        },
      };
    });
  }

  private setBindingTypeValueBatch(selected: Record<string, string>) {
    if (this.props.onChangeType) {
      Object.keys(selected).forEach((type) =>
        this.props.onChangeType!(type as BindingType, selected[type])
      );
    }

    this.setState((prevState) => {
      return {
        selected: {
          ...prevState.selected,
          ...selected,
        },
      };
    });
  }

  private setBindingTypeOptions(type: BindingType, parentId?: string) {
    const bindings = this.table.getBindings(type, parentId);

    this.setState({
      bindingsTypesOptions: {
        ...this.state.bindingsTypesOptions,
        [type]: bindingsToOptions(bindings),
      },
    });
  }

  render() {
    const {
      field,
      form,
      meta,

      name,
      fields,
    } = this.props;

    const { buildings, bindings, buildingsOptions, bindingsTypesOptions, fetching, selected } =
      this.state;

    return this.props.children({
      field,
      form,
      meta,

      name,
      fields,
      onChangeBuilding: this.handleChangeBuilding,
      onChangeType: this.handleChangeBinding,
      buildingsOptions: buildingsOptions,
      bindingsTypesOptions: bindingsTypesOptions,

      bindings,
      buildings,
      fetching,
      selected,
    });
  }
}
