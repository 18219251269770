import { createSlice } from '@reduxjs/toolkit';
import { DeviceReference } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createFetchEntity,
} from './entities';
import api from '../../constants/api';

const slice = createSlice({
  name: 'devices-references',
  initialState: createEntitiesInitialState<DeviceReference>(),
  reducers: createDefaultEntitiesReducers<DeviceReference>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchDeviceReferences = createFetchEntities(api.deviceReference(), slice.actions);
export const fetchDeviceReference = createFetchEntity(api.deviceReference, slice.actions);
