import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { LabelStatus } from 'components/share/components/status/label';
import { hintOperationalStatus } from '../../utils';

interface Props {
  id: string;
  message: string;
  isOnline: boolean;
  view: (id: string) => Partial<Location>;
}

export function PhoneStatus(props: Props) {
  const { id, message, isOnline, view } = props;

  const popup = useMemo(() => {
    return {
      content: hintOperationalStatus(isOnline),
      wide: !isOnline ? ('very' as const) : undefined,
    };
  }, [isOnline]);

  const color = isOnline ? 'green' : 'red';
  return (
    <LabelStatus color={color} popup={popup}>
      <Link to={view(id)}>{message}</Link>
    </LabelStatus>
  );
}
