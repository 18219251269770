import React from 'react';
import { Menu } from 'semantic-ui-react';
import ProfileDropdown from '../../../components/profile';
// import { User } from 'core/constants/contracts';

interface Props {
  // user: User;
}

function ProfileMenuItem(props: Props) {
  return (
    <Menu.Item>
      <ProfileDropdown
      // user={props.user}
      />
    </Menu.Item>
  );
}

export default ProfileMenuItem;
