import { createSlice } from '@reduxjs/toolkit';
import { UserCategory } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createFetchEntity,
} from './entities';
import api from '../../constants/api';

const slice = createSlice({
  name: 'user_categories',
  initialState: createEntitiesInitialState<UserCategory>(),
  reducers: createDefaultEntitiesReducers<UserCategory>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchUserCategories = createFetchEntities(api.userCategories(), slice.actions);
export const fetchUserCategory = createFetchEntity(api.userCategories, slice.actions);
