import { ComponentType } from 'react';

export function printDiffProps(
  prevProps: Record<string, any>,
  nextProps: Record<string, any>,
  group = 'PROPS'
) {
  console.group('DIFF ' + group);

  const now = Object.entries(nextProps);

  const added = now.filter(([key, val]) => {
    if (prevProps[key] === undefined) {
      return true;
    }

    if (prevProps[key] !== val) {
      console.log(`${key}
            - ${typeof prevProps[key]} ${JSON.stringify(prevProps[key])}
            + ${typeof val} ${JSON.stringify(val)}`);
    }

    return false;
  });

  added.forEach(([key, val]) =>
    console.log(`${key} 
            + ${typeof val} ${JSON.stringify(val)}`)
  );

  console.groupEnd();
}

export function withStaticProperties<C extends ComponentType, SP extends Record<string, any>>(
  component: C,
  staticProperties: SP
): C & SP {
  Object.keys(staticProperties).forEach((key) => {
    (component as any)[key] = staticProperties[key];
  });

  return component as C & SP;
}
