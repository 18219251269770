import '../styles.less';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import {
  reduxForm,
  InjectedFormProps,
  formValueSelector,
  initialize,
  getFormValues,
} from 'redux-form';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from 'core/store';
import get from 'lodash/get';
import { createFetchData } from 'views/pages/devices/abstract/create/data/fetch';
import { Intercom, CameraStreamMode } from 'core/constants/contracts';
import { confirm } from 'components/share/dialogs';
import { saveCamera, updateCamera } from 'core/store/features/cameras';
import notification from 'core/utils/notification';
import { CameraForm, CameraFormData, schema } from 'components/share/containers/camera';
import { parseUrl, urlBuilder, UrlType } from 'core/utils/url';
import { DataForCamera } from 'components/share/containers/camera/form/config';
import { FORM_ID as FORM_ID_STEP1 } from '../step1';
import * as RouteUrl from 'core/constants/routes';
import { validate } from '../utils';

import { fetchRestreamers } from 'core/store/features/restreamers';
import { getRestreamersItems } from 'core/store/selectors/restreamers';
import { createSelectArrayToArray } from 'core/store/selectors';
import { Restreamer } from 'core/constants/contracts';
import { DropdownItemProps } from 'semantic-ui-react';

type Props = ConnectedProps<typeof withConnect> &
  RouteComponentProps &
  InjectedFormProps<CameraFormData, Props>;

type State = {
  isFetching: boolean;
};

class Step4 extends React.Component<Props, State> {
  state = {
    isFetching: false,
  };

  componentDidMount() {
    const {
      intercom: { camera },
      streamMode: stream_mode,
      initializeForm,
      getRestreamers,
    } = this.props;

    const restreamerSettings = { stream_mode };

    getRestreamers();

    if (camera) {
      const { name, streams, device } = camera;

      if (streams) {
        const url = streams[Object.keys(streams)[0]];
        const urlObj = parseUrl(decodeURIComponent(url));

        if (urlObj) {
          const { protocol, username, password, hostname, port, path } = urlObj;
          initializeForm({
            name: name ?? '',
            protocol,
            username,
            password,
            hostname,
            port,
            path,
            url,
            timezone: device?.timezone,
            ...restreamerSettings,
          });
        } else {
          initializeForm({
            name: name ?? '',
            url,
            timezone: device?.timezone,
            ...restreamerSettings,
          });
        }
      }
    } else {
      initializeForm({
        protocol: 'rtsp',
        url: urlBuilder({ protocol: 'rtsp' }),
        timezone: '+03:00',
        ...restreamerSettings,
      });
    }
  }

  changeIsFetching = (isFetching: boolean) => this.setState({ isFetching });

  routeToIntercom = () => this.props.history.push(RouteUrl.intercomView(this.props.intercom.id));

  handleSkip = () => {
    confirm({
      content: 'Вы уверены, что хотите пропустить сохранение данных камеры?',
      onConfirm: this.routeToIntercom,
    });
  };

  handleGetStreamFromRestremer = async () => {
    const { intercom, updateCamera, change, restreamers } = this.props;
    const [restreamer] = restreamers;

    this.setState({ isFetching: true });
    let cameraResult, message;

    const data: Partial<DataForCamera> = {
      restreamer: restreamer.value as string,
      stream_mode: CameraStreamMode.restreamer,
    };

    if (intercom.camera) {
      try {
        cameraResult = await updateCamera(intercom.camera.id, data);
        change('rtsp', get(cameraResult, 'list[0].rtsp'));
      } catch (e) {
        console.error(e);
        message = (e as any).message;
      } finally {
        this.setState({ isFetching: false });
      }
    }

    if (message) {
      notification.error(message);
    }
  };

  handleNext = async (values: CameraFormData) => {
    const {
      name,
      timezone,
      protocol,
      username,
      password,
      hostname,
      port,
      path,
      stream_mode,
      rtsp,
    } = values;
    const { intercom, updateCamera, createCamera, restreamers } = this.props;
    const [restreamer] = restreamers;

    confirm({
      content: 'Вы уверены, что хотите сохранить данные камеры?',
      onConfirm: async () => {
        this.setState({ isFetching: true });
        let cameraResult, message;

        const data: DataForCamera = {
          stream: {
            protocol,
            username,
            password,
            hostname,
            port: port ? +port : undefined,
            path,
          },
          name,
          device: {
            timezone: timezone === '' ? null : timezone,
          },
          stream_mode,
          rtsp,
        };

        if (stream_mode === CameraStreamMode.restreamer)
          data.restreamer = restreamer.value as string;

        if (intercom.camera) {
          try {
            cameraResult = await updateCamera(intercom.camera.id, data);
            message = get(cameraResult, 'error');
          } catch (e) {
            console.error(e);
            message = (e as any).message;
          } finally {
            this.setState({ isFetching: false });
          }
        } else {
          try {
            cameraResult = await createCamera({
              ...data,
              parent: intercom.device.id,
            });
            message = get(cameraResult, 'error');
          } catch (e) {
            console.error(e);
            message = (e as any).message;
          } finally {
            this.setState({ isFetching: false });
          }
        }

        if (message) {
          notification.error(message);
        } else {
          notification.success();
          setTimeout(this.routeToIntercom, 1000);
        }
      },
    });
  };

  handleValidate = (values: CameraFormData) => {
    validate(values);
  };

  render() {
    const { isFetching } = this.state;
    const { invalid, url, urlString, change, anyTouched, handleSubmit, streamMode, rtsp } =
      this.props;

    return (
      <CameraForm
        formProps={{ invalid, change, anyTouched }}
        formData={{ url, urlString, streamMode, rtsp }}
        isFetching={isFetching}
        setIsFetching={this.changeIsFetching}
        handleCancel={this.handleSkip}
        handleSubmit={handleSubmit(this.handleNext)}
        handleGetStream={this.handleGetStreamFromRestremer}
        validateFields={handleSubmit(this.handleValidate)}
        isAdmin={this.props.isAdmin}
      />
    );
  }
}

export const FORM_ID = 'create_intercom.step4';
const selector = formValueSelector(FORM_ID);

const Step4ReduxForm = reduxForm<CameraFormData, Props>({
  form: FORM_ID,
  validate: validate(schema),
})(Step4);

const fetchData = createFetchData(['intercom']);
const selectDeviceReferencesAsOptions = createSelectArrayToArray<Restreamer, DropdownItemProps>(
  (v) => ({
    value: v.id,
    text: v.name,
  })
);
const mapStateToProps = (state: RootState) => {
  const { id } = getFormValues(FORM_ID_STEP1)(state) as {
    id: string;
  };
  const intercom = (state.app.intercom.items as { [key: string]: { item: Intercom } })[id].item;

  const url: UrlType = selector(
    state,
    'protocol',
    'hostname',
    'port',
    'path',
    'username',
    'password'
  );
  const urlString: string = selector(state, 'url');
  const rtsp: string = selector(state, 'rtsp');

  return {
    intercom,
    url,
    urlString,
    restreamers: selectDeviceReferencesAsOptions(
      getRestreamersItems(state)
    ) as Array<DropdownItemProps>,
    streamMode: CameraStreamMode.camera,
    rtsp,
    isAdmin: state.appx.user?.isAdmin || false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createCamera: bindActionCreators(saveCamera, dispatch),
    updateCamera: bindActionCreators(updateCamera, dispatch),
    getRestreamers: bindActionCreators(fetchRestreamers, dispatch),
    fetchData: bindActionCreators(fetchData, dispatch),
    initializeForm: (data: Partial<CameraFormData>) => dispatch(initialize(FORM_ID, data)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Step4ReduxForm);
