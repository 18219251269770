import { get } from 'lodash';
import {
  BINDING_TYPE_APARTMENT,
  BINDING_TYPE_ENTRANCE,
  BINDING_TYPE_ENTRANCES_UNION,
  BINDING_TYPE_FLOOR,
} from '../constants/domain/binding';

export const title = (val, preposition) => {
  return (val && `${preposition} ${val}`) || '';
};

export const extractFromBindings = (bindings, what) => {
  let result = [];

  bindings.forEach((item) => {
    if (item?.type === what) {
      result.push(item.number);
    }
  });

  return result.sort().join(', ');
};

export const buildAddressStr = ({ bindings = [], building = {} } = {}, delimiter = ', ') => {
  let addressParts = [
    get(building, 'address.text'),
    title(extractFromBindings(bindings, BINDING_TYPE_ENTRANCES_UNION), 'группа'),
    title(extractFromBindings(bindings, BINDING_TYPE_ENTRANCE), 'подъезд'),
    title(extractFromBindings(bindings, BINDING_TYPE_FLOOR), 'этаж'),
    title(extractFromBindings(bindings, BINDING_TYPE_APARTMENT), 'кв.'),
  ].filter(Boolean);

  if (!addressParts.length) {
    return 'Адрес отсутствует';
  }

  return addressParts.join(delimiter);
};

export const buildGroupAddressStr = (bindings = [], buildings = []) => {
  return groupAddress(bindings, buildings).map((val) => {
    const parts = [val.address, val.entrance, val.floor, val.apartment];

    return parts.filter((v) => !!v).join(', ');
  });
};

export const groupAddress = (bindings = [], buildings = []) => {
  return buildings
    .map((building) => {
      if (bindings.length === 0) {
        return null;
      }

      const bindingsOfBuilding = bindings.filter((binding) => {
        return building.id === binding.index;
      });

      const address = get(building, 'address.text', '');
      const entrance = title(
        extractFromBindings(bindingsOfBuilding, BINDING_TYPE_ENTRANCE),
        'подъезд'
      );
      const floor = title(extractFromBindings(bindingsOfBuilding, BINDING_TYPE_FLOOR), 'этаж');
      const apartment = title(
        extractFromBindings(bindingsOfBuilding, BINDING_TYPE_APARTMENT),
        'кв.'
      );

      return {
        address,
        entrance,
        floor,
        apartment,
      };
    })
    .filter((v) => !!v);
};
