import React, { FC, useEffect, useState, useCallback } from 'react';
import { DropdownItemProps, Form } from 'semantic-ui-react';
import { BindingType, DeviceReference } from 'core/constants/contracts';
import { Dropdown } from '../../../dropdown';
import { useFormikContext } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { fetchBindings, clear as clearBindings } from 'core/store/features/bindings';
import { fetchBuildings } from 'core/store/features/buildings';
import { selectBindingsAsArray } from 'core/store/selectors/bindings';
import { selectBuildingsAsArray } from 'core/store/selectors/buildings';
import { bindingsToOptions, buildingsToOptions } from '../../handler/utils';
import { IntercomAgg } from 'core/store/selectors/intercoms';

type ConnectProps = ConnectedProps<typeof withConnect>;

export interface FormValues {
  [BindingType.building]: string | string[];
  [BindingType.entrance]: string | string[];
  [BindingType.apartment]: string;
}

type AddressBindingOwnProps = {
  intercom?: IntercomAgg;
  deviceType: 'intercom' | 'barrier' | 'camera';
  setFieldValue: (field: string, value: unknown) => void;
};

type AddressBindingProps = AddressBindingOwnProps & ConnectProps;

const AddressBindingForm: FC<AddressBindingProps> = ({
  intercom,
  buildingsOptions,
  buildingsLoading,
  bindingsTypesOptions,
  bindingsLoading,
  clearBindings,
  deviceType,
  setFieldValue,
  fetchBuildings,
  fetchBindings,
}) => {
  const {
    values: { building, entrance },
  } = useFormikContext<FormValues>();
  const bindingsLevels: DeviceReference['bindings_levels'] =
    intercom?.__device_ref?.bindings_levels || [];

  const isCamera = deviceType === 'camera';
  const isBarrier = deviceType === 'barrier';

  const canBindApartment = bindingsLevels
    ? bindingsLevels.some((item) => item === BindingType.apartment)
    : false;

  const [entranceOptions, setEntranceOptions] = useState<Array<DropdownItemProps>>([]);
  const [apartmentOptions, setApartmentOptions] = useState<Array<DropdownItemProps>>([]);

  const clearEntrance = useCallback(() => {
    clearBindings();
    setFieldValue('entrance', isCamera ? '' : []);
    setFieldValue('apartment', '');
    setEntranceOptions([]);
    setApartmentOptions([]);
  }, [isCamera, clearBindings, setFieldValue]);

  const clearApartment = useCallback(() => {
    setFieldValue('apartment', '');
    setApartmentOptions([]);
  }, [setFieldValue]);

  useEffect(() => {
    fetchBuildings({ limit: 3000, filter: { is_active: true } });
  }, [fetchBuildings]);

  useEffect(() => {
    if (building) {
      fetchBindings({
        limit: 3000,
        building: building,
        is_active: true,
      });
    }
  }, [building, fetchBindings]);

  useEffect(() => {
    // Строим массив подъездов
    setEntranceOptions(bindingsTypesOptions.filter((item) => item.type === BindingType.entrance));

    // Строим массив квартир подъезда
    if (canBindApartment) {
      const floors = bindingsTypesOptions.filter(
        (item) => item.type === BindingType.floor && item.parent === entrance[0]
      );

      let floorsApartments: Array<DropdownItemProps> = [];

      floors.forEach(
        (floor) =>
          (floorsApartments = [
            ...floorsApartments,
            ...bindingsTypesOptions.filter((item) => item.parent === floor.value),
          ])
      );

      setApartmentOptions(floorsApartments);
    }
  }, [bindingsTypesOptions, canBindApartment, entrance]);

  useEffect(() => {
    if (building.length === 0 || building === '') {
      clearEntrance();
    }
  }, [building, clearEntrance]);

  useEffect(() => {
    if (entrance.length === 0 || entrance.length > 1) {
      clearApartment();
    }
  }, [entrance, clearApartment]);

  if (isBarrier) {
    return (
      <Dropdown
        name={BindingType.building}
        label="Адрес"
        required
        options={buildingsOptions}
        loading={buildingsLoading}
        search
        multiple
        clearable
      />
    );
  }

  if (canBindApartment) {
    return (
      <>
        <Dropdown
          name={BindingType.building}
          label="Адрес"
          required
          options={buildingsOptions}
          loading={buildingsLoading}
          search
          clearable
        />
        <Form.Group widths="equal">
          <Dropdown
            name={BindingType.entrance}
            label="Подъезд"
            options={entranceOptions}
            loading={bindingsLoading}
            disabled={entranceOptions.length === 0}
            multiple
            clearable
          />
          <Dropdown
            name={BindingType.apartment}
            label="Квартира"
            options={apartmentOptions}
            disabled={apartmentOptions.length === 0 || entrance.length > 1}
            clearable
          />
        </Form.Group>
      </>
    );
  }

  return (
    <Form.Group widths="equal">
      <Dropdown
        name={BindingType.building}
        label="Адрес"
        required
        options={buildingsOptions}
        loading={buildingsLoading}
        search
        clearable
      />
      <Dropdown
        name={BindingType.entrance}
        label="Подъезд"
        options={entranceOptions}
        loading={bindingsLoading}
        disabled={entranceOptions.length === 0}
        multiple={!isCamera}
        required={isCamera}
        clearable
      />
    </Form.Group>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    buildingsOptions: buildingsToOptions(selectBuildingsAsArray(state)),
    buildingsLoading: state.entities.buildings.pending,
    bindingsTypesOptions: bindingsToOptions(selectBindingsAsArray(state)),
    bindingsLoading: state.entities.bindings.pending,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchBuildings: bindActionCreators(fetchBuildings, dispatch),
    fetchBindings: bindActionCreators(fetchBindings, dispatch),
    clearBindings: bindActionCreators(clearBindings, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const AddressBinding = withConnect(AddressBindingForm);
