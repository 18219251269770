import React from 'react';
import PropTypes from 'prop-types';
import Wizard from 'components/share/components/wizard';
import Step1 from './step1';
import Step2 from './step2';
import { withRouter } from 'react-router';

const STEP_1 = 'step1';
const STEP_2 = 'step2';
const STEP_3 = 'step3';

const steps = [
  {
    key: STEP_1,
    icon: 'address card',
    title: 'Контрагент',
  },
  {
    key: STEP_2,
    icon: 'group',
    title: 'Контактное лицо',
  },
];

const components = {
  [STEP_1]: Step1,
  [STEP_2]: Step2,
};

const transitions = [
  {
    from: STEP_1,
    to: STEP_2,
  },
  {
    from: STEP_2,
    to: STEP_3,
  },
];

@withRouter
class CompanyCreateWizard extends React.Component {
  render() {
    return (
      <Wizard
        stateful
        href="/companies/create/:step"
        defaultStep={STEP_1}
        header="Добавить управляющую компанию"
        steps={steps}
        formProps={{
          history: this.props.history,
        }}
        transitions={transitions}
        components={components}
      />
    );
  }
}

CompanyCreateWizard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default CompanyCreateWizard;
