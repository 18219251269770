import {
  // COMPANY_CREATE_REQUEST,
  COMPANY_SAVE_SUCCESS,
  // COMPANY_CREATE_FAIL,
  COMPANY_REQUEST,
  COMPANY_SUCCESS,
  COMPANY_FAIL,
} from 'core/constants/actions';

const defaultState = {
  item: {},
  isFetching: false,
  error: null,
};

export default function company(state = {}, action) {
  switch (action.type) {
    case COMPANY_REQUEST: {
      return {
        ...state,
        [action.payload.id]: {
          ...defaultState,
          isFetching: true,
        },
      };
    }
    case COMPANY_SUCCESS:
    case COMPANY_SAVE_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          item: action.payload.item,
        },
      };
    }
    case COMPANY_FAIL: {
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || {}),
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    default:
      break;
  }

  return state;
}
