import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StepGroup } from 'mts-ui';

export default class Steps extends Component {
  render() {
    let { items, active, ...rest } = this.props;

    let completed = true;
    items.forEach((item) => {
      let itemIsActive = false;
      if (active === item.key) {
        completed = false;
        itemIsActive = true;
      }
      item.completed = completed;
      item.active = itemIsActive;
    });

    return <StepGroup {...rest} items={items} />;
  }
}

Steps.propTypes = {
  items: PropTypes.array,
  active: PropTypes.string,
};
