import { createSelector } from 'reselect';
import { RootState } from '..';
import { GenericParametricSelectorNullable } from './index';
import { Building } from '../../constants/contracts';

interface BuildingIdProps {
  buildingId: string;
}

// @ts-ignore
export const getBuildings = (state: RootState) => state.entities.buildings;
export const getBuildingsItems = (state: RootState) => state.entities.buildings.items;
export const getId = (state: RootState, props: BuildingIdProps) => props.buildingId;
export const getById = (state: RootState, { id }: { id: string }) =>
  state.entities.buildings.items[id];

export const createSelectBuilding = (): GenericParametricSelectorNullable<
  BuildingIdProps,
  Building
> => createSelector([getBuildingsItems, getId], (buildings, buildingId) => buildings[buildingId]);

export const selectBuildingsAsArray = createSelector([getBuildingsItems], (buildings) =>
  Object.values(buildings)
);
