import { PromiseThunk } from 'core/store';
import { fetchIntercom } from 'core/store/features/intercoms';
import { getIntercomsItems } from 'core/store/selectors/intercoms';
import { startSectionLoading, stopSectionLoading } from 'core/store/features/app';
import { fetchCompanies } from 'core/store/features/companies';
import { fetchDeviceCategories } from 'core/store/features/devices-categories';
import { createSelectDeviceCategoriesByAlias } from 'core/store/selectors/devices-categories';
import { fetchDeviceReferences } from 'core/store/features/devices-references';

export const SECTION_LOADING_NAME = 'edit_device';

export const fetchData = (intercomId: string, type: 'intercom' | 'barrier'): PromiseThunk => {
  return (dispatch, getState) => {
    dispatch(startSectionLoading(SECTION_LOADING_NAME));

    return dispatch(fetchIntercom(intercomId))
      .then(() => {
        const intercom = getIntercomsItems(getState())[intercomId];
        const deviceId = intercom?.device.id;
        const referenceId = intercom?.device.reference;

        if (!deviceId || !referenceId) {
          return;
        }

        const promises = [
          dispatch(fetchDeviceCategories()),
          dispatch(fetchCompanies({ limit: 1000 })),
        ];

        return Promise.all(promises);
      })
      .then(() => {
        const selectDeviceCategoriesByAlias = createSelectDeviceCategoriesByAlias([type]);
        const categories = selectDeviceCategoriesByAlias(getState());
        if (categories.length === 0) {
          return;
        }

        const categoriesIds = categories.map((v) => v.id);

        return dispatch(
          fetchDeviceReferences({
            limit: 1000,
            filter: { category: categoriesIds },
          })
        );
      })
      .finally(() => dispatch(stopSectionLoading(SECTION_LOADING_NAME)));
  };
};
