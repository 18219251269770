import React, { PropsWithChildren, useCallback, useState } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { LinkButton } from '../link/variants/button';

const Container = styled.div`
  margin-top: 22px;
  display: none;

  &.open {
    display: block;
  }
`;

interface Props {
  message: string;
  className?: string;
  defaultOpen?: boolean;
}

export function Collapser(props: PropsWithChildren<Props>) {
  const { message, className, defaultOpen = false } = props;

  const [isOpen, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  return (
    <div className={cx('collapser', className)}>
      <LinkButton icon={'arrow-bottom'} onClick={handleClick} defaultOpen={defaultOpen}>
        {message}
      </LinkButton>
      <Container className={cx({ open: isOpen }, 'container')}>{props.children}</Container>
    </div>
  );
}
