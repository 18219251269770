import { combineReducers } from 'redux';

import items from './intercom/items';
import users from './intercom/users';
import property from './intercom/property';

export default combineReducers({
  users,
  items,
  property,
});
