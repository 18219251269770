import { ThemeVars } from 'core/styles/vars';
import styled from 'styled-components';

export const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${ThemeVars.colors.greyChateau};
  margin-bottom: 0.5rem;
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${ThemeVars.colors.deepBlue};
  }
`;
