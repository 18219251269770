export interface DataForVideoRecorder {
  name?: string;
  protocol?: string;
  hostname?: string;
  port?: number | string;
  rtsp_port?: number | string;
  username?: string;
  password?: string;
  device: {
    reference: string;
    owner: string;
  };
}

export const videoRecorderProtocolOptions = [
  { key: 'http', value: 'http:', text: 'HTTP' },
  { key: 'https', value: 'https:', text: 'HTTPS' },
];
