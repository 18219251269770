import React from 'react';
import { Route, Router, Switch } from 'react-router';
import PageRoute, { PageRouteProps } from './core/router/route';
import guard, { GuardProps } from './core/router/guard';
//layouts
import MainLayout from 'views/layouts/responsive';
import ClearLayout from 'views/layouts/clear';
//pages
import SignInPage from 'views/pages/security/sign-in';
import NewPasswordPage from 'views/pages/security/new-password';
import ResetPasswordPage from 'views/pages/security/reset-password';
import MainPage from 'views/pages/main';
import NotFound from 'views/pages/errors/page404';
import Intercoms from 'views/pages/devices/pages/intercoms';
import Cameras from 'views/pages/cameras';
import VideoRecorders from 'views/pages/video-recorders';
import Barriers from 'views/pages/devices/pages/barriers';
import Companies from 'views/pages/companies';
import ViewReport from './views/pages/reports';
import Buildings from 'views/pages/buildings';
import Clients from 'views/pages/clients';
import Integrations from 'views/pages/integrations';
import CloudArchive from 'views/pages/cloud-archive';

interface Props extends GuardProps, PageRouteProps {}

const AdvancedRoute: React.FunctionComponent<Props> = guard(PageRoute);

interface RoutesProps {
  history: any;
}

const Routes: React.FunctionComponent<RoutesProps> = ({ history }) => {
  return (
    <Router history={history}>
      <Switch>
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/'}
          page={MainPage}
          privacy={true}
          breadcrumbs={false}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/intercoms/create/:step?'}
          page={Intercoms.Create}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/intercoms/:id'}
          page={Intercoms.View}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/intercoms/:id/edit'}
          page={Intercoms.Edit}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/intercoms'}
          page={Intercoms.List}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/intercoms/:id/history'}
          page={Intercoms.History}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/intercoms/:intercomId/cameras/:cameraId/edit'}
          page={Cameras.Edit}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/intercoms/:intercomId/cameras/create'}
          page={Cameras.Create}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/cameras'}
          page={Cameras.List}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/cameras/:cameraId/edit'}
          page={Cameras.Edit}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/video-recorders'}
          page={VideoRecorders.List}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/video-recorders/:videoRecorderId/edit'}
          page={VideoRecorders.Edit}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/video-recorders/create'}
          page={VideoRecorders.Create}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/cloud-archive'}
          page={CloudArchive.List}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/barriers/create/:step?'}
          page={Barriers.Create}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/barriers/:id'}
          page={Barriers.View}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/barriers/:id/edit'}
          page={Barriers.Edit}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/barriers/:id/history'}
          page={Barriers.History}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/barriers'}
          page={Barriers.List}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/companies/create/:step?'}
          page={Companies.Create}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/companies/:id/edit'}
          page={Companies.Edit}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/companies/:id/buildings'}
          page={Companies.BuildingsList}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/companies/:id'}
          page={Companies.View}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/companies'}
          page={Companies.List}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/companies/:companyId/user/:userId/edit'}
          page={Companies.UserEdit}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/buildings'}
          page={Buildings.List}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/buildings/create'}
          page={Buildings.Create}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/buildings/:id/edit'}
          page={Buildings.Edit}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/buildings/:id/apartments'}
          page={Buildings.Apartments}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/integrations'}
          page={Integrations.List}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/integrations/:integration/sessions'}
          page={Integrations.Sessions}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/integrations/:integration/sessions/:sessionId/rows'}
          page={Integrations.Rows}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/buildings'}
          page={Buildings.List}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/buildings/create'}
          page={Buildings.Create}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/buildings/:id/edit'}
          page={Buildings.Edit}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/buildings/:id/apartments'}
          page={Buildings.Apartments}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/clients'}
          page={Clients.List}
          privacy={true}
        />
        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/clients/:id'}
          page={Clients.View}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={MainLayout}
          path={'/reports/:alias'}
          page={ViewReport}
          privacy={true}
        />

        <AdvancedRoute
          exact
          layout={ClearLayout}
          path={'/sign-in'}
          page={SignInPage}
          privacy={false}
          breadcrumbs={false}
        />
        <AdvancedRoute
          exact
          layout={ClearLayout}
          path={'/password/new'}
          page={NewPasswordPage}
          privacy={false}
          breadcrumbs={false}
        />
        <AdvancedRoute
          exact
          layout={ClearLayout}
          path={'/password/reset/:id'}
          page={ResetPasswordPage}
          privacy={false}
          breadcrumbs={false}
        />

        <Route component={NotFound} path={'*'} />
      </Switch>
    </Router>
  );
};

export default Routes;
