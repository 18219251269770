import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required('Укажите ФИО'),
  email: yup.string().email('Укажите валидный e-mail').required('Укажите E-mail'),
  phone: yup
    .string()
    .transform(function (value) {
      return this.isType(value) && value !== null ? value.replace(/([^0-9^\\+]*)/g, '') : value;
    })
    .required('Необходимо заполнить номер телефона')
    .matches(/^((\+7|7|8)+([0-9]){10})$/gm, {
      message: 'Укажите валидный номер телефона',
      excludeEmptyString: true,
    }),
});
