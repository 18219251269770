import React from 'react';
import { Grid } from 'semantic-ui-react';
import BuildingCreateContainer from './form';
import { WebHistoryProps } from 'core/constants/props';
import { SetBreadcrumb } from 'components/share/breadcrumbs';

class CreateBuildingPage extends React.PureComponent {
  render() {
    return (
      <Grid>
        <SetBreadcrumb url={'/buildings'}>Дома</SetBreadcrumb>
        <SetBreadcrumb>Добавить</SetBreadcrumb>

        <Grid.Row>
          <Grid.Column>
            <BuildingCreateContainer history={this.props.history} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

CreateBuildingPage.propTypes = {
  history: WebHistoryProps,
};

export default CreateBuildingPage;
