import React, { PropsWithChildren, MouseEvent, MutableRefObject } from 'react';

interface Props {
  innerRef: MutableRefObject<HTMLVideoElement>;
}

export class VideoElementInner extends React.Component<PropsWithChildren<Props>> {
  shouldComponentUpdate(): boolean {
    return false;
  }

  handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
  };

  render() {
    return (
      <div data-vjs-player={true} className={'vjs-intercom vjs-intercom-video-archive'}>
        <video id={'video-1'} ref={this.props.innerRef} onContextMenu={this.handleContextMenu} />
        {this.props.children}
      </div>
    );
  }
}

export const VideoElement = React.forwardRef<
  HTMLVideoElement,
  Omit<PropsWithChildren<Props>, 'innerRef'>
>((props, ref) => (
  <VideoElementInner {...props} innerRef={ref as MutableRefObject<HTMLVideoElement>} />
));
