import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Header, Message } from 'semantic-ui-react';
import ReportForm from './form';
import { fetchReport } from 'core/store/actions/reports';
import { Loader } from 'components/share';
import { RootState } from 'core/store';
import { ReportColumn, ReportFilter } from 'core/constants/contracts';
import './styles.less';
import { SetBreadcrumb } from 'components/share/breadcrumbs';

interface RouteParams {
  alias: string;
}

interface OwnProps {}

interface Props
  extends OwnProps,
    RouteComponentProps<RouteParams>,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

class ViewReport extends Component<Props> {
  fetchReport = (alias: string) => {
    (this.props.fetchReport(alias) as any).catch(() => {});
  };

  componentDidMount() {
    this.fetchReport(this.props.match.params.alias);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.match.params.alias !== prevProps.match.params.alias) {
      this.fetchReport(this.props.match.params.alias);
    }
  }

  render() {
    if (this.props.error) {
      return (
        <Message warning>
          <Message.Header>{this.props.error}</Message.Header>
        </Message>
      );
    }

    if (this.props.isFetching) {
      return <Loader active={true} />;
    }

    const alias = this.props.match.params.alias;
    const report = this.props.report;

    const columns: Array<ReportColumn> = report.columns || [];
    const filters: Array<ReportFilter> = report.filters || [];

    const initialValues = filters.reduce((acc, filter) => {
      acc[filter.id] = filter.arguments?.value;

      return acc;
    }, {} as Record<string, any>);

    return (
      <Grid className={'report'}>
        <SetBreadcrumb url={'/reports'}>Отчеты</SetBreadcrumb>
        <SetBreadcrumb>{report.name}</SetBreadcrumb>

        <Grid.Row>
          <Grid.Column>
            <Header as={'h1'}>{report.name}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ReportForm
              initialValues={initialValues}
              alias={alias}
              columns={columns}
              filters={filters}
              name={report.name}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    report: state.app.reports ? state.app.reports.report : null,
    isFetching: state.app.reports ? state.app.reports.isFetching : true,
    error: state.app.reports ? state.app.reports.error : null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchReport: bindActionCreators(fetchReport, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewReport);
