import React, { FC } from 'react';
import { ModalWindow } from 'components/share/components/modals';
import { DropdownItemProps, Form, Grid, Divider, Button } from 'semantic-ui-react';
import { Input, Dropdown } from 'components/share/form/formik';
import { ButtonGroup } from './styles';
import {
  actualOwnerOptions,
  internetProviderSelect,
} from 'views/pages/devices/pages/intercoms/config';

interface FilterModalProps {
  open: boolean;
  companies: {
    companiesOptions: DropdownItemProps[];
    companiesLoading: boolean;
  };
  dirty: boolean;
  onClose: () => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export const FilterModal: FC<FilterModalProps> = ({
  open,
  companies: { companiesOptions, companiesLoading },
  dirty,
  onClose,
  onSubmit,
}) => {
  return (
    <ModalWindow open={open} header="Расширенный фильтр" onClose={onClose}>
      <Form className="filter-form" onSubmit={onSubmit}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Input name="sip" label="SIP-номер" placeholder="SIP-номер" />
              <Input name="name" label="Название" placeholder="Название" />
              <Input name="address" label="Адрес" placeholder="Адрес" />
              <Dropdown
                name="owner_id"
                label="Владелец"
                placeholder="Владелец"
                options={companiesOptions}
                loading={companiesLoading}
                search
                clearable
              />
              <Dropdown
                name="actual_owner"
                label="Принадлежность панели"
                placeholder="Принадлежность панели"
                options={actualOwnerOptions}
                clearable
              />
              <Dropdown
                name="internet_provider"
                label="Принадлежит МТС"
                placeholder="Принадлежит МТС"
                options={internetProviderSelect}
                clearable
              />
              <Input
                name="regional_billing_account"
                label="ЛС регионального биллинга"
                placeholder="ЛС регионального биллинга"
              />
              <Input
                name="serial_number"
                label="Серийный № панели"
                placeholder="Серийный № панели"
              />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column>
              <ButtonGroup>
                <Button type="button" onClick={onClose} secondary>
                  Отменить
                </Button>
                <Button type="submit" disabled={!dirty}>
                  Применить
                </Button>
              </ButtonGroup>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </ModalWindow>
  );
};
