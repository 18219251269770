import {
  INTERCOM_CREATE_FAIL,
  INTERCOM_CREATE_REQUEST,
  INTERCOM_CREATE_SUCCESS,
  INTERCOM_FAIL,
  INTERCOM_PROPERTY_FAIL,
  INTERCOM_PROPERTY_REQUEST,
  INTERCOM_PROPERTY_SUCCESS,
  INTERCOM_REQUEST,
  INTERCOM_SUCCESS,
  INTERCOM_UPDATE_FAIL,
  INTERCOM_UPDATE_REQUEST,
  INTERCOM_UPDATE_SUCCESS,
  INTERCOM_USERS_FAIL,
  INTERCOM_USERS_REQUEST,
  INTERCOM_USERS_SUCCESS,
  INTERCOMS_FAIL,
  INTERCOMS_ITEM_FAIL,
  INTERCOMS_ITEM_REQUEST,
  INTERCOMS_ITEM_SUCCESS,
  INTERCOMS_REQUEST,
  INTERCOMS_SUCCESS,
} from '../../constants/actions';

import api from '../../constants/api';
import {
  checkException,
  checkResponse,
  formatErrorMessage,
  request,
  sortToString,
  uniqid,
} from '../../utils/request';
import { filterObject } from '../../utils/utils';
import OperationManager from '../../utils/operation';

export const statusTitleMap = [
  { name: 'Активен', value: 'active' },
  { name: 'Не активен', value: 'inactive' },
  { name: 'Дефектный', value: 'defective' },
];

export const fetchIntercoms = ({
  limit = 30,
  page = 0,
  sort = undefined,
  filter = {},
  ...rest
} = {}) => {
  return async (dispatch) => {
    dispatch({ type: INTERCOMS_REQUEST });

    let query = {
      limit,
      page,
      sort: sort && sortToString(sort),
      ...filterObject(filter, (val) => val !== ''),
      ...rest,
    };

    try {
      let response = await request.get(api.intercoms(), { params: query });
      response = checkResponse(response);

      dispatch({
        type: INTERCOMS_SUCCESS,
        payload: {
          items: response.list || [],
          metadata: response.metadata,
        },
      });

      return response;
    } catch (e) {
      dispatch({
        type: INTERCOMS_FAIL,
        payload: {
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};

export const fetchIntercom = (id) => {
  return async (dispatch) => {
    dispatch({
      type: INTERCOM_REQUEST,
      payload: { id },
    });

    try {
      let response = await request.get(api.intercoms(id));
      checkResponse(response);

      dispatch({
        type: INTERCOM_SUCCESS,
        payload: {
          id,
          item: response.data.list[0],
          metadata: response.data.metadata,
        },
      });

      return response.data.list[0];
    } catch (e) {
      dispatch({
        type: INTERCOM_FAIL,
        payload: {
          id,
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};

export const createIntercom = (data) => {
  return async (dispatch) => {
    dispatch({
      type: INTERCOM_CREATE_REQUEST,
    });

    try {
      let response = await request.post(api.intercomsV2(), data);
      const result = checkResponse(response);

      dispatch({
        type: INTERCOM_CREATE_SUCCESS,
        payload: {
          id: result.list[0].id,
          item: result.list[0],
        },
      });

      return result;
    } catch (e) {
      dispatch({
        type: INTERCOM_CREATE_FAIL,
        payload: {
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};

export const intercomAvailable = (ip, login, password, options = {}) => {
  return async (dispatch) => {
    // dispatch({
    //     type: INTERCOM_REQUEST,
    // });

    try {
      let response = await request.post(api.intercomAvailable, {
        ...options,
        login,
        password,
        ip,
      });
      const result = checkResponse(response);

      // dispatch({
      //     type: INTERCOM_SUCCESS,
      //     payload: {
      //         item: result.list[0]
      //     }
      // });

      return result;
    } catch (e) {
      if (checkException(e)) {
        throw e;
      }
      // dispatch({
      //     type: INTERCOM_FAIL,
      //     payload: {
      //         error: formatErrorMessage(e)
      //     }
      // });

      throw new Error(formatErrorMessage(e));
    }
  };
};

export const updateIntercom = ({ id, ...rest }) => {
  return async (dispatch) => {
    dispatch({
      type: INTERCOM_UPDATE_REQUEST,
      payload: { id },
    });

    try {
      let response = await request.put(api.intercomsV2(id), rest);
      checkResponse(response);

      dispatch({
        type: INTERCOM_UPDATE_SUCCESS,
        payload: {
          id,
          item: response.data.list[0],
        },
      });
    } catch (e) {
      dispatch({
        type: INTERCOM_UPDATE_FAIL,
        payload: {
          id,
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};

export const unlockIntercom = (id) => {
  return async (dispatch) => {
    dispatch({
      type: INTERCOMS_ITEM_REQUEST,
      payload: { id },
    });

    let operationId = uniqid();

    try {
      let response = await request.post(
        `${api.intercoms(id)}/unlock`,
        {},
        {
          headers: {
            'X-Operation-ID': operationId,
          },
        }
      );

      checkResponse(response);

      if (!operationId) {
        dispatch({
          type: INTERCOMS_ITEM_SUCCESS,
          payload: {
            id,
            item: response.data.list[0],
          },
        });
      } else {
        const onSuccess = function ({ id, item }) {
          dispatch({
            type: INTERCOMS_ITEM_SUCCESS,
            payload: {
              id,
              item,
            },
          });
        };

        const cb = onSuccess.bind(null, { id, item: response.data.list[0] });

        OperationManager.register(operationId, cb);
      }
    } catch (e) {
      dispatch({
        type: INTERCOMS_ITEM_FAIL,
        payload: {
          id,
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};

export const rebootIntercom = (id) => {
  return async (dispatch) => {
    dispatch({
      type: INTERCOMS_ITEM_REQUEST,
      payload: { id },
    });

    let operationId = uniqid();

    try {
      let response = await request.post(
        `${api.intercoms(id)}/reboot`,
        {},
        {
          headers: {
            'X-Operation-ID': operationId,
          },
        }
      );

      checkResponse(response);

      if (!operationId) {
        dispatch({
          type: INTERCOMS_ITEM_SUCCESS,
          payload: {
            id,
            item: response.data.list[0],
          },
        });
      } else {
        const onSuccess = function ({ id, item }) {
          dispatch({
            type: INTERCOMS_ITEM_SUCCESS,
            payload: {
              id,
              item,
            },
          });
        };

        const cb = onSuccess.bind(null, { id, item: response.data.list[0] });

        OperationManager.register(operationId, cb);
      }
    } catch (e) {
      dispatch({
        type: INTERCOMS_ITEM_FAIL,
        payload: {
          id,
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};

export const setProperty = ({ id, key, value }) => {
  return async (dispatch) => {
    dispatch({
      type: INTERCOM_PROPERTY_REQUEST,
      payload: { id, key },
    });

    let operationId = uniqid();

    try {
      let response = await request.post(
        `${api.intercoms(id)}/property`,
        { key, value },
        {
          headers: {
            'X-Operation-ID': operationId,
          },
        }
      );

      checkResponse(response);

      if (!operationId) {
        dispatch({
          type: INTERCOM_PROPERTY_SUCCESS,
          payload: {
            id,
            key,
            value: response.data.list[0].settings[key],
          },
        });
      } else {
        const onSuccess = function ({ id, item }) {
          dispatch({
            type: INTERCOM_PROPERTY_SUCCESS,
            payload: {
              id,
              key,
              value: item.settings[key],
            },
          });
        };

        const cb = onSuccess.bind(null, { id, item: response.data.list[0] });

        OperationManager.register(operationId, cb);
      }
    } catch (e) {
      dispatch({
        type: INTERCOM_PROPERTY_FAIL,
        payload: {
          id,
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};

export const fetchUsers = ({ id, limit = 30, page = 0, sort, filter = {} }) => {
  return async (dispatch) => {
    dispatch({
      type: INTERCOM_USERS_REQUEST,
      payload: { id },
    });

    let query = {
      limit,
      page,
      sort: sort && sortToString(sort),
      ...filterObject(filter, (val) => val !== ''),
    };

    try {
      let response = await request.get(`${api.intercoms(id)}/users`, { params: query });

      dispatch({
        type: INTERCOM_USERS_SUCCESS,
        payload: {
          id,
          items: response.data.list,
          metadata: response.data.metadata,
        },
      });
    } catch (e) {
      dispatch({
        type: INTERCOM_USERS_FAIL,
        payload: {
          id,
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};

export const linkUsersToDevice = async ({ id, userIds }) => {
  try {
    await request.post(`${api.intercoms(id)}/link`, { user_id: userIds });
  } catch (e) {
    throw new Error(formatErrorMessage(e));
  }
};

export const unlinkUsersToDevice = async ({ id, userIds }) => {
  try {
    await request.post(`${api.intercoms(id)}/unlink`, { user_id: userIds });
  } catch (e) {
    throw new Error(formatErrorMessage(e));
  }
};
