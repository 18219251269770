import React, { FC, useEffect } from 'react';
import { FormikProps } from 'formik';
import debounce from 'lodash/debounce';
import { Form, Icon, SemanticICONS } from 'semantic-ui-react';
import { Input, Dropdown } from 'components/share/form/formik';
import { DropdownOption } from 'components/share/redux-form/address-dropdown/handler';
import { TableFilterFormData, FormFields } from './contracts';
import { FormGroupStyled } from './styled';

type FormInnerProps = FormikProps<TableFilterFormData> & {
  companiesStore: {
    companies: Partial<DropdownOption>[];
    loadingCompanies: boolean;
  };
  fields: FormFields[];
};

const FormInner: FC<FormInnerProps> = ({
  values,
  companiesStore: { companies, loadingCompanies },
  handleSubmit,
  initialValues,
  fields,
}) => {
  useEffect(() => {
    if (values !== initialValues) {
      debounce(handleSubmit, 500)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Form onSubmit={handleSubmit}>
        <FormGroupStyled widths={3}>
        {fields.includes('sip') && (
          <Input
              name="sip"
              placeholder="SIP-номер"
              icon={<Icon name={'mts-icon-search' as SemanticICONS} />}
              hideLabel
              hideHint
          />
        )}
        {fields.includes('name') && (
          <Input
              name="name"
              placeholder="Название"
              icon={<Icon name={'mts-icon-search' as SemanticICONS} />}
              hideLabel
              hideHint
          />
        )}
        {fields.includes('address') && (
          <Input
              name="address"
              placeholder="Адрес"
              icon={<Icon name={'mts-icon-search' as SemanticICONS} />}
              hideLabel
              hideHint
          />
        )}
        {fields.includes('owner_id') && (
          <Dropdown
              name="owner_id"
              placeholder="Владелец"
              loading={loadingCompanies}
              options={companies}
              clearable
              search
              hideLabel
              hideHint
          />
        )}
        </FormGroupStyled>
    </Form>
  );
};

export default FormInner;
