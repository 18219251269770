import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Header, Icon, Segment, Table, Popup } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';

export const STATUS_LOADING = 'loading';
export const STATUS_FAILED = 'failed';
export const STATUS_SUCCESS = 'success';

export class File {
  id;
  originalFile;
  status;
  error;

  constructor(originalFile) {
    this.id = [
      originalFile.name,
      originalFile.size,
      originalFile.type,
      originalFile.lastModified,
    ].join(':');
    this.originalFile = originalFile;
    this.status = STATUS_LOADING;
  }

  getSize(accuracy = 2) {
    const sizeInKb = (this.originalFile.size / 1024).toFixed(accuracy);
    return new Intl.NumberFormat().format(sizeInKb);
  }
}

export class FileUploader {
  onFileUpload;

  upload(file) {
    throw new Error('Method not realized!');
  }
}

export const DropZone = ({ fileUploader }) => {
  const [files, setFiles] = React.useState([]);
  const { addToast } = useToasts();
  const onFileUpload = React.useCallback(
    (file) => {
      const searchFileIndex = files.findIndex((f) => {
        return f.id === file.id;
      });

      if (file.status === STATUS_FAILED) {
        addToast(`Ошибка при загрузке файла "${file.originalFile.name}": ${file.error}`, {
          appearance: 'error',
        });
      } else if (file.status === STATUS_SUCCESS) {
        addToast(`Файл ${file.originalFile.name} загружен`);
      }

      if (searchFileIndex === -1) return;

      files[searchFileIndex] = file;
      setFiles([...files]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [addToast, files]
  );

  fileUploader.onFileUpload = onFileUpload;

  const onDrop = React.useCallback(
    (acceptedFiles, rejectedFiles) => {
      const wrappedFiles = acceptedFiles.map((file) => {
        return new File(file);
      });

      setFiles([].concat(files, wrappedFiles));
      fileUploader.upload(wrappedFiles);

      rejectedFiles.forEach((file) => {
        addToast(`Файл "${file.name}" - не поддерживается`, { appearance: 'warning' });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [addToast, fileUploader, files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <>
      <Segment {...getRootProps()} placeholder className={'files-uploader-container'}>
        <Header icon>
          <Icon name="file code outline" />
          <p>Перетащите сюда файлы или нажмите для выбора файла</p>
        </Header>
        <input {...getInputProps()} />
      </Segment>

      {files.length > 0 && (
        <Segment>
          <h3 textAlign={'center'}>Загруженные файлы</h3>
          <Table striped celled>
            <Table.Body>
              {files.map((file, ind) => {
                let statusIcon = <Icon name={'circle notch'} loading color={'red'} />;
                if (file.status === STATUS_SUCCESS) {
                  statusIcon = (
                    <Icon name={'check circle'} circular color={'green'} title={'Выполнено'} />
                  );
                } else if (file.status === STATUS_FAILED) {
                  statusIcon = (
                    <Popup
                      trigger={
                        <Icon name={'close'} circular color={'red'} style={{ cursor: 'pointer' }} />
                      }
                      content={file.error || 'Что-то пошло не так'}
                    />
                  );
                }
                return (
                  <Table.Row>
                    <Table.Cell>{ind + 1}</Table.Cell>
                    <Table.Cell>{file.originalFile.name}</Table.Cell>
                    <Table.Cell>{file.originalFile.type}</Table.Cell>
                    <Table.Cell>{file.getSize()} KB</Table.Cell>
                    <Table.Cell textAlign={'center'}>{statusIcon}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      )}
    </>
  );
};
