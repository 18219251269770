import * as React from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'semantic-data-grid';
import { Label } from 'semantic-ui-react';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../../core/constants/app';

export default [
  {
    title: '#',
    type: Column.SERIAL_COLUMN,
    columnOptions: {
      width: 1,
    },
  },
  {
    title: 'Дата создания',
    type: Column.SIMPLE_COLUMN,
    value: (item: any) => {
      return moment(item.created_at).format(DATE_TIME_FORMAT);
    },
  },
  {
    title: 'Дата запуска',
    type: Column.SIMPLE_COLUMN,
    columnOptions: {
      textAlign: 'center',
    },
    value: (item: any) => {
      return item.started_at ? moment(item.started_at).format(DATE_TIME_FORMAT) : '-------';
    },
  },
  {
    title: 'Дата завершения',
    type: Column.SIMPLE_COLUMN,
    value: (item: any) => {
      return item.completed_at ? moment(item.completed_at).format(DATE_TIME_FORMAT) : '-------';
    },
  },
  {
    title: 'Файл',
    type: Column.SIMPLE_COLUMN,
    columnOptions: {
      width: 2,
    },
    value: (item: any) => (
      <Link to={`/integrations/${item.integration}/sessions/${item.id}/rows`}>{item.filename}</Link>
    ),
  },
  {
    title: 'Обработано',
    type: Column.SIMPLE_COLUMN,
    columnOptions: {
      textAlign: 'center',
    },
    value: (item: any) => {
      return (
        <Label color={'green'} circular>
          {item.positive}
        </Label>
      );
    },
  },
  {
    title: 'Ошибки',
    type: Column.SIMPLE_COLUMN,
    columnOptions: {
      textAlign: 'center',
    },
    value: (item: any) => {
      return (
        <Label color={'red'} circular>
          {item.negative}
        </Label>
      );
    },
  },
];
