import { createSelector } from 'reselect';
import { GenericSelector } from '.';
import { RootState } from '..';
import { User } from '../../constants/contracts';

interface Props {
  userId: string;
}

export const getUsers = (state: RootState) => state.entities.users;
export const getUsersItems = (state: RootState) => state.entities.users.items;
export const getUsersIds = (state: RootState) => state.entities.users.ids;
export const getUsersPending = (state: RootState) => state.entities.users.pending;
export const getId = (state: RootState, props: Props) => props.userId;

export const createSelectUsersAsArray = (): GenericSelector<Array<User>> =>
  createSelector([getUsersItems], (users) => Object.values(users));

export const selectUser = createSelector([getUsersItems, getId], (users, userId) => users[userId]);
