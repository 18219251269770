import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { fetchUser } from 'core/store/features/users';
import { createSelectMapToArray } from 'core/store/selectors';
import { Token } from 'core/constants/contracts';
import { isSectionLoading } from 'core/store/selectors/app';
import { Loader } from 'components/share';
import ErrorOnPage from 'views/components/error-on-page';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { TokensTable } from './components/tokens';
import { ClientSipInformation } from './components/client-sip';
import { fetchData, LOADER_SECTION_NAME } from './data/fetch';
import { createSelectApartmentAggregations } from './data/selectors';
import { DeviceInfo } from './components/device';

interface OwnProps {
  id: string;
}

interface Props
  extends OwnProps,
    ReturnType<ReturnType<typeof mapStateToProps>>,
    ReturnType<typeof mapDispatchToProps> {}

const TOKENS_LIMIT = 5;

class ClientContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchData({ userId: this.props.id, tokensLimit: TOKENS_LIMIT });
  }

  handleSuccessSipCreate = () => {
    this.props.fetchUser(this.props.id);
  };

  render() {
    const { isLoading, error, aggregations, client, tokens } = this.props;

    // TODO избавиться от проверки !client
    if (isLoading || !client) {
      return <Loader active={true} placeholder={true} />;
    }

    if (error) {
      return <ErrorOnPage>{error}</ErrorOnPage>;
    }

    return (
      <Grid>
        <SetBreadcrumb>{client.name}</SetBreadcrumb>

        <Grid.Row columns={'equal'}>
          <Grid.Column>
            <Header as={'h1'}>Информация о {client?.name}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment padded={'very'} className={'block'}>
              <Header as={'h4'} className={'font-weight-medium'}>
                Токены ({tokens.length} из {TOKENS_LIMIT})
              </Header>
              <TokensTable tokens={tokens} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment padded={'very'} className={'block'}>
              <Header as={'h4'} className={'font-weight-medium'}>
                SIP
              </Header>
              <ClientSipInformation
                client={client}
                aggregations={aggregations}
                onSuccess={this.handleSuccessSipCreate}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment padded={'very'} className={'block'}>
              <Header as={'h4'} className={'font-weight-medium'}>
                Устройство пользователя
              </Header>
              <DeviceInfo {...client.payload} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = () => {
  const selectTokensAsArray = createSelectMapToArray<Token>();
  // const selectIntercomsAsArray = createSelectEntitiesAsArray<Intercom>();
  const selectApartmentAggregations = createSelectApartmentAggregations();

  return function (state: RootState, props: OwnProps) {
    const userId = props.id;
    const client = state.entities.users.items[userId];
    const tokens = selectTokensAsArray(state.entities.tokens.items);
    // const intercoms = selectIntercomsAsArray(state.entities.intercoms.items);

    return {
      aggregations: selectApartmentAggregations(state, { userId }),
      client,
      tokens,
      // intercoms,
      isLoading: isSectionLoading(state, LOADER_SECTION_NAME, true),
      error:
        state.entities.users.error ?? state.entities.tokens.error ?? state.entities.intercoms.error,
    };
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchUser: bindActionCreators(fetchUser, dispatch),
    fetchData: bindActionCreators(fetchData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientContainer);
