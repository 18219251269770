import { createSlice } from '@reduxjs/toolkit';
import { IntercomCommand } from '../../constants/contracts';
import api from '../../constants/api';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
} from './entities';

const slice = createSlice({
  name: 'intercom-commands',
  initialState: createEntitiesInitialState<IntercomCommand>(),
  reducers: createDefaultEntitiesReducers<IntercomCommand>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchIntercomCommands = createFetchEntities<IntercomCommand>(
  api.intercomCommands(),
  slice.actions
);
