import { SERVICES_SUCCESS, SERVICES_FAIL, SERVICES_REQUEST } from '../../../constants/actions';

const defaultState = {
  error: null,
  isFetching: false,
  items: [],
};

export default function services(state = defaultState, action) {
  switch (action.type) {
    case SERVICES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case SERVICES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        items: action.payload.items,
      };
    }
    case SERVICES_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    }
    default:
      break;
  }

  return state;
}
