import React, { PropsWithChildren } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import tokenStorage from 'core/utils/token';

const styles = {
  height: '100vh',
};

export default function ErrorApp(props: PropsWithChildren<any>) {
  return (
    <Grid>
      <Grid.Row verticalAlign={'middle'} style={styles}>
        <Grid.Column textAlign={'center'}>
          <Header color={'red'}>{props.children}</Header>
          Произошла ошибка при загруке приложения
          <br />
          Попробуйте{' '}
          <a href={'/sign-in'} onClick={tokenStorage.clean}>
            войти
          </a>{' '}
          в аккаунт еще раз.
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
