import PropTypes from 'prop-types';

export const UserProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string,
  email: PropTypes.string,
  created_at: PropTypes.string.isRequired,
  parent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),

  is_active: PropTypes.bool.isRequired,
  is_client: PropTypes.bool.isRequired,
  is_company: PropTypes.bool.isRequired,
  is_depend: PropTypes.bool.isRequired,
  is_online: PropTypes.bool,
});

export const UserMetadataProps = PropTypes.shape({
  total: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  can_edit: PropTypes.bool.isRequired,
  can_delete: PropTypes.bool.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  latest_version: PropTypes.object,
});

export const UserItemProps = PropTypes.shape({
  item: UserProps,
  metadata: UserMetadataProps,
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.any,
});

export const UsersProps = PropTypes.shape({
  items: PropTypes.arrayOf(UserProps),
  metadata: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
});

export const CurrentUserProps = PropTypes.shape({
  user: PropTypes.oneOfType([PropTypes.shape({}), UserProps]),
  id: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),

  isAdmin: PropTypes.bool.isRequired,
  isStaff: PropTypes.bool.isRequired,
  isSupport: PropTypes.bool.isRequired,

  isFetching: PropTypes.bool.isRequired,
});

export const ServiceProps = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  alias: PropTypes.string,
  object_type: PropTypes.string,
  provider_type: PropTypes.string,
  created_at: PropTypes.string,
});

export const UserServiceProps = PropTypes.shape({
  id: PropTypes.string,
  created_at: PropTypes.string,
  started_at: PropTypes.string,
  finished_at: PropTypes.string,
  user_id: PropTypes.string,
  service: ServiceProps,
  object: PropTypes.object,
});

export const CompanyProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  role: PropTypes.string.isRequired,
  client_role: PropTypes.string.isRequired,
  detached_client_role: PropTypes.string.isRequired,
  created_at: PropTypes.string,

  administrative_status: PropTypes.oneOf(['active', 'blocked', 'archive']),
  person_type: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
  }),
  // person_type: PropTypes.oneOf([
  //     { id: 1, code: 'legal' },
  //     { id: 2, code: 'individual' },
  // ])
});

export const CompaniesProps = PropTypes.shape({
  items: PropTypes.arrayOf(CompanyProps).isRequired,
  metadata: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
});

export const CurrentCompanyProps = PropTypes.shape({
  company: PropTypes.oneOfType([PropTypes.shape({}), UserProps]),
  id: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.string,
});

export const AddressProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
});

export const EntranceProps = PropTypes.shape({
  number: PropTypes.number.isRequired,
  flats: PropTypes.shape({
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired,
  }).isRequired,
});

export const BuildingProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  entrances: PropTypes.objectOf(EntranceProps),
  address: AddressProps.isRequired,
  owner_role: PropTypes.string,
  created_at: PropTypes.string,
  fias: PropTypes.arrayOf(PropTypes.string),
  payload: PropTypes.shape({
    floors: PropTypes.number,
    year: PropTypes.number,
  }),
});

export const BuildingsProps = PropTypes.shape({
  items: PropTypes.arrayOf(BuildingProps).isRequired,
  metadata: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
});

export const BindingProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

export const BindingsProps = PropTypes.shape({
  items: PropTypes.arrayOf(BindingProps).isRequired,
  metadata: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
});

export const WebHistoryProps = PropTypes.shape({
  push: PropTypes.func.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
});

export const RouterMatchProps = PropTypes.shape({
  isExact: PropTypes.bool,
  match: PropTypes.object,
  path: PropTypes.string,
  url: PropTypes.string,
});

export const DropdownProps = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })
);

export const DeviceReferenceProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  is_active: PropTypes.bool,
  bindings_levels: PropTypes.arrayOf(PropTypes.string),
});

export const Command = PropTypes.shape({
  id: PropTypes.string.isRequired,
  alias: PropTypes.string.isRequired,
  name: PropTypes.string,
  can_customize: PropTypes.bool,
});

export const ProtocolCommand = PropTypes.shape({
  id: PropTypes.string.isRequired,
  alias: PropTypes.string,
  version: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  commands: PropTypes.arrayOf(Command),
});
