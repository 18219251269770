import React from 'react';
import LegalCompanyView from './legal';
import IndividualCompanyView from './individual';
import { Grid } from 'semantic-ui-react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { Company } from 'core/constants/contracts';

interface Props {
  company: Company;
}

const CompanyView = (props: Props) => {
  const { company } = props;

  const view =
    company.person_type === 'legal' ? (
      <LegalCompanyView name={company.person.name} personalAccount={company.personal_account} />
    ) : (
      <IndividualCompanyView
        last_name={company.person.last_name}
        first_name={company.person.first_name}
        mid_name={company.person.mid_name}
        personalAccount={company.personal_account}
      />
    );

  return (
    <div>
      <SetBreadcrumb>{company.name}</SetBreadcrumb>

      <Grid>
        <Grid.Row columns={3}>{view}</Grid.Row>
      </Grid>
    </div>
  );
};

export default CompanyView;
