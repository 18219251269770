import { PromiseThunk } from 'core/store';
import { startSectionLoading, stopSectionLoading } from 'core/store/features/app';
import { fetchCameras } from 'core/store/features/cameras';
import { fetchIntercomsByDevice } from 'core/store/features/intercoms';
import { WebAPIActionFetchParams } from 'core/utils/webapi.contracts';

export const SECTION_LOADING_NAME = 'list_cameras';

export const fetchData = (props: WebAPIActionFetchParams): PromiseThunk => {
  return (dispatch) => {
    dispatch(startSectionLoading(SECTION_LOADING_NAME));

    return dispatch(fetchCameras(props))
      .then((data) => {
        const deviceIdList = data.list.flatMap((item) => (item.parent ? item.parent : []));
        return dispatch(fetchIntercomsByDevice(deviceIdList));
      })
      .finally(() => dispatch(stopSectionLoading(SECTION_LOADING_NAME)));
  };
};
