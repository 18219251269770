import { STATUS_MAP, STATUS_MAP_COLOR } from '../constants/domain/devices';

export function getDeviceStatusTitle(statusId) {
  if (STATUS_MAP[statusId]) {
    return STATUS_MAP[statusId];
  }

  return '';
}

export function getDeviceStatusColor(statusId) {
  return STATUS_MAP_COLOR[statusId];
}
