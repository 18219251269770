import React from 'react';
import cx from 'classnames';
import { ReactComponent as Banner } from './banner.svg';
import { Button, Header } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledHeader = styled(Header)`
  &&&& {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }
`;

const Message = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;
const StyledButton = styled(Button)`
  margin-top: 32px !important;
`;

interface Props {
  className?: string;
  restart?: () => void;
}

export function VideoErrorBanner(props: Props) {
  const { restart, className } = props;

  return (
    <Container className={cx('center-horizontal', className)}>
      <Banner />
      <StyledHeader as={'h4'}>Ошибка трансляции</StyledHeader>
      <Message>
        Произошла ошибка в процессе запуска
        <br />
        трансляции камеры.
        {restart && ' Попробуйте еще раз'}
      </Message>
      {restart && (
        <div>
          <StyledButton onClick={restart}>Повторить</StyledButton>
        </div>
      )}
    </Container>
  );
}
