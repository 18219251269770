import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid, Header, Button } from 'semantic-ui-react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { IntercomsListContainer } from '../../../abstract/list';
import { createColumns } from './columns';
import * as RouteUrl from 'core/constants/routes';

export default function IntercomsPage() {
  const query = new URLSearchParams(useLocation().search);
  const columns = useMemo(() => createColumns(query), [query]);

  return (
    <Grid>
      <SetBreadcrumb>Домофоны</SetBreadcrumb>

      <Grid.Row columns="equal">
        <Grid.Column>
          <Header as="h1">Домофоны</Header>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button as={Link} to={RouteUrl.intercomCreate}>
            Добавить домофон
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <IntercomsListContainer columns={columns} category="intercom" isScrollable />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
