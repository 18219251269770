import React from 'react';
import { Spinner as SpinnerIcon } from '../../assets';
import { FC } from 'react';
import { ColorsEnum, ThemeVars } from 'core/styles/vars';
import styled, { keyframes } from 'styled-components';

export interface ISpinnerProps {
  size?: number;
  duration?: number;
  color?: ColorsEnum;
}

const rotate = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerStyled = styled(SpinnerIcon)<ISpinnerProps>`
  font-size: ${({ size }) => size}px;
  width: 1em;
  height: 1em;

  animation-name: ${rotate};
  animation-duration: ${({ duration }) => duration}ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;

  & g rect {
    color: ${({ color }) => (color ? ThemeVars.colors[color] : 'inherit')};
  }
`;

const Spinner: FC<ISpinnerProps> = ({ size = 24, duration = 1000, color, ...props }) => {
  return <SpinnerStyled size={size} duration={duration} color={color} {...props} />;
};

export default Spinner;
