export const COMPANY_ADMINISTRATIVE_STATUS_ACTIVE = 'active';
export const COMPANY_ADMINISTRATIVE_STATUS_BLOCKED = 'blocked';
export const COMPANY_ADMINISTRATIVE_STATUS_ARCHIVE = 'archive';

export const COMPANY_ADMINISTRATIVE_STATUS_MAP = {
  [COMPANY_ADMINISTRATIVE_STATUS_ACTIVE]: 'Активен',
  [COMPANY_ADMINISTRATIVE_STATUS_BLOCKED]: 'Заблокирован',
  [COMPANY_ADMINISTRATIVE_STATUS_ARCHIVE]: 'Архивный',
};

export const STATUS_ADMINISTRATIVE_COLOR_MAP = {
  [COMPANY_ADMINISTRATIVE_STATUS_ACTIVE]: 'blue',
  [COMPANY_ADMINISTRATIVE_STATUS_ARCHIVE]: 'grey',
  [COMPANY_ADMINISTRATIVE_STATUS_BLOCKED]: 'red',
};

export const COMPANY_COMMERCIAL_STATUS_TEST = 'test';
export const COMPANY_COMMERCIAL_STATUS_PILOT = 'pilot';
export const COMPANY_COMMERCIAL_STATUS_COMMERCIAL = 'commercial';

export const COMPANY_COMMERCIAL_STATUS_MAP = {
  [COMPANY_COMMERCIAL_STATUS_TEST]: 'Тестовый клиент',
  [COMPANY_COMMERCIAL_STATUS_PILOT]: 'Пилотный клиент',
  [COMPANY_COMMERCIAL_STATUS_COMMERCIAL]: 'Коммерческий клиент',
};

export const COMPANY_COMMERCIAL_STATUS_COLOR_MAP = {
  [COMPANY_COMMERCIAL_STATUS_COMMERCIAL]: 'blue',
  [COMPANY_COMMERCIAL_STATUS_PILOT]: 'grey',
  [COMPANY_COMMERCIAL_STATUS_TEST]: 'red',
};

export const COMPANY_CONTRACTOR_TYPE_LEGAL = 'legal';
export const COMPANY_CONTRACTOR_TYPE_INDIVIDUAL = 'individual';

export const COMPANY_CONTRACTOR_TYPE_MAP = {
  [COMPANY_CONTRACTOR_TYPE_LEGAL]: 'Юр. лицо',
  [COMPANY_CONTRACTOR_TYPE_INDIVIDUAL]: 'Физ. лицо',
};
