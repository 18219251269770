import * as React from 'react';
import { Column } from 'semantic-data-grid';
import { Icon, Popup } from 'semantic-ui-react';
import moment from 'moment';

import api from 'core/constants/api';
import { request } from '../../../../core/utils/request';
import { DATE_TIME_FORMAT } from '../../../../core/constants/app';
import { JSONEditable } from '../../../../components/columns/json-editable';

const updateRow = (rowId: string) => {
  return async (payload: any) => {
    await request.put(api.integrationSessionRows(rowId), { payload });
    return true;
  };
};

export default [
  {
    title: '#',
    type: Column.SERIAL_COLUMN,
    columnOptions: {
      width: 1,
    },
  },
  {
    title: 'ID',
    type: Column.SIMPLE_COLUMN,
    field: 'id',
  },
  {
    title: 'Дата',
    type: Column.SIMPLE_COLUMN,
    columnOptions: {
      textAlign: 'center',
    },
    value: (item: any) => {
      return item.created_at ? moment(item.created_at).format(DATE_TIME_FORMAT) : '-------';
    },
  },
  {
    title: 'Payload',
    component: (props: any) => {
      const onConfirm = updateRow(props.row.id);
      return <JSONEditable {...props} onConfirm={onConfirm} />;
    },
    field: 'payload',
  },
  {
    title: 'Статус',
    type: Column.SIMPLE_COLUMN,
    value: (item: any) => {
      if (item.is_failed) {
        let errorMsg: any = 'Что-то пошло не так(';
        if (item.errors && item.errors.length > 0) {
          errorMsg = (
            <ul>
              {item.errors.map((item: string) => {
                return <li>{item}</li>;
              })}
            </ul>
          );
        }
        return (
          <Popup
            trigger={<Icon name={'close'} circular color={'red'} style={{ cursor: 'pointer' }} />}
            content={errorMsg}
          />
        );
      } else if (item.is_processing) {
        return <Icon name={'sync'} circular loading color={'blue'} title={'В процессе'} />;
      } else {
        return <Icon name={'check circle'} circular color={'green'} title={'Выполнено'} />;
      }
    },
  },
  // {
  //     title: 'Registry',
  //     type: Column.SIMPLE_COLUMN,
  //     field: 'registry'
  // },
];
