import React, { PropsWithChildren } from 'react';
import { Popup, Icon, SemanticICONS } from 'semantic-ui-react';
import styled from 'styled-components';

const styles = {
  popup: {
    backgroundColor: '#1A2D41',
    color: 'white',
    // fontSize: '12px',
    // lineHeight: '16px',
  },
};

interface Props {
  cause: string;
  className?: string;
}

export function Label(props: PropsWithChildren<Props>) {
  const trigger = (
    <div className={`rounded ${props.className}`}>
      {props.children}
      <Icon name={'mts-icon-circle-warning' as SemanticICONS} />
    </div>
  );

  return (
    <Popup
      trigger={trigger}
      content={props.cause}
      style={styles.popup}
      size={'mini'}
      inverted
      position={'bottom left'}
    />
  );
}

export const WarningLabel = styled(Label)`
  float: right;
  color: white;
  background-color: #f95721;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 20px;
  display: inline-flex;

  i.icon {
    font-size: 20px;
    height: auto;
    margin: 0 0 0 8px;
  }
`;
