import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { ThemeVars } from 'core/styles/vars';
import { Field } from 'redux-form';

export const EnteranceContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 2rem !important;
  }
`;

export const EntranceFieldStyled = styled(Field)`
  &&&& {
    width: 248px !important;
    flex: 0;
  }
`;

export const LabelStyled = styled.div`
  display: flex;
  align-items: center;

  & > span {
    margin-right: 0.25rem;
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  height: fit-content;
  flex-wrap: wrap;

  & > div {
    margin-right: 3rem;
  }
`;

export const FieldBox = styled.div`
  position: relative;

  & > div.field:first-child {
    margin-bottom: -8px !important;
  }

  & > span > i {
    position: absolute;
    top: 38px;
    right: -24px;
    color: ${ThemeVars.colors.greyChateau};
    cursor: pointer;
  }
`;

export const ButtonStyled = styled(Button)`
  &&&& {
    width: max-content;
    height: fit-content;
    display: flex;
    align-self: flex-end;

    & > i {
      margin-right: 0.5rem;
    }
  }
`;

export const ButtonAdd = styled(ButtonStyled)`
  &&&&& {
    margin-bottom: 1rem;
  }
`;
