import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { Table } from './table';
import { CompanyFilter, CompanyFilterForm } from './components/filter';
import { removeEmptyValues } from 'core/utils/forms';

const defaultFilters: CompanyFilterForm = {
  name: '',
  personal_account: '',
};

const CompaniesListPage: FC = () => {
  const [filters, setFilters] = useState<CompanyFilterForm>(defaultFilters);

  return (
    <Grid>
      <SetBreadcrumb>Компании</SetBreadcrumb>

      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h1">Управляющие компании</Header>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button as={Link} primary to="/companies/create">
            Добавить компанию
          </Button>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <CompanyFilter filters={filters} setFilters={setFilters} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Table filters={removeEmptyValues(filters)} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CompaniesListPage;
