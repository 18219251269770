import React from 'react';
import { Table } from 'mts-ui';
import api from 'core/constants/api';
import { request } from 'core/utils/request';
import notification from 'core/utils/notification';
import { Intercom } from 'core/constants/contracts';
import { errorFromAxios } from 'core/utils/webapi.handlers';
import { Column } from 'components/share/components/table';
import { columns, IntercomExt } from './columns';
import { ApartmentAgg } from '../../../../data/selectors';

interface ApartmentStatusResponse {
  data?: {
    apartment: string;
    phones: string[];
    is_active: boolean;
    is_call_blocked: boolean;
  };
}

interface OwnProps {
  userId: string;
  apartment: ApartmentAgg;
  userSip?: string;
  intercoms: Array<Intercom>;
}

interface Props extends OwnProps {}

interface State {
  intercoms: Array<IntercomExt>;
  columns: Array<Column<IntercomExt>>;
}

export class Intercoms extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      intercoms: props.intercoms,
      columns: this.createColumns(props.userId, props.apartment.bindingObj?.number ?? ''),
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.intercoms !== prevProps.intercoms) {
      this.setState({ intercoms: this.props.intercoms });
    }

    if (this.props.userId !== prevProps.userId || this.props.apartment !== prevProps.apartment) {
      this.setState({
        columns: this.createColumns(this.props.userId, this.props.apartment.bindingObj?.number),
      });
    }
  }

  createColumns(userId: string, apartmentNumber: string) {
    return columns({
      userId,
      apartmentNumber,
      apartmentSipStatus: this.apartmentSipStatus,
      linkUserSip: this.linkUserSip,
    });
  }

  updateIntercom(intercomId: string, fields: Partial<IntercomExt>) {
    this.setState((prevState) => {
      return {
        intercoms: prevState.intercoms.map((v) => {
          if (v.id === intercomId) {
            v = { ...v, ...fields };
          }

          return v;
        }),
      };
    });
  }

  apartmentSipStatus = (apartmentNumber: string, intercomId: string) => {
    if (!apartmentNumber) {
      return;
    }

    this.updateIntercom(intercomId, { ext_user_sip_status: { pending: true } });

    request
      .get<ApartmentStatusResponse>(api.emergencyIntercomApartmentInfo(intercomId), {
        params: { apartment: apartmentNumber },
      })
      .then((result) => {
        const data = result.data.data;
        const isActive =
          this.props.userSip && data?.is_active ? data?.phones.includes(this.props.userSip) : false;

        this.updateIntercom(intercomId, {
          ext_user_sip_status: {
            pending: false,
            is_active: isActive,
            is_call_blocked: data?.is_call_blocked,
          },
        });
        notification.success();
      })
      .catch((err) => {
        const message = errorFromAxios(err);
        this.updateIntercom(intercomId, {
          ext_user_sip_status: { pending: false, error: message },
        });
        notification.error(message);
      });
  };

  linkUserSip = (userId: string, intercomId: string) => {
    this.updateIntercom(intercomId, { ext_link_user_sip: { pending: true } });

    request
      .post(api.emergencyIntercomLinkUserSip(intercomId), { user_id: userId })
      .then(() => {
        this.updateIntercom(intercomId, { ext_link_user_sip: { pending: false } });
        notification.success();
      })
      .catch((err) => {
        const message = errorFromAxios(err);
        this.updateIntercom(intercomId, { ext_link_user_sip: { pending: false, error: message } });
        notification.error(message);
      });
  };

  render() {
    const { columns, intercoms } = this.state;

    return <Table.Simple columns={columns} data={intercoms} />;
  }
}
