import React, { useCallback, useMemo, useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Dimmer, DropdownItemProps, Form, StrictDropdownProps } from 'semantic-ui-react';
import { saveIntercom } from 'core/store/features/intercoms';
import notification from 'core/utils/notification';
import { WebAPIResponse } from 'core/utils/webapi.contracts';
import { IntercomAvailableResponse, Intercom } from 'core/constants/contracts';
import { request } from 'core/utils/request';
import api from 'core/constants/api';
import { Loader } from 'components/share';
import { FormButtonGroup } from 'components/share/form';
import { Dropdown, Input, InputPassword } from 'components/share/form/formik';
import { createSchema } from './schema';
import { AxiosError } from 'axios';

const SCHEMA_LIST = ['https://', 'http://', 'ssh://', 'telnet://'];

const SCHEMA_PORT_REL: Record<string, string> = {
  'https://': '443',
  'http://': '80',
};

const SCHEMA_OPTIONS = SCHEMA_LIST.map((v) => {
  return {
    key: v,
    value: v,
    text: v,
  };
});

const DEFAULT_SCHEMA_VALUE: string = SCHEMA_OPTIONS[0].value;

interface FormValues {
  username?: string;
  name?: string;
  password?: string;
  reference?: string;
  schema?: string;
  ip?: string;
  port?: string;
  owner?: string;
}

interface Props {
  owners: Array<DropdownItemProps>;
  references: Array<DropdownItemProps>;
  onSubmit?: (id: string) => void;
  onCancel?: () => void;
}

export const SaveBarrierForm: FC<Props> = ({ owners, references, onSubmit, onCancel }) => {
  const schema = useMemo(() => createSchema(SCHEMA_LIST), []);
  const [isPending, setPending] = useState(false);

  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  const handleSubmit = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      setPending(true);

      async function action() {
        const dataForSave = {
          name: values.name,
          device: {
            reference: values.reference,
            protocol: values.schema,
            hostname: values.ip,
            port: +values.port!,
            owner: values.owner,
          },
          api: {
            protocol: values.schema,
            hostname: values.ip,
            port: +values.port!,
            username: values.username,
            password: values.password,
          },
        };

        const dataForAvailable = {
          login: values.username,
          password: values.password,
          ip: values.ip,
          reference: values.reference,
          port: +values.port!,
          schema: values.schema,
        };

        try {
          const response = await request.post<WebAPIResponse<IntercomAvailableResponse>>(
            api.intercomAvailable,
            dataForAvailable
          );

          const code = response.data.list[0].code;

          if (code === 'failed') {
            notification.error('Произошла ошибка');
            helpers.setFieldError('ip', 'Устройство не доступно');
          } else {
            try {
              const response = (await dispatch(
                saveIntercom(dataForSave)
              )) as unknown as WebAPIResponse<Intercom>;
              const barrierId = response.list[0].id;

              notification.success();
              setTimeout(() => onSubmit?.(barrierId), 1000);
            } catch (e) {
              notification.error(e.message);
            }
          }
        } catch (e) {
          if ((e as AxiosError).isAxiosError) {
            const errorMessage = (e as AxiosError).response?.data.error.data;
            helpers.setFieldError('ip', errorMessage);
          } else {
            notification.error('Произошла ошибка');
            helpers.setFieldError('ip', 'Устройство не доступно');
          }
        }
      }

      return action().finally(() => setPending(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [prevSchema, setPrevSchema] = useState(DEFAULT_SCHEMA_VALUE);
  const handleChangeSchema = useCallback(
    (e, data: StrictDropdownProps, props: { form: FormikProps<FormValues> }) => {
      const schema = data.value as string;
      const { form } = props;
      const { values } = form;

      if (
        schema !== prevSchema &&
        (values['port'] === '' || values['port'] === SCHEMA_PORT_REL[prevSchema])
      ) {
        const port = SCHEMA_PORT_REL[schema] ?? '';
        form.setFieldValue('port', port);
      }
      setPrevSchema(schema);
    },
    [prevSchema, setPrevSchema]
  );

  return (
    <Dimmer.Dimmable>
      <Loader active={isPending} />

      <Formik<FormValues>
        initialValues={{
          username: '',
          name: '',
          password: '',
          reference: '',
          schema: DEFAULT_SCHEMA_VALUE,
          ip: '',
          port: SCHEMA_PORT_REL[DEFAULT_SCHEMA_VALUE],
          owner: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group widths={3}>
              <Input
                name="username"
                label="Логин"
                placeholder="Логин"
                required
                autoComplete="off"
              />
              <InputPassword
                name="password"
                label="Пароль"
                placeholder="Пароль"
                required
                autoComplete="off"
              />
              <Input
                name="name"
                label="Название"
                placeholder="Название"
                required
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group widths={3}>
              <Dropdown
                name="reference"
                label="Поставщик оборудования"
                required
                options={references}
              />
              <Form.Field>
                <Form.Group widths="equal">
                  <Dropdown
                    name="schema"
                    label="Схема"
                    required
                    options={SCHEMA_OPTIONS}
                    onChange={handleChangeSchema}
                  />
                  <Input name="port" label="Порт" placeholder="Порт" required />
                </Form.Group>
              </Form.Field>
              <Input name="ip" label="IP-адрес" placeholder="IP-адрес" required />
            </Form.Group>

            <Form.Group widths={3}>
              <Form.Field>
                <Dropdown name="owner" label="Владелец" required options={owners} />
              </Form.Field>
            </Form.Group>

            <FormButtonGroup>
              <Button secondary onClick={handleCancel} disabled={formik.isSubmitting}>
                Отменить
              </Button>
              <Button type="submit" disabled={formik.isSubmitting}>
                Сохранить
              </Button>
            </FormButtonGroup>
          </Form>
        )}
      </Formik>
    </Dimmer.Dimmable>
  );
};
