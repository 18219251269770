import React, { ReactElement } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { TextArea } from 'components/share/redux-form';
import FormModalDialog from 'components/share/modals/form';

interface FormData {
  cause?: string;
}

interface Props {
  trigger: ReactElement;
  onSubmit: Function;
}

function ModalBlockBuilding(props: Props) {
  return (
    <FormModalDialog
      {...props}
      content={
        <React.Fragment>
          <div>Заблокировать строение</div>
          <Field
            key={1}
            name={'cause'}
            component={TextArea}
            label={'Причина блокировки'}
            required
          />
        </React.Fragment>
      }
    />
  );
}

const validate = (values: FormData) => {
  const error: Partial<Record<keyof FormData, string>> = {};

  if (!values.cause) {
    error.cause = 'Нужно указать причину';
  }

  if (values.cause && values.cause.length < 10) {
    error.cause = 'Минимальная длина сообщения 10 символов';
  }

  return error;
};

const shouldError = () => {
  return true;
};

const form = reduxForm<FormData, Props>({ form: 'block-building', validate, shouldError })(
  ModalBlockBuilding
);
const connected = connect()(form);

export default connected;
