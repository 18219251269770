import React from 'react';
import { Icon, Modal, ModalProps as Props } from 'semantic-ui-react';

export interface ModalProps extends Props {}

export default function ModalWrapper(props: Props) {
  const icon = props.closeIcon === true ? <Icon className={'mts-icon-close close'} /> : undefined;

  return <Modal {...props} defaultOpen={true} closeIcon={icon} />;
}
