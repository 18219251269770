import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';
import FiasSearch from '../fias-search';
import TextInput from '../text-input';

const numberFilter = (value) => {
  return value.replace(/[^\d]/g, '');
};

export default class AddressSearch extends Component {
  static defaultProps = {
    flatIsRequire: true,
    floorIsRequire: true,
    readOnly: false,
  };

  render() {
    const {
      input: { name },
      floorIsRequire,
      flatIsRequire,
      readOnly,
    } = this.props;

    return (
      <React.Fragment>
        <Field
          required
          readOnly={readOnly}
          name={`${name}.fias`}
          component={FiasSearch}
          label={'Адрес'}
          labelWidth={3}
        />
        <Form.Group>
          <Field
            name={`${name}.entrance`}
            component={TextInput}
            required
            readOnly={readOnly}
            label={'Подъезд'}
            formatter={numberFilter}
            width={4}
          />
          <Field
            name={`${name}.floor`}
            component={TextInput}
            required={floorIsRequire}
            readOnly={readOnly}
            label={'Этаж'}
            formatter={numberFilter}
            width={4}
          />
          <Field
            name={`${name}.flat`}
            component={TextInput}
            readOnly={readOnly}
            required={flatIsRequire}
            label={'Квартира'}
            formatter={numberFilter}
            width={4}
          />
        </Form.Group>
      </React.Fragment>
    );
  }
}

AddressSearch.propTypes = {
  input: PropTypes.object,
  floorIsRequire: PropTypes.bool,
  flatIsRequire: PropTypes.bool,
  readOnly: PropTypes.bool,
};
