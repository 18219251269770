import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import classnames from 'classnames';
import { Form } from 'semantic-ui-react';
import { DATE_FORMAT } from 'core/constants/app';
import InfoIcon from '../common/info-icon';
//import './style.less';

const ReadonlyInput = ({ value, label, readonly = false }) => {
  let placeholder = typeof label === 'string' ? label : '';

  return <Form.Input value={value} readOnly={readonly} placeholder={placeholder} />;
};

ReadonlyInput.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  readonly: PropTypes.bool,
};

const ReduxDatePicker = ({
  label,
  meta: { error = '', touched = false },
  input,
  readonly = false,
  pickerOptions = {},
  dateFormat = DATE_FORMAT,
  timeFormat,
  timeInterval,
  isEdited = false,
  showTimeSelect = false,
  inLine = false,
}) => {
  const onChange = (date) => {
    input.onChange(
      date ? moment(date).format(dateFormat + (timeFormat ? ' ' + timeFormat : '')) : date
    );
  };

  if (readonly) {
    pickerOptions['customInput'] = <ReadonlyInput label={label} readonly />;
  }

  if (showTimeSelect) {
    pickerOptions.showTimeSelect = true;
    pickerOptions.timeFormat = timeFormat;
    pickerOptions.timeIntervals = timeInterval;
  }

  return (
    <Form.Field className={classnames({ 'edited-field': isEdited })}>
      <label style={inLine ? { float: 'left', margin: 10 } : {}}>
        {label} {touched && error && <InfoIcon item={error} />}
      </label>
      <DatePicker
        locale="ru"
        {...input}
        calendarClassName="disabled"
        onChange={onChange}
        selected={
          input.value
            ? moment(input.value, dateFormat + (timeFormat ? ' ' + timeFormat : ''))
            : null
        }
        dateFormat={DATE_FORMAT}
        {...pickerOptions}
      />
    </Form.Field>
  );
};

ReduxDatePicker.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  help: PropTypes.string,
  dateFormat: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  containerStyle: PropTypes.string,
  readonly: PropTypes.bool,
  pickerOptions: PropTypes.object,
  timeFormat: PropTypes.string,
  timeInterval: PropTypes.number,
  showTimeSelect: PropTypes.bool,
  isEdited: PropTypes.bool,
  inLine: PropTypes.bool,
};

export default ReduxDatePicker;
