import React, { ComponentPropsWithoutRef } from 'react';
import { VideoErrorBanner } from '../../../error';
import './styles.less';

export function VideoErrorMessage(props: ComponentPropsWithoutRef<typeof VideoErrorBanner>) {
  return (
    <div className={'video-archive-error-message'}>
      <VideoErrorBanner {...props} />
    </div>
  );
}
