import React, { FC, useEffect, useCallback } from 'react';
import { Grid, Form, DropdownItemProps } from 'semantic-ui-react';
import { FormikProvider, useFormik } from 'formik';
import { Dropdown, DatePicker } from 'components/share/form/formik';
import { FlatButton } from 'components/share';
import debounce from 'lodash/debounce';
import { GridRowBottomStyled, GridRowStyled, GridColumnButton } from './styles';
import { deviceCommandsList } from 'core/constants/device-commands';
import { DeviceCommandType } from 'core/constants/contracts';
import schema from './schema';

type IFiltersProps = {
  onSubmit: (values: IFormValues) => void;
  onReset: () => void;
};

export type IFormValues = {
  commandCategory: string;
  status: string;
  startDate: number | null;
  endDate: number | null;
};

const initialValues = {
  commandCategory: '',
  status: '',
  startDate: null,
  endDate: null,
};

const commandCategoryOptions: DropdownItemProps[] = (
  Object.keys(deviceCommandsList) as Array<keyof typeof DeviceCommandType>
).map((key) => ({
  key: key,
  value: key,
  text: deviceCommandsList[key],
}));

const statusOptions: DropdownItemProps[] = [
  { key: 'success', value: 'success', text: 'Успешно' },
  { key: 'error', value: 'error', text: 'Ошибка' },
];

export const Filters: FC<IFiltersProps> = ({ onSubmit, onReset }) => {
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
  });

  const debounceCallback = useCallback(debounce(formik.handleSubmit, 750), [
    formik.handleSubmit,
    formik.values,
  ]);

  useEffect(() => {
    if (formik.values !== initialValues) {
      debounceCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const handleReset = () => {
    formik.resetForm();
    onReset();
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid>
          <GridRowBottomStyled columns={2}>
            <Grid.Column>
              <Dropdown
                name="commandCategory"
                placeholder="Категория команд"
                options={commandCategoryOptions}
                clearable
                label={null}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                name="status"
                placeholder="Результат"
                options={statusOptions}
                clearable
                label={null}
              />
            </Grid.Column>
          </GridRowBottomStyled>
          <GridRowStyled columns={4}>
            <Grid.Column>
              <DatePicker name="startDate" placeholder="Дата начала" label={null} />
            </Grid.Column>
            <Grid.Column>
              <DatePicker name="endDate" placeholder="Дата окончания" label={null} />
            </Grid.Column>
            <Grid.Column />
            <GridColumnButton>
              <FlatButton onClick={handleReset} disable={!formik.dirty}>
                Убрать поиск
              </FlatButton>
            </GridColumnButton>
          </GridRowStyled>
        </Grid>
      </Form>
    </FormikProvider>
  );
};
