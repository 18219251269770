import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { Button, Input } from 'semantic-ui-react';
import { Form } from 'mts-ui';
import { isBoolean } from 'lodash';

const InputWithButton = (props) => {
  const {
    label,
    formatter,
    help,
    meta = {},
    input,
    handleChange,
    action,
    actionPosition,
    autoComplete,
    ...rest
  } = props;

  const { touched = false, error = '' } = meta;
  const hasError = !!(touched && error);

  const handleOnChange = (e, { value }) => {
    if (formatter) {
      value = formatter(value);
    }

    if (handleChange) {
      handleChange(value);
    }

    input.onChange(value);
  };

  const inputProps = {};

  if (isBoolean(autoComplete)) {
    inputProps['autoComplete'] = autoComplete ? 'on' : 'off';
  }

  return (
    <Form.FieldAdvanced {...rest} error={hasError ? error : undefined} hint={help} label={label}>
      <Input
        {...rest}
        {...input}
        placeholder={label}
        onChange={handleOnChange}
        className={'separate'}
      >
        {actionPosition === 'left' && <Button {...action} />}
        <input {...inputProps} />
        {actionPosition === 'right' && <Button {...action} />}
      </Input>
    </Form.FieldAdvanced>
  );
};

InputWithButton.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  formatter: PropTypes.func,
  action: PropTypes.object.isRequired,
  actionPosition: PropTypes.oneOf(['left', 'right']),
  autoComplete: PropTypes.bool,
};

InputWithButton.defaultProps = {
  actionPosition: 'right',
};

export default InputWithButton;
