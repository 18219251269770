import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { Segment } from 'semantic-ui-react';
import CreateUserForm from '../../components/forms/user-form';
import { FORM_ID as FORM_ID_STEP1 } from './step1';
import get from 'lodash/get';
import { saveCompany } from 'core/store/features/companies';
import { saveUser } from 'core/store/features/users';
import { requestErrorHandlerForReduxForm } from 'core/utils/errors';
import {
  COMPANY_ADMINISTRATIVE_STATUS_ACTIVE,
  COMPANY_COMMERCIAL_STATUS_COMMERCIAL,
} from 'core/constants/domain/companies';

class Step2 extends React.Component {
  state = {
    confirmed: false,
    company: null,
  };

  saveCompany = (companyData) => {
    if (this.state.company) {
      return Promise.resolve(this.state.company);
    }

    return this.props.saveCompany(companyData).then((response) => {
      const company = get(response, 'list.0');
      this.setState({ company });

      return company;
    });
  };

  saveUser = (userData, company) => {
    userData['company'] = company.id;

    return this.props.saveUser(userData).then(() => company);
  };

  handlePrev = () => {
    this.props.prev();
  };

  handleNext = (values) => {
    const { person_type, personal_account, commercial_status, foris, ...rest } = this.props.step1;

    const companyData = {
      person: rest,
      person_type,
      personal_account,
      administrative_status: COMPANY_ADMINISTRATIVE_STATUS_ACTIVE,
      commercial_status,
      foris: commercial_status === COMPANY_COMMERCIAL_STATUS_COMMERCIAL ? foris : null,
    };

    const userData = {
      ...values,
      phone: values.phone,
      username: values.email,
      is_active: true,
      category: 'company',
    };

    this.setState({ isFetching: true });

    return this.saveCompany(companyData)
      .then((company) => this.saveUser(userData, company))
      .then((company) => this.props.history.push(`/companies/${company.id}`))
      .catch(requestErrorHandlerForReduxForm)
      .finally(() => this.setState({ isFetching: false }));
  };

  render() {
    return (
      <Segment padded className="segment-form">
        <CreateUserForm onSubmit={this.handleNext} onCancel={this.handlePrev} />
      </Segment>
    );
  }
}

Step2.propTypes = {
  saveCompany: PropTypes.func,
  saveUser: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  step1: PropTypes.object,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};

const selector = getFormValues(FORM_ID_STEP1);

const mapStateToProps = (state) => {
  return {
    step1: selector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCompany: bindActionCreators(saveCompany, dispatch),
    saveUser: bindActionCreators(saveUser, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
