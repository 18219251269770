import React, { useState, FC } from 'react';
import { Button, Icon as SUIcon, Popup, SemanticICONS } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Title, StyledBox, TitleContainer, AddressPopup } from './styles';
import { VideocamOn, IntercomWithCamera, Info } from 'components/share/assets';
import { Icon } from 'components/share';
import { Camera } from 'core/constants/contracts';
import { buildAddressStr } from 'core/utils/address';
import styled from 'styled-components';

interface Props {
  camera?: Camera;
  url: string;
  active?: boolean;
  isDisabled: boolean;
  onClick?: () => void;
}

const Box = styled.div`
  height: 20px;
`;

export const CameraCard: FC<Props> = ({ camera, url, active, isDisabled, onClick }) => {
  const variant = camera ? 'edit' : 'create';

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handlePopupOpen = () => setIsPopupOpen(!isPopupOpen);

  const hasBinding = Boolean(camera?.device?.building || camera?.device?.bindings.length !== 0);

  if (camera && onClick) {
    return (
      <StyledBox onClick={onClick} variant={variant}>
        <TitleContainer>
          <Title className="rounded" active={active}>
            <Icon icon={camera.is_builtin ? <IntercomWithCamera /> : <VideocamOn />} size="20px" />
            <span>{camera.name || camera.id}</span>
          </Title>
          {hasBinding && (
            <Popup
              trigger={
                <Box onMouseOver={handlePopupOpen} onMouseOut={handlePopupOpen}>
                  <Icon icon={<Info />} size="20px" />
                </Box>
              }
              content={
                <AddressPopup>
                  <span>Адрес: </span>
                  {buildAddressStr(camera.device)}
                </AddressPopup>
              }
              open={isPopupOpen}
              position="top left"
              offset="-14px"
              inverted
            />
          )}
        </TitleContainer>
        <Button icon as={isDisabled ? "button" : Link} to={url} disabled={isDisabled}>
          <SUIcon name={'mts-icon-edit' as SemanticICONS} />
        </Button>
      </StyledBox>
    );
  }

  return (
    <StyledBox variant={variant}>
      <Title className="rounded" active={active}>
        <Icon icon={<VideocamOn />} size="20px" />
        <span>Не подключена</span>
      </Title>
      <Button icon as={isDisabled ? "button" : Link} to={url} disabled={isDisabled}>
        <SUIcon name="plus" />
      </Button>
    </StyledBox>
  );
};
