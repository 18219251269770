import React, { useMemo } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { IntercomsListContainer } from '../../../abstract/list';
import { createColumns } from './columns';
import { Link, useLocation } from 'react-router-dom';

export default function BarriersPage() {
  const query = new URLSearchParams(useLocation().search);
  const columns = useMemo(() => createColumns(query), [query]);

  return (
    <Grid>
      <SetBreadcrumb>Шлагбаумы</SetBreadcrumb>

      <Grid.Row columns="equal">
        <Grid.Column>
          <Header as="h1">Шлагбаумы</Header>
        </Grid.Column>
        <Grid.Column textAlign={'right'}>
          <Button as={Link} to="/barriers/create">
            Добавить шлагбаум
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <IntercomsListContainer columns={columns} category="barrier" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
