import React from 'react';
import { Card, Button, Image } from 'semantic-ui-react';
import building from './building.svg';
import { Link } from 'react-router-dom';

export default function AddIntercomWidget(props) {
  return (
    <Card>
      <Card.Content>
        <Image
          floated="right"
          // size='small'
          src={building}
        />
        <Card.Header style={{ whiteSpace: 'nowrap' }}>
          Подключите <strong>Оборудование</strong>
        </Card.Header>
        <Card.Meta>
          Вносите информацию о подключенных Домофонах
          <div className={'controls'}>
            <Button secondary as={Link} content={'Добавить'} to={'/intercoms/create'} />
          </div>
        </Card.Meta>
      </Card.Content>
    </Card>
  );
}
