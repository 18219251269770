import React, { FC } from 'react';
import { Dimmer, Grid, Loader } from 'semantic-ui-react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { CloudArchivePageHeader } from './components/header';
import { Table } from './table';
import { Service } from 'core/constants/contracts';

export interface CloudArchivePageProps {
  pending: boolean;
  cloudArchiveService: Service;
}

export const CloudArchivePage: FC<CloudArchivePageProps> = ({ pending, cloudArchiveService }) => {
  return (
    <Grid>
      <SetBreadcrumb>Облачный архив</SetBreadcrumb>

      {cloudArchiveService && <CloudArchivePageHeader cloudArchiveService={cloudArchiveService} />}

      <Grid.Row>
        <Grid.Column>
          {pending ? (
            <Dimmer active={true} inverted>
              <Loader />
            </Dimmer>
          ) : (
            <Table />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
