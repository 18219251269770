import React from 'react';
import { Grid } from 'semantic-ui-react';
import BuildingEditForm from './form';
import { RouterMatchProps, WebHistoryProps } from 'core/constants/props';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { DEFAULT_TABLE_PARAMS, pathWithTableParams } from 'core/utils/routing';
import PropTypes from 'prop-types';

const EditBuildingPage = (props) => {
  const { tableParams } = props.location.state || DEFAULT_TABLE_PARAMS;
  return (
    <Grid>
      <SetBreadcrumb url={pathWithTableParams('/buildings', tableParams.page, tableParams.size)}>
        Дома
      </SetBreadcrumb>
      <SetBreadcrumb>Редактирование</SetBreadcrumb>

      <Grid.Row>
        <Grid.Column>
          <BuildingEditForm match={props.match} history={props.history} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

EditBuildingPage.propTypes = {
  match: RouterMatchProps,
  history: WebHistoryProps,
  location: PropTypes.object,
};

export default EditBuildingPage;
