import { Binding, BindingType } from 'core/constants/contracts';
import { findIndex, findLastIndex } from 'lodash';

// TODO
export const DEFAULT_BINDING_TYPE_ORDER = [
  BindingType.terrain,
  BindingType.building,
  BindingType.entrance,
  BindingType.floor,
  BindingType.apartment,
];

type BindingId = string;
type ParentId = BindingId;
type Children = Array<Binding>;

export class RelationshipTable {
  private static readonly ROOT = '__root__';
  private bindingsMap: Record<BindingId, Binding> = {};
  private parentsMap: Record<ParentId, Children> = {};

  constructor(
    private readonly fields: Set<BindingType>,
    private readonly ordering: Array<BindingType> = DEFAULT_BINDING_TYPE_ORDER
  ) {}

  make(bindings: Array<Binding>) {
    this.createBindingsMap(bindings);
    this.createParentsMap(bindings);
  }

  /**
   * получить диапазон типов
   *
   * примеры:
   * fields = { entrance: true, apartment: true } => [entrance, floor, apartment]
   * fields = { apartment: true } => [apartment]
   */
  getDependTypesRange(): Array<BindingType> {
    const type = [];
    const firstIndex = findIndex(this.ordering, (v) => this.fields.has(v));
    const lastIndex = findLastIndex(this.ordering, (v) => this.fields.has(v));

    if (firstIndex > -1 && lastIndex > -1) {
      for (let i = firstIndex; i < lastIndex + 1; i++) {
        type.push(this.ordering[i]);
      }
    }

    return type;
  }

  getBindings(bindingType: BindingType, parentId: string = RelationshipTable.ROOT): Array<Binding> {
    let counter = 0;
    const result = [];
    const parents: Array<string> = [parentId];

    while (parents.length > 0) {
      if (counter > 1500) {
        throw new Error('ADDR: Infinity loop');
      }

      const parent = parents.shift();
      if (parent === undefined) {
        continue;
      }

      const children = this.parentsMap[parent];
      if (children) {
        for (let i = 0; i < children.length; i++) {
          const child = children[i];

          if (child.type === bindingType) {
            result.push(child);
          } else {
            parents.push(child.id);
          }
        }
      }

      counter++;
    }

    return result;
  }

  findFirstType(): BindingType | undefined {
    return this.ordering.find((v) => this.fields.has(v));
  }

  findNextType(type: BindingType): BindingType | undefined {
    const index = this.ordering.findIndex((v) => v === type);
    if (index === -1) {
      return undefined;
    }

    return this.ordering.find((v, i) => {
      if (i <= index) {
        return false;
      }

      return this.fields.has(v);
    });
  }

  private getParent(binding: Binding): string {
    if (binding.parent) {
      const parentId = binding.parent;

      return this.bindingsMap[parentId] ? parentId : RelationshipTable.ROOT;
    }

    return RelationshipTable.ROOT;
  }

  private createBindingsMap(bindings: Array<Binding>) {
    this.bindingsMap = bindings.reduce((acc, v) => {
      acc[v.id] = v;
      return acc;
    }, {} as Record<BindingId, Binding>);
  }

  private createParentsMap(bindings: Array<Binding>) {
    this.parentsMap = bindings.reduce((acc, v) => {
      const parentId = this.getParent(v);
      if (acc[parentId] === undefined) {
        acc[parentId] = [];
      }
      acc[parentId].push(v);
      return acc;
    }, {} as Record<ParentId, Children>);
  }
}
