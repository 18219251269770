import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Close } from '../../assets';
import { Icon } from '../icon';
import { ThemeVars } from 'core/styles/vars';

interface StyledProps {
  variant?: 'white' | 'default';
}

interface ChipProps extends StyledProps {
  onClose?: () => void;
  children: ReactNode;
}

const colors = {
  white: ThemeVars.colors.white,
  default: ThemeVars.colors.whiteSmoke,
};

const ChipBox = styled.div<StyledProps>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  display: flex;
  width: fit-content;
  align-items: center;
  min-height: 28px;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: ${({ variant }) => (variant ? colors[variant] : colors.default)};

  & > div {
    color: ${ThemeVars.colors.lightBlack};
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  & > i {
    margin-left: 0.25rem;
    align-items: center;

    & > svg {
      color: ${ThemeVars.colors.linkLightSecondary};
    }
  }

  &:hover > i > svg {
    color: ${ThemeVars.colors.black};
  }
`;

export const Chip: FC<ChipProps> = ({ children, variant = 'white', onClose }) => {
  return (
    <ChipBox variant={variant} onClick={onClose}>
      <div>{children}</div>
      {onClose && <Icon icon={<Close />} />}
    </ChipBox>
  );
};
