import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Button, Form, Grid } from 'semantic-ui-react';
import { FormButtonGroup } from 'components/share/form';
import { Input, InputMaskPhone } from 'components/share/form/formik/input';
import { updateUser } from 'core/store/features/users';
import { User } from 'core/constants/contracts';
import notification from 'core/utils/notification';
import { schema } from './schema';

interface FormValues {
  name: string;
  email: string;
  phone: string;
}

interface Props {
  user: User;
  onSubmit: () => void;
  onCancel: () => void;
}

export function UserEditForm(props: Props) {
  const {
    user: { id, name, email, phone },
    onSubmit,
    onCancel,
  } = props;
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const data = {
        name: values.name,
        email: values.email,
        phone: values.phone,
      };

      const action = dispatch(updateUser(id, data));

      return Promise.resolve(action)
        .then(() => onSubmit())
        .catch((error) => {
          if (error.response) notification.error(error.response.data.error.data);
          else notification.error('Ошибка сохранения данных');
        });
    },
    [dispatch, id, onSubmit]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        name: name,
        email: email,
        phone: phone,
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid.Row columns={3} stretched>
              <Grid.Column>
                <Input name={'name'} label={'ФИО'} required />
              </Grid.Column>
              <Grid.Column>
                <Input name={'email'} label={'E-mail'} required />
              </Grid.Column>
              <Grid.Column>
                <InputMaskPhone name={'phone'} label={'Номер телефона'} required />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column floated="right">
                <FormButtonGroup>
                  <Button secondary onClick={onCancel}>
                    Отменить
                  </Button>
                  <Button type={'submit'} disabled={!formik.isValid}>
                    Сохранить
                  </Button>
                </FormButtonGroup>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
