import { find, get, isEmpty } from 'lodash';
import { COMPANY_ADMINISTRATIVE_STATUS_BLOCKED } from '../constants/domain/companies';

export function currentUserIsInit(user) {
  return user && false === user.isFetching && false === isEmpty(user.user);
}

// TODO временноая штука
export function check_IOTINTCOM1038(currentUser) {
  return !!find(currentUser.roles, function (v) {
    return v === 'company_5d6e59ec672de82b32ba192e';
  });
}

export function isCompanyBlocked(company) {
  return company.administrative_status === COMPANY_ADMINISTRATIVE_STATUS_BLOCKED;
}

export function isStaffCanEditUsers(user) {
  return get(user, 'source') === 'default';
}
