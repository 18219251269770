import { AxiosError, AxiosResponse } from 'axios';
import { getFirstError, DEFAULT_ERROR_MESSAGE } from './errors';
import { WebAPIErrorData, NestError, WebAPIResponse } from './webapi.contracts';

export function isAxiosError<T = unknown>(e: Error | AxiosError): e is AxiosError<T> {
  return e && (e as AxiosError<T>).isAxiosError;
}

export function isNestError(e: NestError | WebAPIResponse): e is NestError {
  return !!(e && (e as NestError).statusCode);
}

export function isExpressError(e: NestError | WebAPIResponse): e is WebAPIResponse {
  return !!(e && (e as WebAPIResponse).error);
}

export function errorFromAxios(
  err: Error | AxiosError<WebAPIResponse> | AxiosError<NestError>,
  defaultMessage = DEFAULT_ERROR_MESSAGE
): string {
  if (isAxiosError(err)) {
    const data = err.response?.data;

    if (data && isExpressError(data)) {
      const maybeErr = data.error;
      if (maybeErr?.data && typeof maybeErr.data === 'string') {
        return maybeErr.data;
      } else if (maybeErr?.data && typeof maybeErr.data === 'object') {
        const message: string = getFirstError(maybeErr.data);
        if (message) {
          return message;
        }
      } else if (maybeErr?.message && typeof maybeErr.message === 'string') {
        return maybeErr.message;
      }
    } else if (data && isNestError(data)) {
      return data.message;
    }

    console.error(err);
    return defaultMessage;
  } else if (err.message) {
    console.error(err);
    return err.message;
  }

  console.error(err);
  return defaultMessage;
}

export function dataFromAxios<T = any>(
  response: AxiosResponse<WebAPIResponse<T>>
): WebAPIResponse<T> {
  if (response.status >= 200 && response.status < 400) {
    return response.data;
  }

  const err = response.data?.error;
  if (err) {
    throw enhanceError(err);
  }

  throw new Error(DEFAULT_ERROR_MESSAGE);
}

export interface EnhancedError extends Error {
  data: WebAPIErrorData['data'];
  code: WebAPIErrorData['code'];
}

function enhanceError(e: WebAPIErrorData): EnhancedError {
  let data = e.data;
  let code = e.code;
  let message = e.message;

  if (typeof data === 'string') {
    message = data;
    data = undefined;
  }

  const error: EnhancedError = new Error(message) as EnhancedError;
  error.data = data;
  error.code = code;

  return error;
}
