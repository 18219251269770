import { FormButtonGroup } from 'components/share/form';
import { Dropdown, Input } from 'components/share/form/formik';
import { FormikProps, withFormik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Button, DropdownItemProps, Form, InputOnChangeData } from 'semantic-ui-react';
import { FormValues } from '../contracts';
import { schema } from './schema';

interface OwnProps {
  variant: 'create' | 'edit';
  companies: DropdownItemProps[];
  companyData?: {
    id: string;
    max_channels: number;
  };
  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

interface Props extends OwnProps, FormikProps<FormValues> {}

const InnerForm: FC<Props> = ({
  variant,
  companies,
  onCancel,
  handleSubmit,
  isValid,
  dirty,
  setFieldValue,
}) => {
  const isCreate = useCallback(() => variant === 'create', [variant]);

  const controlChannels = (_: React.FormEvent, data: InputOnChangeData) => {
    if (Number(data.value) < 0) {
      setFieldValue('max_channels', 0);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths={2}>
        <Dropdown
          name="owner_id"
          placeholder="Владелец"
          options={companies}
          clearable
          search
          hideLabel={true}
          disabled={!isCreate()}
        />
        <Input
          name="max_channels"
          placeholder="Количество каналов"
          type="number"
          hideLabel={true}
          onChange={controlChannels}
        />
      </Form.Group>

      <FormButtonGroup>
        <Button secondary onClick={onCancel}>
          Отменить
        </Button>
        <Button type="submit" disabled={!(isValid && dirty)}>
          Сохранить
        </Button>
      </FormButtonGroup>
    </Form>
  );
};

const CloudArchiveForm = withFormik<OwnProps, FormValues>({
  mapPropsToValues: ({ companyData }) => {
    return {
      owner_id: companyData ? companyData.id : '',
      max_channels: companyData ? (companyData.max_channels as unknown as string) : '',
    };
  },
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    if (props.onSubmit) {
      props.onSubmit(values);
    }
  },
})(InnerForm);

export default CloudArchiveForm;
