import React from 'react';
import { Loader } from 'components/share';
import { Button, Form, Grid, Header } from 'semantic-ui-react';
import ConfirmBlockCompany from '../modal/block';
import { isCompanyBlocked } from 'core/utils/auth';
import {
  COMPANY_ADMINISTRATIVE_STATUS_ACTIVE,
  COMPANY_ADMINISTRATIVE_STATUS_BLOCKED,
} from 'core/constants/domain/companies';
import notification from 'core/utils/notification';
import { Company } from 'core/constants/contracts';
import { saveCompany } from 'core/store/actions/companies';
import cx from 'classnames';
import { CircleLock } from 'components/share/assets';
import './index.less';

interface Props {
  company: Company;
  saveCompany: ReturnType<typeof saveCompany>;
}

interface State {
  isCompanyUpdating: boolean;
}

class BlockCompany extends React.Component<Props, State> {
  state = {
    isCompanyUpdating: false,
  };

  handleToggleStatusCompany = (values: any) => {
    this.setState({ isCompanyUpdating: true });

    const company = this.props.company;

    const data = {
      id: company.id,
      administrative_status: isCompanyBlocked(company)
        ? COMPANY_ADMINISTRATIVE_STATUS_ACTIVE
        : COMPANY_ADMINISTRATIVE_STATUS_BLOCKED,
      administrative_status_comment: values.cause,
    };

    return this.props
      .saveCompany(data)
      .then(() => notification.success())
      .catch(() => notification.error())
      .finally(() => this.setState({ isCompanyUpdating: false }));
  };

  render() {
    const { company } = this.props;

    const isBlocked: boolean = isCompanyBlocked(company);

    let trigger: React.ReactNode;
    trigger = (
      <Button
        primary={!isBlocked}
        secondary={isBlocked}
        content={isBlocked ? 'Разблокировать' : 'Заблокировать'}
        tall
        className={'width-medium'}
      />
    );

    trigger = (
      <ConfirmBlockCompany
        isBlocked={isBlocked}
        trigger={trigger}
        onSubmit={this.handleToggleStatusCompany}
      />
    );

    const iconClasses = cx({
      'company-block-icon': true,
      blocked: isBlocked,
    });

    return (
      <Grid columns={'equal'} className={'company-blocker'}>
        <Grid.Row>
          <Grid.Column>
            <Header>Блокировка компании</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Loader active={this.state.isCompanyUpdating} />
          <Grid.Column width={1}>
            <CircleLock className={iconClasses} />
          </Grid.Column>
          <Grid.Column width={8}>
            Здесь можем показать подсказку, что такое заблокированная компания или в каких случаях
            нужно ее блокировать
          </Grid.Column>
          <Grid.Column width={3} />
          <Grid.Column width={4} textAlign={'right'}>
            <Form.Field>{trigger}</Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default BlockCompany;
