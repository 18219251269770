import React, { FC } from 'react';
import { CameraAddressUnset } from './address-unset';
import { CameraAddressSet } from './address-set';
import { CameraDelete } from './delete';
import styled from 'styled-components';
import { CameraAgg } from 'core/store/selectors/cameras';

interface Props {
  camera: CameraAgg;
  isBindingAllowed?: boolean;
}

const ActionsBox = styled.div`
  display: flex;
  justify-content: 'center';

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const CameraActions: FC<Props> = ({ camera, isBindingAllowed }) => {
  const hasBindings = Boolean(camera.device?.bindings.length);
  const linked = Boolean(camera.parent);

  const actions: JSX.Element[] = [];

  if (isBindingAllowed && !camera.intercom) {
    if (hasBindings) {
      actions.push(<CameraAddressUnset key="cameraAddressUnset" cameraId={camera.id} />);
    } else {
      actions.push(<CameraAddressSet key="cameraAddressSet" camera={camera} />);
    }
  }

  if (!linked) {
    actions.push(<CameraDelete key="cameraDelete" camera={camera} />);
  }

  return <ActionsBox>{actions}</ActionsBox>;
};
