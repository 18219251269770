import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeVars, ColorsEnum } from 'core/styles/vars';

const { colors } = ThemeVars;

export type TagColorsLiteral = 'blue' | 'green' | 'grey';

type StyleProps = {
  color?: ColorsEnum;
  size?: 24 | 32;
  isInverted?: boolean;
};

type TagProps = StyleProps & {
  children: ReactNode;
  variant?: TagColorsLiteral;
};

const Container = styled.div<StyleProps>((props) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: props.size === 24 ? '0.125rem 0.75rem' : '0.375rem 1rem',
  backgroundColor: props.color ? colors[props.color] : colors.whiteSmoke,
  color: props.isInverted ? colors.white : colors.lightBlack,
  borderRadius: 100,
  width: 'max-content',
  height: props.size ? props.size : 24,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20px',
}));

const tagColors = {
  blue: ColorsEnum.darkBlueberry,
  green: ColorsEnum.normalApple,
  grey: ColorsEnum.linkLightSecondary,
};

export const Tag: FC<TagProps> = ({ children, isInverted = true, color, variant, ...props }) => {
  return (
    <Container isInverted={isInverted} color={variant ? tagColors[variant] : color} {...props}>
      {children}
    </Container>
  );
};
