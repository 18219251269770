import React from 'react';
import { DropdownProps, Form } from 'semantic-ui-react';
import { FieldProps } from 'formik';
import { intersection } from 'lodash';
import { BindingType } from 'core/constants/contracts';
import { BINDING_TYPE_TITLE_MAP } from 'core/constants/domain/binding';
import { Dropdown } from '../../../dropdown';
import { AddressRendererProps } from '../../component';

interface Props extends AddressRendererProps {
  // автозаполняемые значения, берет первое из списка
  // в основоном применяется к типам terrain и building, т.к. пока они в единичных экземплярах
  autoFillFields: Array<BindingType>;
}

interface State {
  // когда меняем строение, новые биндинги еще не подгрузились
  // с помощью этого флага ожидаем первые NOT UNDEFINED список биндингов
  hasChangedBuilding: boolean;
  // поля, которые отображаем для ручного заполнения
  manualFields: Set<BindingType>;
  // поля, которые невозможно заполнить автоматически на основе props.autoFillFields
  unsetAutoFields: Set<BindingType>;
}

class AddressSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasChangedBuilding: false,
      manualFields: this.calcManualFields(props.fields),
      unsetAutoFields: new Set(),
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.fields !== prevProps.fields) {
      this.setState({ manualFields: this.calcManualFields(this.props.fields) });
    }

    if (
      this.props.bindingsTypesOptions !== prevProps.bindingsTypesOptions &&
      this.state.hasChangedBuilding
    ) {
      const autoFillFields = this.props.autoFillFields;
      const intersect = intersection(autoFillFields, Array.from(this.props.fields));

      const unsetFields = new Set<BindingType>();
      for (let i = 0; i < autoFillFields.length; i++) {
        const bindingType = autoFillFields[i];
        const value: string = this.props.bindingsTypesOptions[bindingType]?.[0]?.value as string;

        if (value) {
          this.setState({ hasChangedBuilding: false });

          this.props.form.setFieldValue(`${this.props.name}.${bindingType}`, value);
          this.props.onChangeType(BindingType.building, value);
        } else if (intersect.includes(bindingType)) {
          unsetFields.add(bindingType);
        }
      }

      this.setState({ unsetAutoFields: unsetFields });
    }
  }

  calcManualFields(fields: Set<BindingType>): Set<BindingType> {
    const filteredFields = Array.from(fields.values()).filter(
      (v) => !this.props.autoFillFields.includes(v)
    );

    return new Set<BindingType>(filteredFields);
  }

  handleChangeBuilding = (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps,
    props: FieldProps
  ) => {
    this.props.onChangeBuilding(data.value as string);

    if (this.props.fields.has(BindingType.building)) {
      this.setState({ hasChangedBuilding: true });
    }
  };

  handleChangeBinding = (type: BindingType, value: string) => {
    this.props.onChangeType(type, value);
  };

  render() {
    const { name, buildingsOptions, bindingsTypesOptions, fetching, selected } = this.props;

    const { unsetAutoFields } = this.state;

    const hint =
      selected['building_id'] && unsetAutoFields.size > 0
        ? `Отсутствуют автозаполняемые уровни ${Array.from(unsetAutoFields.values()).join(', ')}`
        : '';

    return (
      <React.Fragment>
        <Dropdown
          name={`${name}.building_id`}
          label={'Адрес'}
          // required
          clearable
          onChange={this.handleChangeBuilding}
          options={buildingsOptions}
          loading={fetching['building_id']}
          hint={hint}
          width={8}
        />
        <Form.Field width={8}>
          <Form.Group widths={'equal'}>
            {Array.from(this.state.manualFields.values()).map((v) => {
              return (
                <Dropdown
                  key={v}
                  name={`${name}.${v}`}
                  label={BINDING_TYPE_TITLE_MAP[v] ?? 'Номер'}
                  // required
                  clearable
                  onChange={(event, data) => this.handleChangeBinding(v, data.value as string)}
                  options={bindingsTypesOptions[v]}
                  loading={fetching[v]}
                />
              );
            })}
          </Form.Group>
        </Form.Field>
      </React.Fragment>
    );
  }
}

const noAutoFill: Array<BindingType> = [];

export function AddressSelectDynamic(props: AddressRendererProps) {
  return <AddressSelect autoFillFields={noAutoFill} {...props} />;
}

const autoFillTerrainAndBuilding: Array<BindingType> = [BindingType.terrain, BindingType.building];

export function AddressSelectDynamicAutoTB(props: AddressRendererProps) {
  return <AddressSelect autoFillFields={autoFillTerrainAndBuilding} {...props} />;
}
