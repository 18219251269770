import React from 'react';
import { Cell } from 'react-table';
import { Link } from 'react-router-dom';
import { Column } from 'components/share/components/table';
import { buildAddressStr } from 'core/utils/address';
import { ExtendedEntity } from 'core/store/selectors';
import { IntercomAgg } from 'core/store/selectors/intercoms';
import { DeviceModel } from '../../../abstract/components/device-model';
import { PhoneStatus } from '../../../abstract/components/phone-status';
import { actualOwnerMap, internetProviderMap } from '../config';
import styled from 'styled-components';
import { Icon, Tag } from 'components/share';
import * as RouteUrl from 'core/constants/routes';
import { Popup } from 'semantic-ui-react';
import { ThemeVars } from 'core/styles/vars';
import { STATUS_PENDING } from 'core/store/reducers/app/intercoms';
import { DeviceActive, DevicePending } from 'components/share/assets';

const viewUrl = (query?: URLSearchParams) => (id: string) => {
  return {
    pathname: RouteUrl.intercomView(id),
    state: {
      tableParams: {
        page: query?.get('page'),
        size: query?.get('size'),
      },
    },
  };
};

const ItalicText = styled.span`
  font-style: italic;
`;
const Box = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-left: 1rem;
  }
`;
const StatusContainer = styled.div`
  margin-top: 0.5rem;
`;

export const createColumns = (
  query?: URLSearchParams
): Array<Column<ExtendedEntity<IntercomAgg>>> => {
  return [
    {
      Header: 'SIP-номер',
      Cell: ({ row: { original } }: Cell<IntercomAgg>) => {
        return (
          <PhoneStatus
            id={original.id}
            message={original.phone}
            isOnline={original.is_online}
            view={viewUrl(query)}
          />
        );
      },
    },
    {
      Header: 'Название',
      Cell: ({ row: { original } }: Cell<IntercomAgg>) => {
        return original.name ?? original.phone;
      },
      width: 200,
    },
    {
      Header: 'Модель устройства',
      Cell: ({ row: { original } }: Cell<IntercomAgg>) => {
        // get(deviceReferences, `${item.device.reference}.category.name`);
        const referenceId = original.device.reference;

        if (!referenceId) {
          return '';
        }

        const deviceReference = original.__device_ref;
        if (!deviceReference) {
          return '';
        }

        return <DeviceModel type={deviceReference.category} model={deviceReference.name} />;
      },
      width: 200,
    },
    {
      Header: 'Панель',
      Cell: ({ row: { original } }: Cell<ExtendedEntity<IntercomAgg>>) => {
        return original.status === STATUS_PENDING ? (
          <Popup
            content="Панель не подключена"
            position="top right"
            trigger={
              <StatusContainer>
                <Icon icon={<DevicePending />} size="24px" color={ThemeVars.colors.redMTS} />
              </StatusContainer>
            }
            inverted
          />
        ) : (
          <Popup
            content="Панель подключена"
            position="top right"
            trigger={
              <StatusContainer>
                <Icon
                  icon={<DeviceActive />}
                  size="24px"
                  color={ThemeVars.colors.linkLightSecondary}
                />
              </StatusContainer>
            }
            inverted
          />
        );
      },
      cellProps: () => ({
        textAlign: 'center',
        verticalAlign: 'middle',
      }),
    },
    {
      Header: 'Владелец',
      Cell: ({ row: { original } }: Cell<IntercomAgg>) => {
        const owner = original.device.owner;
        if (owner) {
          return (
            <Box>
              <Link to={`/companies/${owner.id}`}>{owner.name}</Link>
              {original.__owner_status && (
                <Tag variant={original.__owner_status.color}>{original.__owner_status.name}</Tag>
              )}
            </Box>
          );
        }

        return '';
      },
    },
    {
      Header: 'Адрес',
      accessor: 'device',
      Cell: ({ row: { original } }) => {
        let renderBuildingCell = <ItalicText>(Не привязан к адресу)</ItalicText>;
        const buildingId = original.device.building?.id;

        if (original.has_bindings) {
          renderBuildingCell = (
            <Box>
              {buildingId ? (
                <Link to={`/buildings/${buildingId}/edit`}>{buildAddressStr(original.device)}</Link>
              ) : (
                buildAddressStr(original.device)
              )}
              {original.__building_is_test && <Tag variant="blue">Тестовый</Tag>}
            </Box>
          );
        }

        return renderBuildingCell;
      },
      width: 500,
    },
    {
      Header: 'Принадлежность панели',
      accessor: 'actual_owner',
      Cell: ({ cell: { value } }) => {
        return value ? actualOwnerMap[value] : '';
      },
      width: 200,
    },
    {
      Header: 'Принадлежность интернета',
      accessor: 'internet_provider',
      Cell: ({ cell: { value } }) => {
        return value ? internetProviderMap[value] : '';
      },
      width: 200,
    },
    {
      Header: 'ЛС регионального биллинга',
      accessor: 'regional_billing_account',
      Cell: ({ cell: { value } }) => {
        return value || '-';
      },
      width: 170,
    },
    {
      Header: 'Серийный № панели',
      accessor: 'serial_number',
      Cell: ({ cell: { value } }) => {
        return value || '-';
      },
      width: 170,
    },
  ];
};
