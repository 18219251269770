import React from 'react';
import { Card, Grid, Header } from 'semantic-ui-react';
import { User, UserAuthCredentialAsterisk, UserAuthMethod } from 'core/constants/contracts';
import { SipAsteriskInformation } from './components/sip-info';
import { ApartmentAndIntercoms } from './components/appartment';
import { SipCreate } from './components/sip-create';
import { UnionApartmentAndIntercoms } from '../../data/selectors';
import styled from 'styled-components';

interface OwnProps {
  client: User;
  aggregations: Array<UnionApartmentAndIntercoms>;
  onSuccess?: Function;
  onFailed?: Function;
}

interface Props extends OwnProps {}

const LineSpan = styled.span`
  margin-top: 1rem;
  margin-left: 0.5rem;
`;

export class ClientSipInformation extends React.Component<Props> {
  render() {
    const { client, aggregations, onSuccess, onFailed } = this.props;
    const sipAuth = client.auth?.find((v) => v.method === UserAuthMethod.asterisk);
    const hasSip = !!sipAuth;

    let content;
    if (hasSip) {
      content = (
        <SipAsteriskInformation credential={sipAuth!.credential as UserAuthCredentialAsterisk} />
      );
    } else {
      content = <SipCreate userId={client.id} onSuccess={onSuccess} onFailed={onFailed} />;
    }

    return (
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Header as="h5" className="font-weight-medium">
              Клиент
            </Header>
            {content}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns="equal">
          <Grid.Column>
            <Header as="h5" className="font-weight-medium">
              Домофоны
            </Header>
            <Card.Group>
              {aggregations.length > 0 ? (
                aggregations.map((agg, index) => {
                  if (agg.intercoms.length > 0) {
                    return (
                      <ApartmentAndIntercoms
                        key={index}
                        userId={client.id}
                        userSip={sipAuth?.credential.sip}
                        apartment={agg.apartment}
                        intercoms={agg.intercoms}
                      />
                    );
                  }
                  return <LineSpan>Информация отсутствует</LineSpan>;
                })
              ) : (
                <LineSpan>Информация отсутствует</LineSpan>
              )}
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
