import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { Checkbox } from 'semantic-ui-react';

// это херня, что бы можно было не только true/false получить, а значение checkedValue при checked
const getValue = (isChecked, checkedValue) => {
  if (checkedValue === undefined) {
    return isChecked;
  }

  if (isChecked) {
    return checkedValue;
  }

  return null;
};

const CheckboxControl = ({
  input,
  label,
  toggle,
  checkedValue,
  readOnly,
  disabled,
  defaultChecked,
  onChangeOrigin,
} = {}) => {
  const onChange = useCallback(
    (e, data) => {
      const value = getValue(data.checked, checkedValue);
      input.onChange(value);
      if (onChangeOrigin) {
        data.checked = value;
        onChangeOrigin(e, data);
      }
    },
    [input, checkedValue, onChangeOrigin]
  );

  const isChecked =
    checkedValue === undefined ? input.value === true : input.value !== null && input.value !== '';

  useEffect(() => {
    if (defaultChecked) {
      const value = getValue(true, checkedValue);
      input.onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Checkbox
      onChange={onChange}
      label={label}
      toggle={toggle}
      readOnly={readOnly}
      disabled={disabled}
      value={checkedValue}
      checked={isChecked}
    />
  );
};

CheckboxControl.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  toggle: PropTypes.bool,
  checkedValue: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
};

export default CheckboxControl;
