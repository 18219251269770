import { RootState } from '..';
import { createSelector } from 'reselect';
import { getIntercomsItems } from './intercoms';
import { GenericParametricSelector, PossibleIdProps } from '.';
import { Camera, Intercom, OwnerStatusType } from 'core/constants/contracts';
import { EntitiesState, EntityId } from '../features/entities';
import { getCompaniesItems } from './companies';
import isEmpty from 'lodash/isEmpty';
import { ownerStatusMap } from './shared/helper';

export const getCameras = (state: RootState) => state.entities.cameras;
export const getCamerasItems = (state: RootState) => state.entities.cameras.items;

export interface CameraAgg extends Camera {
  intercom?: Intercom;
  __owner_status?: OwnerStatusType;
}

export const createSelectCamerasAggAsArray = (): GenericParametricSelector<
  PossibleIdProps,
  Array<CameraAgg>
> =>
  createSelector(
    [getCamerasItems, getIntercomsItems, getCompaniesItems],
    (cameras, intercoms, companies) => {
      return Object.values(cameras).map((value: Camera): CameraAgg => {
        let result: CameraAgg = { ...value };
        const deviceOwnerId = value.device?.owner?.id;

        const hasCameraIntercom = Object.keys(intercoms).some(
          (item) => intercoms[item].device.id === value.parent
        );
        const interomId = Object.keys(intercoms).find(
          (item) => intercoms[item].device.id === value.parent
        );
        if (hasCameraIntercom && interomId) {
          result.intercom = { ...intercoms[interomId] };
        }
        return {
          ...result,
          __owner_status:
            deviceOwnerId && !isEmpty(companies)
              ? {
                  name: ownerStatusMap[companies[deviceOwnerId].commercial_status].name,
                  color: ownerStatusMap[companies[deviceOwnerId].commercial_status].color,
                }
              : undefined,
        };
      });
    }
  );

export const createSelectCamerasAggAsMap = (): GenericParametricSelector<
  PossibleIdProps,
  Record<EntityId, CameraAgg>
> => {
  const selectCamerasAggAsArray = createSelectCamerasAggAsArray();

  return createSelector([selectCamerasAggAsArray], (cameras) => {
    return cameras.reduce((acc, v) => {
      acc[v.id] = v;

      return acc;
    }, {} as Record<EntityId, CameraAgg>);
  });
};

export const createSelectCamerasAggAsState = (): GenericParametricSelector<
  PossibleIdProps,
  EntitiesState<CameraAgg>
> => {
  const selectCamerasAggAsMap = createSelectCamerasAggAsMap();

  return createSelector([getCameras, selectCamerasAggAsMap], (camerasState, items) => {
    return {
      ...camerasState,
      items,
    };
  });
};
