import { ThemeVars } from 'core/styles/vars';
import styled from 'styled-components';

export const Paragraph = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${ThemeVars.colors.greyRaven};
  white-space: pre-line;
`;

export const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const ParagraphBox = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const ParagraphBold = styled.span`
  font-weight: bold;
  color: ${ThemeVars.colors.deepBlue};
`;
