import { PromiseThunk } from 'core/store';
import { startSectionLoading, stopSectionLoading } from 'core/store/features/app';

import { fetchDeviceCategories } from 'core/store/features/devices-categories';
import { fetchDeviceReferences } from 'core/store/features/devices-references';
import { createSelectDeviceCategoriesByAlias } from 'core/store/selectors/devices-categories';

export const EDIT_SECTION_LOADING_NAME = 'edit_video_recorders';

export const fetchDeviceReferencesData = (): PromiseThunk => {
  const selectDeviceCategoriesByAlias = createSelectDeviceCategoriesByAlias(['video_recorder']);
  
  return (dispatch, getState) => {
    dispatch(startSectionLoading(EDIT_SECTION_LOADING_NAME));

    return dispatch(fetchDeviceCategories())
      .then(() => {
        const categories = selectDeviceCategoriesByAlias(getState());
        
        if (categories.length === 0) return;
        
        const categoriesIds = categories.map((v) => v.id);

        return dispatch(
          fetchDeviceReferences({
            limit: 1000,
            filter: { category: categoriesIds },
          })
        );
      })
      .finally(() => dispatch(stopSectionLoading(EDIT_SECTION_LOADING_NAME)))
  };
};
