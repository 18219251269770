import * as yup from 'yup';

const integerSchema = yup
  .number()
  .nullable()
  .typeError('Должно быть числом')
  .integer('Должно быть целое число')
  .transform(function (value, originalvalue) {
    return originalvalue === '' ? void 0 : value;
  });

export default integerSchema;
