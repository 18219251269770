import React from 'react';
import { Route, RouteProps } from 'react-router';

export interface PageRouteProps extends RouteProps {
  page: any;
  layout?: any;
  breadcrumbs?: boolean;
}

const route: React.FunctionComponent<PageRouteProps> = (props) => {
  const { layout, page, breadcrumbs, ...rest } = props;

  const LayoutComponent = layout;
  const PageComponent = page;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!layout) {
          return <PageComponent {...props} />;
        }

        return (
          <LayoutComponent {...rest} {...props} breadcrumbs={breadcrumbs}>
            <PageComponent {...props} />
          </LayoutComponent>
        );
      }}
    />
  );
};

export default route;
