import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { Toast } from 'mts-ui';
import { ModalProvider } from './components/share/modals/manager';
import { Provider as BreadcrumbsProvider } from 'components/share/breadcrumbs';
import { CurrentUserContext } from 'core/contexts/user';
import { CurrentCompanyContext } from 'core/contexts/company';
import { RootState } from './core/store';
import { CurrentCompany, CurrentUser } from './core/constants/contracts';

const CurrentUserProvider = CurrentUserContext.Provider;
const CurrentCompanyProvider = CurrentCompanyContext.Provider;

function selectCurrentUserAndCompany(
  state: RootState
): [Nullable<CurrentUser>, Nullable<CurrentCompany>] {
  return [state.appx.user, state.appx.company];
}

export default function Providers(props: PropsWithChildren<any>) {
  const [currentUser, currentCompany] = useSelector(selectCurrentUserAndCompany);

  return (
    <ToastProvider autoDismiss components={{ Toast }}>
      <ModalProvider>
        <BreadcrumbsProvider>
          <CurrentUserProvider value={currentUser!}>
            <CurrentCompanyProvider value={currentCompany!}>
              {props.children}
            </CurrentCompanyProvider>
          </CurrentUserProvider>
        </BreadcrumbsProvider>
      </ModalProvider>
    </ToastProvider>
  );
}
