import './styles.less';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Form, Grid, Header, Icon } from 'semantic-ui-react';
import { Field, FieldArray, formPropTypes } from 'redux-form';
import { Dropdown, FiasSearch, TextInput } from 'components/share/redux-form';
import { get } from 'lodash';
import { DropdownProps } from 'core/constants/props';
import { BuildingCommercialStatus } from 'core/constants/contracts';
import Entrances from './entrances';

const FiasWithoutDescription = (props) => <FiasSearch {...props} description={null} />;

class BuildingForm extends React.Component {
  handleBindingAdd = () => this.props.array.push('bindings', void 0);

  render() {
    const {
      propsSave,
      propsCancel,
      onSubmit,
      onCancel,

      handleSubmit,
      submitting,
      invalid,

      companies,

      readonly,
    } = this.props;

    return (
      <Form error={invalid}>
        <Header as="h4">Общие сведения</Header>
        <Form.Group widths="equal">
          <Grid className="building-form-row">
            <Grid.Column width={8}>
              <Field
                name="owner"
                component={readonly['owner'] ? TextInput : Dropdown}
                label="Владелец"
                placeholder="Владелец"
                required
                readOnly={readonly['owner']}
                options={companies}
                defaultValue={get(companies, '0.value')}
                search
              />

              <Field
                name="address"
                component={FiasWithoutDescription}
                label="Адрес"
                placeholder="Адрес"
                required
                readOnly={readonly['address']}
              />
            </Grid.Column>

            <Grid.Column width={4}>
              <Field
                name="billing_account"
                component={TextInput}
                label="ЛС"
                readOnly={readonly['billing_account']}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Field
                name="year"
                component={TextInput}
                label="Год возведения"
                type="number"
                min={0}
                readOnly={readonly['year']}
              />
            </Grid.Column>

            <Grid.Column width={8}>
              <Form.Group widths="equal">
                <Field
                  name="floors"
                  component={TextInput}
                  type="number"
                  min={0}
                  label="Этажность"
                  readOnly={readonly['floors']}
                />

                <Field
                  name="commercial_status"
                  component={Dropdown}
                  label="Коммерческий статус"
                  placeholder="Коммерческий статус"
                  required
                  readOnly={readonly['commercial_status']}
                  options={[
                    { key: 1, text: 'Коммерческий', value: BuildingCommercialStatus.commercial },
                    { key: 2, text: 'Пилотный', value: BuildingCommercialStatus.pilot },
                    { key: 3, text: 'Тестовый', value: BuildingCommercialStatus.test },
                  ]}
                />
              </Form.Group>
            </Grid.Column>
          </Grid>
        </Form.Group>

        <>
          <Divider />
          <Header as="h4">Доступ</Header>
          <Divider hidden />
          <FieldArray
            name="bindings"
            component={Entrances}
            label="Подъезды"
            required
            readOnly={readonly['bindings']}
          />
        </>

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Button
                icon
                secondary
                onClick={this.handleBindingAdd}
                type="submit"
                floated="left"
                labelPosition="left"
                disabled={submitting}
              >
                <Icon name="plus" />
                Добавить подъезд
              </Button>
              <Button
                primary
                type="submit"
                floated="right"
                disabled={submitting}
                content="Сохранить"
                {...propsSave}
                onClick={handleSubmit(onSubmit)}
              />
              <Button
                secondary
                floated="right"
                disabled={submitting}
                content="Отмена"
                {...propsCancel}
                onClick={onCancel}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

const formProps = PropTypes.shape({
  address: PropTypes.bool,
  owner: PropTypes.bool,
  type: PropTypes.bool,
  entrances: PropTypes.bool,
  year: PropTypes.bool,
  floors: PropTypes.bool,
  billing_account: PropTypes.bool,
});

BuildingForm.propTypes = {
  ...formPropTypes,

  array: PropTypes.object,
  companies: DropdownProps,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  propsCancel: PropTypes.object,
  propsSave: PropTypes.object,

  readonly: formProps,
};

BuildingForm.defaultProps = {
  readonly: {},
};

export default BuildingForm;
