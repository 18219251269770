import React from 'react';
import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';
import tokenStorage from 'core/utils/token';
import { Link } from 'react-router-dom';
import SignInForm from '../components/sign-in-form';
import '../styles.less';

class SignInPage extends React.PureComponent {
  constructor(props) {
    super(props);

    if (tokenStorage.hasToken()) {
      this.props.history.push('/');
    }
  }

  
  render() {
    return (
      <div className={'width700 security'}>
        <Segment>
          <Header as={'h2'}>Вход в личный кабинет АРМ Домофон</Header>
          <SignInForm />
        </Segment>
        <div className={'info'}>
          Забыли пароль? <Link to="/password/new">Восстановить</Link>
          <br />
          <br />В случае возникновения проблем направляйте ваши обращения по адресу{' '}
          <a href={'mailto:domofon@mts.ru'}>domofon@mts.ru</a>
          <br />
          или звоните по телефону <a href={'tel:+78002500873'}>8-800-250-0873</a>
        </div>
      </div>
    );
  }
}

SignInPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default SignInPage;
