import React, { useCallback, useState, FC } from 'react';
import { Modal, Popup, Button } from 'semantic-ui-react';
import { Camera } from 'core/constants/contracts';
import { Loader } from 'components/share';
import notification from 'core/utils/notification';
import { request } from 'core/utils/request';
import api from 'core/constants/api';
import { useDispatch } from 'react-redux';
import { CameraAgg } from 'core/store/selectors/cameras';
import { FormValues } from './form';
import AddressSetForm from './form';
import { fetchData } from 'components/share/containers/camera';

interface Props {
  camera: CameraAgg;
}

interface CameraData {
  device: {
    bindings: string[];
    sort_order: 0 | 1;
  };
}

export const CameraAddressSet: FC<Props> = ({ camera }) => {
  const cameraId = camera.id;
  const [isFetching, setIsFetching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleModal = useCallback(() => setIsModalOpen(!isModalOpen), [isModalOpen]);

  const handleSubmit = useCallback(
    ({ isMain, entrance }: FormValues) => {
      const dataForCamera: CameraData = {
        device: {
          bindings: [],
          sort_order: isMain ? 1 : 0,
        },
      };

      if (entrance !== '') {
        dataForCamera.device.bindings = [entrance];
      }

      setIsFetching(true);
      request
        .put<Camera>(api.cameras(cameraId), dataForCamera)
        .then(() => {
          notification.success('Вы успешно привязали устройство к адресу');
          toggleModal();
          // ToDo: Костыль для обновления таблицы
          dispatch(fetchData({ limit: 30, page: 1, sort: '_id' }));
        })
        .catch((err) => {
          console.error(err);
          notification.error('При привязке к адресу произошла ошибка');
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    [cameraId, toggleModal, dispatch]
  );

  const trigger = <Button icon="linkify" onClick={toggleModal} />;

  return (
    <React.Fragment>
      <Popup
        trigger={trigger}
        position="top right"
        content="Указать местоположение устройства"
        inverted
      />
      {isModalOpen && (
        <Modal open closeIcon onClose={toggleModal}>
          <Modal.Header icon="archive" content="Привязка к адресу" className="font-weight-medium" />
          <Modal.Content>
            <Loader active={isFetching} />

            <AddressSetForm onCancel={toggleModal} onSubmit={handleSubmit} camera={camera} />
          </Modal.Content>
        </Modal>
      )}
    </React.Fragment>
  );
};
