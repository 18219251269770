type Delta = 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9 | 1.0;

export interface PromiseExtendContract {
  /**
   *
   * @param promise
   * @param ms минимальное время выполнения {promise}
   * @param delta если прошло времени(в процентах) больше чем {delta}, то не выполняем таймаут до {ms}
   */
  executeAtLeast<T>(promise: Promise<T>, ms: number, delta?: Delta): Promise<T>;

  timeout<T>(value: T, ms: number): Promise<T>;
}

function executeAtLeast<T>(promise: Promise<T>, ms: number, delta: Delta = 0.8): Promise<T> {
  const start = new Date().getTime();

  return promise.then((value) => {
    const end = new Date().getTime();
    const diff = end - start;

    if (diff < ms && diff / ms < delta) {
      return new Promise((resolve) => setTimeout(() => resolve(value), ms - diff));
    } else {
      return value;
    }
  });
}

function timeout<T>(value: T, ms: number): Promise<T> {
  return new Promise<T>((resolve) => {
    setTimeout(() => {
      resolve(value);
    }, ms);
  });
}

export const PX: PromiseExtendContract = {
  executeAtLeast: executeAtLeast,
  timeout: timeout,
};
