import React, { MouseEvent } from 'react';
import cx from 'classnames';
import './styles.less';

interface Props {
  fullscreen: boolean;
  onClick?: (event: MouseEvent) => void;
}

export function FullscreenToggle(props: Props) {
  const icon = props.fullscreen ? (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16406 3.16699H8.16406V8.16699H3.16406V6.16699H6.16406V3.16699ZM8.16518 17.1666H6.16518V14.1666H3.16518V12.1666H8.16518V17.1666ZM14.1663 14.1666H17.1663V12.1666H12.1663V17.1666H14.1663V14.1666ZM14.1675 3.16713H12.1675V8.16713H17.1675V6.16713H14.1675V3.16713Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.16797 8.16699H3.16797V3.16699H8.16797V5.16699H5.16797V8.16699ZM3.16797 12.1669H5.16797V15.1669H8.16797V17.1669H3.16797V12.1669ZM15.1683 15.1669H12.1683V17.1669H17.1683V12.1669H15.1683V15.1669ZM12.1683 5.16699V3.16699H17.1683V8.16699H15.1683V5.16699H12.1683Z"
        fill="white"
      />
    </svg>
  );

  return (
    <div
      className={cx(
        'video-archive-fullscreen-toggle',
        'control',
        props.fullscreen ? 'fullscreen' : 'window'
      )}
      onClick={props.onClick}
    >
      {icon}
    </div>
  );
}
