import {
  PARSER_ROWS_FAIL,
  PARSER_ROWS_SUCCESS,
  PARSER_ROWS_REQUEST,
} from '../../../constants/actions';

const defaultState = {
  metadata: {
    page: 1,
    perPage: 20,
  },
  isFetching: false,
  items: [],
  error: null,
};

export default function parserRows(state = defaultState, action) {
  switch (action.type) {
    case PARSER_ROWS_REQUEST: {
      return { ...state, isFetching: true, error: null };
    }
    case PARSER_ROWS_SUCCESS: {
      return {
        ...state,
        metadata: action.payload.metadata,
        items: action.payload.items,
        isFetching: false,
      };
    }
    case PARSER_ROWS_FAIL: {
      return { ...state, error: action.payload.error, isFetching: false, items: [], metadata: {} };
    }
    default:
      break;
  }

  return state;
}
