import { ThemeVars } from 'core/styles/vars';
import { Link, LinkProps } from 'react-router-dom';
import { Accordion, AccordionTitleProps } from 'semantic-ui-react';
import styled from 'styled-components';

export const LinkStyled = styled(Link)<LinkProps>`
  font-weight: normal;
`;

export const AccordionTitleStyled = styled(Accordion.Title)<AccordionTitleProps>`
  &&&& {
    color: ${ThemeVars.colors.darkBlueberry};
  }
`;
