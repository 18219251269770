import { bindActionCreators, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { PromiseThunk, ThunkResult } from '..';
import { Company, CurrentCompany, CurrentUser, User } from '../../constants/contracts';
import { request, setAuthHeader } from '../../utils/request';
import tokenStorage from '../../utils/token';
import api from '../../constants/api';
import { onFailed, onSuccess } from './entities';
import { WebAPIResponse } from '../../utils/webapi.contracts';
import menuResolver from '../../menu/resolver';
import { MenuType } from '../../menu/contracts';
import OperationManager from '../../utils/operation';
import authSlice, {
  AuthActionData,
  authenticate as authenticateOrigin,
  initialState as authInitialState,
  logout,
  State as AuthState,
} from './auth';

interface SectionLoading {
  counter: number;
  loading: boolean;
}

interface State {
  isInitialized: boolean;
  title: string;
  auth: AuthState;
  user: Nullable<CurrentUser>;
  company: Nullable<CurrentCompany>;
  menu: Array<MenuType>;
  error: Nullable<string>;
  loaders: Record<string, SectionLoading>;
}

const initialState: State = {
  isInitialized: false,
  title: '',
  auth: authInitialState,
  user: null,
  company: null,
  menu: [],
  error: null,
  loaders: {},
};

function startSectionLoadingHandler(state: State, section: string) {
  if (state.loaders[section]) {
    state.loaders[section].counter++;
    state.loaders[section].loading = true;

    return state;
  }

  state.loaders[section] = {
    counter: 1,
    loading: true,
  };

  return state;
}

function stopSectionLoadingHandler(state: State, section: string) {
  if (state.loaders[section]) {
    state.loaders[section].counter--;

    if (state.loaders[section].counter === 0) {
      state.loaders[section].loading = false;
    }
  }

  return state;
}
function forceStopSectionLoadingHandler(state: State, section: string) {
  if (state.loaders[section]) {
    state.loaders[section].counter = 0;
    state.loaders[section].loading = false;
  }

  return state;
}

function clearSectionLoadingHandler(state: State, section: string) {
  delete state.loaders[section];

  return state;
}

export const LOADER_SECTION_NAME_APP = '__appx__';

const slice = createSlice({
  name: 'appx',
  initialState: initialState,
  extraReducers: (builder) =>
    builder
      .addCase(authSlice.actions.logout, (state) => {
        state.user = null;
        state.company = null;
        state.menu = [];

        tokenStorage.clean();
        OperationManager.unsubscribe();

        return state;
      })
      .addCase(authSlice.actions.authFetch, (state) => {
        state.auth = authSlice.caseReducers.authFetch(state.auth);
        return state;
      })
      .addCase(authSlice.actions.authSuccess, (state, action) => {
        state.auth = authSlice.caseReducers.authSuccess(state.auth, action);
        return state;
      })
      .addCase(authSlice.actions.authFailed, (state, action) => {
        state.auth = authSlice.caseReducers.authFailed(state.auth, action);
        return state;
      }),
  reducers: {
    startLoading: (state) => startSectionLoadingHandler(state, LOADER_SECTION_NAME_APP),
    stopLoading: (state) => stopSectionLoadingHandler(state, LOADER_SECTION_NAME_APP),
    forceStopLoading: (state) => forceStopSectionLoadingHandler(state, LOADER_SECTION_NAME_APP),
    startSectionLoading: (state: State, action: PayloadAction<string>) => {
      const section = action.payload;
      return startSectionLoadingHandler(state, section);
    },
    stopSectionLoading: (state: State, action: PayloadAction<string>) => {
      const section = action.payload;
      return stopSectionLoadingHandler(state, section);
    },
    forceStopSectionLoading: (state: State, action: PayloadAction<string>) => {
      const section = action.payload;
      return forceStopSectionLoadingHandler(state, section);
    },
    clearSectionLoading: (state: State, action: PayloadAction<string>) => {
      const section = action.payload;
      return clearSectionLoadingHandler(state, section);
    },
    appInitialized: (state) => {
      state.isInitialized = true;
    },
    appUnInitialized: (state) => {
      state.isInitialized = false;
    },
    appFailed: (state: State, action: PayloadAction<string>) => {
      state.error = action.payload;

      return state;
    },
    userSuccess: (state, action: PayloadAction<WebAPIResponse<User>>) => {
      const user = action.payload.list[0];
      if (!user) {
        return state;
      }

      OperationManager.subscribe(tokenStorage.getToken());

      const roles = action.payload.metadata.roles || [];
      const isAdmin = roles.includes('admin');
      const isSupport = roles.includes('support');
      const isSupportMain = isSupport && roles.includes('support_main');
      const isStaff = roles.includes('company');

      state.user = {
        id: user.id,
        user: user,
        isFetching: false,
        error: null,

        roles: roles,
        isAdmin: isAdmin,
        isSupport: isSupport,
        isSupportMain: isSupportMain,
        isStaff: isStaff,
      };

      return state;
    },
    userFailed: (state) => {
      tokenStorage.clean();
      state.auth.token = null;
      state.isInitialized = true;

      return state;
    },
    companySuccess: (state, action: PayloadAction<WebAPIResponse<Company>>) => {
      const company = action.payload.list[0];
      if (!company) {
        return state;
      }

      state.company = {
        id: company.id,
        company: company,
        isFetching: false,
        error: null,
      };

      return state;
    },
    menuInitialized: (state, action: PayloadAction<Array<MenuType>>) => {
      state.menu = action.payload;

      return state;
    },
  },
});

export default slice;

export const {
  startLoading,
  stopLoading,
  forceStopLoading,
  startSectionLoading,
  stopSectionLoading,
  forceStopSectionLoading,
  clearSectionLoading,
  appInitialized,
  appUnInitialized,
  appFailed,
  menuInitialized,
  userSuccess,
  userFailed,
  companySuccess,
} = slice.actions;

export { logout };

export const authenticate = (data: AuthActionData): ThunkResult<void> => {
  return async (dispatch: Dispatch) => {
    return Promise.resolve(bindActionCreators(authenticateOrigin, dispatch)(data))
      .then(() => dispatch(appUnInitialized()))
      .then(() => bindActionCreators(initializeApplication, dispatch)());
  };
};

export const initializeMenu = (
  currentUser?: CurrentUser,
  currentCompany?: CurrentCompany
): ThunkResult<void> => {
  return (dispatch: Dispatch, getState) => {
    const user = currentUser ?? getState().appx.user;
    const company = currentCompany ?? getState().appx.company;

    if (user && company) {
      const menu = menuResolver.resolve(user, company);
      if (menu) {
        return dispatch(menuInitialized(menu));
      }
    }

    return dispatch(appFailed('Ошибка при загрузки меню'));
  };
};

export const initializeApplication = (): PromiseThunk => {
  return async (dispatch: Dispatch) => {
    try {
      if (tokenStorage.hasToken()) {
        setAuthHeader();

        await request.get(api.users() + '/self').then(
          (res) => onSuccess(dispatch, userSuccess, res),
          (err) => onFailed(dispatch, userFailed, err)
        );

        await request.get(api.companies() + '/self').then(
          (res) => onSuccess(dispatch, companySuccess, res),
          (err) => onFailed(dispatch, appFailed, err)
        );

        bindActionCreators(initializeMenu, dispatch)();
      }

      dispatch(appInitialized());
    } catch (e) {
      console.error(e);
    }
  };
};
