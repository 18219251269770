import { ReactNode, useContext, useEffect, useMemo } from 'react';
import { BreadcrumbsContext } from './provider';

const genId = function () {
  return Math.random().toString(36).substr(2, 9);
};

export interface HookOptions {
  id?: string;
  content: ReactNode;
  url?: string;
}

export function useBreadcrumb(options: HookOptions) {
  const { id: originId, content, url } = options;

  const id: string = useMemo(() => {
    if (originId) {
      return originId;
    }

    return genId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const manager = useContext(BreadcrumbsContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => manager.set(id, content, url), [content, url]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => manager.remove(id), [id]);
}
