import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { Workflow } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  onDelete,
  onFailed,
  onSuccess,
} from './entities';
import api from '../../constants/api';
import { ApiThunk } from '..';
import { request } from '../../utils/request';
import { dataFromAxios } from '../../utils/webapi.handlers';

const slice = createSlice({
  name: 'workflow',
  initialState: createEntitiesInitialState<Workflow>(),
  reducers: createDefaultEntitiesReducers<Workflow>(),
});

export default slice;

export const { pending, success, failed, clear, deleted } = slice.actions;

export const fetchWorkflows = createFetchEntities(api.workflows(), slice.actions);

export interface UpdateWorkflowStatusData<T> {
  id: string;
  status: T;
  reason?: string;
}

export const updateWorkflowStatusAndHide = <T>(
  data: UpdateWorkflowStatusData<T>,
  hide = false
): ApiThunk<Workflow> => {
  return (dispatch: Dispatch) => {
    dispatch(pending());

    const { id, ...rest } = data;

    return request.put(api.workflows(id), rest).then(
      (res) => {
        if (hide) {
          onDelete(dispatch, deleted, id);
        } else {
          onSuccess(dispatch, success, res);
        }

        return dataFromAxios<Workflow>(res);
      },
      (err) => onFailed(dispatch, failed, err)
    );
  };
};
