import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Search from '../search';

const FIAS_SOURCE = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
const AUTH_TOKEN = '3b134b396487d8bf26ff611d5328ad792629eca8';

const request = axios.create({
  headers: {
    Authorization: `Token ${AUTH_TOKEN}`,
    'Content-Type': 'application/json',
  },
});

/**
 * Возвращает отформатированную фразу с разметкой подсветки
 * @param value
 * @param data
 * @param term
 * @return {*}
 */
const highlight = ({ value, data }, term) => {
  const stopWords = [
    data.city_type,
    data.city_type_full,
    data.region_type,
    data.region_type_full,
    data.flat_type,
    data.house_type,
    data.house_type_full,
    data.street_type,
    data.street_type_full,
  ].filter((val) => val);

  //формируем фразы для подсвечивания и убираем сокращения, которые не нужно подсвечивать
  const highlightKeys = term
    .split(/\s+|,|\./)
    .filter((part) => part.length > 0 && stopWords.includes(part) === false);

  return highlightKeys.reduce(function (acc, key) {
    return acc.replace(new RegExp(`(${key})`, 'i'), '<span class="highlight">$1</span>');
  }, value);
};

/**
 * Получение адресов
 * @param term фраза для поиска
 * @return {Promise<Array>}
 */
const ajaxCallback = async ({ term }) => {
  const response = await request.post(FIAS_SOURCE, { query: term, count: 10 });
  const { suggestions = [] } = response.data;

  return suggestions.map((item) => ({
    title: item.value,
    name: item.value,
    value: item.data.fias_id,
    formatted: <span dangerouslySetInnerHTML={{ __html: highlight(item, term) }} />,
    fias_level: item.data.fias_level,
    flat: item.data.flat,
    house: item.data.house,
    house_type: item.data.house_type,
    block: item.data.block,
    block_type: item.data.block_type,
    street: item.data.street,
    city_fias_id: item.data.city_fias_id,
    house_fias_id: item.data.house_fias_id,
    region_fias_id: item.data.region_fias_id,
    street_fias_id: item.data.street_fias_id,
  }));
};

const resultRenderer = ({ formatted }) => {
  return <label>{formatted}</label>;
};

resultRenderer.propTypes = {
  formatted: PropTypes.any,
};

const FiasSearch = (props) => {
  return <Search {...props} resultRenderer={resultRenderer} ajaxCallback={ajaxCallback} />;
};

FiasSearch.defaultProps = {
  description: 'Для ввода адреса по фиас необходимо выбрать значение из выпадающего списка',
};

FiasSearch.propTypes = {
  description: PropTypes.string,
};

export default FiasSearch;
