import React from 'react';
import './styles.less';

interface Props {
  onClick?: () => void;
}

export function GotoLive(props: Props) {
  return (
    <div onClick={props.onClick} className={'video-archive-goto-live control'}>
      <span className="tooltip">Прямая трансляция</span>
    </div>
  );
}
