import {
  PARSER_ROW_FAIL,
  PARSER_ROW_REQUEST,
  PARSER_ROWS_SUCCESS,
} from '../../../constants/actions';

const defaultState = {
  item: {},
  isFetching: false,
  error: null,
};

export default function parserRow(state = {}, action) {
  switch (action.type) {
    case PARSER_ROW_REQUEST: {
      return {
        ...state,
        [action.payload.id]: {
          ...defaultState,
          isFetching: true,
        },
      };
    }
    case PARSER_ROWS_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          item: action.payload.item,
        },
      };
    case PARSER_ROW_FAIL: {
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || {}),
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    default:
      break;
  }

  return state;
}
