import React, { ComponentType, ReactNode } from 'react';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'core/store';
import { isSectionLoading } from 'core/store/selectors/app';
import { getCamerasItems } from 'core/store/selectors/cameras';
import { clear as clearApartments } from 'core/store/features/apartments';
import { clear as clearCompanies } from 'core/store/features/companies';
import { clear as clearBuildings } from 'core/store/features/buildings';
import { clear as clearIntercoms } from 'core/store/features/intercoms';
import { clear as clearDevicesReferences } from 'core/store/features/devices-references';
import { clear as clearCameras } from 'core/store/features/cameras';
import { Camera, Intercom } from 'core/constants/contracts';
import { Card, Loader, IconButton } from 'components/share';
import { ErrorMessage } from 'components/share/errors/message';
import { fetchData, SECTION_LOADING_NAME } from './data/fetch';
import { createSelectIntercom } from './data/selectors';
import {
  Cameras,
  Monitoring,
  Commands,
  IntercomView,
  IntercomHeader,
  IntercomInfoRow,
} from './components';
import { RouteComponentProps, Link } from 'react-router-dom';
import * as RouteUrl from 'core/constants/routes';
import { createSelectMapToArray } from 'core/store/selectors';
import { IntercomAgg } from 'core/store/selectors/intercoms';
import { SettingsRestore } from 'components/share/assets';
import { request } from 'core/utils/request';
import api from 'core/constants/api';
import notification from 'core/utils/notification';

export interface IntercomProps {
  intercom: Intercom;
}

interface OwnProps {
  id: string;
  type: 'barrier' | 'intercom';
  breadcrumbs: ComponentType<IntercomProps>;
  header: ComponentType<IntercomProps>;
  history: RouteComponentProps['history'];
}

interface Props extends OwnProps, ConnectedProps<typeof withConnect> {}

interface State {
  breadcrumbs: ReactNode;
  header: ReactNode;
  isLoadingDelete: boolean;
}

class IntercomViewContainerInner extends React.Component<Props, State> {
  state: State = {
    breadcrumbs: null,
    header: null,
    isLoadingDelete: false,
  };

  componentDidMount() {
    this.props.fetchData(this.props.id);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const intercom = this.props.intercom;
    if (intercom && intercom !== prevProps.intercom) {
      const Breadcrumbs = this.props.breadcrumbs;
      const Header = this.props.header;

      this.setState({
        breadcrumbs: <Breadcrumbs intercom={intercom} />,
        header: <Header intercom={intercom} />,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearStore();
  }

  handleIntercomEditForm = () => {
    this.props.history.push(RouteUrl.intercomEdit(this.props.id));
  };

  handleBarrierEditForm = () => {
    this.props.history.push(RouteUrl.barrierEdit(this.props.id));
  };

  handleDeleteIntercom = async () => {
    try {
      await request.delete(api.intercomsV2(this.props.id));
      return true;
    } catch (error) {
      console.error(error);
      notification.error('При попытке удаления панели произошла ошибка');
      return false;
    } finally {
      notification.success('Панель успешно удалена');
    }
  };

  handleDeleteCamera = (externalСamerasList: string[]) => async () => {
    externalСamerasList.forEach(async (id) => {
      try {
        await request.delete(api.cameras(id));
      } catch (error) {
        console.error(error);
        notification.error('При попытке удаления камеры произошла ошибка');
      } finally {
        notification.success('Камеры успешно удалены');
      }
    });
  };

  handleUnlinkCamera = (externalСamerasList: string[]) => async () => {
    if (externalСamerasList.length > 0) {
      try {
        await request.put(api.intercomsV2(this.props.id), { call_cameras: [] });
      } catch (error) {
        console.error(error);
        notification.error('При попытке отвязать камеры произошла ошибка');
      } finally {
        notification.success('Камеры успешно отвязаны');
      }
    }
  };

  handleIsLoadingDelete = (value: boolean) => {
    this.setState({ isLoadingDelete: value });
  };

  render() {
    const { id, intercom, cameras, buildings, type, isFetching, error } = this.props;
    const { isLoadingDelete } = this.state;
    const intercomCallCameras = this.props.intercom ? this.props.intercom.call_cameras : [];
    let callCamerasList: Array<string> = [];

    const isIntercom = type === 'intercom';
    const cardTitle = `Информация о ${isIntercom ? 'домофоне' : 'шлагбауме'}`;
    const isDisabled = isLoadingDelete;

    if (intercomCallCameras) {
      callCamerasList = intercomCallCameras.filter((camera) => camera !== intercom?.camera?.id);
    }

    if (error) {
      return <ErrorMessage>{error}</ErrorMessage>;
    }

    if (isFetching) {
      return <Loader active={true} placeholder={true} />;
    }

    if (!isFetching && intercom && !intercom.device) {
      return <ErrorMessage>Данные нарушены</ErrorMessage>;
    }

    if (!isFetching && intercom) {
      return (
        <Grid>
          {this.state.breadcrumbs}

          <IntercomHeader
            header={this.state.header}
            intercom={intercom}
            deleteProps={{
              hasExternalCameras: Boolean(callCamerasList.length),
              isLoadingDelete,
              handleDeleteIntercom: this.handleDeleteIntercom,
              handleDeleteCamera: this.handleDeleteCamera(callCamerasList),
              handleUnlinkCamera: this.handleUnlinkCamera(callCamerasList),
              handleIsLoadingDelete: this.handleIsLoadingDelete,
            }}
            isDisabled={isDisabled}
          />

          <IntercomInfoRow
            device={intercom.device}
            ownerStatus={intercom.__owner_status?.name}
            buildingIsTest={intercom.__building_is_test}
            buildings={buildings}
          />

          <Grid.Row>
            <Grid.Column>
              <Card
                title={cardTitle}
                onEdit={isIntercom ? this.handleIntercomEditForm : this.handleBarrierEditForm}
                buttonProps={{ disabled: isDisabled }}
                actions={
                  <IconButton
                    icon={<SettingsRestore />}
                    secondary
                    as={isDisabled ? 'button' : Link}
                    to={isIntercom ? RouteUrl.intercomHistory(id) : RouteUrl.barrierHistory(id)}
                    disabled={isDisabled}
                  >
                    История операций
                  </IconButton>
                }
              >
                <IntercomView intercom={intercom} />
              </Card>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Commands intercom={intercom} isDisabled={isDisabled} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Monitoring intercom={intercom} isDisabled={isDisabled} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Cameras intercomId={intercom.id} cameras={cameras} isDisabled={isDisabled} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }

    return null;
  }
}

const selectIntercomsAggAsArray = createSelectIntercom();
const selectCamerasAsArray = createSelectMapToArray<Camera>();

function mapStateToProps(state: RootState, props: OwnProps) {
  return {
    intercom: {
      ...selectIntercomsAggAsArray(state, props)[0],
      config: state.entities.intercoms?.metadata?.config || null,
    } as Nullable<IntercomAgg>,
    cameras: selectCamerasAsArray(getCamerasItems(state)),
    buildings: Object.values(state.entities.buildings.items),
    error: state.entities.intercoms.error || state.entities.devices_references.error,
    isFetching: isSectionLoading(state, SECTION_LOADING_NAME, true),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const clearStore = () => {
    bindActionCreators(clearIntercoms, dispatch)();
    bindActionCreators(clearDevicesReferences, dispatch)();
    bindActionCreators(clearCameras, dispatch)();
    bindActionCreators(clearApartments, dispatch)();
    bindActionCreators(clearCompanies, dispatch)();
    bindActionCreators(clearBuildings, dispatch)();
  };

  return {
    fetchData: bindActionCreators(fetchData, dispatch),
    clearStore,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const IntercomViewContainer = withConnect(IntercomViewContainerInner);
