import { PromiseThunk } from 'core/store';
import { fetchDeviceReferences } from 'core/store/features/devices-references';
import { fetchDeviceCategories } from 'core/store/features/devices-categories';
import { startSectionLoading, stopSectionLoading } from 'core/store/features/app';
import { createSelectDeviceCategoriesByAlias } from 'core/store/selectors/devices-categories';
import { fetchCompanies } from 'core/store/features/companies';

export const LOADING_SECTION_NAME = 'create_video_recorders';

export const createFetchData = (categories: Array<string>) => {
  const selectDeviceCategoriesByAlias = createSelectDeviceCategoriesByAlias(categories);

  return (): PromiseThunk => {
    return (dispatch, getState) => {
      dispatch(startSectionLoading(LOADING_SECTION_NAME));

      return dispatch(fetchDeviceCategories())
        .then(() => {
          const categories = selectDeviceCategoriesByAlias(getState());
          if (categories.length === 0) {
            return;
          }
          const categoriesIds = categories.map((v) => v.id);

          const promiseDeviceReferences: Promise<any> = dispatch(
            fetchDeviceReferences({
              limit: 1000,
              filter: { category: categoriesIds },
            })
          );
          const promiseCompanies: Promise<any> = dispatch(fetchCompanies({ limit: 3000 }));
          const promiseAll: Promise<any> = Promise.all([promiseCompanies, promiseDeviceReferences]);

          return promiseAll;
        })
        .finally(() => dispatch(stopSectionLoading(LOADING_SECTION_NAME)));
    };
  };
};
