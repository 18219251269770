import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { IntercomViewContainer, IntercomProps } from '../../../abstract/view';
import {
  DEFAULT_TABLE_PARAMS,
  LocationStateNavigation,
  pathWithTableParams,
} from 'core/utils/routing';
import * as RouteUrl from 'core/constants/routes';

interface RouteParams {
  id: string;
}

function Breadcrumbs(props: IntercomProps) {
  const location = useLocation<LocationStateNavigation>();
  const { tableParams } = location.state || DEFAULT_TABLE_PARAMS;

  return (
    <React.Fragment>
      <SetBreadcrumb
        url={pathWithTableParams(RouteUrl.intercomList, tableParams.page, tableParams.size)}
      >
        Домофоны
      </SetBreadcrumb>
      <SetBreadcrumb>{props.intercom?.phone}</SetBreadcrumb>
    </React.Fragment>
  );
}

function Header(props: IntercomProps) {
  return (
    <React.Fragment>Карточка домофона {props.intercom.name || props.intercom.phone}</React.Fragment>
  );
}

export default function IntercomPage(props: RouteComponentProps<RouteParams>) {
  return (
    <IntercomViewContainer
      id={props.match.params.id}
      type="intercom"
      breadcrumbs={Breadcrumbs}
      header={Header}
      history={props.history}
    />
  );
}
