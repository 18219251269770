import React from 'react';
import { Field } from 'redux-form';

interface Props {
  as: React.ElementType;
  name: string;
  label: string;
  isRequired?: boolean;
  [key: string]: any;
}

const GenericField: React.FunctionComponent<Props> = (props) => {
  const { name, as, label, isRequired, ...rest } = props;

  return (
    <Field
      key={name}
      name={name}
      component={as}
      label={label}
      required={true === isRequired}
      {...rest}
    />
  );
};

export default GenericField;
