import {
  INTERCOM_CREATE_SUCCESS,
  INTERCOM_FAIL,
  INTERCOM_REQUEST,
  INTERCOM_SUCCESS,
  INTERCOM_UPDATE_FAIL,
  INTERCOM_UPDATE_REQUEST,
  INTERCOM_UPDATE_SUCCESS,
} from '../../../../constants/actions';

const defaultState = {
  item: {},
  metadata: {},
  isFetching: false,
  error: null,
};

export default function intercom(state = {}, action) {
  switch (action.type) {
    case INTERCOM_UPDATE_REQUEST: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: true,
        },
      };
    }
    // case INTERCOM_CREATE_REQUEST:
    case INTERCOM_REQUEST: {
      return {
        ...state,
        [action.payload.id]: {
          ...defaultState,
          isFetching: true,
        },
      };
    }
    case INTERCOM_SUCCESS:
    case INTERCOM_UPDATE_SUCCESS:
    case INTERCOM_CREATE_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          item: action.payload.item,
          metadata: action.payload.metadata,
        },
      };
    }
    // case INTERCOM_CREATE_FAIL:
    case INTERCOM_FAIL:
    case INTERCOM_UPDATE_FAIL: {
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || {}),
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    default:
      break;
  }

  return state;
}
