import React from 'react';
import { DropdownItemProps, Form, Header } from 'semantic-ui-react';
import GenericField from './generic-field';
import { DatePicker, Dropdown, FiasSearch } from 'components/share/redux-form';
import { ReportFilter } from 'core/constants/contracts';

interface Props {
  filter: ReportFilter;
}

const FilterFactory: React.FunctionComponent<Props> = ({ filter }) => {
  switch (filter.type) {
    case 'Fias': {
      return (
        <GenericField
          as={FiasSearch}
          name={filter.id}
          label={filter.title}
          isRequired={filter.isRequired}
        />
      );
    }
    case 'Date': {
      return (
        <GenericField
          as={DatePicker}
          name={filter.id}
          label={filter.title}
          isRequired={filter.isRequired}
          inputOptions={{ format: 'MMMM YYYY', placeholder: 'С', style: { width: 250 } }}
        />
      );
    }
    case 'Dropdown': {
      const { options, ...rest } = filter.arguments;

      const optionsPrepared: Nullable<Array<DropdownItemProps>> = options?.map((v) => {
        return { key: v.value, ...v };
      });

      return (
        <GenericField
          as={Dropdown}
          name={filter.id}
          label={filter.title}
          isRequired={filter.isRequired}
          options={optionsPrepared}
          {...rest}
        />
      );
    }
    case 'Title': {
      return (
        <Form.Field className={'center-vertically'}>
          <Header>{filter.title}</Header>
        </Form.Field>
      );
    }
  }

  return null;
};

export default FilterFactory;
