import React from 'react';
import {
  STATUS_ACTIVE,
  STATUS_DEFECTIVE,
  STATUS_INACTIVE,
  STATUS_NEW,
} from 'core/store/reducers/app/intercoms';

const colorMap = {
  [STATUS_NEW]: 'orange',
  [STATUS_INACTIVE]: '#817F71',
  [STATUS_DEFECTIVE]: 'red',
  [STATUS_ACTIVE]: 'green',
};

export const getAdminStatusColor = (status) => {
  return typeof colorMap[status] !== 'undefined' ? colorMap[status] : '#817F71';
};

export function hintOperationalStatus(isOnline) {
  if (isOnline) {
    return 'Оборудование функционирует нормально';
  }

  return (
    <div>
      <p>Невозможно получить состояние оборудования. Возможные проблемы:</p>
      <ul>
        <li>Нет связи с домофоном.</li>
        <li>Изменились доступы к домофону.</li>
        <li>Домофон выключен. Проверьте подключение домофона.</li>
      </ul>
      <p>Попробуйте перезагрузить панель кнопкой справа.</p>
      <p>
        При сохранении проблемы рекомендуем создать обращение в форме обратной связи внизу экрана
        или обратиться в поддержку по номеру телефона 8-800-250-0873
      </p>
    </div>
  );
}

export const maxetlineSwitchMap = {
  maxetline_metacom: 'MaxetLine - конвертер(metacom)',
  maxetline_bk_400: 'MaxetLine - конвертер(Визит БК-400)',
};

export const maxetlineSwitchSet = new Set(Object.values(maxetlineSwitchMap));
