import React from 'react';
import { IconButton } from 'components/share';
import { Column, CellProps } from 'react-table';
import { File } from 'components/share/assets';
import { Popup } from 'semantic-ui-react';
import { Status } from '../components/Status';
import { IntercomCommand } from 'core/constants/contracts';
import moment from 'moment';
import { DATE_TIME_SEC_FORMAT } from 'core/constants/app';
import { deviceCommandsList } from 'core/constants/device-commands';
import { getValueOrDash } from 'core/utils/field';

type IColumnsProps = {
  handleDetails: (details: IntercomCommand) => void;
};

export const getColumns = ({ handleDetails }: IColumnsProps): Array<Column<IntercomCommand>> => [
  {
    Header: 'Категория команд',
    accessor: 'command',
    Cell: ({ row: { original } }: CellProps<IntercomCommand>) => {
      return getValueOrDash(deviceCommandsList[original.command]);
    },
  },
  {
    Header: 'Результат',
    accessor: 'is_successful',
    Cell: ({ cell: { value } }: CellProps<IntercomCommand>) => {
      return getValueOrDash(value, (checkedValue) => <Status isSuccessful={checkedValue} />);
    },
  },
  {
    Header: 'Дата',
    accessor: 'created_at',
    Cell: ({ cell: { value } }: CellProps<IntercomCommand>) => {
      return getValueOrDash(value, (checkedValue) =>
        moment(checkedValue).format(DATE_TIME_SEC_FORMAT)
      );
    },
  },
  {
    Header: 'Действия',
    Cell: ({ row: { original } }: CellProps<IntercomCommand>) => {
      return (
        <Popup
          trigger={
            <IconButton
              icon={<File />}
              secondary
              onClick={() => handleDetails(original)}
            ></IconButton>
          }
          content="Детализация команды"
          position="bottom right"
          inverted
        />
      );
    },
    width: 100,
  },
];
