import { Binding, BindingType, isBuildingObject } from 'core/constants/contracts';

export const getBuildingBindingsIdList = (
  buildingIdList: string[],
  bindingsList: Binding[]
): string[] => {
  return buildingIdList.flatMap((buildingId) => {
    const bindingBuildings = bindingsList.find(({ type, building }) => {
      const id = isBuildingObject(building) ? building.id : building;
      return id === buildingId && type === 'building';
    })?.id;

    return bindingBuildings ? bindingBuildings : [];
  });
};

type StringVariable = string | string[];

type CollectBindingsArgs = {
  building: StringVariable;
  entrance: StringVariable;
  apartment: string;
  bindingsList: Binding[];
};

export const collectBindingsData = ({
  building,
  entrance,
  apartment,
  bindingsList,
}: CollectBindingsArgs) => {
  let bindings: string[] = [];

  if (building !== '' || building.length !== 0) {
    if (Array.isArray(building)) {
      bindings = getBuildingBindingsIdList(building, bindingsList);
    } else {
      bindings = getBuildingBindingsIdList([building], bindingsList);
    }
  }

  if (entrance.length !== 0) {
    bindings = [...entrance];
  }

  if (apartment !== '') {
    bindings = [apartment];
  }

  return bindings;
};

export const countDeviceBindings = (bindingsList: Binding[], bindingType: BindingType): number => {
  let count = 0;

  if (!bindingsList.length) {
    return count;
  }

  bindingsList.forEach((binding) => {
    if (binding.type === bindingType) {
      count++;
    }
  });

  return count;
};

export const getDeviceBindingsByType = (
  bindingsList: Binding[],
  bindingType: BindingType
): Binding[] => {
  let bindings: Binding[] = [];

  if (!bindingsList.length) {
    return bindings;
  }

  bindingsList.forEach((binding) => {
    if (binding.type === bindingType) {
      bindings.push(binding);
    }
  });

  return bindings;
};
