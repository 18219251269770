export interface WebAPIResponse<T = any> {
  list: Array<T>;
  metadata: WebAPIMetadata;
  error: Nullable<WebAPIErrorData>;
}

export interface WebAPIMetadata {
  total: number;
  page: number;
  pageCount: number;
  perPage: number;
  [key: string]: any;
}

export interface WebAPIErrorData {
  code: number;
  message: string;
  data?: WebAPICustomApiError | ApiValidationError | string;
}

export interface WebAPICustomApiError {
  alias: string;
  payload?: object;
}

export interface WebAPIValidationError {
  field: string;
  errors: string[];
}

export interface ApiValidationError {
  fields?: Array<WebAPIValidationError>;
}

//----------------------------------------------

export interface WebAPIActionFetchParams {
  limit?: number;
  page?: number;
  sort?: string | object;
  filter?: object;
  [key: string]: any; // Возможность прокинуть кастомные ключи
}

export const DefaultActionFetchParams: Readonly<WebAPIActionFetchParams> = {
  limit: 30,
  page: 0,
  filter: {},
};

export interface NestError {
  statusCode: string;
  data: string;
  message: string;
}
