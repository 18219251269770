import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { TextArea } from 'semantic-ui-react';
import { Form } from 'mts-ui';
import classnames from 'classnames';

const TextAreaInput = ({
  input,
  label,
  meta: { touched = false, error = '' } = {},
  readonly = false,
  isEdited = false,
  help,
  required,
  className,
  rows,
}) => {
  const hasError = touched && error !== '';

  return (
    <Form.FieldAdvanced
      required={required}
      label={label}
      className={classnames(className, { 'edited-field': isEdited })}
      error={hasError ? error : undefined}
      hint={help}
    >
      <TextArea {...input} placeholder={label} readOnly={readonly} rows={rows} />
    </Form.FieldAdvanced>
  );
};

TextAreaInput.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  readonly: PropTypes.bool,
  isEdited: PropTypes.bool,
  help: PropTypes.string,
  required: PropTypes.bool,
};

export default TextAreaInput;
