import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createDeleteEntity,
  createEntitiesInitialState,
  createFetchEntities,
  createFetchEntity,
  createSaveEntity,
  createUpdateEntity,
  onDelete,
  onFailed,
  onSuccess,
} from './entities';
import api from '../../constants/api';
import { ApiThunk, PromiseThunk } from '..';
import { request } from '../../utils/request';
import { getUsersItems } from '../selectors/users';

interface SwapParent {
  nextParentId: string;
  prevParentId?: string;
}

const slice = createSlice({
  name: 'users',
  initialState: createEntitiesInitialState<User>(),
  reducers: {
    ...createDefaultEntitiesReducers<User>(),
    swap_parent: (state, action: PayloadAction<SwapParent>) => {
      return state;
    },
  },
});

export default slice;

export const { pending, success, failed, clear, deleted, swap_parent } = slice.actions;

export const fetchUser = createFetchEntity(api.users, slice.actions);
export const saveUser = createSaveEntity<User>(api.users, slice.actions);
export const updateUser = createUpdateEntity(api.users, slice.actions);
export const fetchUsers = createFetchEntities(api.users(), slice.actions);
export const clearUsers = clear;
export const deleteUser = createDeleteEntity(api.users, slice.actions);

export const saveStaff = createSaveEntity<User>(api.staff, slice.actions);
export const deleteStaff = createDeleteEntity(api.staff, slice.actions);

export const swapParentUser = (nextParentId: string): ApiThunk<User> => {
  return (dispatch: Dispatch, getState) => {
    dispatch(pending());

    const state = getState();
    const nextUser = getUsersItems(state)[nextParentId];
    const prevUser = getUsersItems(state)[nextUser?.parent?.id];

    return request
      .post(api.swapParent(nextParentId))
      .then(
        (res) => onSuccess<User>(dispatch, success, res),
        (err) => onFailed(dispatch, failed, err)
      )
      .then((res) => {
        dispatch(swap_parent({ nextParentId, prevParentId: prevUser?.id }));

        return res;
      });
  };
};

export const evictUser = (userId: string, apartmentId: string): PromiseThunk<string> => {
  return (dispatch: Dispatch) => {
    dispatch(pending());

    return request.delete(api.users(userId)).then(
      (res) => onDelete(dispatch, deleted, userId),
      (err) => onFailed(dispatch, failed, err)
    );
  };
};

export const evictStaff = (userOrStaffId: string, apartmentId: string): PromiseThunk<string> => {
  return (dispatch: Dispatch) => {
    dispatch(pending());

    return request.post(api.staffEvict(userOrStaffId), { apartment_id: apartmentId }).then(
      (res) => onDelete(dispatch, deleted, userOrStaffId),
      (err) => onFailed(dispatch, failed, err)
    );
  };
};

interface UpdateStaffData {
  first_name?: string;
  last_name?: string;
  patronymic?: string;
  apartment_id?: string;
}

export const updateStaff = createUpdateEntity<UpdateStaffData>(api.staff, slice.actions);
