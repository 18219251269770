import React, { FC } from 'react';
import { Grid } from 'semantic-ui-react';
import TextField from 'components/share/components/text-field';
import isEmpty from 'lodash/isEmpty';

interface Props {
  last_name: string;
  first_name: string;
  mid_name?: string;
  personalAccount?: string;
}

const IndividualCompanyView: FC<Props> = ({ last_name, first_name, mid_name, personalAccount }) => {
  return (
    <>
      <Grid.Column width={5}>
        <TextField title={'ФИО'}>
          {`${last_name} ${first_name}` + (mid_name ? ` ${mid_name}` : ``)}
        </TextField>
      </Grid.Column>
      <Grid.Column width={5}>
        <TextField title="Лицевой счет">
          {isEmpty(personalAccount) ? '-' : personalAccount}
        </TextField>
      </Grid.Column>
    </>
  );
};

export default IndividualCompanyView;
