import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from 'semantic-ui-react';
import { Icon } from '../../icon';
import cx from 'classnames';
import Spinner from '../../spinner';

type IButtonStyledProps = ButtonProps & {
  iconOnly: boolean;
};

type IIconButtonProps = ButtonProps & {
  icon?: React.ReactNode;
  loadingText?: string;
};

const ButtonStyled = styled(Button)<IButtonStyledProps>`
  &&&& {
    display: inline-flex;
    align-items: center;
    height: fit-content;
    box-sizing: border-box;
    ${(props: IButtonStyledProps) => props.iconOnly && 'padding: 10px;'}
    margin: 0;

    & > i {
      margin-right: ${(props: IButtonStyledProps) => (props.iconOnly ? 0 : '0.5rem')};
    }

    & > svg {
      margin-right: ${(props: IButtonStyledProps) => (props.iconOnly ? 0 : '0.5rem')};
    }
  }
`;

const SpinnerStyled = styled(Spinner)`
  &&&& {
    margin-right: 0.5rem;
  }
`;

export const IconButton: FC<IIconButtonProps> = ({
  icon,
  loading,
  loadingText = 'Идет загрузка...',
  children,
  ...buttonProps
}) => {
  const iconOnly = Boolean(icon) && !Boolean(children);
  const classes =
    buttonProps.as &&
    cx('ui button', buttonProps.primary && 'primary', buttonProps.secondary && 'secondary');

  return (
    <ButtonStyled className={classes} iconOnly={iconOnly} {...buttonProps}>
      {icon && !loading && <Icon icon={icon} size="1.25rem" />}
      {loading && <SpinnerStyled size={20} />}
      {!loading ? children : loadingText}
    </ButtonStyled>
  );
};
