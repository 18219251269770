import { Field, FieldProps } from 'formik';
import React from 'react';
import MaskedInput from 'react-input-mask';
import { Input as SemanticInput, StrictInputProps } from 'semantic-ui-react';
import { FormFieldWrapper, FormFieldWrapperProps, useGetComponentProps } from '../field';
import { setFieldValue } from '../helpers';
import { BaseFormikFieldProps } from '../props';

interface OwnProps extends FormFieldWrapperProps, Omit<StrictInputProps, 'label' | 'onChange'> {
  onChange?: (
    event: React.FormEvent<HTMLInputElement>,
    data: StrictInputProps,
    props: Props
  ) => void;
  placeholder?: string;
}

interface Props extends OwnProps, FieldProps {}

function InputControl(props: Props) {
  const {
    onChange: onChangeOrigin,

    field,
    form,
    meta,

    ...rest
  } = useGetComponentProps(props);

  return (
    <FormFieldWrapper {...props}>
      <SemanticInput
        {...rest}
        children={
          <MaskedInput
            mask="+7 (999) 999-99-99"
            value={field.value}
            onChange={(e) => setFieldValue(form, field.name, e.target.value, true)}
            disabled={props.disabled}
          />
        }
      />
    </FormFieldWrapper>
  );
}

interface InputProps extends BaseFormikFieldProps<string>, OwnProps {}

function InputMaskPhone(props: InputProps) {
  return <Field component={InputControl} {...props} />;
}

export { InputMaskPhone };
