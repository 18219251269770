import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { get, debounce } from 'lodash';
import { Search as SemanticSearch } from 'semantic-ui-react';
import { Form } from 'mts-ui';

import './style.less';

export default class Search extends Component {
  static defaultProps = {
    minCharacters: 2,
    showNoResults: false,
    noResultsMessage: 'По Вашему запросу ничего не найдено',
    required: false,
    readOnly: false,
  };

  state = {
    isLoading: false,
    results: [],

    value: get(this.props.input, 'value.title', ''),
    result: get(this.props.input, 'value', null),
  };

  static getDerivedStateFromProps(props, state) {
    if (state.item !== props.input.value) {
      return {
        value: props.input.value.title,
        item: props.input.value,
      };
    }

    return null;
  }

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title, result });

    if (this.props.prepareValue) {
      result = this.props.prepareValue(result);
    }

    if (this.props.handleResultSelect) {
      this.props.handleResultSelect(result);
    }

    this.props.input.onChange(result);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    if (this.props.handleSearchChange) {
      this.props.handleSearchChange(value);
    }

    // debounce
    this.debouncedOnSearchChange(value);
  };

  onSearchChangeCallback = (value) => {
    this.props.ajaxCallback({ term: value }).then((results) => {
      this.setState({
        isLoading: false,
        results,
      });
    });
  };

  debouncedOnSearchChange = debounce(this.onSearchChangeCallback, 1000);

  render() {
    const {
      ajaxCallback, // eslint-disable-line no-unused-vars
      input, // eslint-disable-line no-unused-vars
      meta: { touched = false, error = '' } = {},

      minCharacters,
      label,
      required,
      description,
      ...rest
    } = this.props;

    const { isLoading, results, value } = this.state;
    const hasError = touched && error;

    return (
      <Form.FieldAdvanced
        label={label}
        required={required}
        error={hasError ? error : undefined}
        hint={description}
      >
        <SemanticSearch
          {...rest}
          value={value}
          fluid
          loading={isLoading}
          results={results}
          minCharacters={minCharacters}
          onResultSelect={this.handleResultSelect}
          onSearchChange={this.handleSearchChange}
        />
      </Form.FieldAdvanced>
    );
  }
}

Search.propTypes = {
  ...fieldPropTypes,
  ...SemanticSearch.propTypes,
  label: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,

  ajaxCallback: PropTypes.func.isRequired,
  handleResultSelect: PropTypes.func,
  handleSearchChange: PropTypes.func,
};
