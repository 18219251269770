import '../styles.less';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Field, reduxForm, SubmissionError, formValueSelector } from 'redux-form';
import get from 'lodash/get';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import { Button, Dimmer, Form, Grid, Icon, Loader, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormButtonGroup } from 'components/share/form';
import { confirm } from 'components/share/dialogs';
import { createIntercom, intercomAvailable } from 'core/store/actions/intercoms';
import notification from 'core/utils/notification';
import { requestErrorHandlerForReduxForm } from 'core/utils/errors';
import { createSelectMapToArray } from 'core/store/selectors';
import { getDeviceReferencesItems } from 'core/store/selectors/devices-references';
import { createFetchData } from 'views/pages/devices/abstract/create/data/fetch';
import { Checkbox, TextInput, Dropdown } from 'components/share/redux-form';
import * as RouteUrl from 'core/constants/routes';
import { getCompaniesItems } from 'core/store/selectors/companies';
import { actualOwnerOptions, schemaOptions, internetProviderOptions } from '../../../config';
import { validate, arrayToOptions, ringStrategyNone } from '../utils';
import schema from './schema';
import { maxetlineSwitchMap, maxetlineSwitchSet } from 'views/pages/devices/abstract/utils';
import styled from 'styled-components';

const Content = styled.div`
  white-space: pre;
`;

class Step1 extends React.Component {
  state = {
    isFetching: false,
    showPassword: false,
    optionsRingStrategy: [ringStrategyNone],
  };

  componentDidMount() {
    this.props.fetchData();
  }

  handleNext = async (values) => {
    this.setState({ isFetching: true });
    const { selectedMaxetLineMetacom, selectedMaxetLine } = this.props;

    let result, resultCode, message;

    try {
      result = await this.props.intercomAvailable(values.ip, values.login, values.password, {
        reference: values.reference,
        port: +values.port,
        schema: values.schema,
      });

      resultCode = get(result, 'list.0.code');
      message = get(result, 'list.0.message', '');
    } catch (e) {
      return requestErrorHandlerForReduxForm(e);
    } finally {
      this.setState({ isFetching: false });
    }

    const isUnsuccessful = resultCode !== 'successful';
    const header = isUnsuccessful ? 'Панель недоступна' : 'Подтверждение операции';
    const content = isUnsuccessful ? (
      <Content>{`Не удалось сконфигурировать панель \nВвести данные для отложенного подключения панели?`}</Content>
    ) : (
      'Вы уверены, что хотите добавить устройство (панель домофона)?'
    );

    if (resultCode) {
      confirm({
        header,
        content,
        onConfirm: async () => {
          let intercomResult;

          if (void 0 === values.id) {
            this.setState({ isFetching: true });
            const intercomData = {
              device: {
                reference: values.reference,
                protocol: values.schema,
                hostname: values.ip,
                port: +values.port,
                owner: values.owner,
              },
              api: {
                protocol: values.schema,
                hostname: values.ip,
                port: +values.port,
                username: values.login,
                password: values.password,
              },
              actual_owner: values.actual_owner,
              internet_provider: values.is_mts_internet_provider
                ? internetProviderOptions.mts
                : internetProviderOptions.other,
              regional_billing_account: values.regional_billing_account,
              serial_number: values.serial_number,
              ring_strategy: values.ring_strategy !== 'none' && values.ring_strategy,
              name: values.name,
              ...(isUnsuccessful && { status: 'pending' }),
            };

            if (selectedMaxetLine) {
              intercomData.device['additional_protocol_config'] = {
                'set-sip-config': {
                  ...(selectedMaxetLineMetacom &&
                    !isUndefined(values.сapacity) && { capacity: +values.сapacity }),
                  ...(!isUndefined(values.shift1) && { shift1: +values.shift1 }),
                  ...(!isUndefined(values.range1) && { range1: values.range1.replace(/\s+/g, '') }),
                  ...(!isUndefined(values.shift2) && { shift2: +values.shift2 }),
                  ...(!isUndefined(values.range2) && { range2: values.range2.replace(/\s+/g, '') }),
                },
              };
            }

            try {
              intercomResult = await this.props.createIntercom(intercomData);
              message = get(intercomResult, 'error');
            } catch (e) {
              console.error(e);
              message = e.message;
            } finally {
              this.setState({ isFetching: false });
            }

            this.props.change('id', get(intercomResult, 'list.0.id'));
          }

          if (message) {
            notification.error(message);
          } else {
            this.props.next();
          }
        },
      });
    } else {
      message = message || 'Произошла ошибка на стороне сервера';
      throw new SubmissionError({ ip: message });
    }
  };

  handleMtsInternet = (value) => {
    if (value === false) {
      this.props.change('regional_billing_account', undefined);
    }
  };

  handleReference = (value) => {
    const ringStrategies =
      this.props.deviceReferences.find((item) => item.id === value).payload.ring_strategies || [];

    if (ringStrategies.length) {
      const ringStrategiesOptions = ringStrategies.map((item) => ({
        key: item,
        value: item,
        text: item,
      }));
      this.setState({ optionsRingStrategy: ringStrategiesOptions });
      this.props.change('ring_strategy', ringStrategies[0]);
    }
  };

  render() {
    const { isFetching, showPassword } = this.state;
    const {
      handleSubmit,
      invalid,
      submitting,
      deviceReferences: deviceReferencesItems,
      isFetching: deviceReferencesIsFetching,
      isMtsInternetProvider,
      companies,
      selectedMaxetLineMetacom,
      selectedMaxetLine,
    } = this.props;

    const optionsDeviceReferences = deviceReferencesItems.map(arrayToOptions);
    const optionsCompanies = companies.map(arrayToOptions);

    return (
      <Segment padded className="segment-form">
        <Form error={invalid}>
          <Dimmer active={isFetching || deviceReferencesIsFetching} inverted>
            <Loader />
          </Dimmer>

          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="3">
                  <Field name="name" component={TextInput} required label="Название" />
                  <Field
                    name="owner"
                    label="Владелец"
                    placeholder="Владелец"
                    component={Dropdown}
                    options={optionsCompanies}
                    search
                    clearable
                  />
                </Form.Group>

                <Form.Group widths="3">
                  <Field
                    name="schema"
                    component={Dropdown}
                    label="Schema"
                    placeholder="Schema"
                    options={schemaOptions}
                    className="helper-nowrap"
                    clearable
                  />
                  <Field
                    name="ip"
                    required
                    component={TextInput}
                    label="IP-адрес"
                    controlProps={{
                      required: false,
                    }}
                  />
                  <Field
                    name="port"
                    required
                    component={TextInput}
                    label="Порт"
                    className="helper-nowrap"
                    controlProps={{
                      required: false,
                    }}
                  />
                </Form.Group>

                <Form.Group widths="3">
                  <Field
                    name="actual_owner"
                    component={Dropdown}
                    label="Принадлежность панели"
                    placeholder="Принадлежность панели"
                    options={actualOwnerOptions}
                    className="helper-nowrap"
                    clearable
                  />
                  <div className="toggle-field">
                    <span className="toggle-field-label">Принадлежность интернета</span>
                    <Field
                      name="is_mts_internet_provider"
                      component={Checkbox}
                      toggle
                      label="Принадлежит МТС"
                      onChange={this.handleMtsInternet}
                    />
                  </div>
                  {isMtsInternetProvider && (
                    <Field
                      name="regional_billing_account"
                      component={TextInput}
                      label="ЛС регионального биллинга"
                      controlProps={{
                        required: false,
                      }}
                    />
                  )}
                </Form.Group>

                <Form.Group widths="3">
                  <Field
                    name="reference"
                    required
                    component={Dropdown}
                    label="Поставщик оборудования"
                    placeholder="Поставщик оборудования"
                    options={optionsDeviceReferences}
                    controlProps={{
                      required: false,
                    }}
                    onChange={this.handleReference}
                  />
                  <Field
                    name="ring_strategy"
                    label="Стратегия вызова"
                    component={Dropdown}
                    options={this.state.optionsRingStrategy}
                  />
                  <Field
                    name="serial_number"
                    component={TextInput}
                    label="Серийный № панели"
                    controlProps={{
                      required: false,
                    }}
                  />
                </Form.Group>

                {selectedMaxetLineMetacom && (
                  <Form.Group widths="3">
                    <Field
                      name="сapacity"
                      component={TextInput}
                      label="Емкость коммутатора"
                      controlProps={{
                        required: false,
                      }}
                    />
                  </Form.Group>
                )}

                {selectedMaxetLine && (
                  <Form.Group widths="3">
                    <Field
                      name="shift1"
                      component={TextInput}
                      label="Сдвиг нумерации портов 1"
                      controlProps={{
                        required: false,
                      }}
                    />
                    <Field
                      name="range1"
                      component={TextInput}
                      label="Диапазон портов 1"
                      placeholder="0 - 100"
                      controlProps={{
                        required: false,
                      }}
                    />
                  </Form.Group>
                )}

                {selectedMaxetLine && (
                  <Form.Group widths="3">
                    <Field
                      name="shift2"
                      component={TextInput}
                      label="Сдвиг нумерации портов 2"
                      controlProps={{
                        required: false,
                      }}
                    />
                    <Field
                      name="range2"
                      component={TextInput}
                      label="Диапазон портов 2"
                      placeholder="0 - 100"
                      controlProps={{
                        required: false,
                      }}
                    />
                  </Form.Group>
                )}

                <Form.Group widths="3">
                  <Field
                    name="login"
                    required
                    component={TextInput}
                    label="Логин"
                    controlProps={{
                      required: false,
                    }}
                  />
                  <Field
                    name="password"
                    required
                    component={TextInput}
                    label="Пароль"
                    type={showPassword ? 'text' : 'password'}
                    controlProps={{
                      required: false,
                    }}
                    autoComplete="new-password"
                    icon={
                      <Icon
                        name={showPassword ? 'eye slash' : 'eye'}
                        link
                        onClick={() => {
                          this.setState({
                            showPassword: !showPassword,
                          });
                        }}
                      />
                    }
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column className="step1-button-group">
                <FormButtonGroup>
                  <Button secondary as={Link} to={RouteUrl.intercomList}>
                    Отменить
                  </Button>
                  <Button
                    primary
                    icon
                    labelPosition="right"
                    floated="right"
                    disabled={submitting}
                    onClick={handleSubmit(this.handleNext)}
                  >
                    <Icon name="angle right" />
                    Далее
                  </Button>
                </FormButtonGroup>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}

Step1.propTypes = {
  createIntercom: PropTypes.func,
  intercomAvailable: PropTypes.func,
  fetchDeviceReferences: PropTypes.func,
  deviceReferences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      vendor: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      category: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  companies: PropTypes.array,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  next: PropTypes.func.isRequired,
};

export const FORM_ID = 'create_intercom.step1';

const Step1ReduxForm = reduxForm({
  form: FORM_ID,
  validate: validate(schema),
})(Step1);

const selectMapToArray = createSelectMapToArray();
const fetchData = createFetchData(['intercom']);
const selector = formValueSelector(FORM_ID);

const mapStateToProps = (state) => {
  const deviceReferences = selectMapToArray(getDeviceReferencesItems(state));
  const reference = selector(state, 'reference');
  const selectedDeviceReferenceName = find(deviceReferences, { id: reference })?.name;
  const selectedMaxetLineMetacom =
    selectedDeviceReferenceName === maxetlineSwitchMap.maxetline_metacom;
  const selectedMaxetLine = maxetlineSwitchSet.has(selectedDeviceReferenceName);

  return {
    initialValues: {
      is_mts_internet_provider: false,
      ring_strategy: 'none',
      сapacity: 0,
    },
    isFetching: state.entities.devices_references.pending,
    companies: selectMapToArray(getCompaniesItems(state)),
    isMtsInternetProvider: selector(state, 'is_mts_internet_provider'),
    deviceReferences,
    reference,
    selectedMaxetLineMetacom,
    selectedMaxetLine,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createIntercom: bindActionCreators(createIntercom, dispatch),
    intercomAvailable: bindActionCreators(intercomAvailable, dispatch),
    fetchData: bindActionCreators(fetchData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step1ReduxForm);
