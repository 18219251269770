import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { DropdownItemProps } from 'semantic-ui-react';
import { RootState } from 'core/store';
import { isSectionLoading } from 'core/store/selectors/app';
import { getCompaniesItems } from 'core/store/selectors/companies';
import { createSelectArrayToArray } from 'core/store/selectors';
import { Company, DeviceReference } from 'core/constants/contracts';
import { getDeviceReferencesItems } from 'core/store/selectors/devices-references';
import { Card, ErrorMessage, Loader } from 'components/share';
import { SaveBarrierForm } from './form';
import { createFetchData, LOADING_SECTION_NAME } from '../../../abstract/create/data/fetch';
import { RouteComponentProps } from 'react-router';

interface Props
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps>,
    RouteComponentProps {}

class CreateBarrierContainerInner extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchData();
  }

  onSubmit = (id: string) => this.props.history.push(`/barriers/${id}`);
  onCancel = () => this.props.history.push('/barriers');

  render() {
    const { companies, references, isFetching, error } = this.props;

    if (error) {
      return <ErrorMessage>{error}</ErrorMessage>;
    }

    if (isFetching) {
      return <Loader active={true} placeholder={true} />;
    }

    return (
      <Card title={'Общие сведения'}>
        <SaveBarrierForm
          owners={companies}
          references={references}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </Card>
    );
  }
}

const selectCompaniesAsOptions = createSelectArrayToArray<Company, DropdownItemProps>((v) => ({
  value: v.id,
  text: v.name,
}));

const selectDeviceReferencesAsOptions = createSelectArrayToArray<
  DeviceReference,
  DropdownItemProps
>((v) => ({
  value: v.id,
  text: v.name,
}));

const fetchData = createFetchData(['barrier']);

function mapStateToProps(state: RootState) {
  return {
    companies: selectCompaniesAsOptions(getCompaniesItems(state)),
    references: selectDeviceReferencesAsOptions(getDeviceReferencesItems(state)),
    error: null,
    isFetching: isSectionLoading(state, LOADING_SECTION_NAME, true),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchData: bindActionCreators(fetchData, dispatch),
  };
}

const CreateBarrierContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBarrierContainerInner);

export { CreateBarrierContainer };
