import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// как бы дерево, но без корня
export function prepareBindings(bindings) {
  let data = [];
  if (Array.isArray(bindings)) {
    for (let i = 0; i < bindings.length; i++) {
      const row = bindings[i];

      const entrancesUnion = row.entrances_union;
      const entrance = row.entrance;
      const floor = '999999999';
      const apartments = {
        from: +get(row, 'apartments.from'),
        to: +get(row, 'apartments.to'),
      };
      const ids = row.ids || {};

      let payload = {
        number_service_application: [],
      };
      const poList = bindings[i].poList.numbers.filter(Boolean);

      if (poList.length > 0) {
        payload.number_service_application = [...poList];
      }

      const nodes = [
        {
          id: ids.entrances_union,
          type: 'entrances_union',
          value: entrancesUnion,
        },
        {
          id: ids.entrance,
          type: 'entrance',
          value: entrance,
          payload,
        },
        {
          id: ids.floor,
          type: 'floor',
          value: floor,
        },
        {
          id: ids.apartments ? ids.apartments[0] : undefined,
          type: 'apartment',
          value: apartments,
        },
      ];

      toTree(
        nodes.filter((v) => !isEmpty(v.value)),
        data
      );
    }
  }

  return data;
}

export function toTree(nodes, data) {
  if (false === Array.isArray(nodes)) {
    return data;
  }

  const node = nodes.shift();
  if (!node) {
    return data;
  }

  if (Array.isArray(data)) {
    const existNode = data.find((v) => v.type === node.type && v.value === node.value);
    if (existNode) {
      if (existNode.id !== node.id) {
        existNode.id = node.id;
      }

      return toTree(nodes, existNode.children);
    }
  } else {
    data = [];
  }

  const temp = {
    ...node,
    children: toTree(nodes),
  };

  data.push(temp);

  return data;
}

function rangeOverlaps(a_start, a_end, b_start, b_end) {
  if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
  if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
  if (b_start < a_start && a_end < b_end) return true; // a in b
  return false;
}

function mutlipleRangeOverlaps(ranges) {
  let i = 0,
    j = 0;
  if (ranges.length > 1)
    for (i = 0; i < ranges.length - 1; i += 1) {
      for (j = i + 1; j < ranges.length; j += 1) {
        if (rangeOverlaps(+ranges[i].from, +ranges[i].to, +ranges[j].from, +ranges[j].to))
          return true;
      }
    }
  return false;
}

const prepareRanges = (bindings) => bindings.map(({ apartments }) => apartments);

export const hasIntersections = (bindings) => mutlipleRangeOverlaps(prepareRanges(bindings));
