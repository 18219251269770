import React from 'react';
import PropTypes from 'prop-types';
// import { get, isNaN } from 'lodash'
import Range from './index';
import TextInput from '../text-input';

const InputNumber = (props) => {
  return <TextInput {...props} type={'number'} />;
};

const rangeMinMaxProps = {
  minFrom: PropTypes.number,
  maxFrom: PropTypes.number,
  minTo: PropTypes.number,
  maxTo: PropTypes.number,
};

const FromInput = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { minTo, maxTo, minFrom, maxFrom, ...rest } = props;

  return <InputNumber {...rest} min={minFrom} max={maxFrom} />;
};

FromInput.propTypes = rangeMinMaxProps;

const ToInput = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { minTo, maxTo, minFrom, maxFrom, ...rest } = props;

  return <InputNumber {...rest} min={minTo} max={maxTo} />;
};

ToInput.propTypes = rangeMinMaxProps;

const validateFrom = (value, allValues, props) => {
  // if (this.props.ruleLTE) {
  //     const from = +value;
  //     const to = +get(allValues, `${this.name}_to`);
  //
  //     if (!isNaN(from) && !isNaN(to) && from > to) {
  //         return `${this.props.labelContentFrom || 'От'} должно быть меньше ${this.props.labelContentTo || 'До'}(${to})`;
  //     }
  // }
  //
  return void 0;
};

const validateTo = (value, allValues, props) => {
  return void 0;
};

const NumberRange = (props) => {
  return (
    <Range
      {...props}
      componentFrom={FromInput}
      componentTo={ToInput}
      validateFrom={validateFrom}
      validateTo={validateTo}
    />
  );
};

NumberRange.propTypes = {
  ...Range.propTypes,
  ...rangeMinMaxProps,
  // ruleLTE: PropTypes.bool,
};

export default NumberRange;
