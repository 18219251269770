import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

// const year = (new Date()).getFullYear();

export default function Footer() {
  return (
    <Segment className="content-margin-left footer">
      <Grid centered stackable>
        <Grid.Row centered columns={'equal'}>
          <Grid.Column>© {new Date().getFullYear()} ПАО «МТС» 18+</Grid.Column>
          <Grid.Column>
            Круглосуточная служба поддержки 8-800-250-0873. Бесплатно по России.
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}
