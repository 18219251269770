import React, { ComponentType } from 'react';
import styled from 'styled-components';
import { ThemeVars } from 'core/styles/vars';

interface WrapperProps {
  padded: {
    vertical: number;
    horizontal: number;
  };
}

const Wrapper = styled.div<WrapperProps>`
  padding: ${(props) => `${props.padded.vertical}px ${props.padded.horizontal}px`};
  border: 2px solid ${ThemeVars.colors.linkWater};
  border-radius: 8px;
  margin-bottom: 24px; //unit((24 / 16), rem);

  &:last-child {
    margin-bottom: 0;
  }
`;

const padded = {
  low: {
    vertical: 16,
    horizontal: 24,
  },
  normal: {
    vertical: 24,
    horizontal: 32,
  },
  // TODO пока нигде не применяется
  high: {
    vertical: 32,
    horizontal: 40,
  },
};

interface Props {
  className?: string;
  padded?: keyof typeof padded;
}

export const CardItem: ComponentType<Props> = (props) => {
  const { padded: paddedSize = 'normal', className } = props;

  return (
    <Wrapper padded={padded[paddedSize]} className={className}>
      {props.children}
    </Wrapper>
  );
};
