import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ThemeVars } from '../../../../../../core/styles/vars';

interface Size {
  fontSize: string;
  lineHeight: string;
  fontWeight: string;
}

const sizes: Record<number, Size> = {
  12: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '500',
  },
  14: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'normal',
  },
  16: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'normal',
  },
};

interface LinkProps {
  size: Size;
}

const Link = styled.a<LinkProps>`
  display: inline-block;
  padding: 0 2px 0 2px;
  font-style: normal;
  font-weight: ${(props) => props.size.fontWeight};
  font-size: ${(props) => props.size.fontSize};
  line-height: ${(props) => props.size.lineHeight};
  color: ${ThemeVars.colors.normalBlueberry};

  &:hover {
    color: ${ThemeVars.colors.darkBlueberry};
  }

  &:active {
    color: ${ThemeVars.colors.lightBlueberry};
  }

  &:focus {
    border: none;
    background-color: ${ThemeVars.colors.darkBlueberry};
    color: ${ThemeVars.colors.white};
    outline-style: none;
  }
`;

export interface LinkNaturalProps {
  href: string;
  size?: keyof typeof sizes;
  className?: string;
}

export function LinkNatural(props: PropsWithChildren<LinkNaturalProps>) {
  const { href, size = 16, className, children } = props;

  return (
    <Link
      size={sizes[size]}
      href={href}
      className={className}
      // onKeyDown={}
      // onKeyUp={}
    >
      {children}
    </Link>
  );
}
