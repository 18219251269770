import { BUILDING_FAIL, BUILDING_REQUEST, BUILDING_SUCCESS } from '../../../constants/actions';

const defaultState = {
  item: {},
  isFetching: false,
  error: null,
};

export default function building(state = {}, action) {
  switch (action.type) {
    case BUILDING_REQUEST: {
      return {
        ...state,
        [action.payload.id]: {
          ...defaultState,
          isFetching: true,
        },
      };
    }
    case BUILDING_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          item: action.payload.item,
        },
      };
    }
    case BUILDING_FAIL: {
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || {}),
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    default:
      break;
  }

  return state;
}
