import React, { useEffect, useCallback, useState } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { IntercomCommand } from 'core/constants/contracts';
import { TableRedux, TableReduxFetchOptions } from 'components/share/components/table';
import { getColumns } from './table/columns';
import { Filters, IFormValues } from './components/Filters';
import {
  clear as clearIntercomCommands,
  fetchIntercomCommands,
} from 'core/store/features/intercom-commands';
import { wrapEntitiesTableSelectorFactory } from 'core/store/selectors';
import { getIntercomCommands } from 'core/store/selectors/intercom-commands';
import { ModalWindow } from 'components/share/components/modals';
import { Details } from './components/Details';
import qs from 'qs';
import { TableParams } from 'core/utils/routing';
import moment from 'moment';
import { DATE_TIME_FORMAT, DATE_FORMAT } from 'core/constants/app';
import lodash from 'lodash';

type IParamsProps = {
  id: string;
};

type IStoreProps = {
  isFetching: boolean;
};

const DeviceHistory = () => {
  const { id } = useParams<IParamsProps>();
  const params = useParams();
  const parsedParams = qs.parse(params, { ignoreQueryPrefix: true }) as TableParams;

  const [openModal, setOpenModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<IntercomCommand | undefined>();

  const dispatch = useDispatch();
  const clearStore = () => {
    dispatch(clearIntercomCommands());
  };

  const { isFetching } = useSelector<RootState, IStoreProps>((state) => ({
    isFetching: state.entities.intercomCommands.pending,
  }));

  useEffect(() => {
    return clearStore;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const fetcher = useCallback(
    ({ pageIndex, pageSize, ...other }: TableReduxFetchOptions) => {
      return fetchIntercomCommands({
        page: pageIndex + 1,
        limit: pageSize,
        sort: '-created_at',
        intercomId: id,
        ...other,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [id]
  );

  const selector = useCallback(
    wrapEntitiesTableSelectorFactory(getIntercomCommands, {
      withActions: false,
    }),
    []
  );

  const onSubmit = (values: IFormValues) => {
    const newValues = lodash(values)
      .omitBy(lodash.isEmpty)
      .omitBy(lodash.isUndefined)
      .omitBy(lodash.isNull)
      .value();

    const filter: Record<string, any> = {};

    if (lodash.has(newValues, 'commandCategory')) {
      filter.command = newValues.commandCategory;
    }

    if (lodash.has(newValues, 'status')) {
      filter.is_successful = newValues.status === 'success';
    }

    if (lodash.has(newValues, 'startDate')) {
      filter.start_created_at = moment(
        newValues.startDate?.toString(),
        DATE_FORMAT
      ).toLocaleString();
    }

    if (lodash.has(newValues, 'endDate')) {
      filter.end_created_at = moment(newValues.endDate?.toString(), DATE_TIME_FORMAT)
        .endOf('day')
        .toLocaleString();
    }

    dispatch(
      fetcher({
        pageIndex: 0,
        pageSize: +parsedParams.size || 30,
        filter,
      })
    );
  };

  const onReset = () => {
    dispatch(
      fetcher({
        pageIndex: 0,
        pageSize: +parsedParams.size || 30,
      })
    );
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setSelectedDetails(undefined);
  };

  const handleDetails = (details: IntercomCommand) => {
    onOpenModal();
    setSelectedDetails(details);
  };

  const columns = getColumns({ handleDetails });

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1">История операций</Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Filters onSubmit={onSubmit} onReset={onReset} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <TableRedux
            columns={columns}
            selector={selector}
            fetcher={fetcher}
            isFetching={isFetching}
            showUpperPagination
          />
        </Grid.Column>
      </Grid.Row>

      <ModalWindow
        size="fullscreen"
        open={openModal}
        header="Детализация команды"
        onClose={onCloseModal}
      >
        {selectedDetails && <Details details={selectedDetails} />}
      </ModalWindow>
    </Grid>
  );
};

export default DeviceHistory;
