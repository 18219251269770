import { Formik } from 'formik';
import React, { FC, FormEvent, useCallback } from 'react';
import { Form, Icon, InputOnChangeData, SemanticICONS } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import { Input } from 'components/share/form/formik';
import styled from 'styled-components';

export interface CompanyFilterForm {
  name: string;
  personal_account: string;
}

interface CompanyFilterProps {
  filters: CompanyFilterForm;
  setFilters: React.Dispatch<React.SetStateAction<CompanyFilterForm>>;
}

const FormGroupStyled = styled(Form.Group)`
  &&&& {
    margin: 0em -1.5em;
  }
`;

export const CompanyFilter: FC<CompanyFilterProps> = ({ filters, setFilters }) => {
  const onSubmit = (values: CompanyFilterForm) => {
    setFilters(values);
  };

  const onChange = (
    _event: React.FormEvent<HTMLInputElement>,
    { id, value }: InputOnChangeData
  ) => {
    const name = id.replace('field.', '');
    setFilters({ ...filters, [name]: value });
  };

  const onSubmitDebounced = useCallback(debounce(onChange, 750), [onChange]);

  return (
    <Formik initialValues={filters} onSubmit={onSubmit}>
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <FormGroupStyled widths={3}>
            <Input
              name="personal_account"
              placeholder="Лицевой счет"
              icon={<Icon name={'mts-icon-search' as SemanticICONS} />}
              onChange={onSubmitDebounced}
              hideLabel
              hideHint
            />
            <Input
              name="name"
              placeholder="Наименование"
              icon={<Icon name={'mts-icon-search' as SemanticICONS} />}
              onChange={onSubmitDebounced}
              hideLabel
              hideHint
            />
          </FormGroupStyled>
        </Form>
      )}
    </Formik>
  );
};
