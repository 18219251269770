import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { IntercomViewContainer, IntercomProps } from '../../../abstract/view';
import {
  DEFAULT_TABLE_PARAMS,
  LocationStateNavigation,
  pathWithTableParams,
} from 'core/utils/routing';
import * as RouteUrl from 'core/constants/routes';

interface RouteParams {
  id: string;
}

function Breadcrumbs(props: IntercomProps) {
  const location = useLocation<LocationStateNavigation>();
  const { tableParams } = location.state || DEFAULT_TABLE_PARAMS;

  return (
    <React.Fragment>
      <SetBreadcrumb
        url={pathWithTableParams(RouteUrl.barrierList, tableParams.page, tableParams.size)}
      >
        Шлагбаумы
      </SetBreadcrumb>
      <SetBreadcrumb>{props.intercom?.phone}</SetBreadcrumb>
    </React.Fragment>
  );
}

function Header(props: IntercomProps) {
  return <React.Fragment>Карточка шлагбаума {props.intercom.phone}</React.Fragment>;
}

export default function BarrierPage(props: RouteComponentProps<RouteParams>) {
  return (
    <IntercomViewContainer
      id={props.match.params.id}
      type="barrier"
      breadcrumbs={Breadcrumbs}
      header={Header}
      history={props.history}
    />
  );
}
