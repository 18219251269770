import React, { FC } from 'react';
import { Grid, Button, Form, DropdownItemProps } from 'semantic-ui-react';
import { Formik } from 'formik';

import { Dropdown, Input, InputPassword } from 'components/share/form/formik';

import { videoRecorderProtocolOptions } from '../config';
import { SegmentStyled, ColumnStyled, InputStyled15 } from '../styles';
import { schema } from '../schema';

export interface ApiConfig {
  protocol?: string;
  hostname?: string;
  port?: string | number;
  username?: string;
  password?: string;
}

export interface VideoRecorderFormData extends ApiConfig {
  rtsp_port?: string | number;
  reference: string;
  owner: string;
  name?: string;
}

interface VideoRecorderFormProps {
  references: Array<DropdownItemProps>;
  companies: Array<DropdownItemProps>;
  handleCancel: () => void;
  handleSubmit: (values: VideoRecorderFormData) => void;
}

export const VideoRecorderForm: FC<VideoRecorderFormProps> = ({
  references,
  companies,
  handleCancel,
  handleSubmit,
}) => {
  const handleCancelInner = () => {
    handleCancel();
  };

  return (
    <SegmentStyled padded>
      <Formik<VideoRecorderFormData>
        initialValues={{
          username: '',
          password: '',
          name: '',
          port: '',
          protocol: '',
          hostname: '',
          reference: '',
          owner: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form.Group widths="3">
                    <Input name="name" label="Название" />
                    <Dropdown
                      name="owner"
                      required
                      label="Владелец"
                      options={companies}
                      search
                      clearable
                    />
                    <Dropdown
                      name="reference"
                      required
                      label="Поставщик оборудования"
                      options={references}
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Form.Group widths="3">
                    <Dropdown
                      name="protocol"
                      required
                      label="Протокол"
                      placeholder="Протокол"
                      options={videoRecorderProtocolOptions}
                    />
                    <Input name="hostname" label="Hostname" required />
                    <InputStyled15 name="port" label="API Порт" required />
                    <InputStyled15 name="rtsp_port" label="RTSP Порт" />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Form.Group widths="3">
                    <Input name="username" required label="Логин" />
                    <InputPassword
                      name="password"
                      label="Пароль"
                      required
                      autoComplete="new-password"
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <ColumnStyled>
                  <Button type="button" secondary onClick={handleCancelInner}>
                    Отменить
                  </Button>
                  <Button type="submit" primary>
                    Сохранить
                  </Button>
                </ColumnStyled>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </SegmentStyled>
  );
};
