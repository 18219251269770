import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon, List, Popup } from 'semantic-ui-react';

import { confirm } from 'components/share/dialogs';

export default class FileItem extends Component {
  render() {
    const {
      item,
      errorIcon,
      uploadDescription,
      title,
      fsize,
      fileField,
      handleChange,
      handleUpdateFile,
      handleUploadFiles,
      handleDeleteFile,
      canEdit,
      titleMap,
    } = this.props;

    return (
      <List.Item>
        <div className="icon-status">
          {item.isFetching && !item.errorMessage && <Icon name="spinner" size="big" loading />}
          {item.errorMessage && (
            <Popup placement="top" content={item.errorMessage} trigger={errorIcon} />
          )}
          {!item.errorMessage && !item.isFetching && item.isUploaded && (
            <Icon name="checkmark box" size="big" color={'green'} />
          )}
        </div>
        <List.Content className="inline">
          <List.Description>
            {titleMap && <span>{item.fileType.title}</span>}
            {title && <Popup placement="top" content={item.name} trigger={uploadDescription} />}
            {item.size && <span className="component-uploader-description">{fsize}</span>}
          </List.Description>
        </List.Content>
        <List.Content>
          {item.description && fileField && !fileField.isUpdate && (
            <List.Description
              className="file-description text-muted ellipsis"
              title={item.description}
            >
              {item.description}
            </List.Description>
          )}
        </List.Content>
        <List.Content>
          {fileField && fileField.isUpdate && (
            <Form.Group>
              <Form.Input
                type="text"
                width={11}
                autoFocus
                defaultValue={fileField.description || ''}
                onChange={(e) => {
                  handleChange(item.id, {
                    ...fileField,
                    description: e.target.value,
                  });
                }}
              />
              <Button
                positive
                title="Сохранить"
                onClick={() => {
                  handleUpdateFile(item.id, fileField).then(() => {
                    handleChange(item.id, {
                      ...fileField,
                      isUpdate: false,
                    });
                  });
                }}
              >
                {' '}
                <Icon name="checkmark" />
              </Button>
            </Form.Group>
          )}
        </List.Content>

        <div
          className="component-uploader-toolbar"
          style={{ top: 5, boxShadow: '0 0 10px rgba(0,0,0,0.5)' }}
        >
          <Button.Group>
            {!item.isFetching && item.errorMessage !== '' && !item.isUploaded && (
              <Popup
                placement="top"
                content={'Повторить'}
                trigger={
                  <Button
                    title="Повторить"
                    positive
                    color="orange"
                    onClick={() => {
                      handleUploadFiles([item.originalFile]);
                    }}
                  >
                    <Icon name="repeat" size="small" />
                  </Button>
                }
              />
            )}
            {item.isUploaded && canEdit && (
              <Popup
                placement="top"
                content={<span>Редактировать</span>}
                trigger={
                  <Button
                    title="Редактировать"
                    positive
                    color="green"
                    onClick={() => {
                      handleChange(item.id, {
                        ...fileField,
                        isUpdate: !fileField.isUpdate,
                      });
                    }}
                  >
                    <Icon name="edit" size="small" />
                  </Button>
                }
              />
            )}
            {!item.isFetching && canEdit && (
              <Popup
                placement="top"
                content={'Удалить'}
                trigger={
                  <Button
                    color="red"
                    title="Удалить"
                    onClick={() => {
                      confirm({
                        content: `Удалить файл - ${item.filename}?`,
                        onConfirm: () => {
                          handleDeleteFile(item.id);
                        },
                      });
                    }}
                  >
                    <Icon name="remove" size="small" />
                  </Button>
                }
              />
            )}
          </Button.Group>
        </div>
      </List.Item>
    );
  }
}

FileItem.propTypes = {
  item: PropTypes.object,
  errorIcon: PropTypes.object,
  uploadDescription: PropTypes.string,
  title: PropTypes.string,
  fsize: PropTypes.number,
  fileField: PropTypes.object,
  handleChange: PropTypes.func,
  handleUpdateFile: PropTypes.func,
  handleUploadFiles: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  canEdit: PropTypes.bool,
  titleMap: PropTypes.array,
};
