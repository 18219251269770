import {
  UPLOAD_PARSER_SESSION_FAIL,
  UPLOAD_PARSER_SESSION_SUCCESS,
  UPLOAD_PARSER_SESSION_REQUEST,
} from '../../../constants/actions';

const defaultState = {
  isFetching: false,
  error: null,
};

export default function uploadParserSession(state = defaultState, action) {
  switch (action.type) {
    case UPLOAD_PARSER_SESSION_REQUEST: {
      return { ...state, isFetching: true, error: null };
    }
    case UPLOAD_PARSER_SESSION_SUCCESS: {
      return { ...state, isFetching: false };
    }
    case UPLOAD_PARSER_SESSION_FAIL: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    default:
      break;
  }

  return state;
}
