import { AxiosResponse } from 'axios';
import { IconButton } from 'components/share';
import { Settings } from 'components/share/assets';
import api from 'core/constants/api';
import { WEB_PANEL_URL } from 'core/constants/app';
import { ResponseList, WebProxyAccess } from 'core/constants/contracts';
import { IntercomAgg } from 'core/store/selectors/intercoms';
import notification from 'core/utils/notification';
import { request } from 'core/utils/request';
import React from 'react';
import { useCookies } from 'react-cookie';

interface WebPanelProps {
  intercom: IntercomAgg;
}

export const WepPanel: React.FC<WebPanelProps> = ({ intercom }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const setCookies = useCookies(['PX_TOKEN'])[1];

  const getIntercomUrl = async () => {
    try {
      setIsLoading(true);

      const {
        data: { list },
      } = await request.post<undefined, AxiosResponse<ResponseList<WebProxyAccess>>>(
        api.intercomProxy(intercom.id)
      );
      const token = list[0].token;

      setCookies('PX_TOKEN', token, { domain: '.domofon.mts.ru' });

      const url = WEB_PANEL_URL(token);
      window.open(url, '_blank');
    } catch (error) {
      console.error(error);
      notification.error('Web-панель недоступна');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IconButton
      secondary
      icon={<Settings />}
      onClick={getIntercomUrl}
      disabled={isLoading}
      loading={isLoading}
    >
      Настройки через PROXY
    </IconButton>
  );
};
