import React from 'react';
import PropTypes from 'prop-types';
import { change, Field, fieldPropTypes, stopSubmit } from 'redux-form';
import { InputMask, InputWithButton } from '../index';
import { isObject, set } from 'lodash';

function createValueChanger(props) {
  const { form, dispatch } = props.meta;
  const { name } = props.input;

  return function (field, value) {
    field = `${name}.${field}`;
    dispatch(change(form, field, value));
  };
}

export const STEP_INPUT = 1;
export const STEP_SEND = 2;
export const STEP_CONFIRM = 3;
export const DEFAULT_STEP = STEP_INPUT;

/**
 * @deprecated
 */
class PhoneConfirm extends React.Component {
  timer = null;

  state = {
    step: DEFAULT_STEP,
    countdown: 0,
  };

  static getDerivedStateFromProps(props, state) {
    const {
      onStepChange,
      input: { value: { phone } = {} },
    } = props;

    if (state.step !== DEFAULT_STEP && phone !== state.phone) {
      onStepChange && onStepChange(DEFAULT_STEP);

      const changer = createValueChanger(props);
      changer('code', '');
      changer('token_id', '');

      return { step: DEFAULT_STEP };
    }

    return null;
  }

  setFormErrors = (errors) => {
    const {
      meta: { form, dispatch },
    } = this.props;

    dispatch(stopSubmit(form, errors));
  };

  handleClick = () => {
    const { step } = this.state;

    switch (step) {
      case STEP_INPUT:
        return this.sendToken();
      case STEP_SEND:
        return this.confirmToken();
      default:
        break;
    }

    return void 0;
  };

  sendToken = () => {
    const {
      onStepChange,
      input: { name, value: { phone } = {} },
      resendTimerSec,
    } = this.props;

    if (!phone) {
      return;
    }

    const changer = createValueChanger(this.props);

    return this.props
      .createToken(phone)
      .then((token) => {
        this.setState(
          {
            step: STEP_SEND,
            phone,
            token,
            countdown: resendTimerSec,
          },
          () => {
            onStepChange && onStepChange(STEP_SEND);
          }
        );

        return token;
      })
      .then((token) => {
        this.createResendTimer();

        return token;
      })
      .then((token) => changer('token_id', token.id))
      .catch((err) => {
        console.error('>>>send err:', err);

        const data = err.errors;
        const field = `${name}.phone`;

        if (isObject(data)) {
          if (data.target) {
            set(data, field, data.target);
            delete data.target;
          }

          this.setFormErrors(data);
        } else {
          this.setFormErrors({ [field]: 'Произошла неизвестная ошибка при отправке' });
        }
      });
  };

  confirmToken = () => {
    const {
      token: { id },
    } = this.state;
    const {
      onStepChange,
      input: { name, value: { code } = {} },
    } = this.props;

    return this.props
      .verifyToken(id, code)
      .then((token) => {
        this.setState(
          {
            step: STEP_CONFIRM,
            token,
          },
          () => {
            onStepChange && onStepChange(STEP_CONFIRM);
          }
        );
      })
      .then(() => clearInterval(this.timer))
      .catch((err) => {
        console.error('>>>verify err:', err);

        const field = `${name}.phone`;
        this.setFormErrors({ [field]: 'Произошла неизвестная ошибка при подтверждение' });
      });
  };

  createResendTimer = () => {
    this.timer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState <= 1) {
          clearInterval(this.timer);
        }

        return {
          countdown: prevState.countdown - 1,
        };
      });
    }, 1000);
  };

  onResendToken = (e) => {
    e.preventDefault();
    clearInterval(this.timer);
    this.sendToken();
  };

  render() {
    const {
      meta: { error = {} },
      input: { name },
      required,
      readOnly,
    } = this.props;
    const { step, countdown } = this.state;

    const hasError = !!error.phone;
    const isDisabled = step !== STEP_CONFIRM;

    let help;
    if (step === STEP_SEND) {
      if (countdown > 0) {
        help = `Выслать повторно через ${countdown} сек.`;
      } else {
        help = (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" className="resend-button" onClick={this.onResendToken}>
            Отправить повторно
          </a>
        );
      }
    }

    return (
      <React.Fragment>
        <Field
          name={`${name}.phone`}
          component={InputMask}
          label={'Номер телефона'}
          mask="+7 (999) 999-99-99"
          formatChars={{ 9: '[0-9]' }}
          required={required}
          readOnly={readOnly}
        />
        <Field
          name={`${name}.code`}
          component={InputWithButton}
          label={'Код подтверждения'}
          placeholder={'Код из SMS'}
          readOnly={readOnly}
          hasError={hasError}
          action={{
            color: step === STEP_INPUT ? 'blue' : 'red',
            content: step === STEP_INPUT ? 'Выслать' : 'Проверить',
            disabled: !isDisabled || hasError,
            onClick: this.handleClick,
            primary: true,
          }}
          help={help}
          className={'confirm'}
          autoComplete={false}
        />
        <Field name={`${name}.token_id`} component={'input'} type={'hidden'} />
      </React.Fragment>
    );
  }
}

PhoneConfirm.defaultProps = {
  resendTimerSec: 60,
};

PhoneConfirm.propTypes = {
  ...fieldPropTypes,
  createToken: PropTypes.func.isRequired,
  verifyToken: PropTypes.func.isRequired,
  onStepChange: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  resendTimerSec: PropTypes.number,
};

export default PhoneConfirm;
