import React, { useState, useEffect, FC } from 'react';
import { Camera } from 'core/constants/contracts';
import styled from 'styled-components';
import { VideoPlayerWrapper, CameraCard } from 'components/share/components/intercom';
import * as RouteUrl from 'core/constants/routes';
import { Card } from 'components/share';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;

  & > * {
    margin-bottom: 1rem;
  }

  & > *:not(:last-child) {
    margin-right: 3rem;
  }
`;
interface ICamerasProps {
  cameras: Array<Camera>;
  intercomId: string;
  isDisabled: boolean;
}

export const Cameras: FC<ICamerasProps> = ({ cameras, intercomId, isDisabled }) => {
  const [selected, selectCamera] = useState<Camera | undefined>();

  useEffect(() => {
    if (cameras.length) {
      selectCamera(cameras[0]);
    }

    return () => {
      selectCamera(undefined);
    };
  }, [cameras]);

  const onSelectCamera = (camera: Camera) => () => selectCamera(camera);

  if (!cameras.length) {
    return (
      <Card title="Камеры">
        <Container>
          <CameraCard url={RouteUrl.intercomCameraCreate(intercomId)} isDisabled={isDisabled} />
        </Container>
      </Card>
    );
  }

  return (
    <Card title="Камеры">
      <Container>
        {cameras.map((camera, index) => {
          return (
            <CameraCard
              key={index}
              camera={camera}
              url={RouteUrl.intercomCameraEdit(intercomId, camera.id)}
              active={selected ? selected.id === camera.id : false}
              onClick={onSelectCamera(camera)}
              isDisabled={isDisabled}
            />
          );
        })}
      </Container>
      {selected && <VideoPlayerWrapper camera={selected} />}
    </Card>
  );
};
