import * as React from 'react';
import { Column } from 'semantic-data-grid';
import { FetchProps, ResponseResult, withSubscription } from './hoc';
import { DataGrid } from './components/data-grid';
import api from 'core/constants/api';
import { Link } from 'react-router-dom';
import { SetBreadcrumb } from '../../../components/share/breadcrumbs';

type MyProps = { data: ResponseResult } & FetchProps;

const columns = [
  {
    title: '#',
    type: Column.SERIAL_COLUMN,
    columnOptions: {
      width: 1,
    },
  },
  {
    title: 'Название',
    type: Column.SIMPLE_COLUMN,
    field: 'title',
    columnOptions: {
      width: 2,
    },
    value: (item: any) => <Link to={`/integrations/${item.alias}/sessions`}>{item.title}</Link>,
  },
];

export const IntegrationsPage = ({ data, fetchData }: MyProps) => {
  return (
    <>
      <SetBreadcrumb>Интеграции</SetBreadcrumb>
      <DataGrid
        {...data}
        fetchData={fetchData}
        header={'Интеграции'}
        emptyMessage={'Интеграции не найдены'}
        columns={columns}
      />
    </>
  );
};

export default withSubscription(IntegrationsPage, api.integrations());
