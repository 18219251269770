import { PromiseThunk } from 'core/store';
import { User, Company } from 'core/constants/contracts';
import { fetchCompany } from 'core/store/features/companies';
import { getCompaniesItems } from 'core/store/selectors/companies';
import { fetchUser } from 'core/store/features/users';
import { getUsersItems } from 'core/store/selectors/users';
import { startSectionLoading, stopSectionLoading } from 'core/store/features/app';
import notification from 'core/utils/notification';

export interface FetchDataProps {
  companyId: string;
  userId: string;
}

export const SECTION_LOADING_NAME = 'edit_company_user';

export const fetchData = ({ companyId, userId }: FetchDataProps): PromiseThunk => {
  return (dispatch, getState) => {
    dispatch(startSectionLoading(SECTION_LOADING_NAME));

    let company: Company = getCompaniesItems(getState())[companyId];
    const promiseCompany: Promise<any> = company
      ? Promise.resolve()
      : dispatch(fetchCompany(companyId));

    let user: User = getUsersItems(getState())[userId];
    const promiseUser: Promise<any> = user
      ? Promise.resolve()
      : dispatch(fetchUser(userId, { renew: true }));

    const promiseUserCompany: Promise<any> = Promise.all([promiseCompany, promiseUser]);

    return promiseUserCompany
      .catch((error) => {
        if (error.response) notification.error(error.response.data.error.data);
        else notification.error('Ошибка загрузки данных');
      })
      .finally(() => dispatch(stopSectionLoading(SECTION_LOADING_NAME)));
  };
};
