import {
  INTERCOMS_FAIL,
  INTERCOMS_REQUEST,
  INTERCOMS_SUCCESS,

  //INTERCOMS_ITEM_FAIL,
  INTERCOMS_ITEM_REQUEST,
  INTERCOMS_ITEM_SUCCESS,
} from '../../../constants/actions';

const defaultState = {
  items: [],
  isFetching: false,
  error: null,
  metadata: {},
};

export const STATUS_ACTIVE = 'active';
export const STATUS_INACTIVE = 'inactive';
export const STATUS_DEFECTIVE = 'defective';
export const STATUS_NEW = 'new';
export const STATUS_PENDING = 'pending';

const intercomStatusMap = {
  [STATUS_ACTIVE]: 'Активно',
  [STATUS_INACTIVE]: 'Не активно',
  [STATUS_DEFECTIVE]: 'Дефектное',
  [STATUS_NEW]: 'Новое',
};

export const addStatusInfo = (item) => {
  const statusInfo = { id: item.status, title: item.status };

  if (typeof intercomStatusMap[item.status] !== 'undefined') {
    statusInfo.title = intercomStatusMap[item.status];
  }

  return { ...item, status: statusInfo };
};

export const addStatusInfoToItems = (items) => {
  return items.map(addStatusInfo);
};

export default function intercoms(state = defaultState, action) {
  switch (action.type) {
    case INTERCOMS_REQUEST: {
      return { ...state, isFetching: true };
    }

    case INTERCOMS_ITEM_REQUEST: {
      const items = state.items.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        return {
          ...item,
          isFetching: true,
        };
      });

      return {
        ...state,
        items: items,
      };
    }

    case INTERCOMS_ITEM_SUCCESS: {
      const items = state.items.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        return {
          ...item,
          ...addStatusInfo(action.payload.item),
          isFetching: false,
        };
      });

      return {
        ...state,
        items: items,
      };
    }

    case INTERCOMS_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        error: null,
        isFetching: false,
        metadata: action.payload.metadata,
      };
    }
    case INTERCOMS_FAIL: {
      return { ...state, isFetching: false, error: action.payload.error };
    }
    default:
      break;
  }

  return state;
}
