import React, { FC } from 'react';
import { Grid } from 'semantic-ui-react';
import TextField from 'components/share/components/text-field';
import isEmpty from 'lodash/isEmpty';

interface Props {
  name: string;
  personalAccount?: string;
}

const LegalCompanyView: FC<Props> = ({ name, personalAccount }) => {
  return (
    <>
      <Grid.Column width={5}>
        <TextField title="Наименование">{name}</TextField>
      </Grid.Column>
      <Grid.Column width={5}>
        <TextField title="Лицевой счет">
          {isEmpty(personalAccount) ? '-' : personalAccount}
        </TextField>
      </Grid.Column>
    </>
  );
};

export default LegalCompanyView;
