import React from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { FieldProps } from 'formik';
import { BindingType } from 'core/constants/contracts';
import { Dropdown } from '../../../dropdown';
import { AddressRendererProps } from '../../component';

export class AddressSelectWithEntrance extends React.Component<AddressRendererProps> {
  handleChangeBuilding = (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps,
    props: FieldProps
  ) => {
    this.props.onChangeBuilding(data.value as string);
    // const { name } = this.props;
    // props.form.setFieldValue(`${name}.${BindingType.entrance}`, null);
  };

  handleChangeBinding = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    this.props.onChangeType(BindingType.entrance, data.value as string);
  };

  render() {
    const { name, buildingsOptions, bindingsTypesOptions, fetching } = this.props;

    return (
      <React.Fragment>
        <Dropdown
          name={`${name}.building_id`}
          label={'Адрес'}
          required
          onChange={this.handleChangeBuilding}
          options={buildingsOptions}
          className={'width-66'}
          loading={fetching['building_id']}
        />
        <Dropdown
          name={`${name}.${BindingType.entrance}`}
          label={'Подъезд'}
          required
          onChange={this.handleChangeBinding}
          options={bindingsTypesOptions[BindingType.entrance]}
          className={'width-33'}
          loading={fetching[BindingType.entrance]}
        />
      </React.Fragment>
    );
  }
}
