import { PromiseThunk } from 'core/store';
import { startSectionLoading, stopSectionLoading } from 'core/store/features/app';
import { fetchVideoRecorders } from 'core/store/features/video-recorders';
import { WebAPIActionFetchParams } from 'core/utils/webapi.contracts';

export const SECTION_LOADING_NAME = 'list_video_recorders';

export const fetchData = (props: WebAPIActionFetchParams): PromiseThunk => {
  return (dispatch) => {
    dispatch(startSectionLoading(SECTION_LOADING_NAME));

    return dispatch(fetchVideoRecorders(props))
      .finally(() => dispatch(stopSectionLoading(SECTION_LOADING_NAME)))
  };
};
