import { createAction, createSlice, Dispatch } from '@reduxjs/toolkit';
import { Intercom, DeviceCommandType } from '../../constants/contracts';
import {
  createActionOperationEntity,
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createFetchEntity,
  createSaveEntity,
  createUpdateEntity,
  createUpdateWithPostEntity,
  onFailed,
  onSuccess,
  Options,
} from './entities';
import api from '../../constants/api';
import { ApiThunk } from '..';
import { request } from '../../utils/request';

const unlinkSuccessAction = createAction('intercoms/success/unlink', (id: string) => {
  return {
    payload: id,
  };
});

const opts: Options<Intercom, Intercom> = {
  prepare: (intercom) => {
    if (Array.isArray(intercom.device?.bindings)) {
      intercom.device.bindings = intercom.device.bindings.map((v) => {
        if (v.binding && !v.type) {
          v.type = v.binding;
        }

        return v;
      });
    }

    return intercom;
  },
};

const slice = createSlice({
  name: 'intercoms',
  initialState: createEntitiesInitialState<Intercom>(),
  reducers: createDefaultEntitiesReducers<Intercom, Intercom>(opts),
  extraReducers: (build) =>
    build.addCase(unlinkSuccessAction, (state, action) => {
      const intercomId = action.payload;

      delete state.items[intercomId];
      state.ids = state.ids.filter((v) => v !== intercomId);

      return state;
    }),
});

export default slice;

export const { pending, success, failed, clear, action } = slice.actions;

export const fetchIntercom = createFetchEntity<Intercom>(api.intercoms, slice.actions);
export const fetchIntercoms = createFetchEntities<Intercom>(api.intercomsV2(), slice.actions);
export const updateIntercom = createUpdateEntity(api.intercomsV2, slice.actions);
export const saveIntercom = createSaveEntity(api.intercomsV2, slice.actions);

export const fetchUserIntercoms = (userId: string) =>
  fetchIntercoms({ filter: { user_id: userId } });
export const fetchIntercomsByDevice = (device: string | string[]) =>
  fetchIntercoms({ device: device });

interface PropertyData {
  key: string;
  value: any;
}

export const setIntercomProperty = createUpdateWithPostEntity<Intercom, PropertyData>(
  api.intercomProperty,
  slice.actions
);

/***********************************************************
 *                    Address link
 **********************************************************/

export const unlinkIntercom = (intercomId: string, userId: string): ApiThunk<Intercom> => {
  return (dispatch: Dispatch) => {
    dispatch(pending());

    return request.post(api.intercomsV2(intercomId) + '/unlink', { user_id: userId }).then(
      (res) => {
        const data = onSuccess<Intercom>(dispatch, success, res);
        dispatch(unlinkSuccessAction(intercomId));

        return data;
      },
      (err) => onFailed(dispatch, failed, err)
    );
  };
};

export const linkIntercom = (intercomId: string, userId: string): ApiThunk<Intercom> => {
  return (dispatch: Dispatch) => {
    dispatch(pending());

    return request.post(api.intercomsV2(intercomId) + '/link', { user_id: userId }).then(
      (res) => onSuccess(dispatch, success, res),
      (err) => onFailed(dispatch, failed, err)
    );
  };
};

/***********************************************************
 *                       Actions
 **********************************************************/

export const INTERCOM_ACTION_PROPERTY = 'property';

export const unlockIntercom = createActionOperationEntity({
  url: (id) => `${api.intercoms(id)}/unlock`,
  actionName: DeviceCommandType.unlock,
  actions: slice.actions,
  timeout: 10000,
  autoClear: true,
});
export const rebootIntercom = createActionOperationEntity({
  url: (id) => `${api.intercoms(id)}/reboot`,
  actionName: DeviceCommandType.reboot,
  actions: slice.actions,
});
export const setIntercomPropertyAsOperation = createActionOperationEntity({
  url: api.intercomProperty,
  actionName: INTERCOM_ACTION_PROPERTY,
  actions: slice.actions,
  withSuccess: true,
});
