import React, { FC, useCallback } from 'react';
import { TextArea as SemanticTextArea, TextAreaProps } from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import { setFieldValue } from '../helpers';
import { BaseFormikFieldProps } from '../props';
import { FormFieldWrapper, FormFieldWrapperProps, useGetComponentProps } from '../field';

interface OwnProps extends FormFieldWrapperProps, Omit<TextAreaProps, 'label' | 'onChange'> {
  onChange?: (event: React.FormEvent<HTMLInputElement>, props: Props) => void;
  placeholder?: string;
}

interface Props extends OwnProps, FieldProps {}

function TextAreaControl(props: Props) {
  const {
    onChange: onChangeOrigin,

    field,
    form,
    meta,
    children,
    placeholder,

    ...rest
  } = useGetComponentProps(props);

  const onChange = useCallback(
    (e, data) => {
      setFieldValue(form, field.name, data.value, true);
      onChangeOrigin?.(e, data, props);
    },
    [form, field, props, onChangeOrigin]
  );

  const placeholderInner = placeholder || props.label;

  return (
    <FormFieldWrapper {...props}>
      <SemanticTextArea
        {...rest}
        onChange={onChange}
        value={field.value}
        children={children}
        disabled={props.disabled}
        placeholder={placeholderInner}
      />
    </FormFieldWrapper>
  );
}

export type TextArea = BaseFormikFieldProps<string> & OwnProps;
export const TextArea: FC<TextArea> = (props) => {
  return <Field component={TextAreaControl} {...props} />;
};
