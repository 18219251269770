export interface MenuItem {
  title: string;
  name: string;
  url: string;
  style?: object;
  disabled?: boolean;
}

export interface MenuGroup {
  title: string;
  name: string;
  icon: string;
  style?: object;
  items?: MenuItem[];
  disabled?: boolean;
}

export type MenuType = MenuGroup | MenuItem;

export function isMenuItem(v: MenuType): v is MenuItem {
  return 'url' in v;
}

export function isMenuGroup(v: MenuType): v is MenuGroup {
  return 'items' in v || 'icon' in v;
}
