import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StrictTableHeaderCellProps } from 'semantic-ui-react';
import { ColumnInstance } from 'react-table';
import { Table } from 'mts-ui';
import { CellHeaderProps, Column, TableFetchOptions, TableProps } from '../contracts';
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from 'core/constants/app';

interface TableAsyncFetchOptions<T = any> extends TableFetchOptions {
  filter?: T;
  sort?: StateSort;
}

interface Fetcher {
  (params: TableAsyncFetchOptions): any;
}

interface Scroller {
  (ref: React.RefObject<HTMLElement>): void;
}

interface OwnProps {
  columns: Array<Column<any>>;
  data: Array<any>;
  fetcher?: Fetcher;
  isFetching?: boolean;
  filter?: object;
  sortable?: boolean;
  defaultSort?: StateSort;
  pageCount?: number;
  pageSizes?: Array<number>;
  defaultPageSize?: number;
  tableProps?: TableProps;
  scroll?: boolean | Scroller;
  // Количество элементов в таблице, которое будет удалено/добавлено для последующего автообновления таблицы
  refreshAfterResize?: number;
  isScrollable?: boolean;
  showUpperPagination?: boolean;
  queryPaginationOff?: boolean;
}

interface Props extends OwnProps {}

interface StateSort {
  field: string;
  direction: StrictTableHeaderCellProps['sorted'];
}

const scrollDefault = (ref: React.RefObject<HTMLElement>) => {
  try {
    // @ts-ignore
    if (window.scrollTo && ref.current && ref.current.getBoundingClientRect) {
      window.scrollTo(0, ref.current.getBoundingClientRect().top + window.scrollY - 80);
    }
  } catch (e) {}
};

function TableAsync(props: Props) {
  const {
    fetcher,
    isFetching,
    filter,
    sortable = false,
    defaultSort = { field: '', direction: 'ascending' },
    data,
    columns,
    pageCount,
    pageSizes,
    defaultPageSize,
    tableProps,
    scroll = true,
    refreshAfterResize,
    isScrollable = false,
    showUpperPagination,
    queryPaginationOff = false,
  } = props;

  // для работы с удалением/добавлением строк в таблицу
  const [changes, setChanges] = useState({ count: 0, refresh: 0, size: data.length });

  let actualRefresh = changes.refresh; // ToDo: Костыль

  useEffect(() => {
    if (refreshAfterResize) {
      if (data.length !== changes.size) {
        const delta = changes.size === 0 ? 0 : 1;
        const count = changes.count + delta;
        const isRefreshTime = count === refreshAfterResize;
        const refresh = isRefreshTime ? changes.refresh + 1 : changes.refresh;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        actualRefresh = refresh;

        setChanges({
          count: count,
          refresh: refresh,
          size: data.length,
        });
      }
    }
  }, [refreshAfterResize, data.length]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }: TableAsyncFetchOptions) => {
      if (refreshAfterResize && changes.count > 0) {
        setChanges({
          // если не равны, значит произошло обновление из-за количетсва удаление/добавлений записей в хранилище
          // следовательно следующий рендер значения size и data.items.length будуте разные и добавится 1, что мы здесь омпенсируем
          // TODO мб бага при количестве новых данных равное пришедшему, т.е. на последней странице
          count: actualRefresh === changes.refresh ? -1 : 0,
          refresh: changes.refresh,
          size: data.length,
        });
      }

      if (!filter || (filter && Object.keys(filter).length === 0)) {
        fetcher && fetcher({ pageIndex, pageSize });
      } else {
        fetcher && fetcher({ pageIndex, pageSize, filter });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshAfterResize, changes.count, changes.refresh, filter, actualRefresh]
  );

  const [sort, setSort] = useState<StateSort>(defaultSort);
  const preparedColumns = useMemo(() => {
    if (!sortable) {
      return columns;
    }

    return columns.map((column) => {
      const originHeaderProps: Nullable<CellHeaderProps> = column.cellHeaderProps;
      const columnId = column.id
        ? column.id
        : typeof column.accessor === 'string'
        ? column.accessor
        : '';
      const setSortFunc = () =>
        setSort({
          field: columnId,
          direction: sort.direction === 'ascending' ? 'descending' : 'ascending',
        });

      if (originHeaderProps) {
        column = {
          ...column,
          cellHeaderProps: (column: ColumnInstance) => {
            const headerCellProps = originHeaderProps(column);

            if (headerCellProps.onClick) {
              const originOnClick = headerCellProps.onClick;
              headerCellProps.onClick = (...args: Array<any>) => {
                setSortFunc();
                // @ts-ignore
                return originOnClick(...args);
              };
            } else {
              headerCellProps.onClick = setSortFunc;
            }

            return {
              ...headerCellProps,
              sorted: sort.field === column.id ? sort.direction : undefined,
            };
          },
        };
      } else {
        column = {
          ...column,
          cellHeaderProps: () => ({
            onClick: setSortFunc,
          }),
        };
      }

      return column;
    });
  }, [columns, sortable, sort, setSort]);

  const scrollFunc = scroll === false ? false : scroll === true ? scrollDefault : scroll;
  return (
    <Table.Async
      columns={preparedColumns}
      data={data}
      fetchData={fetchData}
      filter={filter}
      pageSizes={pageSizes || PAGE_SIZES}
      defaultPageSize={defaultPageSize || DEFAULT_PAGE_SIZE}
      pageCount={pageCount}
      isFetching={isFetching}
      tableProps={tableProps}
      scroll={scrollFunc}
      isScrollable={isScrollable}
      showUpperPagination={showUpperPagination}
      queryPaginationOff={queryPaginationOff}
      selectable
    />
  );
}

export { TableAsync };
export type { TableAsyncFetchOptions };
