import React, { FC, useState } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { CloudArchiveModal } from '../modal';
import { Service } from 'core/constants/contracts';

interface CloudArchivePageHeaderProps {
  cloudArchiveService: Service;
}

export const CloudArchivePageHeader: FC<CloudArchivePageHeaderProps> = ({
  cloudArchiveService,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h1">Подключенные компании</Header>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button onClick={toggleModal}>Подключить видеоархив</Button>
        </Grid.Column>
      </Grid.Row>
      <CloudArchiveModal
        variant="create"
        isOpen={isModalOpen}
        onClose={toggleModal}
        serviceId={cloudArchiveService.id}
      />
    </>
  );
};
