import React from 'react';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { setHistory } from 'semantic-data-grid';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Routes from './routes';
import { initializeApplication } from 'core/store/features/app';
import { GlobalPageLoader } from 'components/share';
import ErrorApp from 'components/share/components/error-app';
import ErrorPage from 'views/pages/errors/page500';
import Providers from './providers';
import { RootState } from 'core/store';

if (window.location.hostname === 'admin.domofon.mts.ru') {
  Sentry.init({
    dsn: 'https://b464805e79a64549bb29972fdb78d9bd@o882483.ingest.sentry.io/5872584',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });
}

const history = createBrowserHistory({
  forceRefresh: false,
});

setHistory(history);

type Props = ConnectedProps<typeof withConnect>;

class App extends React.Component<Props> {
  componentDidMount(): void {
    this.props.initializeApplication();
  }

  render() {
    const { isInitialized, appError } = this.props;

    return (
      <Providers>
        <Sentry.ErrorBoundary fallback={ErrorPage}>
          {isInitialized ? (
            <Routes history={history} />
          ) : (
            <GlobalPageLoader active={!appError} page>
              {appError && <ErrorApp>{appError}</ErrorApp>}
            </GlobalPageLoader>
          )}
        </Sentry.ErrorBoundary>
      </Providers>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isInitialized: state.appx.isInitialized,
    appError: state.appx.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    initializeApplication: bindActionCreators(initializeApplication, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(App);
