import React, { PropsWithChildren, useMemo } from 'react';
import { createClient, Provider } from 'urql';
import tokenStorage from 'core/utils/token';

export default function BuilderClientProvider(props: PropsWithChildren<any>) {
  const client = useMemo(() => {
    return createClient({
      url: `${process.env.REACT_APP_API_ORIGIN}/searcher-graphql/graphql`,
      fetchOptions: () => {
        const token = tokenStorage.getToken();
        return {
          headers: { Authorization: token ? `Bearer ${token}` : '' },
        };
      },
    });
  }, []);

  return <Provider value={client}>{props.children}</Provider>;
}
