import { TagColorsLiteral } from 'components/share';

export interface TableFetchDataParams {
  pageIndex: number;
  pageSize: number;
}

// ---------------------------------------------------------------------------------------------------------------------

export interface Restreamer {
  id: string;
  name: string;
  stream: Stream;
  type: RestreamerType;
}

enum RestreamerType {
  flussonic = 'flussonic',
}

interface Stream {
  protocol: string;
  username: string;
  password: string;
  hostname: string;
  port: number;
  pathname: string;
  search: string;
}

export interface AccessObject {
  id: string;
}

export enum AccessObjectType {
  door = 'door',
  perimeter = 'perimeter',
  group = 'group',
}

export interface ApartmentRaw {
  id: string;
  is_active: boolean;
  role: string;
  users: Array<string>;
  admin: string;
  binding: Binding;
  block_calls: Record<string, UserBlockCalls>;
  devices: Array<string>;
  settings: {
    block_call?: boolean;
  };
  type: ApartmentType;
}

export interface Apartment {
  id: string;
  is_active: boolean;
  role: string;
  users: Array<string>;
  admin: string;
  binding: string;
  bindingObj: Binding;
  building: string;
  block_calls: Record<string, UserBlockCalls>;
  devices: Array<string>;
  settings: {
    block_call?: boolean;
  };
  type: ApartmentType;
  external_id?: string;
  sip?: string;
}

export interface ApartmentType {
  id: string;
  alias: ApartmentTypeAlias;
  name: string;
  is_active: boolean;
  possible_categories: Array<string>;
}

export enum ApartmentTypeAlias {
  company_sales = 'company_sales',
  company_tech = 'company_tech',
  concierge = 'concierge',
}

export interface Address {
  id: string;
  type: string;
  text: string;
}

interface BuildingObject {
  id: string;
  address: Address;
}

type BuildingCompose = BuildingObject | string;

export interface Binding {
  id: string;
  // TODO cast to one field
  type: string;
  /** @deprecated */
  binding: string;
  // TODO
  number: string;
  building: BuildingCompose;
  parent: Nullable<string>;
}

export const isBuildingObject = (building?: BuildingCompose): building is BuildingObject => {
  return typeof building !== 'string';
};

export enum BindingType {
  terrain = 'terrain',
  building = 'building',
  entrancesUnion = 'entrances_union',
  entrance = 'entrance',
  floor = 'floor',
  apartment = 'apartment',
}

export const BindingTypeOrder: ReadonlyArray<BindingType> = [
  BindingType.terrain,
  BindingType.building,
  BindingType.entrancesUnion,
  BindingType.entrance,
  BindingType.floor,
  BindingType.apartment,
];

export enum BuildingCommercialStatus {
  commercial = 'commercial',
  pilot = 'pilot',
  test = 'test',
}

export interface Building {
  id: string;
  created_at: string;
  type: string;
  address: Address;
  is_active: boolean;
  commercial_status?: BuildingCommercialStatus;
  is_test: boolean;
}

export interface Event<T extends object = object> {
  id: string;
  alias: string;
  created_at: string;
  is_mandatory: boolean;
  initiator?: User;
  category: {
    id: string;
    name: string;
    alias: string;
  };
  payload: T;
  meta?: {
    body: string;
    subject: string;
  };
}

export interface EventPayloadBilling {
  initiator: {
    id: string;
    name: string;
  };
  serviceTitle: string;
}

export interface Session {
  sid: string;
  user: string;
  created_at: string;
  expire_at: string;
  is_expired: boolean;
}

export interface User {
  id: string;
  name: string;
  last_name: string;
  first_name: string;
  patronymic: string;
  email: string;
  roles: Array<string>;
  is_online: boolean;
  is_depend: boolean;
  is_active: boolean;
  is_company: boolean;
  is_client: boolean;
  language: any;
  parent: {
    id: string;
    name: string;
  };
  phone: string;
  created_at: string;
  bindings: Array<Binding>;
  buildings: Array<Building>;
  block_call: UserBlockCalls;
  signal_level: string;
  payload: UserPayload;
  version: string;
  comment: string;
  source: string;
  category: {
    id: string;
    alias: string;
  };

  auth?: Array<UserAuth>;
  country_code?: string;
  sip?: string;
}

interface UserBlockCalls {
  external: {
    is_blocked: boolean;
  };
  self: {
    is_blocked: boolean;
  };
}

export interface UserPayload {
  brand?: string;
  manufacturer?: string;
  model?: string;
  serialNumber?: string;
  systemVersion?: string;
  deviceType?: string;
  platform?: string;
  appVersion?: {
    versionName?: string;
    versionCode?: number;
  };
  lastVisit?: string;
}

export interface UserAuth {
  credential: UserAuthCredentialAsterisk;
  method: UserAuthMethod;
}

export enum UserAuthMethod {
  asterisk = 'asterisk',
}

export interface UserAuthCredentialAsterisk {
  domain: string;
  secret: string;
  sip: string;
}

export interface UserCategory {
  id: string;
  title: string;
  alias: string;
  features: Array<UserCategoryFeature>;
  parent: Nullable<string>;
  is_active: boolean;
}

export enum UserCategoryAlias {
  company = 'company',
  company_sales = 'company_sales',
  company_tech = 'company_tech',
  concierge = 'concierge',
  support = 'support',
  security = 'security',
}

export enum UserCategoryFeature {
  linkedTenant = 'linkedTenant',
  singleResidency = 'singleResidency',
}

export interface UserUpdateRequest extends Partial<User> {
  phone_confirm_token_id?: string;
}

export interface CurrentUser {
  id: Nullable<string>;
  user: Nullable<User>;
  isFetching: boolean;
  error: Nullable<string>;

  roles: string[];
  isAdmin: boolean;
  isSupport: boolean;
  isSupportMain: boolean;
  isStaff: boolean;
}

export interface UploadedUser {
  apartment: string;
  comment?: string;
  email?: string;
  first_name: string;
  last_name: string;
  mid_name?: string;
  phone: string;
}

export enum CompanyAdministrativeStatus {
  active = 'active',
  blocked = 'blocked',
  archive = 'archive',
}

export enum CompanyCommercialStatus {
  test = 'test', // "Тестовый клиент"
  pilot = 'pilot', // "Пилотный клиент"
  commercial = 'commercial', // "Коммерческий клиент"
}

export type CommercialStatusLiteral = 'Тестовый' | 'Пилотный' | 'Коммерческий';

export interface Company {
  id: string;
  name: string;
  role: string;
  client_role: string;
  detached_client_role: string;
  created_at: string;
  administrative_status: CompanyAdministrativeStatus;
  commercial_status: CompanyCommercialStatus;
  personal_account?: string;
  person: Record<string, any>;
  person_type: string;
  is_b2c: boolean;
}

export interface CurrentCompany {
  id: string;
  company: Company;
  isFetching: boolean;
  error: Nullable<string>;
}

export interface Device {
  id: string;
  disallowed_commands: Array<string>;
  reference: string;
}

export interface DeviceReference {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
    alias: string;
    parent?: string;
  };
  protocols: Array<DeviceProtocol>;
  bindings_levels: Array<string>;
  alias: string;
  payload?: {
    has_embedded_camera: boolean;
    ring_strategies: Array<string>;
    locks?: number;
  };
  vendor?: {
    id: string;
    name: string;
  };
}

export interface DeviceCategory {
  id: string;
  alias: string;
  name: string;
  parent?: string;
}

export interface DeviceCategoryRaw {
  id: string;
  alias: string;
  name: string;
  parent?: {
    id: string;
    name: string;
  };
}

export interface DeviceProtocol {
  id: string;
  alias: string;
  name: string;
  description: string;
  version: string;
  commands: Array<DeviceCommand>;
}

export interface DeviceCommand {
  id: string;
  alias: DeviceCommandType;
  name: string;
  can_customize: boolean;
}

export enum DeviceCommandType {
  available = 'available',
  'set-sip-config' = 'set-sip-config',
  linkSip = 'linkSip',
  unlinkSip = 'unlinkSip',
  'set-user-password' = 'set-user-password',
  reboot = 'reboot',
  unlock = 'unlock',
  'add-rfid' = 'add-rfid',
  'remove-rfid' = 'remove-rfid',
  'set-intercom-config' = 'set-intercom-config',
  'video-from-camera' = 'video-from-camera',
  'block-call-apartment' = 'block-call-apartment',
  'apartment-info' = 'apartment-info',
  'set-sip-peer-tenant' = 'set-sip-peer-tenant',
}

export interface IntercomConfig {
  domain: string;
  secret: string;
  sip: string;
  control?: {
    host?: string;
    login?: string;
    password?: string;
    port?: number;
    schema?: string;
  };
}

export interface Intercom {
  id: string;
  phone: string;
  name: string;
  status: 'created' | 'onsale' | 'active' | 'inactive' | 'defective' | 'pending';
  lock_status: 'unlocked' | 'unlocking' | 'locking' | 'locked';
  settings: {
    alwaysOpen?: boolean;
    showVideoPlayer: boolean;
  };
  config: IntercomConfig;
  /** Встроенная камера  */
  camera?: Camera;
  /** Камеры только для вызова  */
  call_cameras: string[];
  has_bindings: boolean;
  device: {
    id: string;
    bindings: Array<Binding>;
    building?: Building;
    reference: string;
    possible_commands: Array<DeviceCommandType>;
    owner?: IntercomOwner;
    additional_protocol_config?: {
      'set-sip-config': {
        capacity?: number;
        shift1?: number;
        range1?: string;
        shift2?: number;
        range2?: string;
      };
    };
  };
  is_busy: boolean;
  is_online: boolean;
  is_maintenance: boolean;
  can_unlock: boolean;
  control_url?: string;
  monitoring?: Record<string, boolean>;
  actual_owner?: 'mts' | 'client' | 'leased_by_mts';
  internet_provider?: 'mts' | 'other';
  regional_billing_account?: string;
  serial_number?: string;
  ring_strategy: 'default' | 'apartment' | 'master' | undefined;
  locks: IntercomLocks;
}

export enum IntercomLocksActions {
  /**
   * временно открыть замок
   */
  OPEN = 'open',
  /**
   * открыть/выключить замок на длительный/постоянный режим(зависит от модели оборудования)
   */
  OFF = 'off',
  /**
   * перевести замок в обычный режим
   */
  ON = 'on',
}

export interface IntercomLocks {
  /**
   * 0 - открытие дверей по-отдельности |
   * 1 - открытие всех дверей по одному запросу
   */
  opening_mode: 0 | 1;
  items: IntercomLocksItem[];
}

export interface IntercomLocksItem {
  number: number;
  name: string;
  is_active: boolean;
  is_permanently_open: boolean;
}

export interface IntercomOwner {
  id: string;
  name: string;
  role: string;
}

export interface StreamSettings {
  protocol: string;
  hostname?: string;
  port?: number;
  path?: string;
  username?: string;
  password?: string;
}

type StreamTech = string;
type StreamURI = string;

export enum CameraStreamMode {
  camera = 'camera',
  restreamer = 'restreamer',
}

export interface AuthCredential {
  login: string;
  password: string;
}

export interface RtspSettings {
  protocol: string;
  port: number;
  stream: string;
  auth: AuthCredential;
  host: string;
  params: string;
  url?: string;
}

export interface CameraSettings {
  rtsp: RtspSettings;
}

export interface Camera {
  id: string;
  parent: string;
  name?: string;
  rtsp: string;
  server: null;
  settings: CameraSettings;
  stream?: null | string | StreamSettings;
  streams?: Record<StreamTech, StreamURI>;
  features?: Record<string, boolean>;
  /** @deprecated  */
  is_builtin: boolean;
  stream_mode: CameraStreamMode;
  restreamer: null | string;
  video_recorder: null | string;
  device?: {
    id: string;
    bindings: Array<Binding>;
    building?: Building;
    sort_order?: number;
    timezone: string;
    owner?: IntercomOwner;
  };
}

export interface VideoRecorder {
  id: string;
  name?: string;
  shared_info?: {
    id: string;
    channels: Array<string>;
  };
  config?: {
    _id: string;
    api: {
      _id: string;
      protocol: string;
      hostname: string;
      port: number;
      rtsp_port?: number;
      username: string;
      password: string;
    };
  };
  max_duration?: number;
  device: {
    id: string;
    bindings: Array<Binding>;
    building?: Building;
    sort_order?: number;
    timezone: string;
    owner: IntercomOwner;
    reference: string;
  };
}

export interface IntercomAvailableResponse {
  code: 'success' | 'failed';
}

export interface AbstractService {
  id: string;
  initiator: string | null;

  is_active: boolean;
  is_blocked: boolean;
  is_expired: boolean;
  is_deleted: boolean;

  is_processing: boolean;
  processing_action: ProcessingAction | null;

  created_at: string;
  started_at: string;
  finished_at: string;

  provider: {
    id: string;
    type: string;
    name?: string;
  };
  object: {
    id: string;
    type: string;
  };
  history: Array<ServiceHistory>;
}

export interface UserService extends AbstractService {
  user_id: string;
  account_id?: string;
  service: string;
  owner: Owner;
}

export interface CompanyService extends AbstractService {
  owner: {
    id: string;
    type: string;
  };
  payload: {
    max_channels: number;
    used_channels?: number;
  };
  service: Service;
}

export enum ProcessingAction {
  block = 'block',
  unblock = 'unblock',
  start = 'start',
  'delete' = 'delete',
  error = 'error',
}

export interface ServiceHistory {
  date: string;
  new_status: ServiceStatus;
  old_status: ServiceStatus | null;
  reason: string | null;
}

export enum ServiceStatus {
  created = 'created',
  started = 'started',
  finished = 'finished',
  blocked = 'blocked',
  deleted = 'deleted',
  failed = 'failed',
}

export type UserServiceRaw = Omit<UserService, 'service'> & {
  service: Service;
};

export interface Service {
  id: string;
  name: string;
  description: string;
  alias: ServiceAlias;
}

export enum ServiceAlias {
  intercom = 'intercom',
  'intercom-base' = 'intercom-base',
  'intercom-video-archive' = 'intercom-video-archive',
  'company-archive-channel' = 'company-archive-channel',
}

export interface ServiceReason {
  id: string;
  action: 'block' | 'delete';
  name: string;
  alias: string;
  is_active: boolean;
  is_start: boolean;
  is_final: boolean;
  can_manually_from: boolean;
  can_manually_to: boolean;
}

export interface Notification {
  id: string;
  title?: string;
  message: string;
}

export interface Token {
  id: string;
  type: TokenType;
  transport: TokenTransport;
  target: string;
  expire_at: string;
  created_at: string;
  is_verified: boolean;
  is_expired: boolean;

  code?: string;
}

export enum TokenType {
  auth = 'auth',
  register = 'register',
  verify = 'verify',
  resetPassword = 'resetPassword',
  verifyPhone = 'verifyPhone',
  verifyEmail = 'verifyEmail',
}

export enum TokenTransport {
  email = 'email',
  sms = 'sms',
  push = 'push',
  ws = 'ws',
}

export interface ReportColumn {}

export interface ReportFilter<T = any> {
  id: string;
  title: string;
  type: 'Fias' | 'Date' | 'Dropdown' | 'Title';
  isRequired?: boolean;
  arguments: {
    value?: T;
    options?: Array<{ text: string; value: string | number }>;
    [key: string]: any;
  };
}

export interface Report {
  id: string;
  alias: string;
  name: string;
  columns: Array<ReportColumn>;
  filters: Array<ReportFilter>;
}

export interface Workflow<P = any> {
  id: string;
  workflow: string;
  created_at: string;
  reason: string;
  role: string;
  status: string;
  possible_statuses: Array<string>;
  payload: P;
  message?: string;
  finished_at?: string;
}

export interface WorkflowReason {
  id: string;
  workflow: string;
  alias: string;
  name: string;
}

export interface WorkflowStatus<T = string> {
  id: string;
  workflow: string;
  alias: T;
  name: string;
}

export interface ClientConnectionRequest {
  id: string; // код плательщика
  first_name: string;
  last_name: string;
  patronymic: string;
  phone: string;
  token: string;
  email: string;
  acquired_at?: string;
}

export enum ClientConnectionRequestStatus {
  created = 'created',
  acquired = 'acquired',
  accepted = 'accepted',
  declined = 'declined',
}

export type StreamInfo = StreamInfoSuccess | StreamInfoError;

export interface StreamInfoSuccess {
  streams: Array<StreamMedia>;
  format: StreamFormat;
}
export interface StreamInfoError {
  error: StreamErrorMessage;
}

interface StreamErrorMessage {
  code: string;
  title: string;
  detail?: string;
}

interface StreamMedia {
  index: number;
  title?: string;
  codec_name: string;
  codec_type: 'video' | 'audio';
  avg_frame_rate: string;
}

interface StreamFormat {
  filename: string;
  format_name: string;
  score: number;
}

export type DeviceType = 'intercom' | 'barrier' | 'camera';

export interface Entrance {
  id: string;
  number_service_application: string[];
  binding: string;
  created_at: string;
  updated_at?: string;
}

export interface Owner {
  id: string;
  type: 'apartment';
}

export type OwnerStatusType = {
  name: CommercialStatusLiteral;
  color: TagColorsLiteral;
};

export type IntercomCommand = {
  id: string;
  initiator_id: string;
  device_id: string;
  command: DeviceCommandType;
  command_payload: object;
  retry_policy: {
    max_tries: number;
    sip_status_max_tries: number;
    tryable: boolean;
  };
  is_successful: boolean;
  created_at: Date;
  attempts: Attempt[];
  protocols: ProtocolInfo[];
  vendor_name: string;
};

export type Attempt = {
  message: string;
  result_code: string;
  result_raw_log: any;
  created_at: Date;
  finished_at: Date;
  error_trace: any;
};

export type ProtocolInfo = {
  protocol_alias: string;
  protocol_version: string;
};

export type WebProxyAccess = {
  id: string;
  initiator: string;
  device: string;
  token: string;
  request_id: string;
  created_at: number;
};

export type MetaData = {
  page: number;
  pageCount: number;
  perPage: number;
  total: number;
};

export type ErrorData = {
  message: string;
  code: number;
  data: Record<string, any>;
};

export interface ResponseList<T> {
  list: Array<T>;
  error: Nullable<ErrorData>;
  metadata: MetaData;
}
