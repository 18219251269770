import React, { FC, useState } from 'react';
import { Intercom } from 'core/constants/contracts';
import { CommandsContainer } from './styles';
import { SettingsRestore } from 'components/share/assets';
import { DeviceCommands } from '../../../components/device-commands';
import { Card, IconButton } from 'components/share';
import { confirm } from 'components/share/dialogs';
import { request } from 'core/utils/request';
import api from 'core/constants/api';
import notification from 'core/utils/notification';

interface Props {
  intercom: Intercom;
  isDisabled: boolean;
}

export const Commands: FC<Props> = ({ intercom, isDisabled }) => {
  const { id: intercomId } = intercom;
  const [isLoadingReconfiguration, setIsLoadingReconfiguration] = useState(false);

  const loadConfiguration = () => {
    confirm({
      content: 'Вы уверены, что хотите загрузить конфигурацию домофона?',
      onConfirm: () => {
        setIsLoadingReconfiguration(true);
        request
          .post(
            api.emergencyIntercomReconfiguration(intercomId),
            null,
            { timeout: 60000 } // ToDo: Убрать, при переходе на асинхронный вид операции
          )
          .then(() => {
            notification.success('Загрузка конфигурации запущена');
          })
          .catch((err) => {
            console.error(err);
            notification.error('При загрузке конфигурации произошла ошибка');
          })
          .finally(() => {
            setIsLoadingReconfiguration(false);
          });
      },
    });
  };

  return (
    <Card
      title="Команды"
      actions={
        <IconButton
          secondary
          onClick={loadConfiguration}
          disabled={isLoadingReconfiguration || isDisabled}
          loading={isLoadingReconfiguration}
          icon={<SettingsRestore />}
        >
          Загрузить конфигурацию
        </IconButton>
      }
    >
      <CommandsContainer>
        <DeviceCommands intercom={intercom} chunkSize={3} isDisabled={isDisabled} />
      </CommandsContainer>
    </Card>
  );
};
