import React, { useCallback, useState } from 'react';
import { Button, Grid, Message } from 'semantic-ui-react';
import { request } from 'core/utils/request';
import api from 'core/constants/api';
import { dataFromAxios, errorFromAxios } from 'core/utils/webapi.handlers';
import notification from 'core/utils/notification';

interface Props {
  userId: string;
  onSuccess?: Function;
  onFailed?: Function;
}

export function SipCreate(props: Props) {
  const [isPending, setPending] = useState(false);
  const handleCreate = useCallback(() => {
    setPending(true);

    request
      .post(api.emergencyUserCreateSip(props.userId))
      .then((result) => {
        const data = dataFromAxios<string>(result);
        notification.success();

        if (props.onSuccess) {
          props.onSuccess(data);
        }
      })
      .catch((err) => {
        const message = errorFromAxios(err);
        notification.error(message);

        if (props.onFailed) {
          props.onFailed();
        }
      })
      .finally(() => setPending(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId]);

  return (
    <Grid>
      <Grid.Row columns={'equal'}>
        <Grid.Column>
          <Message warning>
            <Message.Header>Информация отсутствует</Message.Header>
            <p>Отсутствует информация о регистрации клиента на SIP сервере</p>
          </Message>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={'equal'}>
        <Grid.Column>
          <Button
            loading={isPending}
            disabled={isPending}
            primary
            content={'Зарегистрировать SIP'}
            onClick={handleCreate}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
