import {
  configureStore,
  EnhancedStore,
  getDefaultMiddleware,
  StateFromReducersMapObject,
  PreloadedState,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import { ReducersMapObject, StoreEnhancer } from 'redux';
import stopwatchEnhancer from './enhancers/stopwatch';
import { ManagedRootState, ReducerManager } from './manager';
import { ENV_PROD } from '../constants/app';
import { reducers } from './reducers';
import { Report, User } from '../constants/contracts';
import { WebAPIResponse } from '../utils/webapi.contracts';

export interface LegacyRootState {
  app: {
    users: {
      items: User[];
      isFetching: boolean;
    };
    reports: {
      report: Report;
      build: object;
      submitting: boolean;
      building: boolean;
    };
  };
}

export type RootState = LegacyRootState &
  ManagedRootState &
  StateFromReducersMapObject<typeof reducers>;

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action<string>>;
export type VoidThunk = ThunkResult<void>;
export type PromiseThunk<T = any> = ThunkResult<Promise<T>>;
export type ApiThunk<T> = PromiseThunk<WebAPIResponse<T>>;

export type ManagedStore = EnhancedStore & { manager: ReducerManager };

export interface Options {
  additionalReducers?: ReducersMapObject;
  preloadedState?: PreloadedState<RootState>;
}

export default function createStore(options: Options = {}): ManagedStore {
  const isProd = process.env.NODE_ENV === ENV_PROD;
  const isDev = !isProd;

  const enhancers: Array<StoreEnhancer> = [];
  if (isDev) {
    process.env.REACT_APP_REDUX_PERFORMANCE &&
      !!+process.env.REACT_APP_REDUX_PERFORMANCE &&
      enhancers.push(stopwatchEnhancer);
  }

  const reducerManager = new ReducerManager({ ...reducers, ...options.additionalReducers });

  const store = configureStore({
    reducer: reducerManager.reduce.bind(reducerManager),
    devTools: isDev,
    middleware: getDefaultMiddleware({
      thunk: true,
      immutableCheck: false, //isDev,
      serializableCheck: false, //isDev,
    }),
    preloadedState: options.preloadedState,
    enhancers: enhancers,
  });

  reducerManager.setDispatch(store.dispatch);

  // @ts-ignore
  return { ...store, manager: reducerManager };
}
