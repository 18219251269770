import React, { ComponentType, PropsWithChildren } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { reduxForm } from 'redux-form';
import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { ButtonProps } from 'semantic-ui-react';
import { ConfigProps, InjectedFormProps } from 'redux-form/lib/reduxForm';
import ModalWindow, { ModalWindowProps } from './modal';

interface OwnProps extends Omit<ModalWindowProps, 'actions'> {
  // header?: ReactNode;
  // onClose?: () => void;
  onSubmit?: (values: Record<string, any>, dispatch: Dispatch, props: any) => void;
  cancelButton?: ButtonProps | false;
  confirmButton?: ButtonProps | false;
}

interface Props extends PropsWithChildren<OwnProps>, InjectedFormProps {}

export class FormDialog extends React.Component<Props> {
  private close?: Function;

  static defaultProps: Partial<OwnProps> = {
    header: 'Подтверждение операции',
    closeIcon: false,
  };

  setClose = (close: Function) => {
    this.close = close;
  };

  handleClose = () => {
    this.close && this.close();
    // this.props.onClose && this.props.onClose();
  };

  onSubmit = (values: object, dispatch: Dispatch, props: any) => {
    const result = this.props.onSubmit ? this.props.onSubmit(values, dispatch, props) : undefined;

    return Promise.resolve(result).then(() => this.handleClose());
  };

  renderActions = () => {
    const actions = [];

    if (false !== this.props.cancelButton) {
      actions.push(
        <Button
          secondary
          onClick={this.handleClose}
          content={'Отменить'}
          {...this.props.cancelButton}
        />
      );
    }
    if (false !== this.props.confirmButton) {
      actions.push(
        <Button
          primary
          type={'submit'}
          form={this.props.form}
          content={'Подтвердить'}
          {...this.props.confirmButton}
        />
      );
    }

    return actions;
  };

  render() {
    const {
      children,
      handleSubmit,
      invalid,
      form,

      size,
      isLoading,
      closeOnClick,
      closeIcon,
      open,
      header,
      onClose,
    } = this.props;

    return (
      <ModalWindow
        size={size}
        isLoading={isLoading}
        closeOnClick={closeOnClick}
        closeIcon={closeIcon}
        open={open}
        header={header}
        onClose={onClose}
        actions={this.renderActions()}
        setClose={this.setClose}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form id={form} error={invalid} onSubmit={handleSubmit(this.onSubmit)}>
                {children}
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ModalWindow>
    );
  }
}

export const MODAL_FORM_ID = 'confirm-form-dialog';

export function createModalForm(
  props: Nullable<PropsWithChildren<OwnProps>>,
  formProps?: Partial<ConfigProps>
): ComponentType<OwnProps> {
  function Wrapper(injectedProps: InjectedFormProps) {
    return <FormDialog {...props} {...injectedProps} />;
  }

  const component = reduxForm({ form: MODAL_FORM_ID, ...formProps })(Wrapper);

  return connect()(component) as any;
}

export interface ModalFormProps extends OwnProps {}

const form = reduxForm({ form: MODAL_FORM_ID })(FormDialog as any);
export default connect()(form) as ComponentType<OwnProps>;
