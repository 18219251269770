import React, { useCallback } from 'react';
import {
  Dropdown as SemanticDropdown,
  StrictDropdownProps as SemanticDropdownProps,
} from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import { setFieldValue } from '../helpers';
import { BaseFormikFieldProps } from '../props';
import { FormFieldWrapper, FormFieldWrapperProps, useGetComponentProps } from '../field';

interface OwnProps extends FormFieldWrapperProps, Omit<SemanticDropdownProps, 'onChange'> {
  onChange?: (
    event: React.SyntheticEvent<HTMLElement>,
    data: SemanticDropdownProps,
    props: Props
  ) => void;
}

interface Props extends OwnProps, FieldProps {}

function DropdownControl(props: Props) {
  const {
    onChange: onChangeOrigin,
    selection = true,

    field,
    form,
    meta,

    ...rest
  } = useGetComponentProps(props);

  const handleChange = useCallback(
    (e, data: SemanticDropdownProps) => {
      setFieldValue(form, field.name, data.value, true);
      onChangeOrigin?.(e, data, props);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [form, field.name, onChangeOrigin, props]
  );

  return (
    <FormFieldWrapper {...props}>
      <SemanticDropdown
        {...rest}
        selection={selection}
        onChange={handleChange}
        onBlur={field.onBlur}
        value={field.value}
        disabled={props.disabled}
      />
    </FormFieldWrapper>
  );
}

export interface DropdownProps
  extends BaseFormikFieldProps<SemanticDropdownProps['value']>,
    OwnProps {}

export function Dropdown({ placeholder, ...props }: DropdownProps) {
  let newPlaceholder = placeholder;
  if (props.label && !newPlaceholder) {
    newPlaceholder = props.label.toString();
  }
  return <Field component={DropdownControl} placeholder={newPlaceholder} {...props} />;
}
