import React, { FC } from 'react';
import { LabelStatus } from 'components/share/components/status/label';

interface Props {
  message: string;
  color: string;
}

export const AdminStatus: FC<Props> = ({ message, color }) => {
  return (
    <LabelStatus color={color} light>
      {message}
    </LabelStatus>
  );
};
