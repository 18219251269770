import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import TextInput from 'components/share/redux-form/text-input';
import { InputMaskPhone } from 'components/share/redux-form';
import { validateBySchema } from 'core/utils/validate';
import { schemaUserFactory } from './schema';

const validate = (values) => {
  // TODO баг валидатора? с matches нужно каждый раз передавать новый инстанс
  return validateBySchema(schemaUserFactory(), values);
};

function CreateUserForm(props) {
  const { handleSubmit, invalid, submitting } = props;

  return (
    <Form error={invalid}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Form.Group widths={3}>
              <Field name={'name'} component={TextInput} label={'ФИО'} required />
              <Field
                name={'email'}
                component={TextInput}
                label={'E-mail'}
                type={'email'}
                required
              />
              <Field name={'position'} component={TextInput} label={'Должность'} />
            </Form.Group>
            <Form.Group widths={3}>
              <Field name={'phone'} component={InputMaskPhone} label={'Номер телефона'} required />
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={'row-button'} columns={3}>
          <Grid.Column floated={'right'}>
            <Button
              primary
              floated={'right'}
              disabled={submitting}
              onClick={handleSubmit(props.onSubmit)}
              content={'Создать'}
              className={'width-medium'}
            />
            <Button
              secondary
              floated={'left'}
              disabled={submitting}
              onClick={props.onCancel}
              content={'Назад'}
              className={'width-medium'}
              type={'button'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}

CreateUserForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export const FORM_ID = 'create_company_user';
const form = reduxForm({ form: FORM_ID, validate })(CreateUserForm);

export default connect()(form);
