import { createSelector } from 'reselect';
import { GenericSelector } from '.';
import { RootState } from '..';
import { Company } from '../../constants/contracts';

export const getCompanies = (state: RootState) => state.entities.companies;
export const getCompaniesItems = (state: RootState) => state.entities.companies.items;
export const getCompaniesIds = (state: RootState) => state.entities.companies.ids;

export const selectCompaniesAsArray = createSelector([getCompaniesItems], (bindings) =>
  Object.values(bindings)
);

export const createSelectCompaniesAsArray = (): GenericSelector<Array<Company>> =>
  createSelector([getCompaniesItems], (bindings) => Object.values(bindings));
