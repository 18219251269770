import { createSelector } from 'reselect';
import { Entrance } from '../../constants/contracts';
import { GenericSelector } from '.';
import { RootState } from '..';

export const getEntrances = (state: RootState) => state.entities.entrances;
export const getEntrancesItems = (state: RootState) => state.entities.entrances.items;
export const getEntrancesIds = (state: RootState) => state.entities.entrances.ids;

export const selectEntrancesAsArray = createSelector([getEntrancesItems], (entrances) =>
  Object.values(entrances)
);

export const createSelectBindingsAsArray = (): GenericSelector<Array<Entrance>> =>
  createSelector([getEntrancesItems], (entrances) => Object.values(entrances));
