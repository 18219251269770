import React, { FC } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { FilterValues } from '../container';
import { Chip } from 'components/share';
import styled from 'styled-components';
import { actualOwnerMap, internetProviderMap } from 'views/pages/devices/pages/intercoms/config';

interface FilterChipProps {
  filter: FilterValues | undefined;
  filterItem: keyof FilterValues;
  companiesOptions: DropdownItemProps[];
  onClose: () => void;
}

type ChipType = {
  [key in keyof FilterValues]: string;
};

const chipTitle: ChipType = {
  sip: 'SIP-номер',
  name: 'Название',
  owner_id: 'Владелец',
  address: 'Адрес',
  actual_owner: 'Принадлежность панели',
  internet_provider: 'Принадлежность интернета',
  regional_billing_account: 'ЛС регионального биллинга',
  serial_number: 'Серийный № панели',
};

const BoldText = styled.span`
  font-weight: 500;
`;

export const FilterChip: FC<FilterChipProps> = ({
  filter,
  filterItem,
  companiesOptions,
  onClose,
}) => {
  if (filter && filter[filterItem]) {
    let value: any;

    switch (filterItem) {
      case 'owner_id':
        value = companiesOptions.find((company) => company.value === filter.owner_id)?.text;
        break;
      case 'actual_owner':
        value = actualOwnerMap[filter.actual_owner || 'mts'];
        break;

      case 'internet_provider':
        value = internetProviderMap[filter.internet_provider || 'mts'];
        break;
      default:
        value = filter[filterItem];
    }

    return (
      <Chip variant="white" onClose={onClose} key={filterItem}>
        <BoldText>{chipTitle[filterItem]}: </BoldText>
        {value}
      </Chip>
    );
  }

  return null;
};
