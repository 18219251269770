import {
  COMPANIES_FAIL,
  COMPANIES_REQUEST,
  COMPANIES_SUCCESS,
  COMPANY_FAIL,
  COMPANY_REQUEST,
  COMPANY_SAVE_FAIL,
  COMPANY_SAVE_REQUEST,
  COMPANY_SAVE_SUCCESS,
  COMPANY_SUCCESS,
  CURRENT_COMPANY_FAIL,
  CURRENT_COMPANY_REQUEST,
  CURRENT_COMPANY_SUCCESS,
} from '../../constants/actions';
import {
  formatErrorMessage,
  prepareError,
  prepareResponse,
  request,
  singleResult,
  sortToString,
} from '../../utils/request';
import { filterObject } from '../../utils/utils';
import api from '../../constants/api';

export const fetchCompanies = ({ limit = 30, page = 0, sort, filter = {}, ...rest } = {}) => {
  return async (dispatch) => {
    dispatch({ type: COMPANIES_REQUEST });

    let query = {
      limit,
      page,
      sort: sort && sortToString(sort),
      ...filterObject(filter, (val) => val !== ''),
      ...rest,
    };

    try {
      let response = await request.get(api.companies(), { params: query });

      dispatch({
        type: COMPANIES_SUCCESS,
        payload: {
          items: response.data.list || [],
          metadata: response.data.metadata,
        },
      });

      return response.data.list;
    } catch (e) {
      dispatch({
        type: COMPANIES_FAIL,
        payload: {
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};

const saveCompanyRequest = () => {
  return { type: COMPANY_SAVE_REQUEST };
};

const saveCompanySuccess = (res) => {
  return {
    type: COMPANY_SAVE_SUCCESS,
    payload: {
      id: res.id,
      item: res,
    },
  };
};

const saveCompanyFailure = (e) => {
  return {
    type: COMPANY_SAVE_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
};

export const saveCompany = (data) => {
  return (dispatch) => {
    dispatch(saveCompanyRequest());

    const method = data.id ? request.put : request.post;

    const result = method(api.companies(data.id), data)
      .catch(prepareError)
      .then(prepareResponse)
      .then(singleResult);

    result
      .then((r) => dispatch(saveCompanySuccess(r)))
      .catch((e) => dispatch(saveCompanyFailure(e)));

    return result;
  };
};

const fetchCompanyRequest = (id) => {
  return {
    type: COMPANY_REQUEST,
    payload: {
      id,
    },
  };
};

const fetchCompanySuccess = (res) => {
  return {
    type: COMPANY_SUCCESS,
    payload: {
      id: res.id,
      item: res,
    },
  };
};

const fetchCompanyFailure = (e, id) => {
  return {
    type: COMPANY_FAIL,
    payload: {
      id,
      error: formatErrorMessage(e),
    },
  };
};

export const fetchCompany = (id) => {
  return (dispatch) => {
    dispatch(fetchCompanyRequest(id));

    const result = request
      .get(api.companies(id))
      .catch(prepareError)
      .then(prepareResponse)
      .then(singleResult);

    result
      .then((r) => dispatch(fetchCompanySuccess(r)))
      .catch((e) => dispatch(fetchCompanyFailure(e, id)));

    return result;
  };
};

export const getCurrentCompany = () => {
  return async (dispatch) => {
    dispatch({ type: CURRENT_COMPANY_REQUEST });

    try {
      let response = await request.get(api.companies());

      dispatch({
        type: CURRENT_COMPANY_SUCCESS,
        payload: {
          company: response.data.list[0] || {},
          metadata: response.data.metadata,
        },
      });

      return response.data.list[0];
    } catch (e) {
      dispatch({
        type: CURRENT_COMPANY_FAIL,
        payload: {
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};
