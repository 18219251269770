import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';

const Line = styled.hr`
  &.wide {
    margin-left: -48px;
    margin-right: -48px;
  }
`;

interface Props {
  wide?: boolean;
}

function CardDelimiter(props: Props) {
  return <Line className={cx({ wide: props.wide })} />;
}

export { CardDelimiter };
