import { createSelector } from 'reselect';
import { uniqBy } from 'lodash';
import { GenericSelector } from '.';
import { RootState } from '..';
import { DeviceCategory } from '../../constants/contracts';

export const getDeviceCategories = (state: RootState) => state.entities.devices_categories;
export const getDeviceCategoriesItems = (state: RootState) =>
  state.entities.devices_categories.items;
export const getDeviceCategoriesIds = (state: RootState) => state.entities.devices_categories.ids;

function findChildren(parent: string, items: Array<DeviceCategory>) {
  const result: Array<DeviceCategory> = [];

  function find(parent: string) {
    const children = items.filter((v) => v.parent === parent);
    result.push(...children);
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      find(child.id);
    }
  }

  find(parent);

  return result;
}

export const createSelectDeviceCategoriesByAlias = (
  aliases: Array<string>,
  withChildren = true
): GenericSelector<Array<DeviceCategory>> =>
  createSelector([getDeviceCategoriesItems], (items) => {
    const result = [];
    const deviceCategories = Object.values(items);

    for (let i = 0; i < aliases.length; i++) {
      const alias = aliases[i];
      const deviceCategory = deviceCategories.find((v) => v.alias === alias);

      if (deviceCategory) {
        result.push(deviceCategory);

        if (withChildren) {
          result.push(...findChildren(deviceCategory.id, deviceCategories));
        }
      }
    }

    return uniqBy(result, (v) => v.id);
  });

export const createSelectDeviceCategoriesFirstLevel = (): GenericSelector<Array<DeviceCategory>> =>
  createSelector([getDeviceCategoriesItems], (items) => {
    return Object.values(items).filter((v) => !v.parent);
  });
