import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { change, Field, fieldPropTypes, untouch } from 'redux-form';
import { Form } from 'semantic-ui-react';
import Dropdown from '../dropdown';
import { Checkbox } from '../index';

const BINDING_TYPE_ENTRANCES_UNION = 'entrances_union';
const BINDING_TYPE_ENTRANCE = 'entrance';
const BINDING_TYPE_FLOR = 'floor';
const BINDING_TYPE_APARTMENT = 'apartment';

function findBindingParentByType(binding, parentType, bindings) {
  if (!binding.parent) {
    return null;
  }

  const parent = bindings.find((v) => v.id === binding.parent);

  return parent.type === parentType
    ? parent
    : findBindingParentByType(parent, parentType, bindings);
}

export default class AddressList extends Component {
  state = {
    isFetching: false,
    buildingId: null,

    entranceOptions: [],
    floorOptions: [],
    apartmentOptions: [],

    entrancesUnion: null,
  };

  constructor(props) {
    super(props);

    const {
      input: { name },
    } = this.props;

    this.onBuildingChangeFields = [`${name}.apartment`, `${name}.floor`, `${name}.entrance`];
    this.onEntranceChangeFields = [`${name}.apartment`, `${name}.floor`];
  }

  componentDidMount() {
    this.componentDidHandler();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.componentDidHandler();
  }

  componentDidHandler = () => {
    const buildingId = get(this.props.input.value, 'building');

    if (
      this.state.entranceOptions.length === 0 &&
      this.state.apartmentOptions.length === 0 &&
      buildingId &&
      buildingId !== this.state.buildingId
    ) {
      this.updateOptions(buildingId);
    }
  };

  static calcOptionsState(bindings = []) {
    const entranceOptions = [];
    const floorOptions = [];
    const apartmentOptions = [];

    const entrancesUnions = bindings.filter((v) => v.type === BINDING_TYPE_ENTRANCES_UNION);
    const entrances = bindings.filter((v) => v.type === BINDING_TYPE_ENTRANCE);
    for (let i = 0; i < entrances.length; i++) {
      const entrance = entrances[i];
      const entrancesUnion = findBindingParentByType(
        entrance,
        BINDING_TYPE_ENTRANCES_UNION,
        entrancesUnions
      );

      entranceOptions.push({
        key: entrance.id,
        text: entrance.number,
        value: entrance.id,
        origin: entrance,
        entrancesUnion,
      });
    }

    const floors = bindings.filter((v) => v.type === BINDING_TYPE_FLOR);
    for (let i = 0; i < floors.length; i++) {
      const floor = floors[i];
      const entrance = findBindingParentByType(floor, BINDING_TYPE_ENTRANCE, bindings);

      floorOptions.push({
        key: floor.id,
        text: floor.number,
        value: floor.id,
        origin: floor,
        entrance,
      });
    }

    const apartments = bindings
      .filter((v) => v.type === BINDING_TYPE_APARTMENT)
      .sort((a, b) => parseInt(a.number) - parseInt(b.number));
    for (let i = 0; i < apartments.length; i++) {
      const apartment = apartments[i];
      const floor = findBindingParentByType(apartment, BINDING_TYPE_FLOR, bindings);

      apartmentOptions.push({
        key: apartment.id,
        text: apartment.number,
        value: apartment.id,
        origin: apartment,
        floor,
      });
    }

    return {
      entranceOptions,
      floorOptions,
      apartmentOptions,
    };
  }

  /**
   *
   * @param {string} buildingId
   */
  updateOptions = (buildingId) => {
    this.setState({ isFetching: true, buildingId });

    this.props
      .fetchBindings(buildingId)
      .then((result) => result.list)
      .catch((err) => {
        console.error(err);
        return [];
      })
      .then((bindings) => this.setState(AddressList.calcOptionsState(bindings)))
      .finally(() => this.setState({ isFetching: false }));
  };

  handleChangeBuilding = (e, value) => {
    if (this.props.input.building !== value) {
      this.resetFields(this.onBuildingChangeFields);
      this.updateOptions(value);

      if (this.props.onChangeAddress) {
        const building = this.props.buildings.find((v) => v.id === value);
        this.props.onChangeAddress('building', value, building);
      }
    }
  };

  handleChangeEntrance = (e, value) => {
    if (this.props.input.entrance === value) {
      return;
    }

    const entrance = this.state.entranceOptions.find((v) => v.value === value);
    const entrancesUnion = get(entrance, 'entrancesUnion');
    this.changeField(`entrances_union`, get(entrancesUnion, 'id'), entrancesUnion);

    if (entrance && entrance.entrancesUnion) {
      this.setState({ entrancesUnion: entrance.entrancesUnion });
    } else {
      this.setState({ entrancesUnion: null });
    }

    const floor = this.state.floorOptions.find((v) => v.entrance.id === value);
    this.changeField(`floor`, get(floor, 'value'), get(floor, 'origin'));

    if (floor) {
      const apartment = this.state.apartmentOptions.find((v) => v.floor.id === floor.value);
      this.changeField(`apartment`, get(apartment, 'value'), get(apartment, 'origin'));
    }

    if (this.props.onChangeAddress) {
      const entrance = this.state.entranceOptions.find((v) => v.value === value);
      this.props.onChangeAddress('entrance', value, get(entrance, 'origin'));
    }
  };

  handleChangeFloor = (e, value) => {
    if (this.props.input.floor === value) {
      return;
    }

    const floor = this.state.floorOptions.find((v) => v.value === value);

    const entrance = get(floor, 'entrance');
    this.changeField(`entrance`, get(entrance, 'id'), entrance);

    if (entrance) {
      const entrancesUnion = this.state.entranceOptions.find(
        (v) => v.value === entrance.id
      ).entrancesUnion;
      this.changeField(`entrances_union`, get(entrancesUnion, 'id'), entrancesUnion);
    }

    const apartment = this.state.apartmentOptions.find((v) => v.floor.id === value);
    this.changeField(`apartment`, get(apartment, 'value'), get(apartment, 'origin'));

    if (this.props.onChangeAddress) {
      this.props.onChangeAddress('floor', value, get(floor, 'origin'));
    }
  };

  handleChangeApartment = (e, value) => {
    if (this.props.input.apartment === value) {
      return;
    }

    const apartment = this.state.apartmentOptions.find((v) => v.value === value);
    const floor = get(apartment, 'floor');
    this.changeField(`floor`, get(floor, 'id'), floor);

    if (floor) {
      const entrance = this.state.floorOptions.find((v) => v.value === floor.id).entrance;
      this.changeField(`entrance`, get(entrance, 'id'), entrance);

      if (entrance) {
        const entrancesUnion = this.state.entranceOptions.find(
          (v) => v.value === entrance.id
        ).entrancesUnion;
        this.changeField(`entrances_union`, get(entrancesUnion, 'id'), entrancesUnion);
      }
    }

    if (this.props.onChangeAddress) {
      this.props.onChangeAddress('apartment', value, get(apartment, 'origin'));
    }
  };

  resetFields = (fields) => {
    const {
      meta: { form, dispatch },
    } = this.props;

    fields.forEach((field) => {
      dispatch(change(form, field, null));
      dispatch(untouch(form, field));
    });
  };

  changeField = (field, value, option) => {
    const {
      input: { name },
      meta: { form, dispatch },
      onChangeAddress,
    } = this.props;

    if (value === undefined) {
      value = null;
    }

    dispatch(change(form, `${name}.${field}`, value));

    if (onChangeAddress) {
      onChangeAddress(field, value, option);
    }
  };

  render() {
    const { isFetching, entrancesUnion } = this.state;

    const {
      input: { name },
      buildings,
      readOnly,
      readOnlyBuilding,
      showCheckboxEntranceUnion,
      showEntrance,
      showApartment,
      showFloor,
      requiredBuilding,
      requiredEntrance,
      requiredApartment,
      requiredFloor,
    } = this.props;

    const buildingOptions = buildings.map((item) => ({
      text: item.address.text,
      value: item.id,
      key: item.id,
    }));

    return (
      <React.Fragment>
        <Field
          name={`${name}.building`}
          component={Dropdown}
          required={requiredBuilding}
          disabled={readOnly || readOnlyBuilding}
          label={'Адрес'}
          search
          options={buildingOptions}
          onChange={this.handleChangeBuilding}
          noResultsMessage={'Дома не найдены'}
        />
        <Form.Group>
          {showApartment && (
            <Field
              name={`${name}.apartment`}
              component={Dropdown}
              required={requiredApartment}
              disabled={readOnly}
              label={'Квартира'}
              width={4}
              search
              options={this.state.apartmentOptions}
              onChange={this.handleChangeApartment}
              noResultsMessage={'Квартира не найдена'}
              loading={isFetching}
            />
          )}
          {showFloor && (
            <Field
              name={`${name}.floor`}
              component={Dropdown}
              required={requiredFloor}
              disabled={readOnly}
              label={'Этаж'}
              width={4}
              search
              options={this.state.floorOptions}
              onChange={this.handleChangeFloor}
              noResultsMessage={'Этаж не найдены'}
              loading={isFetching}
            />
          )}
          {showEntrance && (
            <Field
              name={`${name}.entrance`}
              component={Dropdown}
              required={requiredEntrance}
              disabled={readOnly}
              label={'Подъезд'}
              width={4}
              search
              options={this.state.entranceOptions}
              onChange={this.handleChangeEntrance}
              noResultsMessage={'Подъезд не найден'}
              loading={isFetching}
            />
          )}
        </Form.Group>
        {showCheckboxEntranceUnion && entrancesUnion && (
          <Field
            name={`${name}.entrances_union`}
            component={Checkbox}
            checkedValue={this.state.entrancesUnion.id}
            label={'Сквозной подъезд'}
          />
        )}
      </React.Fragment>
    );
  }
}

AddressList.defaultProps = {
  readOnly: false,
  readOnlyBuilding: false,
  buildings: [],

  requiredBuilding: true,
  requiredEntrance: true,
  requiredApartment: true,
  requiredFloor: true,

  showCheckboxEntranceUnion: false,
  showEntrance: true,
  showApartment: true,
  showFloor: true,

  calculateFloor: true,
};

AddressList.propTypes = {
  ...fieldPropTypes,
  readOnly: PropTypes.bool,
  readOnlyBuilding: PropTypes.bool,
  buildings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      address: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }).isRequired,
      owner_role: PropTypes.string,
      created_at: PropTypes.string,
      fias: PropTypes.arrayOf(PropTypes.string),
      payload: PropTypes.shape({
        floors: PropTypes.number,
        year: PropTypes.number,
      }),
    })
  ),

  requiredBuilding: PropTypes.bool,
  requiredEntrance: PropTypes.bool,
  requiredApartment: PropTypes.bool,
  requiredFloor: PropTypes.bool,

  showCheckboxEntranceUnion: PropTypes.bool,
  showEntrance: PropTypes.bool,
  showApartment: PropTypes.bool,
  showFloor: PropTypes.bool,

  calculateFloor: PropTypes.bool,

  onChangeAddress: PropTypes.func,
  fetchBindings: PropTypes.func.isRequired,
};
