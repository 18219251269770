import { get, find } from 'lodash';
import {
  USER_DEVICES_FAIL,
  USER_DEVICES_REQUEST,
  USER_DEVICES_SUCCESS,
  USER_CHANGE_DEVICE_FAIL,
  USER_CHANGE_DEVICE_REQUEST,
  USER_CHANGE_DEVICE_SUCCESS,
  USER_SERVICES_FAIL,
  USER_SERVICES_REQUEST,
  USER_SERVICES_SUCCESS,
  USER_ADD_SERVICE_FAIL,
  USER_ADD_SERVICE_REQUEST,
  //USER_ADD_SERVICE_SUCCESS,
  USER_DELETE_SERVICE_REQUEST,
  USER_DELETE_SERVICE_FAIL,
  USER_DELETE_SERVICE_SUCCESS,
  USER_RESTORE_PASSWORD_FAIL,
  USER_RESTORE_PASSWORD_REQUEST,
  USER_RESTORE_PASSWORD_SUCCESS,
  USER_SWAP_PARENT_FAIL,
  USER_SWAP_PARENT_REQUEST,
  USER_SWAP_PARENT_SUCCESS,
  UPDATE_USER_SERVICE_FAIL,
  UPDATE_USER_SERVICE_REQUEST,
  UPDATE_USER_SERVICE_SUCCESS,
} from '../../constants/actions';

import { request, formatErrorMessage, checkResponse, uniqid } from '../../utils/request';
import api from '../../constants/api';
import { prepareError, prepareResponse, singleResult } from '../../utils/request';
import OperationManager from '../../utils/operation';
import { getUser } from './users';

export const fetchUserDevices = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: USER_DEVICES_REQUEST,
      payload: {
        userId,
      },
    });

    try {
      let response = await request.get(api.intercoms(), { params: { user_id: userId } });
      checkResponse(response);

      dispatch({
        type: USER_DEVICES_SUCCESS,
        payload: {
          items: response.data.list,
          userId,
        },
      });
    } catch (e) {
      dispatch({
        type: USER_DEVICES_FAIL,
        payload: {
          userId,
          error: formatErrorMessage(e),
        },
      });

      throw e;
    }
  };
};

export const changeUserDevice = ({ deviceId, userId, active }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_CHANGE_DEVICE_REQUEST,
      payload: {
        userId,
        deviceId,
      },
    });

    try {
      const operationId = uniqid();

      const response = await request.post(
        `${api.intercoms(deviceId)}/${active ? 'link' : 'unlink'}`,
        {
          user_id: userId,
        },
        {
          headers: {
            'X-Operation-ID': operationId,
          },
        }
      );
      checkResponse(response);

      let item = find(get(getState(), `app.intercoms.items`, []), { id: deviceId });

      dispatch({
        type: USER_CHANGE_DEVICE_SUCCESS,
        payload: {
          userId,
          deviceId,
          active,
          item,
        },
      });

      const cb = function (userId) {
        dispatch(getUser(userId));
      };

      OperationManager.register(operationId, cb);
    } catch (e) {
      dispatch({
        type: USER_CHANGE_DEVICE_FAIL,
        payload: {
          userId,
          deviceId,
          error: formatErrorMessage(e),
        },
      });

      throw e;
    }
  };
};

export const fetchUserServices = ({ userId, objectType }) => {
  return async (dispatch) => {
    dispatch({
      type: USER_SERVICES_REQUEST,
      payload: {
        userId,
      },
    });

    try {
      let response = await request.get(api.billingUserServices(userId));
      checkResponse(response);

      dispatch({
        type: USER_SERVICES_SUCCESS,
        payload: {
          userId,
          items: response.data.list,
        },
      });
    } catch (e) {
      dispatch({
        type: USER_SERVICES_FAIL,
        payload: {
          userId,
          error: formatErrorMessage(e),
        },
      });

      throw e;
    }
  };
};

export const addServiceToUser = ({
  serviceId,
  objectType,
  objectId,
  startedAt,
  finishedAt,
  userId,
}) => {
  return async (dispatch) => {
    let id = new Date().getTime();

    dispatch({
      type: USER_ADD_SERVICE_REQUEST,
      payload: {
        userId,
        serviceId,
        id,
      },
    });

    let body = {
      service_id: serviceId,
      object_type: objectType,
      object_id: objectId,
      started_at: startedAt,
      finished_at: finishedAt,
    };

    try {
      let response = await request.post(`${api.billingUserServices(userId)}`, body);
      checkResponse(response);

      response = await request.get(api.billingUserServices(userId));
      checkResponse(response);

      dispatch({
        type: USER_SERVICES_SUCCESS,
        payload: {
          userId,
          items: response.data.list,
        },
      });

      //dispatch(fetchUserServices({ userId, objectType }));

      // dispatch({
      //     type: USER_ADD_SERVICE_SUCCESS,
      //     payload: {
      //         userId,
      //         serviceId,
      //         id,
      //         item: response.data.list[0]
      //     }
      // });
    } catch (e) {
      dispatch({
        type: USER_ADD_SERVICE_FAIL,
        payload: {
          userId,
          id,
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};

export const deleteUserService = ({ serviceId, userId, comment }) => {
  return async (dispatch) => {
    dispatch({
      type: USER_DELETE_SERVICE_REQUEST,
      payload: {
        id: serviceId,
        userId,
      },
    });

    try {
      let response = await request.delete(api.billingUserServices(userId), {
        data: { service_id: serviceId, comment },
      });
      checkResponse(response);

      dispatch({
        type: USER_DELETE_SERVICE_SUCCESS,
        payload: {
          id: serviceId,
          userId,
        },
      });
    } catch (e) {
      dispatch({
        type: USER_DELETE_SERVICE_FAIL,
        payload: {
          id: serviceId,
          userId,
          error: formatErrorMessage(e),
        },
      });

      throw new Error(formatErrorMessage(e));
    }
  };
};

function updateUserServiceRequest() {
  return {
    type: UPDATE_USER_SERVICE_REQUEST,
  };
}

function updateUserServiceSuccess(res) {
  return {
    type: UPDATE_USER_SERVICE_SUCCESS,
    payload: {
      item: res,
    },
  };
}

function updateUserServiceFail(e) {
  return {
    type: UPDATE_USER_SERVICE_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export const updateUserService = ({ userId, userServiceId, action, comment }) => {
  return (dispatch) => {
    dispatch(updateUserServiceRequest());

    const result = request
      .put(api.billingUserServices(userId), { service_id: userServiceId, action, comment })
      .then(prepareResponse)
      .then(singleResult)
      .catch(prepareError);

    result
      .then((r) => dispatch(updateUserServiceSuccess(r)))
      .catch((e) => dispatch(updateUserServiceFail(e)));

    return result;
  };
};

function restoreUserPasswordRequest() {
  return {
    type: USER_RESTORE_PASSWORD_REQUEST,
  };
}

function restoreUserPasswordSuccess(res) {
  return {
    type: USER_RESTORE_PASSWORD_SUCCESS,
    payload: {
      item: res,
    },
  };
}

function restoreUserPasswordFail(e) {
  return {
    type: USER_RESTORE_PASSWORD_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export const restoreUserPassword = ({ username, source }) => {
  return (dispatch) => {
    dispatch(restoreUserPasswordRequest());

    const result = request
      .post(api.userRestorePassword(), { username, source })
      .catch(prepareError)
      .then(prepareResponse)
      .then(singleResult);

    result
      .catch((e) => dispatch(restoreUserPasswordFail(e)))
      .then((r) => dispatch(restoreUserPasswordSuccess(r)));

    return result;
  };
};

function swapParentRequest() {
  return {
    type: USER_SWAP_PARENT_REQUEST,
  };
}

function swapParentSuccess(res) {
  return {
    type: USER_SWAP_PARENT_SUCCESS,
    payload: {
      item: res,
    },
  };
}

function swapParentFail(e) {
  return {
    type: USER_SWAP_PARENT_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export const swapParent = (id) => {
  return (dispatch) => {
    dispatch(swapParentRequest());

    const result = request
      .post(api.swapParent(id))
      .catch(prepareError)
      .then(prepareResponse)
      .then(singleResult);

    result.catch((e) => dispatch(swapParentFail(e))).then((r) => dispatch(swapParentSuccess(r)));

    return result;
  };
};
