const users = (id) =>
  `${process.env.REACT_APP_API_ORIGIN}/api/security/v1/users${id ? '/' + id : ''}`;

export default {
  auth: (id) => `${process.env.REACT_APP_API_ORIGIN}/api/auth/v1/auth${id ? '/' + id : ''}`,
  tokens: (id) => `${process.env.REACT_APP_API_ORIGIN}/api/token/v1/tokens${id ? '/' + id : ''}`,
  users: users,
  userCategories: () => `${process.env.REACT_APP_API_ORIGIN}/api/security/v1/categories`,
  staff: (id) => `${process.env.REACT_APP_API_ORIGIN}/api/employee/v1/users${id ? '/' + id : ''}`,
  staffEvict: (id) => `${process.env.REACT_APP_API_ORIGIN}/api/employee/v1/users/${id}/evict`,
  intercoms: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/intercom/v1/intercoms${id ? '/' + id : ''}`,
  intercomsV2: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/intercom/v2/intercoms${id ? '/' + id : ''}`,
  intercomProperty: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/intercom/v1/intercoms/${id}/property`,
  intercomAvailable: `${process.env.REACT_APP_API_ORIGIN}/api/intercom/v1/intercoms/available`,
  intercomCommands: () => `${process.env.REACT_APP_API_ORIGIN}/api/intercom-command/v1/commands`,
  intercomActivate: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/intercom/v2/intercoms/${id}/activate`,
  intercomProxy: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/intercom/v2/intercoms/${id}/proxy`,
  cameras: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/intercom/v1/cameras${id ? '/' + id : ''}`,
  videoRecorders: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/intercom/v1/video-recorders${id ? '/' + id : ''}`,
  cameraStreams: (id, start, duration = 360) => {
    const params = '?start=' + start + '&duration=' + duration;
    return `${process.env.REACT_APP_API_ORIGIN}/api/intercom/v1/cameras/${id}/streams` + params;
  },
  checkCameraStream: (url) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/intercom/v1/streams/info?uri=${url}`,
  companies: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/company/v1/companies${id ? '/' + id : ''}`,
  resendConfirmationEmail: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/company/v1/companies/${id}/contact/resend/email`,
  buildings: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/platform/v1/buildings${id ? '/' + id : ''}`,
  bindings: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/platform/v1/bindings${id ? '/' + id : ''}`,
  bindingsBatch: `${process.env.REACT_APP_API_ORIGIN}/api/platform/v1/bindings/batch`,
  entrances: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/platform/v1/entrances${id ? '/' + id : ''}`,
  device: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/platform/v1/devices${id ? '/' + id : ''}`,
  deviceCategory: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/platform/v1/device-categories${id ? '/' + id : ''}`,
  deviceReference: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/platform/v1/device-references${id ? '/' + id : ''}`,
  menu: (name = 'basic') =>
    `${process.env.REACT_APP_API_ORIGIN}/api/backend-intercom/v1/menu/${name}`,
  reports: (alias) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/report/v1/reports/${alias ? `${alias}/` : ''}`,
  builds: `${process.env.REACT_APP_API_ORIGIN}/api/report/v1/builds/`,
  userServices: (userId) => `${users(userId)}/services`,
  userActivate: (userId) => `${users(userId)}/activate`,
  swapParent: (userId) => `${users(userId)}/parent/swap`,
  userRestorePassword: () => `${users()}/password/restore?source=${process.env.REACT_APP_SOURCE}`,
  services: `${process.env.REACT_APP_API_ORIGIN}/api/billing/v2/services`,
  companiesWithService: `${process.env.REACT_APP_API_ORIGIN}/api/billing/v2/companies/services`,
  billingCompanyServicesRegistry: (companyId) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/billing/v2/companies/${companyId}/servicesViaRegistry`,
  servicesReasons: `${process.env.REACT_APP_API_ORIGIN}/api/billing/v2/reasons`,
  billingUserServices: (userId) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/billing/v2/users/${userId}/services`,
  billingUserServicesRegistry: (userId) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/billing/v2/users/${userId}/servicesViaRegistry`,
  events: `${process.env.REACT_APP_API_ORIGIN}/api/event/v1/events`,
  feedback: `${process.env.REACT_APP_API_ORIGIN}/api/feedback/v1/messages`,
  apartments: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/apartment/v1/apartments${id ? '/' + id : ''}`,
  apartmentTypes: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/apartment/v1/types${id ? '/' + id : ''}`,
  apartmentsLink: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/apartment/v1/apartments${id ? '/' + id : ''}/link`,
  apartmentsUnlink: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/apartment/v1/apartments${id ? '/' + id : ''}/unlink`,
  blockApartmentUser: ({apartmentId, userId}) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/apartment/v1/apartments/${apartmentId}/block/${userId}`,
  uploadImages: `${process.env.REACT_APP_API_ORIGIN}/api/multi-uploader/v1/images`,
  metrics: `${process.env.REACT_APP_API_ORIGIN}/api/moleculer-gw/v1/gateway/metrics`,
  parserSessions: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/billing/v1/sessions${id ? '/' + id : ''}`,
  parserRows: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/billing/v1/rows${id ? '/' + id : ''}`,
  workflows: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/workflow/v1/workflow${id ? '/' + id : ''}`,
  workflowReasons: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/workflow/v1/reasons${id ? '/' + id : ''}`,
  emergencyUserCreateSip: (userId) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/emergency/v1/users/${userId}/createSip`,
  emergencyIntercomLinkUserSip: (intercomId) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/emergency/v1/intercoms/${intercomId}/linkSip`,
  emergencyIntercomApartmentInfo: (intercomId) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/emergency/v1/intercoms/${intercomId}/apartmentInfo`, // TODO
  emergencyIntercomRunCommand: (intercomId) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/emergency/v1/intercoms/${intercomId}/command`,
  emergencyIntercomReconfiguration: (intercomId) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/emergency/v1/intercoms/${intercomId}/reconfiguration`,
  integrations: () => `${process.env.REACT_APP_API_ORIGIN}/api/billing/v2/integrations`,
  integrationSessions: () => `${process.env.REACT_APP_API_ORIGIN}/api/billing/v2/sessions`,
  integrationSessionRows: (rowId) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/billing/v2/rows${rowId ? `/${rowId}` : ''}`,
  userUpload: () => `${process.env.REACT_APP_API_ORIGIN}/api/security/v1/users/upload`,
  userUploadConfirm: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/security/v1/users/upload/${id}/run`,
  restreamer: (id) =>
    `${process.env.REACT_APP_API_ORIGIN}/api/intercom/v1/restreamers${id ? '/' + id : ''}`,
};
