import React, { MouseEvent } from 'react';
import videojs from 'video.js';
import { throttle } from 'lodash';
import cx from 'classnames';
import { VideoElement } from './components/video';
import { VideoSeekSlider } from './components/slider';
import { PlayToggle } from './components/button-play';
import { FullscreenToggle } from './components/button-fullscreen';
import { Spinner } from './components/spinner';
import { LiveDisplay } from './components/live-display';
import { GotoLive } from './components/button-goto-live';
import { CurrentTime } from './components/current-time';
import { closeFullscreen, openFullscreen } from './utils';
import './styles.less';
import { VideoErrorMessage } from './components/error';

interface Props {
  stream: (percent?: number) => Promise<string | undefined>;
  timezone: number; // sec
  timeline: number; // sec
  autoplay?: boolean;

  showSeekSlider?: boolean;
}

interface State {
  isPlaying: boolean;
  isLoadingVideoData: boolean;
  isLoadingNewSource: boolean;
  isLive: boolean;
  isFullscreen: boolean;
  currentTime: number; // sec
  hasError: boolean;

  isUserActive: boolean;

  isSeeking: boolean;
  seekTime: number;
  seekPercent: number;

  timeline: number;

  // костыль
  hasAtLeastOnePlay: boolean;
  startDate: Date;
}

type DefaultProps = Required<Pick<Props, 'autoplay'>>;

const DEFAULT_INACTIVE_TIME = 5000;

export class VideoArchivePlayer extends React.Component<Props, State> {
  private container = React.createRef<HTMLDivElement>();
  private video = React.createRef<HTMLVideoElement>();
  private player?: videojs.Player;
  private activeTimer?: number;

  static defaultProps: DefaultProps = {
    autoplay: false,
  };

  constructor(props: Props & DefaultProps) {
    super(props);

    this.state = {
      isPlaying: props.autoplay,
      isLoadingVideoData: false,
      isLoadingNewSource: false,
      isLive: false,
      isFullscreen: false,
      currentTime: props.timeline,
      hasError: false,

      isUserActive: true,

      isSeeking: false,
      seekTime: 0,
      seekPercent: 0,

      timeline: Math.abs(props.timeline),

      hasAtLeastOnePlay: props.autoplay,
      startDate: new Date(),
    };
  }

  componentDidMount() {
    console.log('>>>PLAYER:INIT', this.video.current);

    const options: videojs.PlayerOptions = {
      // @ts-ignore
      debug: true,

      muted: true,
      fluid: false,
      preload: 'auto',
      html5: {
        hls: {
          enableLowInitialPlaylist: true,
          smoothQualityChange: true,
          overrideNative: true,
        },
      },
      autoplay: this.props.autoplay,
      children: [],
      // @ts-ignore
      // crossorigin: 'anonymous',
    };

    //@ts-ignore
    this.player = videojs(this.video.current, options);
    const player = this.player;
    // @ts-ignore
    window.__player = player;

    player.on('error', () => {
      console.error('>>>PLAYER:ERR', player.error());

      if (!player.paused()) {
        player.pause();
      }

      this.setState({
        isLoadingVideoData: false,
        isLive: true,
        isPlaying: false,
        hasError: true,
      });
    });

    this.container.current!.addEventListener('fullscreenchange', this.toggleFullscreen);
    // player.on('fullscreenchange', () => {
    //     this.setState({
    //         isFullscreen: this.player!.isFullscreen(),
    //     });
    // });

    player.on('play', () => {
      console.log('>>>PLAYER:STATE_PLAY');
      this.startInactiveTimer();
      if (!this.state.hasAtLeastOnePlay) {
        this.setState({ hasAtLeastOnePlay: true });
      }
    });
    player.on('pause', () => {
      console.log('>>>PLAYER:STATE_PAUSE');
      this.stopInactiveTimer();
    });
    player.on('ended', () => {
      const percent = player.duration() / this.state.timeline + this.state.seekPercent;
      if (percent > 0.99) {
        this.gotoLive();
      } else {
        this.loadAndSetSrc(percent)
          .then(() => {
            if (!this.state.isLive) {
              this.setState({
                seekTime: this.state.seekTime + player.duration(),
                seekPercent: percent,
              });
            }
          })
          .catch((err) => console.error('>>>PLAYER:ENDED:ERR', err));
      }
    });
    player.on('canplay', () => {
      console.log('>>>PLAYER:CANPLAY', player.src());
    });
    player.on('canplaythrough', () => {
      console.log('>>>PLAYER:CANPLAYTHROUGH', player.src());
    });

    player.on('durationchange', () => {
      const duration = player.duration();
      const isLive = this.isLive();

      console.log(
        `>>>PLAYER:DURATION live=${isLive} duration=${duration} curt=${this.state.currentTime}`
      );
      if (duration !== 0) {
        this.setState({
          isLive: isLive,
        });

        if (isLive) {
          this.tryStartPlay();
        }
      }
    });

    player.on('loadedmetadata', () => {
      console.log('>>>PLAYER:METADATA');

      this.tryStartPlay();
    });

    // show spinner
    player.on('loadstart', this.startLoading);
    player.on('waiting', this.startLoading);
    player.on('seeking', this.startLoading);
    player.on('seeked', this.stopLoading);
    player.on('loadeddata', this.stopLoading);

    if (!this.props.autoplay) {
      player.preload(false);
    }
    this.gotoLive();
    //
    // setTimeout(() => {
    //     this.player!.trigger('error');
    // }, 6000);
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }

    if (this.container) {
      this.container.current!.removeEventListener('fullscreenchange', this.toggleFullscreen);
    }

    if (this.activeTimer) {
      clearTimeout(this.activeTimer);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.stream !== prevProps.stream) {
      this.setState({
        hasError: false,

        isSeeking: false,
        currentTime: 0,
        seekTime: 0,
        seekPercent: 0,
      });
      this.gotoLive();
    }
  }

  tryStartPlay() {
    console.log('>>>PLAYER:TRY_START');

    if (this.state.hasError) {
      this.setState({
        hasError: false,
      });
    }

    this.player!.pause();
    if (this.state.isPlaying) {
      setTimeout(() => {
        Promise.resolve(this.player!.play()).catch((err) =>
          console.error('>>>PLAYER:TRY_START:ERR', err)
        );
      }, 1);
    }
  }

  togglePlay() {
    if (this.state.isPlaying) {
      this.player!.play();
    } else {
      this.player!.pause();
    }
  }

  startPlay() {
    this.setState({ isPlaying: true });
    if (this.player!.paused()) {
      this.player!.play();
    }
  }

  stopPlay() {
    this.setState({ isPlaying: false });
    if (!this.player!.paused()) {
      this.player!.pause();
    }
  }

  toggleFullscreen = () => {
    this.setState({
      isFullscreen: !this.state.isFullscreen,
    });
  };

  startLoading = () => {
    console.log('>>>PLAYER:LOAD_START');

    if (this.state.hasAtLeastOnePlay) {
      this.setState({
        isLoadingVideoData: true,
      });
    }
  };

  stopLoading = () => {
    console.log('>>>PLAYER:LOAD_STOP');

    this.setState({
      isLoadingVideoData: false,
    });
  };

  private setSrc(src: string) {
    const player = this.player!;

    if (player.src() === src) {
      return;
    }

    console.log('>>>PLAYER:SRC', src, player.src());

    // if (!player.paused()) {
    //     player.pause();
    // }

    player.src(src);
    // player.load();
  }

  private loadAndSetSrc(percent?: number) {
    this.setState({
      isLoadingNewSource: true,
      startDate: new Date(),
    });

    return this.props
      .stream(percent)
      .then((src) => src && this.setSrc(src))
      .finally(() => this.setState({ isLoadingNewSource: false }));
  }

  private isLive() {
    return !Number.isFinite(this.player!.duration());
  }

  private gotoLive() {
    console.log('>>>PLAYER:GOTO_LIVE');

    this.setState({
      // isLive: true,
      currentTime: this.state.timeline,
    });

    this.loadAndSetSrc().catch((err) => console.error('>>>PLAYER:GOTO_LIVE:ERR', err));
  }

  private startInactiveTimer() {
    this.stopInactiveTimer();

    //@ts-ignore
    this.activeTimer = setTimeout(() => {
      this.setState({
        isUserActive: false,
      });
    }, DEFAULT_INACTIVE_TIME);
  }

  private stopInactiveTimer() {
    if (this.activeTimer) {
      clearTimeout(this.activeTimer);
      this.activeTimer = undefined;
    }

    if (!this.state.isUserActive) {
      this.setState({
        isUserActive: true,
      });
    }
  }

  handleSliderSeeking = (time: number, percent: number) => {
    console.log('>>>BAR:SEEKING', time, percent);

    this.setState({
      seekTime: time,
      seekPercent: percent,
    });
  };

  handleSliderChange = (isSeeking: boolean, time: number, percent: number) => {
    console.log('>>>BAR:CHANGE', isSeeking, time, percent);

    if (isSeeking) {
      this.setState({
        isSeeking,
        seekTime: time,
        seekPercent: percent,
      });
    } else {
      this.setState({
        isSeeking,
        seekTime: time,
        seekPercent: percent,
        currentTime: time,
      });

      if (percent > 0.99) {
        this.gotoLive();
      } else {
        this.loadAndSetSrc(percent).catch((err) => console.error('>>>BAR:CHANGE:ERR', err));
      }
    }
  };

  handlePlayToggle = () => {
    const isPlaying = !this.state.isPlaying;

    if (isPlaying) {
      this.startPlay();
    } else {
      this.stopPlay();
    }

    // const isLoading = this.state.isLoading;
    //
    // this.setState({
    //     isPlaying: isPlaying,
    //     isLoading: isPlaying ? isLoading : false,
    // }, () => {
    //     this.togglePlay();
    // });
  };

  handleFullscreenToggle = (event: MouseEvent) => {
    event.preventDefault();
    const isFullscreen = !this.state.isFullscreen;

    if (isFullscreen) {
      openFullscreen(this.container.current!);
    } else {
      closeFullscreen();
    }
  };

  handleGotoLive = () => {
    this.gotoLive();
  };

  handleMouseMove = throttle(() => {
    this.startInactiveTimer();
  }, 75);

  handleRestart = () => {
    this.setState({
      hasError: false,
    });

    this.player!.play();
  };

  render() {
    let current;
    // TODO вынести в обработчики
    if (this.state.isSeeking) {
      current = this.state.seekTime;
    } else if (this.player) {
      current = this.state.currentTime + this.player.currentTime();
    } else {
      current = this.state.currentTime;
    }

    const className = cx('player-video-archive', {
      'user-inactive': !this.state.isUserActive,
      fullscreen: this.state.isFullscreen,
      error: this.state.hasError,
    });

    // console.log(`>>>RENDER current=${current}`);

    return (
      <div className={className} onMouseMove={this.handleMouseMove} ref={this.container}>
        <VideoElement ref={this.video} />
        {this.state.hasError ? (
          <VideoErrorMessage restart={this.handleRestart} />
        ) : this.state.isLive ? (
          <LiveDisplay />
        ) : (
          <GotoLive onClick={this.handleGotoLive} />
        )}

        {/* TODO вынести контроль в сам слайдер и тригерить только по остановке поиска */}
        {this.props.showSeekSlider !== false && (
          <VideoSeekSlider
            max={this.state.timeline}
            buffered={0}
            current={current}
            onSeeking={this.handleSliderSeeking}
            onChange={this.handleSliderChange}
          />
        )}
        <PlayToggle play={this.state.isPlaying} onClick={this.handlePlayToggle} />
        <FullscreenToggle
          fullscreen={this.state.isFullscreen}
          onClick={this.handleFullscreenToggle}
        />
        {!this.state.hasError && (
          <CurrentTime
            timezone={this.props.timezone}
            timeline={this.props.timeline}
            currentStart={this.state.startDate}
            currentTime={this.state.isLive ? undefined : current}
          />
        )}
        {(this.state.isLoadingVideoData || this.state.isLoadingNewSource) && <Spinner />}
      </div>
    );
  }
}
