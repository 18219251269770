import React, { useCallback } from 'react';
import {
  Checkbox as SemanticCheckbox,
  CheckboxProps,
  StrictCheckboxProps,
} from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import { setFieldValue } from '../helpers';
import { BaseFormikFieldProps } from '../props';
import { FormFieldWrapper, FormFieldWrapperProps, useGetComponentProps } from '../field';
import styled from 'styled-components';

const CheckboxStyled = styled(SemanticCheckbox)`
  min-height: 44px !important;
  display: flex !important;
  align-items: center !important;
  & > label {
    padding-top: 4px !important;
  }
`;

// это херня, что бы можно было не только true/false получить из формы, а значение checkedValue при checked
const getValue = (isChecked: boolean, checkedValue: string | undefined) => {
  if (checkedValue === undefined) {
    return isChecked;
  }

  if (isChecked) {
    return checkedValue;
  }

  return undefined;
};

interface OwnProps
  extends FormFieldWrapperProps,
    Omit<StrictCheckboxProps, 'id' | 'label' | 'name' | 'onChange'> {
  onChange?: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps, props: Props) => void;
  fieldLabel?: string;
}

interface Props extends OwnProps, FieldProps {}

function CheckboxControl({ fieldLabel, ...props }: Props) {
  const {
    onChange: onChangeOrigin,

    field,
    form,
    meta,

    ...rest
  } = useGetComponentProps(props);

  const label = props.label;
  const value = props.value;

  const onChange = useCallback(
    (e, data) => {
      const v = getValue(data.checked, value as string);
      setFieldValue(form, field.name, v, true);
      onChangeOrigin?.(e, data, props);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [value, form, field.name, onChangeOrigin, props]
  );

  const isChecked =
    value === undefined ? field.value === true : field.value !== null && field.value !== '';

  return (
    <FormFieldWrapper {...props} label={fieldLabel}>
      <CheckboxStyled
        {...rest}
        onChange={onChange}
        checked={isChecked}
        label={label}
        disabled={props.disabled}
      />
    </FormFieldWrapper>
  );
}

interface WrapProps extends BaseFormikFieldProps<StrictCheckboxProps['value']>, OwnProps {}

export function Checkbox(props: WrapProps) {
  return <Field component={CheckboxControl} {...props} />;
}
