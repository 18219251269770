import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { FormField, SemanticWIDTHS } from 'semantic-ui-react';
import { EMPTY_STRING, getFieldError } from '../helpers';
import { FieldProps as FormikFieldProps } from 'formik';

export interface FormFieldWrapperProps {
  id?: string;
  label?: ReactNode | false;
  hint?: ReactNode | false;
  required?: boolean;
  disabled?: boolean;
  inline?: boolean;
  width?: SemanticWIDTHS;
  className?: string;
  hideLabel?: boolean;
  hideHint?: boolean;
}

interface Props extends FormFieldWrapperProps, FormikFieldProps {}

/*------------------------------
           Component
------------------------------*/

export const FormFieldWrapper = (props: PropsWithChildren<Props>) => {
  const {
    id,
    label = EMPTY_STRING,
    hint = EMPTY_STRING,
    required,
    disabled,
    inline,
    width,
    className,

    field,
    form,

    hideLabel,
    hideHint,

    children,
  } = props;

  const error = getFieldError(form, field);
  const message = error === undefined ? hint : error;

  return (
    <FormField
      required={required}
      disabled={disabled}
      inline={inline}
      width={width}
      className={className}
      error={!!error}
    >
      {label && !hideLabel && <label htmlFor={id}>{label}</label>}
      {children}
      {message && !hideHint && <span className="hint">{message}</span>}
    </FormField>
  );
};

/*------------------------------
              Hook
------------------------------*/

export function useGetComponentProps<T extends Props>(props: T) {
  return useMemo(() => {
    const {
      label,
      hint,
      required,
      disabled,
      inline,
      width,
      className,

      ...rest
    } = props;

    rest.id = rest.id ?? `field.${rest.field.name}`;

    return rest as Omit<T, keyof FormFieldWrapperProps> & Pick<T, 'id'>;
  }, [props]);
}
