import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CompanyBuildings } from './container';
import ClientProvider from '../../../../client-provider';

interface Params {
  id: string;
}

interface Props extends RouteComponentProps<Params> {}

export default function CompanyBuildingsPage(props: Props) {
  return (
    <ClientProvider>
      <CompanyBuildings companyId={props.match.params.id} />
    </ClientProvider>
  );
}
