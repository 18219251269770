import React, { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { fetchApartments } from 'core/store/actions/apartments';
import { WebAPIActionFetchParams } from 'core/utils/webapi.contracts';
import { Apartment } from 'core/constants/contracts';

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchApartments: bindActionCreators(fetchApartments, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  fetchApartments: (params: WebAPIActionFetchParams) => Promise<any>;
}

interface State {
  isLoading: boolean;
  apartments: Apartment[];
}

class ApartmentList extends React.PureComponent<Props, State> {
  state = {
    isLoading: false,
    apartments: [],
  };

  componentDidMount(): void {
    this.setState({ isLoading: true });

    const filter = {
      building: this.props.match.params.id,
    };

    this.props
      .fetchApartments({ filter })
      .then((apartments) => this.setState({ apartments }))
      .finally(() => this.setState({ isLoading: false }));
  }

  render(): ReactNode {
    return <div>Apartments List</div>;
  }
}

export default connector(ApartmentList as any);
