import {
  checkResponse,
  formatErrorMessage,
  prepareError,
  prepareResponse,
  request,
  returnValue,
  singleResult,
} from '../../utils/request';
import api from '../../constants/api';
import { compose } from 'redux';
import {
  CREATE_REPORT_FAIL,
  CREATE_REPORT_REQUEST,
  CREATE_REPORT_SUCCESS,
  REPORT_COMPLETED,
  REPORT_FAIL,
  REPORT_REQUEST,
  REPORT_SUCCESS,
} from '../../constants/actions';

export function fetchReportRequest() {
  return {
    type: REPORT_REQUEST,
  };
}

export function fetchReportSuccess(res) {
  return {
    type: REPORT_SUCCESS,
    payload: {
      item: res,
    },
  };
}

export function fetchReportFail(e) {
  return {
    type: REPORT_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export function createReportRequest(res) {
  return {
    type: CREATE_REPORT_REQUEST,
  };
}

export function createReportSuccess(res) {
  return {
    type: CREATE_REPORT_SUCCESS,
    payload: {
      item: res,
    },
  };
}

export function createReportFail(e) {
  return {
    type: CREATE_REPORT_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export function reportCompleted(build) {
  return {
    type: REPORT_COMPLETED,
    payload: {
      url: build.url,
      error: build.error,
    },
  };
}

export const createBuild = ({ alias, criteria, columns }) => {
  return (dispatch) => {
    dispatch(createReportRequest());

    const data = {
      report_alias: alias,
      criteria,
      columns,
    };

    const result = request
      .post(api.builds, data)
      .then(prepareResponse)
      .then(singleResult)
      .catch(prepareError);

    result
      .then((r) => compose(returnValue, dispatch, createReportSuccess)(r))
      .catch((e) => dispatch(createReportFail(e)));

    return result;
  };
};

export const fetchReport = (alias) => {
  return async (dispatch) => {
    dispatch(fetchReportRequest());

    try {
      let response = await request.get(api.reports(alias));
      checkResponse(response);

      dispatch(fetchReportSuccess(response.data.list[0]));

      return response.data.list[0];
    } catch (e) {
      console.error(e);

      dispatch(fetchReportFail(e));

      throw new Error(formatErrorMessage(e));
    }
  };
};
