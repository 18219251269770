import { TextFieldX } from 'components/share';
import { getDeviceStatusTitle, getDeviceStatusColor } from 'core/infrastructure/device';
import { IntercomAgg } from 'core/store/selectors/intercoms';
import React, { FC, useState } from 'react';
import { Accordion, Divider, Grid, Icon } from 'semantic-ui-react';
import { actualOwnerMap, internetProviderMap } from 'views/pages/devices/pages/intercoms/config';
import { DeviceModel } from '../../../components/device-model';
import { AdminStatus } from '../admin-status';
import { OperationStatus } from '../operation-status';
import { LinkStyled, AccordionTitleStyled } from './styles';

interface Props {
  intercom: IntercomAgg;
}

export const IntercomView: FC<Props> = ({ intercom }) => {
  const {
    phone,
    __device_ref: deviceReference,
    is_online,
    status,
    device,
    actual_owner,
    internet_provider,
    regional_billing_account,
    serial_number,
    ring_strategy,
    config,
  } = intercom;

  const [isCredentialsOpen, setIsCredentialsOpen] = useState(false);
  const handleCredentials = () => setIsCredentialsOpen(!isCredentialsOpen);

  return (
    <Grid columns={4}>
      <Grid.Row>
        <TextFieldX title="SIP" size={2}>
          {phone}
        </TextFieldX>
        <TextFieldX title="Модель устройства" size={6}>
          {deviceReference && (
            <DeviceModel type={deviceReference.category} model={deviceReference.name} />
          )}
        </TextFieldX>
        <TextFieldX title="Оперативный статус" size={4}>
          <OperationStatus isOnline={is_online} message={is_online ? 'Доступен' : 'Недоступен'} />
        </TextFieldX>
        <TextFieldX title="Административный статус" size={4}>
          <AdminStatus
            message={getDeviceStatusTitle(status)}
            color={getDeviceStatusColor(status)}
          />
        </TextFieldX>
      </Grid.Row>

      <Grid.Row>
        <TextFieldX title="Владелец" size={2}>
          {device.owner?.id && (
            <LinkStyled to={`/companies/${device.owner?.id}`}>{device.owner?.name}</LinkStyled>
          )}
        </TextFieldX>
        <TextFieldX title="Принадлежность панели" size={6}>
          {actual_owner && actualOwnerMap[actual_owner]}
        </TextFieldX>
        <TextFieldX title="Принадлежность интернета" size={4}>
          {internet_provider && internetProviderMap[internet_provider]}
        </TextFieldX>
        <TextFieldX title="ЛС регионального биллинга" size={4}>
          {regional_billing_account}
        </TextFieldX>
      </Grid.Row>

      <Grid.Row>
        <TextFieldX title="Стратегия вызова" size={2}>
          {ring_strategy}
        </TextFieldX>
        <TextFieldX title="Серийный № панели" size={6}>
          {serial_number}
        </TextFieldX>
      </Grid.Row>

      <Divider />

      <Grid.Row>
        <Grid.Column width={16}>
          <Accordion>
            <AccordionTitleStyled onClick={handleCredentials} active>
              <Icon name={isCredentialsOpen ? 'eye slash' : 'eye'} />
              {isCredentialsOpen ? 'Скрыть учетные данные' : 'Показать учетные данные'}
            </AccordionTitleStyled>
            <Accordion.Content active={isCredentialsOpen}>
              <Grid columns={4}>
                <Grid.Row>
                  <TextFieldX title="Логин" size={2}>
                    {config?.control?.login}
                  </TextFieldX>
                  <TextFieldX title="Пароль" size={2}>
                    {config?.control?.password}
                  </TextFieldX>
                  <TextFieldX title="Секрет" size={2}>
                    {config?.secret}
                  </TextFieldX>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
