import List from './list';
import View from './view';
import Edit from './edit';
import Create from './create';
import BuildingsList from './buildings';
import UserEdit from './users-edit-form/';

export default {
  List,
  View,
  Edit,
  Create,
  BuildingsList,
  UserEdit,
};
