import { PropsWithChildren } from 'react';
import { useBreadcrumb } from './hooks';

export interface SetBreadcrumbProps {
  id?: string;
  url?: string;
}

export function SetBreadcrumb(props: PropsWithChildren<SetBreadcrumbProps>) {
  useBreadcrumb({
    id: props.id,
    content: props.children,
    url: props.url,
  });

  return null;
}
