import React, { useEffect } from 'react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { useParams } from 'react-router';
import * as RouteUrl from 'core/constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { clear as clearIntercom, fetchIntercom } from 'core/store/features/intercoms';
import { RootState } from 'core/store';
import { Intercom } from 'core/constants/contracts';
import DeviceHistory from 'views/pages/devices/abstract/history';

type IParamsProps = {
  id: string;
};

type IStoreProps = {
  intercom: Intercom;
};

const IntercomHistory = () => {
  const { id } = useParams<IParamsProps>();

  const dispatch = useDispatch();
  const clearStore = () => {
    dispatch(clearIntercom());
  };

  const { intercom } = useSelector<RootState, IStoreProps>((state) => ({
    intercom: state.entities.intercoms.items[id],
  }));

  useEffect(() => {
    dispatch(fetchIntercom(id));

    return clearStore;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IDBCursorWithValue, dispatch]);

  return (
    <>
      <SetBreadcrumb url={RouteUrl.intercomList}>Домофоны</SetBreadcrumb>
      <SetBreadcrumb url={RouteUrl.intercomView(id)}>{intercom?.phone}</SetBreadcrumb>
      <SetBreadcrumb>История операций</SetBreadcrumb>

      <DeviceHistory />
    </>
  );
};

export default IntercomHistory;
