import * as yup from 'yup';
import integerSchema from './integer';
import { minEntrance, maxEntrance, minApartment, maxApartment } from './config';

const bindingsSchema = yup.object().shape(
  {
    entrances_union: integerSchema,
    entrance: integerSchema
      .required('Укажите подъезд')
      .min(minEntrance, `Значение не меньше ${minEntrance}`)
      .max(maxEntrance, `Значение не больше ${maxEntrance}`)
      .when('apartments', function (apartments, schema) {
        const apartments_from = apartments && apartments.from;
        const apartments_to = apartments && apartments.to;

        return !!apartments_from || !!apartments_to
          ? schema.required('Укажите подъезд').min(0, 'Значение не меньше нуля')
          : schema;
      }),
    apartments: yup.object().when('entrance', function (entrance, schema) {
      return schema.shape(
        {
          from: integerSchema.when('to', function (apartments_to, schema) {
            return !!entrance || !!apartments_to
              ? schema
                  .required('Заполните диапазон квартир (от)')
                  .min(minApartment, `Значение не меньше ${minApartment}`)
                  .max(maxApartment, `Значение не больше ${maxApartment}`)
              : schema;
          }),
          to: integerSchema.when('from', function (apartments_from, schema) {
            if (!!entrance || !!apartments_from) {
              schema = schema
                .required('Заполните диапазон квартир (до)')
                .min(minApartment, `Значение не меньше ${minApartment}`);
            }
            // проверим поле apartments_from схемой поля apartments_to, т.к. они совпадают
            if (this.isType(apartments_from)) {
              schema = schema
                .min(apartments_from, `Значение не меньше ${apartments_from}`)
                .max(maxApartment, `Значение не больше ${maxApartment}`);
            }

            return schema;
          }),
        },
        ['to', 'from']
      );
    }),
    poList: yup.object().shape({
      numbers: yup.array().of(yup.string().matches(/^$|^[0-9]{11}$/, 'ПО состоит из 11 цифр')),
    }),
  },
  ['entrance', 'apartments']
);

export default bindingsSchema;
