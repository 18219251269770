import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import ResetPasswordForm from '../components/reset-password-form';
import '../styles.less';

export default class CreateUserPage extends Component {
  render() {
    return (
      <div className={'content-margin-top width700 security'}>
        <Segment>
          <Header as={'h2'} style={{ fontWeight: '500' }}>
            Установка нового пароля
          </Header>
          <ResetPasswordForm />
        </Segment>
      </div>
    );
  }
}
