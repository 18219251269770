import { RootState } from '..';
import isEmpty from 'lodash/isEmpty';
import { GenericParametricSelector, PossibleIdProps } from '.';
import { createSelector } from 'reselect';

import { VideoRecorder, OwnerStatusType } from 'core/constants/contracts';
import { EntitiesState, EntityId } from '../features/entities';
import { getCompaniesItems } from './companies';
import { ownerStatusMap } from './shared/helper';

export const getVideoRecorders = (state: RootState) => state.entities.video_recorders;
export const getVideoRecordersItems = (state: RootState) => state.entities.video_recorders.items;

export interface VideoRecorderAgg extends VideoRecorder {
  __owner_status?: OwnerStatusType;
}

export const createSelectVideoRecorderAggAsArray = (): GenericParametricSelector<
  PossibleIdProps,
  Array<VideoRecorderAgg>
> =>
  createSelector(
    [getVideoRecordersItems, getCompaniesItems],
    (videoRecorder, companies) => {
      return Object.values(videoRecorder).map((value: VideoRecorder): VideoRecorderAgg => {
        let result: VideoRecorderAgg = { ...value };
        const deviceOwnerId = value.device?.owner?.id;

        return {
          ...result,
          __owner_status:
            deviceOwnerId && !isEmpty(companies)
              ? {
                  name: ownerStatusMap[companies[deviceOwnerId].commercial_status].name,
                  color: ownerStatusMap[companies[deviceOwnerId].commercial_status].color,
                }
              : undefined,
        };
      });
    }
  );

export const createSelectVideoRecordAggAsMap = (): GenericParametricSelector<
  PossibleIdProps,
  Record<EntityId, VideoRecorderAgg>
> => {
  const selectVideoRecorderAggAsArray = createSelectVideoRecorderAggAsArray();

  return createSelector([selectVideoRecorderAggAsArray], (videoRecorder) => {
    return videoRecorder.reduce((acc, v) => {
      acc[v.id] = v;

      return acc;
    }, {} as Record<EntityId, VideoRecorderAgg>);
  });
};

export const createSelectVideoRecordAggAsState = (): GenericParametricSelector<
  PossibleIdProps,
  EntitiesState<VideoRecorderAgg>
> => {
  const selectCamerasAggAsMap = createSelectVideoRecordAggAsMap();

  return createSelector([getVideoRecorders, selectCamerasAggAsMap], (videoRecordersState, items) => {
    return {
      ...videoRecordersState,
      items,
    };
  });
};
