import React, { FC } from 'react';
import { ColorsEnum } from 'core/styles/vars';
import { Box, Dot } from './styles';

type IStatusProps = {
  isSuccessful: boolean;
};

export const Status: FC<IStatusProps> = ({ isSuccessful }) => {
  const color: ColorsEnum = isSuccessful ? ColorsEnum.normalApple : ColorsEnum.orangeNormal;
  const title = isSuccessful ? 'Успешно' : 'Ошибка';

  return (
    <Box>
      <Dot color={color} />
      {title}
    </Box>
  );
};
