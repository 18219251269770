import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface IconWrapperProps {
  size?: string;
  color?: string;
}

interface IIconProps extends IconWrapperProps {
  icon: ReactNode;
}

const IconWrapper = styled.i<IconWrapperProps>`
  display: inline-block;
  width: ${(props) => props.size && props.size};
  height: ${(props) => props.size && props.size};
  font-size: ${(props) => props.size && props.size};

  & svg {
    width: 1em;
    height: 1em;
    font-size: inherit;
    color: ${(props) => (props.color ? props.color : 'inherit')};
  }
`;

export const Icon: FC<IIconProps> = ({ icon, size = '16px', color }) => {
  return (
    <IconWrapper size={size} color={color}>
      {icon}
    </IconWrapper>
  );
};
