import React, { ReactNode } from 'react';
import { Breadcrumb, BreadcrumbProps, BreadcrumbSectionProps, Loader } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { BreadcrumbsConsumer, BreadcrumbsContext, Item } from './provider';

interface BreadcrumbsProps {
  static?: Array<Item>;
  size?: BreadcrumbProps['size'];
  divider?: ReactNode;
}

export class Breadcrumbs extends React.Component<BreadcrumbsProps> {
  static context = BreadcrumbsContext;

  render() {
    return (
      <BreadcrumbsConsumer>
        {(context) => <BreadcrumbsItems {...this.props} items={context.items} />}
      </BreadcrumbsConsumer>
    );
  }
}

interface BreadcrumbsItemsProps extends BreadcrumbsProps {
  items: Array<Item>;
  delay?: number;
}

class BreadcrumbsItems extends React.Component<BreadcrumbsItemsProps> {
  updateDebounced = debounce(this.forceUpdate, this.props.delay ? this.props.delay : 150);

  shouldComponentUpdate() {
    this.updateDebounced();
    return false;
  }

  render() {
    const items = Array.isArray(this.props.static)
      ? [...this.props.static, ...this.props.items]
      : this.props.items;
    const result: Array<ReactNode> = [];

    items.forEach((v, index) => {
      const props: BreadcrumbSectionProps =
        index === items.length - 1
          ? {
              active: true,
            }
          : {
              as: Link,
              to: v.url ?? '/',
            };

      const content = v.content || <Loader active inline size={'mini'} />;
      const section = (
        <Breadcrumb.Section key={v.id} {...props}>
          {content}
        </Breadcrumb.Section>
      );

      result.push(section);

      if (index < items.length - 1) {
        const divider = (
          <Breadcrumb.Divider key={index} className={cx({ active: index === items.length - 2 })}>
            {this.props.divider ?? '/'}
          </Breadcrumb.Divider>
        );

        result.push(divider);
      }
    });

    return <Breadcrumb size={this.props.size}>{result}</Breadcrumb>;
  }
}
