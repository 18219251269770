import React, { FC, useCallback, useState, useEffect } from 'react';
import { Intercom, DeviceReference, Company } from 'core/constants/contracts';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import {
  internetProviderOptions,
  actualOwnerOptions,
  schemaOptions,
} from '../../pages/intercoms/config';
import { useDispatch } from 'react-redux';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import {
  StrictDropdownItemProps,
  Button,
  Form,
  CheckboxProps,
  Grid,
  StrictDropdownProps,
  DropdownItemProps,
} from 'semantic-ui-react';
import { updateIntercom } from 'core/store/features/intercoms';
import { schema } from './schema';
import { Input, Dropdown, Checkbox, InputPassword } from 'components/share/form/formik';
import { FormButtonGroup } from 'components/share/form';
import { createSelectArrayToArray } from 'core/store/selectors';
import notification from 'core/utils/notification';
import { checkEmptyField } from 'core/utils/forms';
import { maxetlineSwitchMap, maxetlineSwitchSet } from '../utils';

interface IntercomEditFormProps {
  intercom: Intercom;
  companies: Record<string, Company>;
  deviceReferences: Record<string, DeviceReference>;
  handleBack: () => void;
}

interface FormValues
  extends Pick<Intercom, 'name' | 'actual_owner' | 'regional_billing_account' | 'serial_number'> {
  owner?: string;
  schema?: string;
  ip?: string;
  port?: string | number;
  is_mts_internet_provider: boolean;
  reference: string;
  ring_strategy: 'default' | 'apartment' | 'master' | 'none' | undefined;
  login?: string;
  password?: string;
  сapacity?: string;
  shift1?: string;
  range1?: string;
  shift2?: string;
  range2?: string;
}

const ringStrategyNone = {
  text: 'Не выбрано',
  value: 'none',
};

const toOptions = <T extends { id: string; name: string }>({ id, name }: T) => ({
  key: id,
  text: name,
  value: id,
});

const ringStrategyToOptions = (value: string) => ({
  key: value,
  text: value,
  value: value,
});

const IntercomEditForm: FC<IntercomEditFormProps> = ({
  intercom,
  companies,
  deviceReferences,
  handleBack,
}) => {
  const dispatch = useDispatch();
  const [ringStrategyOptions, setRingStrategyOptions] = useState<Array<StrictDropdownItemProps>>([
    ringStrategyNone,
  ]);

  useEffect(() => {
    const ringStrategyArray =
      deviceReferences[intercom.device.reference]?.payload?.ring_strategies || [];
    if (ringStrategyArray.length) {
      const newRingStrategyOptions = ringStrategyArray.map(ringStrategyToOptions);
      setRingStrategyOptions(newRingStrategyOptions);
    }
  }, [intercom, deviceReferences]);

  const companyOptions = createSelectArrayToArray<Company, StrictDropdownItemProps>(toOptions)(
    companies
  ) as DropdownItemProps[];
  const deviceReferenceOptions = createSelectArrayToArray<DeviceReference, StrictDropdownItemProps>(
    toOptions
  )(deviceReferences) as DropdownItemProps[];

  const handleSubmit = useCallback(
    async (
      {
        name,
        owner,
        schema,
        ip,
        port,
        actual_owner,
        is_mts_internet_provider,
        regional_billing_account,
        reference,
        serial_number,
        login,
        password,
        сapacity,
        shift1,
        range1,
        shift2,
        range2,
      }: FormValues,
      formikHelpers: FormikHelpers<FormValues>
    ) => {
      const data: Record<string, any> = {
        device: {
          reference,
          protocol: schema,
          hostname: ip,
          port: Number(port),
          owner,
        },
        api: {
          protocol: schema,
          hostname: ip,
          port: Number(port),
          username: login,
          password,
        },
        actual_owner: checkEmptyField(actual_owner),
        internet_provider: is_mts_internet_provider
          ? internetProviderOptions.mts
          : internetProviderOptions.other,
        regional_billing_account: checkEmptyField(regional_billing_account),
        serial_number: checkEmptyField(serial_number),
        name,
      };

      const selectedDeviceReferenceName = find(deviceReferences, { id: reference })?.name;
      const selectedMaxetLineMetacom =
        selectedDeviceReferenceName === maxetlineSwitchMap.maxetline_metacom;
      const selectedMaxetLine = maxetlineSwitchSet.has(selectedDeviceReferenceName || '');

      if (selectedMaxetLine) {
        data.device['additional_protocol_config'] = {
          'set-sip-config': {
            ...(selectedMaxetLineMetacom && сapacity !== '' && { capacity: +сapacity! }),
            ...(shift1 !== '' && { shift1: +shift1! }),
            ...(!isEmpty(range1) && { range1: range1!.replace(/\s+/g, '') }),
            ...(shift2 !== '' && { shift2: +shift2! }),
            ...(!isEmpty(range2) && { range2: range2!.replace(/\s+/g, '') }),
          },
        };
      }

      try {
        await dispatch(updateIntercom(intercom.id, data));
        notification.success();
        handleBack();
      } catch (error) {
        console.error(error);
        notification.error('При попытке сохранения данных произошла ошибка');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intercom.id, dispatch]
  );

  const formik = useFormik({
    initialValues: {
      name: intercom.name ?? intercom.phone,
      owner: intercom.device.owner?.id || '',
      schema: intercom.config?.control?.schema || 'http://',
      ip: intercom.config?.control?.host || '',
      port: intercom.config?.control?.port || '',
      actual_owner: intercom.actual_owner || undefined,
      is_mts_internet_provider: intercom.internet_provider === internetProviderOptions.mts,
      regional_billing_account: intercom.regional_billing_account || '',
      reference: intercom.device.reference || '',
      ring_strategy: intercom.ring_strategy || 'none',
      serial_number: intercom.serial_number || '',
      login: intercom.config?.control?.login || '',
      password: intercom.config?.control?.password || '',
      сapacity: String(intercom.device.additional_protocol_config?.['set-sip-config']?.capacity || 0),
      shift1: String(intercom.device.additional_protocol_config?.['set-sip-config']?.shift1 || ''),
      range1:
        intercom.device.additional_protocol_config?.['set-sip-config']?.range1?.replace(
          '-',
          ' - '
        ) || '',
      shift2: String(intercom.device.additional_protocol_config?.['set-sip-config']?.shift2 || ''),
      range2:
        intercom.device.additional_protocol_config?.['set-sip-config']?.range2?.replace(
          '-',
          ' - '
        ) || '',
    },
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const handleMtsInternet = (_props: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    if (!data.checked) {
      formik.setFieldValue('regional_billing_account', '');
    }
  };

  const handleDeviceReferences = (
    _event: React.SyntheticEvent<HTMLElement>,
    { value }: StrictDropdownProps
  ) => {
    const ringStrategyArray =
      deviceReferences[value?.toString() || '']?.payload?.ring_strategies || [];
    const newRingStrategyOptions = ringStrategyArray.map(ringStrategyToOptions);
    setRingStrategyOptions(newRingStrategyOptions);
    formik.setFieldValue('ring_strategy', newRingStrategyOptions[0].value);
  };

  const reference = formik.values.reference;
  const selectedDeviceReferenceName = find(deviceReferences, { id: reference })?.name;
  const selectedMaxetLineMetacom =
    selectedDeviceReferenceName === maxetlineSwitchMap.maxetline_metacom;
  const selectedMaxetLine = maxetlineSwitchSet.has(selectedDeviceReferenceName || '');

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Input name="name" label="Название" placeholder="Введите название" required />
            </Grid.Column>
            <Grid.Column>
              <Dropdown name="owner" label="Владелец" options={companyOptions} search />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column>
              <Dropdown name="schema" label="Schema" options={schemaOptions} />
            </Grid.Column>
            <Grid.Column>
              <Input name="ip" label="IP-адрес" placeholder="Введите IP-адрес" required />
            </Grid.Column>
            <Grid.Column>
              <Input name="port" label="Порт" placeholder="Введите порт" required />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column>
              <Dropdown
                name="actual_owner"
                label="Принадлежность панели"
                options={actualOwnerOptions}
                clearable
              />
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                name="is_mts_internet_provider"
                label="Принадлежит МТС"
                fieldLabel="Принадлежность интернета"
                toggle
                onChange={handleMtsInternet}
              />
            </Grid.Column>
            <Grid.Column>
              {formik.values.is_mts_internet_provider && (
                <Input
                  name="regional_billing_account"
                  label="ЛС регионального биллинга"
                  placeholder="Заполните ЛС регионального биллинга"
                />
              )}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column>
              <Dropdown
                name="reference"
                label="Поставщик оборудования"
                options={deviceReferenceOptions}
                onChange={handleDeviceReferences}
                required
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                name="ring_strategy"
                label="Стратегия вызова"
                options={ringStrategyOptions}
                disabled
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                name="serial_number"
                label="Серийный № панели"
                placeholder="Введите серийный № панели"
              />
            </Grid.Column>
          </Grid.Row>

          {selectedMaxetLineMetacom && (
            <Grid.Row columns={3}>
              <Grid.Column>
                <Input
                  name="сapacity"
                  label="Емкость коммутатора"
                  placeholder="Введите емкость коммутатора"
                />
              </Grid.Column>
            </Grid.Row>
          )}

          {selectedMaxetLine && (
            <Grid.Row columns={3}>
              <Grid.Column>
                <Input
                  name="shift1"
                  label="Сдвиг нумерации портов 1"
                  placeholder="Введите сдвиг нумерации портов"
                />
              </Grid.Column>
              <Grid.Column>
                <Input name="range1" label="Диапазон портов 1" placeholder="0 - 100" />
              </Grid.Column>
            </Grid.Row>
          )}

          {selectedMaxetLine && (
            <Grid.Row columns={3}>
              <Grid.Column>
                <Input
                  name="shift2"
                  label="Сдвиг нумерации портов 2"
                  placeholder="Введите сдвиг нумерации портов"
                />
              </Grid.Column>
              <Grid.Column>
                <Input name="range2" label="Диапазон портов 2" placeholder="0 - 100" />
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row columns={3}>
            <Grid.Column>
              <Input name="login" label="Логин" placeholder="Введите логин" required />
            </Grid.Column>
            <Grid.Column>
              <InputPassword name="password" label="Пароль" placeholder="Введите пароль" required />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <FormButtonGroup>
                <Button secondary onClick={handleBack}>
                  Отменить
                </Button>
                <Button type="submit" disabled={formik.isSubmitting} loading={formik.isSubmitting}>
                  Сохранить
                </Button>
              </FormButtonGroup>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default IntercomEditForm;
