import React, { PropsWithChildren } from 'react';
import { Grid, Header } from 'semantic-ui-react';

interface Props {}

export function ErrorMessage(props: PropsWithChildren<Props>) {
  const { children } = props;

  const message = React.isValidElement(children)
    ? children
    : children && children.toString
    ? children.toString()
    : children
    ? children + ''
    : '';
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} textAlign={'center'}>
          <Header as={'h4'}>{message}</Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
