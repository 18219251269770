import React, { useState } from 'react';
import { Grid, Header, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import BuildingGrid from './table';
import BuildingFilter, { FormData } from './filter';
import { CurrentUser } from 'core/constants/contracts';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import BuilderClientProvider from '../client.provider';

interface Props {
  currentUser: CurrentUser;
}

const defaultFilter: Readonly<Partial<FormData>> = {};

function BuildingListPage(props: Props) {
  const [filter, setFilter] = useState(defaultFilter);

  return (
    <Grid>
      <SetBreadcrumb>Дома</SetBreadcrumb>

      <Grid.Row columns={'equal'}>
        <Grid.Column>
          <Header as={'h1'}>Дома</Header>
        </Grid.Column>
        <Grid.Column textAlign={'right'}>
          <Button as={Link} primary to={'/buildings/create'}>
            Добавить дом
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <BuildingFilter filter={filter} defaultFilter={defaultFilter} setFilter={setFilter} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <BuilderClientProvider>
            <BuildingGrid filter={filter} />
          </BuilderClientProvider>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default BuildingListPage;
