import React from 'react';
import { Icon, Loader } from 'semantic-ui-react';

interface Props {
  pending: boolean;
  status?: boolean;
  messageStatusTrue: string;
  messageStatusFalse: string;
  messageStatusUnknown?: string;
}

export function TernaryStatus(props: Props) {
  const {
    pending,
    status,
    messageStatusFalse,
    messageStatusTrue,
    messageStatusUnknown = 'Неизвестно',
  } = props;

  if (pending) {
    return (
      <React.Fragment>
        <Loader active inline />
      </React.Fragment>
    );
  }
  if (status === true) {
    return (
      <React.Fragment>
        <Icon name={'check'} color={'green'} />
        {messageStatusTrue}
      </React.Fragment>
    );
  }

  if (status === false) {
    return (
      <React.Fragment>
        <Icon name={'times'} color={'red'} />
        {messageStatusFalse}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Icon name={'question'} color={'grey'} />
      {messageStatusUnknown}
    </React.Fragment>
  );
}
