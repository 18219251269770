import React from 'react';
import { Cell } from 'react-table';
import { Link } from 'react-router-dom';
import { Column } from 'components/share/components/table';
import { buildAddressStr } from 'core/utils/address';
import { ExtendedEntity } from 'core/store/selectors';
import { IntercomAgg } from 'core/store/selectors/intercoms';
import { PhoneStatus } from '../../../abstract/components/phone-status';
import styled from 'styled-components';
import { countDeviceBindings } from 'core/utils/bindings';
import { BindingType } from 'core/constants/contracts';
import { Tag } from 'components/share';

const viewUrl = (query?: URLSearchParams) => (id: string) => {
  return {
    pathname: `/barriers/${id}`,
    state: {
      tableParams: {
        page: query?.get('page'),
        size: query?.get('size'),
      },
    },
  };
};

const ItalicText = styled.span`
  font-style: italic;
`;
const Box = styled.div`
  display: inline-flex;
  align-items: center;
  & > div {
    margin-left: 1rem;
  }
`;
const TagStyled = styled(Tag)`
  &&&& {
    margin-left: 0.5rem;
  }
`;

export const createColumns = (query?: URLSearchParams) => {
  const columns: Array<Column<ExtendedEntity<IntercomAgg>>> = [
    {
      Header: 'Номер',
      Cell: ({ row: { original } }: Cell<IntercomAgg>) => {
        return (
          <PhoneStatus
            id={original.id}
            message={original.phone}
            isOnline={original.is_online}
            view={viewUrl(query)}
          />
        );
      },
      width: 150,
    },
    {
      Header: 'Владелец',
      Cell: ({ row: { original } }: Cell<IntercomAgg>) => {
        const owner = original.device.owner;
        if (owner) {
          return <Link to={`/companies/${owner.id}`}>{owner.name}</Link>;
        }

        return '';
      },
      width: 350,
    },
    {
      Header: 'Адрес',
      accessor: 'device',
      Cell: ({ row: { original } }) => {
        let renderBuildingCell = <ItalicText>(Не привязан к адресу)</ItalicText>;
        const buildingId = original.device.building?.id;
        const buildingBindingCount = countDeviceBindings(
          original.device.bindings,
          BindingType.building
        );

        if (original.has_bindings) {
          renderBuildingCell = (
            <Box>
              {buildingId ? (
                <Link to={`/buildings/${buildingId}/edit`}>{buildAddressStr(original.device)}</Link>
              ) : (
                buildAddressStr(original.device)
              )}
            </Box>
          );
        }

        return (
          <>
            {renderBuildingCell}
            {buildingBindingCount > 1 && (
              <TagStyled variant="grey" isInverted>
                {`+${buildingBindingCount - 1}`}
              </TagStyled>
            )}
          </>
        );
      },
    },
  ];

  return columns;
};
