import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { WebAPIResponse } from '../../../utils/webapi.contracts';
import { Action, EntitiesState, Entity, EntityId, Meta } from './contracts';
import { convertMetadata } from './handlers';

/*******************************
 *      Entities Reducers
 *******************************/

export function startEntitiesPendingReducer(
  state: Draft<EntitiesState<any>>
): Draft<EntitiesState<any>> {
  state.pending = true;

  return state;
}

export function stopEntitiesPendingReducer(state: EntitiesState<any>) {
  state.pending = false;

  return state;
}

export interface ReducerOptions<T extends Entity, Raw = any> {
  prepare?: (entity: Raw) => T;
}
export function successEntitiesReducerFactory<T extends Entity, Raw = T>(
  options?: ReducerOptions<T, Raw>
) {
  const prepare = options?.prepare;

  return function (
    state: EntitiesState<T>,
    action: PayloadAction<WebAPIResponse<T & Raw>, string, Meta>
  ): EntitiesState<T> {
    state.pending = false;
    state.error = undefined;
    state.metadata = convertMetadata(action.payload.metadata);

    if (action.meta && true === action.meta.renew) {
      state.items = {};
      state.ids = [];
    }

    for (let i = 0; i < action.payload.list.length; i++) {
      let entity: T = action.payload.list[i];
      if (prepare) {
        entity = prepare(action.payload.list[i]);
      }

      if (undefined === state.items[entity.id]) {
        state.ids.push(entity.id);
        state.items[entity.id] = entity;
      } else {
        state.items[entity.id] = {
          ...state.items[entity.id],
          ...entity,
        };
      }
    }

    return state;
  };
}

export function deleteEntityReducer(state: EntitiesState, action: PayloadAction<string>) {
  state.pending = false;
  state.error = undefined;
  // state.metadata = undefined;

  const entityId = action.payload;

  delete state.items[entityId];
  delete state.actions[entityId];
  state.ids = state.ids.filter((v) => v !== entityId);

  if (state.metadata) {
    // TODO recalculate metadata
  }

  return state;
}

export function failedEntitiesReducer(state: EntitiesState, action: PayloadAction) {
  state.pending = false;

  // state.items = {};
  // state.ids = [];
  state.error = action.payload;

  return state;
}

export function clearEntitiesReducer<T extends Entity>(state: EntitiesState<T>) {
  state.items = {};
  state.ids = [];
  state.pending = false;
  state.actions = {};
  state.error = undefined;
  state.metadata = undefined;

  return state;
}

export interface EntityActionPayload {
  id: EntityId;
  name: string;
  action: Partial<Action>;
}

export function actionEntityReducer(
  state: EntitiesState,
  action: PayloadAction<EntityActionPayload>
) {
  const entityId = action.payload.id;
  const actionName = action.payload.name;
  const actionAction = action.payload.action;

  if (state.items[entityId]) {
    if (state.actions[entityId] === undefined) {
      state.actions[entityId] = {};
    }

    state.actions[entityId][actionName] = {
      pending: actionAction.pending ?? false,
      status: actionAction.status ?? 'unknown',
      error: actionAction.error,
      data: actionAction.data,
    };
  }

  return state;
}

export interface EntityClearActionPayload {
  id: EntityId;
  name: string;
  unset?: boolean;
}

export function clearActionEntityReducer(
  state: EntitiesState,
  action: PayloadAction<EntityClearActionPayload>
) {
  const entityId = action.payload.id;
  const actionName = action.payload.name;
  const isUnset = action.payload.unset === true;

  if (state.items[entityId] && state.actions[entityId]?.[actionName]) {
    if (isUnset) {
      delete state.actions[entityId][actionName];
    } else {
      state.actions[entityId][actionName] = {
        pending: false,
        status: 'unknown',
        error: undefined,
        data: undefined,
      };
    }
  }

  return state;
}
