export const APP_NAME_COMPANY = 'Личный кабинет';
export const APP_NAME_SUPPORT = 'Личный кабинет';
export const APP_MENU_NAME_ADMIN = 'admin';
export const APP_MENU_NAME_SUPPORT = 'support';
export const APP_MENU_NAME_ADMIN_MTS = 'admin_mts';
export const PAGE_SIZES = [10, 30, 50, 100];
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_PAGE_NUMBER = 1;

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATE_TIME_SEC_FORMAT = 'DD.MM.YYYY HH:mm:ss';
export const MYSQL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const ENV_DEV = 'development';
export const ENV_PROD = 'production';

export const FLAG_DISABLE_ADD_CHILD = !!+process.env.REACT_APP_FLAG_DISABLE_ADD_CHILD;

export const WEB_PANEL_URL = (random_hash) =>
  `${process.env.REACT_APP_WEP_PANEL_URL}/${random_hash}`;
