import { Grid, Header } from 'semantic-ui-react';
import React, { FC, ReactNode } from 'react';
import { IntercomAgg } from 'core/store/selectors/intercoms';
import { DeleteIntercomBtn, IDeleteIntercomProps } from '../delete-intercom';
import { WepPanel } from '../web-panel';
import { IconButton } from 'components/share';
import { Settings } from 'components/share/assets';

type IntercomHeaderProps = {
  header: ReactNode;
  intercom: IntercomAgg;
  deleteProps: IDeleteIntercomProps;
  isDisabled: boolean;
};

export const IntercomHeader: FC<IntercomHeaderProps> = ({
  header,
  intercom,
  deleteProps,
  isDisabled,
}) => {
  return (
    <Grid.Row columns={2}>
      <Grid.Column>
        <Header as="h1">{header}</Header>
      </Grid.Column>
      <Grid.Column textAlign="right">
        {intercom.control_url && (
          <IconButton
            icon={<Settings />}
            secondary
            as={isDisabled ? 'button' : 'a'}
            target="_blank"
            href={intercom.control_url}
            disabled={isDisabled}
          >
            Настройки через Web-панель
          </IconButton>
        )}
        <WepPanel intercom={intercom} />
        <DeleteIntercomBtn {...deleteProps} />
      </Grid.Column>
    </Grid.Row>
  );
};
