import { createSlice } from '@reduxjs/toolkit';
import { Company } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createFetchEntity,
  createSaveEntity,
  createUpdateEntity,
} from './entities';
import api from '../../constants/api';

const slice = createSlice({
  name: 'companies',
  initialState: createEntitiesInitialState<Company>(),
  reducers: createDefaultEntitiesReducers<Company>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchCompanies = createFetchEntities(api.companies(), slice.actions);

export const fetchCompany = createFetchEntity(api.companies, slice.actions);

export const saveCompany = createSaveEntity(api.companies, slice.actions);

export const updateCompany = createUpdateEntity(api.companies, slice.actions);
