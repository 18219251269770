import 'react-datepicker/dist/react-datepicker.min.css';
import React, { FC, useCallback } from 'react';
import moment from 'moment';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Field, FieldProps } from 'formik';
import { BaseFormikFieldProps } from '../props';
import { FormFieldWrapperProps, FormFieldWrapper, useGetComponentProps } from '../field';
import { setFieldValue } from '../helpers';
import ru from 'date-fns/locale/ru';
import { DATE_FORMAT } from 'core/constants/app';
import calendar from 'components/share/assets/calendar.svg';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

type Props = OwnProps & FieldProps;

type OwnProps = FormFieldWrapperProps &
  Omit<ReactDatePickerProps, 'onChange' | 'dateFormat' | 'placeholderText'> & {
    placeholder?: string;
    enableKeyboard?: boolean;
    onChange?: (event: React.SyntheticEvent<HTMLElement>, date: Date | null) => void;
  };

const Icon = styled.i`
  &&&& {
    top: 12px !important;
  }

  & img {
    width: 1em;
    height: 1em;
    font-size: inherit;
  }
`;

const FormFieldWrapperStyled = styled(FormFieldWrapper)`
  &&&& .react-datepicker-wrapper {
    width: 100%;
  }
`;

const CalendarIcon = (
  <Icon className="icon">
    <img src={calendar} alt="Календарь" />
  </Icon>
);

const DatePickerControl: FC<Props> = (props) => {
  const {
    onChange: onChangeOrigin,
    placeholder,
    enableKeyboard,

    field,
    form,
    meta,

    ...rest
  } = useGetComponentProps(props);

  const handleChange = useCallback(
    (date: Date | null, event: React.SyntheticEvent<any, Event>) => {
      const value = date ? moment(date).format(DATE_FORMAT) : date;
      setFieldValue(form, field.name, value, true);
      onChangeOrigin?.(event, date);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, field.name]
  );

  const handleDateChangeRaw = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!enableKeyboard) {
      e.preventDefault();
    }
  };

  return (
    <FormFieldWrapperStyled {...props}>
      <ReactDatePicker
        locale={ru}
        dateFormat={'dd.MM.yyyy'}
        onChange={handleChange}
        onBlur={field.onBlur}
        selected={field.value ? moment(field.value, DATE_FORMAT).toDate() : null}
        disabled={props.disabled}
        placeholderText={placeholder}
        customInput={<Input icon={CalendarIcon} input={{ autocomplete: 'off' }} />}
        onChangeRaw={handleDateChangeRaw}
        {...rest}
      />
    </FormFieldWrapperStyled>
  );
};

export type DatePickerProps = BaseFormikFieldProps<ReactDatePickerProps['value']> & OwnProps;

export const DatePicker: FC<DatePickerProps> = ({ placeholder, ...props }) => {
  let newPlaceholder = placeholder;
  if (props.label && !newPlaceholder) {
    newPlaceholder = props.label.toString();
  }
  return <Field component={DatePickerControl} placeholder={newPlaceholder} {...props} />;
};
