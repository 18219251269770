import { MenuType } from './contracts';
import { CurrentCompany, CurrentUser } from '../constants/contracts';

export type MenuResolve = (
  currentUser: CurrentUser,
  currentCompany: CurrentCompany
) => Nullable<Array<MenuType>>;

export class MenuResolver {
  private resolvers: Array<MenuResolve> = [];

  add(value: MenuResolve): this {
    this.resolvers.push(value);
    return this;
  }

  remove(value: MenuResolve): this {
    const index = this.resolvers.findIndex((v) => v === value);
    if (index > -1) {
      this.resolvers.splice(index, 1);
    }

    return this;
  }

  clean(): this {
    this.resolvers = [];
    return this;
  }

  resolve(user: CurrentUser, company: CurrentCompany): Nullable<Array<MenuType>> {
    for (let ii = 0; ii < this.resolvers.length; ii++) {
      const resolver = this.resolvers[ii];

      const menu = resolver(user, company);
      if (menu) {
        return menu;
      }
    }

    return null;
  }
}

const menuResolver = new MenuResolver();

export default menuResolver;
