import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { modalClose } from './redux/action';
import ConfirmDialog from '../confirm-dialog';
import AlertDialog from '../alert-dialog';

const modalsMapDefault = {
  confirm: ConfirmDialog,
  alert: AlertDialog,
};

class ModalManager extends Component {
  render() {
    const { modalName, action, payload } = this.props;
    let modalsMap = modalsMapDefault;

    if (this.props.modalsMap) {
      modalsMap = this.props.modalsMap;
    }

    let modalComponent = null;

    if (action === 'open' && modalName) {
      if (typeof modalsMap[modalName] === 'undefined') {
        throw new Error(`Modal name "${modalName}" not found in mapping`);
      }
      let Modal = modalsMap[modalName];
      modalComponent = <Modal {...payload} onClose={this.props.onClose} />;
    }

    return modalComponent;
  }
}

ModalManager.propTypes = {
  modalName: PropTypes.string,
  action: PropTypes.string,
  payload: PropTypes.object,
  onClose: PropTypes.func,
  modalsMap: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    modalName: state.modals.name,
    action: state.modals.action,
    payload: state.modals.payload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: bindActionCreators(modalClose, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);
