import styled from 'styled-components';
import { Input, Dropdown } from 'components/share/form/formik';

export const FieldInputCSS = styled(Input)`
  min-width: 300px;
  margin-bottom: 0 !important;
  &&& > label,
  &&& > .hint {
    display: none;
  }
  &&& > div > i.icon {
    cursor: unset !important;
  }
`;

export const FieldDropdownCSS = styled(Dropdown)`
  min-width: 300px;
  &&& > label,
  &&& > .hint {
    display: none;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FieldsBox = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 3rem !important;
  }
`;
