import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';

function mapToOptions([key, value]) {
  const newKey = parseFloat(key);
  if (false === isNaN(newKey) && isFinite(newKey) && isNumber(newKey)) {
    key = newKey;
  }

  return {
    key: key,
    text: value,
    value: key,
  };
}

function checkEmptyField(value) {
  if (value === '' || value === undefined) {
    return null;
  }
  return value;
}

function removeEmptyValues(obj) {
  if (obj) {
    for (const key of Object.keys(obj)) {
      if (obj[key] === '') {
        delete obj[key];
      }
    }

    return obj;
  }
}

export { mapToOptions, checkEmptyField, removeEmptyValues };
