import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { selectBindingsAsArray } from 'core/store/selectors/bindings';
import { selectEntrancesAsArray } from 'core/store/selectors/entrances';

/**
 * Найти все типы биндингов, с родителским parentId на любом уровне
 *
 * @param {Object[]} bindings
 * @param {string} type
 * @param {string} parentId
 * @return {*}
 */
export function findThrough(bindings, type, parentId) {
  return bindings.filter((v) => {
    if (v.type === type && v.is_active && v.parent) {
      if (v.parent === parentId) {
        return true;
      }

      let temp = v;
      while (temp && temp.parent) {
        if (temp.parent === parentId) {
          return true;
        }

        // ToDo: Костыль - так делать не нужно
        // eslint-disable-next-line no-loop-func
        temp = bindings.find((v) => v.id === temp.parent);
      }
    }

    return false;
  });
}

const splitToSlices = (bindings = []) => {
  const result = [];

  for (let i = 0; i < bindings.length; i++) {
    const binding = bindings[i];
    const apartments = binding.apartments.sort((a, b) => a - b);
    const apartmentsLength = apartments.length;

    if (apartmentsLength === 0) {
      continue;
    }

    let startWith = binding.apartments[0];
    let prevNumber = binding.apartments[0];

    for (let k = 1; k < apartmentsLength; k++) {
      let nextNumber = binding.apartments[k];

      if (prevNumber + 1 !== nextNumber) {
        result.push({
          entrances_union: binding.entrances_union,
          entrance: binding.entrance,
          apartments: {
            from: startWith,
            to: prevNumber,
          },
          ids: binding.ids,
        });

        startWith = nextNumber;
      }

      prevNumber = nextNumber;
    }

    result.push({
      entrances_union: binding.entrances_union,
      entrance: binding.entrance,
      apartments: {
        from: startWith,
        to: prevNumber,
      },
      ids: binding.ids,
      poList: binding.poList,
    });
  }

  return result;
};

function selectIsTest(state, props) {
  return props.is_test ?? false;
}

export const calcEntrancesSelectorFactory = () => {
  return createSelector(
    [selectBindingsAsArray, selectEntrancesAsArray, selectIsTest],
    (bindings, entrancesPOs, isTestBuilding) => {
      const entrancesUnions = bindings.filter((v) => v.type === 'entrances_union');
      const entrances = bindings.filter((v) => v.type === 'entrance');

      const preparedBindings = entrances.map((v) => {
        const entranceUnion = entrancesUnions.find((x) => x.id === v.parent);
        const floors = findThrough(bindings, 'floor', v.id);
        const apartments = findThrough(bindings, 'apartment', v.id)
          // для тестовых строений отобразим все апартаменты
          .filter((v) => (isTestBuilding ? true : !v.is_test));
        const apartmentsNumbers = apartments.map((v) => +v.number);
        const apartmentsIds = apartments.map((v) => v.id);
        const poItem = entrancesPOs.find((item) => item.binding === v.id);

        return {
          entrances_union: entranceUnion ? entranceUnion.number : undefined,
          entrance: v.number,
          apartments: apartmentsNumbers,
          ids: {
            entrances_union: entranceUnion ? entranceUnion.id : undefined,
            entrance: v.id,
            floor: get(floors, '0.id'),
            apartments: apartmentsIds,
          },
          poList: {
            id: poItem?.id || '',
            numbers: poItem?.number_service_application || [],
          },
        };
      });

      return splitToSlices(preparedBindings);
    }
  );
};
