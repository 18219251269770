import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import { Form } from 'mts-ui';

const Dropdown = (props) => {
  const { input, meta = {}, onChange, onChangeOrigin, ...rest } = props;

  const { touched = false, error = '' } = meta;
  const hasError = !!(touched && error);

  const handleChange = (e, data) => {
    input.onChange(data.value);
    onChange && onChange(e, data);
    onChangeOrigin && onChangeOrigin(e, data);
  };

  return (
    <Form.FieldAdvanced
      control={SemanticDropdown}
      selection
      onChange={handleChange}
      value={input.value}
      error={hasError ? error : undefined}
      {...rest}
    />
  );
};

Dropdown.propTypes = {
  ...fieldPropTypes,
  onChangeOrigin: PropTypes.func,
};

export default Dropdown;
