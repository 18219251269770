import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { ThemeVars } from 'core/styles/vars';

interface Props {
  color: string;
  text: string;
  url: Partial<Location>;
  hintText?: string;
}

const OvalStatusCSS = styled(Link)`
  display: inline-flex;
  border: 1px solid ${ThemeVars.colors.linkWater};
  border-radius: 25px;
  padding: 0.25rem 0.75rem;
  line-height: 24px;
  font-size: 16px;
  align-items: center;

  & > i.icon {
    line-height: 24px;
    height: auto;
    margin-right: 0.5rem;
  }
`;

export const OvalStatus: FC<Props> = ({ color, text, url, hintText }) => {
  return (
    <OvalStatusCSS to={url}>
      <Popup
        trigger={<Icon name="circle" style={{ color }} size="small" />}
        content={hintText}
        position="left center"
        inverted
        disabled={!hintText}
      />
      {text}
    </OvalStatusCSS>
  );
};
