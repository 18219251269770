import React, { useCallback } from 'react';
import { RadioProps, Radio, StrictRadioProps } from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import { setFieldValue } from '../helpers';
import { BaseFormikFieldProps } from '../props';
import { FormFieldWrapper, FormFieldWrapperProps, useGetComponentProps } from '../field';
import styled from 'styled-components';

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

interface OwnProps
  extends FormFieldWrapperProps,
    Omit<StrictRadioProps, 'id' | 'label' | 'name' | 'onChange'> {
  onChange?: (event: React.FormEvent<HTMLInputElement>, data: RadioProps, props: Props) => void;
  fieldLabel?: string;
  options: Array<{
    key: string;
    name: string;
    value: any;
  }>;
}

type Props = OwnProps & FieldProps;

function CheckboxControl({ fieldLabel, ...props }: Props) {
  const { onChange: onChangeOrigin, field, form } = useGetComponentProps(props);

  const onChange = useCallback(
    (e, data) => {
      setFieldValue(form, field.name, data.value, true);
      onChangeOrigin?.(e, data, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field, form, onChangeOrigin, props]
  );

  return (
    <FormFieldWrapper {...props} label={fieldLabel}>
      <RadioGroupContainer>
        {props.options.map((option) => (
          <Radio
            key={option.key}
            name={field.name}
            label={option.name}
            value={option.value}
            checked={field.value === option.value}
            onChange={onChange}
            disabled={props.disabled}
          />
        ))}
      </RadioGroupContainer>
    </FormFieldWrapper>
  );
}

interface WrapProps extends BaseFormikFieldProps<StrictRadioProps['value']>, OwnProps {}

export function RadioGroup(props: WrapProps) {
  return <Field component={CheckboxControl} {...props} />;
}
