import { COMPANIES_REQUEST, COMPANIES_SUCCESS, COMPANIES_FAIL } from '../../../constants/actions';

const defaultState = {
  items: [],
  isFetching: false,
  error: null,
  metadata: {},
};

export default function companies(state = defaultState, action) {
  switch (action.type) {
    case COMPANIES_REQUEST: {
      return { ...state, isFetching: true };
    }
    case COMPANIES_SUCCESS: {
      return {
        items: action.payload.items,
        error: null,
        isFetching: false,
        metadata: action.payload.metadata,
      };
    }
    case COMPANIES_FAIL: {
      return { ...state, isFetching: false, error: action.payload.error };
    }
    default:
      break;
  }

  return state;
}
