import {
  DEVICE_REFERENCE_FAIL,
  DEVICE_REFERENCE_REQUEST,
  DEVICE_REFERENCE_SUCCESS,
} from '../../../constants/actions';

const defaultState = {
  item: {},
  isFetching: false,
  error: null,
};

export default function deviceReference(state = {}, action) {
  switch (action.type) {
    case DEVICE_REFERENCE_REQUEST: {
      return {
        ...state,
        [action.payload.id]: {
          ...defaultState,
          isFetching: true,
        },
      };
    }
    case DEVICE_REFERENCE_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          item: action.payload.item,
        },
      };
    }
    case DEVICE_REFERENCE_FAIL: {
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || {}),
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    default:
      break;
  }

  return state;
}
