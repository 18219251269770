import { get, set, unset, isObject, isString, isUndefined, isEmpty } from 'lodash';
import notification from './notification';
import { SubmissionError } from 'redux-form';

export const DEFAULT_ERROR_MESSAGE = 'Неизвестная ошибка';

export const extractErrorMessage = (err, defaultMessage = DEFAULT_ERROR_MESSAGE) => {
  let message;

  const tempError = get(err, 'response.data.error');
  if (tempError) {
    err = tempError;
  }

  if (err.data && typeof err.data === 'string') {
    message = err.data;
  } else if (err.message && typeof err.message === 'string') {
    message = err.message;
  } else if (err.data && typeof err.data === 'object') {
    message = getFirstError(err.data);
  } else {
    message = defaultMessage;
  }

  return message;
};

export const requestErrorHandlerForReduxFormSilent = (err) => {
  console.error(err);

  let error;

  if ('response' in err) {
    error = get(err, 'response.data.error.data');
  } else {
    error = err.data || err.message;
  }

  if (isString(error)) {
    return error;
  } else if (isObject(error)) {
    return Promise.reject(new SubmissionError(error));
  }

  return err;
};

export const requestErrorHandlerForReduxForm = (err, message = DEFAULT_ERROR_MESSAGE) => {
  console.error(err);

  let error;

  if ('response' in err) {
    error = get(err, 'response.data.error.data');
  } else {
    error = err.data || err.message;
  }

  if (isString(error)) {
    notification.error(error);
  } else if (isObject(error)) {
    return Promise.reject(new SubmissionError(error));
  } else {
    notification.error(message);
  }

  return err;
};

export const requestErrorHandler = (err, message = DEFAULT_ERROR_MESSAGE) => {
  console.error(err);

  let error;

  if ('response' in err) {
    error = get(err, 'response.data.error.data');
  } else {
    error = err.data || err.message;
  }

  if (isString(error)) {
    notification.error(error);
  } else {
    let errorMessage;
    if (isObject(error) && !isEmpty(error)) {
      errorMessage = message = Object.entries(error)[0][1];
    }

    notification.error(errorMessage || message);
  }

  return err;
};

/**
 * Делаем "плоской" ошибки SubmissionError, т.е. берет первую ошибку из объекта и делает объект строкой
 * Пример;
 *
 * До:
 * SubmissionError
 * {
 *     errors: {
 *         address: {
 *             text: "Укажите адресс",
 *             id: "Укажите ID"
 *         }
 *         x: {
 *             y: {
 *                 z: 'Message'
 *             }
 *         }
 *     }
 * }
 *
 * submissionErrorFlatten(e, [address, x.y])
 * После:
 * SubmissionError
 * {
 *     errors: {
 *         address: "Укажите адресс",
 *         x: {
 *             y: 'Message'
 *         }
 *     }
 * } *
 * @param e
 * @param fields
 * @return {SubmissionError|*}
 */
export const submissionErrorFlatten = (e, fields) => {
  if (!(e instanceof SubmissionError)) {
    return e;
  }

  const errors = { ...e.errors };

  fields.forEach((field) => {
    const newError = getFirstError(get(errors, field));
    if (isUndefined(newError)) {
      unset(errors, field);
    } else {
      set(errors, field, newError);
    }
  });

  return new SubmissionError(errors);
};

export function getFirstError(error) {
  if (false === isObject(error)) {
    return error;
  }

  const errorKeys = Object.keys(error);
  if (errorKeys.length === 0) {
    return undefined;
  }

  const test = error[errorKeys[0]];
  if (isObject(test)) {
    return getFirstError(test);
  }

  return test;
}
