import React from 'react';
import { Company, CompanyAdministrativeStatus } from 'core/constants/contracts';
import { COMPANY_ADMINISTRATIVE_STATUS_MAP } from 'core/constants/domain/companies';
import { Link } from 'react-router-dom';
import { LabelStatus } from 'components/share/components/status/label';
import { CommercialStatus } from './components/commercial-status';
import { Column } from 'components/share/components/table';
import { ThemeVars } from '../../../../core/styles/vars';

export const createColumns = (query?: URLSearchParams): Array<Column<Company>> => {
  return [
    {
      Header: 'Наименование',
      accessor: 'name',
      Cell: ({ cell: { value }, row: { original } }) => {
        return (
          <Link
            className={'font-weight-medium'}
            to={{
              pathname: `/companies/${original.id}`,
              state: {
                tableParams: {
                  page: query?.get('page'),
                  size: query?.get('size'),
                },
              },
            }}
          >
            {value}
          </Link>
        );
      },
    },
    {
      Header: 'Административный статус',
      accessor: 'administrative_status',
      Cell: ({ cell: { value }, row: { original } }) => {
        const text =
          COMPANY_ADMINISTRATIVE_STATUS_MAP[
            value as keyof typeof COMPANY_ADMINISTRATIVE_STATUS_MAP
          ];

        if (text && value === CompanyAdministrativeStatus.active) {
          return <LabelStatus color={ThemeVars.colors.normalApple}>{text}</LabelStatus>;
        }

        if (text && value === CompanyAdministrativeStatus.blocked) {
          return <LabelStatus color={ThemeVars.colors.orangeNormal}>{text}</LabelStatus>;
        }

        if (text && value === CompanyAdministrativeStatus.archive) {
          return <LabelStatus color={ThemeVars.colors.greyRaven}>{text}</LabelStatus>;
        }

        return (
          <LabelStatus
            iconName={'question circle'}
            iconSize={'large'}
            color={ThemeVars.colors.midnightBlue}
          >
            Неизвестно
          </LabelStatus>
        );
      },
    },
    {
      Header: 'Коммерческий статус',
      accessor: 'commercial_status',
      Cell: ({ cell: { value } }) => {
        if (!value) {
          return (
            <LabelStatus
              iconName={'question circle'}
              iconSize={'large'}
              color={ThemeVars.colors.midnightBlue}
            >
              Неизвестно
            </LabelStatus>
          );
        }

        return <CommercialStatus status={value} />;
      },
    },
    {
      Header: 'Лицевой счет',
      accessor: 'personal_account',
      Cell: ({ cell: { value } }) => value || '-',
    },
  ];
};
