import React from 'react';
import cx from 'classnames';
import './styles.less';

interface Props {
  play: boolean;
  onClick?: () => void;
}

export function PlayToggle(props: Props) {
  const icon = props.play ? (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33333 15.8332H5V4.1665H8.33333V15.8332ZM11.6667 15.8332V4.1665H15V15.8332H11.6667Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.66797 4.1665V15.8332L15.8346 9.99984L6.66797 4.1665Z" fill="white" />
    </svg>
  );

  return (
    <div
      className={cx('video-archive-play-toggle', 'control', props.play ? 'play' : 'pause')}
      onClick={props.onClick}
    >
      {icon}
    </div>
  );
}
