import React, { FC, useState } from 'react';
import { Grid, DropdownItemProps } from 'semantic-ui-react';
import { Filter as FilterIcon } from 'components/share/assets';
import { ButtonStyled, ButtonSimple, FiltersBlock, FiltersContainer } from './styles';
import { withFormik, FormikProps } from 'formik';
import { FilterValues } from '..';
import { FilterModal } from './modal';
import { FilterChip } from './filterChip';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'core/store';

export type OwnProps = ConnectedProps<typeof withConnect> & {
  filter?: FilterValues | undefined;
  setFilter: (values: FilterValues) => void;
};

type Props = OwnProps & FormikProps<FilterValues>;

const initialFilterValues = {
  sip: '',
  name: '',
  owner_id: '',
  address: '',
  actual_owner: '',
  internet_provider: '',
  regional_billing_account: '',
  serial_number: '',
};

const IntercomsFilterInner: FC<Props> = ({
  filter,
  dirty,
  setFilter,
  handleSubmit,
  resetForm,
  setFieldValue,
  // Redux store
  companiesOptions,
  companiesLoading,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    handleModal();
    handleSubmit();
  };

  const handleClear = () => {
    setFilter({});
    resetForm();
  };

  const handleClearCurrent = (filterName: keyof FilterValues) => () => {
    if (filter) {
      const { [filterName]: deletedValue, ...newValues } = filter;
      setFilter(newValues);
    }
    setFieldValue(filterName, '');
  };

  const buttonOpenModal = (e: React.FocusEvent<HTMLButtonElement>) => {
    e.target.blur();
    handleModal();
  };

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <ButtonStyled onClick={buttonOpenModal}>
            <FilterIcon />
            Фильтр
          </ButtonStyled>
        </Grid.Column>
      </Grid.Row>

      {filter && Object.keys(filter).length !== 0 && (
        <Grid.Row>
          <Grid.Column>
            <FiltersBlock>
              <FiltersContainer>
                {(Object.keys(filter) as Array<keyof FilterValues>).map((item) => (
                  <FilterChip
                    key={item}
                    filter={filter}
                    filterItem={item}
                    companiesOptions={companiesOptions}
                    onClose={handleClearCurrent(item)}
                  />
                ))}
              </FiltersContainer>
              <ButtonSimple onClick={handleClear}>Очистить все</ButtonSimple>
            </FiltersBlock>
          </Grid.Column>
        </Grid.Row>
      )}

      <FilterModal
        open={openModal}
        onClose={handleModal}
        onSubmit={onSubmit}
        dirty={dirty}
        companies={{ companiesOptions, companiesLoading }}
      />
    </>
  );
};

const IntercomsFilter = withFormik<OwnProps, FilterValues>({
  mapPropsToValues: () => initialFilterValues,
  handleSubmit: (values, { props }) => {
    let filter: FilterValues = {};

    (Object.keys(values) as Array<keyof FilterValues>).forEach((item) => {
      if (values[item] && values[item] !== '') {
        filter[item] = values[item];
      }
    });

    props.setFilter(filter);
  },
})(IntercomsFilterInner);

const mapStateToProps = (state: RootState) => {
  const companiesMap = state.entities.companies.items;
  const companiesOptions = Object.keys(companiesMap).map((key) => ({
    key,
    text: companiesMap[key].name,
    value: key,
  })) as DropdownItemProps[];

  return {
    companiesOptions,
    companiesLoading: state.entities.companies.pending,
  };
};

const withConnect = connect(mapStateToProps);

export default withConnect(IntercomsFilter);
