import { MODAL_OPEN } from './modal-manager/redux/constants';

let dispatchCb;

export const configure = ({ dispatch }) => {
  dispatchCb = dispatch;
};

export function alert({ message, title = 'Внимание!', onClose = null }) {
  dispatchCb({
    type: MODAL_OPEN,
    name: 'alert',
    payload: {
      message,
      title,
      onClose,
      open: true,
    },
  });
}

export function confirm({
  content,
  header = 'Подтверждение операции',
  onConfirm = null,
  onClose = null,
  cancelButton = null,
  confirmButton = null,
}) {
  dispatchCb({
    type: MODAL_OPEN,
    name: 'confirm',
    payload: {
      content,
      header,
      confirmCallback: onConfirm,
      closeCallback: onClose,
      open: true,
      cancelButton,
      confirmButton,
    },
  });
}
