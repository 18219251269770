import React, { FC } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import notification from 'core/utils/notification';
import { Camera } from 'core/constants/contracts';
import { request } from 'core/utils/request';
import api from 'core/constants/api';
import { useDispatch } from 'react-redux';
import { confirm } from 'components/share/dialogs';
import { CameraAgg } from 'core/store/selectors/cameras';
import { fetchData } from 'components/share/containers/camera';

interface Props {
  cameraId: CameraAgg['id'];
}

export const CameraAddressUnset: FC<Props> = ({ cameraId }) => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const data = {
      device: { bindings: [] },
    };

    request
      .put<Camera>(api.cameras(cameraId), data)
      .then(() => {
        notification.success();
        // ToDo: Костыль для обновления таблицы
        dispatch(fetchData({ limit: 30, page: 1, sort: '_id' }));
      })
      .catch((err) => notification.error(err.message));
  };

  const toggleModal = () => {
    confirm({
      content: 'Вы уверены, что хотите отвязать устройство от адреса?',
      onConfirm: handleSubmit,
    });
  };

  const trigger = (
    <Button secondary icon onClick={toggleModal}>
      <Icon name="unlink" />
    </Button>
  );

  return <Popup position="top right" content="Отвязать камеру" trigger={trigger} inverted />;
};
