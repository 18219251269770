import {
  INTERCOM_USERS_REQUEST,
  INTERCOM_USERS_SUCCESS,
  INTERCOM_USERS_FAIL,
} from '../../../../constants/actions';

const defaultUsersState = {
  items: [],
  error: null,
  metadata: {},
  isFetching: false,
};

export default function users(state = {}, action) {
  switch (action.type) {
    case INTERCOM_USERS_REQUEST: {
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || defaultUsersState),
          isFetching: true,
        },
      };
    }
    case INTERCOM_USERS_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          items: action.payload.items,
          metadata: action.payload.metadata,
          isFetching: false,
          error: null,
        },
      };
    }
    case INTERCOM_USERS_FAIL: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          error: action.payload.error,
          isFetching: false,
        },
      };
    }
    default:
      break;
  }

  return state;
}
