import React, { FC } from 'react';
import './style.less';
import { XlsIcon, Close } from './assets';
import { Progress } from 'semantic-ui-react';
import { UploadConfig } from 'components/share';

interface DroppedFileProps {
  file: File;
  uploadConfig?: UploadConfig;
  onRemove: (file: File) => void;
}

const getFileIcon = (fileType: string) => {
  switch (fileType) {
    case 'text/csv':
    case 'application/vnd.ms-excel':
      return <XlsIcon />;
    default:
      return null;
  }
};

const DroppedFile: FC<DroppedFileProps> = ({ file, uploadConfig, onRemove }) => {
  const remove = () => onRemove(file);
  return (
    <div className="dropped__root">
      {getFileIcon(file.type)}
      <div className="dropped__content">
        <span className="dropped__name">{file.name}</span>
        {uploadConfig &&
          (uploadConfig.error ? (
            <span className="dropped__progress">Ошибка загрузки</span>
          ) : (
            <Progress className="dropped__progress" size="tiny" percent={uploadConfig.percent} />
          ))}
      </div>
      <Close onClick={remove} />
    </div>
  );
};

const propsAreEqual = (oldProps: DroppedFileProps, newProps: DroppedFileProps) => {
  const sameFile = newProps.uploadConfig
    ? !(newProps.uploadConfig.fileName === newProps.file.name)
    : true;

  const result =
    oldProps.file === newProps.file && oldProps.onRemove === newProps.onRemove && sameFile;

  return result;
};

export default React.memo(DroppedFile, propsAreEqual);
