import { StrictDropdownItemProps } from 'semantic-ui-react';
import { Binding, BindingType, Building } from 'core/constants/contracts';

export interface Entity {
  id: string;
}

export function toMap<T extends Entity>(list: Array<T>): Record<string, T> {
  return list.reduce((acc, v) => {
    acc[v.id] = v;
    return acc;
  }, {} as Record<string, T>);
}

export function buildingsToOptions(buildings: Array<Building>): Array<StrictDropdownItemProps> {
  const result = [];
  for (let i = 0; i < buildings.length; i++) {
    const v = buildings[i];

    result.push({
      value: v.id,
      text: v.address.text,
    });
  }

  return result;
}

interface Type {
  type: BindingType;
  parent?: string;
}

export function bindingsToOptions(bindings: Array<Binding>): Array<StrictDropdownItemProps & Type> {
  const result = [];
  for (let i = 0; i < bindings.length; i++) {
    const v = bindings[i];

    result.push({
      value: v.id,
      text: v.number,
      type: v.type as BindingType,
      parent: v.parent as string,
    });
  }

  return result;
}
