import { createSlice } from '@reduxjs/toolkit';
import api from '../../constants/api';
import { CompanyService } from 'core/constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
} from './entities';

const slice = createSlice({
  name: 'company_services',
  initialState: createEntitiesInitialState<CompanyService>(),
  reducers: createDefaultEntitiesReducers<CompanyService>(),
});

export default slice;

export const { pending, success, failed, clear, deleted } = slice.actions;

export const fetchCompaniesWithService = createFetchEntities<CompanyService>(
  api.companiesWithService,
  slice.actions
);
