export type EntityId = string;

export interface Entity {
  id: EntityId;
}

export interface EntitiesState<T extends Entity = Entity> {
  items: Record<EntityId, T>;
  ids: Array<EntityId>;
  pending: boolean;
  error: Nullable<any>;
  actions: Record<EntityId, Actions>;
  metadata: Nullable<EntitiesMetadata>;
}

export interface Meta {
  name?: string;
  renew?: boolean;
  ref_normalize?: boolean;
}

export interface EntitiesMetadata {
  pageIndex: number;
  pageSize: number;
  pageCount: number;
  total: number;
  [key: string]: any; // В metadata могут быть прокинуты любые данные
}

export type ActionType = string;
export type Actions = Record<ActionType, Action>;
export type ActionStatus = 'success' | 'failed' | 'unknown';

export interface Action<T = any> {
  status: ActionStatus;
  pending: boolean;
  error?: string;
  data?: T;
}

/*****************************
 *       УЛУЧШЕНИЯ
 ****************************/

// TODO
enum TODO__Status {
  unknown = 0,
  fetching = 1,
  pending = 2,
  success = 4,
  error = 8, // а нужен ли этот статус
}

// TODO: псевдо статус
export function TODO__isLoading(status: TODO__Status) {
  return (status & (TODO__Status.fetching | TODO__Status.pending)) !== 0;
}

// TODO
export interface TODO__EntitiesState<T extends Entity = Entity> {
  items: Record<EntityId, T>;
  ids: Array<EntityId>;
  status: TODO__Status;
  // если лшибка при получении, то ничего не показываем на странице, кроме ошибки
  error_fetching?: string;
  // а тут можно показывать страницу и ошибку "обновление не удалось"
  // т.е. это для операций обновления/добавления
  error_pending?: string;
  actions?: Record<EntityId, TODO__Actions>;
  metadata?: EntitiesMetadata;
}

export type TODO__Actions = Record<ActionType, TODO__Action>;

export interface TODO__Action<T = any> {
  // статуста init скорее всего никога не будет
  status: TODO__Status;
  error?: string;
  data?: T;
}
