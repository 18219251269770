import { createSlice } from '@reduxjs/toolkit';
import { Apartment, ApartmentRaw, isBuildingObject } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createDeleteEntityWithCustomUrl,
  createEntitiesInitialState,
  createFetchEntities,
  createFetchEntity,
  createUpdateEntity,
  createUpdateEntityWithCustomUrl,
} from './entities';
import api from '../../constants/api';
import { swap_parent } from './users';
import notification from 'core/utils/notification';

const options = {
  prepare: function (entity: ApartmentRaw): Apartment {
    const { binding } = entity;
    if (!binding) {
      const errorMessage = `Нарушена целостность данных для Квартиры с ID: ${entity.id}. Отсутствует привязка к адресу.`;
      notification.error(errorMessage);
      console.error(errorMessage);
    }

    const building = isBuildingObject(binding.building) ? binding.building.id : binding.building;

    return {
      ...entity,
      binding: binding?.id || '',
      building: building,
      bindingObj: binding,
    };
  },
};

const slice = createSlice({
  name: 'apartments',
  initialState: createEntitiesInitialState<Apartment>(),
  reducers: createDefaultEntitiesReducers<Apartment, ApartmentRaw>(options),
  extraReducers: (builder) =>
    builder.addCase(swap_parent, (state, action) => {
      if (action.payload.prevParentId) {
        const prevApartmentsIds = state.ids.filter(
          (id) => state.items[id].admin === action.payload.prevParentId
        );
        prevApartmentsIds.forEach((id) => {
          state.items[id].admin = action.payload.nextParentId;
        });
      }
    }),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchApartments = createFetchEntities(api.apartments(), slice.actions);

export const fetchApartment = createFetchEntity(api.apartments, slice.actions);

export const updateApartment = createUpdateEntity(api.apartments, slice.actions);

export const blockCalls = createUpdateEntityWithCustomUrl(api.blockApartmentUser, slice.actions)
export const unblockCalls = createDeleteEntityWithCustomUrl(api.blockApartmentUser, slice.actions)


