import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  features: object;
  pending: boolean;
  error?: string;
}

const featuresSlice = createSlice({
  name: 'features',
  initialState: {
    pending: false,
    features: {},
  } as State,
  reducers: {
    pending: (state) => {
      state.pending = true;
      return state;
    },
    success: (state, action: PayloadAction<any>) => {
      state.pending = false;
      state.features = action.payload;
      return state;
    },
    failed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      return state;
    },
  },
});

export default featuresSlice;
