import { PromiseThunk } from 'core/store';
import { fetchUser } from 'core/store/features/users';
import { getApartmentsItems } from 'core/store/selectors/apartments';
import { fetchTokens } from 'core/store/features/tokens';
import { fetchApartments } from 'core/store/features/apartments';
import { fetchIntercoms } from 'core/store/features/intercoms';
import { fetchBuildings } from 'core/store/features/buildings';
import { startSectionLoading, stopSectionLoading } from 'core/store/features/app';
import { Apartment } from 'core/constants/contracts';

export const LOADER_SECTION_NAME = 'client-info';

interface Params {
  userId: string;
  tokensLimit: number;
}

export const fetchData = ({ userId, tokensLimit }: Params): PromiseThunk => {
  return (dispatch, getState) => {
    dispatch(startSectionLoading(LOADER_SECTION_NAME));

    const promises = [
      dispatch(fetchUser(userId)),
      dispatch(
        fetchTokens({
          filter: { user: userId },
          limit: tokensLimit,
          sort: '-created_at',
        })
      ),
      dispatch(fetchApartments({ filter: { users: userId } })),
    ] as const;

    return Promise.all(promises)
      .then(() => {
        const apartments = Object.values(getApartmentsItems(getState()));

        const devices: Apartment['devices'] = [];
        const buildings: Apartment['building'][] = [];

        apartments.forEach((apartment) => {
          devices.push(...apartment.devices);
          if (apartment.building) {
            buildings.push(apartment.building);
          }
        });

        if (devices.length !== 0 && buildings.length !== 0) {
          const promises = [
            dispatch(
              fetchIntercoms({
                filter: { device: [...new Set(devices)] },
              })
            ),
            dispatch(
              fetchBuildings({
                filter: { _id: [...new Set(buildings)] },
              })
            ),
          ] as const;

          return Promise.all(promises).catch((e) => console.error(e));
        }

        return;
      })
      .catch((e) => console.error(e))
      .finally(() => dispatch(stopSectionLoading(LOADER_SECTION_NAME)));
  };
};
