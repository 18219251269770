import { createSlice } from '@reduxjs/toolkit';
import { Restreamer } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createFetchEntity,
} from './entities';
import api from '../../constants/api';

const slice = createSlice({
  name: 'restreamers',
  initialState: createEntitiesInitialState<Restreamer>(),
  reducers: createDefaultEntitiesReducers<Restreamer>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchRestreamers = createFetchEntities<Restreamer>(api.restreamer(), slice.actions);
export const fetchRestreamer = createFetchEntity<Restreamer>(api.restreamer, slice.actions);
