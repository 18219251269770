import {
  USER_SUCCESS,
  USER_FAIL,
  USER_REQUEST,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_SUCCESS,
} from '../../../constants/actions';

const defaultState = {
  item: {},
  metadata: {},
  isFetching: false,
};

export default function user(state = {}, action) {
  switch (action.type) {
    case USER_REQUEST:
    case USER_DELETE_REQUEST:
    case USER_ACTIVATE_REQUEST:
    case USER_UPDATE_REQUEST:
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || defaultState),
          isFetching: true,
          error: null,
        },
      };
    case USER_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          error: null,
          item: action.payload.user,
          metadata: action.payload.metadata,
        },
      };
    case USER_FAIL:
    case USER_DELETE_FAIL:
    case USER_ACTIVATE_FAIL:
    case USER_UPDATE_FAIL:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFetching: false,
          error: action.payload.error,
        },
      };
    case USER_DELETE_SUCCESS: {
      let newState = state;
      delete newState[action.payload.id];

      return { ...newState };
    }
    case USER_ACTIVATE_SUCCESS:
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || {}),
          isFetching: false,
          error: null,
          item: action.payload.user,
          metadata: action.payload.metadata,
        },
      };
    default:
      return state;
  }
}
