import { METRIC_FAIL, METRIC_REQUEST, METRIC_SUCCESS } from '../../../constants/actions';

export default function metrics(state = {}, action) {
  switch (action.type) {
    case METRIC_REQUEST: {
      return {
        ...state,
        [action.payload.id]: {
          [action.payload.name]: {
            item: {},
            isFetching: true,
          },
        },
      };
    }
    case METRIC_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          [action.payload.name]: {
            item: action.payload.item,
            isFetching: false,
          },
        },
      };
    }
    case METRIC_FAIL: {
      return {
        ...state,
        [action.payload.id]: {
          [action.payload.name]: {
            ...state[action.payload.id][action.payload.name],
            isFetching: false,
            error: action.payload.error,
          },
        },
      };
    }
    default:
      break;
  }

  return state;
}
