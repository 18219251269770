import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Grid, Header } from 'semantic-ui-react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { CreateBarrierContainer } from './container';

interface DeviceCreateProps extends RouteComponentProps {}

export default class DeviceCreate extends React.Component<DeviceCreateProps> {
  render() {
    return (
      <Grid>
        <SetBreadcrumb url="/barriers">Шлагбаумы</SetBreadcrumb>
        <SetBreadcrumb>Добавить шлагбаум</SetBreadcrumb>

        <Grid.Row columns={1}>
          <Grid.Column>
            <Header as="h1">Добавить шлагбаум</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <CreateBarrierContainer {...this.props} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
