import React, { FC } from 'react';
import { Button, Divider, Form } from 'semantic-ui-react';
import { FormikProps, withFormik, useFormikContext } from 'formik';
import { BindingType, Camera } from 'core/constants/contracts';
import { FormButtonGroup } from 'components/share/form';
import { AddressBinding } from 'components/share/form/formik/address/renderer/address-binding';
import { schema } from './schema';
import { Checkbox } from 'components/share/form/formik';

export interface FormValues {
  isMain: boolean;
  [BindingType.building]: string;
  [BindingType.entrance]: string;
}

interface OwnProps {
  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
  camera: Camera;
}

interface Props extends OwnProps, FormikProps<FormValues> {}

const InnerAddressSetForm: FC<Props> = ({ onCancel, handleSubmit, setFieldValue }) => {
  const { isValid } = useFormikContext<FormValues>();

  return (
    <Form onSubmit={handleSubmit}>
      <Checkbox name="isMain" label="Основная камера для звонков" toggle />

      <Divider />

      <AddressBinding setFieldValue={setFieldValue} deviceType="camera" />

      <FormButtonGroup>
        <Button secondary onClick={onCancel}>
          Отменить
        </Button>
        <Button type="submit" disabled={!isValid}>
          Сохранить
        </Button>
      </FormButtonGroup>
    </Form>
  );
};

const AddressSetForm = withFormik<OwnProps, FormValues>({
  mapPropsToValues: ({ camera }) => {
    const isMain = camera.device?.sort_order === 1;
    return {
      isMain,
      building: '',
      entrance: '',
    };
  },
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    if (props.onSubmit) {
      props.onSubmit(values);
    }
  },
})(InnerAddressSetForm);

export default AddressSetForm;
