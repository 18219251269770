import React from 'react';
import { Button, Dimmer, Form, Icon, Loader, Message, Segment } from 'semantic-ui-react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import OperationManager from 'core/utils/operation';
import { createBuild, reportCompleted } from 'core/store/actions/reports';
import FilterFactory from './filter-factory';
import { RootState } from 'core/store';
import { ReportColumn, ReportFilter } from 'core/constants/contracts';
import { SemanticWIDTHSNUMBER } from 'semantic-ui-react/dist/commonjs/generic';

export const FORM_ID = 'report_form';

interface FormData {}

interface OwnProps {
  initialValues: object;
  alias: string;
  columns: Array<ReportColumn>;
  filters: Array<ReportFilter>;
  name: string;
  fieldsPerRow?: SemanticWIDTHSNUMBER;
}

interface Props
  extends OwnProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

interface State {
  elements: Nullable<Array<React.ReactElement>>;
}

type FinalProps = Props & InjectedFormProps<FormData, Props>;

class ReportForm extends React.Component<FinalProps, State> {
  state = {
    elements: null,
  };

  static defaultProps: Partial<OwnProps> = {
    fieldsPerRow: 3,
  };

  componentDidMount() {
    this.setState({ elements: this.createFilter() });
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.filters !== this.props.filters) {
      this.setState({ elements: this.createFilter() });
    }

    if (
      this.props.build &&
      this.props.build.url &&
      prevProps.build &&
      // prevProps.build.url &&
      this.props.build.url !== prevProps.build.url
    ) {
      window.open(this.props.build.url);
    }
  }

  renderButton = () => {
    return (
      <Form.Field className={'center-vertically'} key="submit-btn">
        <Button type="submit" primary>
          Скачать
          <Icon name="download" className={'right'} />
        </Button>
      </Form.Field>
    );
  };

  createFilter = () => {
    const elements = this.props.filters.map((v, i) => <FilterFactory filter={v} key={i} />);
    elements.push(this.renderButton());

    return elements
      .reduce((acc, v, index) => {
        if (index % this.props.fieldsPerRow! === 0) {
          acc.push([]);
        }

        acc[acc.length - 1].push(v);

        return acc;
      }, [] as Array<Array<React.ReactElement>>)
      .map((v, i) => (
        <Form.Group widths={this.props.fieldsPerRow!} key={i}>
          {v}
        </Form.Group>
      ));
  };

  handleSave = async (values: any) => {
    const criteria = this.props.filters.reduce((acc, filter) => {
      acc[filter.id] = values[filter.id];

      return acc;
    }, {} as Record<string, any>);

    OperationManager.register('report.completed', (event: any) => {
      this.props.reportCompleted({
        url: event.payload.url,
        error: event.payload.error,
      });
    });

    await this.props.createBuild({
      alias: this.props.alias,
      columns: [],
      criteria,
    });
  };

  render() {
    let error = this.props.build?.error || '';

    const { handleSubmit, building } = this.props;

    return (
      <React.Fragment>
        <Segment padded={'very'} className={'block'}>
          <Dimmer.Dimmable dimmed={building}>
            <Dimmer active={building} inverted>
              <Loader />
            </Dimmer>

            <Form onSubmit={handleSubmit(this.handleSave)} error={error.length > 0}>
              {this.state.elements}
            </Form>
          </Dimmer.Dimmable>
        </Segment>

        {/* TODO сообщил дизайнеру, что нужны ошибки */}
        {error.length > 0 && <Message error content={error} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
  return {
    build: state.app.reports ? state.app.reports.build : null,
    submitting: state.app.reports ? state.app.reports.submitting : true,
    building: state.app.reports ? state.app.reports.building : false,
    initialValues: props.initialValues,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createBuild: bindActionCreators(createBuild, dispatch),
    reportCompleted: bindActionCreators(reportCompleted, dispatch),
  };
};

const decoratedViaReduxForm = reduxForm<FormData, Props>({
  form: FORM_ID,
  enableReinitialize: true,
})(ReportForm);

export default connect(mapStateToProps, mapDispatchToProps)(decoratedViaReduxForm);
