import React, { PropsWithChildren } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import './styles.less';

interface Props {
  active: boolean;
  page?: boolean;
}

export function GlobalPageLoader(props: PropsWithChildren<Props>) {
  return (
    <Dimmer.Dimmable className={'global'} dimmed>
      <Dimmer active={props.active} page={props.page} inverted>
        <Loader active={props.active} />
      </Dimmer>
      {props.children}
    </Dimmer.Dimmable>
  );
}
