import React from 'react';
import { DropdownProps, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { BindingType } from 'core/constants/contracts';
import { Dropdown } from '../../../dropdown';
import { AddressRendererProps } from '../../component';

const Group = styled(Form.Group)`
  &&&& {
    margin-bottom: 0;
  }
`;

export class AddressSelectWithEntranceAndApartment extends React.Component<AddressRendererProps> {
  handleChangeBuilding = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    this.props.onChangeBuilding(data.value as string);
    // const { name } = this.props;
    // props.form.setFieldValue(`${name}.${BindingType.entrance}`, null);
  };

  handleChangeEntrance = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    this.props.onChangeType(BindingType.entrance, data.value as string);
  };

  handleChangeApartment = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    this.props.onChangeType(BindingType.apartment, data.value as string);
  };

  render() {
    const { name, buildingsOptions, bindingsTypesOptions, fetching } = this.props;

    return (
      <React.Fragment>
        <Dropdown
          name={`${name}.building_id`}
          label={'Адрес'}
          required
          onChange={this.handleChangeBuilding}
          options={buildingsOptions}
          loading={fetching['building_id']}
        />
        <Form.Field>
          <Group widths={'equal'}>
            <Dropdown
              name={`${name}.${BindingType.entrance}`}
              label={'Подъезд'}
              required
              onChange={this.handleChangeEntrance}
              options={bindingsTypesOptions[BindingType.entrance]}
              loading={fetching[BindingType.entrance]}
            />
            <Dropdown
              name={`${name}.${BindingType.apartment}`}
              label={'Квартира'}
              onChange={this.handleChangeApartment}
              options={bindingsTypesOptions[BindingType.apartment]}
              loading={fetching[BindingType.apartment]}
            />
          </Group>
        </Form.Field>
      </React.Fragment>
    );
  }
}
