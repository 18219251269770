import React, { useCallback, FC } from 'react';
import api from 'core/constants/api';
import { Camera } from 'core/constants/contracts';
import { request } from 'core/utils/request';
import { WebAPIResponse } from 'core/utils/webapi.contracts';
import { VideoArchivePlayer } from 'components/share/video';

const DEFAULT_TIMELINE = -1 * 3 * 24 * 60 * 60; // sec
const DEFAULT_DURATION = 600; // sec

interface Props {
  camera: Camera;
}

export const VideoPlayerWrapper: FC<Props> = ({ camera }: Props) => {
  const timezoneParts = camera.device ? camera.device.timezone.split(':') : ['0', '0'];
  const hours = Number.parseInt(timezoneParts[0]) * 60 * 60;
  const minutes = Number.parseInt(timezoneParts[1]) * 60;

  const timezone = hours + minutes; // sec
  const timeline = DEFAULT_TIMELINE; //sec

  const stream = useCallback(
    async (percent?: number) => {
      if (percent !== undefined) {
        const timelineMs = timeline * 1000; // ms

        const currentLocal = new Date(Date.now() + timelineMs - timelineMs * percent);

        const currentLocalTs = (currentLocal.getTime() / 1000) | 0;
        const duration = DEFAULT_DURATION;

        console.log(
          `>>>GET_STREAM: current=${currentLocalTs} duration=${duration} percent=${percent}`
        );

        // Для отображения тестового видео подставить вместо 'camera.id' -> '5d9560346c2269137e591cbe'
        const result = await request.get<WebAPIResponse<Camera>>(
          api.cameraStreams(camera.id, currentLocalTs, duration)
        );

        return result.data.list[0]?.streams?.hls;
      }

      if (camera.streams) {
        return camera.streams['hls'];
      }
    },
    [camera.id, camera.streams, timeline]
  );

  return (
    <VideoArchivePlayer autoplay={false} stream={stream} timezone={timezone} timeline={timeline} />
  );
};
