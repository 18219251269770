import React from 'react';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import { CellProps, Column } from 'react-table';
import { Token } from 'core/constants/contracts';

function copyCode(code: string) {
  if (!navigator.clipboard) {
    return;
  }

  navigator.clipboard.writeText(code).catch(() => {});
}

export const columns: Array<Column<Token>> = [
  {
    Header: 'Тип',
    accessor: 'type',
  },
  {
    Header: 'Статус',
    Cell: ({ cell: { value }, row: { original } }: CellProps<Token>) => {
      if (original.is_verified) {
        return (
          <div>
            <Icon name={'check'} color={'green'} />
            Подтвержден
          </div>
        );
      }

      if (original.is_expired) {
        return (
          <div>
            <Icon name={'times'} color={'red'} />
            Просрочен
          </div>
        );
      }

      return (
        <div>
          <Icon name={'plus'} color={'blue'} />
          Ожидает
        </div>
      );
    },
  },
  {
    Header: 'Код',
    accessor: 'code',
    Cell: ({ cell: { value } }: CellProps<Token>) => {
      return (
        <div>
          {value}
          <Icon name={'copy outline'} className={'copy'} onClick={() => copyCode(value)} />
        </div>
      );
    },
  },
  {
    Header: 'Транспорт',
    Cell: ({ cell: { value }, row: { original } }: CellProps<Token>) => {
      return (
        <div>
          <strong>{original.target}</strong> ({original.transport})
        </div>
      );
    },
  },
  {
    Header: 'Дата создания',
    accessor: 'created_at',
    Cell: (props: CellProps<Token>) => {
      return moment(props.cell.value).format('DD.MM.YYYY');
    },
  },
  {
    Header: 'Дата окончания',
    accessor: 'expire_at',
    Cell: (props: CellProps<Token>) => {
      return moment(props.cell.value).format('DD.MM.YYYY');
    },
  },
];
