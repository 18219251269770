import React from 'react';
import { Card } from 'semantic-ui-react';
import { Intercom } from 'core/constants/contracts';
import { buildAddressStr } from 'core/utils/address';
import { Intercoms } from '../intercoms';
import { ApartmentAgg } from '../../../../data/selectors';

interface Props {
  userId: string;
  userSip?: string;
  apartment: ApartmentAgg;
  intercoms: Array<Intercom>;
}

export function ApartmentAndIntercoms(props: Props) {
  const { apartment } = props;

  const tableTitle = buildAddressStr({
    building: apartment.building,
    bindings: apartment.bindings,
  });

  return (
    <Card fluid color="blue">
      <Card.Content>
        <Card.Header>{tableTitle}</Card.Header>
      </Card.Content>
      <Card.Content>
        <Card.Description>
          <Intercoms {...props} />
        </Card.Description>
      </Card.Content>
    </Card>
  );
}
