import randomstring from 'randomstring';
import { validateBySchema } from 'core/utils/validate';

export const validate = (schema: any) => (values: any) => {
  return validateBySchema(schema, values);
};

export const generatePassword = () => {
  return randomstring.generate({ length: 12 });
};

type IBaseDropdownOption = {
  id: string;
  name: string;
};

export const arrayToOptions = (item: IBaseDropdownOption) => {
  return {
    key: item.id,
    value: item.id,
    text: item.name,
  };
};

export const ringStrategyNone = {
  key: 'none',
  value: 'none',
  text: 'Не выбрано',
};
