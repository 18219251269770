import React from 'react';

interface RenderProps {
  onMouseDown: React.MouseEventHandler;
  ref: React.Ref<any>; //TODO почему это херота расширяет тип, а не сужает? <DragScrollProvider>{ref => <div ref={ref}/>}</DragScrollProvider>
}

interface Props {
  vertically?: boolean;
  horizontally?: boolean;
  children: (props: RenderProps) => React.ReactNode;
}

class DragScrollProvider extends React.Component<Props> {
  private isMouseDown = false;
  private startDragPositionX: number | null = null;
  private startDragPositionY: number | null = null;
  private ref: HTMLElement | null = null;

  public componentDidMount() {
    document.documentElement.addEventListener('mouseup', this.onMouseUp);
    document.documentElement.addEventListener('mousemove', this.onMouseMove);
  }

  public componentWillUnmount() {
    document.documentElement.removeEventListener('mouseup', this.onMouseUp);
    document.documentElement.removeEventListener('mousemove', this.onMouseMove);
  }

  private onMouseUp = () => {
    this.isMouseDown = false;
    this.startDragPositionX = null;
    this.startDragPositionY = null;
  };

  private onMouseMove = (event: MouseEvent) => {
    if (!this.isMouseDown) {
      return;
    }
    if (this.ref === null) {
      return;
    }
    if (this.startDragPositionX === null || this.startDragPositionY === null) {
      return;
    }

    if (this.props.horizontally) {
      this.ref.scrollLeft += this.startDragPositionX - event.clientX;
    }
    if (this.props.vertically) {
      this.ref.scrollTop += this.startDragPositionY - event.clientY;
    }

    this.startDragPositionX = event.clientX;
    this.startDragPositionY = event.clientY;
  };

  private handleOnMouseDown = (event: React.MouseEvent) => {
    this.isMouseDown = true;
    this.startDragPositionX = event.clientX;
    this.startDragPositionY = event.clientY;
  };

  private setRef = (element: HTMLElement) => {
    this.ref = element;
  };

  public render() {
    return this.props.children({
      onMouseDown: this.handleOnMouseDown,
      ref: this.setRef,
    });
  }
}

export { DragScrollProvider };

export type { Props as DragScrollProviderProps, RenderProps as DragScrollProviderRenderProps };
