import styled from 'styled-components';
import { ThemeVars } from 'core/styles/vars';

type TitleProps = { active?: boolean };
type StyledBoxProps = { variant?: 'edit' | 'create' };

const { colors } = ThemeVars;

export const Title = styled.div<TitleProps>`
  display: flex;
  max-width: 270px;
  align-items: center;
  background: ${(props) => (props.active ? colors.normalApple : colors.linkWater)};
  color: ${(props) => (props.active ? colors.white : colors.deepBlue)};
  padding: 0.375rem 1rem;
  margin-right: 0.5rem;
  border: none;

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0.25rem;
  }
`;

export const StyledBox = styled.div<StyledBoxProps>`
  width: 385px;
  padding: 1.125rem 1rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border: 2px solid ${colors.greyHeather};
  border-radius: 8px;

  &:hover {
    border: 2px solid
      ${(props) => (props.variant === 'create' ? colors.greyHeather : colors.darkBlueberry)};
    cursor: ${(props) => (props.variant === 'create' ? 'unset' : 'pointer')};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`;

export const AddressPopup = styled.div`
  font-size: 12px;
  line-height: 16px;

  & > span {
    font-weight: bold;
  }
`;
