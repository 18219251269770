import {
  CREATE_USER_FAIL,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_REQUEST,
  USER_ACTIVATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_FAIL,
  USER_REQUEST,
  USER_SUCCESS,
  USER_UPDATE_SUCCESS,
  USERS_FAIL,
  USERS_REQUEST,
  USERS_SUCCESS,
} from '../../constants/actions';
import {
  checkResponse,
  formatErrorMessage,
  prepareError,
  prepareResponse,
  request,
  singleResult,
  sortToString,
  uniqid,
} from '../../utils/request';
import api from '../../constants/api';
import OperationManager from '../../utils/operation';

export function getUser(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_REQUEST,
      payload: { id },
    });

    // if (getState().app.user[id].item.id) {
    //     dispatch({
    //         type: USER_SUCCESS,
    //         payload: {
    //             ...getState().app.user[id],
    //             id
    //         }
    //     });
    //
    //     return getState().app.user[id];
    // }

    try {
      let response = await request.get(api.users(id));
      let result = checkResponse(response);

      dispatch({
        type: USER_SUCCESS,
        payload: {
          id,
          user: result.list[0],
          metadata: result.metadata,
        },
      });

      return result.list[0];
    } catch (e) {
      dispatch({
        type: USER_FAIL,
        payload: {
          error: formatErrorMessage(e),
          id,
        },
      });

      throw e;
    }
  };
}

export function deleteUser(id) {
  return async (dispatch) => {
    dispatch({
      type: USER_DELETE_REQUEST,
      payload: {
        id,
      },
    });

    try {
      let response = await request.delete(api.users(id));
      checkResponse(response);

      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: {
          id,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: USER_DELETE_FAIL,
        payload: {
          error: formatErrorMessage(e),
          id,
        },
      });

      throw e;
    }
  };
}

function activateUserRequest(id) {
  return {
    type: USER_ACTIVATE_REQUEST,
    payload: {
      id,
    },
  };
}

function activateUserSuccess(res) {
  return {
    type: USER_ACTIVATE_SUCCESS,
    payload: {
      id: res._model.id,
      user: res._model,
    },
  };
}

function activateUserFail(e) {
  return {
    type: USER_ACTIVATE_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export function activateUser(id) {
  return (dispatch) => {
    dispatch(activateUserRequest(id));

    const result = request
      .post(api.userActivate(id))
      .catch(prepareError)
      .then(prepareResponse)
      .then(singleResult);

    result
      .catch((e) => dispatch(activateUserFail(e)))
      .then((r) => dispatch(activateUserSuccess(r)));

    return result;
  };
}

/**
 * Список пользователей
 * @param perPge
 * @param page
 * @param sort
 * @param rest
 * @returns {Function}
 */
export function getUsers({ limit = 30, page = 0, sort = undefined, filter = {}, ...rest } = {}) {
  return async (dispatch) => {
    dispatch({
      type: USERS_REQUEST,
    });

    const restKeys = Object.keys(rest);

    Object.keys(filter).forEach((key) => {
      if (typeof filter[key] === 'undefined') {
        delete filter[key];
      } else if (typeof filter[key] === 'string' && !filter[key]) {
        delete filter[key];
      } else if (restKeys.includes(key)) {
        const restValue = rest[key];
        delete rest[key];

        if (!Array.isArray(filter[key])) {
          filter[key] = [filter[key]];
        }
        filter[key].push(restValue);
      }
    });

    try {
      if ('address' in filter) {
        filter['fias'] = filter.address.fias;
        if (filter.address.flat) {
          filter['bindings.apartment'] = filter.address.flat;
        }
        delete filter['address'];
      }

      const query = {
        limit,
        page,
        sort: sort && sortToString(sort),
        ...filter,
        ...rest,
      };

      const response = await request.get(api.users(), { params: query });
      checkResponse(response);
      dispatch({
        type: USERS_SUCCESS,
        payload: {
          items: response.data.list,
          metadata: response.data.metadata,
        },
      });

      return response.data;
    } catch (e) {
      dispatch({
        type: USERS_FAIL,
        payload: {
          error: formatErrorMessage(e),
        },
      });

      throw e;
    }
  };
}

// export function getCurrentUser() {
//     return async (dispatch) => {
//         dispatch({
//             type: CURRENT_USER_REQUEST
//         });
//
//         try {
//             let response = await request.get(api.users() + '/self');
//             let userInfo = response.data.list[0],
//                 metadata = response.data.metadata;
//
//             dispatch({
//                 type: CURRENT_USER_SUCCESS,
//                 user: userInfo,
//                 metadata
//             });
//
//             if (authUtils.getToken()) {
//                 OperationManager.listen(authUtils.getToken());
//             }
//
//             return userInfo;
//         } catch (e) {
//             if (e.response && e.response.status === 401) {
//                 authUtils.logout();
//                 window.location.reload();
//             }
//             dispatch({
//                 type: CURRENT_USER_FAIL,
//                 error: formatErrorMessage(e)
//             });
//         }
//     }
// }

/**
 * Авторизация пользователя
 * @param username
 * @param password
 * @param remember_me
 * @param menuName
 * @returns {Function}
 */
// export function auth({ username, password, remember_me, menuName }) {
//     return async (dispatch) => {
//         dispatch({
//             type: AUTH_REQUEST
//         });
//
//         try {
//             let response = await request.post(api.auth, { username, password });
//             let authInfo = response.data.list[0];
//
//             authUtils.setToken(authInfo.sid, remember_me);
//             setAuthHeader(authInfo.sid);
//             dispatch(init({ menuName }));
//
//             dispatch({
//                 type: AUTH_SUCCESS,
//                 payload: {
//                     authInfo
//                 }
//             });
//         } catch (e) {
//             let error;
//
//             const code = get(e, 'response.data.error.code');
//             const message = get(e, 'response.data.error.message');
//
//             if (code === 401 && message === "Unauthorized") {
//                 error = 'Введен неверный логин или пароль. Повторите ввод или обратитесь в службу поддержки.';
//             } else {
//                 error = formatErrorMessage(e);
//                 if (error === 'Unknown error') {
//                     error = 'Введен неверный логин или пароль. Повторите ввод. Если вы не знаете пароль, обратитесь в службу поддержки.';
//                 }
//             }
//
//             dispatch({
//                 type: AUTH_FAIL,
//                 payload: {
//                     error
//                 }
//             });
//
//             throw e;
//         }
//     }
// }

export function saveUser({ id = undefined, ...rest }) {
  return async (dispatch) => {
    dispatch({
      type: CREATE_USER_REQUEST,
    });

    try {
      let method = id ? request.put : request.post;
      let operationId = uniqid();
      let response = await method(api.users(id), rest, {
        headers: {
          'X-Operation-ID': operationId,
        },
      });

      let result = checkResponse(response);

      const onSuccess = function ({ id, user, metadata }) {
        dispatch({
          type: CREATE_USER_SUCCESS,
          payload: {
            result,
          },
        });

        if (id) {
          dispatch({
            type: USER_UPDATE_SUCCESS,
            payload: {
              user,
              id,
              metadata,
            },
          });
        }
      };

      const cb = onSuccess.bind(null, {
        id,
        user: response.data.list[0],
        metadata: response.data.metadata,
      });

      OperationManager.register(operationId, cb);

      result = result.list[0];

      return result;
    } catch (e) {
      console.error(e);
      dispatch({
        type: CREATE_USER_FAIL,
        error: formatErrorMessage(e),
      });

      throw e;
    }
  };
}
