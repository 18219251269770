import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { reset } from 'redux-form';
import { RootState } from 'core/store';
import { fetchCompanies } from 'core/store/features/companies';
import { createSelectMapToArray } from 'core/store/selectors';
import { Company } from 'core/constants/contracts';
import { Loader } from 'components/share';
import ClientTable from './grid';
import ClientFilter from './filter';

interface OwnProps {}

interface Props
  extends OwnProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

interface State {
  isLoaded: boolean;
  filter: Partial<Filter>;
}

interface Filter {
  is_active: boolean;
  roles: Array<string>;
}

const DEFAULT_FILTER = {};

const DEFAULT_QUERY_FILTER: Filter = {
  roles: ['client'],
  is_active: true,
};

class ClientsContainer extends React.Component<Props, State> {
  state: State = {
    isLoaded: false,
    filter: {},
  };

  constructor(props: Props) {
    super(props);

    Promise.resolve(this.props.fetchCompanies({ limit: 1000 })).then(() =>
      this.setState({ isLoaded: true })
    );
  }

  setFilter = (filter: Record<string, any>) => {
    let roles: Array<string> = DEFAULT_QUERY_FILTER.roles;
    const filterRole: string = filter.roles;
    if (filterRole) {
      filter = { ...filter };
      roles = [...roles, filterRole];
      delete filter.roles;
    }

    // TODO поиск по нескольким ролям
    this.setState(() => {
      return {
        filter: {
          ...DEFAULT_QUERY_FILTER,
          ...filter,
          roles,
        },
      };
    });
  };

  render() {
    const { isFetching, companies } = this.props;
    const { isLoaded } = this.state;

    if (isFetching && isLoaded) {
      return <Loader active={true} placeholder={true} />;
    }

    return (
      <React.Fragment>
        <ClientFilter
          companies={companies}
          defaultFilter={DEFAULT_FILTER}
          filter={this.state.filter}
          setFilter={this.setFilter}
        />
        <ClientTable filter={this.state.filter} />
      </React.Fragment>
    );
  }
}

const selectCompaniesAsArray = createSelectMapToArray<Company>();

function mapStateToProps(state: RootState) {
  return {
    companies: selectCompaniesAsArray(state.entities.companies.items),
    isFetching: state.entities.companies.pending,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    resetFilter: bindActionCreators(reset, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsContainer);
