import React from 'react';
import { Dimmer, Image, Loader as SemanticLoader, Segment } from 'semantic-ui-react';
import paragraph from './short-paragraph.png';

interface Props {
  active?: boolean;
  placeholder?: boolean;
  as?: any;
}

export function Loader(props: Props) {
  const { active, placeholder, as = Segment } = props;

  if (placeholder) {
    return (
      <Dimmer.Dimmable as={as} dimmed={active} padded={'very'}>
        <Dimmer active={active} inverted>
          <SemanticLoader active={active} />
        </Dimmer>
        <p>
          <Image src={paragraph} />
        </p>
        <p>
          <Image src={paragraph} />
        </p>
      </Dimmer.Dimmable>
    );
  }

  return (
    <Dimmer active={active} inverted>
      <SemanticLoader />
    </Dimmer>
  );
}
