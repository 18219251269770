import * as yup from 'yup';
import { reURLInformation } from 'core/utils/url';

export const schema = yup.object().shape({
  protocol: yup.string().required('Укажите протокол'),
  hostname: yup.string().required('Укажите hostname'),
  name: yup.string().trim().required('Укажите Имя камеры'),
  rtsp: yup.string().trim().required('Выполните получение потока'),
  url: yup.string().required('Укажите URL').matches(reURLInformation, 'URL не валидный'),
});
