import { Grid } from 'semantic-ui-react';
import React, { FC } from 'react';
import { Tag } from 'components/share';
import { ColorsEnum } from 'core/styles/vars';
import {
  Binding,
  BindingType,
  Intercom,
  CommercialStatusLiteral,
  Building,
} from 'core/constants/contracts';
import { BINDING_TYPE_TITLE_MAP } from 'core/constants/domain/binding';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import styled from 'styled-components';

type IntercomHeaderProps = {
  device: Intercom['device'];
  ownerStatus?: CommercialStatusLiteral;
  buildingIsTest?: boolean;
  buildings: Building[];
};

const GridRowStyled = styled(Grid.Row)`
  &&&& {
    margin: 0 1rem;
    gap: 1rem;
  }
`;

export const IntercomInfoRow: FC<IntercomHeaderProps> = ({
  device,
  ownerStatus,
  buildingIsTest,
  buildings,
}) => {
  const sortBindings = (bindings: Array<Binding>) => {
    let bindingsMap = groupBy(bindings, (binding) => binding.type);
    let bindingsSortedList: Binding[] = [];

    if (!isEmpty(bindingsMap[BindingType.terrain])) {
      bindingsSortedList.push(...bindingsMap[BindingType.terrain]);
    }

    if (!isEmpty(bindingsMap[BindingType.entrance])) {
      bindingsSortedList.push(...bindingsMap[BindingType.entrance]);
    }

    if (!isEmpty(bindingsMap[BindingType.apartment])) {
      bindingsSortedList.push(...bindingsMap[BindingType.apartment]);
    }

    return bindingsSortedList;
  };

  return (
    <GridRowStyled>
      {!isEmpty(device.building) ? (
        buildings.map((building) => (
          <Tag key={building.id} color={ColorsEnum.white} isInverted={false}>
            {building.address.text}
          </Tag>
        ))
      ) : (
        <Tag color={ColorsEnum.white} isInverted={false}>
          Не привязан к адресу
        </Tag>
      )}

      {sortBindings(device.bindings).map((item, index) => (
        <Tag key={index} color={ColorsEnum.white} isInverted={false}>
          {`${BINDING_TYPE_TITLE_MAP[item.type as BindingType]} ${item.number}`}
        </Tag>
      ))}

      {buildingIsTest && (
        <Tag color={ColorsEnum.white} isInverted={false}>
          Тестовый дом
        </Tag>
      )}

      {ownerStatus && (
        <Tag color={ColorsEnum.white} isInverted={false}>
          {ownerStatus} клиент
        </Tag>
      )}
    </GridRowStyled>
  );
};
