import { createSlice } from '@reduxjs/toolkit';
import { Service, UserService, UserServiceRaw } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
} from './entities';
import api from '../../constants/api';
import { success as userServiceSuccessAction } from './user-services';
import { WebAPIResponse } from '../../utils/webapi.contracts';

const slice = createSlice({
  name: 'services',
  initialState: createEntitiesInitialState<Service>(),
  reducers: createDefaultEntitiesReducers<Service>(),
  extraReducers: (builder) =>
    builder.addCase(userServiceSuccessAction, (state, action) => {
      // TODO any
      const payload: WebAPIResponse<UserService & UserServiceRaw> = action.payload as any;
      for (let i = 0; i < payload.list.length; i++) {
        const userService: UserServiceRaw = payload.list[i] as UserServiceRaw; // TODO надо както прокидывать тип для каждого редьюсера
        const service: Service = userService.service;

        state.items[service.id] = service;
        state.ids.push(service.id);
      }
    }),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchServices = createFetchEntities(api.services, slice.actions);
