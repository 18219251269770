import React from 'react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import WizardPage from './wizard';

const CreateCompanyPage = () => {
  return (
    <React.Fragment>
      <SetBreadcrumb url={'/companies'}>Компании</SetBreadcrumb>
      <SetBreadcrumb>Добавить</SetBreadcrumb>

      <WizardPage />
    </React.Fragment>
  );
};

export default CreateCompanyPage;
