import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeVars } from 'core/styles/vars';
import * as Icons from 'components/share/assets';
import { Icon } from 'components/share';
import { DeviceReference } from 'core/constants/contracts';
import { Popup } from 'semantic-ui-react';

const ModelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ModelName = styled.div`
  margin-left: 0.5rem;
`;

const Box = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
`;

interface DeviceModelProps {
  type: DeviceReference['category'];
  model: string;
}

const modelConfig: Record<string, ReactNode> = {
  intercom: <Icons.Intercom />,
  camera: <Icons.CameraIntercom />,
  multi_user_intercom: <Icons.MultiSubscriberIntercom />,
  single_user_intercom: <Icons.SingleSubscriberIntercom />,
  multi_user_converter: <Icons.MultiSubscriberConverter />,
  barrier: <Icons.BarrierIntercom />,
};

export const DeviceModel: FC<DeviceModelProps> = ({ type, model }) => {
  return (
    <ModelContainer>
      <Popup
        content={type.name}
        trigger={
          <Box>
            <Icon
              icon={modelConfig[type?.alias || 'default']}
              size="24px"
              color={ThemeVars.colors.greyHeather}
            />
          </Box>
        }
        offset={-12}
        inverted
      />

      <ModelName>{model}</ModelName>
    </ModelContainer>
  );
};
