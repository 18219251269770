import React, {
  ComponentProps,
  ComponentType,
  ReactElement,
  ReactNode,
  Ref,
  useContext,
} from 'react';
import Modal, { ModalProps } from './modal';

const ModalContext = React.createContext<Context>(null as any);

const Consumer = ModalContext.Consumer;
const Provider = ModalContext.Provider;

// TODO !!!!!!!!!!!!!!!!!!!!!
export interface OpenModalOptions<P> {
  id?: string;
  component: ComponentType<P>;
  props?: Omit<P, keyof InjectedModalProps>;
  // element?: ReactElement;
  container?: ComponentType;
  containerProps?: ModalProps;
}

interface Context {
  openModal<P>(options: OpenModalOptions<P>): string;
  closeModal(id: string): void;
  closeModalAll(): void;
}

export interface InjectedModalManagerProps {
  modalManager: Context;
}

export interface InjectedModalProps {
  closeModal: () => void;
  openModal: () => void;
}

// --------------------------------------------------

interface ModalContainerProps {
  onClose?: (...args: any[]) => void;
}

interface Props {
  container: ComponentType<ModalContainerProps>;
}

interface State {
  modals: Record<string, ReactElement>;
}

export class ModalProvider extends React.Component<Props, State> {
  static defaultProps = {
    container: Modal,
  };

  modalManager: Context;

  constructor(props: Props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModalAll = this.closeModalAll.bind(this);

    this.state = {
      modals: {},
    };

    this.modalManager = {
      openModal: this.openModal,
      closeModal: this.closeModal,
      closeModalAll: this.closeModalAll,
    };
  }

  internalCloseModal = (options: OpenModalOptions<any>) => {
    return () => {
      this.closeModal(options.id!);
    };
  };

  internalOpenModal = (options: OpenModalOptions<any>) => {
    return () => {
      if (this.state.modals[options.id!]) {
        return;
      }

      this.openModal(options);
    };
  };

  openModal<P>(options: OpenModalOptions<P>): string {
    const id = options.id ?? Math.random().toString(36).substring(2);

    const Content = options.component as ComponentType</*P & InjectedModalProps*/ any>;
    const Container = options.container ?? this.props.container;
    options = { ...options, id };

    const modal = (
      <Container {...options.containerProps} key={id} onClose={this.internalCloseModal(options)}>
        <Content
          {...options.props}
          closeModal={this.internalCloseModal(options)}
          openModal={this.internalOpenModal(options)}
        />
      </Container>
    );

    this.setState((state) => {
      const modals = { ...state.modals, [id]: modal };

      return { modals };
    });

    return id;
  }

  closeModal(id: string) {
    this.setState((state) => {
      const modals = { ...state.modals };
      delete modals[id];

      return { modals };
    });
  }

  closeModalAll() {
    this.setState({ modals: {} });
  }

  render(): ReactNode {
    return (
      <Provider value={this.modalManager}>
        {this.props.children}
        {Object.values(this.state.modals).map((v) => v)}
      </Provider>
    );
  }
}

interface ModalConsumerProps {
  children: (context: Context) => ReactNode;
}

const ModalConsumer = (props: ModalConsumerProps) => {
  return <Consumer>{(context) => props.children(context)}</Consumer>;
};

type OMP<P> = Omit<P, keyof InjectedModalManagerProps>;

export function withModalManager<P>(component: ComponentType<P & OMP<P>>): ComponentType<OMP<P>> {
  const WrappedComponent = component;

  // @ts-ignore
  return React.forwardRef((props: ComponentProps<typeof component>, ref: Ref<any>) => (
    <ModalConsumer>
      {(context) => <WrappedComponent modalManager={context} {...props} ref={ref} />}
    </ModalConsumer>
  ));
}

export const useModals = (): Context => {
  const context = useContext(ModalContext);

  if (!context) {
    throw Error('The `useModals` hook must be called from a descendent of the `ModalProvider`.');
  }

  return context;
};
