export const schemaList = ['http://', 'https://', 'ssh://', 'telnet://'];

export const schemaOptions = schemaList.map((item) => {
  return {
    key: item,
    value: item,
    text: item,
  };
});

export const actualOwnerMap: Record<string, string> = {
  mts: 'Принадлежит МТС',
  client: 'Принадлежит клиенту',
  leased_by_mts: 'В аренде у клиента',
};

export const actualOwnerOptions = [
  { key: 'mts', value: 'mts', text: 'Принадлежит МТС' },
  { key: 'client', value: 'client', text: 'Принадлежит клиенту' },
  {
    key: 'leased_by_mts',
    value: 'leased_by_mts',
    text: 'В аренде у клиента',
  },
];

export const internetProviderMap: Record<string, string> = {
  mts: 'Принадлежит МТС',
  other: 'Не принадлежит МТС',
};

export const internetProviderSelect = [
  { key: 'mts', value: 'mts', text: 'Принадлежит МТС' },
  { key: 'other', value: 'other', text: 'Не принадлежит МТС' },
];

export const internetProviderOptions: Record<string, string> = {
  mts: 'mts',
  other: 'other',
};
