import * as yup from 'yup';
import { IP_ADDRESS_REGEXP } from 'core/constants/validation';

const createSchema = (schemaList: Array<string>) =>
  yup.object().shape({
    ip: yup
      .string()
      .matches(IP_ADDRESS_REGEXP, 'IP адрес не валидный')
      .required('Укажите IP адрес устройства'),
    port: yup
      .number()
      .typeError('Укажите число')
      .required('Укажите порт устройства')
      .integer('Должно быть целое число')
      .min(0, 'Укажите значение от 0 до 65535')
      .max(65535, 'Укажите значение от 0 до 65535'),
    schema: yup.string().oneOf(schemaList),
    username: yup.string().max(30).required('Укажите логин устройства'),
    password: yup.string().max(30).required('Укажите пароль устройства'),
    reference: yup.mixed().required('Укажите поставщика устройства'),
    owner: yup.mixed().required('Укажите владельца'),
  });

export { createSchema };
