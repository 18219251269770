import axios from 'axios';
import tokenStorage from './token';
import _ from 'lodash';
import qs from 'qs';

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';

export const DEFAULT_SORT_DIR = SORT_ASC;

export const SORT_MAP = {
  '-': SORT_DESC,
  '+': SORT_ASC,
};

const uniqid = function () {
  return Math.random().toString(36).substr(2, 9);
};

const request = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
});

const setAuthHeader = (token = null) => {
  if (!token) {
    token = tokenStorage.getToken();
  }

  if (!token) {
    return;
  }

  request.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const enhanceError = (e) => {
  let data = e.data;
  let code = e.code;
  let message = e.message;

  if (typeof data === 'string') {
    message = data;
    data = null;
  }

  const error = new Error(message);
  error.data = data;
  error.code = code;

  return error;
};

const prepareError = (e) => {
  throw returnError(e);
};

/**
 * @deprecated
 * @param e
 * @returns {Error}
 */
const returnError = (e) => {
  const err = _.get(e, 'response.data.error');

  if (err) {
    return enhanceError(err);
  }

  return new Error('Unknown error');
};

/**
 * @deprecated
 * @param response
 * @returns {*}
 */
const prepareResponse = (response) => {
  if (!response) {
    throw new Error('Произошла непредвиденная ошибка');
  }

  if (response.status >= 200 && response.status < 400) {
    return response.data;
  }

  const err = _.get(response, 'data.error');

  if (err) {
    throw enhanceError(err);
  }

  throw new Error('Unknown error');
};

const singleResult = (res) => {
  return res.list[0];
};

const multipleResult = (res) => {
  return res.list;
};

/**
 * Получим значение полсе action или после dispatch
 *
 * @param action
 * @return {*}
 */
const returnValue = (action) => {
  if (!action.payload) {
    return void 0;
  }

  if (action.payload.list) {
    return action.payload;
  }

  if (action.payload.item) {
    return action.payload.item;
  }

  if (action.payload.items) {
    return action.payload.items;
  }

  if (action.payload.error) {
    return action.payload.error;
  }

  return void 0;
};

const checkException = (e) => {
  const error = _.get(e, 'response.data.error');

  return !_.isEmpty(error) || !_.isUndefined(error);
};

const checkResponse = (response) => {
  if (response.status >= 200 && response.status < 400) {
    return response.data;
  }

  const error = _.get(response, 'data.error');
  if (error !== null && error !== undefined) {
    throw new Error(_.set({}, 'response.data.message', error));
  }

  throw new Error(formatErrorMessage(error));
};

const formatErrorMessage = (error) => {
  let message = 'Unknown error';

  if (error.response) {
    if (error.response.data.error) {
      const { code, message, data } = error.response.data.error;
      if (data && typeof data === 'string') {
        return data;
      }

      return `${code}: ${message}`;
    }

    message = error.response.status;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    message = error.message;
  }
  return message;
};

const getQueryParams = (search) => {
  if (!search) {
    return {};
  }

  if (search.startsWith('?')) {
    search = search.substr(1);
  }

  return qs.parse(search);
};

const flip = (obj) => {
  let result = {};
  for (let field in obj) {
    result[obj[field]] = field;
  }

  return result;
};

const sortToString = (sort) => {
  if (typeof sort === 'string') {
    return sort;
  }

  let result = [];
  let sortMap = flip(SORT_MAP);
  for (let field in sort) {
    result.push(`${sortMap[sort[field]]}${field}`);
  }

  return result.join(',');
};

export {
  request,
  formatErrorMessage,
  checkResponse,
  getQueryParams,
  setAuthHeader,
  sortToString,
  singleResult,
  multipleResult,
  returnValue,
  prepareResponse,
  prepareError,
  returnError,
  checkException,
  uniqid,
};
