import React, { useCallback, useMemo } from 'react';
import { TableRedux, TableReduxFetchOptions } from 'components/share/components/table';
import { wrapEntitiesTableSelectorFactory } from 'core/store/selectors';
import { fetchCompaniesWithService } from 'core/store/features/company-services';
import { createSelectCompanyServiceAggAsState } from 'core/store/selectors/company-services';
import { createColumns } from './columns';

export const Table = () => {
  const columns = useMemo(() => createColumns(), []);

  const companyServiceSelector = useMemo(() => createSelectCompanyServiceAggAsState(), []);
  const selector = useCallback(wrapEntitiesTableSelectorFactory(companyServiceSelector), []);

  const fetcher = useCallback(({ pageIndex, pageSize }: TableReduxFetchOptions) => {
    return fetchCompaniesWithService({
      page: pageIndex + 1,
      limit: pageSize,
    });
  }, []);

  return (
    <TableRedux
      columns={columns}
      fetcher={fetcher}
      selector={selector}
      refreshAfterResize={5}
      showUpperPagination
    />
  );
};
