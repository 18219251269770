import { Column } from 'components/share/components/table';
import { CompanyServiceAgg } from 'core/store/selectors/company-services';
import React from 'react';
import { Popup, Button } from 'semantic-ui-react';
import { CloudArchiveModal } from './components/modal';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Service, ServiceAlias } from 'core/constants/contracts';
import { createSelectMapToArray } from 'core/store/selectors';
import { getServicesItems } from 'core/store/selectors/services';

interface IStoreProps {
  cloudArchiveService: Service;
}

export const createColumns = (): Array<Column<CompanyServiceAgg>> => {
  return [
    {
      Header: 'Наименование',
      accessor: '__company_name',
      Cell: ({ cell: { value } }) => {
        return value;
      },
    },
    {
      Header: 'Количество подключенных каналов',
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original.payload.max_channels,
    },
    {
      Header: 'Количество использованных каналов',
      Cell: ({
        cell: {
          row: { original },
        },
      }) => original.payload.used_channels || 0,
    },
    {
      Header: 'Действия',
      Cell: ({ row: { original } }) => {
        const [isModalOpen, setIsModalOpen] = React.useState(false);

        const { cloudArchiveService } = useSelector<RootState, IStoreProps>((state) => {
          const selector = createSelectMapToArray<Service>();
          const [service] = selector(getServicesItems(state)).filter(
            (s) => s.alias === ServiceAlias['company-archive-channel']
          );
          return {
            cloudArchiveService: service,
          };
        });

        const toggleModal = () => {
          setIsModalOpen((prev) => !prev);
        };
        return (
          <>
            <Popup
              trigger={<Button icon={'mts-icon-edit'} onClick={toggleModal} />}
              content="Редактировать"
              position="top right"
              inverted
            />
            <CloudArchiveModal
              isOpen={isModalOpen}
              onClose={toggleModal}
              serviceId={cloudArchiveService.id}
              variant="edit"
              companyData={{
                id: original.owner.id,
                max_channels: original.payload.max_channels,
                serviceRegistryId: original.id,
              }}
            />
          </>
        );
      },
      width: 100,
    },
  ];
};
