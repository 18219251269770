import './styles.less';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Menu, Segment } from 'semantic-ui-react';
import { VdomeLogo } from 'components/share/assets';

const ClearLayout = (props) => {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.classList.value = 'clear-layout';
    }
  }, []);

  return (
    <React.Fragment>
      {/* Header */}
      <Menu borderless pointing size="large" className="top-menu" fixed="top">
        <Menu.Item as={Link} to="/sign-in" className="logo">
          <div className="logo-container">
            <VdomeLogo />
            <div className="logo-description">
              <div className="title">АРМ<br />VDome</div>
              {/* <span>Обслуживающая компания</span> */}
            </div>
          </div>
        </Menu.Item>
      </Menu>
      {/* Content */}
      <Segment className="content-margin-top content-wrapper">
        <Grid>
          <Grid.Column>{props.children}</Grid.Column>
        </Grid>
      </Segment>
      {/* Footer */}
      <Segment className="footer" />
    </React.Fragment>
  );
};

ClearLayout.propTypes = {
  children: PropTypes.node,
};

export default ClearLayout;
