import React, { ReactNode, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';
import SidebarMenu from './side-menu';
import TopMenu from './top-menu';
import Footer from './footer';
import { Breadcrumbs } from 'components/share/breadcrumbs';
import ModalManager from 'components/share/dialogs/modal-manager';
import { CurrentUserContext } from 'core/contexts/user';
import { CurrentCompanyContext } from 'core/contexts/company';
import { isAppLoading } from 'core/store/selectors/app';
import { RootState } from 'core/store';
import './styles.less';

const staticBreadcrumbs = [
  {
    id: 'home',
    content: 'Главная',
    url: '/',
  },
];

interface Props extends ReturnType<typeof mapStateToProps> {
  breadcrumbs?: boolean;
  children: ReactNode;
  sidebar?: boolean;
}

const ResponsiveLayout: React.FC<Props> = (props) => {
  const { isLoading, breadcrumbs, menu } = props;

  const currentUser = useContext(CurrentUserContext);
  const currentCompany = useContext(CurrentCompanyContext);

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.classList.value = 'responsive-layout';
    }
  }, []);

  return (
    <React.Fragment>
      <SidebarMenu menu={menu} />
      <TopMenu currentUser={currentUser!} currentCompany={currentCompany!} />
      <Dimmer active={isLoading} inverted>
        <Loader active={isLoading} />
      </Dimmer>
      <Segment className={'content-margin-top content-margin-left content-wrapper'}>
        <Grid stretched>
          {false !== breadcrumbs && (
            <Grid.Row className={'breadcrumbs'}>
              <Grid.Column>
                <Breadcrumbs static={staticBreadcrumbs} size={'mini'} />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column>{props.children}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Footer />
      <ModalManager />
    </React.Fragment>
  );
};

function mapStateToProps(state: RootState) {
  return {
    menu: state.appx.menu,
    isLoading: isAppLoading(state),
  };
}

export default connect(mapStateToProps)(ResponsiveLayout);
