import { Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { Entity, Meta } from './contracts';
import { WebAPIMetadata, WebAPIResponse } from 'core/utils/webapi.contracts';
import { dataFromAxios, errorFromAxios } from 'core/utils/webapi.handlers';

export function returnPayload<T = any>(action: PayloadAction<T>): T {
  return action.payload;
}

export function returnError(action: PayloadAction<string>): Error {
  return new Error(action.payload);
}

// TODO any избавится
export function onSuccess<T = Entity>(
  dispatch: Dispatch,
  success: any,
  res: AxiosResponse<WebAPIResponse<T>>,
  meta?: Meta
): WebAPIResponse<T> {
  const data = dataFromAxios(res);
  const action = dispatch(success(data, meta));

  return returnPayload<WebAPIResponse<T>>(action);
}

// TODO any избавится
export function onFailed(dispatch: Dispatch, failed: any, err: any, meta?: Meta) {
  const message = errorFromAxios(err);
  const action = dispatch(failed(message, meta));
  const reason = returnError(action);

  return Promise.reject(reason);
}

// TODO any избавится
export function onDelete(dispatch: Dispatch, deleted: any, id: string, meta?: Meta) {
  const action = dispatch(deleted(id, meta));

  return returnPayload<string>(action);
}

export function convertMetadata({ page, perPage, pageCount, total, ...rest }: WebAPIMetadata) {
  return {
    pageIndex: page,
    pageSize: perPage,
    pageCount: pageCount,
    total: total,
    ...rest,
  };
}
