import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from 'core/constants/app';
import { TableAsync, TableFetchOptions } from 'components/share/components/table';
import ErrorOnPage from 'views/components/error-on-page';
import { createColumns, Building } from './columns';

const query = `
query ($page: Int, $perPage: Int, $orderBy: BuildingsOrderInput, $filter: BuildingsFilterInput) {
    buildings (
        page: $page
        perPage: $perPage
        orderBy: $orderBy
        filter: $filter
    ) {
        totalCount
        page
        perPage
        list {
            id
            address
            is_active
            bindings_statistic {
                type
                count
            }
        }
    }
}
`;

const mutation = `
mutation ($input: BuildingUpdateInput!) {
    updateBuilding (input: $input) {
        id
        is_active
    }
}
`;

interface Response {
  buildings: {
    page: number;
    perPage: number;
    totalCount: number;
    list: Array<Building>;
  };
}

interface Props {
  companyId: string;
}

interface State {
  page: number;
  perPage: number;
  orderBy: {
    field: 'id' | 'address';
    direction: 'ASC' | 'DESC';
  };
  owner_id: string;
}

export function CompanyBuildings(props: Props) {
  const [variables, setVariables] = useState<State>({
    page: 1,
    perPage: DEFAULT_PAGE_SIZE,
    owner_id: props.companyId,
    orderBy: {
      field: 'id',
      direction: 'ASC',
    },
  });

  const [, update] = useMutation(mutation);

  const updateBuilding = useCallback((data) => update({ input: data }), [update]);
  const columns = useMemo(
    () => createColumns({ block: updateBuilding, unblock: updateBuilding }),
    [updateBuilding]
  );
  const fetcher = useCallback(
    (params: TableFetchOptions) => {
      const newState: State = {
        ...variables,
        page: params.pageIndex + 1,
        perPage: params.pageSize,
      };

      setVariables(newState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [result] = useQuery<Response>({
    query,
    variables,
  });
  const { data, fetching, error } = result;

  if (error) {
    return <ErrorOnPage>{error?.message}</ErrorOnPage>;
  }

  return (
    <TableAsync
      columns={columns}
      data={data?.buildings.list ?? []}
      fetcher={fetcher}
      pageSizes={PAGE_SIZES}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      pageCount={data ? Math.ceil(data.buildings.totalCount / data.buildings.perPage) : 0}
      isFetching={fetching}
    />
  );
}
