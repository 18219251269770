import * as React from 'react';

import { FetchProps, ResponseResult, withSubscription } from '../hoc';
import api from 'core/constants/api';
import { DataGrid } from '../components/data-grid';

import columns from './columns';
import { SetBreadcrumb } from '../../../../components/share/breadcrumbs';

type MyProps = {
  data: ResponseResult;
  match: { params: { sessionId: string; integration: string } };
} & FetchProps;

export const RowsPage = ({ data, fetchData, match }: MyProps) => {
  return (
    <>
      <SetBreadcrumb url={'/integrations'}>Интеграции</SetBreadcrumb>
      <SetBreadcrumb url={`/integrations/${match.params.integration}/sessions`}>
        Сессии
      </SetBreadcrumb>
      <SetBreadcrumb>Записи</SetBreadcrumb>
      <DataGrid
        {...data}
        defaultParams={{
          session: match.params.sessionId,
        }}
        rowOptions={(item: any) => {
          return {
            negative: item.is_failed,
          };
        }}
        fetchData={fetchData}
        header={'Детальная информация'}
        emptyMessage={'Сессии не найдены'}
        columns={columns}
      />
    </>
  );
};

export default withSubscription(RowsPage, api.integrationSessionRows());
