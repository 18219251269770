import React from 'react';
import './styles.less';

export function Spinner() {
  return (
    <div className={'video-archive-spinner'}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask_s0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="16"
          y="4"
          width="6"
          height="8"
        >
          <path
            d="M16.2656 6.44951L18.0968 4.07324C20.4795 5.90941 22.0012 8.79505 21.9985 11.9997H18.9985C19.0006 9.75852 17.9392 7.73921 16.2656 6.44951Z"
            fill="url(#paint0_linear)"
          />
        </mask>
        <g mask="url(#mask_s0)">
          <rect x="1" y="1" width="22" height="22" fill="white" />
        </g>
        <mask
          id="mask_s1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="12"
          y="12"
          width="11"
          height="10"
        >
          <path
            d="M12 18.9998C15.7006 19.0029 18.7946 16.104 18.9905 12.366C18.9969 12.2434 19.0001 12.1214 19.0002 12H22.0003C22.0002 12.1734 21.9955 12.3478 21.9864 12.523C21.7065 17.863 17.2866 22.0043 12 21.9999V18.9998Z"
            fill="url(#paint1_linear)"
          />
        </mask>
        <g mask="url(#mask_s1)">
          <rect x="1" y="1" width="22" height="22" fill="white" />
        </g>
        <mask
          id="mask_s2"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="2"
          y="12"
          width="11"
          height="10"
        >
          <path
            d="M11.6339 18.99C11.7566 18.9964 11.8787 18.9997 12.0002 18.9998V21.9999C11.8266 21.9997 11.6522 21.9951 11.4769 21.9859C6.13705 21.7061 1.99574 17.2864 2 12H5.00008C4.99716 15.7004 7.89605 18.7941 11.6339 18.99Z"
            fill="url(#paint2_linear)"
          />
        </mask>
        <g mask="url(#mask_s2)">
          <rect x="1" y="1" width="22" height="22" fill="white" />
        </g>
        <mask
          id="mask_s3"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="2"
          y="1"
          width="12"
          height="12"
        >
          <path
            d="M12.3666 5.00935C8.5059 4.80702 5.21217 7.77271 5.00984 11.6334C5.0034 11.7563 5.00017 11.8785 5.00007 12.0002H2C2.00014 11.8265 2.00475 11.6518 2.01395 11.4764C2.30299 5.96112 7.00833 1.72442 12.5236 2.01346C13.3509 2.05682 13.9864 2.76262 13.943 3.58991C13.8997 4.4172 13.1939 5.05271 12.3666 5.00935Z"
            fill="url(#paint3_linear)"
          />
        </mask>
        <g mask="url(#mask_s3)">
          <rect x="1" y="1" width="22" height="22" fill="white" />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="18.9982"
            y1="11.9998"
            x2="15.9982"
            y2="3.99976"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.08" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="12"
            y1="19"
            x2="19"
            y2="12"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.35" />
            <stop offset="1" stopOpacity="0.08" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="5.00018"
            y1="12"
            x2="12.0002"
            y2="19"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.65" />
            <stop offset="1" stopOpacity="0.35" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="14.0002"
            y1="5.00015"
            x2="5.00018"
            y2="12.0002"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop offset="1" stopOpacity="0.65" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
