import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { Checkbox, TextInput } from 'components/share/redux-form';
import { authenticate } from 'core/store/features/app';
import tokenStorage from 'core/utils/token';

function validate(values) {
  let errors = {};

  if (!values.username || values.username.length === 0) {
    errors.username = 'Необходимо заполнить поле "E-mail"';
  }

  if (!values.password || values.password.length === 0) {
    errors.password = 'Необходимо заполнить поле "Пароль"';
  }

  return errors;
}

class SigInForm extends React.Component {
  handleSubmit = async (values) => {
    try {
      await this.props.authenticate({ ...values, role: 'admin' });
      await this.props.history.push('/');
      tokenStorage.setRememberMe(values.remember_me);
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const {
      handleSubmit,
      submitting,
      invalid,
      submitFailed = false,
      submitSucceeded,
      authData,
    } = this.props;
    const formErrors = [authData.error].filter((val) => val);
    const hasErrors = (submitSucceeded || submitFailed) && formErrors.length > 0;

    return (
      <Form error={hasErrors} loading={authData.isFetching} className={'bg-gage'}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={'username'}
                placeholder={'E-mail'}
                label={'E-mail'}
                component={TextInput}
                fluid
                type={'email'}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field
                name={'password'}
                placeholder={'Пароль'}
                label={'Пароль'}
                component={TextInput}
                fluid
                type="password"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row textAlign={'center'} className={'button-container'}>
            <Grid.Column>
              <Button
                name="submit"
                color="red"
                disabled={invalid || submitting}
                onClick={handleSubmit(this.handleSubmit)}
                content={'Войти'}
                className={'if_disabled__white'}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Field name={'remember_me'} label={'Запомнить меня'} component={Checkbox} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Message error list={formErrors} />
      </Form>
    );
  }
}

SigInForm.propTypes = {
  history: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  authData: PropTypes.object,
};

const shouldError = () => {
  return true;
};

const mapStateToProps = (state) => {
  const rememberMe = !!tokenStorage.getRememberMe();

  return {
    user: state.appx.user,
    authData: state.appx.auth,
    initialValues: {
      remember_me: rememberMe,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticate: bindActionCreators(authenticate, dispatch),
});

let component = SigInForm;
component = withRouter(component);
component = reduxForm({ form: 'sign-in', validate, shouldError })(component);

export default connect(mapStateToProps, mapDispatchToProps)(component);
