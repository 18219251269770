import { DeviceCommandType } from './contracts';

export const deviceCommandsList = {
  [DeviceCommandType.available]: 'Административная проверка на доступность устройства',
  [DeviceCommandType['set-sip-config']]: 'Установка SIP параметров',
  [DeviceCommandType.linkSip]: 'Привязка устройства к квартире',
  [DeviceCommandType.unlinkSip]: 'Отвязка устройства от квартиры',
  [DeviceCommandType['set-user-password']]: 'Изменение пароля администратора',
  [DeviceCommandType.reboot]: 'Перезагрузка устройства',
  [DeviceCommandType.unlock]: 'Открытие двери',
  [DeviceCommandType['add-rfid']]: 'Добавление ключа RFID',
  [DeviceCommandType['remove-rfid']]: 'Удаление ключа RFID',
  [DeviceCommandType['set-intercom-config']]: 'Изменение свойств устройства',
  [DeviceCommandType['video-from-camera']]: 'Запрос видео с камеры',
  [DeviceCommandType['block-call-apartment']]: 'Блокировка вызова квартиры',
  [DeviceCommandType['apartment-info']]: 'Получение информации по квартире',
  [DeviceCommandType['set-sip-peer-tenant']]: 'Установка SIP на устройстве',
};
