import React, { PropsWithChildren, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icon, IconProps, Popup, PopupProps } from 'semantic-ui-react';
import styled from 'styled-components';
import cx from 'classnames';
import { ThemeVars } from '../../../../../core/styles/vars';

interface StyledProps {
  light?: boolean;
}

interface Props extends StyledProps {
  color?: string;
  url?: string;
  iconSize?: IconProps['size'];
  iconName?: IconProps['name'];
  className?: string;
  popup?: PopupProps;
}

function Label(props: PropsWithChildren<Props>) {
  const { children, color, url, iconSize = 'small', iconName = 'circle', className, popup } = props;

  const style = useMemo(() => ({ color }), [color]);
  const classNamePrepared = cx('status-label rounded', className);

  const content = (
    <React.Fragment>
      <Icon name={iconName} style={style} size={iconSize} />
      {children}
    </React.Fragment>
  );

  let contentWrapper;
  if (url) {
    contentWrapper = (
      <Link className={classNamePrepared} to={url}>
        {content}
      </Link>
    );
  } else {
    contentWrapper = <div className={classNamePrepared}>{content}</div>;
  }

  if (popup) {
    return <Popup trigger={contentWrapper} position={'right center'} inverted {...popup} />;
  }

  return contentWrapper;
}

export const LabelStatus = styled(Label)<Props>`
  display: inline-flex;
  align-items: center;
  font-weight: normal;
  font-size: ${(props) => (props.light ? 14 : 16)}px;
  line-height: ${(props) => (props.light ? '20px' : '24px')};
  padding: 0.125rem 0.8125rem;

  &.rounded {
    border: 2px solid ${ThemeVars.colors.linkWater};
    padding: 0.125rem 0.8125rem;
  }

  i.icon {
    line-height: 24px;
    height: auto;

    &.small {
      margin-right: 8px;
    }

    &.large {
      font-size: 16px;
      margin-right: 16px;
    }
  }
`;
