import React, { FC, useCallback, useState } from 'react';
import { Checkbox, CheckboxProps, Dimmer, Form } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { PX } from 'core/utils/promise';
import { Intercom } from 'core/constants/contracts';
import { updateIntercom } from 'core/store/features/intercoms';
import { Loader } from 'components/share/loader/component';
import { Card } from 'components/share';

interface MonitoringItem {
  alias: string;
  name: string;
}

const MONITORING_LIST: Array<MonitoringItem> = [
  {
    alias: 'sip',
    name: 'SIP',
  },
  {
    alias: 'ping',
    name: 'Ping',
  },
  {
    alias: 'api',
    name: 'API',
  },
  {
    alias: 'rtsp',
    name: 'RTSP',
  },
  {
    alias: 'imageQuality',
    name: 'Кач. картинки',
  },
];

interface Props {
  intercom: Intercom;
  isDisabled: boolean;
}

export const Monitoring: FC<Props> = ({ intercom: { id: intercomId, monitoring = {} }, isDisabled }) => {
  const [isPending, setPending] = useState(false);

  const dispatch = useDispatch();
  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      const m = {
        ...monitoring,
        [data['alias']]: data.checked,
      };

      setPending(true);
      const action = dispatch(
        updateIntercom(intercomId, {
          monitoring: m,
        })
      );
      const promise = Promise.resolve(action);

      return PX.executeAtLeast(promise, 300).finally(() => setPending(false));
    },
    [dispatch, intercomId, monitoring]
  );

  return (
    <Card title="Мониторинг">
      <Form>
        <Dimmer.Dimmable>
          <Loader active={isPending} />

          <Form.Group widths={6}>
            {MONITORING_LIST.map((v, index) => {
              return (
                <Form.Field key={index}>
                  <Checkbox
                    alias={v.alias}
                    label={v.name}
                    toggle
                    disabled={isPending || isDisabled}
                    onChange={handleChange}
                    checked={monitoring[v.alias]}
                  />
                </Form.Field>
              );
            })}
          </Form.Group>
        </Dimmer.Dimmable>
      </Form>
    </Card>
  );
};
