import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.less';

export default class TextField extends Component {
  render() {
    const { title } = this.props;

    return (
      <div className={'cp-text-field'}>
        <label className={'cp-label'}>{title}</label>
        <div>{this.props.children}</div>
      </div>
    );
  }
}

TextField.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.any,
  description: PropTypes.string,
};
