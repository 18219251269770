import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Field, formPropTypes, formValueSelector, reduxForm } from 'redux-form';
import { Button, Form, Grid } from 'semantic-ui-react';
import { validateBySchema } from 'core/utils/validate';
import IndividualForm from '../components/forms/individual-form-body';
import EntityForm from '../components/forms/entity-form-body';
import schemaLegalEntityFactory from '../components/forms/entity-form-body/schema';
import schemaIndividualFactory from '../components/forms/individual-form-body/schema';
import {
  COMPANY_COMMERCIAL_STATUS_COMMERCIAL,
  COMPANY_COMMERCIAL_STATUS_MAP,
  COMPANY_CONTRACTOR_TYPE_INDIVIDUAL,
  COMPANY_CONTRACTOR_TYPE_LEGAL,
} from 'core/constants/domain/companies';
import { mapToOptions } from 'core/utils/forms';
import { FormButtonGroup } from 'components/share/form';
import { fetchCompany, saveCompany } from 'core/store/actions/companies';
import { requestErrorHandlerForReduxForm } from 'core/utils/errors';
import { withToastManager } from 'react-toast-notifications';
import { showToast } from '../../../../core/utils/toast';

const commercialStatusOptions = Object.entries(COMPANY_COMMERCIAL_STATUS_MAP).map(mapToOptions);
const commercialStatusValues = commercialStatusOptions.map((item) => item.value);

const schemaLegalEntitySchema = schemaLegalEntityFactory(commercialStatusValues);
const schemaIndividualSchema = schemaIndividualFactory(commercialStatusValues);

const validate = (values, props) => {
  let schema;

  switch (props.type) {
    case COMPANY_CONTRACTOR_TYPE_INDIVIDUAL:
      schema = schemaIndividualSchema;
      break;
    case COMPANY_CONTRACTOR_TYPE_LEGAL:
      schema = schemaLegalEntitySchema;
      break;
    default:
      throw new Error('Invalid validation type');
  }

  return validateBySchema(schema, values);
};

class CompanyEditForm extends React.Component {
  state = {
    isCompanyUpdating: false,
  };

  handleSave = (values) => {
    const { personal_account, commercial_status, foris, isEditForm, ...rest } = values;

    const newForis = foris && foris !== '' ? foris : null;

    const data = {
      id: this.props.company.id,
      foris: commercial_status === COMPANY_COMMERCIAL_STATUS_COMMERCIAL ? newForis : undefined,
      commercial_status: commercial_status,
      person: rest,
      personal_account: personal_account,
    };

    return this.props
      .saveCompany(data)
      .then(() =>
        showToast({
          toastManager: this.props.toastManager,
          message: 'Данные успешно сохранены',
          type: 'success',
        })
      )
      .then(() => this.props.history.push(`/companies/${this.props.company.id}`))
      .catch((err) => requestErrorHandlerForReduxForm(err));
  };

  render() {
    const {
      handleSubmit,
      invalid,
      submitting,

      type,
      showForis,
    } = this.props;

    let form;
    if (type === COMPANY_CONTRACTOR_TYPE_INDIVIDUAL) {
      form = (
        <IndividualForm
          field={Field}
          showForis={showForis}
          commercialStatuses={commercialStatusOptions}
          isEditForm={Boolean(this.props.company.id)}
        />
      );
    } else {
      form = (
        <EntityForm
          field={Field}
          showForis={showForis}
          commercialStatuses={commercialStatusOptions}
          isEditForm={Boolean(this.props.company.id)}
        />
      );
    }

    return (
      <Form error={invalid}>
        <Grid>
          <Grid.Row stretched>
            <Grid.Column>{form}</Grid.Column>
          </Grid.Row>
          <Grid.Row className={'row-button'}>
            <Grid.Column>
              <FormButtonGroup>
                <Button secondary onClick={this.props.handleCancel}>
                  Отменить
                </Button>
                <Button
                  primary
                  floated={'right'}
                  tall
                  disabled={submitting}
                  onClick={handleSubmit(this.handleSave)}
                  className={'width-medium'}
                >
                  Сохранить
                </Button>
              </FormButtonGroup>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

CompanyEditForm.propTypes = {
  ...formPropTypes,
  type: PropTypes.string,
  company: PropTypes.object.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  saveCompany: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export const FORM_ID = 'edit_company';
const selector = formValueSelector(FORM_ID);

const mapStateToProps = (state, props) => {
  const company = props.company;
  const commercialStatus = selector(state, 'commercial_status');

  let showForis = company.commercial_status === COMPANY_COMMERCIAL_STATUS_COMMERCIAL;
  if (commercialStatus !== undefined) {
    showForis = commercialStatus === COMPANY_COMMERCIAL_STATUS_COMMERCIAL;
  }

  return {
    type: company.person_type,
    showForis: showForis,
    initialValues: {
      // common
      inn: company.person.inn,

      // legal
      name: company.person.name,
      phone: company.person.phone,
      web_site: company.person.web_site,
      ogrn: company.person.ogrn,
      fact_address: company.person.fact_address,
      legal_address: company.person.legal_address,

      // individual
      last_name: company.person.last_name,
      first_name: company.person.first_name,
      mid_name: company.person.mid_name,
      address: company.person.address,

      // company
      personal_account: company.personal_account,
      commercial_status: company.commercial_status,
      status: company.administrative_status,
      foris: company.foris,

      // logic
      isEditForm: true,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompany: bindActionCreators(fetchCompany, dispatch),
    saveCompany: bindActionCreators(saveCompany, dispatch),
  };
};

let component;
component = reduxForm({ form: FORM_ID, validate })(CompanyEditForm);
component = withRouter(component);
component = withToastManager(component);
component = connect(mapStateToProps, mapDispatchToProps)(component);

export default component;
