import React, { FC, useEffect } from 'react';
import { FormikProps } from 'formik';
import debounce from 'lodash/debounce';
import { Form, Icon, SemanticICONS } from 'semantic-ui-react';
import { FormData, initialValues } from '.';
import { FlatButton } from 'components/share/components';
import { FormContainer, FieldsBox, FieldInputCSS, FieldDropdownCSS } from './styled';
import { DropdownOption } from 'components/share/redux-form/address-dropdown/handler';

type FormInnerProps = FormikProps<FormData> & {
  companiesStore: {
    companies: Partial<DropdownOption>[];
    loadingCompanies: boolean;
  };
};

const FormInner: FC<FormInnerProps> = ({
  values,
  dirty,
  companiesStore: { companies, loadingCompanies },
  handleSubmit,
  resetForm,
}) => {
  useEffect(() => {
    if (values !== initialValues) {
      debounce(handleSubmit, 500)();
    }
  }, [values, handleSubmit]);

  const handleReset = () => {
    resetForm();
    handleSubmit();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormContainer>
        <FieldsBox>
          <FieldInputCSS
            name="address"
            placeholder="Адрес"
            icon={<Icon name={'mts-icon-search' as SemanticICONS} />}
          />
          <FieldDropdownCSS
            name="owner_id"
            placeholder="Управляющая компания"
            loading={loadingCompanies}
            options={companies}
            clearable
            search
          />
        </FieldsBox>
        <FlatButton onClick={handleReset} disable={!dirty}>
          Очистить все
        </FlatButton>
      </FormContainer>
    </Form>
  );
};

export default FormInner;
