import { createSelector } from 'reselect';
import { GenericParametricSelector, PossibleIdProps } from 'core/store/selectors';
import { createSelectIntercomsAggAsArray, IntercomAgg } from 'core/store/selectors/intercoms';
import { getApartmentsMetadata } from 'core/store/selectors/apartments';

export interface IntercomAggAndCount extends IntercomAgg {
  count: number;
}

export const createSelectIntercom = (): GenericParametricSelector<
  PossibleIdProps,
  Array<IntercomAggAndCount>
> => {
  const selectIntercomsAggAsArray = createSelectIntercomsAggAsArray();

  return createSelector(
    [selectIntercomsAggAsArray, getApartmentsMetadata],
    (intercoms, apartmentMetadata) => {
      return intercoms.map((intercom) => {
        return {
          ...intercom,
          count: apartmentMetadata?.total ?? 0,
        };
      });
    }
  );
};
