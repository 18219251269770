import React from 'react';
import { Icon } from 'semantic-ui-react';
import { CompanyCommercialStatus } from 'core/constants/contracts';
import { COMPANY_COMMERCIAL_STATUS_MAP } from 'core/constants/domain/companies';
import './styles.less';

interface Props {
  status: CompanyCommercialStatus;
}

export function CommercialStatus({ status }: Props) {
  const text = COMPANY_COMMERCIAL_STATUS_MAP[status];

  let color;
  switch (status) {
    case CompanyCommercialStatus.test: {
      color = '#E30611';
      break;
    }
    case CompanyCommercialStatus.pilot: {
      color = '#6E7782';
      break;
    }
    case CompanyCommercialStatus.commercial: {
      color = '#26CD58';
      break;
    }
  }

  return (
    <div className={'commercial-status'}>
      <Icon name={'cube'} circular style={{ backgroundColor: color }} />
      {text}
    </div>
  );
}
