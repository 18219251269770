import React, { Component } from 'react';
import { Grid, Card, Header } from 'semantic-ui-react';
import AddIntercomWidget from '../../widgets/add_intercom';
import CreateCompanyWidget from '../../widgets/create_company';
import ViewReportsWidget from '../../widgets/view_reports';
import './styles.less';

export default class MainPage extends Component {
  render() {
    return (
      <Grid>
        <Grid.Row>
          <div className={'main-dashboard'}>
            <Grid.Column textAlign={'left'}>
              <Header as={'h2'}>Добро пожаловать в систему Smart Intercom!</Header>
            </Grid.Column>
          </div>
        </Grid.Row>
        <Grid.Row>
          <div className={'main-dashboard'}>
            <Grid.Column textAlign={'left'}>
              <Card.Group itemsPerRow={3}>
                <CreateCompanyWidget />
                <AddIntercomWidget />
                <ViewReportsWidget />
              </Card.Group>
            </Grid.Column>
          </div>
        </Grid.Row>
      </Grid>
    );
  }
}
