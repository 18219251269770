import React, { ReactNode } from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';
import Dropdown from '../dropdown';
import AddressDropdownHandler, {
  changeHandler,
  ComponentProps as AddressDropdownHandlerProps,
  HandlerProps,
} from './handler';
import './styles.less';
import { Checkbox } from '../index';

interface OwnProps extends AddressDropdownHandlerProps {}

interface Props extends OwnProps {}

interface DropdownProps {
  onChange: changeHandler;
}

class AddressDropdown extends React.Component<Props> {
  renderProps = (props: HandlerProps) => {
    const {
      input: { name },
    } = this.props;

    const {
      isFetching,
      readOnly,
      readOnlyBuilding,
      showCheckboxEntranceUnion,
      showEntrance,
      showApartment,
      showFloor,
      requiredBuilding,
      requiredEntrance,
      requiredApartment,
      requiredFloor,

      buildingOptions,
      floorOptions,
      apartmentOptions,
      entranceOptions,

      handleChangeBuilding,
      handleChangeApartment,
      handleChangeFloor,
      handleChangeEntrance,

      entrancesUnion,
    } = props;

    return (
      <React.Fragment>
        <Field
          name={`${name}.building`}
          component={Dropdown}
          required={requiredBuilding}
          disabled={readOnly || readOnlyBuilding}
          label={'Адрес'}
          search
          options={buildingOptions}
          onChange={handleChangeBuilding as any}
          noResultsMessage={'Дома не найдены'}
          // width={5}
        />
        <Form.Field>
          <Form.Group widths={2} className={'address-dropdown-bindings'}>
            {showApartment && (
              <Field
                name={`${name}.apartment`}
                component={Dropdown}
                required={requiredApartment}
                disabled={readOnly}
                label={'Квартира'}
                search
                options={apartmentOptions}
                onChange={handleChangeApartment as any}
                noResultsMessage={'Квартира не найдена'}
                loading={isFetching}
                // width={4}
              />
            )}
            {showFloor && (
              <Field
                name={`${name}.floor`}
                component={Dropdown}
                required={requiredFloor}
                disabled={readOnly}
                label={'Этаж'}
                search
                options={floorOptions}
                onChange={handleChangeFloor as any}
                noResultsMessage={'Этаж не найдены'}
                loading={isFetching}
                // width={4}
              />
            )}
            {showEntrance && (
              <Field
                name={`${name}.entrance`}
                component={Dropdown}
                required={requiredEntrance}
                disabled={readOnly}
                label={'Подъезд'}
                search
                options={entranceOptions}
                onChange={handleChangeEntrance as any}
                noResultsMessage={'Подъезд не найден'}
                loading={isFetching}
                // width={4}
              />
            )}
          </Form.Group>
          {showCheckboxEntranceUnion && entrancesUnion && (
            <Field
              name={`${name}.entrances_union`}
              component={Checkbox}
              checkedValue={entrancesUnion.id}
              label={'Сквозной подъезд'}
            />
          )}
        </Form.Field>
      </React.Fragment>
    );
  };

  render(): ReactNode {
    return <AddressDropdownHandler {...this.props}>{this.renderProps}</AddressDropdownHandler>;
  }
}

export default AddressDropdown;
