import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Wizard from 'components/share/components/wizard';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

const STEP_1 = 'step1';
const STEP_2 = 'step2';
const STEP_3 = 'step3';
const STEP_4 = 'step4';

const steps = [
  {
    key: STEP_1,
    icon: 'microchip',
    title: 'Общие сведения',
  },
  {
    key: STEP_2,
    icon: 'phone',
    title: 'SIP-параметры',
  },
  {
    key: STEP_3,
    icon: 'save outline',
    title: 'Администрирование',
  },
  {
    key: STEP_4,
    icon: 'camera',
    title: 'Камера',
  },
];

const components = {
  [STEP_1]: Step1,
  [STEP_2]: Step2,
  [STEP_3]: Step3,
  [STEP_4]: Step4,
};

const transitions = [
  {
    from: STEP_1,
    to: STEP_2,
  },
  {
    from: STEP_2,
    to: STEP_3,
  },
  {
    from: STEP_3,
    to: STEP_4,
  },
];

@withRouter
class IntercomDeviceType extends React.Component {
  render() {
    return (
      <Wizard
        stateful
        defaultStep={STEP_1}
        href='/intercoms/create/:step'
        header='Добавление домофона'
        steps={steps}
        formProps={{
          history: this.props.history,
        }}
        transitions={transitions}
        components={components}
      />
    );
  }
}

IntercomDeviceType.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default IntercomDeviceType;
