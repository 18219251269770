import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { VdomeLogo } from 'components/share/assets';
import { CurrentCompany, CurrentUser } from 'core/constants/contracts';
import BlockedCompanyMenuItem from './menu-items/blocked-company';
// import NotificationMenuItem from './menu-items/notification';
// import SelectCompanyMenuItem from './menu-items/select-company';
import ProfileMenuItem from './menu-items/profile';

interface Props {
  currentUser: CurrentUser;
  currentCompany: CurrentCompany;
}

class TopMenu extends React.PureComponent<Props> {
  render() {
    const { currentCompany } = this.props;
    const { company } = currentCompany;

    return (
      <Menu borderless pointing size="large" className="top-menu" fixed="top">
        <Menu.Item as={Link} to="/" className="logo">
          <div className="logo-container">
            <VdomeLogo />
            <div className="title">АРМ<br />VDome</div>
          </div>
        </Menu.Item>
        <Menu.Menu position="right">
          <BlockedCompanyMenuItem company={company} />
          {/*<SelectCompanyMenuItem company={company} />*/}
          {/*<NotificationMenuItem />*/}
          <ProfileMenuItem
          // user={currentUser.user}
          />
        </Menu.Menu>
      </Menu>
    );
  }
}

export default TopMenu;
