export type UrlType = {
  protocol?: string;
  hostname?: string;
  port?: string | number;
  path?: string;
  username?: string;
  password?: string;
};

export const reURLInformation = new RegExp(
  [
    '^([A-Za-z\\d@$!%*#?&-]*)\\:\\/\\/', // protocol
    '([A-Za-z\\d@$!%*#?&]*[\\:])?', // username
    '([A-Za-z\\d$!%*#?&]*[\\@])?', // password
    '([^:/?#]*)\\:?', // hostname
    '([0-9]+)?\\/?', // port
    '([^#]*)?$', // pathname
  ].join('')
);

export const urlBuilder = (url: UrlType) => {
  const { protocol, hostname, port, path, username, password } = url;
  const fillProtocol = 'ПРОТОКОЛ';
  const fillHost = 'ХОСТ';

  return `${protocol || fillProtocol}://${username ? username + ':' : ''}${
    password ? password + '@' : ''
  }${hostname || fillHost}${port ? ':' + port : ''}${path ? '/' + path : ''}`;
};

export const parseUrl = (value: string): UrlType | null => {
  const match = value.match(reURLInformation);

  if (match) {
    const [, protocol, username, password, hostname, port, path] = match;
    return {
      protocol,
      username: username && username.slice(0, -1),
      password: password && password.slice(0, -1),
      hostname,
      port,
      path,
    };
  }

  return null;
};
