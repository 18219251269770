import React, { useCallback, useMemo } from 'react';
import { CellProps } from 'react-table';
import { Button } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import { fetchUsers } from 'core/store/features/users';
import { User } from 'core/constants/contracts';
import { wrapEntitiesTableSelectorFactory } from 'core/store/selectors';
import { TableRedux, TableReduxFetchOptions } from 'components/share/components/table/redux';
import { Column } from 'components/share/components/table';
import { selectUsersWithCompany, UserAndCompany } from './data/selectors';

interface Props {
  filter?: object;
}

function ClientTable(props: Props) {
  const { filter } = props;
  const query = new URLSearchParams(useLocation().search);

  const columns: Array<Column<UserAndCompany>> = useMemo(
    () => [
      {
        Header: 'ФИО',
        accessor: 'name',
        cellProps: () => {
          return {
            width: 4,
          };
        },
      },
      {
        Header: 'Компания',
        Cell: ({ row: { original } }: CellProps<UserAndCompany>) => {
          if (!original.company) {
            return '';
          }

          return (
            <Link
              to={{
                pathname: `/companies/${original.company.id}`,
                state: {
                  tableParams: {
                    page: query.get('page'),
                    size: query.get('size'),
                  },
                },
              }}
            >
              {original.company.name}
            </Link>
          );
        },
        cellProps: () => {
          return {
            width: 6,
          };
        },
      },
      {
        Header: 'Номер телефона',
        accessor: 'phone',
        cellProps: () => {
          return {
            width: 4,
          };
        },
      },
      {
        Header: 'Действия',
        Cell: (cell: CellProps<User>) => {
          return (
            <React.Fragment>
              <Button
                icon={'user outline'}
                as={Link}
                to={{
                  pathname: `/clients/${cell.row.original.id}`,
                  state: {
                    tableParams: {
                      page: query.get('page'),
                      size: query.get('size'),
                    },
                  },
                }}
              />
            </React.Fragment>
          );
        },
        cellProps: () => {
          return {
            width: 2,
          };
        },
      },
    ],
    [query]
  );
  const selector = useCallback(wrapEntitiesTableSelectorFactory(selectUsersWithCompany), []);
  const fetcher = useCallback(
    (params: TableReduxFetchOptions) =>
      fetchUsers(
        {
          page: params.pageIndex + 1,
          limit: params.pageSize,
          filter: filter,
        },
        {
          renew: true,
        }
      ),
    [filter]
  );

  return (
    <TableRedux
      columns={columns}
      fetcher={fetcher}
      selector={selector}
      showUpperPagination
    />
  );
}

export default ClientTable;
