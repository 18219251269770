import React, { FC, useEffect, useState } from 'react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { Grid, Header, DropdownItemProps } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { reduxForm, formValueSelector, InjectedFormProps, initialize } from 'redux-form';
import { CameraForm, CameraFormData, schema } from 'components/share/containers/camera';
import { confirm } from 'components/share/dialogs';
import { updateCamera, fetchCamera } from 'core/store/features/cameras';
import notification from 'core/utils/notification';
import { clear as clearIntercoms } from 'core/store/features/intercoms';
import { clear as clearCameras } from 'core/store/features/cameras';
import { validateBySchema } from 'core/utils/validate';
import { UrlType, parseUrl } from 'core/utils/url';
import { isSectionLoading } from 'core/store/selectors/app';
import { RootState } from 'core/store';
import { Intercom, Camera, CameraStreamMode } from 'core/constants/contracts';
import { Loader } from 'components/share';
import {
  fetchCameraData as fetchData,
  SECTION_LOADING_NAME,
} from 'views/pages/devices/abstract/view/data/fetchCamera';
import { DataForCamera } from 'components/share/containers/camera/form/config';
import { buildAddressStr } from 'core/utils/address';
import {
  DEFAULT_TABLE_PARAMS,
  LocationStateNavigation,
  pathWithTableParams,
} from 'core/utils/routing';

import { fetchRestreamers } from 'core/store/features/restreamers';
import { getRestreamersItems } from 'core/store/selectors/restreamers';
import { createSelectArrayToArray } from 'core/store/selectors';
import { Restreamer } from 'core/constants/contracts';
import { isRestreamerMode } from 'core/utils/restreamer-mode';

type ConnectProps = ConnectedProps<typeof withConnect>;

type RouteParams = {
  intercomId?: string;
  cameraId: string;
};

type CameraEditProps = ConnectProps &
  RouteComponentProps<RouteParams, any, LocationStateNavigation> &
  InjectedFormProps<CameraFormData, CameraEditProps>;

const validate = (values: CameraFormData) => {
  return validateBySchema(schema, values);
};

const CameraEdit: FC<CameraEditProps> = ({
  intercom,
  camera,
  formData,
  invalid,
  isLoading,
  anyTouched,
  history,
  location,
  match,
  isAdmin,
  restreamers,
  change,
  handleSubmit,
  fetchData,
  fetchCamera,
  updateCamera,
  getRestreamers,
  clearSection,
  initializeForm,
}) => {
  const { intercomId, cameraId } = match.params;
  const { tableParams } = location.state || DEFAULT_TABLE_PARAMS;
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    getRestreamers();

    if (intercomId && cameraId) {
      fetchData({ intercomId, cameraId });
    } else {
      fetchCamera(cameraId);
    }

    return () => {
      clearSection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraId, intercomId]);

  useEffect(() => {
    if (camera) {
      const { name, streams, device, stream_mode, rtsp, settings } = camera;
      if (streams) {
        const url = streams[Object.keys(streams)[0]];
        const urlObj = parseUrl(decodeURIComponent(url));
        const address = device && buildAddressStr(device);

        if (urlObj) {
          const { protocol, auth, host: hostname, port, stream: path, url } = settings.rtsp;
          initializeForm({
            name: name ?? '',
            protocol,
            username: auth.login,
            password: auth.password,
            hostname,
            port,
            path,
            url,
            timezone: device?.timezone,
            address,
            owner: device?.owner?.name,
            stream_mode,
            rtsp: isRestreamerMode(stream_mode) ? rtsp : '',
          });
        } else {
          initializeForm({
            name: name ?? '',
            url,
            timezone: device?.timezone,
          });
        }
      }
    }
  }, [camera, initializeForm]);

  const changeIsFetching = (isFetching: boolean) => setIsFetching(isFetching);

  const routeToIntercom = () => {
    history.push(
      intercomId
        ? `/intercoms/${intercomId}`
        : pathWithTableParams('/cameras', tableParams.page, tableParams.size)
    );
  };

  const handleCancel = (isChanged: boolean) => {
    if (isChanged) {
      confirm({
        content: 'Вы уверены, что хотите отменить изменение камеры?',
        onConfirm: routeToIntercom,
      });
    } else {
      routeToIntercom();
    }
  };

  const handleGetStreamFromRestremer = async () => {
    const [restreamer] = restreamers;

    setIsFetching(true);

    let message;

    const data: Partial<DataForCamera> = {
      restreamer: restreamer.value as string,
      stream_mode: CameraStreamMode.restreamer,
    };

    if (camera) {
      try {
        const cameraResult = await updateCamera(camera.id, data);
        message = _.get(cameraResult, 'error');
      } catch (e) {
        console.error(e);
        message = (e as any).message;
      } finally {
        setIsFetching(false);
      }
    }

    if (message) {
      notification.error(message);
    }
  };

  const handleSubmitForm = async (values: CameraFormData) => {
    const {
      name,
      timezone,
      protocol,
      username,
      password,
      hostname,
      port,
      path,
      stream_mode,
      rtsp,
    } = values;
    const [restreamer] = restreamers;

    confirm({
      content: 'Вы уверены, что хотите сохранить данные камеры?',
      onConfirm: async () => {
        setIsFetching(true);
        let message;

        const data: DataForCamera = {
          stream: {
            protocol,
            username,
            password,
            hostname,
            port: port ? +port : undefined,
            path,
          },
          name,
          stream_mode,
          rtsp,
          device: {
            timezone: timezone === '' ? null : timezone,
          },
        };

        if (isRestreamerMode(stream_mode)) data.restreamer = restreamer.value as string;

        if (camera) {
          try {
            const cameraResult = await updateCamera(camera.id, data);
            message = _.get(cameraResult, 'error');
          } catch (e) {
            console.error(e);
            message = e.message;
          } finally {
            setIsFetching(false);
          }
        } else {
          setIsFetching(false);
          message = 'Произошла ошибка, отсутствуют данные камеры';
        }

        if (message) {
          notification.error(message);
        } else {
          notification.success();
          setTimeout(routeToIntercom, 1000);
        }
      },
    });
  };

  const handleValidate = (values: CameraFormData) => {
    validate(values);
  };

  if (isLoading) {
    return <Loader active={true} placeholder={true} />;
  }

  let breadcrumbs: JSX.Element = (
    <>
      <SetBreadcrumb url={pathWithTableParams('/cameras', tableParams.page, tableParams.size)}>
        Камеры
      </SetBreadcrumb>
      <SetBreadcrumb>{camera?.name || 'Камера'}</SetBreadcrumb>
    </>
  );

  if (intercomId) {
    breadcrumbs = (
      <>
        <SetBreadcrumb url={'/intercoms'}>Домофоны</SetBreadcrumb>
        <SetBreadcrumb url={`/intercoms/${intercomId}`}>
          {intercom?.phone || 'Домофон'}
        </SetBreadcrumb>
        <SetBreadcrumb>{camera?.name || 'Камера'}</SetBreadcrumb>
      </>
    );
  }

  return (
    <Grid>
      {breadcrumbs}

      <Grid.Row>
        <Grid.Column>
          <Header as="h1">Карточка камеры</Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <CameraForm
            formProps={{ invalid, change, anyTouched }}
            formData={formData}
            isFetching={isFetching}
            setIsFetching={changeIsFetching}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit(handleSubmitForm)}
            validateFields={handleSubmit(handleValidate)}
            handleGetStream={handleGetStreamFromRestremer}
            isAdmin={isAdmin}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const FORM_ID = 'edit_camera';
const selector = formValueSelector(FORM_ID);

const CameraEditReduxForm = reduxForm<CameraFormData, CameraEditProps>({
  form: FORM_ID,
  validate,
})(CameraEdit);

const selectDeviceReferencesAsOptions = createSelectArrayToArray<Restreamer, DropdownItemProps>(
  (v) => ({
    value: v.id,
    text: v.name,
  })
);

const mapStateToProps = (state: RootState, { match }: RouteComponentProps<RouteParams>) => {
  const { intercomId, cameraId } = match.params;

  const url: UrlType = selector(
    state,
    'protocol',
    'hostname',
    'port',
    'path',
    'username',
    'password'
  );
  const urlString: string = selector(state, 'url');
  const address: string = selector(state, 'address');
  const owner: string = selector(state, 'owner');
  const streamMode: string = selector(state, 'stream_mode');
  const rtsp: string = selector(state, 'rtsp');

  return {
    formData: {
      url,
      urlString,
      address,
      owner,
      streamMode,
      rtsp,
    },
    intercom: intercomId
      ? (state.entities.intercoms.items[intercomId] as Intercom | undefined)
      : undefined,
    camera: state.entities.cameras.items[cameraId] as Camera | undefined,
    isLoading: intercomId
      ? isSectionLoading(state, SECTION_LOADING_NAME, true)
      : state.entities.cameras.pending,
    isAdmin: state.appx.user?.isAdmin || false,
    restreamers: selectDeviceReferencesAsOptions(
      getRestreamersItems(state)
    ) as Array<DropdownItemProps>,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const clearSection = () => {
    bindActionCreators(clearIntercoms, dispatch)();
    bindActionCreators(clearCameras, dispatch)();
  };

  return {
    fetchData: bindActionCreators(fetchData, dispatch),
    fetchCamera: bindActionCreators(fetchCamera, dispatch),
    updateCamera: bindActionCreators(updateCamera, dispatch),
    getRestreamers: bindActionCreators(fetchRestreamers, dispatch),
    initializeForm: (data: Partial<CameraFormData>) => dispatch(initialize(FORM_ID, data)),
    clearSection,
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(CameraEditReduxForm);
