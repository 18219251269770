import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'core/constants/app';

export type TableParams = {
  page: string;
  size: string;
};

export type LocationStateNavigation = {
  tableParams: TableParams;
};

export const DEFAULT_TABLE_PARAMS = {
  tableParams: {
    page: DEFAULT_PAGE_INDEX,
    size: DEFAULT_PAGE_SIZE,
  },
};

export function pathWithTableParams(
  url: string,
  page?: string | number,
  size?: string | number
): string {
  if (page && size) {
    return `${url}?page=${page}&size=${size}`;
  }

  return url;
}
