import { createSelector } from 'reselect';
import { Binding } from '../../constants/contracts';
import { GenericSelector } from '.';
import { RootState } from '..';

export const getBindings = (state: RootState) => state.entities.bindings;
export const getBindingsItems = (state: RootState) => state.entities.bindings.items;
export const getBindingsIds = (state: RootState) => state.entities.bindings.ids;

export const selectBindingsAsArray = createSelector([getBindingsItems], (bindings) =>
  Object.values(bindings)
);

export const createSelectBindingsAsArray = (): GenericSelector<Array<Binding>> =>
  createSelector([getBindingsItems], (bindings) => Object.values(bindings));
