import React from 'react';
import { RouteComponentProps } from 'react-router';
import ClientContainer from './container';
import { SetBreadcrumb } from '../../../../components/share/breadcrumbs';
import {
  DEFAULT_TABLE_PARAMS,
  LocationStateNavigation,
  pathWithTableParams,
} from 'core/utils/routing';

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams, any, LocationStateNavigation> {}

export function ClientView(props: Props) {
  const { tableParams } = props.location.state || DEFAULT_TABLE_PARAMS;

  return (
    <React.Fragment>
      <SetBreadcrumb url={pathWithTableParams('/clients', tableParams.page, tableParams.size)}>
        Клиенты
      </SetBreadcrumb>

      <ClientContainer id={props.match.params.id} />
    </React.Fragment>
  );
}
