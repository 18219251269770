import React from 'react';
import './styles.less';

interface Props {
  max: number;
  current: number;
  buffered?: number;
  onSeeking?: (time: number, percent: number) => void;
  onChange?: (isSeeking: boolean, time: number, percent: number) => void;
  className?: string;
}

interface State {
  trackWidth: number;
  seekHoverPosition: number;
}

export type VideoSeekSliderProps = Props;

export class VideoSeekSlider extends React.Component<Props, State> {
  private readonly className = 'video-slider';

  state: State = {
    trackWidth: 0,
    seekHoverPosition: 0,
  };

  static defaultProps: Props = {
    max: 100,
    current: 0,
    buffered: 0,
    offset: 0,
  } as Props;

  private seeking?: boolean;
  private mobileSeeking?: boolean;
  private track = React.createRef<HTMLDivElement>();

  public componentDidMount(): void {
    this.setTrackWidthState();
    window.addEventListener('resize', this.setTrackWidthState);
    window.addEventListener('mousemove', this.handleSeeking);
    window.addEventListener('mouseup', this.mouseSeekingHandler);
    window.addEventListener('touchmove', this.handleTouchSeeking);
    window.addEventListener('touchend', this.mobileTouchSeekingHandler);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.setTrackWidthState);
    window.removeEventListener('mousemove', this.handleSeeking);
    window.removeEventListener('mouseup', this.mouseSeekingHandler);
    window.removeEventListener('touchmove', this.handleTouchSeeking);
    window.removeEventListener('touchend', this.mobileTouchSeekingHandler);
  }

  private handleTouchSeeking = (event: any): void => {
    let pageX: number = 0;

    for (let i = 0; i < event.changedTouches.length; i++) {
      pageX = event.changedTouches[i].pageX;
    }

    pageX = pageX < 0 ? 0 : pageX;

    if (this.mobileSeeking) {
      this.changeCurrentTimePosition(pageX);
    }
  };

  private handleSeeking = (event: MouseEvent): void => {
    if (this.seeking) {
      this.changeCurrentTimePosition(event.pageX);
    }
  };

  private changeCurrentTimePosition(pageX: number): void {
    let position: number = pageX - this.track.current!.getBoundingClientRect().left;

    position = position < 0 ? 0 : position;
    position = position > this.state.trackWidth ? this.state.trackWidth : position;

    this.setState({ seekHoverPosition: position });

    const percent: number = position / this.state.trackWidth;
    const time: number = +(percent * this.props.max).toFixed(0);

    this.props.onSeeking?.(time, percent);
  }

  private setTrackWidthState = (): void => {
    if (this.track) {
      this.setState({ trackWidth: this.track.current!.offsetWidth });
    }
  };

  private handleTrackHover = (clear: boolean, e: any): void => {
    let position: number = e.pageX - this.track.current!.getBoundingClientRect().left;

    if (clear) {
      position = 0;
    }

    this.setState({ seekHoverPosition: position });
  };

  private getPositionStyle(time: number): object {
    let position: number = time / this.props.max;

    return {
      transform: `scaleX(${position})`,
    };
  }

  private getThumbHandlerPosition(): object {
    let position: number = this.state.trackWidth / (this.props.max / this.props.current);

    return {
      transform: `translateX(${position}px)`,
    };
  }

  private getSeekHoverPosition(): object {
    let position: number = this.state.seekHoverPosition / this.state.trackWidth;

    return {
      transform: `scaleX(${position})`,
    };
  }

  private mouseSeekingHandler = (event: MouseEvent): void => {
    if (this.seeking) {
      this.setSeeking(false, event);
    }
  };

  private setSeeking = (isSeeking: boolean, event: MouseEvent): void => {
    event.preventDefault();

    this.handleSeeking(event);
    this.seeking = isSeeking;

    // const position = isSeeking ? this.state.seekHoverPosition : 0;
    const position = this.state.seekHoverPosition;
    const percent: number = position / this.state.trackWidth;
    const time: number = +(percent * this.props.max).toFixed(0);

    // console.log(`>>>SLIDER:SEEKING ${isSeeking} time=${time} percent=${percent} ${this.state.seekHoverPosition}`);

    this.props.onChange?.(isSeeking, time, percent);
    // this.setState({ seekHoverPosition: position });
  };

  private mobileTouchSeekingHandler = (): void => {
    this.setMobileSeeking(false);
  };

  private setMobileSeeking = (isSeeking: boolean): void => {
    this.mobileSeeking = isSeeking;

    this.setState({ seekHoverPosition: !isSeeking ? 0 : this.state.seekHoverPosition });
  };

  private isThumbActive(): boolean {
    // @ts-ignore
    return this.state!.seekHoverPosition! > 0 || this.seeking;
  }

  public render(): JSX.Element {
    const isThumbActive = this.isThumbActive();
    const className = this.props.className
      ? this.className + ' ' + this.props.className
      : this.className;

    return (
      <div className={className}>
        <div
          className={isThumbActive ? 'track active' : 'track'}
          ref={this.track}
          onMouseMove={(e) => this.handleTrackHover(false, e)}
          onMouseLeave={(e) => this.handleTrackHover(true, e)}
          onMouseDown={(e) => this.setSeeking(true, e as any)}
          onTouchStart={() => this.setMobileSeeking(true)}
        >
          <div className={'main'}>
            <div className={'buffered'} style={this.getPositionStyle(this.props.buffered!)} />

            <div className={'seek-hover'} style={this.getSeekHoverPosition()} />

            <div className={'connect'} style={this.getPositionStyle(this.props.current)} />
          </div>
        </div>

        <div
          className={isThumbActive ? 'thumb active' : 'thumb'}
          style={this.getThumbHandlerPosition()}
        >
          <div className={'handler'} />
        </div>
      </div>
    );
  }
}
