import React from 'react';
import { CellProps, Column, UsePaginationState } from 'react-table';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Tag } from 'components/share/components';
import { ExtendedEntity } from 'core/store/selectors';

import { VideoRecorderAgg } from 'core/store/selectors/video-recorders';

type CreateColumnsType = {
  query?: URLSearchParams;
  isArmDomofon?: boolean;
};

type ColumnType = Column<ExtendedEntity<VideoRecorderAgg>>;

const Box = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-left: 1rem;
  }
`;

export const createColumns = (props: CreateColumnsType): Array<ColumnType> => {
  const colums: Array<ColumnType> = [
    {
      Header: '№',
      Cell: ({ row: { index }, state }: CellProps<VideoRecorderAgg>) => {
        const { pageIndex, pageSize } = state as unknown as UsePaginationState<VideoRecorderAgg>;

        return index + 1 + pageIndex * pageSize;
      },
      width: 65,
    },
    {
      Header: 'Название',
      accessor: 'id',
      Cell: ({ row: { original } }: CellProps<VideoRecorderAgg>) => {
        return (
          <Link
            to={{
              pathname: `/video-recorders/${original.id}/edit`,
              state: {
                tableParams: {
                  page: props?.query?.get('page'),
                  size: props?.query?.get('size'),
                },
              },
            }}
          >
            {original.name || original.id}
          </Link>
        );
      },
      width: 250,
    },
    {
      Header: 'Владелец',
      id: 'owner',
      Cell: ({ row: { original } }: CellProps<VideoRecorderAgg>) => {
        const { owner } = original.device || {};
        if (owner) {
          return (
            <Box>
              <Link to={`/companies/${owner.id}`}>{owner.name}</Link>
              {original.__owner_status && (
                <Tag variant={original.__owner_status.color}>{original.__owner_status.name}</Tag>
              )}
            </Box>
          );
        }

        return '';
      },
    },
  ];

  if (props?.isArmDomofon) {
    const index = colums.findIndex((item) => item.id === 'owner');
    colums.splice(index, 1);
  }

  return colums;
};
