import React from 'react';
import { Header, Container } from 'semantic-ui-react';
import myIcon from './images/park.svg';

export default function NotFoundPage() {
  return (
    <div className="background" style={{ paddingTop: '10em' }}>
      <Container text textAlign={'center'}>
        <Header as={'h1'} size={'huge'} textAlign={'center'}>
          Ошибка 404. Страница не найдена
        </Header>
        <p style={{ fontSize: '1.3em' }}>
          Приносим свои извинения за доставленные неудобства.
          <br />
          Мы уже работаем над устранением проблемы.
        </p>
        <img style={{ paddingTop: '10em' }} src={myIcon} alt="icon" />
      </Container>
    </div>
  );
}
