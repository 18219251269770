import { BindingType } from '../contracts';

export const BINDING_TYPE_ENTRANCES_UNION = 'entrances_union';
export const BINDING_TYPE_ENTRANCE = 'entrance';
export const BINDING_TYPE_FLOOR = 'floor';
export const BINDING_TYPE_APARTMENT = 'apartment';

export const BINDING_TYPE_TITLE_MAP = {
  [BindingType.building]: 'Здание',
  [BindingType.terrain]: 'Территория',
  [BindingType.entrancesUnion]: 'Группа',
  [BindingType.entrance]: 'Подъезд',
  [BindingType.floor]: 'Этаж',
  [BindingType.apartment]: 'Квартира',
};
