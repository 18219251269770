import React from 'react';
import { Card } from 'components/share';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { ThemeVars } from 'core/styles/vars';

const GridRow = styled(Grid.Row)`
  &&&&:not(:first-child) {
    padding-top: 2px;
  }
  &&&&:not(:last-child) {
    padding-bottom: 2px;
  }
`;

const GridColumnHeader = styled(Grid.Column)`
  color: ${ThemeVars.colors.greyChateau};
  font-size: 14px;
  line-height: 20px;
`;

const GridColumnValue = styled(Grid.Column)`
  color: ${ThemeVars.colors.deepBlue};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

interface Props {
  brand?: string;
  manufacturer?: string;
  model?: string;
  systemVersion?: string;
  platform?: string;
  appVersion?: {
    versionName?: string;
    versionCode?: number;
  };
}

export const DeviceInfo = (props: Props) => {
  if (isEmpty(props)) {
    return <div>Данные отсутствуют</div>;
  }

  const { manufacturer, model, systemVersion, platform, appVersion } = props;

  return (
    <Card.Item>
      <Grid columns={'equal'}>
        <GridRow>
          <GridColumnHeader>Марка устройства</GridColumnHeader>
          <GridColumnHeader>Модель устройства</GridColumnHeader>
          <GridColumnHeader>Платформа</GridColumnHeader>
          <GridColumnHeader>Версия платформы</GridColumnHeader>
          <GridColumnHeader>Версия приложения</GridColumnHeader>
        </GridRow>
        <GridRow>
          <GridColumnValue>{typeof manufacturer === 'string' ? manufacturer : '-'}</GridColumnValue>
          <GridColumnValue>{model || '-'}</GridColumnValue>
          <GridColumnValue>{platform || '-'}</GridColumnValue>
          <GridColumnValue title={systemVersion}>
            {systemVersion?.replace(/[^\d^.]/g, '') || '-'}
          </GridColumnValue>
          <GridColumnValue title={appVersion?.versionCode}>
            {appVersion?.versionName || '-'}
          </GridColumnValue>
        </GridRow>
      </Grid>
    </Card.Item>
  );
};
