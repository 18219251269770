import io from 'socket.io-client';

class OperationManager {
  constructor() {
    this.operations = new Map();
  }

  subscribe(token) {
    console.log(`[ws]: subscribe=${token}`);

    if (!token) {
      return;
    }

    if (this.token && this.token !== token) {
      if (this.socket && this.socket.connected) {
        this.socket.disconnect();
      }

      this.token = token;
    }

    if (this.socket && this.socket.connected) {
      return;
    }

    console.log(
      `[ws]: uri=${
        process.env.REACT_APP_NOTIFIER_SOCKETIO_URL + process.env.REACT_APP_NOTIFIER_SOCKETIO_PATH
      }`
    );

    this.socket = io.connect(process.env.REACT_APP_NOTIFIER_SOCKETIO_URL, {
      transports: ['websocket'],
      reconnectionAttempts: 5,
      path: process.env.REACT_APP_NOTIFIER_SOCKETIO_PATH + 'socket.io',
      query: {
        access_token: token,
      },
    });

    this.socket.on('connect', () => {
      console.log('[ws]: connected');
    });

    this.socket.on('connect_error', (err) => {
      console.error('[ws]: connection error:', err);
    });

    const onEvent = function (event) {
      console.log('[ws]: received:', event);

      if (event.operationId && this.operations.has(event.operationId)) {
        this.execute(event.operationId, { payload: event.payload, data: event.data });
      }

      if (event.action && this.operations.has(event.action)) {
        this.execute(event.action, { payload: event.payload });
      }
    }.bind(this);

    this.socket.on('notify', onEvent);
  }

  unsubscribe() {
    if (this.socket && this.socket.close) {
      this.socket.close();
    }
  }

  /**
   *
   * @param id
   * @param callback
   * @param timeout
   * @param once
   */
  register(id, callback, timeout = 3000, once = true) {
    console.log('[ws]: operation registered: ', id);

    this.operations.set(id, {
      id,
      callback,
      timer:
        timeout > 0
          ? setTimeout(() => {
              if (callback) {
                callback();
              }

              this.unregister(id);
            }, timeout)
          : null,
      once,
    });
  }

  execute(id, args) {
    if (!this.operations.has(id)) {
      return false;
    }

    const callback = this.operations.get(id).callback;
    if (this.operations.has(id)) {
      const operation = this.operations.get(id);

      if (operation.once) {
        this.unregister(id);
      }
    }
    let cb = callback.bind(null, { id, ...args });

    return cb();
  }

  unregister(id) {
    if (this.operations.has(id)) {
      const timer = this.operations.get(id).timer;

      if (timer) {
        clearTimeout(timer);
      }

      this.operations.delete(id);
    }
  }
}

export default new OperationManager();
