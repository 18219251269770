import React from 'react';
import { CellProps, ColumnInstance } from 'react-table';
import moment from 'moment';
import { Column, StrictTableHeaderCellProps } from 'components/share/components/table';
import { Button, Popup } from 'semantic-ui-react';
import { IModalState } from './table';
import { ThemeVars } from 'core/styles/vars';
import { OvalStatus } from 'components/share/components/status';
import { Tag } from 'components/share';
import styled from 'styled-components';
import { Location } from 'history';
import { Link } from 'react-router-dom';

export interface Building {
  id: string;
  address: string;
  created_at: string;
  is_active: boolean;
  is_test: boolean;
  billing_account: null | string;
  owner_name: string;
  owner_id: string;
}

export interface Sort {
  field?: string;
  direction?: StrictTableHeaderCellProps['sorted'];
}

// type SortFunc = Dispatch<SetStateAction<Sort>>;
type SortFunc = (column: ColumnInstance) => void;

export interface ICreateColumnsProps {
  sort: Sort;
  query?: URLSearchParams;
  onSort: SortFunc;
  modalOpen: (newState: IModalState) => void;
}

interface IopenBlockModalHandlerParams {
  id: string;
  is_active: boolean;
}

const cellHeaderProps = (
  column: ColumnInstance,
  sort: Sort,
  onSort: SortFunc,
  other: Partial<StrictTableHeaderCellProps> = {}
): StrictTableHeaderCellProps => {
  return {
    ...other,
    sorted: sort.field === column.id ? sort.direction : undefined,
    onClick: () => onSort(column),
  };
};

const Box = styled.div`
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 1rem;
  }
`;

export function createColumns({
  sort,
  query,
  onSort,
  modalOpen,
}: ICreateColumnsProps): Array<Column<Building>> {
  const openBlockModalHandler = ({ id, is_active }: IopenBlockModalHandlerParams) => () =>  {
    modalOpen({
      open: true,
      id,
      variant: is_active ? 'block' : 'unblock',
    });
  };

  const openDeleteModalHandler = (id: string) => () => {
    modalOpen({
      open: true,
      id,
      variant: 'delete',
    });
  }

  return [
    {
      id: 'address',
      Header: 'Адрес',
      accessor: 'address',
      Cell: ({
        cell: { value },
        row: {
          original: { id, address, is_active, is_test },
        },
      }: CellProps<Building>) => {
        if (!value) {
          return '';
        }

        return (
          <Box>
            <OvalStatus
              url={
                {
                  pathname: `/buildings/${id}/edit`,
                  state: {
                    tableParams: {
                      page: query?.get('page'),
                      size: query?.get('size'),
                    },
                  },
                } as unknown as Location
              }
              color={is_active ? ThemeVars.colors.normalApple : ThemeVars.colors.orangeNormal}
              text={address}
              hintText={is_active ? 'Дом активен' : 'Дом заблокирован'}
            />
            {is_test && <Tag variant="blue">Тестовый</Tag>}
          </Box>
        );
      },
      cellHeaderProps: (column) => cellHeaderProps(column, sort, onSort),
    },
    {
      id: 'owner_id',
      Header: 'Управляющая компания',
      accessor: 'owner_id',
      Cell: ({
        row: {
          original: { owner_name, owner_id },
        },
      }: CellProps<Building>) => {
        return <Link to={`/companies/${owner_id}`}>{owner_name}</Link>;
      },
    },
    {
      id: 'billing_account',
      Header: 'Лицевой счет',
      Cell: ({
        row: {
          original: { billing_account },
        },
      }: CellProps<Building>) => {
        return billing_account || '-';
      },
    },
    {
      id: 'created_at',
      Header: 'Дата создания',
      accessor: 'created_at',
      Cell: ({ cell: { value } }: CellProps<Building>) => {
        return value ? moment(value).format('DD.MM.YYYY') : '';
      },
      cellHeaderProps: (column) => cellHeaderProps(column, sort, onSort, { width: 3 }),
    },
    {
      id: 'actions',
      Header: 'Действия',
      Cell: ({
        row: {
          original: { id, is_active },
        },
      }: CellProps<Building>) => {
        return (
          <React.Fragment>
            <Popup
              content={is_active ? 'Заблокировать дом' : 'Разблокировать дом'}
              size="mini"
              position="left center"
              inverted
              trigger={
                <Button
                  icon={is_active ? 'unlock' : 'lock'}
                  onClick={openBlockModalHandler({ id, is_active })}
                />
              }
            />
            <Popup
              content="Удалить дом"
              size="mini"
              position="left center"
              inverted
              trigger={
                <Button
                  icon="trash"
                  onClick={openDeleteModalHandler(id)}
                />
              }
            />
          </React.Fragment>
        );
      },
      cellProps: () => {
        return { width: 2 };
      },
    },
  ];
}
