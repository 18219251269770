import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import App from './app';
import * as serviceWorker from './serviceWorker';
import createStore from 'core/store';
import { configure as configureModals } from 'components/share/dialogs';
import modalReducer from 'components/share/dialogs/modal-manager/redux/reducer';
// хз как по другому
import 'menu/init';

import 'semantic-ui-less/semantic.less';
import 'semantic-ui-mts-themes/styles.less';
import 'core/styles/styles.less';
import 'styles.less';

console.info(`[app]: env=${process.env.NODE_ENV}`);

const store = createStore({
  additionalReducers: {
    modals: modalReducer,
  },
});

configureModals({ dispatch: store.dispatch });

// @ts-ignore
window.VIDEOJS_NO_DYNAMIC_STYLE = true;

ReactDOM.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
