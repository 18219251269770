import React, { useEffect, FC } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { useParams, RouteComponentProps, useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { clear as clearIntercom } from 'core/store/features/intercoms';
import { clear as clearCompany } from 'core/store/features/companies';
import { clear as clearDevicesReferences } from 'core/store/features/devices-references';
import { Dispatch, bindActionCreators } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { Card, Loader, ErrorMessage } from 'components/share';
import IntercomEditForm from 'views/pages/devices/abstract/edit';
import { fetchData, SECTION_LOADING_NAME } from 'views/pages/devices/abstract/edit/fetch';
import { isSectionLoading } from 'core/store/selectors/app';
import { IntercomConfig, Intercom } from 'core/constants/contracts';
import * as RouteUrl from 'core/constants/routes';

type ParamsType = {
  id: string;
};

type IntercomEditPageProps = ConnectedProps<typeof withConnect>;

const IntercomEditPage: FC<IntercomEditPageProps> = ({
  intercom,
  companies,
  deviceReferences,
  isPending,
  error,
  fetchData,
  clearStore,
}) => {
  const { id } = useParams<ParamsType>();
  const history = useHistory();

  useEffect(() => {
    fetchData(id, 'intercom');
    return () => {
      clearStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    history.push(RouteUrl.intercomView(id));
  };

  if (isPending) {
    return <Loader active placeholder />;
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (intercom) {
    return (
      <Grid>
        <SetBreadcrumb url={RouteUrl.intercomList}>Домофоны</SetBreadcrumb>
        <SetBreadcrumb url={RouteUrl.intercomView(id)}>{intercom.phone}</SetBreadcrumb>
        <SetBreadcrumb>Редактирование домофона</SetBreadcrumb>

        <Grid.Row>
          <Grid.Column>
            <Header as="h1">Редактирование домофона</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Card>
              <IntercomEditForm
                intercom={intercom}
                companies={companies}
                deviceReferences={deviceReferences}
                handleBack={handleBack}
              />
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  return null;
};

const mapStateToProps = (state: RootState, props: RouteComponentProps<ParamsType>) => {
  return {
    intercom: {
      ...state.entities.intercoms.items[props.match.params.id],
      config: state.entities.intercoms.metadata?.config as IntercomConfig,
    } as Intercom,
    companies: state.entities.companies.items,
    deviceReferences: state.entities.devices_references.items,
    isPending: isSectionLoading(state, SECTION_LOADING_NAME, true),
    error: state.entities.intercoms.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const clearStore = () => {
    bindActionCreators(clearIntercom, dispatch)();
    bindActionCreators(clearCompany, dispatch)();
    bindActionCreators(clearDevicesReferences, dispatch)();
  };

  return {
    fetchData: bindActionCreators(fetchData, dispatch),
    clearStore,
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(IntercomEditPage);
