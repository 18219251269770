import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { Binding } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createFetchEntity,
  DEFAULT_META_MULTIPLE,
  Meta,
  SuccessActions,
  successEntitiesReducerFactory,
} from './entities';
import api from '../../constants/api';
import { success as apartmentsSuccessAction } from './apartments';
import { ApiThunk } from '..';
import { request } from '../../utils/request';
import { onFailed, onSuccess } from './entities/handlers';

const reducerSuccess = successEntitiesReducerFactory();

const slice = createSlice({
  name: 'bindings',
  initialState: createEntitiesInitialState<Binding>(),
  reducers: createDefaultEntitiesReducers<Binding>(),
  extraReducers: (builder) =>
    builder.addCase(apartmentsSuccessAction, (state, action) => {
      const meta: Nullable<Meta> = action.meta as any;
      if (false === !!meta?.ref_normalize) {
        return state;
      }

      const newAction = {
        type: action.type,
        payload: action.payload,
        meta: action.meta,
      };

      // @ts-ignore
      newAction.payload.list = action.payload.list.map((v) => v.binding);

      reducerSuccess(state, newAction as any);
    }),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchBindings = createFetchEntities<Binding>(api.bindings(), slice.actions);

export const fetchBinding = createFetchEntity<Binding>(api.bindings, slice.actions);

export const createFetchBindingWithParents =
  (actions: SuccessActions) =>
  (id: string, meta?: Meta): ApiThunk<Binding> => {
    return (dispatch: Dispatch) => {
      meta = { ...DEFAULT_META_MULTIPLE, ...meta };

      dispatch(actions.pending(meta));

      return request.get(api.bindings(id) + '/parents').then(
        (res) => onSuccess(dispatch, actions.success, res, meta),
        (err) => onFailed(dispatch, actions.failed, err, meta)
      );
    };
  };

export const fetchBindingWithParents = createFetchBindingWithParents(slice.actions);

// ---------------------------------------------------------------------------------------------------------------------

interface RangeValue {
  from: null;
  to: number;
}

type NodeValue = string | RangeValue;

interface Node {
  id: string;
  type: string;
  value: NodeValue;
  children?: Array<Node>;
}

export interface BatchProcessingData {
  building_id: string;
  data: Array<Node>;
}

export const createBatchProcessingBindings =
  (actions: SuccessActions) =>
  (data: BatchProcessingData, meta?: Meta): ApiThunk<Binding> => {
    return (dispatch: Dispatch) => {
      meta = { ...DEFAULT_META_MULTIPLE, ...meta };

      dispatch(actions.pending(meta));

      return request.post(api.bindingsBatch, data).then(
        (res) => onSuccess(dispatch, actions.success, res, meta),
        (err) => onFailed(dispatch, actions.failed, err, meta)
      );
    };
  };

export const batchProcessingBindings = createBatchProcessingBindings(slice.actions);
