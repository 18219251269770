import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { Form } from 'mts-ui';
import { Field } from 'redux-form';
import classnames from 'classnames';
import { fieldPropTypes } from 'redux-form';

import './styles.less';

export default class Range extends React.Component {
  static defaultProps = {
    flatIsRequire: true,
    floorIsRequire: true,
    readOnly: false,
  };

  render() {
    const {
      input,
      meta,

      required,
      inline,
      label,

      componentFrom,
      componentTo,
      placeholderFrom,
      placeholderTo,
      labelContentFrom,
      labelContentTo,
      helpFrom,
      helpTo,
      onChangeFrom,
      onChangeTo,
      validateFrom,
      validateTo,
      ...rest
    } = this.props;

    const { touched = false, error = '' } = meta;
    const { name } = input;

    const labelFrom = labelContentFrom ? { basic: true, content: labelContentFrom } : void 0;
    const labelTo = labelContentTo ? { basic: true, content: labelContentTo } : void 0;
    const hasError = !!(touched && error);

    return (
      <Form.FieldAdvanced
        required={required}
        className={classnames({ range: true })}
        error={hasError}
        label={label}
        inline={inline}
      >
        <Field
          {...rest}
          name={`${name}.from`}
          component={componentFrom}
          label={labelFrom}
          labelPosition="left"
          placeholder={placeholderFrom}
          className={'range-from'}
          onChange={onChangeFrom}
          validate={validateFrom}
          help={helpFrom}
        />
        <Field
          {...rest}
          name={`${name}.to`}
          component={componentTo}
          label={labelTo}
          labelPosition="left"
          placeholder={placeholderTo}
          className={'range-to'}
          onChange={onChangeTo}
          validate={validateTo}
          help={helpTo}
        />
      </Form.FieldAdvanced>
    );
  }
}

Range.defaultProps = {
  inline: true,
};

Range.propTypes = {
  ...fieldPropTypes,
  componentFrom: PropTypes.func,
  componentTo: PropTypes.func,
  labelContentFrom: PropTypes.string,
  labelContentTo: PropTypes.string,
  placeholderFrom: PropTypes.string,
  placeholderTo: PropTypes.string,
  helpFrom: PropTypes.string,
  helpTo: PropTypes.string,
  onChangeFrom: PropTypes.func,
  onChangeTo: PropTypes.func,
  validateFrom: PropTypes.func,
  validateTo: PropTypes.func,

  readonly: PropTypes.bool,
  required: PropTypes.bool,
  inline: PropTypes.bool,
};

Range.defaultProps = {
  componentFrom: Input,
  componentTo: Input,
  placeholderFrom: 'От...',
  placeholderTo: 'До...',
};
