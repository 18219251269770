import { MODAL_CLOSE, MODAL_OPEN } from './constants';

export const modalOpen = (name, payload = {}) => {
  return {
    type: MODAL_OPEN,
    name,
    payload,
  };
};

export const modalClose = (payload) => {
  return {
    type: MODAL_CLOSE,
    payload,
  };
};
