import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { ColorsEnum, ThemeVars } from 'core/styles/vars';

export const GridRowStyled = styled(Grid.Row)`
  &&&& {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const GridRowBottomStyled = styled(Grid.Row)`
  &&&& {
    padding-bottom: 0;
  }
`;

export const GridColumnButton = styled(Grid.Column)`
  &&&& {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

type IDotStyleProps = {
  color: ColorsEnum;
};

export const Dot = styled.div<IDotStyleProps>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 5px;
  background-color: ${({ color }) => ThemeVars.colors[color]};
`;
