import React, { FC } from 'react';
import { Modal, Button } from 'semantic-ui-react';

export type IModalVariant = 'block' | 'unblock' | 'delete';

interface IManageBuildingModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  variant: IModalVariant;
}

const content = {
  block: {
    header: 'Заблокировать дом',
    text: 'Вы уверены, что хотите заблокировать дом? После блокировки дому не будет доступна услуга домофон.',
    submitBtnText: 'Заблокировать',
  },
  unblock: {
    header: 'Разблокировать дом',
    text: 'Вы уверены, что хотите разблокировать дом? После разблокировки дому будет доступна услуга домофон.',
    submitBtnText: 'Разблокировать',
  },
  delete: {
    header: 'Удалить дом',
    text: 'Вы уверены, что хотите удалить дом?',
    submitBtnText: 'Удалить',
  }
};

export const ManageBuildingModal: FC<IManageBuildingModalProps> = ({
  open,
  onClose,
  onSubmit,
  variant,
}) => {
  const submitHandler = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal open={open} closeIcon onClose={onClose} size={'small'}>
      <Modal.Header content={content[variant].header} />
      <Modal.Content>{content[variant].text}</Modal.Content>
      <Modal.Actions>
        <Button content="Отменить" onClick={onClose} secondary />
        <Button content={content[variant].submitBtnText} onClick={submitHandler} />
      </Modal.Actions>
    </Modal>
  );
};
