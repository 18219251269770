import React, { PropsWithChildren, Ref, useCallback } from 'react';
import styled from 'styled-components';
import {
  DragScrollProvider,
  DragScrollProviderProps,
  DragScrollProviderRenderProps,
} from './provider';

interface ContainerProps {
  containerHeight?: string;
  containerWidth?: string;
  vertically?: boolean;
  horizontally?: boolean;
}

const Container = styled.div<ContainerProps>`
  overflow-x: ${(props) => (props.horizontally === true ? 'scroll' : 'auto')};
  overflow-y: ${(props) => (props.vertically === true ? 'scroll' : 'auto')};
  height: ${(props) => (props.containerHeight !== undefined ? props.containerHeight : 'auto')};
  width: ${(props) => (props.containerWidth !== undefined ? props.containerWidth : 'auto')};

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #bbc1c7;
    border-radius: 4px;
  }
`;

interface ScrollContainerProps extends ContainerProps {
  smooth?: boolean; // TODO
  className?: string;
  ref?: Ref<HTMLDivElement>;
}

function ScrollContainer(props: PropsWithChildren<ScrollContainerProps>) {
  return <Container {...props} />;
}

interface DraggableScrollContainerProps
  extends ScrollContainerProps,
    Omit<DragScrollProviderProps, 'children'> {}

function DraggableScrollContainer(props: PropsWithChildren<DraggableScrollContainerProps>) {
  const { vertically, horizontally } = props;

  const render = useCallback(
    (dragProps: DragScrollProviderRenderProps) => {
      return <Container {...props} {...dragProps} />;
    },
    [props]
  );

  return (
    <DragScrollProvider vertically={vertically} horizontally={horizontally}>
      {render}
    </DragScrollProvider>
  );
}

export { ScrollContainer, DraggableScrollContainer };
