import { Action, AnyAction, CaseReducer, Draft, PayloadAction } from '@reduxjs/toolkit';
import { WebAPIResponse } from '../../../utils/webapi.contracts';
import { EntitiesState, Entity, Meta } from './contracts';
import {
  actionEntityReducer,
  clearActionEntityReducer,
  clearEntitiesReducer,
  deleteEntityReducer,
  EntityActionPayload,
  EntityClearActionPayload,
  failedEntitiesReducer,
  ReducerOptions,
  startEntitiesPendingReducer,
  stopEntitiesPendingReducer,
  successEntitiesReducerFactory,
} from './reducers';

interface MetaAction extends Action {
  meta?: any;
}

export function createEntitiesInitialState<T extends Entity>(): Readonly<EntitiesState<T>> {
  return {
    items: {},
    ids: [],
    pending: false,
    error: undefined,
    actions: {},
    metadata: undefined,
  };
}

/***********************************************************
 *      Reducers prepare
 **********************************************************/

export function prepareMeta(meta?: Meta): any {
  return { meta };
}

export function prepareWithMeta(payload: any, meta?: Meta): any {
  return { payload, meta };
}

export function startPendingEntitiesReducerCreator() {
  return {
    reducer: startEntitiesPendingReducer,
    prepare: prepareMeta,
  };
}

/***********************************************************
 *      Reducers creators
 *
 * функции нужны для генериков, автотвывода типов
 **********************************************************/

export function stopPendingEntitiesReducerCreator<T extends Entity>(): CaseReducer<
  EntitiesState<T>
> {
  return stopEntitiesPendingReducer;
}

export function successEntitiesReducerCreator<T extends Entity, Raw>(options?: Options<T, Raw>) {
  return {
    reducer: successEntitiesReducerFactory<T, Raw>(options),
    prepare: prepareWithMeta,
  };
}

export function deleteEntityReducerCreator() {
  return {
    reducer: deleteEntityReducer,
    prepare: prepareWithMeta,
  };
}

export function failedEntitiesReducerCreator() {
  return {
    reducer: failedEntitiesReducer,
    prepare: prepareWithMeta,
  };
}

export function clearEntitiesReducerCreator() {
  return {
    reducer: clearEntitiesReducer,
    prepare: prepareMeta,
  };
}

export function actionEntitiesReducerCreator() {
  return actionEntityReducer;
}

export function clearActionEntitiesReducerCreator() {
  return clearActionEntityReducer;
}

/***********************************************************
 *            Filterable reducers
 **********************************************************/

function createNamedWrapperReducer<A extends Action = AnyAction>(
  reducer: CaseReducer<any, A>,
  reducerName?: string
) {
  return (state: Draft<EntitiesState>, action: A) => {
    const isInitializationCall = state === undefined;
    if (isInitializationCall) {
      return reducer(state, action);
    }

    const currentReducerName = (action as AnyAction)?.meta?.name;
    if (currentReducerName === reducerName) {
      return reducer(state, action);
    }

    return state;
  };
}

/***********************************************************
 *            Default reducers collection
 **********************************************************/

export interface Options<T extends Entity, Raw = any> extends ReducerOptions<T, Raw> {
  reducerName?: string;
}

export function createDefaultEntitiesReducers<T extends Entity, Raw = T>(
  options?: Options<T, Raw>
) {
  const pending = startPendingEntitiesReducerCreator();
  const success = successEntitiesReducerCreator<T, Raw>(options);
  const failed = failedEntitiesReducerCreator();
  const clear = clearEntitiesReducerCreator();
  const action = actionEntitiesReducerCreator();
  const clearAction = clearActionEntitiesReducerCreator();
  const deleted = deleteEntityReducerCreator();

  return {
    pending: {
      reducer: createNamedWrapperReducer<MetaAction>(pending.reducer, options?.reducerName),
      prepare: pending.prepare,
    },
    success: {
      reducer: createNamedWrapperReducer<PayloadAction<WebAPIResponse<T & Raw>, string, Meta>>(
        success.reducer,
        options?.reducerName
      ),
      prepare: success.prepare,
    },
    failed: {
      reducer: createNamedWrapperReducer<PayloadAction<any, string, Meta>>(
        failed.reducer,
        options?.reducerName
      ),
      prepare: failed.prepare,
    },
    clear: {
      reducer: createNamedWrapperReducer<PayloadAction<WebAPIResponse<T & Raw>, string, Meta>>(
        clear.reducer,
        options?.reducerName
      ),
      prepare: clear.prepare,
    },
    action: createNamedWrapperReducer<PayloadAction<EntityActionPayload>>(
      action,
      options?.reducerName
    ),
    clearAction: createNamedWrapperReducer<PayloadAction<EntityClearActionPayload>>(
      clearAction,
      options?.reducerName
    ),
    deleted: {
      reducer: createNamedWrapperReducer<PayloadAction<string, string, Meta>>(
        deleted.reducer,
        options?.reducerName
      ),
      prepare: deleted.prepare,
    },
  };
}
