import { MenuType } from '../../core/menu/contracts';
import { __ } from '../utils';
import { CurrentUser, CurrentCompany } from 'core/constants/contracts';

export default function (
  currentUser: CurrentUser,
  currentCompany: CurrentCompany
): Array<MenuType> {
  return [
    {
      title: __('Владельцы'),
      name: 'owners',
      icon: 'mts-icon-users',
      items: [
        {
          title: __('Компании'),
          name: 'companies',
          url: '/companies',
        },
        {
          title: __('Клиенты'),
          name: 'clients',
          url: '/clients',
        },
      ],
    },
    {
      title: __('Оборудование'),
      name: 'devices',
      icon: 'mts-icon-lock',
      items: [
        {
          title: __('Домофоны'),
          name: 'intercoms',
          url: '/intercoms',
        },
        {
          title: __('Шлагбаумы'),
          name: 'barriers',
          url: '/barriers',
        },
        {
          title: __('Камеры'),
          name: 'cameras',
          url: '/cameras',
        },
        {
          title: __('Видеорегистраторы'),
          name: 'video_recorders',
          url: '/video-recorders',
        },
        {
          title: __('Облачный архив'),
          name: 'cloud-archive',
          url: '/cloud-archive',
        },
      ],
    },
    {
      title: __('Подключение дома'),
      name: 'buildings',
      icon: 'building',
      items: [
        {
          title: __('Дома'),
          name: 'buildings_list',
          url: '/buildings',
        },
      ],
    },
    {
      title: __('Отчеты'),
      name: 'reports',
      icon: 'mts-icon-file',
      items: [
        {
          title: __('БП Отчет'),
          name: 'apartments_usage',
          url: '/reports/apartments_usage',
        },
        {
          title: __('Статистика ДК'),
          name: 'summary',
          url: '/reports/summary',
        },
        {
          title: __('Статистика'),
          name: 'statistic',
          url: '/reports/statistic',
        },
        {
          title: __('Активное оборудование'),
          name: 'device_count',
          url: '/reports/device_count',
        },
      ],
    },
    {
      title: __('Интеграции'),
      name: 'integrations',
      icon: 'list alternate outline',
      url: '/integrations',
    },
  ];
}
