import { reducer as reduxFormReducer } from 'redux-form';
import app from './app';
import appx from './appx';
import entities from './entities';
import features from './features';

export const reducers = {
  app,
  appx: appx.reducer,
  entities,
  features: features.reducer,
  form: reduxFormReducer,
};
