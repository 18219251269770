import {
  APARTMENT_FAIL,
  APARTMENT_REQUEST,
  APARTMENT_SUCCESS,
  APARTMENTS_FAIL,
  APARTMENTS_REQUEST,
  APARTMENTS_SUCCESS,
  SAVE_APARTMENT_FAIL,
  SAVE_APARTMENT_REQUEST,
  SAVE_APARTMENT_SUCCESS,
  UNLINK_APARTMENT_FAIL,
  UNLINK_APARTMENT_REQUEST,
  UNLINK_APARTMENT_SUCCESS,
} from '../../constants/actions';
import {
  formatErrorMessage,
  prepareError,
  prepareResponse,
  request,
  returnValue,
  singleResult,
  sortToString,
} from '../../utils/request';
import api from '../../constants/api';
import { compose } from 'redux';

function fetchApartmentsRequest() {
  return {
    type: APARTMENTS_REQUEST,
  };
}

function fetchApartmentsSuccess(res) {
  return {
    type: APARTMENTS_SUCCESS,
    payload: {
      items: res.list,
      metadata: res.metadata,
    },
  };
}

function fetchApartmentsFail(e) {
  return {
    type: APARTMENTS_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export const fetchApartments = ({
  limit = 30,
  page = 0,
  sort = undefined,
  filter = {},
  ...rest
} = {}) => {
  return (dispatch) => {
    dispatch(fetchApartmentsRequest());

    let query = {
      limit,
      page,
      sort: sort && sortToString(sort),
      ...filter,
      ...rest,
    };

    const result = request
      .get(api.apartments(), { params: query })
      .then(prepareResponse)
      .catch(prepareError);

    result
      .then((res) => {
        dispatch(fetchApartmentsSuccess(res));
        return res;
      })
      .catch((err) => dispatch(fetchApartmentsFail(err)));

    return result;
  };
};

function fetchApartmentRequest() {
  return {
    type: APARTMENT_REQUEST,
  };
}

function fetchApartmentSuccess(res) {
  return {
    type: APARTMENT_SUCCESS,
    payload: {
      items: res.list,
      metadata: res.metadata,
    },
  };
}

function fetchApartmentFail(e) {
  return {
    type: APARTMENT_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export const fetchApartment = (id) => {
  return (dispatch) => {
    dispatch(fetchApartmentRequest());

    const result = request
      .get(api.apartments(id))
      .then(prepareResponse)
      .then(singleResult)
      .catch(prepareError);

    result
      .then((r) => compose(returnValue, dispatch, fetchApartmentSuccess)(r))
      .catch((e) => dispatch(fetchApartmentFail(id, e)));

    return result;
  };
};

function unlinkApartmentRequest() {
  return {
    type: UNLINK_APARTMENT_REQUEST,
  };
}

function unlinkApartmentSuccess(res) {
  return {
    type: UNLINK_APARTMENT_SUCCESS,
    payload: {
      item: res,
    },
  };
}

function unlinkApartmentFailed(e) {
  return {
    type: UNLINK_APARTMENT_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export const unlinkApartment = (userId, apartmentId) => {
  return (dispatch) => {
    dispatch(unlinkApartmentRequest());

    const result = request
      .post(api.apartmentsUnlink(apartmentId), { user_id: userId })
      .then(prepareResponse)
      .then(singleResult)
      .catch(prepareError);

    result
      .then((r) => compose(returnValue, dispatch, unlinkApartmentSuccess)(r))
      .catch((e) => dispatch(unlinkApartmentFailed(e)));

    return result;
  };
};

function saveApartmentRequest() {
  return {
    type: SAVE_APARTMENT_REQUEST,
  };
}

function saveApartmentSuccess(res) {
  return {
    type: SAVE_APARTMENT_SUCCESS,
    payload: {
      item: res,
    },
  };
}

function saveApartmentFailed(e) {
  return {
    type: SAVE_APARTMENT_FAIL,
    payload: {
      error: formatErrorMessage(e),
    },
  };
}

export const saveApartment = (params = {}) => {
  return (dispatch) => {
    dispatch(saveApartmentRequest());

    const { id, ...data } = params;
    const method = id ? request.put : request.post;

    const result = method(api.apartments(id), data)
      .then(prepareResponse)
      .then(singleResult)
      .catch(prepareError);

    result
      .then((r) => compose(returnValue, dispatch, saveApartmentSuccess)(r))
      .catch((e) => dispatch(saveApartmentFailed(e)));

    return result;
  };
};
