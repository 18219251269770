import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, List, Popup } from 'semantic-ui-react';

const InfoIcon = ({ header, items = [], item = '', iconName = 'info circle', color = 'black' }) => {
  let content = item;

  if (Array.isArray(item)) {
    items = item;
  }

  if (items.length > 0) {
    content = (
      <List bulleted>
        {items.map((item, i) => (
          <List.Item key={i}>{item}</List.Item>
        ))}
      </List>
    );
  }

  if (header) {
    content = (
      <Grid columns={1}>
        <Grid.Column>
          <h4>{header}</h4>
        </Grid.Column>
        <Grid.Column>{content}</Grid.Column>
      </Grid>
    );
  }

  return <Popup trigger={<Icon name={iconName} color={color} />} content={content} />;
};

InfoIcon.propTypes = {
  header: PropTypes.string,
  items: PropTypes.array,
  item: PropTypes.string,
  iconName: PropTypes.string,
  color: PropTypes.string,
};

export default InfoIcon;
