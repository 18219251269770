import { USERS_SUCCESS, USERS_FAIL, USERS_REQUEST } from '../../../constants/actions';

const defaultState = {
  items: [],
  isFetching: false,
  error: null,
  metadata: {
    page: 1,
    perPage: 20,
  },
};

export default function users(state = defaultState, action) {
  switch (action.type) {
    case USERS_REQUEST: {
      return { ...state, isFetching: true, error: null };
    }
    case USERS_SUCCESS: {
      return {
        ...state,
        metadata: action.payload.metadata,
        items: action.payload.items,
        isFetching: false,
      };
    }
    case USERS_FAIL: {
      return { ...state, error: action.payload.error, isFetching: false, items: [], metadata: {} };
    }
    default:
      break;
  }

  return state;
}
