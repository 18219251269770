import { Camera } from 'core/constants/contracts';

export type DataForCamera = Partial<Omit<Camera, 'device'>> & {
  device: {
    timezone: string | null | undefined;
  };
};

const timezoneMap = {
  '+00:00': '+00:00',
  '+01:00': '+01:00',
  '+02:00': '+02:00',
  '+03:00': '+03:00',
  '+04:00': '+04:00',
  '+05:00': '+05:00',
  '+06:00': '+06:00',
  '+07:00': '+07:00',
  '+08:00': '+08:00',
  '+09:00': '+09:00',
  '+10:00': '+10:00',
  '+11:00': '+11:00',
  '+12:00': '+12:00',
};

export const timezoneOptions = Object.keys(timezoneMap).map((item) => ({
  key: item,
  value: item,
  text: item,
}));

export const cameraProtocolOptions = [
  { key: 'rtsp', value: 'rtsp', text: 'RTSP' },
  { key: 'rtp', value: 'rtp', text: 'RTP' },
  { key: 'hls', value: 'hls', text: 'HLS' },
  { key: 'rtmp', value: 'rtmp', text: 'RTMP' },
  { key: 'http', value: 'http', text: 'HTTP' },
  { key: 'https', value: 'https', text: 'HTTPS' },
];

export enum ErrorType {
  ERROR_UNKNOWN = 'ERROR_UNKNOWN',
  ERROR_401 = 'ERROR_401',
  AVERROR_BSF_NOT_FOUND = 'AVERROR_BSF_NOT_FOUND',
  AVERROR_BUG = 'AVERROR_BUG',
  AVERROR_BUG2 = 'AVERROR_BUG2',
  AVERROR_BUFFER_TOO_SMALL = 'AVERROR_BUFFER_TOO_SMALL',
  AVERROR_DECODER_NOT_FOUND = 'AVERROR_DECODER_NOT_FOUND',
  AVERROR_DEMUXER_NOT_FOUND = 'AVERROR_DEMUXER_NOT_FOUND',
  AVERROR_ENCODER_NOT_FOUND = 'AVERROR_ENCODER_NOT_FOUND',
  AVERROR_EOF = 'AVERROR_EOF',
  AVERROR_EXIT = 'AVERROR_EXIT',
  AVERROR_EXTERNAL = 'AVERROR_EXTERNAL',
  AVERROR_FILTER_NOT_FOUND = 'AVERROR_FILTER_NOT_FOUND',
  AVERROR_INVALIDDATA = 'AVERROR_INVALIDDATA',
  AVERROR_MUXER_NOT_FOUND = 'AVERROR_MUXER_NOT_FOUND',
  AVERROR_OPTION_NOT_FOUND = 'AVERROR_OPTION_NOT_FOUND',
  AVERROR_PATCHWELCOME = 'AVERROR_PATCHWELCOME',
  AVERROR_PROTOCOL_NOT_FOUND = 'AVERROR_PROTOCOL_NOT_FOUND',
  AVERROR_STREAM_NOT_FOUND = 'AVERROR_STREAM_NOT_FOUND',
  AVERROR_UNKNOWN = 'AVERROR_UNKNOWN',
  AVERROR_EXPERIMENTAL = 'AVERROR_EXPERIMENTAL',
  AVERROR_INPUT_CHANGED = 'AVERROR_INPUT_CHANGED',
  AVERROR_OUTPUT_CHANGED = 'AVERROR_OUTPUT_CHANGED',
  AVERROR_HTTP_BAD_REQUEST = 'AVERROR_HTTP_BAD_REQUEST',
  AVERROR_HTTP_UNAUTHORIZED = 'AVERROR_HTTP_UNAUTHORIZED',
  AVERROR_HTTP_FORBIDDEN = 'AVERROR_HTTP_FORBIDDEN',
  AVERROR_HTTP_NOT_FOUND = 'AVERROR_HTTP_NOT_FOUND',
  AVERROR_HTTP_OTHER_4XX = 'AVERROR_HTTP_OTHER_4XX',
  AVERROR_HTTP_SERVER_ERROR = 'AVERROR_HTTP_SERVER_ERROR',
  AV_ERROR_MAX_STRING_SIZE = 'AV_ERROR_MAX_STRING_SIZE',
}

export const errorsDictionary = {
  [ErrorType.ERROR_UNKNOWN]:
    'Превышен лимит обращений к устройству. Попробуйте повторить попытку через несколько секунд',
  [ErrorType.ERROR_401]: 'Сетевая ошибка авторизации. Пройдите авторизацию повторно',
  [ErrorType.AVERROR_BSF_NOT_FOUND]: 'Фильтр битового потока не найден',
  [ErrorType.AVERROR_BUG]: 'Внутренняя ошибка мультимедийного устройства',
  [ErrorType.AVERROR_BUG2]: 'Внутренняя ошибка мультимедийного устройства',
  [ErrorType.AVERROR_BUFFER_TOO_SMALL]: 'Слишком маленький буфер мультимедийного потока',
  [ErrorType.AVERROR_DECODER_NOT_FOUND]: 'Декодер мультимедийного устройства не найден',
  [ErrorType.AVERROR_DEMUXER_NOT_FOUND]: 'Демуксер мультимедийного устройства не найден',
  [ErrorType.AVERROR_ENCODER_NOT_FOUND]: 'Энкодер мультимедийного устройства не найден',
  [ErrorType.AVERROR_EOF]: 'Произошла ошибка, проверьте правильность настройки оборудования',
  [ErrorType.AVERROR_EXIT]:
    'Внутренняя логическая ошибка. Был запрошен немедленный выход. Вызываемая функция не должна быть перезапущена',
  [ErrorType.AVERROR_EXTERNAL]: 'Общая ошибка во внешней библиотеке',
  [ErrorType.AVERROR_FILTER_NOT_FOUND]: 'Фильтр мультимедийного устройства не найден',
  [ErrorType.AVERROR_INVALIDDATA]: 'Неверные данные, обнаруженные при обработке входных данных',
  [ErrorType.AVERROR_MUXER_NOT_FOUND]: 'Мультиплексор мультимедийного устройства не найден',
  [ErrorType.AVERROR_OPTION_NOT_FOUND]: 'Запрос поддерживаемых методов не найден',
  [ErrorType.AVERROR_PATCHWELCOME]: 'Формат устройства еще не реализован в FFmpeg',
  [ErrorType.AVERROR_PROTOCOL_NOT_FOUND]: 'Протокол мультимедийного устройства не найден',
  [ErrorType.AVERROR_STREAM_NOT_FOUND]: 'Поток мультимедийного устройства не найден',
  [ErrorType.AVERROR_UNKNOWN]: 'Неизвестная ошибка, как правило, из внешней библиотеки',
  [ErrorType.AVERROR_EXPERIMENTAL]: 'Запрошенная функция помечена как экспериментальная',
  [ErrorType.AVERROR_INPUT_CHANGED]: 'Ввод данных менялся между вызовами. Требуется перенастройка',
  [ErrorType.AVERROR_OUTPUT_CHANGED]:
    'Вывод данных менялся между вызовами. Требуется перенастройка',
  [ErrorType.AVERROR_HTTP_BAD_REQUEST]: 'Сетевая ошибка. Мультимедийное устройство не отвечает',
  [ErrorType.AVERROR_HTTP_UNAUTHORIZED]:
    'Ошибка авторизации. Проверьте правильность введенных логина и пароля от мультимедийного устройства',
  [ErrorType.AVERROR_HTTP_FORBIDDEN]:
    'Сетевая ошибка. Доступ к мультимедийного устройства запрещен',
  [ErrorType.AVERROR_HTTP_NOT_FOUND]: 'Сетевая ошибка. Мультимедийное устройство не найдено',
  [ErrorType.AVERROR_HTTP_OTHER_4XX]: 'Сетевая ошибка. Ошибка клиента мультимедийного устройства',
  [ErrorType.AVERROR_HTTP_SERVER_ERROR]:
    'Серверная ошибка. Ошибка сервера мультимедийного устройства',
  [ErrorType.AV_ERROR_MAX_STRING_SIZE]:
    'Неизвестная ошибка. Проверьте настройки и повторите попытку позднее',
};
