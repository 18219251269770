import * as yup from 'yup';
import bindingsSchema from './bindings';
import integerSchema from './integer';
import { minYear, maxYear, minFloor } from './config';

const schema = () => {
  return yup.object().shape({
    isAddressValidate: yup.bool(),
    address: yup.mixed().when('isAddressValidate', {
      is: true,
      then: yup
        .mixed()
        .test('address', 'Укажите адрес', function (value) {
          return yup
            .object()
            .shape({
              value: yup.string().required(),
              title: yup.string().required(),
            })
            .isValid(value);
        })
        .test('address_is_home', 'Указанный адрес не является домом', function (value) {
          return yup
            .object()
            .shape({
              house: yup.string().required(),
            })
            .isValid(value);
        }),
    }),
    owner: yup.string().required('Укажите владельца дома'),
    type: yup.string().default('residential').oneOf(['residential']),
    year: integerSchema
      .min(minYear, `Год не должен быть меньше ${minYear}`)
      .max(maxYear, `Год не должен быть больше ${maxYear}`),
    floors: integerSchema.min(minFloor, `Этажность не должна быть меньше ${minFloor}`),
    bindings: yup
      .array()
      .of(bindingsSchema.uniqueProperty('entrance', 'Подъезд должен быть уникальным')),
  });
};

export default schema;
