import { Icon, IconButton } from 'components/share';
import { InfoFilled, RecycleBin } from 'components/share/assets';
import { confirm } from 'components/share/dialogs';
import { intercomList } from 'core/constants/routes';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { ParagraphContainer, Paragraph, ParagraphBox, ParagraphBold } from './styles';

export interface IDeleteIntercomProps {
  hasExternalCameras: boolean;
  isLoadingDelete: boolean;
  handleDeleteIntercom: () => Promise<boolean>;
  handleDeleteCamera: () => Promise<void>;
  handleUnlinkCamera: () => Promise<void>;
  handleIsLoadingDelete: (value: boolean) => void;
}

const WarningMessageIntercom = (
  <ParagraphContainer>
    <Paragraph>
      Панель будет очищена от информации о жильцах и квартирах. Встроенная камера будет удалена
      автоматически.
    </Paragraph>
    <ParagraphBox>
      <Icon icon={<InfoFilled />} size="24px" />
      <Paragraph>
        <ParagraphBold>Обратите внимание</ParagraphBold>, что после удаления панели, тарификация
        услуг не будет остановлена автоматически.
      </Paragraph>
    </ParagraphBox>
  </ParagraphContainer>
);

const WarningMessageCamera = (
  <ParagraphContainer>
    <Paragraph>
      С домофоном связаны внешние камеры <ParagraphBold>Хотите удалить?</ParagraphBold>.
    </Paragraph>
    <Paragraph>
      Камеры можно будет удалить позже из меню <ParagraphBold>Камеры</ParagraphBold>.
    </Paragraph>
  </ParagraphContainer>
);

export const DeleteIntercomBtn: FC<IDeleteIntercomProps> = ({
  hasExternalCameras,
  isLoadingDelete,
  handleDeleteIntercom,
  handleDeleteCamera,
  handleUnlinkCamera,
  handleIsLoadingDelete,
}) => {
  const history = useHistory();

  const onDeleteIntercom = async () => {
    handleIsLoadingDelete(true);
    await handleUnlinkCamera();
    const success = await handleDeleteIntercom();
    handleIsLoadingDelete(false);

    if (success) {
      history.push(intercomList);
    }
  };

  const onDeleteBoth = async () => {
    handleIsLoadingDelete(true);
    const success = await handleDeleteIntercom();

    if (success) {
      await handleDeleteCamera();
    }

    handleIsLoadingDelete(false);
    history.push(intercomList);
  };

  // First screen
  const toggleModalIntercom = () => {
    confirm({
      header: 'Подтвердите удаление домофонной панели',
      content: WarningMessageIntercom,
      onConfirm: hasExternalCameras ? onConfirmWithCamera : onDeleteIntercom,
    });
  };

  // Second screen
  const toggleModalCamera = () => {
    confirm({
      header: 'Подтвердите удаление связанной камеры',
      content: WarningMessageCamera,
      onConfirm: onDeleteBoth,
      onClose: onDeleteIntercom,
    });
  };

  const onConfirmWithCamera = () => {
    toggleModalIntercom();
    setTimeout(toggleModalCamera, 500);
  };

  return (
    <IconButton
      icon={<RecycleBin />}
      secondary
      onClick={toggleModalIntercom}
      loading={isLoadingDelete}
      disabled={isLoadingDelete}
    >
      Удалить
    </IconButton>
  );
};
