import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Grid, Header, Message } from 'semantic-ui-react';
import classnames from 'classnames';
import { get } from 'lodash';
import Steps from './steps';
import './index.less';

class Wizard extends Component {
  state = {
    step: this.props.defaultStep,
    visited: [],
    prevProps: null,
  };

  UNSAFE_componentWillMount() {
    const { step } = this.props.match.params;
    if (step) {
      this.setState({ step });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    let currentStep = get(this.props, 'match.params.step', this.props.defaultStep),
      nextStep = get(nextProps, 'match.params.step', this.props.defaultStep);

    if (nextStep !== currentStep) {
      this.setState({ step: nextProps.match.params.step });
    }
  }

  _createHref = (step) => {
    let { href } = this.props;
    if (!href) {
      return;
    }
    let replacePairs = {},
      { match } = this.props;

    for (let key in match.params) {
      replacePairs[`:${key}`] = match.params[key];
    }
    replacePairs[':step'] = step;

    for (let placeholder in replacePairs) {
      let replaceTo = replacePairs[placeholder];
      href = href.replace(new RegExp(placeholder, 'ig'), replaceTo);
    }

    return href;
  };

  _navigate = (step) => {
    this.props.history.push(this._createHref(step));
  };

  next = (prevFormProps) => {
    const { transitions } = this.props;
    const { step } = this.state;
    for (let i in transitions) {
      let transition = transitions[i];
      if (transition.from === step) {
        this.setState((state) => ({
          step: transition.to,
          visited: [...state.visited, transition.from],
          prevProps: prevFormProps,
        }));
        this._navigate(transition.to);
        return;
      }
    }
  };

  prev = (nextFormProps) => {
    const { transitions } = this.props;
    const { step } = this.state;
    for (let i in transitions) {
      let transition = transitions[i];
      if (transition.to === step) {
        this.setState((state) => ({
          step: transition.from,
          visited: state.visited.slice(0, -1),
          prevProps: nextFormProps,
        }));
        this._navigate(transition.from);
        return;
      }
    }
  };

  isValidStep = () => {
    const { step, visited } = this.state;
    const { transitions } = this.props;

    const from = visited.slice(-1)[0];
    // на 1ом шаге
    if (from === void 0) {
      if (step !== transitions[0].from) {
        return false;
      }
    } else {
      // на всех остальных
      const transition = transitions.find((item) => {
        return item.from === from && item.to === step;
      });
      if (transition === void 0) {
        return false;
      }
    }

    return true;
  };

  renderContent = (content) => {
    const { step } = this.state;
    const { steps, header } = this.props;

    return (
      <Grid className={'wizard'}>
        <Grid.Row>
          <Grid.Column textAlign={'left'}>
            <Header as={'h2'}>{header}</Header>
            <Steps items={steps} size={'mini'} active={step} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{content}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  render() {
    const { step, visited } = this.state;
    const { steps, components, formProps = {}, stateful } = this.props;

    let content;

    if (false === this.isValidStep()) {
      const firstStep = steps.slice().shift();
      content = (
        <Message>
          <Message.Header>Внимание!</Message.Header>
          <p>Возможно вы начали с неверного шага помощника.</p>
          {firstStep && (
            <p>
              Попробуйте с шага <a href={this._createHref(firstStep.key)}>{firstStep.title}</a>.
            </p>
          )}
        </Message>
      );

      return this.renderContent(content);
    }

    let Element = components[step];

    if (stateful) {
      content = Object.entries(components).map((item) => {
        const key = item[0];
        if (step !== key && !visited.includes(key)) {
          return null;
        }

        const className = classnames({
          hidden: step !== key,
          transition: true,
        });

        const Element = item[1];
        return (
          <div key={key} className={className}>
            <Element
              next={this.next}
              prev={this.prev}
              search={this.props.history.location.search}
              wizardPrevFormProps={this.state.prevProps}
              {...formProps}
            />
          </div>
        );
      });
    } else {
      content = (
        <Element
          next={this.next}
          prev={this.prev}
          search={this.props.history.location.search}
          wizardPrevFormProps={this.state.prevProps}
          {...formProps}
        />
      );
    }

    return this.renderContent(content);
  }
}

Wizard.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  defaultStep: PropTypes.string,
  transitions: PropTypes.array,
  components: PropTypes.object,
  steps: PropTypes.array,
  header: PropTypes.string,
  formProps: PropTypes.object,
  href: PropTypes.string,
  stateful: PropTypes.bool,
};

Wizard.defaultProps = {
  stateful: false,
};

export default withRouter(Wizard);
