import React, { ReactNode } from 'react';
import { Table } from 'mts-ui';
import { Column } from '../contracts';
import { sortBy } from 'lodash';
import { StrictTableHeaderCellProps } from 'semantic-ui-react';

interface Props {
  columns: Array<Column<any>>;
  data: Array<object>;
  sortable?: boolean;
}

interface State {
  columns: Array<Column<any>>;
  data: Array<object>;
  column?: number;
  direction?: StrictTableHeaderCellProps['sorted'];
}

export class TableSimple extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      data: props.data,
      columns: props.columns,
    };
  }

  componentDidMount(): void {
    if (this.props.sortable) {
      this.setState({ columns: this.prepareColumnsAsSortable(this.props.columns) });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.data !== this.state.data) {
      const { column, direction } = this.state;

      let data = this.props.data;
      if (column) {
        data = sortBy(this.props.data, [this.state.columns[column].accessor]);
        if (direction === 'descending') {
          data = data.reverse();
        }
      }

      this.setState({ data });
    }

    if (this.props.sortable !== prevProps.sortable) {
      const columns = this.props.sortable
        ? this.prepareColumnsAsSortable(this.props.columns)
        : this.props.columns;
      this.setState({ columns });
    }
  }

  handleClickHeader = (clickedColumn: number) => () => {
    const { data, column, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState(
        {
          column: clickedColumn,
          data: sortBy(data, [this.state.columns[clickedColumn].accessor]),
          direction: 'ascending',
        },
        () => {
          this.setState({ columns: this.prepareColumnsAsSortable(this.props.columns) });
        }
      );

      return;
    }

    this.setState(
      {
        data: [...data.reverse()],
        direction: direction === 'ascending' ? 'descending' : 'ascending',
      },
      () => {
        this.setState({ columns: this.prepareColumnsAsSortable(this.props.columns) });
      }
    );
  };

  prepareColumnsAsSortable = (columns: Array<Column<any>>) => {
    const { column: selectedColumn, direction } = this.state;

    for (let ii = 0; ii < columns.length; ii++) {
      const column = columns[ii];

      column['cellHeaderProps'] = () => {
        return {
          sorted: selectedColumn === ii ? direction : undefined,
          onClick: this.handleClickHeader(ii),
        };
      };
    }

    return [...columns];
  };

  render(): ReactNode {
    const { sortable } = this.props;
    const { columns, data } = this.state;

    return (
      <Table.Simple
        columns={columns}
        data={data}
        tableProps={{
          sortable: sortable,
        }}
      />
    );
  }
}
