import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanies } from 'core/store/features/companies';
import { RootState } from 'core/store';
import { CloudArchivePage } from './page';
import { fetchServices } from 'core/store/features/services';
import { Service, ServiceAlias } from 'core/constants/contracts';
import { createSelectMapToArray } from 'core/store/selectors';
import { getServicesItems } from 'core/store/selectors/services';

interface IStoreProps {
  pending: boolean;
  cloudArchiveService: Service;
}

const CloudArchiveContainer = () => {
  const dispatch = useDispatch();

  const { pending, cloudArchiveService } = useSelector<RootState, IStoreProps>((state) => {
    const selector = createSelectMapToArray<Service>();
    const [service] = selector(getServicesItems(state)).filter(
      (s) => s.alias === ServiceAlias['company-archive-channel']
    );
    return {
      pending: state.entities.companies.pending,
      cloudArchiveService: service,
    };
  });

  useEffect(() => {
    dispatch(fetchCompanies({ limit: 3000 }));
    dispatch(fetchServices({ provider_type: 'admin' }));
  }, [dispatch]);

  return <CloudArchivePage pending={pending} cloudArchiveService={cloudArchiveService} />;
};

export default CloudArchiveContainer;
