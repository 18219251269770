import React, { FC } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import notification from 'core/utils/notification';
import { Camera } from 'core/constants/contracts';
import { request } from 'core/utils/request';
import api from 'core/constants/api';
import { useDispatch } from 'react-redux';
import { confirm } from 'components/share/dialogs';
import { CameraAgg } from 'core/store/selectors/cameras';
import { fetchData } from 'components/share/containers/camera';
import styled from 'styled-components';

type CameraDeleteProps = {
  camera: CameraAgg;
};

const BoldText = styled.span`
  font-weight: bold;
`;

export const CameraDelete: FC<CameraDeleteProps> = ({ camera: { id, name } }) => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    request
      .delete<Camera>(api.cameras(id))
      .then(() => {
        notification.success();
        // ToDo: Костыль для обновления таблицы
        dispatch(fetchData({ limit: 30, page: 1, sort: '_id' }));
      })
      .catch((err) => notification.error(err.message));
  };

  const toggleModal = () => {
    confirm({
      header: 'Удаление камеры',
      content: (
        <span>Вы уверены, что хотите удалить камеру {<BoldText>{name || id}</BoldText>}?</span>
      ),
      onConfirm: handleSubmit,
    });
  };

  const trigger = (
    <Button secondary icon onClick={toggleModal}>
      <Icon name="trash" />
    </Button>
  );

  return <Popup position="top right" content="Удалить камеру" trigger={trigger} inverted />;
};
