import { createSlice } from '@reduxjs/toolkit';
import { VideoRecorder } from '../../constants/contracts';
import api from '../../constants/api';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
  createSaveEntity,
  createUpdateEntity,
  createFetchEntityById,
} from './entities';

const slice = createSlice({
  name: 'video-recorders',
  initialState: createEntitiesInitialState<VideoRecorder>(),
  reducers: createDefaultEntitiesReducers<VideoRecorder>(),
});

export default slice;

export const { pending, success, failed, clear } = slice.actions;

export const fetchVideoRecorder = createFetchEntityById<VideoRecorder>(api.videoRecorders, slice.actions);
export const fetchVideoRecorders = createFetchEntities<VideoRecorder>(api.videoRecorders(), slice.actions);
export const saveVideoRecorder = createSaveEntity(api.videoRecorders, slice.actions);
export const updateVideoRecorder = createUpdateEntity(api.videoRecorders, slice.actions);
