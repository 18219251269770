import React, { FC, useCallback, useMemo } from 'react';
import { TableRedux, TableReduxFetchOptions } from 'components/share/components/table';
import { wrapEntitiesTableSelectorFactory } from 'core/store/selectors';
import { fetchCompanies } from 'core/store/features/companies';
import { getCompanies } from 'core/store/selectors/companies';
import { createColumns } from './columns';
import { useLocation } from 'react-router-dom';
import { CompanyFilterForm } from './components/filter';

interface ICompanyTableProps {
  filters: Partial<CompanyFilterForm>;
}

export const Table: FC<ICompanyTableProps> = ({ filters }) => {
  const query = new URLSearchParams(useLocation().search);
  const columns = useMemo(() => createColumns(query), [query]);
  const selector = useCallback(wrapEntitiesTableSelectorFactory(getCompanies), []);
  const fetcher = useCallback(({ pageIndex, pageSize }: TableReduxFetchOptions) => {
    return fetchCompanies(
      {
        page: pageIndex + 1,
        limit: pageSize,
        filter: filters,
      },
      {
        renew: true,
      }
    );
  }, [filters]);

  return (
    <TableRedux
      columns={columns}
      fetcher={fetcher}
      selector={selector}
      refreshAfterResize={5}
      showUpperPagination
    />
  );
};
