import * as yup from 'yup';

export const schemaUserFactory = () => {
  return yup.object().shape({
    name: yup.string().required('Укажите ФИО'),
    email: yup.string().email('Укажите валидный e-mail').required('Укажите E-mail'),
    phone: yup
      .string()
      .transform(function (value) {
        return this.isType(value) && value !== null ? value.replace(/([^0-9^\\+]*)/g, '') : value;
      })
      .required('Необходимо заполнить номер телефона')
      .matches(/^((\+7|7|8)+([0-9]){10})$/gm, {
        message: 'Укажите валидный номер телефона',
        excludeEmptyString: true,
      }),
  });
};

// При создании и контрагента необходимо выбрать его тип: Юр. лицо (это будет УК, ТСЖ и т.д.) или Физ.лицо (оно же ИП). От типа будет зависеть набор полей
// Набор полей для типа "Юр. лицо": Наименование*, Юр. адрес*, Факт. адрес, телефон*, сайт, ИНН*, ОГРН, ЛС (для будущей интеграции с форис), Статус* (справочник: Активен, Заблокирован, Архивный)
// Набор полей для типа "Физ. лицо/ИП": Фамилия*, Имя*, Отчество, Адрес*, ИНН, ЛС, Статус * (аналогично Юр.лицу)

// При создании контактного лица должны быть поля:
//     ФИО*
//     должность
// телефон (формат +7 (_ _ _)_ _ _- _ _ - _ _, только цифры)
// e-mail* (должна быть проверка правильности ввода).
// флаг "пользователь системы УК"
// роль - поле обязательное, если флаг проставлен, иначе - недоступно для редактирования. Справочник. На MVP одно значение - Администратор
// статус* - Справочник. Набор значений: Актуальный, Архивный

// При сохранении контактного лица с флагом "пользователь системы УК" генерируется ссылка для задания пароля и происходит отправка ссылки на e-mail контактного лица.
