import {
  REPORT_REQUEST,
  REPORT_SUCCESS,
  REPORT_FAIL,
  CREATE_REPORT_REQUEST,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_FAIL,
  REPORT_COMPLETED,
} from '../../../constants/actions';

const defaultState = {
  error: null,
  isFetching: false,
  report: {},
};

export default function reports(state = defaultState, action) {
  switch (action.type) {
    case REPORT_REQUEST: {
      return {
        ...state,
        report: {},
        isFetching: true,
      };
    }

    case REPORT_SUCCESS: {
      return {
        ...state,
        report: action.payload.item,
        isFetching: false,
        error: null,
      };
    }

    case REPORT_FAIL: {
      return {
        ...state,
        report: {},
        isFetching: false,
        error: action.payload.error,
      };
    }

    case CREATE_REPORT_REQUEST: {
      return {
        ...state,
        build: {},
        submitting: true,
      };
    }

    case CREATE_REPORT_SUCCESS: {
      return {
        ...state,
        build: action.payload.item,
        submitting: false,
        building: true,
      };
    }

    case CREATE_REPORT_FAIL: {
      return {
        ...state,
        build: {},
        submitting: false,
        error: action.payload.error,
      };
    }

    case REPORT_COMPLETED: {
      return {
        ...state,
        building: false,
        build: {
          ...state.build,
          url: action.payload.url,
          error: action.payload.action,
        },
      };
    }

    default:
      break;
  }

  return state;
}
