import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Modal } from 'semantic-ui-react';

class AlertDialog extends Component {
  static defaultProps = {
    open: false,
    title: 'Внимание!',
    message: '',
  };

  state = {
    open: this.props.open || false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({
        open: nextProps.open,
      });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { title, message } = this.props;

    return (
      <Modal size="small" open={this.state.open} closeOnDimmerClick={false}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content image={true}>
          <div className="image">
            <Icon name="warning" size="large" color="red" />
          </div>
          <Modal.Description>{message}</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary content={'Закрыть'} onClick={this.handleClose} />
        </Modal.Actions>
      </Modal>
    );
  }
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.any,
  message: PropTypes.any.isRequired,
  onClose: PropTypes.func,
};

export default AlertDialog;
