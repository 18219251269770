import React, { ReactNode } from 'react';
import { Dropdown, Icon, Image } from 'semantic-ui-react';
// import { User } from 'core/constants/contracts';
import { confirm } from 'components/share/dialogs';
import avatar from './default_avatar.svg';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { logout } from 'core/store/features/app';
import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import './styles.less';

interface Props extends ReturnType<typeof mapDispatchToProps> {
  // user: User;
}

class ProfileDropdown extends React.PureComponent<Props> {
  logout = () => {
    confirm({
      content: 'Вы действительно хотите выйти из аккаунта?',
      onConfirm: () => {
        this.props.logout();
        // try {
        //     window.location.replace(window.location.origin + '/');
        // } catch (e) {
        //     notification.error('Ошибка при выходе из аккаунта(');
        // }
      },
    });
  };

  render(): ReactNode {
    // const { user } = this.props;

    const trigger = (
      <div className={'content'}>
        <Image src={avatar} avatar size={'mini'} />
        {/*
                <div className={'info'}>
                    <div className={'name'}>{user.name}</div>
                    <div className={'phone'}>{user.phone}</div>
                </div>
*/}
      </div>
    );

    return (
      <Dropdown item pointing trigger={trigger} className={'profile'} icon={null}>
        <Dropdown.Menu>
          {/*
                    <Dropdown.Item as={Link} to={'/profile'}>
                        <Icon name={'user outline'} />
                        Профиль
                    </Dropdown.Item>
*/}
          <Dropdown.Item as={Link} to={'/feedback'}>
            <Icon name={'comment outline'} />
            Обратная связь
          </Dropdown.Item>
          {/*
                    <Dropdown.Item as={Link} to={'/faq'}>
                        <Icon name={'question circle outline'} />
                        Инструкции
                    </Dropdown.Item>
*/}
          <Dropdown.Item onClick={this.logout}>
            <Icon name={'log out'} />
            Выход
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    logout: bindActionCreators(logout, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ProfileDropdown);
