import React from 'react';
import PropTypes from 'prop-types';
import CurrentUserContext from './context';
import { isFunction } from 'lodash';

export const withCurrentUser = (Component) => {
  class Wrapper extends React.PureComponent {
    render() {
      const currentUser = this.context;

      return <Component currentUser={currentUser} {...this.props} />;
    }
  }

  Wrapper.contextType = CurrentUserContext;
  Wrapper.displayName = `withCurrentUser(${
    Component.displayName || Component.name || 'Component'
  })`;

  return Wrapper;
};

export const CurrentUser = (props) => {
  const { children, ...rest } = props;

  if (isFunction(children)) {
    return (
      <CurrentUserContext.Consumer>
        {(currentUser) => children({ currentUser, ...rest })}
      </CurrentUserContext.Consumer>
    );
  }

  return (
    <CurrentUserContext.Consumer>
      {(currentUser) =>
        React.Children.map(children, (child) => {
          return React.cloneElement(child, { currentUser, ...rest });
        })
      }
    </CurrentUserContext.Consumer>
  );
};

CurrentUser.propTypes = {
  children: PropTypes.node,
};
