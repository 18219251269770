import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { UserService, UserServiceRaw } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntityById,
  onDelete,
  onFailed,
  onSuccess,
} from './entities';
import api from '../../constants/api';
import { ApiThunk, PromiseThunk } from '..';
import { request } from '../../utils/request';

const options = {
  prepare: function (entity: UserServiceRaw): UserService {
    return { ...entity, service: entity.service.id };
  },
};

const slice = createSlice({
  name: 'user_services',
  initialState: createEntitiesInitialState<UserService>(),
  reducers: createDefaultEntitiesReducers<UserService, UserServiceRaw>(options),
});

export default slice;

export const { pending, success, failed, clear, deleted } = slice.actions;

export const fetchUserServices = createFetchEntityById<UserServiceRaw>(
  api.billingUserServices,
  slice.actions
);

export interface CreateUserServiceData {
  user_id: string;
  service_id: string;
  apartment_id: string;
  need_notification?: boolean;
}

export const createUserService = (data: CreateUserServiceData): ApiThunk<UserService> => {
  return (dispatch: Dispatch) => {
    dispatch(pending());

    return request.post(api.billingUserServicesRegistry(data.user_id), data).then(
      (res) => onSuccess(dispatch, success, res),
      (err) => onFailed(dispatch, failed, err)
    );
  };
};

export interface UpdateUserServiceData {
  user_id: string;
  service_id: string;
  action: 'block' | 'unblock';
  reason_id?: string;
}

export const updateUserService = (data: UpdateUserServiceData): ApiThunk<UserService> => {
  return (dispatch: Dispatch) => {
    dispatch(pending());

    const { user_id, ...rest } = data;

    return request.put(api.billingUserServicesRegistry(data.user_id), rest).then(
      (res) => onSuccess(dispatch, success, res),
      (err) => onFailed(dispatch, failed, err)
    );
  };
};

export interface DeleteUserServiceData {
  user_id: string;
  service_id: string;
  reason_id: string;
}

export const deleteUserService = (data: DeleteUserServiceData): PromiseThunk<string> => {
  return (dispatch: Dispatch) => {
    dispatch(pending());

    const { user_id, ...rest } = data;

    return request.delete(api.billingUserServicesRegistry(data.user_id), { data: rest }).then(
      (res) => onDelete(dispatch, deleted, data.service_id),
      (err) => onFailed(dispatch, failed, err)
    );
  };
};
