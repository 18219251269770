import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { CellProps } from 'react-table';
import { Column } from 'components/share/components/table';
import { Intercom } from 'core/constants/contracts';
import { TernaryStatus } from './components/ternary-status';

export interface IntercomExt extends Intercom {
  ext_user_sip_status?: {
    pending: boolean;
    is_active?: boolean;
    is_call_blocked?: boolean;
    error?: string;
  };
  ext_link_user_sip?: {
    pending: boolean;
    error?: string;
  };
}

interface Params {
  userId: string;
  apartmentNumber: string;
  apartmentSipStatus: (apartmentId: string, intercomId: string) => void;
  linkUserSip: (userId: string, intercomId: string) => void;
}

export function columns(params: Params): Array<Column<IntercomExt>> {
  const { userId, apartmentNumber, linkUserSip, apartmentSipStatus } = params;

  return [
    {
      Header: 'SIP номер',
      accessor: 'phone',
      cellProps: () => ({
        width: 2,
      }),
    },
    {
      Header: 'Название',
      accessor: 'name',
    },
    // {
    //     Header: 'Адрес',
    //     Cell: ({ row: { original } }: CellProps<IntercomExt>) => {
    //         if (!original.has_bindings) {
    //             return <span style={{ fontStyle: 'italic' }}>(Не привязан к адресу)</span>
    //         }
    //
    //         return buildAddressStr(original.device);
    //     }
    // },
    {
      Header: 'Устройство',
      accessor: function (original) {
        return original.device.id;
      },
    },
    {
      Header: 'Статус SIP',
      Cell: ({ row: { original } }: CellProps<IntercomExt>) => {
        return (
          <TernaryStatus
            pending={original.ext_user_sip_status?.pending === true}
            status={original.ext_user_sip_status?.is_active}
            messageStatusTrue={'Записан'}
            messageStatusFalse={'Отсутствует'}
          />
        );
      },
      cellProps: () => ({
        width: 2,
      }),
    },
    {
      Header: 'Блок вызова',
      Cell: ({ row: { original } }: CellProps<IntercomExt>) => {
        return (
          <TernaryStatus
            pending={original.ext_user_sip_status?.pending === true}
            status={original.ext_user_sip_status?.is_call_blocked}
            messageStatusTrue={'Заблокирован'}
            messageStatusFalse={'Разблокирован'}
          />
        );
      },
      cellProps: () => ({
        width: 2,
        style: {
          whiteSpace: 'noWrap',
        },
      }),
    },
    {
      Header: 'Действия',
      accessor: 'id',
      Cell: ({ cell: { value: intercomId }, row: { original } }: CellProps<IntercomExt>) => {
        const isPendingUserSipStatus = original.ext_user_sip_status?.pending ?? false;
        const isPendingLinkUserSip = original.ext_link_user_sip?.pending ?? false;

        return (
          <React.Fragment>
            <Popup
              position={'top center'}
              content={'Получить статус о клиенте непосредственно с домофона'}
              trigger={
                <Button
                  loading={isPendingUserSipStatus}
                  disabled={isPendingUserSipStatus}
                  secondary
                  icon={'reply'}
                  onClick={() => apartmentSipStatus(apartmentNumber, intercomId)}
                />
              }
            />
            <Popup
              position={'top center'}
              content={'Прописать SIP номер клиента на домофон'}
              trigger={
                <Button
                  loading={isPendingLinkUserSip}
                  disabled={isPendingLinkUserSip}
                  secondary
                  icon={'sync'}
                  onClick={() => linkUserSip(userId, intercomId)}
                />
              }
            />
          </React.Fragment>
        );
      },
      cellProps: () => ({
        width: 2,
      }),
    },
  ];
}
