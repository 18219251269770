import React, { FC } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';

import { SetBreadcrumb } from 'components/share/breadcrumbs';
import { CreateVideoRecorderContainer } from 'components/share/containers/video-recorder/form/create';

interface VideoRecorderCreateProps extends RouteComponentProps {};

const VideoRecorderCreate: FC<VideoRecorderCreateProps> = (props) => {
  return (
    <Grid>
      <SetBreadcrumb url={'/video-recorders'}>Видеорегистраторы</SetBreadcrumb>
      <SetBreadcrumb>Добавить видеорегистратор</SetBreadcrumb>

      <Grid.Row>
        <Grid.Column>
          <Header as="h1">Добавить видеорегистратор</Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <CreateVideoRecorderContainer {...props}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default VideoRecorderCreate;
