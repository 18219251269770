import React, { ReactNode } from 'react';
import { change, untouch, WrappedFieldProps } from 'redux-form';
import { StrictDropdownItemProps } from 'semantic-ui-react';
import { get } from 'lodash';
import { Binding, Building } from 'core/constants/contracts';
import { WebAPIResponse } from 'core/utils/webapi.contracts';

export const BINDING_TYPE_ENTRANCES_UNION = 'entrances_union';
export const BINDING_TYPE_ENTRANCE = 'entrance';
export const BINDING_TYPE_FLOR = 'floor';
export const BINDING_TYPE_APARTMENT = 'apartment';

function findBindingParentByType(
  binding: Binding,
  parentType: string,
  bindings: Array<Binding>
): Nullable<Binding> {
  if (!binding.parent) {
    return null;
  }

  const parent = bindings.find((v) => v.id === binding.parent);
  if (!parent) {
    return null;
  }

  return parent.type === parentType
    ? parent
    : findBindingParentByType(parent, parentType, bindings);
}

export interface DropdownOption {
  key: string;
  text: string;
  value: string | number;

  origin: Nullable<Binding>;

  entrancesUnion?: Nullable<Binding>;
  entrance?: Nullable<Binding>;
  floor?: Nullable<Binding>;
}

export type changeHandler = (e: React.SyntheticEvent<HTMLElement>, value: string) => void;

export interface HandlerProps {
  isFetching: boolean;
  readOnly: boolean;
  readOnlyBuilding: boolean;
  // TODO show флаги здесь не нужны
  showCheckboxEntranceUnion: boolean;
  showEntrance: boolean;
  showApartment: boolean;
  showFloor: boolean;
  // TODO required флаги здесь не нужны
  requiredBuilding: boolean;
  requiredEntrance: boolean;
  requiredApartment: boolean;
  requiredFloor: boolean;

  buildingOptions: Array<StrictDropdownItemProps>;
  apartmentOptions: Array<StrictDropdownItemProps>;
  floorOptions: Array<StrictDropdownItemProps>;
  entranceOptions: Array<StrictDropdownItemProps>;

  handleChangeBuilding: changeHandler;
  handleChangeApartment: changeHandler;
  handleChangeFloor: changeHandler;
  handleChangeEntrance: changeHandler;

  entrancesUnion: Nullable<Binding>;
}

interface OwnProps {
  children: (props: HandlerProps) => ReactNode;
  onChangeAddress?: Function;
  fetchBindings?: (buildingId: string) => Promise<WebAPIResponse<Binding>>;
}

interface OwnPropsDefault {
  readOnly: boolean;
  readOnlyBuilding: boolean;
  buildings: Array<Building>;
  bindings?: Array<Binding>;

  requiredBuilding: boolean;
  requiredEntrance: boolean;
  requiredApartment: boolean;
  requiredFloor: boolean;

  showCheckboxEntranceUnion: boolean;
  showEntrance: boolean;
  showApartment: boolean;
  showFloor: boolean;

  calculateFloor: boolean;
}

interface Props extends OwnProps, OwnPropsDefault, WrappedFieldProps {}

export type ComponentProps = PartialSubset<Props, OwnPropsDefault>;

interface State {
  isFetching: boolean;
  buildingId: Nullable<string>;
  entranceOptions: Array<DropdownOption>;
  floorOptions: Array<DropdownOption>;
  apartmentOptions: Array<DropdownOption>;
  entrancesUnion: Nullable<Binding>;
}

class AddressDropdownHandler extends React.Component<Props, State> {
  state: State = {
    isFetching: false,
    buildingId: null,

    entranceOptions: [],
    floorOptions: [],
    apartmentOptions: [],

    entrancesUnion: null, // TODO не нужно в стейте
  };

  static defaultProps: Partial<OwnPropsDefault> = {
    readOnly: false,
    readOnlyBuilding: false,
    buildings: [],

    requiredBuilding: true,
    requiredEntrance: true,
    requiredApartment: true,
    requiredFloor: true,

    showCheckboxEntranceUnion: false,
    showEntrance: true,
    showApartment: true,
    showFloor: true,

    calculateFloor: true,
  };

  private readonly onBuildingChangeFields: Array<string>;
  private readonly onEntranceChangeFields: Array<string>;

  constructor(props: Props) {
    super(props);

    const {
      input: { name },
    } = this.props;

    if (!(props.fetchBindings || props.bindings)) {
      throw new Error('Set props "fetchBindings" or "bindings"');
    }

    this.onBuildingChangeFields = [`${name}.apartment`, `${name}.floor`, `${name}.entrance`];
    this.onEntranceChangeFields = [`${name}.apartment`, `${name}.floor`];
  }

  componentDidMount() {
    this.componentDidHandler();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.componentDidHandler();
  }

  componentDidHandler = () => {
    const buildingId = get(this.props.input.value, 'building');

    if (
      this.state.entranceOptions.length === 0 &&
      this.state.apartmentOptions.length === 0 &&
      buildingId &&
      buildingId !== this.state.buildingId
    ) {
      this.updateOptions(buildingId);
    }
  };

  static calcOptionsState(bindings: Array<Binding> = []) {
    const entranceOptions: Array<DropdownOption> = [];
    const floorOptions: Array<DropdownOption> = [];
    const apartmentOptions: Array<DropdownOption> = [];

    const entrancesUnions = bindings.filter((v) => v.type === BINDING_TYPE_ENTRANCES_UNION);
    const entrances = bindings.filter((v) => v.type === BINDING_TYPE_ENTRANCE);
    for (let i = 0; i < entrances.length; i++) {
      const entrance = entrances[i];
      const entrancesUnion = findBindingParentByType(
        entrance,
        BINDING_TYPE_ENTRANCES_UNION,
        entrancesUnions
      );

      entranceOptions.push({
        key: entrance.id,
        text: entrance.number,
        value: entrance.id,
        origin: entrance,
        entrancesUnion,
      });
    }

    const floors = bindings.filter((v) => v.type === BINDING_TYPE_FLOR);
    for (let i = 0; i < floors.length; i++) {
      const floor = floors[i];
      const entrance = findBindingParentByType(floor, BINDING_TYPE_ENTRANCE, bindings);

      floorOptions.push({
        key: floor.id,
        text: floor.number,
        value: floor.id,
        origin: floor,
        entrance,
      });
    }

    const apartments = bindings
      .filter((v) => v.type === BINDING_TYPE_APARTMENT)
      .sort((a, b) => parseInt(a.number) - parseInt(b.number));
    for (let i = 0; i < apartments.length; i++) {
      const apartment = apartments[i];
      const floor = findBindingParentByType(apartment, BINDING_TYPE_FLOR, bindings);

      apartmentOptions.push({
        key: apartment.id,
        text: apartment.number,
        value: apartment.id,
        origin: apartment,
        floor,
      });
    }

    return {
      entranceOptions,
      floorOptions,
      apartmentOptions,
    };
  }

  /**
   *
   * @param {string} buildingId
   */
  updateOptions = (buildingId: string) => {
    if (!this.props.fetchBindings) {
      this.setState({
        buildingId,
        ...AddressDropdownHandler.calcOptionsState(this.props.bindings),
      });
      return;
    }

    this.setState({ isFetching: true, buildingId });

    this.props
      .fetchBindings(buildingId)
      .then((result) => result.list)
      .catch((err) => {
        console.error(err);
        return [];
      })
      .then((bindings) => this.setState(AddressDropdownHandler.calcOptionsState(bindings)))
      .finally(() => this.setState({ isFetching: false }));
  };

  handleChangeBuilding: changeHandler = (e, value) => {
    // @ts-ignore
    if (this.props.input.building !== value) {
      this.resetFields(this.onBuildingChangeFields);
      this.updateOptions(value);

      if (this.props.onChangeAddress) {
        const building = this.props.buildings.find((v) => v.id === value);
        this.props.onChangeAddress('building', value, building);
      }
    }
  };

  handleChangeEntrance: changeHandler = (e, value) => {
    // @ts-ignore
    if (this.props.input.entrance === value) {
      return;
    }

    const entrance = this.state.entranceOptions.find((v) => v.value === value);
    const entrancesUnion = get(entrance, 'entrancesUnion');
    this.changeField(`entrances_union`, get(entrancesUnion, 'id'), entrancesUnion);

    if (entrance && entrance.entrancesUnion) {
      this.setState({ entrancesUnion: entrance.entrancesUnion });
    } else {
      this.setState({ entrancesUnion: null });
    }

    const floor = this.state.floorOptions.find((v) => v.entrance!.id === value);
    this.changeField(`floor`, get(floor, 'value'), get(floor, 'origin'));

    if (floor) {
      const apartment = this.state.apartmentOptions.find((v) => v.floor!.id === floor.value);
      this.changeField(`apartment`, get(apartment, 'value'), get(apartment, 'origin'));
    }

    if (this.props.onChangeAddress) {
      const entrance = this.state.entranceOptions.find((v) => v.value === value);
      this.props.onChangeAddress('entrance', value, get(entrance, 'origin'));
    }
  };

  handleChangeFloor: changeHandler = (e, value) => {
    // @ts-ignore
    if (this.props.input.floor === value) {
      return;
    }

    const floor = this.state.floorOptions.find((v) => v.value === value);

    const entrance = get(floor, 'entrance');
    this.changeField(`entrance`, get(entrance, 'id'), entrance);

    if (entrance) {
      const entrancesUnion = this.state.entranceOptions.find(
        (v) => v.value === entrance.id
      )!.entrancesUnion;
      this.changeField(`entrances_union`, get(entrancesUnion, 'id'), entrancesUnion);
    }

    const apartment = this.state.apartmentOptions.find((v) => v.floor!.id === value);
    this.changeField(`apartment`, get(apartment, 'value'), get(apartment, 'origin'));

    if (this.props.onChangeAddress) {
      this.props.onChangeAddress('floor', value, get(floor, 'origin'));
    }
  };

  handleChangeApartment: changeHandler = (e, value) => {
    // @ts-ignore
    if (this.props.input.apartment === value) {
      return;
    }

    const apartment = this.state.apartmentOptions.find((v) => v.value === value);
    const floor = get(apartment, 'floor');
    this.changeField(`floor`, get(floor, 'id'), floor);

    if (floor) {
      const entrance = this.state.floorOptions.find((v) => v.value === floor.id)!.entrance;
      this.changeField(`entrance`, get(entrance, 'id'), entrance);

      if (entrance) {
        const entrancesUnion = this.state.entranceOptions.find(
          (v) => v.value === entrance.id
        )!.entrancesUnion;
        this.changeField(`entrances_union`, get(entrancesUnion, 'id'), entrancesUnion);
      }
    }

    if (this.props.onChangeAddress) {
      this.props.onChangeAddress('apartment', value, get(apartment, 'origin'));
    }
  };

  resetFields = (fields: Array<string>) => {
    const {
      meta: { form, dispatch },
    } = this.props;

    fields.forEach((field) => {
      dispatch(change(form, field, null));
      dispatch(untouch(form, field));
    });
  };

  changeField = (field: string, value: any, option: Nullable<Binding>) => {
    const {
      input: { name },
      meta: { form, dispatch },
      onChangeAddress,
    } = this.props;

    if (value === undefined) {
      value = null;
    }

    dispatch(change(form, `${name}.${field}`, value));

    if (onChangeAddress) {
      onChangeAddress(field, value, option);
    }
  };

  render() {
    const { isFetching, entrancesUnion } = this.state;

    const {
      // input: { name },
      buildings,
      readOnly,
      readOnlyBuilding,
      showCheckboxEntranceUnion,
      showEntrance,
      showApartment,
      showFloor,
      requiredBuilding,
      requiredEntrance,
      requiredApartment,
      requiredFloor,
    } = this.props;

    const buildingOptions = buildings.map((item) => ({
      text: item.address.text,
      value: item.id,
      key: item.id,
    }));

    return this.props.children({
      isFetching,
      readOnly,
      readOnlyBuilding,
      showCheckboxEntranceUnion,
      showEntrance,
      showApartment,
      showFloor,
      requiredBuilding,
      requiredEntrance,
      requiredApartment,
      requiredFloor,

      buildingOptions: buildingOptions,
      apartmentOptions: this.state.apartmentOptions,
      floorOptions: this.state.floorOptions,
      entranceOptions: this.state.entranceOptions,

      handleChangeBuilding: this.handleChangeBuilding,
      handleChangeApartment: this.handleChangeApartment,
      handleChangeFloor: this.handleChangeFloor,
      handleChangeEntrance: this.handleChangeEntrance,

      entrancesUnion,
    });
  }
}

export default AddressDropdownHandler;
