import { createSlice } from '@reduxjs/toolkit';
import { Event } from '../../constants/contracts';
import {
  createDefaultEntitiesReducers,
  createEntitiesInitialState,
  createFetchEntities,
} from './entities';
import api from '../../constants/api';

const slice = createSlice({
  name: 'events',
  initialState: createEntitiesInitialState<Event>(),
  reducers: createDefaultEntitiesReducers<Event>(),
});

export default slice;

export const { pending, success, failed, clear, deleted } = slice.actions;

export const fetchEvents = createFetchEntities(api.events, slice.actions);
