import React, { FC, useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

import { confirm } from 'components/share/dialogs';
import notification from 'core/utils/notification';
import { RootState } from 'core/store';

import { DeviceReference, VideoRecorder } from 'core/constants/contracts';
import { updateVideoRecorder } from 'core/store/features/video-recorders';
import { DataForVideoRecorder } from 'components/share/containers/video-recorder/form/config';
import { clear as clearVideoRecorders } from 'core/store/features/video-recorders';
import { VideoRecorderEditForm, VideoRecorderFormData } from './edit-form';

import { createSelectArrayToArray } from 'core/store/selectors';

import { LocationStateNavigation } from 'core/utils/routing';

type RouteParams = {
  videoRecorderId: string;
};
type ConnectProps = ConnectedProps<typeof withConnect>;
type VideoRecorderEditProps = ConnectProps &
  RouteComponentProps<RouteParams, any, LocationStateNavigation>;

interface EditProps {
  videoRecorder: VideoRecorder;
  references: Record<string, DeviceReference>;
}

const VideoRecorderEdit: FC<VideoRecorderEditProps> = ({
  videoRecorder,
  references,
  history,
  updateVideoRecorder,
}) => {
  const [, setIsFetching] = useState(false);

  const routeToIntercom = () => history.push('/video-recorders');

  const handleCancel = () => {
    confirm({
      content: 'Вы уверены, что хотите отменить изменение видеорегистратора?',
      onConfirm: routeToIntercom,
    });
  };

  const handleSubmitForm = async (values: VideoRecorderFormData) => {
    const { name, protocol, username, password, hostname, port, rtsp_port, reference } = values;

    confirm({
      content: 'Вы уверены, что хотите сохранить данные видеорегистратора?',
      onConfirm: async () => {
        setIsFetching(true);
        let message;

        const data: DataForVideoRecorder = {
          name,
          protocol,
          username,
          password,
          hostname,
          port,
          rtsp_port,
          device: { reference, owner: videoRecorder.device.owner.id },
        };

        if (videoRecorder) {
          try {
            const videoRecorderResult = await updateVideoRecorder(videoRecorder.id, data);
            message = _.get(videoRecorderResult, 'error');
          } catch (e) {
            message = 'Update video-recorder error';
            if (e instanceof Error) {
              message = e.message;
            }
            console.error(e);
          } finally {
            setIsFetching(false);
          }
        } else {
          setIsFetching(false);
          message = 'Произошла ошибка, отсутствуют данные Видеорегистратора';
        }

        if (message) {
          notification.error(message);
        } else {
          notification.success();
          setTimeout(routeToIntercom, 1000);
        }
      },
    });
  };

  return (
    <>
      <VideoRecorderEditForm
        references={references}
        videoRecorder={videoRecorder}
        handleCancel={handleCancel}
        handleSubmit={handleSubmitForm}
      />
    </>
  );
};

const selectDeviceReferencesAsOptions = createSelectArrayToArray<
  DeviceReference,
  DropdownItemProps
>((v) => ({
  value: v.id,
  text: v.name,
}));

const mapStateToProps = (state: RootState, data: EditProps) => {
  return {
    references: selectDeviceReferencesAsOptions(data.references) as Array<DropdownItemProps>,
    videoRecorder: data.videoRecorder,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    updateVideoRecorder: bindActionCreators(updateVideoRecorder, dispatch),
    clearVideoRecorders: bindActionCreators(clearVideoRecorders, dispatch),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(VideoRecorderEdit);
