import List from './list';
import Create from './create';
import Edit from './edit';
import Apartments from './apartments';

export default {
  List,
  Create,
  Edit,
  Apartments,
};
