import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  formPropTypes,
  formValueSelector,
  reduxForm,
  SubmissionError,
  defaultShouldValidate,
} from 'redux-form';
import { Button, Form, Grid, Segment } from 'semantic-ui-react';
import { validateBySchema } from 'core/utils/validate';
import IndividualForm from '../../components/forms/individual-form-body';
import schemaIndividualFactory from '../../components/forms/individual-form-body/schema';
import EntityForm from '../../components/forms/entity-form-body';
import schemaLegalEntityFactory from '../../components/forms/entity-form-body/schema';
import {
  COMPANY_ADMINISTRATIVE_STATUS_ACTIVE,
  COMPANY_COMMERCIAL_STATUS_COMMERCIAL,
  COMPANY_COMMERCIAL_STATUS_MAP,
  COMPANY_CONTRACTOR_TYPE_INDIVIDUAL,
  COMPANY_CONTRACTOR_TYPE_LEGAL,
  COMPANY_CONTRACTOR_TYPE_MAP,
} from 'core/constants/domain/companies';
import { mapToOptions } from 'core/utils/forms';
import { request } from 'core/utils/request';
import api from 'core/constants/api';
import notification from 'core/utils/notification';
import isObject from 'lodash/isObject';

const contractorOptions = Object.entries(COMPANY_CONTRACTOR_TYPE_MAP).map(mapToOptions);
const defaultContractorType = COMPANY_CONTRACTOR_TYPE_LEGAL;

const commercialStatusOptions = Object.entries(COMPANY_COMMERCIAL_STATUS_MAP).map(mapToOptions);
const defaultCommercialStatus = commercialStatusOptions[0].value;
const commercialStatusValues = commercialStatusOptions.map((item) => item.value);

const schemaEntitySchema = schemaLegalEntityFactory(commercialStatusValues);
const schemaIndividualSchema = schemaIndividualFactory(commercialStatusValues);

const validate = (values) => {
  let schema;

  switch (values.person_type) {
    case COMPANY_CONTRACTOR_TYPE_INDIVIDUAL:
      schema = schemaIndividualSchema;
      break;
    case COMPANY_CONTRACTOR_TYPE_LEGAL:
      schema = schemaEntitySchema;
      break;
    default:
      throw new Error('Invalid validation type');
  }

  return validateBySchema(schema, values);
};

class Step1 extends React.Component {
  state = {
    person_type: defaultContractorType,
  };

  handleChangeType = (value) => {
    if (this.state.person_type !== value) {
      this.props.destroy();
      this.props.initialize({ person_type: value, commercial_status: defaultCommercialStatus });
      this.setState({ person_type: value });
    }
  };

  handleNext = async (values) => {
    try {
      const { person_type, commercial_status, foris, personal_account, ...rest } = values;

      const data = {
        person: rest,
        person_type,
        administrative_status: COMPANY_ADMINISTRATIVE_STATUS_ACTIVE,
        commercial_status,
        foris: commercial_status === COMPANY_COMMERCIAL_STATUS_COMMERCIAL ? foris : null,
        personal_account,
      };

      await request.post(api.companies() + '/validate', data);
      this.props.next();
    } catch (err) {
      const response = err.response ? err.response.data : {};
      const error = response.error;

      if (error) {
        if (error.data && isObject(error.data)) {
          let shape = error.data;
          if (shape.person) {
            shape = { ...shape, ...shape.person };
            delete shape.person;
          }
          if (shape.person_type) {
            return notification.error(shape.person_type);
          }

          throw new SubmissionError(shape);
        }

        if (error.message) {
          return notification.error(error.message);
        }
      }

      notification.error('Неизвестная ошибка');
    }
  };

  render() {
    const { person_type } = this.state;

    const { handleSubmit, invalid, submitting, showForis } = this.props;

    let form;
    if (person_type === COMPANY_CONTRACTOR_TYPE_INDIVIDUAL) {
      form = (
        <IndividualForm
          showForis={showForis}
          commercialStatuses={commercialStatusOptions}
          contractorOptions={contractorOptions}
          handleChangeType={this.handleChangeType}
        />
      );
    } else {
      form = (
        <EntityForm
          showForis={showForis}
          commercialStatuses={commercialStatusOptions}
          contractorOptions={contractorOptions}
          handleChangeType={this.handleChangeType}
        />
      );
    }

    return (
      <Segment padded className="segment-form">
        <Form error={invalid}>
          <Grid>
            <Grid.Row>
              <Grid.Column>{form}</Grid.Column>
            </Grid.Row>
            <Grid.Row className="row-button" columns={3}>
              <Grid.Column floated="right">
                <Button
                  primary
                  floated="right"
                  disabled={submitting}
                  onClick={handleSubmit(this.handleNext)}
                  content="Далее"
                  className="width-medium"
                  tall
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}

Step1.propTypes = {
  ...formPropTypes,
  showForis: PropTypes.bool,
  next: PropTypes.func.isRequired,
};

export const FORM_ID = 'create_company';

const selector = formValueSelector(FORM_ID);

const mapStateToProps = (state) => {
  return {
    showForis: selector(state, 'commercial_status') === COMPANY_COMMERCIAL_STATUS_COMMERCIAL,
    initialValues: {
      person_type: defaultContractorType,
      commercial_status: defaultCommercialStatus,
    },
  };
};

function shouldValidate(data) {
  if (data.initialRender) {
    return false;
  }

  return defaultShouldValidate(data);
}

const form = reduxForm({ form: FORM_ID, validate, shouldValidate })(Step1);

export default connect(mapStateToProps)(form);
