import React, { ReactNode } from 'react';

const NOOP = () => {};

export const BreadcrumbsContext = React.createContext<Context>({
  items: [],
  set: NOOP,
  remove: NOOP,
  clear: NOOP,
});
BreadcrumbsContext.displayName = 'BreadcrumbsContext';

export const BreadcrumbsConsumer = BreadcrumbsContext.Consumer;
export const BreadcrumbsProvider = BreadcrumbsContext.Provider;

export interface Item {
  id: string;
  url?: string;
  content: ReactNode;
}

interface Context {
  items: Array<Item>;
  set: (id: string, content: ReactNode, path?: string) => void;
  remove: (id: string) => void;
  clear: () => void;
}

interface State {
  map: Record<string, Item>;
  positions: Array<string>;
}

export class Provider extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = this.createDefaultState();
  }

  createDefaultState(): State {
    return {
      map: {},
      positions: [],
    };
  }

  setItem = (id: string, content: ReactNode, url?: string) => {
    if (this.state.map[id]) {
      this.setState((prevState) => {
        const newMap = {
          ...prevState.map,
          [id]: {
            id,
            content,
            url,
          },
        };

        return {
          map: newMap,
        };
      });
    } else {
      this.setState((prevState) => {
        const newPositions = [...prevState.positions];
        newPositions.push(id);

        const newMap = {
          ...prevState.map,
          [id]: {
            id,
            content,
            url,
          },
        };

        return {
          map: newMap,
          positions: newPositions,
        };
      });
    }
  };

  removeItem = (id: string) => {
    this.setState((prevState) => {
      const newMap = { ...prevState.map };
      delete newMap[id];

      const newPositions = [...prevState.positions].filter((v) => v !== id);

      return {
        map: newMap,
        positions: newPositions,
      };
    });
  };

  clearItems = () => {
    this.setState(this.createDefaultState());
  };

  render() {
    const context: Context = {
      items: this.state.positions.map((key) => this.state.map[key]),
      set: this.setItem,
      remove: this.removeItem,
      clear: this.clearItems,
    };

    return <BreadcrumbsProvider value={context}>{this.props.children}</BreadcrumbsProvider>;
  }
}
