import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Dimmer, Header, Loader } from 'semantic-ui-react';
import { Card } from 'components/share';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { bindActionCreators } from '@reduxjs/toolkit';
import { fetchCompany, saveCompany } from 'core/store/actions/companies';
import { SetBreadcrumb } from 'components/share/breadcrumbs';
import BlockCompany from '../components/block-company';
import CompanyEditForm from './form';

class CompanyEditContainer extends React.Component {
  componentDidMount() {
    const { match } = this.props;
    this.props.fetchCompany(match.params.id);
  }

  handleCancel = () => {
    this.props.history.push(`/companies/${this.props.company.item.id}`);
  };

  render() {
    const {
      company: { item = {} },
      saveCompany,
    } = this.props;

    if (isEmpty(item)) {
      return (
        <Dimmer active={true} inverted>
          <Loader />
        </Dimmer>
      );
    }

    return (
      <React.Fragment>
        <SetBreadcrumb url={'/companies'}>Компании</SetBreadcrumb>
        <SetBreadcrumb url={`/companies/${item.id}`}>{item.name}</SetBreadcrumb>
        <SetBreadcrumb>Редактирование</SetBreadcrumb>

        <Header as={'h2'}>{item.name}</Header>
        <Card title={'Данные компании'}>
          <CompanyEditForm company={item} handleCancel={this.handleCancel} />
        </Card>
        <BlockCompany company={item} saveCompany={saveCompany} />
      </React.Fragment>
    );
  }
}

CompanyEditContainer.propTypes = {
  fetchCompany: PropTypes.func,
  saveCompany: PropTypes.func,
  company: PropTypes.shape({
    isFetching: PropTypes.bool,
    item: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),

  match: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state, props) => {
  const company = get(state, `app.company.${props.match.params.id}`, {});

  return {
    company,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCompany: bindActionCreators(fetchCompany, dispatch),
  saveCompany: bindActionCreators(saveCompany, dispatch),
});

let component;

component = withRouter(CompanyEditContainer);
component = connect(mapStateToProps, mapDispatchToProps)(component);

export default component;
