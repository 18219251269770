import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, CheckboxProps, Form, SemanticWIDTHSNUMBER } from 'semantic-ui-react';
import { chunk } from 'lodash';
import { DeviceCommand } from 'core/constants/contracts';
import { PX } from 'core/utils/promise';
import { saveDevice } from 'core/store/features/devices';
import notification from 'core/utils/notification';
import { IntercomAgg } from 'core/store/selectors/intercoms';
import { FormStyled } from './styles';

export type DeviceCommandAndCheck = DeviceCommand & { checked: boolean };

interface Props {
  intercom: IntercomAgg;
  chunkSize?: SemanticWIDTHSNUMBER;
  isDisabled?: boolean;
}

const commandsList: { [key: string]: string } = {
  'video-from-camera': 'Доступен просмотр видео с камеры в приложении',
  unlock: 'Доступно открытие двери в приложении',
};

export function DeviceCommands(props: Props) {
  const { intercom, chunkSize = 3, isDisabled } = props;

  const deviceId = intercom.device.id;

  const dispatch = useDispatch();

  const commands = useMemo<Array<DeviceCommandAndCheck>>(() => {
    const possibleCommands = intercom?.device.possible_commands;
    const commands = intercom?.__commands?.flatMap((v) =>
      v.can_customize
        ? {
            ...v,
            checked: possibleCommands?.includes(v.alias) ?? false,
          }
        : []
    );
    return commands ?? [];
  }, [intercom]);

  const initialState = useMemo(() => {
    return commands.reduce((acc, v) => {
      acc[v.alias] = v.checked;
      return acc;
    }, {} as Record<string, boolean>);
  }, [commands]);

  const [isPending, setPending] = useState(false);
  const [checks, setCheck] = useState(initialState);

  const updateChecks = useCallback(
    (alias: string, checked: boolean) => {
      const nextState = { ...checks, [alias]: checked };
      setCheck(nextState);
      setPending(true);

      const disallowedCommands = Object.keys(nextState).filter((alias) => !nextState[alias]);

      const data = {
        id: deviceId,
        disallowed_commands: disallowedCommands,
      };
      const promise = dispatch(saveDevice(data)) as unknown as Promise<any>;

      PX.executeAtLeast(promise, 300)
        .catch((err) => notification.error(err.message))
        .finally(() => setPending(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checks, deviceId]
  );

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      updateChecks(data['alias'], data.checked ?? false);
    },
    [updateChecks]
  );

  return (
    <FormStyled>
      {chunk(commands, chunkSize).map((arr, index) => {
        return (
          <Form.Group widths={chunkSize} key={index}>
            {arr.map((v) => {
              return (
                <Form.Field key={v.alias}>
                  <Checkbox
                    alias={v.alias}
                    label={commandsList[v.alias]}
                    toggle
                    disabled={isPending || isDisabled}
                    onChange={handleChange}
                    checked={checks[v.alias]}
                  />
                </Form.Field>
              );
            })}
          </Form.Group>
        );
      })}
    </FormStyled>
  );
}
