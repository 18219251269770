import React, { PropsWithChildren, useCallback, useState } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { ThemeVars } from 'core/styles/vars';
import { ReactComponent as ArrowBottom } from '../../assets/arrow-bottom.svg';

interface Size {
  fontSize: string;
  lineHeight: string;
  fontWeight: string;
}

const sizes = {
  12: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '500',
  },
  14: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'normal',
  },
  16: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'normal',
  },
};

interface LinkProps {
  size: Size;
}

const Link = styled.button<LinkProps>`
  font-family: 'MTS Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  display: inline-flex;
  background: none;
  border: none;
  padding: 0 2px 0 2px;
  cursor: pointer;
  font-style: normal;
  font-weight: ${(props) => props.size.fontWeight};
  font-size: ${(props) => props.size.fontSize};
  line-height: ${(props) => props.size.lineHeight};
  color: ${ThemeVars.colors.normalBlueberry};

  svg {
    fill: ${ThemeVars.colors.normalBlueberry};
  }

  &:hover {
    color: ${ThemeVars.colors.darkBlueberry};

    svg {
      fill: ${ThemeVars.colors.darkBlueberry};
    }
  }

  &:active {
    color: ${ThemeVars.colors.lightBlueberry};

    svg {
      fill: ${ThemeVars.colors.lightBlueberry};
    }
  }

  &:focus {
    border: none;
    background-color: ${ThemeVars.colors.darkBlueberry};
    color: ${ThemeVars.colors.white};
    outline-style: none;

    svg {
      fill: ${ThemeVars.colors.white};
    }
  }

  span {
    display: flex;
    align-items: center;

    .icon {
      font-size: 20px; // TODO
      margin-left: 8px;

      &.arrow-bottom {
        transform: rotate(90deg);
        transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &.open {
          transform: rotate(0deg);
        }
      }

      svg {
        width: 1em;
        height: 1em;
      }
    }
  }
`;

export interface LinkButtonProps {
  onClick?: () => void;
  size?: keyof typeof sizes;
  className?: string;
  defaultOpen?: boolean;
  icon?: 'arrow-bottom';
}

export function LinkButton(props: PropsWithChildren<LinkButtonProps>) {
  const { size = 16, icon, onClick, className, defaultOpen = false, children } = props;

  const [isOpen, setOpen] = useState(defaultOpen);

  const handleClick = useCallback(() => {
    onClick?.();
    setOpen((prev) => !prev);
  }, [onClick, setOpen]);

  let iconContent;
  switch (icon) {
    case 'arrow-bottom': {
      iconContent = <ArrowBottom />;
      break;
    }
  }

  if (iconContent) {
    iconContent = <span className={cx('icon', icon, { open: isOpen })}>{iconContent}</span>;
  }

  return (
    <Link size={sizes[size]} onClick={handleClick} className={className}>
      <span>
        {children}
        {iconContent}
      </span>
    </Link>
  );
}
