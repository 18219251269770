import * as React from 'react';
import { Grid } from 'semantic-ui-react';

import { FetchProps, ResponseResult, withSubscription } from '../hoc';
import api from 'core/constants/api';
import { DataGrid } from '../components/data-grid';
import { DropZone, FileUploader, File, STATUS_FAILED, STATUS_SUCCESS } from 'components/drop-zone';
import { request } from 'core/utils/request';
import columns from './columns';
import { SetBreadcrumb } from 'components/share/breadcrumbs';

type MyProps = { data: ResponseResult; match: { params: { integration: string } } } & FetchProps;

class IntegrationFileUploader extends FileUploader {
  integration: string;

  constructor(integration: string) {
    super();
    this.integration = integration;
  }

  async uploadIntegration(file: File) {
    try {
      const formData = new FormData();
      formData.set('integration', this.integration);
      formData.append('files', file.originalFile);
      await request.post(api.integrationSessions(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 10000,
      });
      file.status = STATUS_SUCCESS;
    } catch (e) {
      file.status = STATUS_FAILED;
      file.error = e.message;
    }
  }

  async upload(files: Array<File>) {
    files.forEach((file) => {
      this.uploadIntegration(file).then(() => {
        this.onFileUpload(file);
      });
    });
  }
}

export const SessionsPage = ({ data, fetchData, match }: MyProps) => {
  const fileUploader: IntegrationFileUploader = React.useMemo(
    () => new IntegrationFileUploader(match.params.integration),
    [match.params.integration]
  );

  return (
    <Grid columns={1}>
      <SetBreadcrumb url={'/integrations'}>Интеграции</SetBreadcrumb>
      <SetBreadcrumb>Сессии</SetBreadcrumb>
      <Grid.Column>
        <DropZone fileUploader={fileUploader} />
      </Grid.Column>
      <Grid.Column>
        <DataGrid
          {...data}
          defaultParams={{
            integration: match.params.integration,
            sort: '-created_at',
          }}
          rowOptions={(item: any) => {
            return {
              negative: item.negative > 0,
            };
          }}
          fetchData={fetchData}
          header={'Сессии'}
          emptyMessage={'Сессии не найдены'}
          columns={columns}
        />
      </Grid.Column>
    </Grid>
  );
};

export default withSubscription(SessionsPage, api.integrationSessions());
