import { createSelector } from 'reselect';
import { RootState } from '..';
import { DeviceCommand } from '../../constants/contracts';

export const getDeviceReferences = (state: RootState) => state.entities.devices_references;
export const getDeviceReferencesItems = (state: RootState) =>
  state.entities.devices_references.items;
export const getDeviceReferencesIds = (state: RootState) => state.entities.devices_references.ids;

export const selectDeviceReferencesAsArray = createSelector([getDeviceReferencesItems], (items) =>
  Object.values(items)
);

interface DevicesReferencesIdProps {
  id?: string;
}

export const getDeviceReferencesId = (state: RootState, props?: DevicesReferencesIdProps) =>
  props?.id;

export const selectDeviceReferenceCustomizedCommands = createSelector(
  [getDeviceReferencesItems, getDeviceReferencesId],
  (items, id): Array<DeviceCommand> => {
    if (!id) {
      return [];
    }

    const reference = items[id];
    if (!reference) {
      return [];
    }

    const commands = new Map<string, DeviceCommand>();
    reference.protocols.forEach((p) =>
      p.commands.filter((c) => c.can_customize).forEach((c) => commands.set(c.id, c))
    );

    return Array.from(commands.values());
  }
);
