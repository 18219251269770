import * as yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT } from 'core/constants/app';

export const DATE_LESS_THAN = 'Дата окончания интервала не должна быть меньше даты начала';
export const DATE_MORE_THAN = 'Дата начала интервала не должна быть больше даты окончания';

function parseDateString(value: string, originalValue: null | string): Date | null {
  if (originalValue === null) {
    return null;
  }

  return moment(originalValue, DATE_FORMAT).toDate();
}

const schema = yup.object().shape({
  startDate: yup
    .date()
    .nullable()
    .transform(parseDateString)
    .when('endDate', (endDate: any, schema: any) => {
      if (endDate) {
        return schema.max(yup.ref('endDate'), DATE_MORE_THAN);
      }
      return schema;
    }),
  endDate: yup
    .date()
    .nullable()
    .transform(parseDateString)
    .min(yup.ref('startDate'), DATE_LESS_THAN),
});

export default schema;
