import { FieldInputProps, FormikProps, getIn } from 'formik';

export const EMPTY_STRING = '\u00A0';

export const getFieldError = (
  form: FormikProps<any>,
  field: FieldInputProps<any>
): string | undefined => {
  const { name } = field;
  const { serverValidation } = form.status || {};
  const touched = getIn(form.touched, name);
  const checkTouched = serverValidation ? !touched : touched;

  if (!checkTouched) {
    return undefined;
  }

  const errors = getIn(form.errors, name);

  return Array.isArray(errors) ? errors[0] : errors;
};

export const setFieldValue = (
  form: FormikProps<any>,
  name: string,
  value: any,
  shouldValidate: boolean
) => {
  form.setFieldValue(name, value, shouldValidate);
  form.setFieldTouched(name, true, false);
  // или валидировать?
  // Promise.resolve().then(() => {
  //     form.setFieldTouched(name, true, shouldValidate);
  // });
};
