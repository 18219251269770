import { combineReducers } from '@reduxjs/toolkit';
import { SET_PAGE_TITLE } from '../../../constants/actions';
import users from './users';
import user from './user';
import token from './token';
import intercoms from './intercoms';
import intercom from './intercom';
import metrics from './metrics';
import services from './services';
import company from './company';
import companies from './companies';
import apartments from './apartments';
import building from './building';
import buildings from './buildings';
import bindings from './bindings';
import reports from './reports';
import deviceReference from './device_reference';
import deviceReferences from './device_references';
import parserRow from './parser-row';
import parserRows from './parser-rows';
import parserSessions from './upload-session';
import uploadParserSession from './upload-session';

export function setPageTitle(state = 'Умный домофон | МТС', action) {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return action.title;
    default:
      return state;
  }
}

export default combineReducers({
  title: setPageTitle,
  apartments,
  building,
  buildings,
  bindings,
  deviceReference,
  deviceReferences,
  company,
  companies,
  user,
  users,
  intercom,
  intercoms,
  metrics,
  services,
  reports,
  token,
  parserRow,
  parserSessions,
  parserRows,
  uploadParserSession,
});
