import { createSelector } from 'reselect';
import { CompanyService } from 'core/constants/contracts';
import { RootState } from '..';
import { GenericParametricSelector, PossibleIdProps } from '.';
import { getCompaniesItems } from './companies';
import { EntitiesState, EntityId } from '../features/entities';

export const getCompanyService = (state: RootState) => state.entities.companyService;
export const getCompanyServiceItems = (state: RootState) => state.entities.companyService.items;

// агрегат
export interface CompanyServiceAgg extends CompanyService {
  __company_name: string;
}

export const createSelectCompanyAggAsArray = (): GenericParametricSelector<
  PossibleIdProps,
  Array<CompanyServiceAgg>
> => {
  return createSelector([getCompaniesItems, getCompanyServiceItems], (company, companyService) => {
    return Object.values(companyService).map((v) => {
      const companyName = Object.keys(company).length ? company[v.owner.id].name : v.owner.id;

      return {
        ...v,
        __company_name: companyName,
      };
    });
  });
};

export const createSelectCompanyAggAsMap = (): GenericParametricSelector<
  PossibleIdProps,
  Record<EntityId, CompanyServiceAgg>
> => {
  const selectCompanyAggAsArray = createSelectCompanyAggAsArray();

  return createSelector([selectCompanyAggAsArray], (intercoms) => {
    return intercoms.reduce((acc, v) => {
      acc[v.id] = v;

      return acc;
    }, {} as Record<EntityId, CompanyServiceAgg>);
  });
};

export const createSelectCompanyServiceAggAsState = (): GenericParametricSelector<
  PossibleIdProps,
  EntitiesState<CompanyServiceAgg>
> => {
  const selectCompanyAggAsMap = createSelectCompanyAggAsMap();

  return createSelector(
    [getCompanyService, selectCompanyAggAsMap],
    (companyServiceState, items) => {
      return {
        ...companyServiceState,
        items,
      };
    }
  );
};
