import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { Header, Segment } from 'semantic-ui-react';
import { validateBySchemaAsync } from 'core/utils/validate';
import { requestErrorHandlerForReduxForm, submissionErrorFlatten } from 'core/utils/errors';
import { CompanyProps, CurrentUserProps, WebHistoryProps } from 'core/constants/props';
import { BuildingCommercialStatus } from 'core/constants/contracts';
import { saveBuilding } from 'core/store/features/buildings';
import { batchProcessingBindings } from 'core/store/features/bindings';
import { fetchCompanies } from 'core/store/features/companies';
import { selectCompaniesAsArray } from 'core/store/selectors/companies';
import notification from 'core/utils/notification';
import BuildingForm from 'views/pages/buildings/components/forms';
import { prepareBindings, hasIntersections } from '../utils';

import schema from '../schema/home';

const FORM_ID = 'create-building';

const mapStateToPropsForm = (state, props) => {
  return {
    initialValues: props.initialValues,
  };
};

const s = schema();
const validate = (values) => {
  return validateBySchemaAsync(s, values);
};

const FormComponent = connect(mapStateToPropsForm)(
  reduxForm({
    form: FORM_ID,
    asyncValidate: validate,
    shouldAsyncValidate: () => true,
    enableReinitialize: true,
  })(BuildingForm)
);

class BuildingCreateContainer extends React.Component {
  state = {
    building: null,
  };

  componentDidMount() {
    this.props.fetchCompanies({ limit: 1000 });
  }

  saveBuilding = (buildingParams) => {
    if (this.state.building) {
      return Promise.resolve(this.state.building);
    }

    return this.props.saveBuilding(buildingParams).then((response) => {
      const building = get(response, 'list.0');
      this.setState({ building });

      return building;
    });
  };

  createBindings = (bindingParams, building) => {
    return this.props
      .batchProcessingBindings({
        ...bindingParams,
        building_id: building.id,
      })
      .then(() => building);
  };

  onSubmit = (values) => {
    const company = this.props.companies.find((v) => v.role === values.owner);

    if (hasIntersections(values.bindings)) {
      notification.error('Дублирование квартир. Проверьте внесённые диапазоны');
      return;
    }

    const data = prepareBindings(values.bindings);

    const additionalFiasId = `${get(values.address, 'street_fias_id') || ''} ${
      get(values.address, 'house_type') || ''
    }${get(values.address, 'house') || ''}${get(values.address, 'block_type') || ''}${
      get(values.address, 'block') || ''
    }`;
    let addressInfo = {
      address: {
        id: values.address.value,
        text: values.address.title,
        type: 'fias',
      },
      fias: [
        get(values.address, 'region_fias_id'),
        get(values.address, 'city_fias_id'),
        get(values.address, 'street_fias_id'),
        get(values.address, 'house_fias_id'),
      ].filter(Boolean),
      additional_fias_for_check: additionalFiasId,
    };

    if (values.address.fias_level === '7') {
      addressInfo = {
        address: {
          id: additionalFiasId,
          text: values.address.title,
          type: 'fias',
        },
        fias: [
          get(values.address, 'region_fias_id'),
          get(values.address, 'city_fias_id'),
          get(values.address, 'street_fias_id'),
          additionalFiasId,
        ].filter(Boolean),
        additional_fias_for_check: additionalFiasId,
      };
    }

    const buildingParams = {
      ...addressInfo,
      commercial_status: values.commercial_status,
      owner_role: company.role,
      type: 'residential',
      year: values.year,
      floors: values.floors,
      billing_account: values.billing_account,
    };
    const bindingParams = {
      building_id: null,
      data: data,
    };

    return this.saveBuilding(buildingParams)
      .then((building) => this.createBindings(bindingParams, building))
      .then((building) => {
        notification.success();

        return building;
      })
      .then((building) => this.props.history.push(`/buildings/${building.id}/edit`))
      .catch(requestErrorHandlerForReduxForm)
      .catch((e) => Promise.reject(submissionErrorFlatten(e, ['address'])));
  };

  onCancel = () => {
    this.props.history.push('/buildings');
  };

  render() {
    const companiesOptions = this.props.companies.map((v) => {
      return {
        key: v.id,
        text: v.name,
        value: v.role,
      };
    });

    return (
      <>
        <Header as={'h2'}>{this.props.header || 'Добавить дом'}</Header>
        <Segment padded={'very'} className={'block'}>
          <FormComponent
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            companies={companiesOptions}
            initialValues={this.props.initialValues}
          />
        </Segment>
      </>
    );
  }
}

BuildingCreateContainer.propTypes = {
  initialValues: PropTypes.object,
  header: PropTypes.string,
  companies: PropTypes.arrayOf(CompanyProps),
  history: WebHistoryProps.isRequired,
  currentUser: CurrentUserProps.isRequired,
  saveBuilding: PropTypes.func,
  batchProcessingBindings: PropTypes.func,
  fetchCompanies: PropTypes.func,
};

const mapStateToProps = (state) => {
  const companies = selectCompaniesAsArray(state);

  return {
    companies,
    initialValues: {
      isAddressValidate: true,
      commercial_status: BuildingCommercialStatus.commercial,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveBuilding: bindActionCreators(saveBuilding, dispatch),
    batchProcessingBindings: bindActionCreators(batchProcessingBindings, dispatch),
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingCreateContainer);
