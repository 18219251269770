import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { Form, Radio } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  &&&& {
    margin-bottom: 1.5rem;
  }

  &&&& > div.field {
    display: inline-block;
    margin-right: 32px;
    margin-top: 12px;
  }

  &&&& > div.field:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const RadioGroup = ({ input, meta: _meta, items, readonly, disabled, label }) => {
  const handleChange = (_e, data) => {
    input.onChange(data.value);
  };

  return (
    <Container className="field">
      {label && <label>{label}</label>}
      {items.map((item, i) => {
        return (
          <Form.Field key={`${item.value}-${i}`}>
            <Radio
              {...input}
              value={item.value}
              label={item.name}
              readOnly={readonly}
              disabled={readonly || disabled}
              checked={item.value === input.value}
              onChange={handleChange}
            />
          </Form.Field>
        );
      })}
    </Container>
  );
};

RadioGroup.propTypes = {
  ...fieldPropTypes,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default RadioGroup;
