import React from 'react';
import { CellProps, Column, UsePaginationState } from 'react-table';
import { Link } from 'react-router-dom';
import { CameraAgg } from 'core/store/selectors/cameras';
import { buildAddressStr } from 'core/utils/address';
import { ExtendedEntity } from 'core/store/selectors';
import { CameraActions } from './actions';
import { Tag } from 'components/share/components';
import styled from 'styled-components';

type CreateColumnsType = {
  isBindingAllowed?: boolean;
  query?: URLSearchParams;
  isArmDomofon?: boolean;
};

type ColumnType = Column<ExtendedEntity<CameraAgg>>;

const Box = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-left: 1rem;
  }
`;

export const createColumns = (props: CreateColumnsType): Array<ColumnType> => {
  const colums: Array<ColumnType> = [
    {
      Header: '№',
      Cell: ({ row: { index }, state }: CellProps<CameraAgg>) => {
        const { pageIndex, pageSize } = state as unknown as UsePaginationState<CameraAgg>;

        return index + 1 + pageIndex * pageSize;
      },
      width: 65,
    },
    {
      Header: 'Название',
      accessor: 'id',
      Cell: ({ row: { original } }: CellProps<CameraAgg>) => {
        return (
          <Link
            to={{
              pathname: `/cameras/${original.id}/edit`,
              state: {
                tableParams: {
                  page: props?.query?.get('page'),
                  size: props?.query?.get('size'),
                },
              },
            }}
          >
            {original.name || original.id}
          </Link>
        );
      },
      width: 250,
    },
    {
      Header: 'Домофон',
      Cell: ({ row: { original } }: CellProps<CameraAgg>) => {
        return original.intercom ? (
          <Link to={`/intercoms/${original.intercom.id}`}>
            {original.intercom.name} ({original.intercom.phone})
          </Link>
        ) : (
          ''
        );
      },
      width: 200,
    },
    {
      Header: 'Атрибут',
      Cell: ({ row: { original } }: CellProps<CameraAgg>) => {
        const isMain = original.device?.sort_order === 1;
        return isMain ? <Tag variant="green">Основная</Tag> : '';
      },
      width: 100,
    },
    {
      Header: 'Владелец',
      id: 'owner',
      Cell: ({ row: { original } }: CellProps<CameraAgg>) => {
        const { owner } = original.device || {};
        if (owner) {
          return (
            <Box>
              <Link to={`/companies/${owner.id}`}>{owner.name}</Link>
              {original.__owner_status && (
                <Tag variant={original.__owner_status.color}>{original.__owner_status.name}</Tag>
              )}
            </Box>
          );
        }

        return '';
      },
    },
    {
      Header: 'Адрес',
      Cell: ({ row: { original } }: CellProps<CameraAgg>) => {
        if (!original.device || !original.device.bindings.length) {
          return <span style={{ fontStyle: 'italic' }}>(Не привязан к адресу)</span>;
        }

        return buildAddressStr(original.device);
      },
    },
    {
      Header: 'Действия',
      Cell: ({ row: { original } }: CellProps<CameraAgg>) => {
        return <CameraActions camera={original} isBindingAllowed={props?.isBindingAllowed} />;
      },
    },
  ];

  if (props?.isArmDomofon) {
    const index = colums.findIndex((item) => item.id === 'owner');
    colums.splice(index, 1);
  }

  return colums;
};
