import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';

export default class ErrorOnPage extends Component {
  render() {
    const { children } = this.props;

    const message = React.isValidElement(children)
      ? children
      : children.toString
      ? children.toString()
      : children + '';
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign={'center'}>
            <Header as={'h4'}>{message}</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

ErrorOnPage.propTypes = {
  children: PropTypes.any,
};
