import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';
import { Form } from 'mts-ui';

const TextInput = (props) => {
  const {
    label,
    formatter,
    help,
    meta = {},
    input,
    handleChange = null,
    controlledError,
    ...rest
  } = props;

  const { touched = false, error = '' } = meta;
  const hasError = !!(touched && error);

  const handleOnChange = (e, { value }) => {
    if (formatter) {
      value = formatter(value);
    }

    if (handleChange) {
      handleChange(value);
    }

    input.onChange(value);
  };

  return (
    <Form.InputAdvanced
      placeholder={label}
      {...rest}
      {...input}
      error={controlledError || (hasError ? error : undefined)}
      hint={help}
      label={label}
      onChange={handleOnChange}
    />
  );
};

TextInput.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  help: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  horizontal: PropTypes.bool,
  isEdited: PropTypes.bool,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
  formatter: PropTypes.func,
  onClick: PropTypes.func,
};

TextInput.defaultProps = {
  required: false,
};

export default TextInput;
