import React, { isValidElement, PropsWithChildren, ReactNode } from 'react';
import {
  Button,
  ButtonProps,
  Modal,
  StrictModalProps,
  Icon,
  Dimmer,
  Loader,
} from 'semantic-ui-react';

interface OwnProps {
  size?: StrictModalProps['size'];
  isLoading?: boolean;
  closeOnClick?: boolean;
  closeIcon?: boolean;
  open?: boolean;
  header?: ReactNode;
  onClose?: () => void;
  actions: Array<ReactNode | ButtonProps>;

  // TODO какой то херовый вариант
  setClose?: (close: Function) => void;
  setOpen?: (close: Function) => void;
}

interface Props extends PropsWithChildren<OwnProps> {}

interface State {
  open: boolean;
}

class ModalWindow extends React.Component<Props, State> {
  static defaultProps: Partial<OwnProps> = {
    closeIcon: true,
    header: 'Подтверждение операции',
    size: 'small',
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      open: props.open ?? true,
    };
  }

  componentDidMount(): void {
    if (this.props.setClose) {
      this.props.setClose(this.setClose);
    }

    if (this.props.setOpen) {
      this.props.setOpen(this.setOpen);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.open !== undefined && this.props.open !== this.state.open) {
      this.setState({ open: this.props.open });
    }
  }

  setClose = () => {
    this.setState({ open: false });
  };

  setOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { actions, size, isLoading, header, closeIcon, closeOnClick } = this.props;

    const icon = closeIcon ? <Icon className={'mts-icon-close close'} /> : undefined;

    return (
      <Modal
        open={this.state.open}
        size={size}
        onClose={this.handleClose}
        closeIcon={icon}
        closeOnDimmerClick={closeOnClick}
        closeOnDocumentClick={closeOnClick}
        closeOnEscape={closeOnClick}
      >
        {header && <Modal.Header content={header} />}
        <Modal.Content>
          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>

          {this.props.children}
        </Modal.Content>
        {actions && actions.length > 0 && (
          <Modal.Actions>
            {actions.map((action, index) => {
              if (isValidElement(action)) {
                return action;
              }

              return <Button key={index} {...(action as ButtonProps)} />;
            })}
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export interface ModalWindowProps extends OwnProps {}

export default ModalWindow;
