import styled from 'styled-components';
import {
  Segment,
  StrictDropdownProps,
  Button,
  Grid,
  FormGroup,
  FormGroupProps,
} from 'semantic-ui-react';
import { Field, BaseFieldsProps } from 'redux-form';
import { ThemeVars } from 'core/styles/vars';

const { colors } = ThemeVars;

export const SegmentStyled = styled(Segment)`
  &&&& {
    padding-top: 2em;
    padding-bottom: 4em;
    border-radius: 8px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08);
  }
`;

type InputProps = { label?: string; required?: boolean };

type FieldType = Omit<BaseFieldsProps<InputProps & Partial<StrictDropdownProps>>, 'names'>;

export const FromGroupStyled = styled(FormGroup)<FormGroupProps>`
  & {
    align-items: center;
  }
`;

export const FieldStyled15 = styled(Field)<FieldType>`
  &&&&& {
    width: calc(100% / 3 * 0.5);
  }
`;

export const FieldStyled50 = styled(Field)<FieldType>`
  &&&&& {
    width: calc(100% / 3 * 1.5);
  }
`;

export const ButtonStyled = styled(Button)`
  &&&& {
    background-color: ${colors.whiteSmoke};
    border: 2px solid ${colors.whiteSmoke};
    color: ${colors.lightBlack};

    &:hover {
      filter: brightness(95%);
    }
  }
`;

export const ColumnStyled = styled(Grid.Column)`
  &&&& > div,
  &&&& > div > div > div {
    margin-bottom: 0;
  }

  &&&& {
    display: flex;
    justify-content: space-between;
  }
`;
