import React, { useCallback } from 'react';
import { Grid, Button, Icon, SemanticICONS, Popup } from 'semantic-ui-react';
import TextField from 'components/share/components/text-field';
import './styles.less';
import { request } from 'core/utils/request';
import api from 'core/constants/api';
import { showToast } from 'core/utils/toast';
import { ToastConsumerContext } from 'react-toast-notifications';
import notification from 'core/utils/notification';

type User = {
  name: string;
  email: string;
  phone: string;
};

interface Props {
  user: User;
  id: string;
  toastManager: ToastConsumerContext;
}

const fetchRequest = (id: string) => request.post(api.resendConfirmationEmail(id));

export const ContactUserView = (props: Props) => {
  const { name, email, phone } = props.user;
  const resendEmail = useCallback(() => {
    fetchRequest(props.id)
      .then(() => {
        showToast({
          toastManager: props.toastManager,
          message: 'Письмо успешно отправлено',
          type: 'success',
        });
      })
      .catch((error) => {
        if (error.response) notification.error(error.response.data.error.data);
        else notification.error('Ошибка сети');
      });
  }, [props.id, props.toastManager]);

  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column width={5}>
          <TextField title={'ФИО'}>{name}</TextField>
        </Grid.Column>
        <Grid.Column width={5}>
          <div className="field-with-btn">
            <TextField title={'E-mail'}>{email}</TextField>
            <Popup
              content="Повторно отправить письмо для установки пароля"
              trigger={
                <Button icon onClick={resendEmail}>
                  <Icon name={'sync' as SemanticICONS} />
                </Button>
              }
            />
          </div>
        </Grid.Column>
        <Grid.Column width={5}>
          <TextField title={'Номер телефона'}>{phone}</TextField>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ContactUserView;
