import styled from 'styled-components';
import { Segment, Button, Grid } from 'semantic-ui-react';
import { ThemeVars } from 'core/styles/vars';
import { Input, InputProps } from 'components/share/form/formik';
const { colors } = ThemeVars;

export const SegmentStyled = styled(Segment)`
  &&&& {
    padding-top: 2em;
    padding-bottom: 4em;
    border-radius: 8px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08);
  }
`;

export const InputStyled15 = styled(Input)<InputProps>`
  &&&&& {
    width: calc(100% / 3 * 0.5);
  }
`;

export const ButtonStyled = styled(Button)`
  &&&& {
    background-color: ${colors.whiteSmoke};
    border: 2px solid ${colors.whiteSmoke};
    color: ${colors.lightBlack};

    &:hover {
      filter: brightness(95%);
    }
  }
`;

export const ColumnStyled = styled(Grid.Column)`
  &&&& {
    display: flex;
    justify-content: flex-end;
  }
`;
