import {
  TOKEN_REQUEST,
  TOKEN_SUCCESS,
  TOKEN_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from '../../../constants/actions';

export default function token(state = {}, action) {
  switch (action.type) {
    case TOKEN_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        token: null,
        isFetching: true,
        error: null,
      };

    case TOKEN_FAIL:
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        token: null,
        isFetching: false,
        error: action.payload.error,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        token: {
          ...action.payload.token,
        },
        error: null,
        isFetching: false,
      };

    case TOKEN_SUCCESS: {
      let token = action.payload.token;

      if (!token.is_verified && !token.is_expired) {
        token = null;
      }

      return {
        ...state,
        token: token,
        error: null,
        isFetching: false,
      };
    }

    default:
      return state;
  }
}
