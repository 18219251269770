import { createSelector } from 'reselect';
import { Apartment, User } from 'core/constants/contracts';
import { GenericParametricSelectorNullable } from '.';
import { RootState } from '..';
import { getUsersItems } from './users';

interface ApartmentIdProps {
  apartmentId: string;
}

interface BindingIdProps {
  bindingId: string;
}

export const getApartments = (state: RootState) => state.entities.apartments;
export const getApartmentsItems = (state: RootState) => state.entities.apartments.items;
export const getApartmentsMetadata = (state: RootState) => state.entities.apartments.metadata;
export const getApartmentById = (state: RootState, props: ApartmentIdProps) =>
  state.entities.apartments.items[props.apartmentId];
export const getApartmentId = (state: RootState, props: ApartmentIdProps) => props.apartmentId;
export const getBindingId = (state: RootState, props: BindingIdProps) => props.bindingId;

export const createSelectApartment = (): GenericParametricSelectorNullable<
  ApartmentIdProps,
  Apartment
> =>
  createSelector(
    [getApartmentsItems, getApartmentId],
    (apartments, apartmentId) => apartments[apartmentId]
  );

export const createSelectApartmentByBinding = (): GenericParametricSelectorNullable<
  BindingIdProps,
  Apartment
> =>
  createSelector([getApartmentsItems, getBindingId], (apartments, bindingId) => {
    return Object.values(apartments).find((v) => v.binding === bindingId);
  });

export const createSelectApartmentAdmin = (): GenericParametricSelectorNullable<
  ApartmentIdProps,
  User
> =>
  createSelector([getApartmentById, getUsersItems], (apartment, users) =>
    apartment ? users[apartment.admin] : undefined
  );
